import React from 'react';
import {
  Table,
  Input,
  Button,
  Tabs,
  Select,
  Modal,
  message,
} from 'antd';
import styles from './index.scss';
import request from 'utils/request';
import open from 'utils/request/open';

import AddReport from 'components/Form/noAnswers';
import EditReport from 'components/Form/failureReport';

const pageSize = 10;

const { Option } = Select;

export default class Unfinished extends React.PureComponent {
  state = {
    unfinishedSource: [],
    overdueSource: [],
    currentPage: 1,
    keyword: '',
    level: '',
    tabKey: '1',
    status: 1,
    submitData: [],

    visible: false,
    form_id: '',

    editVisible: false,

    submitVisible: false,
  };

  unfinishedColumns = [{
    title: '序号',
    dataIndex: 'index',
  }, {
    title: '任务标题',
    dataIndex: 'task_name',
  }, {
    title: '备注',
    dataIndex: 'describe',
  }, {
    title: '级别',
    dataIndex: 'level',
    render: (cell, row) => {
      if (row.level == 1) {
        return '一般';
      } else {
        return '紧急';
      }
    }
  }, {
    title: '填报起止时间',
    dataIndex: 'time',
  }, {
    title: '操作',
    key: 'actions',
    render: (cell, row) => {
      if (row.status === 1 || row.status === '1') {
        return <Button style={{marginLeft: '5px'}} size="small" onClick={ () => {this.formAdd(row.id)}}>填报</Button>
      } else {
        return(
          <div className={styles.item}>
            <Button size="small" onClick={ () => {this.formEdit(row.id)}}>编辑</Button>
            <Button size="small" onClick={ () => {this.formExport(row.id)}}>导出</Button>
            <Button size="small" onClick={ () => {this.formSubmit(row.id)}}>提交</Button>
          </div>
        )
      }
    }
  }];

  overdueColumns = [{
    title: '序号',
    dataIndex: 'index',
  }, {
    title: '任务标题',
    dataIndex: 'task_name',
  }, {
    title: '备注',
    dataIndex: 'describe',
  }, {
    title: '级别',
    dataIndex: 'level',
    render: (cell, row) => {
      if (row.level == 1) {
        return '一般';
      } else {
        return '紧急';
      }
    }
  }, {
    title: '填报起止时间',
    dataIndex: 'time',
  }, {
    title: '操作',
    key: 'actions',
    render: (cell, row) => {
      return <Button size="small" disabled>已过期</Button>
    }
  }];

  componentDidMount() {
    this.getList(this.state.tabKey, this.state.currentPage);
  }

  getList = (key, currentPage) => {
    if (key == '1') {
      request({
        url: '/web/Form/noAsk',
        data: {
          limit: pageSize,
          page: currentPage,
          keyword: this.state.keyword,
          level: this.state.level,
        },
      })
        .then(body => body)
        .then(({ data }) => {
          const list = data.data;
          // const status = data.status;
          const limit = 10;
          const current_page = parseInt(list.current_page, 10);
          const total = parseInt(list.total, 10);
          const dataSource = list.data.map((row, index) => ({
            key: row.id,
            index: (index + 1) + (current_page - 1) * limit,
            ...row,
          }));
          this.setState({
            currentPage: current_page,
            totalPages: total,
            unfinishedSource:dataSource,
            // status,
          });
        });
    } else {
      request({
        url: '/web/Form/overdueAsk',
        data: {
          limit: pageSize,
          page: currentPage,
          keyword: this.state.keyword,
          level: this.state.level,
        },
      })
        .then(body => body)
        .then(({ data }) => {
          const list = data.data;
          const status = data.status;
          const limit = 10;
          const currentPage = parseInt(list.current_page, 10);
          const total = parseInt(list.total, 10);
          const dataSource = list.data.map((row, index) => ({
            key: row.id,
            index: (index + 1) + (currentPage - 1) * limit,
            ...row,
          }));
          this.setState({
            // currentPage,
            totalPages: total,
            overdueSource:dataSource,
            status
          });
        });
    }
    this.setState({
      tabKey: key
    })
  };

  keywordChange = (e) => {
    this.setState({keyword: e.target.value});
  };

  selectChange = (val) => {
    this.setState({level: val});
  };

  searchChange = () => {
    this.state.currentPage = 1;
    this.getList(this.state.tabKey, this.state.currentPage);
  };

  tabsChange = (key) => {
    this.state.currentPage = 1;
    this.state.keyword = '';
    this.state.level = '';

    this.getList(key, this.state.currentPage)
  };

  paginationChange = (current_page) => {
    this.setState({
      currentPage: current_page
    });
    this.getList(this.state.tabKey, current_page)
  };

  formAdd = (id) => {
    this.setState({
      form_id: id,
      visible: true,
    })
  };

  formEdit = (id) => {
    this.setState({
      form_id: id,
      editVisible: true,
    })
  };

  formExport = (id) => {
    const url = '/web/Form/exportExcel';
    const data = {
      reply_id: id,
    };
    open(url, data);
  };

  formSubmit = (id) => {
    this.setState({
      submitVisible: true,
      form_id: id,
    })
  };

  saveForm = (data) => {
    this.setState({
      submitData: data,
    });
    request({
      url: '/web/Form/saveForm',
      data: {
        res: data,
        id: this.state.form_id,
      },
    })
      .then((body)=>{
        if (body.data.code === 200) {
          this.closeModal(false);
          // this.setState({visible: false})
        } else {
          message.error(body.msg);
        }
      })
  };

  closeModal = (visible) => {
    this.setState({
      visible: visible,
      editVisible: visible,
    });
    this.getList(this.state.tabKey, this.state.currentPage);
  };

  handleOk = (e) => {
    request({
      url: '/web/Form/sendCity',
      data: {
        reply_id: this.state.form_id,
      },
    })
      .then((body)=>{
        if (body.data.code === 200) {
          this.setState({
            submitVisible: false,
          });
          this.getList(this.state.tabKey, this.state.currentPage);
        } else {
          message.info(body.msg);
        }
      });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      editVisible: false,
      submitVisible: false,
    });
  };

  render() {
    const { currentPage, totalPages, unfinishedSource, overdueSource, visible, editVisible, submitVisible, } = this.state;
    return (
      <div className={styles.Form}>
        <Tabs activeKey={this.state.tabKey} onChange={activeKey=>{this.tabsChange(activeKey)}}>
          <Tabs.TabPane key="1" tab="未填报">
            <div className={styles.topLine}>
              <div className={styles.search}>
                <Input placeholder="关键字" onChange={e => {this.keywordChange(event)}}/>
              </div>
              <div className={styles.search}>
                <Select showSearch style={{ width: 150 }} placeholder="请选择级别" onChange={this.selectChange.bind(this)}>
                  <Option value="">请选择级别</Option>
                  <Option value="1">一般</Option>
                  <Option value="2">紧急</Option>
                </Select>
              </div>
              <div className={styles.search}>
                <Button type="primary"  onClick={()=>{this.searchChange()}}>搜索</Button>
              </div>
            </div>
            <Table
              bordered
              columns={this.unfinishedColumns}
              dataSource={unfinishedSource}
              pagination={{pageSize, current: currentPage, total: totalPages, onChange: this.paginationChange, showTotal: () => {return `共${totalPages}条`}}}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab="已过期" >
            <div className={styles.topLine}>
              <div className={styles.search}>
                <Input placeholder="关键字" onChange={e => {this.keywordChange(event)}}/>
              </div>
              <div className={styles.search}>
                <Select showSearch style={{ width: 150 }} placeholder="请选择级别" onChange={this.selectChange.bind(this)}>
                  <Option value="">请选择级别</Option>
                  <Option value="1">一般</Option>
                  <Option value="2">紧急</Option>
                </Select>
              </div>
              <div className={styles.search}>
                <Button type="primary" onClick={()=>{this.searchChange()}}>搜索</Button>
              </div>
            </div>
            <Table
              bordered
              columns={this.overdueColumns}
              dataSource={overdueSource}
              pagination={{pageSize, current: currentPage, total: totalPages, onChange: this.paginationChange, showTotal: () => {return `共${totalPages}条`}}}
            />
          </Tabs.TabPane>
        </Tabs>

        {
          <Modal
            destroyOnClose={true}
            visible={visible}
            title="填报"
            width={1200}
            footer={null}
            onCancel={this.handleCancel}
          >
            <AddReport data={{form_id: this.state.form_id}} saveForm={this.saveForm.bind(this)} closeModal={this.closeModal.bind(this)}/>
          </Modal>
        }

        {
          <Modal
            destroyOnClose={true}
            visible={editVisible}
            title="编辑"
            width={1200}
            footer={null}
            onCancel={this.handleCancel}
          >
            <EditReport data={{form_id: this.state.form_id}} saveForm={this.saveForm.bind(this)} closeModal={this.closeModal.bind(this)}/>
          </Modal>
        }

        {
          <Modal
            title="提交"
            visible={submitVisible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <div style={{fontSize: '16px'}}>
              <span>您将提交表单，</span>
              <span style={{color: 'red'}}>提交后无法修改！</span>
              <span>请确定</span>
              <span style={{color: 'red'}}>填报内容正确</span>
              <span>和</span>
              <span style={{color: 'red'}}>需要上传的文件或签字盖章件</span>
              <span>都已正确上传！</span>
            </div>
          </Modal>
        }
      </div>
    );
  }
}
