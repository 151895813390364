

import React, { useState } from 'react'
import { Table, Button} from 'antd'
import { reasonArr } from '../../share'
import { previewable } from 'components/Previewer/index';
import Track from './Track'
import ImagePreviewButtonRender from 'ui/TableCell/ImagePreviewButtonRender';
import styles from './index.scss'
/* 上报表格 */
function MainForm(props) {
  let { selectedRows, preview } = props
  const render = ImagePreviewButtonRender(preview);
  const [trackInfo, setTrackInfo] = useState(false)

  /** 判断是否有数据 */
  const DetermineIfThereIsData = t => t ? t : '暂无'
  const columns = [
    { title: '序号', dataIndex: 'num', width: 80, },
    { title: '车牌', dataIndex: 'car_number', width: 100, },
    { title: '是否温江录名', dataIndex: 'is_car_directory', width: 130, render: function (v) { return v == 1 ? '是' : '否' } },
    { title: '运企名称', dataIndex: 'transport_name', width: 200, },
    { title: '所属区域', dataIndex: 'area_name', width: 90, },
    { title: '关联驾驶员', dataIndex: 'driver_name', width: 110, },
    { title: '事由', dataIndex: 'reason', width: 100, render: function (v) { return reasonArr[v].label } },
    { title: '处罚', dataIndex: 'deal_result', render: function () { return <span style={{ color: 'red' }}>停运</span> } },
    { title: '告警时间', dataIndex: 'alarm_time', width: 200, render:DetermineIfThereIsData},
    { title: '告警地点', dataIndex: 'address', width: 150, render:DetermineIfThereIsData },
    { title: '抓拍图片', dataIndex: 'images', width: 100, render },
    { title: '轨迹', dataIndex: 'is_route', align: 'center', render: (v, e) => v == 1 ? <Button type="link" onClick={() => { setTrackInfo(true) }}>查看</Button> : '无' },

  ];

  let data = []
  selectedRows.forEach((item, i) => {
    data.push({
      key: i,
      num: i + 1,
      car_number: item.car_number,
      is_car_directory: item.is_car_directory,
      transport_name: item.transport_name,
      area_name: item.area_name,
      driver_name: item.driver_name,
      reason: item.reason,
      deal_result: item.deal_result,
      alarm_time: item.alarm_time,
      address: item.address,
      images: item.images,
      is_route: item.is_route,
    })
  })
  function TrackInfo({ visible }) { if (!visible) { setTrackInfo(false) } }
  return (
    <div className={styles.mainForm}>
      <Track props={{ trackInfo, TrackInfo, selectedRows }} />
      <Table
        className={styles.mainFormTable}
        bordered={true}
        columns={columns}
        dataSource={data}
        onChange={(q, w, e, r) => { }}
        pagination={{ showSizeChanger: true, showTotal: total => `共 ${total} 条`, onChange: (e) => { } }}
      />
    </div>
  )
}
export default previewable(MainForm);
