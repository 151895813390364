import React from 'react';
import ModalFormAddPage from 'ui/ModalFormAddPage';
import debug from 'utils/debug';
import controller from './controller';
import request from "utils/request";
import {InputNumber, message} from "antd";
const ReasonOptions = [
  {value: '泥巴', label: '泥巴'},
  {value: '垃圾', label: '垃圾'},
  {value: '未装满', label: '未装满'},
  {value: '其他', label: '其他'},
];

const inputs = [
  {
    label: '起点',
    children: [
      // { placeholder: '区域', key: 'start_area_id', hide: true, type: 'select', options: '/web/Gps_Fault/area_list', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
      { placeholder: '起点部门', key: 'start_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
      { placeholder: '起点', key: 'start_id', type: 'select', options: '/web/instance/getBuildingAdd', extraData: { types: 1 }, valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['start_department_id'], reliesAs:['id'], required: true, style: { width: 240 } },
      { placeholder: '运输车', key: 'car_num', type: 'select', options: '/web/Car/getCarList', realTimeSearch: 'keywords', valueKey: 'car_num', labelKey: 'car_num', relies: ['start_department_id', 'start_id'], reliesAs: ['id', 'instance_id'], required: true, style: { width: 130 } },
      { placeholder: '司机', key: 'driver_id', type: 'select', options: '/web/Driver/getDriverByCarNum', valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'car_num', style: { width: 120 } },
    ],
    /*children: [
      { placeholder: '起点部门', key: 'start_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
      { placeholder: '起点', key: 'start_id', type: 'select', options: '/web/instance/getBuildingAdd', extraData: { types: 1 }, valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'start_department_id', relyAs: 'id', required: true, style: { width: 240 } },
      { placeholder: '运输车', key: 'car_num', type: 'select', options: '/web/Car/getCarList', realTimeSearch: 'keywords', valueKey: 'car_num', labelKey: 'car_num', relies: ['start_department_id', 'start_id'], reliesAs: ['id', 'instance_id'], required: true, style: { width: 130 } },
      { placeholder: '司机', key: 'driver_id', type: 'select', options: '/web/Driver/getDriverByCarNum', valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'car_num', required: false, style: { width: 120 } },
    ],*/
  },
  { label: '开单时间', key: 'start_time', type: 'date', showTime: true, required: true },
  {
    label: '终点',
    children: [
      { placeholder: '终点部门', value: 126, key: 'end_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
      { placeholder: '终点', value: 1, key: 'end_id', type: 'select', options: '/web/instance/getBuilding', extraData: { types: 2 }, valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'end_department_id', relyAs: 'id', required: true, style: { width: 240 } },
    ],
  },
  { label: '入库时间', key: 'end_time', type: 'date', showTime: true, required: true },
  { label: '收货人', key: 'acquirer_id', type: 'select', options: '/web/user/getUsers', valueKey: 'id', labelKey: 'name', showSearch: true, required: true, style: { width: 195 } },
  {
    label: '物料',
    children: [
      { placeholder: '物料', key: 'materiel_id', type: 'select', options: '/web/route/initMateriel', valueKey: 'id', labelKey: 'name', required: true, style: { width: 195 } },
    ],
  },
  { label: '方量',
    children: [
      { placeholder: '货箱方量', key: 'car_volumn', disabled: true, options: '/web/ticket/getCarVolumn', rely: 'car_num', precision: 2, type: 'number-options', style: { width: 165 } },
      // { placeholder: '扣方', min: 0, step: 0.1, key: 'deduction_volume', type: 'number-options',disabled: false, style: { width: 165 } },
      { placeholder: '实际方量', key: 'volumn', type: 'number-options', precision: 2, extraKey: 'car_volumn', relyMoreKey: 'deduction_volume', disabled: true, style: { width: 165 }, reliesCount: ['car_volumn', 'deduction_volume'] }
    ]
  },
  { label: '扣方原因', key: 'reason', type: 'radioVolume', options: ReasonOptions, showOrHide: 'deduction_volume', hide: true, required: true },
  { label: '补录原因', key: 'added_reason', type: 'textarea' },
];

export default function Create({ history, location }) {
  const {params} = location.state
  debug.render('Create');
  const back = () => history.goBack();
  const onFinish = (data, type) => {
    const second = JSON.parse(sessionStorage.getItem('secondReceiver')) ? JSON.parse(sessionStorage.getItem('secondReceiver')).name : '';
    if(data.reasonVolumnKey){
      data.reason = data.reasonVolumnKey;
    }
    if(!data.volumn){
      data.volumn = parseFloat(parseFloat(data.car_volumn) - (data.deduction_volume ? parseFloat(data.deduction_volume).toFixed(1) : 0)).toFixed(1);
    }
    request('/web/ticket/add', {...data, second_receiver: second, transport_id: '0'})
      .then(body => {
        console.log(body.data)
        if(body.data.code !== 200){
          message.warn(body.data.msg)
        }else{
          const d = body.data.data;
          sessionStorage.setItem('ticketAddAndPrint', JSON.stringify(d))
          controller.refresh();
          back(); 
        }
      })
  };

  return (
    <ModalFormAddPage
      defaultValue={{ ...params, deduction_volume: 0 }}
      title="补录电子联单"
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
      modalProps={{
        width: 800,
      }}
      footer={true}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}
    />
  );
}
