import React from 'react';
import { Route } from 'react-router-dom';
import moment from 'moment';
import { previewable } from 'components/Previewer';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import LinkButton from 'ui/TableCell/LinkButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';
import ImagePreviewButtonRender from 'ui/TableCell/ImagePreviewButtonRender';
import Deal from './Deal';

export const dataSource = new DataSource('/river/river_alarm/getRiverTicketLists');

function List({ preview, path, ...rest }) {
  const defaultDate = [moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')]
  const render = ImagePreviewButtonRender(preview);
  const percentRange = [
    { label: '0% ~ 10%', value: 1 },
    { label: '10% ~ 30%', value: 2 },
    { label: '30% ~ 50%', value: 3 },
    { label: '50% ~ 80%', value: 4 },
    { label: '大于80%', value: 5 },
  ];
  const columns = [
    serial(true),
    { title: '项目名称', dataIndex: 'project_name' },
    { title: '运企名称', dataIndex: 'transport_name' },
    { title: '车辆号牌', dataIndex: 'plate_num', width: width(5) },
    { title: '自编号', dataIndex: 'car_id' },
    // { title: '告警类型', dataIndex: 'alarm_type', width: width(5) },
    { title: '是否超速', dataIndex: 'is_alarm', width: width(5), render: (v) => v === 1 ? '是' : '否' },
    { title: '告警时间', dataIndex: 'alarm_time', render: (v, {end_time, is_alarm}) => is_alarm === 1 ? end_time : '' },
    { title: '时速(KM/H)', dataIndex: 'speed', width: width(6) },
    { title: '超速百分比(%)', dataIndex: 'percent', width: width(7) },
    { title: '载重状态', dataIndex: 'is_empty', width: width(5), render: (v) => v === 1 ? '空车' : '重车' },
    { title: '第1个检查点时间', dataIndex: 'begin_time' },
    { title: '第1个检查点照片', dataIndex: 'begin_pic', render },
    { title: '第2个检查点时间', dataIndex: 'end_time' },
    { title: '第2个检查点照片', dataIndex: 'end_pic', render },
    { title: '告警处理结果', dataIndex: 'deal_result' },
    { title: '告警处理人', dataIndex: 'deal_name' },
    { title: '处理上传时间', dataIndex: 'deal_time' },
    actions({
      width: width(4),
      render: ($, {id, deal_result, plate_num, is_alarm}) => (
        <>
          {
            is_alarm ? <LinkButton to={{pathname: `./deal`, state: {id, plate_num, deal_result}}} children={"处理结果"} /> : ''
          }
        </>
      )
    })
  ];

  const AlarmOption = [{ id: 1, name: '超速', }, { id: 2, name: '正常' }];
  const emptyOption = [{ id: 1, name: '空车', }, { id: 2, name: '重车' }];
  const querys = [
    { title: ['记录', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
    { title: '请选择项目', key: 'project_id', type: 'select', options: '/river/river_project/getRiverProjectOption', valueKey: 'id', labelKey: 'name', style: { width: 240 } },
    { title: '请选择运企', key: 'transport_id', type: 'select', options: '/river/river_base/getRiverTransportOption', valueKey: 'id', labelKey: 'name', style: { width: 300 } },
    { title: '请选择载重状态', key: 'is_empty', type: 'select', options: emptyOption, valueKey: 'id', labelKey: 'name', style: { width: 150 } },
    { title: '请选择是否超速', key: 'is_alarm', type: 'select', options: AlarmOption, valueKey: 'id', labelKey: 'name', style: { width: 150 } },
    { title: '请选择超速百分比范围', key: 'percent', type: 'select', options: percentRange, style: { width: 200 } },
    { title: '请输入车辆号牌、自编号、处理人模糊搜索', key: 'keyword', style: { width: 320 } },
  ];

  const buttons = [
    { title: '导出', download: '/river/river_alarm/exportRiverTicketLists' },
  ];

  return (
    <>
      <TablePage
        defaultQuery={{ times: defaultDate }}
        dataSource={dataSource}
        buttons={buttons}
        querys={querys}
        columns={columns}
        defaultLimit={10}
        showOverAlarm={true}
      />
      <Route path={path + 'deal'} component={Deal} />
    </>
  );
}

export default previewable(List)
