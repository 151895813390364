import React from 'react';
import {message} from 'antd';
import ModalFormPage from 'ui/ModalFormPage';
import { dataSource, inputs } from './List';

export default function Update({ history, match }) {
  const inputs = [
    { label: '项目名称', placeholder: '请填写项目名称', key: 'project_name', required: true, disabled: true },
    { label: '自编号', placeholder: '请填写自编号', key: 'car_id', required: true },
    { label: '核定方量', placeholder: '请填写核定方量', key: 'volumn', type: 'number', required: true },
  ];
  const back = () => history.goBack();
  const onFinish = () => {
    message.success('项目车辆更新成功');
    dataSource.refresh();
    back();
  };

  const id = match.params.id;
  const car_num = match.params.car_num;
  return (
    <ModalFormPage
      init={['/river/river_project/editRiverProjectCarInfo', { river_project_car_id: id }]}
      submit={['/river/river_project/updateRiverProjectCarInfo', { river_project_car_id: id }]}
      title={`修改项目车辆(${car_num})信息`}
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
    />
  );
}
