import React, { useState, useEffect } from 'react';
import { previewable } from 'components/Previewer/index';
import {Modal, Table} from 'antd';
import styles from "./Deal.scss";
import request from "utils/request";
import {typeArr, ShowFile} from "../share";

const columns = [
  { title: '序号', dataIndex: 'serial' },
  { title: '处罚', dataIndex: 'deal_result', render: (v) => (<span style={{ color: '#D9001B' }}>{v === 1 ? '进入灰名单' : '进入黑名单'}</span>) },
  { title: '车牌', dataIndex: 'car_number' },
  { title: '是否温江目录', dataIndex: 'is_car_directory', render: (v) => (<span>{ (v === 1 || v === '1') ? '是' : '否'}</span>) },
  { title: '关联驾驶员', dataIndex: 'driver_name' },
  { title: '事由', dataIndex: 'reason', width: 300, render: (v) => typeArr[v] },
  { title: '备注', dataIndex: 'remark', width: 300 },
];

const statusArr = ['', '已确认', '申诉中', '申诉未通过', '申诉通过'];
const statusColor = ['', '#AAAAAA', '#F59A23', '#D9001B', '#49D966'];

const columnsByCheck = [
  { title: '序号', dataIndex: 'serial' },
  { title: '处罚', dataIndex: 'deal_result', render: (v) => (<span style={{ color: '#D9001B' }}>{v === 1 ? '进入灰名单' : '进入黑名单'}</span>) },
  { title: '状态', dataIndex: 'status', render: (v) => (<span style={{ color: statusColor[v] }}>{statusArr[v]}</span>) },
  { title: '车牌', dataIndex: 'car_number' },
  { title: '是否温江目录', dataIndex: 'is_car_directory', render: (v) => (<span>{ (v === 1 || v === '1') ? '是' : '否'}</span>) },
  { title: '关联驾驶员', dataIndex: 'driver_name' },
  { title: '事由', dataIndex: 'reason', width: 300, render: (v) => typeArr[v] },
  { title: '备注', dataIndex: 'remark', width: 300 },
];
function getVerifyBillDetails(id){
  const [data, setData] = useState({step1: {}, step2: {}, step3: {}, step4: {}});
  useEffect(() => {
    request('/web/gb_list/getVerifyBillDetails', {bill_id: id})
      .then(body => body.data.data)
      .then(setData)
  }, [id]);

  return [data]
}

function Detail({ history, preview, match }) {
  const back = () => history.goBack();
  const { params: {id} } = match;
  const [data] = getVerifyBillDetails(id);
  const title = ['', '车辆违规事由通知', '车辆违规事由核实', '车辆违规事由审核', '车辆违规事由通知'];
  const DealChecked = ({ info, status }) => {
    return (
      <>
        <div className={status === 2 ? styles.appeal : styles.check}>
          <span className={styles.active} style={{backgroundColor: status === 3 ? '#FD332D' : '#F59A23'}}>{status === 2 ? '申诉已勾选' : '驳回已勾选'}</span>
        </div>
        <div className={status === 2 ? styles.evidenceAppeal : styles.evidenceCheck}>
          <div>
            <span>证据文件</span>
            <div style={{ height: 60}}>
              {
                 info.images && info.images.split(',').map((item, i) => ((item.indexOf('.pdf') > -1 || item.indexOf('.doc') > -1 || item.indexOf('.xlsx') > -1) ? <span className={styles.showFile} onClick={() => ShowFile(item)}>查看文件</span> : <img key={i} className={styles.imgs} src={item}  onClick={() => preview.image(item)} alt=""/>))
               }
            </div>
          </div>
          <div>
            <span>{status === 2 ? '申诉理由' : '驳回理由'}</span>
            <div style={{ height: 60}}>{info.reason}</div>
          </div>
        </div>
      </>
    )
  }
  const CheckTemplate = ({ data, info, rowSelection, status }) => {
    return (
      <div className={styles.detailPart}>
        <div className={styles.tipPart}>
          <p>{title[status]}</p>
          <div>
            <span>{status === 1 ? '创建时间：' : status === 2 ? '申诉时间：' : status === 3 ? '审核时间：' : '上报时间：'}{info.time}</span>
            <span>{status === 1 ? '创建人员：' : status === 2 ? '申诉人员：' : status === 3 ? '审核人员：' : '上报人员：'}{info.operator ? info.operator : '系统默认'}</span>
          </div>
        </div>
        {
          (status === 1 || status === 4) ?
            <Table columns={columns} dataSource={data} rowKey={'id'} scroll={{ y: 280 }} pagination={false} />
            : <Table rowSelection={rowSelection} columns={columnsByCheck} dataSource={data} rowKey={'id'} scroll={{ y: 280 }} pagination={false} />
        }
        { (status === 2 || status === 3) && <DealChecked info={info} status={status} /> }
      </div>
    )
  };
  function filterTable(data, type){
    let arr = [];
    if(type === 4){
      const arr1 = data.map(({ status, ...v}) => (status === 4 ? false : { status, ...v})).filter(item => item);
      arr = arr1.map((v, i) => ({serial: (i + 1), ...v}))
    }else {
      arr = data.map(({status, ...v}, i) => ({serial: (i + 1), status: type === 2 ? status !==1 ? 2 : 1 : status, ...v}))
    }
    return arr
  }
  const DetailStatus = ({data, type}) => {
    if(!data || JSON.stringify(data) === '{}') return null;
    let select = [];
    const table = filterTable(data.table, type);
    if(type === 2){
      select = data.table.map(({status, id}) => ( status !== 1 ? id : false)).filter(item => item);
    }else if(type === 3){
      select = data.table.map(({status, id}) => ( status === 3 ? id : false)).filter(item => item);
    }
    return <CheckTemplate data={table} rowSelection={{ selectedRowKeys: select, getCheckboxProps: record => ({disabled: true}) }} info={data.info} status={type} />;
  };

  return (
    <Modal
      title={(Object.keys(data.step2).length > 0 && Object.keys(data.step3).length === 0) ? '正在审核中' : '进入名单' }
      visible={true}
      onCancel={back}
      footer={null}
      width={1600}
      bodyStyle={{ height: '70vh', overflow: 'auto'}}
    >
      { <DetailStatus data={data.step4} type={4}/> }
      { <DetailStatus data={data.step3} type={3}/> }
      { <DetailStatus data={data.step2} type={2}/> }
      { <DetailStatus data={data.step1} type={1}/> }

    </Modal>
  )
}

export default previewable(Detail)
