/* eslint-disable no-unused-vars */
import md5 from 'blueimp-md5';
import store from 'stores/user';
import lodash from 'lodash';

const crypto = (userId, key, type, args) => {
  // 毫秒时间戳
  const timestamp = parseInt(new Date().getTime() / 1000, 10);
  // 除了sign和key所有字段都参与排序
  // 过滤值为不需要传的参数
  const extra = lodash.toPairs(args).filter(([k, v]) => (v !== undefined && v !== null && v !== ''));
  const items = [
    ...extra,
    ['userId', userId],
    ['type', type],
    ['timestamp', timestamp],
  ];
  // 按键名从小到大排序
  items.sort(([a], [b]) => a.localeCompare(b));
  // url拼接(数组和对象不参与排序, 末尾增加key)
  const encoded = [...items, ['key', key]]
    .filter(([k, v]) => (!Array.isArray(v) && typeof v !== 'object'))
    .map(item => item.join('='))
    .join('&');
  // console.log('加密字符串:', encoded);
  // MD5加密并大写
  const sign = md5(encoded).toUpperCase();
  return lodash.fromPairs([...items, ['sign', sign]]);
};

export default (data = {}) => {
  const { id, key } = store.getState();
  return crypto(id, key, 'city_web', data);
};
