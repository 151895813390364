import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Select, Input, message, Button } from 'antd';
import { dataSource } from './List';
import request from 'utils/request';
import styles from './../Create.scss';

const { Option } = Select;

function getTransport() {
  const [arr, setArr] = useState([]);
  useEffect(() => {
    request('/river/river_base/getRiverTransportOption')
      .then(body => body.data.data)
      .then(setArr)
  }, []);
  return arr;
}

export default function Create({ history, match }) {
  const back = () => history.goBack();
  const transportArr = getTransport();
  const [transportId, setTransportId] = useState();
  const [carNumbers, setCarNumbers] = useState(1);
  const [carMsg, setCarMsg] = useState([{ plate_num: '' }]);
  const onFinish = () => {
    request('/river/river_base/createRiverTransportCarInfo', {river_transport_id: transportId, cars: carMsg})
      .then(body => body.data)
      .then(data => {
        if(data.code === 200) {
          message.success(data.msg);
          dataSource.refresh();
          back();
        }else {
          message.warn(data.msg)
        }
      });
  };
  const ModifyMsg = (msg, serial) => {
    setCarMsg((s) => s.map((v, i) => (
        {
          ...v,
          plate_num: serial === i ? msg : v.plate_num
        }
      )));
  };

  const deleteCar = useCallback((e) => {
    const deleteId = Number(e.target.dataset.value);
    setCarNumbers((v) => (v - 1))
    setCarMsg((s) => s.map((v, i) => i === deleteId ? false : {...v}).filter((v) => v))
  }, []);

  const [dis, setDis] = useState(false);
  useEffect(() => {
    carMsg.map(({ plate_num }) => {
      if(plate_num !== '') {setDis(true)}
      else setDis(false)
    })
  }, [JSON.stringify(carMsg)]);

  return (
    <Modal
      title={"新增车辆"}
      visible={true}
      width={500}
      onCancel={back}
      onOk={onFinish}
      okButtonProps={{ disabled: !transportId || !dis }}
    >
      <div className={styles.title}>
        <Select placeholder={'请选择运企'} value={transportId} style={{ width: 250 }} onChange={(e) => setTransportId(e)} >
          {
            transportArr.map(({ id, name }, i) => (
              <Option key={i} value={id}>{name}</Option>
            ))
          }
        </Select>
        <div className={styles.changeNumber}>
          <span>车辆数量：{carNumbers}</span><span onClick={() => {setCarNumbers((v) => (v + 1)); setCarMsg((s) => { s.push({ plate_num: ''}); return s } )}}>+</span>
        </div>
      </div>
      <div className={`${styles.content} ${styles.carContent}`}>
        <div>
          <span>序号</span>
          <span>车辆号牌</span>
          <span />
        </div>
        {
          carMsg.map(({ plate_num }, i) => (
            <div key={i}>
              <span><Input value={i + 1} readOnly /></span>
              <span><Input value={plate_num} onChange={(e) => ModifyMsg(e.target.value, i)} /></span>
              <Button data-value={i} onClick={deleteCar} type="danger" disabled={carNumbers === 1}>删除</Button>
            </div>
          ))
        }
      </div>
    </Modal>
  );
}
