import common from "./common";

/**
 * 本地转换，逐个转换，建议使用，速度比向百度请求快多了
 *
 */
const pi = 3.14159265358979324;      //圆周率
const ee = 0.00669342162296594323;   //椭球的偏心率
const a = 6378245.0;                 //卫星椭球坐标投影到平面地图坐标系的投影因子
const x_pi = 3.14159265358979324 * 3000.0 / 180.0;  //圆周率转换量
// 求弧度

const h_common = {
  radian: function(d){
    return d * pi / 180.0;   //角度1? = π / 180
  },
  transformLat: function(lat, lon)    //纬度转化
  {
    let ret = -100.0 + 2.0 * lat + 3.0 * lon + 0.2 * lon * lon + 0.1 * lat * lon + 0.2 * Math.sqrt(Math.abs(lat));
    ret += (20.0 * Math.sin(6.0 * lat * pi) + 20.0 * Math.sin(2.0 * lat * pi)) * 2.0 / 3.0;
    ret += (20.0 * Math.sin(lon * pi) + 40.0 * Math.sin(lon / 3.0 * pi)) * 2.0 / 3.0;
    ret += (160.0 * Math.sin(lon / 12.0 * pi) + 320 * Math.sin(lon * pi / 30.0)) * 2.0 / 3.0;
    return ret;
  },

  transformLon: function(lat, lon)   //经度转化
  {
    let ret = 300.0 + lat + 2.0 * lon + 0.1 * lat * lat + 0.1 * lat * lon + 0.1 * Math.sqrt(Math.abs(lat));
    ret += (20.0 * Math.sin(6.0 * lat * pi) + 20.0 * Math.sin(2.0 * lat * pi)) * 2.0 / 3.0;
    ret += (20.0 * Math.sin(lat * pi) + 40.0 * Math.sin(lat / 3.0 * pi)) * 2.0 / 3.0;
    ret += (150.0 * Math.sin(lat / 12.0 * pi) + 300.0 * Math.sin(lat / 30.0 * pi)) * 2.0 / 3.0;
    return ret;
  },
  GPS84_To_GCJ02: function(WGS84_Lat, WGS84_Lon){
    let dLat;
    let dLon;
    let radLat;
    let magic;
    let sqrtMagic;
    dLat = h_common.transformLat(WGS84_Lon - 105.0, WGS84_Lat - 35.0);
    dLon = h_common.transformLon(WGS84_Lon - 105.0, WGS84_Lat - 35.0);
    radLat = WGS84_Lat / 180.0 * pi;
    magic = Math.sin(radLat);
    magic = 1 - ee * magic * magic;
    sqrtMagic = Math.sqrt(magic);
    dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * pi);
    dLon = (dLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * pi);
    let GCJ02_Lat = parseFloat(WGS84_Lat) + parseFloat(dLat);    //GCJ02_Lat是百度纬度存储变量的地址  *GCJ02_Lat就是那个值
    let GCJ02_Lon = parseFloat(WGS84_Lon) + parseFloat(dLon);    //GCJ02_Lon是百度经度存储变量的地址  *GCJ02_Lon
    return [GCJ02_Lat, GCJ02_Lon]
  },
  GCJ02_To_BD09: function(GCJ02_Lat, GCJ02_Lon){
    let x = GCJ02_Lon, y = GCJ02_Lat;
    let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
    let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
    let BD_09_Lon = z * Math.cos(theta) + 0.0065;
    let BD_09_Lat = z * Math.sin(theta) + 0.006;
    return [BD_09_Lon, BD_09_Lat]
  },
  GPS_transformation: function(WGS84_Lat, WGS84_Lon){
    let Gcj02 = h_common.GPS84_To_GCJ02(WGS84_Lat, WGS84_Lon);//GPS坐标转火星坐标
    return h_common.GCJ02_To_BD09(Gcj02[0], Gcj02[1]); //火星坐标转百度坐标
  },
};
export default h_common
