import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  DatePicker,
  Icon,
  message,
  Radio,
  Modal,
  Button,
  Drawer,
  Switch,
  Slider,
  Table,
  Input,
  Tooltip,
  Spin,
  Divider,
  Progress,
  Pagination,
  Carousel,
  Select
} from 'antd';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import moment from 'moment';
import echarts from 'echarts';
import request from 'utils/request';
import styles from './index.scss';
import store from 'stores/user';
import h_common from 'utils/h_common';
import {
  previewable,
  UseEcharts,
  Convert,
  ConvertCoords,
  UseMapScatter,
  UseMapInstance,
  StartOrEndPoint,
  UseMapLines,
  UseMapLinesTooltip,
  GetTicketShowTime,
  UseMapAnchorage,
  PointClass
} from 'components/Previewer';
import TrashTicketDetail from './CommandCenter/TrashTicketDetail';
import { 拉运计划列表 } from './CommandCenter/TrashTicketDetail';
import CallDriver from "./CommandCenter/CallDriver";
import WaitTicketDeal from "./CommandCenter/WaitTicketDeal";
import AlarmCenter from './Alarm/AlarmCenter';
import PointPreview from './PointPreview';
import MergeTicket from 'components/PopoverPage/MergeTicket';
import SplitTicket from 'components/PopoverPage/SplitTicket';
import OverTicket from "components/PopoverPage/OverTicket";
import OverTicketByInstance from "components/PopoverPage/OverTicketByInstance";
import OverTicketByPoints from "components/PopoverPage/OverTicketByPoints";
import UnableToVerify from '../../components/PopoverPage/UnableToVerify';
import OfflineWork from 'components/PopoverPage/OfflineWork'; // 点击离线作业联单出现离线详情
//案件中心
import CasesCenter from './CasesCenter';
import ControlSlider from 'components/PopoverPage/ControlSlider';
import TrailRadio from 'components/PopoverPage/TrailRadio';
import { UseChangePoint } from 'components/PopoverPage/TicketCommon';

const Search = Input.Search;
const { confirm } = Modal;
const { Option } = Select;
const defaultDates = [moment().subtract(0, 'day').startOf('day'), moment().endOf('day')];
const defaultGrossDates = [moment().subtract(0, 'day').startOf('day'), moment().endOf('day')];
const defaultEleDates = [moment().subtract(6, 'day').startOf('day'), moment().endOf('day')]; //电子联单， 默认统计一周数据
const coordObjectsToArrays = objects => objects.map(({lng, lat}) => [lng, lat]);
const coordObjectsToArraysByTrans = objects => objects.map(({lng, lat}) => h_common.GPS_transformation(lat, lng));
const coordObjectsToArraysByTicket = objects => objects.map(({lng, lat, stay_time, ticket_id, time,}) => ({ value: h_common.GPS_transformation(lat, lng), stay_time, ticket_id, time}));
const filterStopPoints = (objects, color) => objects.map(({ lng, lat, stay_time, ticket_id, time, }) =>({
  value: h_common.GPS_transformation(lat, lng),
  stay_time,
  ticket_id,
  time,
  itemStyle: {
    color
  }
}));
const coordObjectsToArraysByPoints = objects => objects.map(({lng, lat, speed, time, ticket_id}) => ({ value: h_common.GPS_transformation(lat, lng), speed, time, ticket_id }));
let plansData = [], plansListData = [], plansStopData = [], plansElseList = [];
let ticket_status = null;
let planFlag = false;
let buildMapPoints = [], buildMapPoints2 = [], buildMapPoints3 = [], buildMapPoints4 = [], sandMapPoints = [], consumeMapPoints = [], concertMapPoints = [], waitMapPoints = [], suspiciousMapPoints = [], otherMapPoints = [], factoryMapPoints = [];
const SOURCELIST = ['', '车载识别', '人工补录', '车牌识别'];
const imagesSize = [39, 47];
const pointsImages = [
  'image:///images/center/wait.png',
  [
    '',
    'image:///images/center/consum0.png',
    ['', 'image:///images/center/b1.png', 'image:///images/center/b3.png', 'image:///images/center/b5.png'],
    ['', 'image:///images/center/s1.png', 'image:///images/center/s3.png', 'image:///images/center/s5.png'],
    ['', 'image:///images/center/x1.png', 'image:///images/center/x3.png', 'image:///images/center/x5.png'],
    ['', 'image:///images/center/t1.png', 'image:///images/center/t3.png', 'image:///images/center/t5.png'],
    ['', 'image:///images/center/q1.png', 'image:///images/center/q2.png', 'image:///images/center/q3.png'],
    ['', 'image:///images/center/j1.png', 'image:///images/center/j3.png', 'image:///images/center/j5.png'],
  ],
  [
    '',
    'image:///images/center/consum0.png',
    ['', 'image:///images/center/b2.png', 'image:///images/center/b4.png', 'image:///images/center/b6.png'],
    ['', 'image:///images/center/s2.png', 'image:///images/center/s4.png', 'image:///images/center/s6.png'],
    ['', 'image:///images/center/x2.png', 'image:///images/center/x4.png', 'image:///images/center/x6.png'],
    ['', 'image:///images/center/t2.png', 'image:///images/center/t4.png', 'image:///images/center/t6.png'],
    ['', 'image:///images/center/q1.png', 'image:///images/center/q2.png', 'image:///images/center/q3.png'],
    ['', 'image:///images/center/j2.png', 'image:///images/center/j4.png', 'image:///images/center/j6.png'],
  ],
  [
    '',
    'image:///images/center/consum0.png',
    ['', 'image:///images/center/b2.png', 'image:///images/center/b4.png', 'image:///images/center/b6.png'],
    ['', 'image:///images/center/s2.png', 'image:///images/center/s4.png', 'image:///images/center/s6.png'],
    ['', 'image:///images/center/x2.png', 'image:///images/center/x4.png', 'image:///images/center/x6.png'],
    ['', 'image:///images/center/t2.png', 'image:///images/center/t4.png', 'image:///images/center/t6.png'],
    ['', 'image:///images/center/q1.png', 'image:///images/center/q2.png', 'image:///images/center/q3.png'],
    ['', 'image:///images/center/j2.png', 'image:///images/center/j4.png', 'image:///images/center/j6.png'],
  ]
];

function useInterval(callback, delay) {
  const savedCallback = useRef();
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function usePlans(dates, materiel_type) {
  const [chartLoading, setChartLoading] = useState(true);
  const [data, setData] = useState({
    elseList: [],
    list: [],
    lines: [],
    sourceList: [],
    stopPoints: [],
    ticketLines: [],
    ticketSource: [],
    suspectConsum: [],
    summary: {in: {}, out: {}, both: {}}
  });
  const begin_date = dates[0].format('YYYY-MM-DD HH:mm:ss');
  const end_date = dates[1].format('YYYY-MM-DD HH:mm:ss');
  useEffect(() => {
    setChartLoading(true);
    request('web/ticket_map/transportDistribution', {begin_date: begin_date, end_date: end_date, materiel_type})
      .then(body => body.data.data)
      .then(setData)
      .finally(() => {
        setChartLoading(false);
      });
  }, [begin_date, end_date, materiel_type]);
  plansData = data.sourceList;
  plansListData = data.list;
  plansStopData = data.stopPoints;
  plansElseList = data.elseList;
  return [data, chartLoading];
}

function useAbnormalList(dates, materiel_type, type, page, keywords, balance_accounts, areaId, area_level, source, ticket_ids, stopOrList, overFlag) {
  const [leftLoading, setLeftLoading] = useState(false);
  const [data, setData] = useState({list: [], summary: []});
  const begin_date = dates[0].format('YYYY-MM-DD HH:mm:ss');
  const end_date = dates[1].format('YYYY-MM-DD HH:mm:ss');
  const ticket_type = type;  
  useEffect(() => {
    if (area_level === 1 && !areaId) {    
      return;
    }    
    setLeftLoading(true);
    const promise = stopOrList ?
      request('/web/ticket_map/getTicketList', {ticket_ids: ticket_ids, materiel_type}) :
      request('web/ticket_map/abnormalList', {
        begin_date: begin_date,
        end_date: end_date,
        materiel_type,
        source: source,
        ticket_type: ticket_type,
        page: page,
        limit: 10,
        keywords: keywords,
        balance_accounts,
        areaId
      });
    promise
      .then(body => body.data.data)
      .then(setData)
      .finally(() => {
        setLeftLoading(false);
      });
  }, [begin_date, end_date, stopOrList, materiel_type, ticket_type, page, keywords, balance_accounts, areaId, area_level, source, ticket_ids, overFlag]);
  return [data, leftLoading];
}

let bound = null;
let zoom = 15;
let bmap = null;

function useMap(isChartUsable, chart, areaLine, mapType, series, center, play_begin, play_end, flag, otherCenterFlag) {
  // 用 useState 和 useEffect 请求和存储 areaPolyLines
  const [areaPolyLines, setAreaPolyLines] = useState();
  useEffect(() => {
    request('web/radar/areaLine')
      .then(body => body.data.data)
      .then(data => setAreaPolyLines(data));
  }, []);
  // 在依赖里添加 areaPolyLines 保证请求完成后这个 effect 会重新运行
  useEffect(() => {
    // 在这里判断 areaPolyLines 是否请求完了 (流程控制)
    if (!areaPolyLines) return;
    const area_line = areaPolyLines.map(({name, poly_line}, i) => (
      UseMapLines({
        data: [{
          coords: poly_line ? ConvertCoords(poly_line) : [[], []],
        }], lineColor: '#1491fb', lineType: 'dashed', lineWidth: 2
      })
    ));
    const area_back = areaPolyLines.map(({name, poly_line}, i) => (
      {
        name: i,
        zlevel: 2,
        type: 'custom',
        coordinateSystem: 'bmap',
        renderItem: function (params, api) {
          return Convert(areaPolyLines[params['seriesName']], params, api);
        },
        itemStyle: {
          normal: {
            label: {
              show: true, color: "#0B1747", formatter: function (parms) {
                return parms.name
              }
            }
          },
          emphasis: {
            label: {
              show: true, formatter: function (parms) {
                return parms.name
              }
            }
          }
        },
        tooltip: {
          show: true,
          formatter: '{b}'
        },
        animation: false,
        data: [{name: name, value: [0]}],
        z: -10
      }
    ));
    if (!isChartUsable()) return;
    (!play_begin || play_end) ? chart.clear() : null;
    let option = {
      tooltip: {
        trigger: 'item'
      },
      animation: false,
      bmap: {
        center: center || [103.836472, 30.719426],
        zoom: 13,
        roam: true,
        mapStyle: {
          // styleJson: mapStyleJson,
        },
      },
      toolbox: {
        itemSize: 20,
        itemGap: 15,
        top: 120,
        right: 340,
        zlevel: 10,
        orient: 'vertical',
        iconStyle: {
          normal: {
            borderColor: '#4B505D',
            borderWidth: 1.5,
            backgroundColor: '#ffffff',
            padding: [4, 5]
          },
          emphasis: {
            borderColor: '#1491fb',
            borderWidth: 2
          }
        }
      },
      brush: {
        toolbox: ['rect', 'polygon', 'keep', 'clear'],
        brushType: 'polygon',
        brushMode: "multiple",
        outOfBrush: {
          color: '#abc'
        },
        brushStyle: {
          borderWidth: 2,
          color: 'rgba(0,0,0,0.2)',
          borderColor: 'rgba(0,0,0,0.5)',
          backgroundColor: '#ffffff'
        },
        throttleType: 'debounce',
        throttleDelay: 300,
        geoIndex: 0
      },
      series: areaLine ? [...series, ...area_line, ...area_back] : [...series],
    };
    if (bmap) {
      option.bmap.zoom = bmap.getZoom();
      let lng = bmap.getCenter().lng;
      let lat = bmap.getCenter().lat;
      bound = bmap.getBounds();
      option.bmap.center = [lng, lat];
    }
    if (flag) {
      option.bmap.center = center;
    }
    if (otherCenterFlag) {
      option.bmap.center = center;
    }
    chart.setOption(option);
    bmap = chart ? chart.getModel().getComponent('bmap').getBMap() : null;
    bmap ? mapType ? bmap.setMapType(BMAP_HYBRID_MAP) : bmap.setMapType(BMAP_NORMAL_MAP) : null;
  }, [chart, series, areaPolyLines, areaLine, mapType]);
}

//获取右侧区县的天气情况
function getEnvironment(area_id, area_name) {
  const [data, setData] = useState({alarm: [{}]});
  useEffect(() => {
    request('web/radar/environment', {area_id, area_name})
      .then(body => body.data.data)
      .then(setData)
  }, []);
  return data
}

function getBuildCount(area_id) {
  const [data, setData] = useState({
    visor: [],
    check: []
  });
  useEffect(() => {
    request('web/radar/buildCount', {area_id})
      .then(body => body.data.data)
      .then(setData)
  }, []);
  return data;
}

// 获取右侧车辆监管
function carCount(date, area_id) {
  const [data, setData] = useState([{total: 0}, {total: 0}, {total: 0}]);
  const start_date = date[0].format('YYYY-MM-DD');
  const end_date = date[1].format('YYYY-MM-DD');
  useEffect(() => {
    request('web/radar/carCount', {area_id, time: [start_date, end_date]})
      .then(body => body.data.data)
      .then(setData)
  }, [start_date, end_date]);
  return data
}

function getTransportCount(date, area_id) {
  const [data, setData] = useState({inner: [], in: [], out: []});
  const start_date = date[0].format('YYYY-MM-DD');
  const end_date = date[1].format('YYYY-MM-DD');
  useEffect(() => {
    request('web/radar/transportCount', {area_id, time: [start_date, end_date]})
      .then(body => body.data.data)
      .then(setData)
  }, [start_date, end_date]);
  return data;
}

// 获取右侧面板电子联单数据
function getEleTicket(date, area_id) {
  const [data, setData] = useState({recognize: [{data: [], name: ''}], confirm: [{data: [], name: ''}], date: []});
  const start_date = date[0].format('YYYY-MM-DD');
  const end_date = date[1].format('YYYY-MM-DD');
  useEffect(() => {
    request('web/radar/ticketLine', {area_id, time: [start_date, end_date]})
      .then(body => body.data.data)
      .then(setData)
  }, [start_date, end_date]);
  return data;
}

// 获取告警数据
function obtainAlarmData(dates) {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const begin_date = dates[0].format('YYYY-MM-DD');
  const end_date = dates[1].format('YYYY-MM-DD');
  useEffect(() => {
    request('/web/count_alarm/data', {page: 1, limit: 100, status: 1, time: [begin_date, end_date]})
      .then(b => b.data.data)
      .then(data => {
        setData(data.data.data);
        setTotal(data.data.total)
      })
  }, [begin_date, end_date]);
  return [data, total]
}

function useLineChart(isChartUsable, chart, series, xAxis, color) {
  useEffect(() => {
    if (!isChartUsable()) return;
    chart.clear()
    let option = {
      grid: {
        left: 10,
        right: 0,
        top: 0,
        bottom: 20
      },
      tooltip: {
        trigger: 'axis'
      },
      color: color,
      xAxis: {
        type: 'category',
        data: xAxis,
        axisLine: {
          lineStyle: {
            color: '#E5E5E5'
          }
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: '#3F4146',
          fontSize: 12,
          interval: 0,
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          fontSize: 12,
          color: '#C3C3C3',
          inside: true,
          align: 'left',
          padding: [0, 0, 12, 0],
          margin: 0,
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: '#E5E5E5'
          }
        }
      },
      series: series
    };
    chart.setOption(option, true);
  }, [chart, series])
}

const isInArray = (arr, value) => {
  for (var i = 0; i < arr.length; i++) {
    if (value === arr[i]) {
      return true;
    }
  }
  return false;
};

Array.prototype.remove = (val) => {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};

//去重
const DuplicateRemoval = (data) => {
  let arr = [];
  data.map(v => {
    let f = true;
    arr.map(e => {
      if ((v.id === e.id) && (v.department_id === e.department_id)) {
        f = false
      }
    })
    if (f) {
      arr.push(v)
    }
  });
  return arr;
};

let chartBuild = null;
const useAreaArr = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    request('web/area/data', )
      .then((res) => res.data.data)
      .then(setData)
  }, []);
  return data;
}
function TransportRadar({preview}) {
  const balanceAccountsArr = useMemo(() => [
    {id: 0, name: '结算确认-全部'},
    {id :1, name: '待确认'},
    {id: 2, name: '无需结算'},
    {id: 3, name: '未结算'},
    {id: 4, name: '纳入结算'},
    {id: 5, name: '无'}
  ], []);
  const areaArr = useAreaArr();
  const [chart, isChartUsable, chartRefGetter] = UseEcharts();
  const [dates, setDates] = useState(defaultDates);
  const [alarmDate, setAlarmDate] = useState(defaultDates);
  const [AlarmData, AlarmTotal] = obtainAlarmData(alarmDate)
  const [series, setSeries] = useState([]);
  const storeInfo = store.getState();
  const roleId = storeInfo.roles.id;
  const isZx = (roleId === 111 || roleId === 115 || roleId === '111' || roleId === '115');
  const { center_one_lng, center_one_lat } = storeInfo.info;
  const {info: {area_id, area_name, area_level}} = storeInfo;
  const [materiel_type, setMaterielType] = useState(1);
  const [plans, chartLoading] = usePlans(dates, materiel_type);
  const [dataSource, setDataSource] = useState([]);//useSource(plans.list);
  const [mapType, setMapType] = useState(false);
  const [lastHour, setLastHour] = useState();
  const [hour, setHour] = useState();
  const [trashDetailData, setTrashDetailData] = useState({});
  const [trashDetailSpin, setTrashDetailSpin] = useState([]);
  const [trash_show_detail, setTrashShowDetail] = useState(false);
  const [ticketList, setTicketList] = useState([]);
  const [ticketId, setTicketId] = useState(); //
  const [offlineWorkTicketId, setOfflineWorkTicketId] = useState({ id: null, index: 2 }); // 点击离线作业电子联单
  const [ticketSeries, setTicketSeries] = useState();
  const [call_driver, setCallDriver] = useState(false);
  const [msg, setMsg] = useState('通话中。。。');
  const [call_flag, setCallFlag] = useState(false);
  const [trailBtn, setTrailBtn] = useState({ ticket_lines: false, showCars: false, rectify: true, stopPoint: false, show_points: false, show_stops: false, play_begin: false, play_end: false, visible: false, play_restart: false, slider: 0, moveSpeed: '400', playIndex: 0, movePos: 0 });
  const [stopPoint, setStopPoint] = useState(); // 查看轨迹时，点击停靠点显示其经纬度，用于复制
  const [near, setNear] = useState(true); // 是否只显示轨迹附近的固定源
  const [center, setCenter] = useState([center_one_lng, center_one_lat]);
  const [centerFlag, setCenterFlag] = useState(false);
  const [otherCenterFlag, setOtherCenterFlag] = useState(true);
  const [visibleTitle, setVisibleTitle] = useState('接管报表');
  const [concertData, setConcertData] = useState({});
  const [visibleTable, setVisibleTable] = useState(false);
  //左侧列表
  const [navNum, setNavNum] = useState(3); //左侧的伪导航 1 - 异常 2 - 已处理
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [keywords, setKeywords] = useState('');
  const [balance_accounts, setBalanceAccounts] = useState(0);
  const [areaId, setAreaId] = useState(); // 联动区县筛选 
  const [source, setSource] = useState(0);
  const [stopOrList, setStopOrList] = useState(false);
  const [ticket_ids, setTicketIds] = useState('');
  const [overFlag, setOverFlag] = useState(false);
  const [abnormalList, leftLoading] = useAbnormalList(dates, materiel_type, navNum, page, keyword, balance_accounts, areaId, area_level, source, ticket_ids, stopOrList, overFlag);
  const [abnormalData, setAbnormalData] = useState([]);
  const [accountSource, setAccountSource] = useState(0);
  const [summary, setSummary] = useState([]);
  const [newConsum, setNewConsum] = useState();
  const [right_flag, setRightFlag] = useState([5, 6, 8]);
  const [areaBorder, setAreaBorder] = useState(true);
  const [linesSource, setLinesSource] = useState();
  const [linesFlag, setLinesFlag] = useState(false);
  const [right_flag1, setRightFlag1] = useState([]);
  const [top_flag, setTopFlag] = useState([1, 2, 3, 4, 5]);//顶部四条线路的显示与隐藏
  // 黑工地
  const [visibleBuild, setVisibleBuild] = useState(false);
  const [moreVisible, setMoreVisible] = useState(true);
  //接管报表筛选条件
  const [filterFlag, setFilterFlag] = useState('0');
  const [sourceFlag, setSourceFlag] = useState('0');
  // 点击告警中心
  const [warnVisible, setWarnVisible] = useState(false);
  //右侧面板
  //  排放工地 + 车辆监管数据
  const environment = getEnvironment(area_id);
  const carManage = carCount(dates, area_id);
  const grossData = getBuildCount(area_id);
  const [grossType, setGrossType] = useState();
  const [watchVisible, setWatchVisible] = useState(false); //监管车辆抽屉显示与否
  const [isArea, setIsArea] = useState(1);
  const transCount = getTransportCount(dates, area_id);
  const [eleDates, setEleDates] = useState(defaultEleDates);
  const [eleFlag, setEleFlag] = useState(true);
  const eleData = getEleTicket(eleDates, area_id); //获取电子联单数据
  const [eleOption, setEleOption] = useState(1);
  const [eleSeries, setEleSeries] = useState([]);
  const [eleXAxis, setEleXAxis] = useState([]);
  const [eleChart, eleIsChartUsable, eleChartRefGetter] = UseEcharts();
  const [eleLegend, setEleLegend] = useState([]);
  const [countBuild, setCountBuild] = useState([0, 0, 0, 0, 0, 0]);
  const [countLines, setCountLines] = useState([{sum: 0, volumn: 0}, {sum: 0, volumn: 0}, {sum: 0, volumn: 0}, {sum: 0, volumn: 0}, {sum: 0, volumn: 0}]);

  // 排放工地一级菜单  ---- 监管
  const [grossKeyword, setGrossKeyword] = useState('');
  const [grossKeywords, setGrossKeywords] = useState('');
  const [grossTime, setGrossTime] = useState(defaultGrossDates);
  const [grossPageLimit, setGrossPageLimit] = useState({page: 1, limit: 10});
  const [grossListData, setGrossListData] = useState({total: 0, data: []});
  const [grossCarOrMap, setGrossCarOrMap] = useState({id: '', flag: false, department_id: ''});
  const [grossSpan, setGrossSpan] = useState(false);
  const [grossTitle, setGrossTitle] = useState('');
  const [inoutTitle, setInoutTitle] = useState('');

  // 获取分类工地、消纳场等
  function instanceClassify(type, data){
    const pointsData = [], concertData = [],  suspiciousData = [], otherData = [], waitData = [], sandData = [], consumeData = [], factoryData = []; //工地icon 商砼 疑似点位 其他点位 待排查点位 加工厂
    let l1 = 0, l6 = 0, l7 = 0, l8 = 0, l9 = 0;
    const r0 = isInArray(type ? right_flag1 : right_flag, 0), r1 = isInArray(type ? right_flag1 : right_flag, 1), r9 = isInArray(type ? right_flag1 : right_flag, 9),
      r10 = isInArray(type ? right_flag1 : right_flag, 10), r11 = isInArray(type ? right_flag1 : right_flag, 11), r12 = isInArray(type ? right_flag1 : right_flag, 12),
      r13 = isInArray(type ? right_flag1 : right_flag, 13), r15 = isInArray(type ? right_flag1 : right_flag, 15), r16 = isInArray(type ? right_flag1 : right_flag, 16),
      r17 = isInArray(type ? right_flag1 : right_flag, 17), r18 = isInArray(type ? right_flag1 : right_flag, 18);
    const build = type ? trailParams.build : pointsParams.build, sand = type ? trailParams.sand : pointsParams.sand,
      consume = type ? trailParams.consume : pointsParams.consume, concert = type ? trailParams.concert : pointsParams.concert,
      factory = type ? trailParams.factory : pointsParams.factory;

    data.map(({ consum_type, time_status, point_type, open_status, status, type, lng, lat, ...item }, i) => {
      //  consum_type 0 - 待排查 1 - 非法点位 2 - 非违规区域
      //  point_type 点位特征值 1 - 疑似点位 2 - 工地 3 - 砂石厂 4 - 消纳厂 5 - 商砼站 6 - 其他
      //  time_status 1 - 无时间期限 2 - 有时间期限 3 - 超时间期限

      if(point_type === 2 && item.area_name === area_name){
        if (open_status === 1) {
          if (status === 2) {
            l6 ++;
            (r1 || r9) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/green1.png', symbolSize: [42, 42] });
          } else if (status === 3) {
            l7 ++;
            (r1 || r10) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/orange1.png', symbolSize: [42, 42] })
          } else if (status === 7) {
            l8 ++;
            (r1 || r11) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/red1.png', symbolSize: [42, 42] })
          } else {
            l1 ++;
            (r1 || r12) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/yellow1.png', symbolSize: [42, 42] })
          }
        } else {
          l9 ++;
          r13 && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/gray1.png', symbolSize: [42, 42] })
        }
      }
      if(consum_type === 0) {
        (r0 || r15) && waitData.push({ symbol: pointsImages[0], symbolSize: [31, 40], value: [lng, lat], point_type, open_status, status, consum_type, time_status, type, ...item })
      }else{
        if(point_type === 1){
          (r0 || r16) && suspiciousData.push({symbol: pointsImages[consum_type][point_type], symbolSize: [31, 40], value: [lng, lat], point_type, open_status, status, consum_type, time_status, type, ...item });
        }
        else if(consum_type && point_type && time_status){
          const point = { symbol: pointsImages[consum_type][point_type][time_status], symbolSize: imagesSize, point_type, open_status, status, value: [lng, lat], consum_type, time_status, type, ...item }
          switch (point_type) {
            // case 1: (r0 || r16) && suspiciousData.push(point); break;
            case 2: (r0 || build === 0) ? pointsData.push(point) : build === consum_type ? pointsData.push(point) : null;break;
            case 3: (r0 || sand === 0) ? sandData.push(point) : sand === consum_type ? sandData.push(point) : null; break;
            case 4: (r0 || consume === 0) ? consumeData.push(point) : consume === consum_type ? consumeData.push(point) : null; break;
            case 5: (r0 || concert === 0) ? concertData.push(point) : concert === consum_type ? concertData.push(point) : null; break;
            case 6: (r0 || r17) && otherData.push({ ...point, symbolSize: [31, 40] }); break;
            case 7: (r0 || factory === 0) ? factoryData.push(point) : factory === consum_type ? factoryData.push(point) : null; break;
          }
        }
      }
    });    
    return [pointsData, sandData, concertData, consumeData, waitData, suspiciousData, otherData, factoryData, l1, l6, l7, l8, l9]
  }

  const sourceListForStop = (color, data)  => {
    let sourceListArr = [];
    const [pointsData, sandData, concertData, consumeData, waitData, suspiciousData, otherData, factoryData] = instanceClassify(1, data);
    buildMapPoints = DuplicateRemoval(pointsData.slice(0, 2000));
    buildMapPoints2 = DuplicateRemoval(pointsData.slice(2000, 4000));
    buildMapPoints3 = DuplicateRemoval(pointsData.slice(4001, 6000));
    buildMapPoints4 = DuplicateRemoval(pointsData.slice(6001, 8000));
    sandMapPoints = DuplicateRemoval(sandData);
    consumeMapPoints = DuplicateRemoval(consumeData);
    concertMapPoints = DuplicateRemoval(concertData);
    waitMapPoints = DuplicateRemoval(waitData);
    suspiciousMapPoints = DuplicateRemoval(suspiciousData);
    otherMapPoints = DuplicateRemoval(otherData);
    factoryMapPoints = DuplicateRemoval(factoryData);

    sourceListArr.push(
      UseMapAnchorage({ data: buildMapPoints, symbol: 'image:///images/center/green1.png', name: 'forOverTicket' }),
      UseMapAnchorage({ data: sandMapPoints, symbol: 'image:///images/center/green1.png', name: 'forOverTicket' }),
      UseMapAnchorage({ data: concertMapPoints, symbol: 'image:///images/center/green1.png', name: 'forOverTicket' }),
      UseMapAnchorage({ data: consumeMapPoints, symbol: 'image:///images/center/green1.png', name: 'forOverTicket' }),
      UseMapAnchorage({ data: waitMapPoints, symbol: 'image:///images/center/green1.png', name: 'forOverTicket' }),
      UseMapAnchorage({ data: suspiciousMapPoints, symbol: 'image:///images/center/green1.png', name: 'forOverTicket' }),
      UseMapAnchorage({ data: otherMapPoints, symbol: 'image:///images/center/green1.png', name: 'forOverTicket' }),
      UseMapAnchorage({ data: buildMapPoints2, symbol: 'image:///images/center/green1.png', name: 'forOverTicket' }),
      UseMapAnchorage({ data: buildMapPoints3, symbol: 'image:///images/center/green1.png', name: 'forOverTicket' }),
      UseMapAnchorage({ data: buildMapPoints4, symbol: 'image:///images/center/green1.png', name: 'forOverTicket' }),
    );
    return sourceListArr
  };

  const simplePass = ({ name = 'ticketLines', data, label = {
    show: true,
    position: 'middle',
    backgroundColor: "#fff",
    borderRadius: 4,
    padding: [2, 4],
    formatter: (a) => {
      return `趟数:  ${a.data.num} 方量: ${a.data.volumn}`
    }
  }, lineColor, effectSymbol, tooltip = {
    formatter: function (a) {
      return '起点: ' + a.data.start_name + '</br>终点: ' + a.data.end_name + '</br>趟数: ' + a.data.num + '</br>方量: ' + a.data.volumn
    }
  }}) => {
    return UseMapLinesTooltip({
      name,
      polyline: false,
      trailLength: 0.1,
      silent: false,
      symbol: ['none', 'arrow'],
      effect: true,
      lineColor,
      lineWidth: 2,
      curveness: 0.5,
      label,
      effectSymbol: 'circle',
      effectSymbolSize: 1.5,
      constantSpeed: 20,
      data,
      tooltip,
      zlevel: 5
    })
  };

  const returnLineWidth = (num) => {
    return num < 10 ? 1 : num >= 10 && num < 20 ? 2 : num >= 20 && num < 30 ? 3 : num >= 40 && num < 50 ? 4 : 5;
  };

  useEffect(() => {
    if (stopOrList) {
      setAbnormalData(abnormalList)
    }
    else {
      if (abnormalList.list.length === 0 || abnormalList.list.length < 10) {
        setMoreVisible(false)
      } else {
        setMoreVisible(true)
      }
      if (page === 1) {
        setAbnormalData(abnormalList.list);
        setSummary(abnormalList.summary)
      }
      else {
        setAbnormalData(abnormalList.list)
      }
    }
  }, [abnormalList])

  const sourceList = (color, data, linesFlag) => {
    let sourceListArr = [], pointsData = [], sandData = [], concertData = [], consumeData = [], waitData = [], suspiciousData = [], otherData = [], factoryData = [], l1 = 0, l6 = 0, l7 = 0, l8 = 0, l9 = 0;
    const build = pointsParams.build, sand = pointsParams.sand, consume = pointsParams.consume, concert = pointsParams.concert, factory = pointsParams.factory;
    if (linesFlag) {
      const r0 = isInArray(right_flag, 0), r1 = isInArray(right_flag, 1), r9 = isInArray(right_flag, 9),
        r10 = isInArray(right_flag, 10), r11 = isInArray(right_flag, 11), r12 = isInArray(right_flag, 12),
        r13 = isInArray(right_flag, 13), r15 = isInArray(right_flag, 15), r16 = isInArray(right_flag, 16),
        r17 = isInArray(right_flag, 17);
      const {start_id, start_department_id, end_id, end_department_id} = linesSource;
      data.map(({ consum_type, time_status, point_type, open_status, status, type, lng, lat, ...item }, i) => {
        //  consum_type 0 - 待排查 1 - 非法点位 2 - 非违规区域
        //  point_type 点位特征值 1 - 疑似点位 2 - 工地 3 - 砂石厂 4 - 消纳厂 5 - 商砼站 6 - 其他
        //  time_status 1 - 无时间期限 2 - 有时间期限 3 - 超时间期限
        if((item.id === start_id && item.department_id === start_department_id) || (item.id === end_id && item.department_id === end_department_id)){
          if(point_type === 2){
            if (open_status === 1) {
              if (status === 2) {
                l6 ++;
                (r1 || r9) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/green1.png', symbolSize: [42, 42] });
              } else if (status === 3) {
                l7 ++;
                (r1 || r10) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/orange1.png', symbolSize: [42, 42] })
              } else if (status === 7) {
                l8 ++;
                (r1 || r11) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/red1.png', symbolSize: [42, 42] })
              } else {
                l1 ++;
                (r1 || r12) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/yellow1.png', symbolSize: [42, 42] })
              }
            } else {
              l9 ++;
              r13 && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/gray1.png', symbolSize: [42, 42] })
            }
          }
          if(consum_type === 0) {
            (r0 || r15) && waitData.push({ symbol: pointsImages[0], symbolSize: [31, 40], value: [lng, lat], point_type, open_status, status, consum_type, time_status, type, ...item })
          }else{
            if(consum_type && point_type && time_status){
              const point = { symbol: pointsImages[consum_type][point_type][time_status], symbolSize: imagesSize, point_type, open_status, status, value: [lng, lat], consum_type, time_status, type, ...item }
              switch (point_type) {
                case 1: (r0 || r16)  && suspiciousData.push(point); break;
                case 2: (r0 || build === 0) ? pointsData.push(point) : build === consum_type ? pointsData.push(point) : null; break;
                case 3: (r0 || sand === 0) ? sandData.push(point) : sand === consum_type ? pointsData.push(point) : null; break;
                case 4: (r0 || consume === 0) ? consumeData.push(point) : consume === consum_type ? pointsData.push(point) : null; break;
                case 5: (r0 || concert === 0) ? concertData.push(point) : concert === consum_type ? concertData.push(point) : null; break;
                case 6: (r0 || r17) && otherData.push({ ...point, symbolSize: [31, 40] }); break;
                case 7: (r0 || factory === 0) ? factoryData.push(point) : factory === consum_type ? factoryData.push(point) : null; break;
              }
            }
          }
        }
      });
    } else {
      [pointsData, sandData, concertData, consumeData, waitData, suspiciousData, otherData, factoryData, l1, l6, l7, l8, l9] = instanceClassify(0, data);
      setCountBuild([(l1 + l6 + l7 + l8), l6, l7, l8, l1, l9])
    }
    // pointsData = pointsData.map(({ name, ...rest }) => name.includes('春溪') ? ({ name, ...rest }) : false).filter((item) => item)
    sourceListArr.push(
      UseMapAnchorage({ data: DuplicateRemoval(pointsData.slice(0, 2000)), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(sandData), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(concertData), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(consumeData), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(waitData), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(suspiciousData), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(otherData), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(pointsData.slice(2000, 4000)), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(pointsData.slice(4001, 6000)), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(pointsData.slice(6001, 8000)), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(factoryData), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
    );
    return sourceListArr
  };

  const [trashLoading, setTrashLoading] = useState(false);
  function getTicketDetail(id){
    setTrashLoading(true)
    request('web/homemap/ticketDetail', {id: id, area_id, area_name})
      .then(body => { setTrashDetailData(body.data.data); })
      .finally(() => {
        setTrashLoading(false);
        setTrashShowDetail(true);
      })
  }
  const [ticketTimes, setTicketTimes] = useState([]);
  const [mergeTicket, setMergeTicket] = useState(); // 判断是否合并联单
  const [splitTicket, setSplitTicket] = useState(); //判断是否拆分联单
  const [splitTicketnumbers, setSplitTicketNumbers] = useState([]); //存储拆分后的联单，做一个拆分标识
  const [changePoint, setChangePoint] = useState(false); // 是否起终点调换
  const [ticketCarNum, setTicketCarNum] = useState(0);
  const [ticketStartTime, setTicketStartTime] = useState('');
  const [unableVerify, setUnableVerify] = useState();
  function getTicketTimes(car_num, ticket_date){
    request('/web/ticket_map/getTicketTimes', {car_num, ticket_date})
      .then((body) => body.data.data)
      .then(setTicketTimes)
  }
  function getOtherTicketLines(id){
    if(id !== ticketId){
      setTicketId(id);
      getTicketLine(id)
    }
  }

 /** 车载轨迹包含的数据 */
 const [initialCarLine] = useState({bind_lines: [],
  end_point: [],
  lines: [],
  points: [],
  start_point: []})
// 获取联单轨迹 - 联单本身有起终点
function getTicketLine(id) {
  request('/web/ticket_map/getTicketLine', {ticket_id: id, hour: 0}).then(body => {
    const data = body.data.data;
    // setVisible(true);
    ticket_status = data.ticket_status ? data.ticket_status : 1;
    const plan_line = data.plan_line;
    if(plan_line.length === 0) message.warn('暂无规划路线');
    const status = data.status;
    const arr = data.ticket_lines.map(({time}) => time);
    if (data.ticket_lines.length === 0 && data.stop_points.length === 0) {
      message.warn('暂无联单路线');
    }
    const stop = filterStopPoints(data.stop_points || [], '#EB3873');
    const btn = { ...trailBtn,
      ticket_id: id,
      start_point: data.start_point,
      end_point: data.end_point,
      plan_line,
      visible: true,
      stopPoints: stop,
      slider: arr,
      initialSlider: arr,
      lastSlider: [],
      nextSlider: [],
      ticket_lines: data,
      totalLines: data,
      nextLines: { ticket_lines: [], stop_points: [], bind_lines: [] },
      lastLines: { ticket_lines: [], stop_points: [], bind_lines: [] },
      carDeviceLine: data.carDeviceLine,
      nextCarLine: initialCarLine,
      lastCarLine: initialCarLine,
    };
    if(status === 0) {
      setTrailBtn({ ...btn }); setHour(); setLastHour();
    }else if (status === 1)  {
      setHour(1);
      getExtraTicketLine(id, 1, btn);
    } else if (status === 2) {
      setLastHour(-1);
      getExtraTicketLine(id, -1, btn);
    }
  });
}

// 获取联单轨迹 - 联单只有起点或终点，有hour; hour > 0  只有起点  hour === next - 取消后n个小时的选择， hour < 0  只有终点 hour === last 取消前n个小时的选择
function getExtraTicketLine(ticket_id, hour, btn) {
  const b = btn ? btn : trailBtn;
  if(typeof hour === 'number') {
    request('/web/ticket_map/getTicketLine', {ticket_id, hour})
      .then(body => {
        const data = body.data.data;
        const arr = data.ticket_lines.map(({time}) => time);
        let params = {};
        if(hour > 0) { //
          if (data.ticket_lines.length === 0 && data.stop_points.length === 0) {
            message.warn(`未找到${hour}小时后的联单路线`);
          }
          const s = b.lastSlider.concat(b.initialSlider.concat(arr));
          const tt = b.lastLines.ticket_lines.concat(b.ticket_lines.ticket_lines.concat(data.ticket_lines));
          const ss =  b.lastLines.stop_points.concat(b.ticket_lines.stop_points.concat(data.stop_points));
          const bb =  b.lastLines.bind_lines.concat(b.ticket_lines.bind_lines.concat(data.bind_line));
          params = { ...b, visible: true, slider: s, nextSlider: arr, totalLines: { ticket_lines: tt, stop_points: ss, bind_line: bb }, nextLines: data, nextCarLine: data.carDeviceLine, };
        } else if (hour < 0) {
          if (data.ticket_lines.length === 0 && data.stop_points.length === 0) {
            message.warn(`未找到${Math.abs(hour)}小时前的联单路线`);
          }
          const s = arr.concat(b.initialSlider.concat(b.nextSlider));
          const tt = data.ticket_lines.concat(b.ticket_lines.ticket_lines.concat(b.nextLines.ticket_lines));
          const ss = data.stop_points.concat(b.ticket_lines.stop_points.concat(b.nextLines.stop_points));
          const bb =  data.bind_lines.concat(b.ticket_lines.bind_lines.concat(b.nextLines.bind_line));
          params = { ...b, visible: true, slider: s, lastSlider: arr, totalLines: { ticket_lines: tt, stop_points: ss, bind_line: bb }, lastLines: data, lastCarLine: data.carDeviceLine, };
        }
        const lastStop = filterStopPoints(params.lastLines.stop_points || [], '#333333');
        const stop = filterStopPoints(params.ticket_lines.stop_points || [], '#EB3873');
        const nextStop = filterStopPoints(params.nextLines.stop_points || [], '#1491FB');
        const stopPoints = lastStop.concat(stop.concat(nextStop));
        setTrailBtn({ ...params, ticket_id: ticket_id, start_point: data.start_point, end_point: data.end_point, stopPoints });
      });
  } else {
    let params = {};
    if (hour === 'last') { // 小于
      const s = b.initialSlider.concat(b.nextSlider);
      const tt = b.ticket_lines.ticket_lines.concat(b.nextLines.ticket_lines);
      const ss = b.ticket_lines.stop_points.concat(b.nextLines.stop_points);
      const bb =  b.ticket_lines.bind_lines.concat(b.nextLines.bind_line);
      params = { ...b, visible: true, slider: s, lastSlider: [], totalLines: { ticket_lines: tt, stop_points: ss, bind_line: bb }, lastLines: { ticket_lines: [], stop_points: [], bind_lines: [] }, lastCarLine: initialCarLine };
    }
    else if (hour === 'next') {
      const s = b.lastSlider.concat(b.initialSlider);
      const tt = b.lastLines.ticket_lines.concat(b.ticket_lines.ticket_lines);
      const ss =  b.lastLines.stop_points.concat(b.lastLines.stop_points);
      const bb =  b.lastLines.bind_lines.concat(b.lastLines.bind_lines);
      params = { ...b, visible: true, slider: s, nextSlider: [], totalLines: { ticket_lines: tt, stop_points: ss, bind_line: bb }, nextLines: { ticket_lines: [], stop_points: [], bind_lines: [] }, nextCarLine: initialCarLine };
    }
    const lastStop = filterStopPoints(params.lastLines.stop_points || [], '#333333');
    const stop = filterStopPoints(params.ticket_lines.stop_points || [], '#EB3873');
    const nextStop = filterStopPoints(params.nextLines.stop_points || [], '#1491FB');
    const stopPoints = lastStop.concat(stop.concat(nextStop));
    setTrailBtn({ ...params, ticket_id: ticket_id, stopPoints });
  }
}
  // 点击轨迹时判断联单状态
  const [isAcquirer, setIsAcquirer] = useState(); // 记录联单的状态 1=>补录联单 2=>已确认收货联单 0=>可以操作
  function getTicketIsAcquirer(id) {
    request('/web/ticket/isAcquirer', { id })
      .then((body) => body.data.data)
      .then(({ status }) => {
        if(status === 1) {
          message.error('补录联单')
        } else if(status === 2) {
          message.success('已签收联单');
        }
        setIsAcquirer(status);
      })
  }
// 判断联单是否有起始点
  const [ticketStatus, setTicketStatus] = useState(0); // 0 - 有起始点 1 - 只有起点 - hour 为1小时   2 - 只有终点 lastHour为-1
  function JudgeTicketStatus(id) {
    request('/web/ticket/canChangePoint', { id })
      .then((body) => body.data.data)
      .then(({ status }) => {
        setTicketStatus(status);
      })
  }

  function showTicketLine({ id, car_num, account_source, start_time }) {
    setTicketId(id);
    setStopPoint();
    setTicketCarNum(car_num);
    setAccountSource(account_source);
    setTicketStartTime(start_time && start_time.split(' ')[0]);
    JudgeTicketStatus(id);
    getTicketLine(id);
    getTicketTimes(car_num, start_time && start_time.split(' ')[0]);
    getTicketIsAcquirer(id);
    getTicketDetail(id)
    if(trash_show_detail) {getTicketDetail(id)}
  }

  const consumType = ['待排查', '非法点位', '非违规区域'];
  const [consumColor, setConsumColor] = useState('');

  // 第一屏 右下侧 工地 消纳场 商砼 砂石场 的选择与否
  const [pointsParams, setPointsParams] = useState({build: 10, sand: 10, concert: 10, consume: 10, factory: 10});
  // 第二屏 右下侧 工地 消纳场 商砼 砂石场 的选择与否
  const [trailParams, setTrailParams] =  useState({build: 10, sand: 10, concert: 10, consume: 10, factory: 10});
  const pointsClass = ['point0', 'point1', 'point2', 'point3', '', '', '', '', '', '', '', ''];
  function cancelRightType(siteType, newType){
    newType.remove(0);
    siteType ? setRightFlag1(newType) : setRightFlag(newType)
  }

  function pointFilter(siteType, pointType, type){
    // siteType 0 - 第一屏  1 - 第二屏
    // pointType 1 - 工地 2 - 砂石场 3 - 商砼 4 - 消纳场  18 - 加工厂
    // type 0 - 全部 1 - 非法点位 2 - 非违规区域
    let newType = [...right_flag];
    switch (pointType) {
      case 1: {
        if(siteType === 0){
          setPointsParams({ ...pointsParams, build: type });
          if(type !== 10){
            newType.remove(0);
            newType.remove(1);
            newType.remove(9);
            newType.remove(10);
            newType.remove(11);
            newType.remove(12);
            newType.remove(13);
            setRightFlag(newType)
          }
        }else{
          setTrailParams({ ...trailParams, build: type });
          let newType = [...right_flag1];
          if(type !== 10){
            newType.remove(0);
            newType.remove(1);
            newType.remove(9);
            newType.remove(10);
            newType.remove(11);
            newType.remove(12);
            newType.remove(13);
            setRightFlag1(newType)
          }
        }
      } break;
      case 2: cancelRightType(siteType, newType); siteType ? setTrailParams({ ...trailParams, sand: type }) : setPointsParams({ ...pointsParams, sand: type }); break;
      case 3: cancelRightType(siteType, newType); siteType ? setTrailParams({ ...trailParams, concert: type }) : setPointsParams({ ...pointsParams, concert: type }); break;
      case 4: cancelRightType(siteType, newType); siteType ? setTrailParams({ ...trailParams, consume: type }) : setPointsParams({ ...pointsParams, consume: type }); break;
      case 18: cancelRightType(siteType, newType); siteType ? setTrailParams({ ...trailParams, factory: type }) : setPointsParams({ ...pointsParams, factory: type }); break;
      default: cancelRightType(siteType, newType); siteType ? setTrailParams({ ...trailParams }) : setPointsParams({ ...pointsParams }); break;
    }
  }

  useEffect(() => {}, [ticketList, trash_show_detail, ticketSeries, call_driver, dataSource, abnormalData]);
  useEffect(() => {
    if(!trailBtn.visible) {
      setTicketSeries();
    } else {
      if (trailBtn.totalLines) {
        // 联单本身的轨迹
        const ticket_lines = trailBtn.ticket_lines.ticket_lines;
        const stop_points = trailBtn.ticket_lines.stop_points;
        const bind_lines = trailBtn.ticket_lines.bind_lines;
        // 联单获取到后n个小时的轨迹
        const next_lines = trailBtn.nextLines.ticket_lines;
        const next_stop = trailBtn.nextLines.stop_points;
        const next_bind = trailBtn.nextLines.bind_lines;
        // 联单获取到前n个小时
        const last_lines = trailBtn.lastLines.ticket_lines;
        const last_stop = trailBtn.lastLines.stop_points;
        const last_bind = trailBtn.lastLines.bind_lines;
        const { rectify, show_points, show_stops, play_begin, movePos, totalLines, plan_line } = trailBtn;
        let series_data = [];
        const nearPoints = coordObjectsToArraysByTrans(totalLines.ticket_lines);
        const diff = 0.005;
        let minLng = 180, maxLng = 0, minLat = 90, maxLat = 0;
        let polygons = null; // 存储固定源的电子围栏数据，要将其添加到series最后面，以免框选出错
        // 获取轨迹的最大最小经纬度
        nearPoints.forEach(([lng, lat]) => {
          minLng = Math.min(lng, minLng);
          maxLng = Math.max(lng, maxLng);
          minLat = Math.min(lat, minLat);
          maxLat = Math.max(lat, maxLat);
        });
        const nearPaths = (nearPoints.length > 0 && maxLng !== minLng && maxLat !== minLat) ?
          [[minLng - diff, minLat - diff], [minLng - diff, maxLat + diff], [maxLng + diff, maxLat + diff], [maxLng + diff, minLat - diff]] : false;
        // const nearPath = [
        //   new BMap.Point(minLng - diff, minLat - diff),
        //   new BMap.Point(minLng - diff, maxLat + diff),
        //   new BMap.Point(maxLng + diff, maxLat + diff),
        //   new BMap.Point(maxLng + diff, minLat - diff),
        // ];        // const ply = new BMap.Polygon(nearPath);
        if (plans.sourceList.length > 0) {
          const source = !near ? plans.sourceList : plans.sourceList.map(({ lng, lat, ...rest }) => {
            // const pt = new BMap.Point(lng, lat);
            const result = nearPaths ? AMap.GeometryUtil.isPointInRing([Number(lng), Number(lat)], nearPaths) : true;
            return result ? { lng, lat, ...rest } : false
          }).filter((item) => item);
          const paths = source.map(({ polygon }) => {
            if (polygon && polygon.length > 0) {
              const pol = polygon.map(({ lng, lat }) => [lng, lat]);
              pol.push([polygon[0].lng, polygon[0].lat]);
              return pol;
            } else return false;
          }).filter((item) => item);
          const allPath = paths.map((polygon) => ({ coords: polygon }))
          polygons = UseMapLines({
            data: allPath, lineColor: '#707070', lineWidth: 2
          });
          series_data = sourceListForStop('#22B74F', source)
        }
        
        if(totalLines.ticket_lines.length !== 0 || totalLines.stop_points.length !== 0){
          // 显示纠偏轨迹 ？ 原始轨迹 - 联单本身的轨迹
          const b1 = bind_lines.length > 1;
          const b2 = ticket_lines.length > 1;
          const lineColor = ticketStatus === 2 ? '#333' : ticketStatus === 1 ? '#1491fb' : rectify ? '#ff0000' : '#808080';
          const trailData = rectify ? [{ coords: bind_lines.length === 0 ? [[],[]] : b1 ? coordObjectsToArrays(bind_lines || []) : [[bind_lines[0].lng, bind_lines[0].lat], [bind_lines[0].lng, bind_lines[0].lat]] }]
            : [{ coords: ticket_lines.length === 0 ? [[],[]] : b2 ? coordObjectsToArraysByTrans(ticket_lines || []) : [h_common.GPS_transformation(ticket_lines[0].lat, ticket_lines[0].lng), h_common.GPS_transformation(ticket_lines[0].lat, ticket_lines[0].lng)] }];
          series_data.push(UseMapLines({polyline: true, silent: true, lineWidth: 4, lineColor, data: trailData}));
          //是否显示原始轨迹点
          series_data.push(UseMapScatter({
            data: coordObjectsToArraysByPoints(trailBtn.totalLines.ticket_lines || []),
            zlevel: 8,
            symbolSize: [10, 10],
            name: 'carPoints',
            tooltip: {
              formatter: function (a) {
                return a.data.time + '<br />' + a.data.speed + 'km/h';
              }
            },
            color: "#5D59E8",
            opacity: show_points ? 0.8 : 0
          }));
          //是否显示停靠点
          series_data.push(UseMapScatter({
            data: trailBtn.stopPoints,
            zlevel: 8,
            symbolSize: 15,
            name: 'clickOverTicket',
            tooltip: {
              formatter: function (a) {
                return show_stops ? a.data.time + '\n停靠：' + a.data.stay_time + '分钟' : null;
              }
            },
            color: "#1491FB",
            opacity: show_stops ? 1 : 0,
            label: {
              show: true,
              position: 'top',
              backgroundColor: 'rgba(255, 255, 255, .85)',
              padding: [2, 5],
              formatter: function (a) {
                return a.data.time + '\n停靠：' + a.data.stay_time + '分钟'
              }
            }
          }));
          
          if(next_lines.length !== 0 || next_stop.length !== 0) {
            const f1 = next_bind.length > 1;
            const f2 = next_lines.length > 1;
            const trailData = rectify ? [{ coords: f1 ? coordObjectsToArrays(next_bind || []) : [[next_bind[0].lng, next_bind[0].lat], [next_bind[0].lng, next_bind[0].lat]] }]
              : [{ coords: f2 ? coordObjectsToArraysByTrans(next_lines || []) : [h_common.GPS_transformation(next_lines[0].lat, next_lines[0].lng), h_common.GPS_transformation(next_lines[0].lat, next_lines[0].lng)] }];
            series_data.push(UseMapLines({polyline: true, silent: true, lineWidth: 4, lineColor: '#1491fb', data: trailData}));
          }
          if(last_lines.length !== 0 || last_stop.length !== 0) {
            const f1 = last_bind.length > 1;
            const f2 = last_lines.length > 1;
            const trailData = rectify ? [{ coords: f1 ? coordObjectsToArrays(last_bind || []) : [[last_bind[0].lng, last_bind[0].lat], [last_bind[0].lng, last_bind[0].lat]] }]
              : [{ coords: f2 ? coordObjectsToArraysByTrans(last_lines || []) : [h_common.GPS_transformation(last_lines[0].lat, last_lines[0].lng), h_common.GPS_transformation(last_lines[0].lat, last_lines[0].lng)] }];
            series_data.push(UseMapLines({polyline: true, silent: true, lineWidth: 4, lineColor: '#333333', data: trailData}));
          }
          play_begin && series_data.push(UseMapScatter({
            name: 'currscatter',
            data: [{
              time: trailBtn.totalLines.ticket_lines[movePos].time,
              direction: trailBtn.totalLines.ticket_lines[movePos].direction,
              speed: trailBtn.totalLines.ticket_lines[movePos].speed,
              value: h_common.GPS_transformation(trailBtn.totalLines.ticket_lines[movePos].lat, trailBtn.totalLines.ticket_lines[movePos].lng)
            }],
            symbol: 'image:///images/center/a2.png',
            symbolSize: [15, 42.5],
            symbolRotate: (360 - trailBtn.totalLines.ticket_lines[movePos].direction),
            zlevel: 8,
            tooltip: {
              formatter: function (a) {
                return a.name;
              }
            },
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: function (a) {
                  return a.data.time + '\n' + a.data.speed + 'km/h';
                },
                backgroundColor: '#fff',
                padding: 5

              }
            },
          }));
          if (ticket_lines.length > 0) {
            series_data.push(StartOrEndPoint({
              name: 'start_pointer',
              symbol: 'circle',
              data: [{ name: '起点',value: h_common.GPS_transformation(ticket_lines[0].lat, ticket_lines[0].lng) }],
              format: '起点',
              backImage: '/images/center/lvse.png',
              itemColor: '#00CF88',
              opacity: ticketStatus === 2 ? 0 : 1,
            }));
            series_data.push(StartOrEndPoint({
              name: 'end_pointer',
              symbol: 'circle',
              data: [{ name: '终点', value: h_common.GPS_transformation(ticket_lines[ticket_lines.length - 1].lat, ticket_lines[ticket_lines.length - 1].lng) }],
              format: '终点',
              backImage: '/images/center/chengse.png',
              itemColor: '#FF9316',
              opacity: ticketStatus === 1 ? 0 : 1,
            }));
          }
          if (last_lines.length > 0) {
            series_data.push(StartOrEndPoint({
              name: 'begin_pointer',
              symbol: 'circle',
              data: [{ name: '开始',value: h_common.GPS_transformation(last_lines[0].lat, last_lines[0].lng) }],
              format: '开始',
              backImage: '/images/center/heise.png',
              itemColor: '#333333'
            }));
          }
          if (next_lines.length > 0) {
            series_data.push(StartOrEndPoint({
              name: 'finish_pointer',
              symbol: 'circle',
              data: [{ name: '结束',value: h_common.GPS_transformation(next_lines[next_lines.length - 1].lat, next_lines[next_lines.length - 1].lng) }],
              format: '结束',
              backImage: '/images/center/lanse.png',
              itemColor: '#1491fb'
            }));
          }
          if (play_begin) {
            setCenterFlag(false);
            let m_c = h_common.GPS_transformation(totalLines.ticket_lines[movePos].lat, totalLines.ticket_lines[movePos].lng);
            if (bound.containsPoint(new BMap.Point(m_c[0], m_c[1])) !== true) {
              setCenter(m_c);
              setCenterFlag(true);
            }
          } else {
            setCenterFlag(false);
            if (totalLines.ticket_lines.length > 0) {
              setCenter(h_common.GPS_transformation(totalLines.ticket_lines[0].lat, totalLines.ticket_lines[0].lng));
            }
          }
        }
        /** 显示规划路线  */
        if(plan_line && plan_line.length > 1) {
          const planData = coordObjectsToArraysByTrans(plan_line)
          series_data.push(UseMapLines({polyline: true, silent: true, lineWidth: 4, lineColor: '#26C26E', data: [{coords: planData}]}));
        }
        // 装载点
        if (trailBtn.start_point) {
          series_data.push(StartOrEndPoint({
            name: 'icons_pointer',
            symbol: 'circle',
            data: [{ name: '装载点', type: '装载点', ticket_id: trailBtn.ticket_id, time: trailBtn.start_point.time, value: h_common.GPS_transformation(trailBtn.start_point.lat, trailBtn.start_point.lng) }],
            format: '',
            backImage: '/images/center/装.png',
            itemColor: '#FF9316',
            opacity: 1,
            width: 30,
            height: 36
          }));
        }
        // 卸货点
        if (trailBtn.end_point) {
          series_data.push(StartOrEndPoint({
            name: 'icons_pointer',
            symbol: 'circle',
            data: [{ name: '卸货点', type: '卸货点', ticket_id: trailBtn.ticket_id, time: trailBtn.end_point.time, value: h_common.GPS_transformation(trailBtn.end_point.lat, trailBtn.end_point.lng) }],
            format: '',
            backImage: '/images/center/卸.png',
            itemColor: '#FF9316',
            opacity: 1,
            width: 30,
            height: 36
          }));
        }
        if (newConsum) {
          series_data.push(UseMapInstance({
            symbol: 'image:///images/center/gray4.png',
            symbolSize: [34, 46],
            zlevel: 7,
            name: 'newConsum',
            offsetArr: [-1, -5],
            label: '疑',
            color: '#1491fb',
            tooltip: {
              formatter: '{b}'
            },
            data: [{
              name: newConsum.name,
              id: newConsum.id,
              department_id: newConsum.department_id,
              value: [newConsum.lng, newConsum.lat]
            }],
          }))
        }
        // 显示车载轨迹
        if (trailBtn.showCars) {
          const { carDeviceLine, nextCarLine, lastCarLine } = trailBtn;
          // 车载轨迹
          const carLines = lastCarLine.bind_lines.concat(carDeviceLine.bind_lines).concat(nextCarLine.lines);
          // const carLines = carLine.map(({ lng, lat, ...rest }) => ({ }))
          if (carLines.length > 0) {
            // 车载变化点 "evVal": 1,// 1=> 空变重（装载） 2=>重变空 （卸货）
            const points = lastCarLine.points.concat(carDeviceLine.points).concat(nextCarLine.points);
            // 卸货点
            const xhPoints = points.map(({ evVal, ...rest }) => evVal === 2 ? ({ evVal, ...rest }) : false).filter((item) => item);
            // 装载点
            const zzPoints = points.map(({ evVal, ...rest }) => evVal === 1 ? ({ evVal, ...rest }) : false).filter((item) => item);

            const startPoint = carLines[0]; // 起点
            const endPoint = carLines[carLines.length - 1]; // 终点
            const planData = carLines.map(({ lng, lat }) => [lng, lat]);//coordObjectsToArraysByTrans(carLines);
            if (planData.length > 1) {
              series_data.push(UseMapLines({polyline: true, silent: true, lineWidth: 4, lineColor: '#5E659C', data: [{coords: planData}]}));
            }
            // 卸货
            series_data.push(UseMapScatter({
              data: xhPoints.map(({lng, lat, ...data}) => ({value: h_common.GPS_transformation(lat, lng), ...data})),
              name: 'xiehuo',symbolSize: 20, color: '#5E659C',zlevel: 3,
              tooltip:{
                formatter:function(a,b,c){
                  return '卸货点：' + a.data.time;
                }
              },
              type: 'scatter',
            }));
            // 装载
            series_data.push(UseMapScatter({
              data: zzPoints.map(({lng, lat, ...data}) => ({value: h_common.GPS_transformation(lat, lng), ...data})),
              name: 'xiehuo',symbolSize: 20, color: '#15CEBD',zlevel: 3,
              tooltip:{
                formatter:function(a,b,c){
                  return '装载点：' + a.data.time;
                }
              },
              type: 'scatter',
            }));
            series_data.push(StartOrEndPoint({
              name: 'begin_pointer',
              symbol: 'circle',
              data: [{ name: '开始',value: [startPoint.lng, startPoint.lat] }],
              format: '',
              width: 28,
              height: 16,
              backImage: '/images/center/zs.png',
              itemColor: '#5E659C'
            }));            
           
            series_data.push(StartOrEndPoint({
              name: 'finish_pointer',
              symbol: 'circle',
              data: [{ name: '结束',value: [endPoint.lng, endPoint.lat] }],
              format: '',
              width: 28,
              height: 16,
              backImage: '/images/center/zj.png',
              itemColor: '#5E659C'
            }));            
          } else {
            message.warn('当前无车载轨迹');
          }
        }
        // 规划线路终点
        if (plan_line && plan_line.length > 1) {
          const po = plan_line[plan_line.length - 1];
          series_data.push(StartOrEndPoint({
            name: 'icons_pointer',
            symbol: 'circle',
            data: [{ name: '规划线路终点', type: '装载点', value: h_common.GPS_transformation(po.lat, po.lng) }],
            format: '',
            backImage: '/images/center/组1515.png',
            itemColor: '#FF9316',
            opacity: 1,
            width: 40,
            height: 40
          }));
        }
        if (polygons) {
          series_data.push(polygons);
        }
        setTicketSeries([...series_data])
      }
    }
  }, [JSON.stringify(trailBtn), newConsum, right_flag1, near, JSON.stringify(trailParams)]);

  // useEffect(() => {
  // }, [play_begin, movePos, environment]);

  useEffect(() => {
  }, [environment]);

  useInterval(() => {
    if(trailBtn.movePos === trailBtn.slider.length - 1) {
      setTrailBtn((btn) => ({ ...btn, play_begin: false, movePos: (btn.movePos + 1), playIndex: (btn.movePos + 1)}))

    } else {
      setTrailBtn((btn) => ({ ...btn, movePos: (btn.movePos + 1), playIndex: (btn.movePos + 1)}))
    }
  }, (trailBtn.play_begin && (trailBtn.movePos !== trailBtn.slider.length - 1) && !trailBtn.play_end) ? trailBtn.moveSpeed : null);

  useEffect(() => {
    const webRTC1 = document.createElement('script');
    webRTC1.type = 'text/javascript';
    webRTC1.src = `/webim/webim.config.js`;
    document.head.appendChild(webRTC1);

    const webRTC2 = document.createElement('script');
    webRTC2.type = 'text/javascript';
    webRTC2.src = `/webim/strophe-1.2.8.min.js`;
    document.head.appendChild(webRTC2);

    const webRTC3 = document.createElement('script');
    webRTC3.type = 'text/javascript';
    webRTC3.src = `/webim/websdk-1.4.13.js`;
    document.head.appendChild(webRTC3);

    const webRTC4 = document.createElement('script');
    webRTC4.type = 'text/javascript';
    webRTC4.src = `/webim/webrtc/adapter.js`;
    document.head.appendChild(webRTC4);

    const webRTC5 = document.createElement('script');
    webRTC5.type = 'text/javascript';
    webRTC5.src = `/webim/webrtc/webrtc-1.4.13.js`;
    document.head.appendChild(webRTC5);

    const webRTC6 = document.createElement('script');
    webRTC6.type = 'text/javascript';
    webRTC6.src = `/webim/MediaStreamRecorder.js`;
    document.head.appendChild(webRTC6);
    request({
      url: 'web/web_rtc/register',
      data: {}
    }).then(res => {
    });
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `/LodopFuncs.js`;
    document.head.appendChild(script);
  }, []);

  // 疑似点位点击在地图上的小窗口显示
  const [pointMsg, setPointMsg] = useState();
  const [overPoints, setOverPoints] = useState([]); // 结束的固定源
  const [overTimes, setOverTimes] = useState([]); //结束的时间
  const [overByPoints, setOverByPoints] = useState();
  const [overByInstances, setOverByInstances] = useState();

  function getNewOverTicket(params) {
    const lines = trailBtn.totalLines;
    if(lines.ticket_lines.length === 0) return;
    const overTicketLines = coordObjectsToArraysByPoints(lines.ticket_lines || []);
    const overStopPoints = coordObjectsToArraysByTicket(lines.stop_points || []);
    const dataIndex0 = params.batch[0].selected[0].dataIndex; // 工地 0 - 2000
    const dataIndex1 = params.batch[0].selected[1].dataIndex; // 砂石场
    const dataIndex2 = params.batch[0].selected[2].dataIndex; // 商砼
    const dataIndex3 = params.batch[0].selected[3].dataIndex; // 消纳场
    const dataIndex4 = params.batch[0].selected[4].dataIndex; // 待排查
    const dataIndex5 = params.batch[0].selected[5].dataIndex; // 疑似点位
    const dataIndex6 = params.batch[0].selected[6].dataIndex; // 其他
    const dataIndex7 = params.batch[0].selected[7].dataIndex; // 工地 - 2000 - 4000
    const dataIndex10 = params.batch[0].selected[8].dataIndex; // 工地 - 4000 - 6000
    const dataIndex12 = params.batch[0].selected[9].dataIndex; // 工地 - 4000 - 6000
    const dataIndex11 = params.batch[0].selected[10].dataIndex; // 加工厂
    const dataIndex8 = params.batch[0].selected[12].dataIndex; //轨迹点
    const dataIndex9 = params.batch[0].selected[13].dataIndex; //停靠点
    let arr = [], data0 = [], data1 = [], data2 = [], data3 = [], data4 = [], data5 = [], data6 = [], data7 = [], data10 = [], data11 = [], data12 = [];
    buildMapPoints.map((v) => data0.push({ ...v }));
    buildMapPoints2.map((v) => data7.push({ ...v }));
    buildMapPoints3.map((v) => data10.push({ ...v }));
    buildMapPoints4.map((v) => data12.push({ ...v }));
    sandMapPoints.map((v) => data1.push({ ...v }));
    concertMapPoints.map((v) => data2.push({ ...v }));
    consumeMapPoints.map((v) => data3.push({ ...v }));
    waitMapPoints.map((v) => data4.push({ ...v }));
    suspiciousMapPoints.map((v) => data5.push({ ...v }));
    otherMapPoints.map((v) => data6.push({ ...v }));
    factoryMapPoints.map((v) => data11.push({ ...v }));
    dataIndex0.map(v => {arr.push(data0[v])});
    dataIndex1.map(v => {arr.push(data1[v])});
    dataIndex2.map(v => {arr.push(data2[v])});
    dataIndex3.map(v => {arr.push(data3[v])});
    dataIndex4.map(v => {arr.push(data4[v])});
    dataIndex5.map(v => {arr.push(data5[v])});
    dataIndex6.map(v => {arr.push(data6[v])});
    dataIndex7.map(v => {arr.push(data7[v])});
    dataIndex10.map(v => {arr.push(data10[v])});
    dataIndex12.map(v => {arr.push(data12[v])});
    dataIndex11.map(v => {arr.push(data11[v])});

    let overDates = [];
    if (trailBtn.show_points) {
      dataIndex8.map(v => {
        overDates.push(overTicketLines[v].time)
      })
    }
    if (trailBtn.show_stops) {
      dataIndex9.map(v => {
        overDates.push(overStopPoints[v].time)
      })
    }
    const m_overDates = Array.from(new Set(overDates));
    if (arr.length === 0) {
      // message.warn('请至少选择一个固定源以结束该联单');
    } else {
      if(isZx && isAcquirer !== 0) {
        confirm({
          title: '操作失败',
          content: `当前联单为${isAcquirer === 1 ? '补录联单' : '已签收联单'}，无法进行联单编辑`
        });
        return;
      }
      setOverPoints(arr);
      setOverTimes(m_overDates);
    }
  }

  function renderBrushed(params) {
    if (params.batch[0].selected[0].seriesName === 'forOverTicket') {
      getNewOverTicket(params);
    }
    else {
      let dataIndex0 = [], dataIndex3 = [];
      dataIndex0.concat(params.batch[0].selected[0].dataIndex).concat(params.batch[0].selected[1].dataIndex).concat(params.batch[0].selected[2].dataIndex).concat(params.batch[0].selected[3].dataIndex).concat(params.batch[0].selected[4].dataIndex);
      let dataIndex1 = params.batch[0].selected[5].dataIndex;
      let dataIndex2 = params.batch[0].selected[6].dataIndex;
      dataIndex3.concat(params.batch[0].selected[7].dataIndex).concat(params.batch[0].selected[8].dataIndex);
      let dataIndex = dataIndex0.concat(dataIndex1).concat(dataIndex2).concat(dataIndex3);
      let arr = [];
      let data0 = [], data1 = [], data2 = [], data3 = [];
      plansData.map((v) => {
        if (v.type === 'building') {
          data0.push(v)
        } else if (v.type === 'sand_factory') {
          data1.push(v)
        } else if (v.type === 'consum') {
          data3.push(v)
        } else {
          data2.push(v);
        }
      });
      if (dataIndex0.length > 0) {
        dataIndex0.map(v => {
          const id = data0[v].id;
          const department_id = data0[v].department_id;
          plansListData.map(({start_id, start_department_id, end_id, end_department_id, ...data}) => {
            if ((id === start_id && department_id === start_department_id) || (id === end_id && department_id === end_department_id)) {
              arr.push({start_id, start_department_id, end_id, end_department_id, ...data})
            }
          });
        });
      }
      if (dataIndex1.length > 0) {
        dataIndex1.map(v => {
          const id = data1[v].id;
          const department_id = data1[v].department_id;
          plansListData.map(({start_id, start_department_id, end_id, end_department_id, ...data}) => {
            if ((id === start_id && department_id === start_department_id) || (id === end_id && department_id === end_department_id)) {
              arr.push({start_id, start_department_id, end_id, end_department_id, ...data})
            }
          });
        });
      }
      if (dataIndex2.length > 0) {
        dataIndex2.map(v => {
          const id = data2[v].id;
          const department_id = data2[v].department_id;
          plansListData.map(({start_id, start_department_id, end_id, end_department_id, ...data}) => {
            if ((id === start_id && department_id === start_department_id) || (id === end_id && department_id === end_department_id)) {
              arr.push({start_id, start_department_id, end_id, end_department_id, ...data})
            }
          });
        });
      }
      if (dataIndex3.length > 0) {
        dataIndex3.map(v => {
          const id = data3[v].id;
          const department_id = data3[v].department_id;
          plansListData.map(({start_id, start_department_id, end_id, end_department_id, ...data}) => {
            if ((id === start_id && department_id === start_department_id) || (id === end_id && department_id === end_department_id)) {
              arr.push({start_id, start_department_id, end_id, end_department_id, ...data})
            }
          });
        });
      }
      if (dataIndex.length > 0) {
        setDataSource(arr)
      }
      else {
        setDataSource(plansListData);
      }
      const selected = params.batch[0].selected;
      let stopIndex = [];
      let elseIndex = [];
      selected.map(v => {
        if (v.seriesName === "plansStopPoints") {
          stopIndex = v.dataIndex;
        }
        else if (v.seriesName === 'plans_elseListPoint') {
          elseIndex = v.dataIndex
        }
      });
      if (stopIndex.length > 0) {
        const ticketIds = stopIndex.map(v => (plansStopData[v].ticket_id));
        setStopOrList(true);
        setTicketIds(ticketIds.join(','));
      }
      if (elseIndex.length > 0) {
        const plansElsePoint = elseIndex.map(v => (plansElseList[v].id));
      }
    }
  }

  //点击 plans.lines 与 plans.lists 进行匹配筛选
  function dressingListsBylines({l_start_id, l_start_department_id, l_end_id, l_end_department_id, ...data}) {
    setLinesSource({
      start_id: l_start_id,
      start_department_id: l_start_department_id,
      end_id: l_end_id,
      end_department_id: l_end_department_id, ...data
    });
    setLinesFlag(true);
    let arr = [];
    plansListData.map(({start_id, start_department_id, end_id, end_department_id, ...data}) => {
      if (l_start_id === start_id && l_start_department_id === start_department_id && l_end_id === end_id && l_end_department_id === l_end_department_id) {
        arr.push({start_id, start_department_id, end_id, end_department_id, ...data})
      }
    });
    setDataSource(arr);
    setVisibleTable(true)
  }

  // 点击 plans.sourceList 与 plans.lists 禁行匹配筛选
  function dressingListsBySourceList({id, department_id, b_type, ...data}) {
    let arr = [];
    plansListData.map(({start_id, start_department_id, end_id, end_department_id, ...data}) => {
      if ((id === start_id && department_id === start_department_id) || (id === end_id && department_id === end_department_id)) {
        arr.push({start_id, start_department_id, end_id, end_department_id, ...data})
      }
    });
    setDataSource(arr);
    setVisibleTable(true);
    if (b_type === 'consum') {
      setVisibleTitle('消纳场报表');
      setConcertData({name: data.name, address: data.address, images: data.images, videos: data.videos, flag: true});
    } else {
      setConcertData({flag: false})
      setVisibleTitle('接管报表');
    }
  }

  function showSuspectConsum(label, data, point) {
    setVisibleBuild(true);
    chartBuild = echarts.init(document.getElementById('consumChart'));
    useLine(chartBuild, label, {
      type: "line",
      data: data,
      lineStyle: {
        width: 3,
      }
    })
  }

  function useLine(chart, label, series) {
    const consumOption = {
      color: ['#1491FB'],
      tooltip: {
        trigger: 'axis',
        splitLine: {
          show: false
        }
      },
      xAxis: {
        type: 'category',
        data: label,
        axisLabel: {
          color: "#191D2D",
        },
        axisLine: {
          lineStyle: {
            color: "#E9E9E9"
          }
        },
      },
      yAxis: {
        type: 'value',
        inside: true,
        name: '车辆数',
        nameTextStyle: {
          color: '#191D2D'
        },
        splitLine: {
          lineStyle: {
            color: "#E9E9E9"
          }
        },
        axisLabel: {
          color: "#191D2D",
        },
        axisLine: {
          show: false,
          lineStyle: {
            width: 0,
            color: "#E9E9E9"
          }
        },
      },
      series: series
    };
    chart.setOption(consumOption, true);
  }
  const [extra, setExtra] = useState(true); // 每次状态变化，用以监听关闭编辑联单Modal的变化，以用来取消echart选框
  const [clickAlarm, setClickAlarm] = useState();
  // charts 的一些操作
  useEffect(() => {
    if (!isChartUsable()) return;
    chart.on('click', (data) => {
      if (data.seriesName === 'new_ticketPoint') { // 点击工地、消纳场、砂石场等弹出报表
        setPointMsg(data.data)
        // dressingListsBySourceList(data.data);
      }
      else if (data.seriesName === 'new_consum') {
        setPointMsg(data.data)
      }
      else if (data.seriesName === 'ticketLines') { // 点击plans.lines获取相关联的固定源
        dressingListsBylines(data.data);
      }
      else if (data.seriesName === 'plans_elseList') { // 点击plans.elseList获取该联单详情
        const id = data.data.id;
        setTicketId(id);
        getTicketDetail(id);
      }
      else if (data.seriesName === 'forOverTicket') { // 点击工地、砂石场、商砼、消纳厂 结束联单
        setOverByInstances(data.data)
      } // 点击固定源结束联单
      else if (data.seriesName === 'overTicket' || data.seriesName === 'carPoints' || data.seriesName === 'icons_pointer') {
        setOverByPoints(data.data)
      }//点击停靠点或原始轨迹点结束联单
      else if (data.seriesName === 'clickOverTicket') {
        setStopPoint(data.data.value);
      }//新需求 - 点击停靠点
      else if (data.seriesName === 'suspectConsum') { //点击黑工地出现折线图
        showSuspectConsum(data.data.label, data.data.car_num, data.data.point)
      }
      else if (data.seriesName === 'alarmPoints') {
        const d = data.data;
        setClickAlarm({id: d.id, status: d.status, dispatch_task_id: d.dispatch_task_id, notice_count: d.notice_count})
      }
    });
    chart.on('brushselected', renderBrushed);
    return () => {chart.off('click');chart.off('brushselected')};
  }, [isChartUsable, chart, ticketId, ticketTimes, buildMapPoints, JSON.stringify(trailBtn), isAcquirer]);

  function getTicketLinesVolumn(data) {
    let num = 0;
    data.map((v) => {
      if (v.volumn) {
        num += parseFloat(v.volumn)
      }
    });
    return num === 0 ? 0 : num.toFixed(1);
  }

  useEffect(() => {
    let new_series = [];
    let LinesSum = [];
    if (!grossCarOrMap.flag || grossCarOrMap.flag === 'car' || grossCarOrMap.flag === 'point') {
      setDataSource(plans.list);
      new_series = sourceList('#22B74F', plans.sourceList, linesFlag); // 获取地图上的点位显示与否
      const r0 = isInArray(right_flag, 0), r1 = isInArray(right_flag, 1), r4 = isInArray(right_flag, 4), r5 = isInArray(right_flag, 5),  r6 = isInArray(right_flag, 6)
      const t1 = isInArray(top_flag, 1), t2 = isInArray(top_flag, 2), t3 = isInArray(top_flag, 3), t4 = isInArray(top_flag, 4), t5 = isInArray(top_flag, 5);
      // 轨迹
      if (plans.stopPoints.length > 0 && (r6 || r0)) {
        if (t1) {
          const stopPoints = plans.stopPoints.map(({ lng, lat, ...d }) => ({ ...d, value: h_common.GPS_transformation(lat, lng) }))
          new_series.push(UseMapScatter({ color: '#fd322c', name: 'plansStopPoints', data: stopPoints, tooltip: { formatter: '停靠点' } }));
        }
      }
      // 线路
      if (plans.lines.length > 0 && (r5 || r0)) {
        let coords = [], coords_sand = [], ex_coords = [];
        let instanceData = [];
        plans.lines.map(({start_id, start_department_id, end_id, end_department_id, volumn, num, ...data}) => {
          const lines = {
            volumn: volumn,
            num: num,
            lineStyle: { width: returnLineWidth(num) },
            l_start_id: start_id,
            l_start_department_id: start_department_id,
            l_end_id: end_id,
            l_end_department_id: end_department_id,
            start_name: data.start_address.name,
            end_name: data.end_address.name,
            coords: [[data.start_address.lng, data.start_address.lat], [data.end_address.lng, data.end_address.lat]]
          };
          // 筛选线路
          if (data.end_address && data.end_address !== null ) { // 到砂石场
            data.end_address.type === 'sand_factory' ? coords_sand.push({ ...lines })
              : data.end_address.type !== 'consum' ? coords.push({ ...lines }) : ex_coords.push({ ...lines })
          }
          // 筛选工地
          if ((r5 || r0) && t2 && t5) {
            plans.sourceList.map(({id, department_id, lng, lat, type, open_status, status, consum_type, point_type, time_status, ...list}) => {
              if ((start_id === id && start_department_id === department_id) || (end_id === id && end_department_id === department_id)) {
                if(consum_type === 0){
                  instanceData.push({ symbol: pointsImages[0], symbolSize: [31, 40], id, department_id, value: [lng, lat], type, open_status, status, consum_type, point_type, time_status, ...list})
                }
                if(consum_type && point_type && time_status){
                  const point = {value: [lng, lat], id, department_id, type, status, open_status, consum_type, point_type, time_status, symbol: pointsImages[consum_type][point_type][time_status], symbolSize: imagesSize, ...list}
                  if(pointsParams.build === 10){
                    if(type === 'building'){
                      if (open_status === 1) {
                        if (status === 2) {
                          instanceData.push({ ...point, symbol: 'image:///images/center/green1.png', symbolSize: [42, 42] });
                        } else if (status === 3) {
                          instanceData.push({ ...point, symbol: 'image:///images/center/orange1.png', symbolSize: [42, 42] });
                        } else if (status === 7) {
                          instanceData.push({ ...point, symbol: 'image:///images/center/red1.png', symbolSize: [42, 42] })
                        } else {
                          instanceData.push({ ...point, symbol: 'image:///images/center/yellow1.png', symbolSize: [42, 42] })
                        }
                      }else {
                        instanceData.push({ ...point, symbol: 'image:///images/center/gray1.png', symbolSize: [42, 42] })
                      }
                    }
                    else{
                      instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
                    }
                  }else {
                    instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
                  }
                }
              }
            });
          } else if ((r5 || r0) && t2 && !t5) {
            if (data.end_address.type !== 'sand_factory' && data.end_address.type !== 'consum') {
              plans.sourceList.map(({id, department_id, lng, lat, type, open_status, status, consum_type, point_type, time_status, ...list}) => {
                if ((start_id === id && start_department_id === department_id) || (end_id === id && end_department_id === department_id)) {
                  if(consum_type === 0){
                    instanceData.push({ symbol: pointsImages[0], symbolSize: [31, 40], id, department_id, value: [lng, lat], type, open_status, status, consum_type, point_type, time_status, ...list})
                  }
                  if(consum_type && point_type && time_status){
                    const point = {value: [lng, lat], id, department_id, type, status, open_status, consum_type, point_type, time_status, symbol: pointsImages[consum_type][point_type][time_status], symbolSize: imagesSize, ...list}
                    if(pointsParams.build === 10){
                      if(type === 'building'){
                        if (open_status === 1) {
                          if (status === 2) {
                            instanceData.push({ ...point, symbol: 'image:///images/center/green1.png', symbolSize: [42, 42] });
                          } else if (status === 3) {
                            instanceData.push({ ...point, symbol: 'image:///images/center/orange1.png', symbolSize: [42, 42] });
                          } else if (status === 7) {
                            instanceData.push({ ...point, symbol: 'image:///images/center/red1.png', symbolSize: [42, 42] })
                          } else {
                            instanceData.push({ ...point, symbol: 'image:///images/center/yellow1.png', symbolSize: [42, 42] })
                          }
                        }else {
                          instanceData.push({ ...point, symbol: 'image:///images/center/gray1.png', symbolSize: [42, 42] })
                        }
                      }
                      else{
                        instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
                      }
                    }else {
                      instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
                    }
                  }
                }
              });
            }
          }
          else if ((r5 || r0) && !t2 && t5) {
            if (data.end_address.type !== 'building' && data.end_address.type !== 'consum') {
              plans.sourceList.map(({id, department_id, lng, lat, type, open_status, status, consum_type, point_type, time_status, ...list}) => {
                if ((start_id === id && start_department_id === department_id) || (end_id === id && end_department_id === department_id)) {
                  if(consum_type === 0){
                    instanceData.push({ symbol: pointsImages[0], symbolSize: [31, 40], id, department_id, value: [lng, lat], type, open_status, status, consum_type, point_type, time_status, ...list})
                  }
                  if(consum_type && point_type && time_status){
                    const point = {value: [lng, lat], id, department_id, type, status, open_status, consum_type, point_type, time_status, symbol: pointsImages[consum_type][point_type][time_status], symbolSize: imagesSize, ...list}
                    if(pointsParams.build === 10){
                      if(type === 'building'){
                        if (open_status === 1) {
                          if (status === 2) {
                            instanceData.push({ ...point, symbol: 'image:///images/center/green1.png', symbolSize: [42, 42] });
                          } else if (status === 3) {
                            instanceData.push({ ...point, symbol: 'image:///images/center/orange1.png', symbolSize: [42, 42] });
                          } else if (status === 7) {
                            instanceData.push({ ...point, symbol: 'image:///images/center/red1.png', symbolSize: [42, 42] })
                          } else {
                            instanceData.push({ ...point, symbol: 'image:///images/center/yellow1.png', symbolSize: [42, 42] })
                          }
                        }else {
                          instanceData.push({ ...point, symbol: 'image:///images/center/gray1.png', symbolSize: [42, 42] })
                        }
                      }
                      else{
                        instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
                      }
                    }else {
                      instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
                    }
                  }
                }
              });
            }
          }
        });
        t2 && new_series.push(simplePass({data: coords, lineColor: '#57DC71'})); // 到工地或商砼
        t5 && new_series.push(simplePass({data: coords_sand, lineColor: '#5AC7FA'})); // 到砂石场
        t3 && new_series.push(simplePass({data: ex_coords, lineColor: '#8E4FFE'})); // 可能lines的起点为消纳场
        (r5 && !r1 && !r0) && new_series.push({ ...UseMapAnchorage({ data: DuplicateRemoval(instanceData), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }) });
      }
      // plans.elseList 未知终点联单 没有终点 点击查看该联单详情
      if (plans.elseList.length > 0 && (r5 || r0)) {
        let coords = [], endPointer = [];
        let instanceData = [];
        plans.elseList.map(({start_id, start_department_id, end_id, end_department_id, volumn, num = 1, ...data}) => {
          endPointer.push({ value: [data.end_address.lng, data.end_address.lat], start_id, start_department_id, end_id, end_department_id, volumn, num, ...data });
          if (data.start_address && data.start_address.lng > 3 && data.end_address.lng > 3) {
            coords.push({
              name: data.transport_name,
              start_name: data.start_address.name,
              lineStyle: {width: returnLineWidth(num)},
              end_name: data.end_address.name,
              volumn: volumn,
              coords: [[data.start_address.lng, data.start_address.lat], [data.end_address.lng, data.end_address.lat]], ...data
            });
            if (r5 && r4) {
              plans.sourceList.map(({id, department_id, lng, lat, type, status, open_status, consum_type, point_type, time_status, ...list}) => {
                if ((start_id === id && start_department_id === department_id) || (end_id === id && end_department_id === department_id)) {
                  if(consum_type === 0){
                    instanceData.push({ symbol: pointsImages[0], symbolSize: [31, 40], id, department_id, value: [lng, lat], type, open_status, status, consum_type, point_type, time_status, ...list})
                  }
                  if(consum_type && point_type && time_status){
                    const point = {value: [lng, lat], id, department_id, type, status, open_status, consum_type, point_type, time_status, symbol: pointsImages[consum_type][point_type][time_status], symbolSize: imagesSize, ...list}
                    if(pointsParams.build === 10){
                      if(type === 'building'){
                        if (open_status === 1) {
                          if (status === 2) {
                            instanceData.push({ ...point, symbol: 'image:///images/center/green1.png', symbolSize: [42, 42] });
                          } else if (status === 3) {
                            instanceData.push({ ...point, symbol: 'image:///images/center/orange1.png', symbolSize: [42, 42] });
                          } else if (status === 7) {
                            instanceData.push({ ...point, symbol: 'image:///images/center/red1.png', symbolSize: [42, 42] })
                          } else {
                            instanceData.push({ ...point, symbol: 'image:///images/center/yellow1.png', symbolSize: [42, 42] })
                          }
                        }else {
                          instanceData.push({ ...point, symbol: 'image:///images/center/gray1.png', symbolSize: [42, 42] })
                        }
                      }
                      else{
                        instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
                      }
                    }else {
                      instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
                    }
                  }
                }
              })
            }
          }
        });
        t4 && new_series.push(simplePass({
          name: 'plans_elseList', lineColor: '#FF9B1D', label: {
            show: true, formatter: (a) => {
              return `方量: ${a.data.volumn}`
            }
          }, data: coords, tooltip: {
            formatter: function (a) {
              return '起点: ' + a.data.start_name + '</br>终点: ' + a.data.end_name + '</br>方量: ' + a.data.volumn
            }
          }
        }));
        r5 && new_series.push({ ...UseMapAnchorage({ data: instanceData, symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }) });
      }
      
      if (plans.ticketLines.length > 0 && (r6 || r0)) {
        let m_arr = [], pointer = [];
        let m_index = 0;
        plans.ticketLines.map((v, i) => {
          if (v.points.length > 1) {
            m_arr.push({coords: []});
            v.points.map(({lng, lat, ...data}, j) => {
              m_arr[m_index].coords.push(h_common.GPS_transformation(lat, lng));
              if (j === 0) {
                pointer.push({value: h_common.GPS_transformation(lat, lng), ...data, name: '起点'})
              }
            });
            m_index++;
          }
        });
        if (t1) {
          new_series.push(UseMapLines({ name: 'plansTicketLines', polyline: true, silent: true, symbol: ['none', 'arrow'], effect: true, lineColor: '#FF5152', lineWidth: 2, curveness: 0.5, trailLength: 0, effectSymbol: 'image:///images/center/a4.png', effectSymbolSize: [15, 42.5], data: m_arr }));
          new_series.push(StartOrEndPoint({ name: 'start_pointer', symbol: 'circle', data: DuplicateRemoval(pointer), format: '起点', backImage: '/images/center/diago1.png', itemColor: '#03d5a0' }));
        }
        if ((r6 || r0) && t1) {
          let instanceData = [];
          plans.sourceList.map(({id, department_id, type, lng, lat, open_status, status, consum_type, point_type, time_status,  ...list}) => {
            plans.ticketSource.map(({instance_id, ...data}) => {
              if (id === instance_id && department_id === data.department_id) {
                if(consum_type === 0){
                  instanceData.push({ symbol: pointsImages[0], symbolSize: [31, 40], id, department_id, value: [lng, lat], type, open_status, status, consum_type, point_type, time_status, ...list})
                }
                if(consum_type && point_type && time_status){
                  const point = {value: [lng, lat], id, department_id, type, status, open_status, consum_type, point_type, time_status, symbol: pointsImages[consum_type][point_type][time_status], symbolSize: imagesSize, ...list}
                  if(pointsParams.build === 10){
                    if(type === 'building'){
                      if (open_status === 1) {
                        if (status === 2) {
                          instanceData.push({ ...point, symbol: 'image:///images/center/green1.png', symbolSize: [42, 42] });
                        } else if (status === 3) {
                          instanceData.push({ ...point, symbol: 'image:///images/center/orange1.png', symbolSize: [42, 42] });
                        } else if (status === 7) {
                          instanceData.push({ ...point, symbol: 'image:///images/center/red1.png', symbolSize: [42, 42] })
                        } else {
                          instanceData.push({ ...point, symbol: 'image:///images/center/yellow1.png', symbolSize: [42, 42] })
                        }
                      }else {
                        instanceData.push({ ...point, symbol: 'image:///images/center/gray1.png', symbolSize: [42, 42] })
                      }
                    }
                    else{
                      instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
                    }
                  }else {
                    instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
                  }
                }
              }
            })
          });
          // new_series.push(...returnSourceList(data1, data2, data3, data4, data5, data6, data7, data8, data9, 'new_ticketPoint'))
          r6 && new_series.push({ ...UseMapAnchorage({ data: instanceData, symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }) });
        }
      }
      let data_b = [], data_s = [], data_c = [], data_e = [];
      LinesSum.push({sum: plans.ticketLines.length, volumn: getTicketLinesVolumn(plans.ticketLines)});
      plans.list.map(({end_address: {type}, ...data}) => {
        if (type === 'building' || type === 'concrete') {
          data_b.push(data)
        }
        else if (type === 'sand_factory') {
          data_s.push(data)
        }
        else if (type === 'consum') {
          data_c.push(data)
        }
        else {
          data_e.push(data)
        }
      });
      const a = getDataByParams(1, data_b), b = getDataByParams(1, data_s), c = getDataByParams(1, data_c), d = getDataByParams(1, data_e);
      LinesSum.push({sum: getDataByParams(2, data_b), volumn: a === 0 ? 0 : a});
      LinesSum.push({sum: getDataByParams(2, data_s), volumn: b === 0 ? 0 : b});
      LinesSum.push({sum: getDataByParams(2, data_c), volumn: c === 0 ? 0 : c});
      LinesSum.push({sum: getDataByParams(2, data_e), volumn: d === 0 ? 0 : d});
      if(plans.suspectConsum.length > 0 && (isInArray(right_flag, 7) || isInArray(right_flag, 0))){
        new_series.push(UseMapScatter({
          data: plans.suspectConsum.map(({point, ...data}) => ({value: point, ...data})),
          name: 'suspectConsum',symbolSize: 16, color: '#333333',zlevel: 3,
          tooltip:{
            formatter:function(a,b,c){
              return a.data.name;
            }
          },
          type: 'effectScatter',symbol: 'circle',
        }))
      }
      new_series.push(UseMapScatter({
        name: 'alarmPoints',
        data: AlarmData.map(({lng, lat, ...d}) => ({value: [lng, lat], ...d})),
        type: 'effectScatter', symbol: 'circle', zlevel: 8,
        symbolSize: 16, color: '#FF0000', tooltip: {
          show: true, formatter: function (d) {
            const type = d.data.type;
            return `<div><span style="color: #fff; font-size: 14px">${type === 8 ? d.data.address : ((type === 9 && d.data.device_type === 1) || type === 10) ? d.data.car_num : d.data.building_name}</span><span style="color: #FE4442; font-size: 12px; margin-left: 8px">${d.data.type_text}</span></div>
                  <div style="font-size: 12px; color: #ffffff">告警时间： ${d.data.created_time}</div>`
          }
        }
      }));
      if(grossCarOrMap.flag === 'point'){
        const d = [{value: [grossCarOrMap.lng, grossCarOrMap.lat, ], ...grossCarOrMap}]
        new_series.push(UseMapScatter({
          name: 'alarmPoints',
          data: d,
          type: 'effectScatter',
          symbol: 'circle',
          zlevel: 18,
          symbolSize: 16,
          color: '#FF0000',
          label: {
            show: true,
            position: 'top',
            backgroundColor: 'rgba(50,50,50,0.7)',
            borderRadius: 5,
            padding: 5,
            formatter: function (d) {
              return `{a|${d.data.name}}{b|${d.data.type_text}}\n{c|告警时间： ${d.data.created_time}}`
            },
            rich: {
              a: {color: '#fff', fontSize: 14, lineHeight: 24},
              b: {color: '#FE4442', fontSize: 12, marginLeft: 8, lineHeight: 24},
              c: {fontSize: 12, color: '#ffffff'}
            }
          },
          tooltip: {
            show: true,
            formatter: function (d) {
              return `<div><span style="color: #fff; font-size: 14px">${d.data.name}</span><span style="color: #FE4442; font-size: 12px; margin-left: 8px">${d.data.type_text}</span></div>
                  <div style="font-size: 12px; color: #ffffff">告警时间： ${d.data.created_time}</div>`
            }
          }
        }));
      }
      setCountLines(LinesSum);
      setSeries(new_series);
    }
    else {
      if (grossCarOrMap.flag === 'map') {
        const g_id = grossCarOrMap.id;
        const g_department_id = grossCarOrMap.department_id
        let trans_series = [];
        const plansSource = plans.elseList.map(({id, department_id, ...item}) => (
          id === g_id && department_id === g_department_id ? item : false
        )).filter(item => ({id: g_id, department_id: g_department_id, ...item}));
        let instanceData = [];
        plansSource.map(({lng, lat, type, status, open_status, consum_type, point_type, time_status, ...item}) => {
          if(consum_type === 0){
            instanceData.push({ symbol: pointsImages[0], symbolSize: [31, 40], id, department_id, value: [lng, lat], type, open_status, status, consum_type, point_type, time_status, ...list})
          }
          if(consum_type && point_type && time_status){
            const point = {value: [lng, lat], id, department_id, type, status, open_status, consum_type, point_type, time_status, symbol: pointsImages[consum_type][point_type][time_status], symbolSize: imagesSize, ...list}
            if(pointsParams.build === 10){
              if(type === 'building'){
                if (open_status === 1) {
                  if (status === 2) {
                    instanceData.push({ ...point, symbol: 'image:///images/center/green1.png', symbolSize: [42, 42] });
                  } else if (status === 3) {
                    instanceData.push({ ...point, symbol: 'image:///images/center/orange1.png', symbolSize: [42, 42] });
                  } else if (status === 7) {
                    instanceData.push({ ...point, symbol: 'image:///images/center/red1.png', symbolSize: [42, 42] })
                  } else {
                    instanceData.push({ ...point, symbol: 'image:///images/center/yellow1.png', symbolSize: [42, 42] })
                  }
                }else {
                  instanceData.push({ ...point, symbol: 'image:///images/center/gray1.png', symbolSize: [42, 42] })
                }
              }
              else{
                instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
              }
            }else {
              instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
            }
          }
        });
        trans_series.push({ ...UseMapAnchorage({ data: instanceData, symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }) });
        let coords = [];
        plans.elseList.map(({start_id, start_department_id, end_id, end_department_id, volumn, num, ...data}) => {
          if (data.start_address.lng > 3 && data.end_address.lng > 3 && (start_id === g_id && start_department_id === g_department_id)) {
            coords.push({
              name: data.transport_name,
              start_name: data.start_address.name,
              end_name: data.end_address.name,
              volumn: volumn,
              coords: [[data.start_address.lng, data.start_address.lat], [data.end_address.lng, data.end_address.lat]], ...data
            });
          }
        });
        trans_series.push(simplePass({
          name: 'plans_elseList', lineColor: '#FF9B1D', label: {
            show: true, formatter: (a) => {
              return `方量: ${a.data.volumn}`
            }
          }, data: coords, tooltip: {
            formatter: function (a) {
              return '起点: ' + a.data.start_name + '</br>终点: ' + a.data.end_name + '</br>方量: ' + a.data.volumn
            }
          }
        }))
        let coords_b = [], coords_sand = [], ex_coords = [];
        plans.lines.map(({start_id, start_department_id, end_id, end_department_id, volumn, num, ...data}) => {
          if (start_id === g_id && start_department_id === g_department_id) {
            const lines = { volumn: volumn, num: num, lineStyle: {width: returnLineWidth(num)}, l_start_id: start_id, l_start_department_id: start_department_id, l_end_id: end_id, l_end_department_id: end_department_id, start_name: data.start_address.name, end_name: data.end_address.name, coords: [[data.start_address.lng, data.start_address.lat], [data.end_address.lng, data.end_address.lat]] };
            if (data.end_address.lng.length > 3 && data.end_address.type === 'sand_factory') {
              coords_sand.push({ ...lines })
            }
            else if (data.end_address.lng.length > 3 && data.end_address.type !== 'consum') {
              coords_b.push({ ...lines })
            } else {
              ex_coords.push({ ...lines })
            }
          }
        });
        // coords_b  ---- 到工地  coords_sand ---- 到砂石场 ex_coords ---- 到消纳场
        trans_series.push(
          simplePass({data: coords_sand, lineColor: '#5AC7FA'}),
          simplePass({data: coords_b, lineColor: '#57DC71'}),
          simplePass({data: ex_coords, lineColor: '#8E4FFE'})
        );
        setSeries([...trans_series]);
      }
    }
  }, [chart, plans, right_flag, linesSource, top_flag, JSON.stringify(pointsParams), AlarmData, JSON.stringify(grossCarOrMap)]);

  useMap(isChartUsable, chart, areaBorder, mapType, ticketSeries ? ticketSeries : series, center, trailBtn.play_begin, trailBtn.play_end, centerFlag, otherCenterFlag);

  const [eleChartColor, setEleChartColor] = useState([]);
  const [transVisible, setTransVisible] = useState(false);
  const [transTitle, setTransTitle] = useState('');
  const [transKeyword, setTransKeyword] = useState('');
  const [transKeywords, setTransKeywords] = useState('');
  const [transportList, setTransportList] = useState([]);
  const [showTransList, setShowTransList] = useState([]);
  // 右侧数据函数
  // 运输统计列表
  function getTransportList(area_type, material_id) {
    const start_date = dates[0].format('YYYY-MM-DD');
    const end_date = dates[1].format('YYYY-MM-DD');
    request('web/radar/transportList', {area_id, time: [start_date, end_date], area_type, material_id})
      .then(body => body.data.data)
      .then(setTransportList)
  }

  useEffect(() => {
    setShowTransList(transportList.map((item) => (
      item.start_name.indexOf(transKeywords) > -1 ? item : false
    )).filter(item => item));
  }, [transportList, transKeyword]);
  useEffect(() => {
    if (grossType) {
      const time = [grossTime[0].format('YYYY-MM-DD HH:mm:ss'), grossTime[1].format('YYYY-MM-DD HH:mm:ss')];
      setGrossSpan(true);
      request('/web/radar/visorList', {area_id, visor_type: grossType, name: grossKeyword, time, ...grossPageLimit})
        .then(body => body.data.data)
        .then(data => {
          setGrossListData({data: data.data, total: data.total});
          setGrossSpan(false);
        })
    }
  }, [grossType, grossTime, grossKeyword, grossPageLimit]);
  //排放工地二级菜单
  const [inoutPage, setInoutPage] = useState({page: 1, limit: 10});
  const [inoutKey, setInoutKey] = useState('');
  const [inoutVisible, setInoutVisible] = useState(false);
  const [inoutSpan, setInoutSpan] = useState(false);
  const [inoutData, setInoutData] = useState({data: [], total: 0});

  useEffect(() => {
    if (grossCarOrMap.flag === 'car') {
      setInoutSpan(true);
      const time = [grossTime[0].format('YYYY-MM-DD HH:mm:ss'), grossTime[1].format('YYYY-MM-DD HH:mm:ss')]
      request('/web/radar/carInoutList', {build_id: grossCarOrMap.id, car_num: inoutKey, time, ...inoutPage})
        .then(body => body.data.data)
        .then(data => {
          setInoutData({data: data.data, total: data.total});
          setInoutSpan(false);
        })
    }
  }, [grossCarOrMap, grossTime, inoutKey, inoutPage]);
  // 排放工地三级菜单 -- 进出车辆数据详情
  const [inoutCarNum, setInoutCarNum] = useState('');
  const [inoutDetailVisible, setInoutDetailVisible] = useState(false);
  const [inoutDetailPage, setInoutDetailPage] = useState({page: 1, limit: 10})
  const [inoutDetailData, setInoutDetailData] = useState({data: [], total: 0});
  useEffect(() => {
    if (grossCarOrMap.flag === 'car') {
      const time = [grossTime[0].format('YYYY-MM-DD HH:mm:ss'), grossTime[1].format('YYYY-MM-DD HH:mm:ss')]
      request('web/radar/carInoutHistory', {build_id: grossCarOrMap.id, car_num: inoutCarNum, time, ...inoutDetailPage})
        .then(body => body.data.data)
        .then(data => {
          setInoutDetailData({data: data.data, total: data.total})
        })
    }
  }, [inoutCarNum, grossTime, inoutDetailPage]);
  //  排放工地一级菜单  ---- 黑工地
  const [checkVisible, setCheckVisible] = useState(false);
  const [checkType, setCheckType] = useState();
  const [checkTitle, setCheckTitle] = useState('');
  const [checkParams, setCheckParams] = useState({keywords: '', page: 1, limit: 10});
  const [checkData, setCheckData] = useState({data: [], total: 0});
  const [checkSpin, setCheckSpin] = useState(false);
  const [checkId, setCheckId] = useState();
  useEffect(() => {
    if (checkType) {
      setCheckSpin(true);
      request('/web/stationary_source/getConsumeLists', {area_id, ...checkParams, data_type: checkType})
        .then(body => body.data.data)
        .then(data => {
          setCheckData({data: data.data, total: data.total});
          setCheckSpin(false);
        })
    }
  }, [checkType, checkParams]);

  // 电子联单折线图
  useEffect(() => {
    const colors = ['#1E6FF4', '#FECB16', '#5956D4', '#49D966'];
    const showData = eleFlag ? eleData.recognize : eleData.confirm;
    const realData = eleOption === 1 ? showData : [showData[eleOption - 1]];
    setEleXAxis(eleData.date);
    setEleLegend(showData.map(({name}) => name));
    setEleSeries(realData.map(({data, name}) => ({
      name: name,
      data: data,
      type: 'line',
      smooth: true,
    })));
    setEleChartColor(eleOption === 1 ? colors : colors[eleOption - 1])
  }, [eleData, eleOption, eleFlag]);
  useLineChart(eleIsChartUsable, eleChart, eleSeries, eleXAxis, eleChartColor);

  function closeTicketDetail() {
    setTrashShowDetail(false);
    setOfflineWorkTicketId(({ id }) => ({ id, index: 1}))
  }

  // 呼叫司机
  function startCallDriver(phone, id) {
    setMsg('正在呼叫。。。');
    var options = {
      user: store.getState().info.telephone,
      pwd: 'TFBUserPwd112',
      apiUrl: WebIM.config.apiURL,
      appKey: WebIM.config.appkey
    };
    let conn = new WebIM.connection({
      isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
      https: typeof WebIM.config.https === 'boolean' ? WebIM.config.https : location.protocol === 'https:',
      url: WebIM.config.xmppURL,
      heartBeatWait: WebIM.config.heartBeatWait,
      autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
      autoReconnectInterval: WebIM.config.autoReconnectInterval,
      apiUrl: WebIM.config.apiURL,
      isAutoLogin: true
    });
    conn.open(options);
    let rtcCall = new WebIM.WebRTC.Call({
      connection: conn,
      mediaStreamConstaints: {
        audio: true,
        video: true
      },
      listener: {
        onAcceptCall: function (from, options) {
        },
        //通过streamType区分视频流和音频流，streamType: 'VOICE'(音频流)，'VIDEO'(视频流)
        onGotRemoteStream: function (stream, streamType) {
          var video = document.getElementById('video');
          video.srcObject = stream;
          setMsg('通话中。。。');
          setCallFlag(true);
        },
        onGotLocalStream: function (stream, streamType) {
          var video = document.getElementById('localVideo');
          video.srcObject = stream;
        },
        onRinging: function (caller, streamType) {
          me.channel.ringing(caller, streamType)
        },
        onTermCall: function (reason) {
          setCallDriver(false);
          message.error('用户挂断!');
        },
        onIceConnectionStateChange: function (iceState) {
          setMsg('通话中。。。');
          setCallFlag(true);
        },
        onError: function (e) {
          setCallDriver(false);
          if (e.message === 'callee is not online!') {
            message.error('呼叫用户不在线!');
          } else {
            message.error('呼叫用户失败!');
          }
        }
      }
    });
    rtcCall.caller = 'mengyuanyuan';
    request({
      url: 'web/web_rtc/addFriend',
      data: {target_userId: id, target_userPhone: phone},
    }).then(res => {
      setTimeout(function () {
        rtcCall.makeVoiceCall(phone);
      }, 1000);
    });
    setCallDriver(true);
  }

   /** type 1 - 删除   6 - 联单结算状态相关  待核实时需要将这条数据移除  需要改变ticketTiems的联单结算状态的值 */
  function modifyCarFunc(type, id, new_car_num){
    const data = [...abnormalData];
    const nsc = [...ticketTimes];    
    const arr =  data.map((item) => {
      const match = item.id === id;
      if (type === 1) {
        const { total, volumn } = summary;
        const t = total - 1;
        const v = (volumn - item.volumn).toFixed(1);
        setSummary({ total: t < 0 ? 0 : t, volumn: v < 0 ? 0 : v });
        return match ? null : item;
      } else if (type === 2) {
        const car_num = match ? new_car_num : item.car_num;
        return {...item, car_num};
      } else if (type === 3) {
        const start_name = match ? new_car_num : item.start_name;
        return {...item, start_name};
      }else if (type === 4) {
        const end_name = match ? new_car_num : item.end_name;
        return {...item, end_name};
      }else if (type === 5) {
        const materiel_name = match ? new_car_num : item.materiel_name;
        return {...item, materiel_name};
      } else if (type === 6) {
        const { total, volumn } = summary;
        const t = total - 1;
        const v = (volumn - item.volumn).toFixed(1);
        setSummary({ total: t < 0 ? 0 : t, volumn: v < 0 ? 0 : v });
        if (navNum === 1) {
          return match ? null : item;
        }
        return item;
      }
    }).filter(item => item);
    if (type === 6) {
      const tiTimes = nsc.map(({ id: ticketId, balance_accounts, ...rest }) => {
        return ticketId === id ? ({ id: ticketId, balance_accounts: new_car_num, ...rest }) : ({ id: ticketId, balance_accounts, ...rest });
      }).filter((item) => item);
      setTicketTimes(tiTimes);
    }    
    setAbnormalData(arr);    
  }

  function rtcCallEnd() {
    rtcCall.endCall();
    setCallDriver(false)
  }

  const columns = [
    {
      title: '起点',
      dataIndex: 'start_address.name',
      key: 'start_address.name',
      width: 200,
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (isInArray(arrIndex, index)) {
          obj.props.rowSpan = row.start_num;
        }
        else {
          obj.props.rowSpan = 0;
        }
        return obj;
      }
    },
    {
      title: '终点',
      dataIndex: 'end_address.name',
      key: 'end_address.name',
      width: 200,
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (isInArray(endIndex, index)) {
          obj.props.rowSpan = row.end_num;
        }
        else {
          obj.props.rowSpan = 0;
        }
        return obj;
      }
    },
    {
      title: '运企',
      dataIndex: 'transport_name',
      key: 'transport_name',
      width: 150,
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (isInArray(transportIndex, index)) {
          obj.props.rowSpan = row.transport_num;
        }
        else {
          obj.props.rowSpan = 0;
        }
        return obj;
      }
    },
    {
      title: '车牌号',
      dataIndex: 'car_num',
      key: 'car_num',
      width: 100
    },
    {
      title: '趟数',
      dataIndex: 'num',
      key: 'num',
      width: 80
    },
    {
      title: '方量(m³)',
      dataIndex: 'volumn',
      key: 'volumn',
      width: 100
    }
  ];
  // 查看table的合并
  let arrIndex = [];
  let endIndex = [], transportIndex = [];

  function getRowSpan(data) {
    let arr = [];
    let l_start_id = '', l_start_department_id = '', start_num = 0;
    let l_end_id = '', l_end_department_id = '', end_num = 0;
    let l_transport_id = '', transport_num = 0;
    let start_index = 0;
    let end_index = 0;
    let transport_index = 0;
    arrIndex = [];
    endIndex = [];
    transportIndex = [];
    const length = data.length - 1
    data.map(({start_id, start_department_id, end_id, end_department_id, transport_id, num, ...data}, i) => {
      arr.push({start_id, start_department_id, end_id, end_department_id, num, ...data})
      if (l_start_id === start_id && l_start_department_id === start_department_id) {
        start_num++;
        if (l_end_id === end_id && l_end_department_id === end_department_id) { //终点相同时
          end_num++;
          arr[transport_index].transport_num = transport_num;
          if (l_transport_id === transport_id) {
            transport_num++
          } else {
            // 运企
            transportIndex.push(i);
            transport_num = 0;
            l_transport_id = transport_id;
            transport_index = i;
            transport_num++;
          }
        } else { // 起点相同终点不同时
          if (i !== 0) {
            arr[end_index].end_num = end_num
          }
          endIndex.push(i);
          end_index = i;
          end_num = 0;
          l_end_id = end_id;
          l_end_department_id = end_department_id;
          end_num++;
          // 运企
          if (i !== 0) {
            arr[transport_index].transport_num = transport_num;
          }
          transportIndex.push(i);
          // 运企
          transport_num = 0;
          l_transport_id = transport_id;
          transport_index = i;
          transport_num++;
        }
        if (i === length) {
          arr[start_index].start_num = start_num;
          arr[end_index].end_num = end_num;
          arr[transport_index].transport_num = transport_num;
        }
      } else {
        if (i !== 0 || data.length === 1) {
          arr[start_index].start_num = start_num;
          arr[end_index].end_num = end_num;
          arr[transport_index].transport_num = transport_num;
        }
        endIndex.push(i);
        arrIndex.push(i);
        transportIndex.push(i);
        // transportIndex.push(i);
        start_index = i;
        start_num = 0;
        l_start_id = start_id;
        l_start_department_id = start_department_id;
        start_num++;
        //终点
        end_num = 0;
        l_end_id = end_id;
        end_index = i;
        l_end_department_id = end_department_id;
        end_num++;
        // 运企
        transport_num = 0;
        l_transport_id = transport_id;
        transport_index = i;
        transport_num++;
      }
    });
    return arr;
  }

  //接管报表的二级菜单筛选 --- 到疑似倾倒点 等等
  function filterDataBySourceFlag(data, flag) {
    const sourceType = ['', 'building', 'sand_factory', 'consum'];
    if (flag === 1) {
      return data.map((item) => {
        return (item.end_address.type === sourceType[flag] || item.end_address.type === 'concrete') ? item : null
      }).filter(item => item)
    }
    else if (flag) {
      return data.map((item) => {
        return item.end_address.type === sourceType[flag] ? item : null
      }).filter(item => item)
    }
    else {
      return data.map((item) => {
        return (!item.end_address.type && item.end_address.type !== 'building' && item.end_address.type !== 'sand_factory' && item.end_address.type !== 'consum' && item.end_address.type !== 'concrete') ? item : null
      }).filter(item => item)
    }
  }

  // 接管报表的一级菜单筛选 --- 区域内外  1 --- 区域内  2 --- 区域外
  function filterAreaData(data, flag) {
    if (flag === 1) {
      return data.map(({start_area_id, end_area_id, ...item}) => {
        return start_area_id === area_id && end_area_id === area_id ? item : null
      }).filter(item => item)
    }
    else if (flag === 2) {
      return data.map(({start_area_id, end_area_id, ...item}) => {
        return start_area_id !== area_id && end_area_id === area_id ? item : null
      }).filter(item => item)
    }
    else if (flag === 3) {
      return data.map(({start_area_id, end_area_id, ...item}) => {
        return start_area_id === area_id && end_area_id !== area_id ? item : null
      }).filter(item => item)
    }
  }

  function filterDataSource(data) {
    const flag = parseInt(sourceFlag);
    const filter = parseInt(filterFlag);
    let filterData = data;
    let newData = [];
    switch (filter) {
      case 0: filterData = data; break;
      case 1: filterData = filterAreaData(data, 1); break;
      case 2: filterData = filterAreaData(data, 2); break;
      case 3: filterData = filterAreaData(data, 3); break;
    }
    switch (flag) {
      case 0: newData = filterData; break;
      case 1: newData = filterDataBySourceFlag(filterData, 1); break;
      case 2: newData = filterDataBySourceFlag(filterData, 2); break;
      case 3: newData = filterDataBySourceFlag(filterData, 3); break;
      case 4: newData = filterDataBySourceFlag(filterData, false); break;
    }
    return newData;
  }

  // 合计方量/趟数 1 - 方量 2 - 趟数
  function getDataByParams(type, dataSource) {
    let number = 0;
    switch (type) {
      case 1:
        dataSource.map(({volumn}) => {
          volumn ? number += parseFloat(volumn) : number
        });
        break;
      case 2:
        dataSource.map(({num}) => {
          num ? number += parseFloat(num) : number
        })
        break;
    }
    return type === 1 ? number.toFixed(0) : number;
  }

  //右侧控制方法的增加、取消
  function getRightType(type) {
    let [...newType] = right_flag;
    setOtherCenterFlag(false);
    if (!isInArray(newType, type)) {
      if (type === 0) {
        newType = [0];
        setPointsParams({build: 10, sand: 10, consume: 10, concert: 10, factory: 10})
      } else {
        if(type === 1) {
          newType.remove(9);newType.remove(10);newType.remove(11);newType.remove(12);
        }
        if (type === 9 || type === 10 || type === 11 || type === 12 && isInArray(newType, 1)) {
          newType.remove(1);
        }
        newType.push(type);
        newType.remove(0);
      }
    }
    else {
      newType.remove(type)
    }
    if(type === 9 || type === 10 || type === 11 || type === 12 || type === 13 || type === 1){
      setPointsParams({...pointsParams, build: 10})
    }
    const r5 = isInArray(newType, 5), r6 = isInArray(newType, 6);
    const t1 = isInArray(top_flag, 1), t2 = isInArray(top_flag, 2), t3 = isInArray(top_flag, 3),
      t4 = isInArray(top_flag, 4), t5 = isInArray(top_flag, 5);
    if (type === 0 && (!t1 || !t2 || !t3 || !t4 || !t5)) {
      setTopFlag([1, 2, 3, 4, 5])
    }
    else if ((r6 && !t1) && !r5) {
      setTopFlag([1])
    }
    else if ((r6 && !t1) && r5) {
      setTopFlag([...top_flag, 1])
    }
    else if (!r6 && t1 && r5) {
      setTopFlag([2, 3, 4, 5])
    }
    else if (!r5 && !r6 && type !== 0) {
      setTopFlag([])
    }
    else if (!r5 && r6) {
      setTopFlag([1])
    }
    else if (r5 && !t2 && !t3 && !t4 && !t5 && r6) {
      setTopFlag([1, 2, 3, 4, 5])
    }
    else if (r5 && !t2 && !t3 && !t4 && !t5 && !r6) {
      setTopFlag([2, 3, 4, 5])
    }
    setLinesFlag(false);
    setRightFlag(newType);
  }

  function getRightType1(type) {
    let [...newType] = right_flag1;
    if (!isInArray(newType, type)) {
      if (type === 0) {
        setTrailParams({build: 10, sand: 10, consume: 10, concert: 10, factory: 10})
        newType = [0]
      } else {
        if(type === 1) {
          newType.remove(9);newType.remove(10);newType.remove(11);newType.remove(12);
        }
        if (type === 9 || type === 10 || type === 11 || type === 12 && isInArray(newType, 1)) {
          newType.remove(1);
        }
        newType.push(type);
        newType.remove(0);
      }
    }
    else {
      newType.remove(type)
    }
    if(type === 9 || type === 10 || type === 11 || type === 12 || type === 13 || type === 1){
      setTrailParams({...trailParams, build: 10})
    }
    setRightFlag1(newType)
  }

  function getTopFlag(type) {
    let [...newType] = top_flag;
    if (!isInArray(newType, type)) {
      if (type === 0) {
        newType = [0]
      } else {
        newType.push(type);
        newType.remove(0);
      }
    }
    else {
      newType.remove(type)
    }
    setTopFlag(newType);
    const r5 = isInArray(right_flag, 5), r6 = isInArray(right_flag, 6);
    const t1 = isInArray(newType, 1), t2 = isInArray(newType, 2), t3 = isInArray(newType, 3),
      t4 = isInArray(newType, 4), t5 = isInArray(newType, 5);
    if (t1 && !r6) {
      setRightFlag([...right_flag, 6])
    }
    if (!t1 && r6) {
      setRightFlag([...right_flag.remove(6)])
    }
    if (!t2 && !t3 && !t4 && !t5 && r5) {
      setRightFlag([...right_flag.remove(5)])
    }
    if ((t2 || t3 || t4 || t5) && !r5) {
      setRightFlag([...right_flag, 5])
    }
  }

  function getPercent(value, arr, type = 'value') {
    let sum = 0;
    arr.map(v => {
      sum += v[type]
    });
    return sum === 0 ? 0 : parseInt(((value / sum) * 100).toFixed(1))
  }

  function alarmCountOmit(num) {
    return num > 99 ? '99+' : num
  }

  function OutToFile() {
    var LODOP = getLodop();
    LODOP.PRINT_INIT("");
    LODOP.ADD_PRINT_TABLE(5, 5, "99%", "100%", document.getElementById("div1").innerHTML);
    //LODOP.SET_SAVE_MODE("QUICK_SAVE",true);//快速生成（无表格样式,数据量较大时或许用到）
    LODOP.SAVE_TO_FILE("报表.xlsx");
  }

  const getVolumn = function (data) {
    if (!data) return 0;
    return data <= 10000 ? data : (data / 10000).toFixed(2) + '万'
  };

  //案件中心
  const [casesList, CasesTotal] = obtainCasesData([moment().subtract(7 - 1, 'days').startOf('day'), moment().subtract(0, 'days').endOf('day')]);
  const [casesVisible, setCasesVisible] = useState(false);

  //案件列表
  function obtainCasesData(dates) {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const begin_date = dates[0].format('YYYY-MM-DD');
    const end_date = dates[1].format('YYYY-MM-DD');
    useEffect(() => {
      request('/rest/case_ticketDetaort/list', {page: 1, list_rows: 100, case_time: [begin_date, end_date], area_id})
        .then(res => res.data.data)
        .then(data => {
          setData(data.data);
          setTotal(data.total);
        })
    }, []);
    return [data, total]
  }

  function getAccountSource(){
    !accountSource ? setUnableVerify(true) : confirm({
      title: '提示',
      content: '该联单已被标记为“无法识别”联单，是否继续进行修改？',
      onOk() {
        setUnableVerify(true)
      },
      onCancel() {
      },
    })
  }

  function JudgeOverTicketClose(e, flag) {
    setOverPoints([]);
    setOverTimes([]);
    setTicketTimes(e);
    setExtra((t) => !t);
    if(flag){ 
      getTicketDetail(ticketId);
      setOverFlag((f) => !f);
    }
  }
  const [planVisible, setPlanVisible] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.overlays}>
        <div className={styles.dates}>
          <DatePicker.RangePicker className={styles.date} allowClear={false} separator={'至'} size="small" format="YYYY-MM-DD " suffixIcon={false} value={dates} onChange={(next) => { setDates(next); setTrailBtn((btn) => ({ ...btn, visible: false, play_begin: false })); planFlag = false; setTrashShowDetail(false); }} />
          <Divider type="vertical" style={{backgroundColor: 'rgba(255,255,255,0.7)', width: 3}}/>
          <Button className={styles.btn} onClick={() => setVisibleTable(true)} size={'small'} type={'primary'}>接管报表</Button>
        </div>
        <div className={styles.leftTwoAction}>
          <Spin spinning={leftLoading} delay={500}>
            {
              !stopOrList ?
                <div className={styles.continuousSand}>
                  <div className={styles.leftBoxOne}>
                    <Search defaultValue={keywords} className={styles.antInput}
                            placeholder="请输入关键字"
                            onPressEnter={(e) => {
                              setPage(1);
                              setKeyword(keywords)
                            }}
                            onChange={(e) => {
                              e.persist();
                              setKeywords(e.target.value)
                            }}
                            enterButton={false}
                    />
                  </div>
                  <div style={{ display: 'grid', gridAutoFlow: "column", columnGap: 5}}>
                    <Select placeholder="联单结算状态" value={balance_accounts} onChange={(e) => setBalanceAccounts(e)}>
                      {balanceAccountsArr.map(({ id, name }, i) => (
                        <Option key={i} value={id} title={name}>{name}</Option>
                      ))}
                    </Select>
                    {area_level === 1 ? <Select placeholder="选择区县" value={areaId} onChange={(e) => setAreaId(e)}>
                      {areaArr.map(({ id, name }, i) => (
                        <Option key={i} value={id} title={name}>{name}</Option>
                      ))}
                    </Select> : null}
                  </div>
                  <div className={styles.sandType}>
                    <div className={navNum === 1 ? `${styles.active}` : ''} onClick={() => {setPage(1);setNavNum(1);}}><span>待核实</span></div>
                    {
                      isZx ?
                        <div className={navNum === 4 ? `${styles.active}` : ''} onClick={() => {
                          setPage(1);
                          setNavNum(4);
                        }}><span>已核实</span></div>
                        : null
                    }
                    <div className={navNum === 3 ? `${styles.active}` : ''} onClick={() => {setPage(1);setNavNum(3);}}><span>正常接管</span></div>
                    <div className={navNum === 2 ? `${styles.active}` : ''} onClick={() => {setPage(1);setNavNum(2);}}><span>异常接管</span></div>
                  </div>
                  <div className={styles.summary}>
                    <div>
                      <span>{summary.total ? summary.total : '0'}</span>
                      <span>趟数</span>
                    </div>
                    <div>
                      <span>{summary.volumn ? summary.volumn : '0'}</span>
                      <span>方量</span>
                    </div>
                  </div>
                  <div className={styles.inCirculation}>
                    <div className={styles.sourceClass}>
                      <span className={source === 0 ? styles.active : ''} onClick={() => {setPage(1);setSource(0);}}>全部</span>
                      <span className={source === 1 ? styles.active : ''} onClick={() => {setPage(1);setSource(1)}}>车载识别</span>
                      <span className={source === 2 ? styles.active : ''} onClick={() => {setPage(1);setSource(2)}}>人工补录</span>
                      <span className={source === 3 ? styles.active : ''} onClick={() => {setPage(1);setSource(3)}}>车牌识别</span>
                    </div>
                    <div className={styles.ticketLists}>
                      {
                        abnormalData.length === 0 ?
                          <div style={{color: '#8A898F', backgroundColor: 'transparent', boxShadow: 'none'}}>暂无该类型联单</div> :
                          abnormalData.map((v, i) => (
                            <div className={ticketId === v.id ? styles.active :  ''} key={i}>
                              <div className={styles.carTop}>
                                <div><span>{v.car_num}</span></div>
                                <div><span>{SOURCELIST[v.source]}</span><span>{v.volumn}方</span></div>
                              </div>
                              <div className={styles.companyName}>
                                <span className={v.is_device ? styles.device : ''}>{v.is_device ? '已装车载' : '未装车载'}</span>
                                <span className={v.is_constract ? styles.device : ''}>{v.is_constract ? '合同' : '非合同'}</span>
                                <span className={v.is_directory ? styles.device : ''}>{v.is_directory ? '名录' : '非名录'}</span>
                                {v.offline_work ? <span
                                  title={'已申报卫星定位离线，被系统抓拍到还在运输作业'}
                                  className={`${styles.offline_work} ${offlineWorkTicketId.id === v.id && styles.active}`}
                                  onClick={() => setOfflineWorkTicketId({ id: v.id, index: ticketId ? 2 : 1})}>离线作业</span> : ''
                                }                                
                                {v.is_no_end ? <span className={!v.is_no_end ? styles.device : ''}>本单无终点</span> : ''}
                              </div>
                              <div className={styles.trashPoint}>
                                <div className={styles.trashRoute}><p title={v.start_name}>{v.start_name}</p>
                                  <span>起点</span></div>
                                <div className={styles.trashRouteLine}>{}</div>
                                <div className={styles.trashRoute}><p title={v.end_name}>{v.end_name}</p>
                                  <span>终点</span></div>
                              </div>
                              <div className={styles.inTime}><span>{v.start_time}</span>
                                <div>
                                    <span className={styles.detail} onClick={() => {
                                      setTicketId(v.id);
                                      getTicketDetail(v.id);
                                      getTicketIsAcquirer(v.id)
                                    }}>详情</span>
                                  <span className={styles.trail} onClick={() => showTicketLine({id: v.id, car_num: v.car_num, account_source: v.account_source, start_time: v.start_time})}>轨迹</span>
                                </div>
                              </div>
                            </div>
                          ))
                      }
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <Pagination size="small" total={summary.total} onChange={ setPage } />
                      {/*<span style={{display: 'inline-block', width: '100%', backgroundColor: 'transparent', padding: '5px 0', cursor: 'pointer', textAlign: 'center'}} onClick={() => {moreVisible ? setPage(page + 1) : null}}>{moreVisible ? '点击加载更多' : '暂无更多'}</span>*/}
                    </div>
                  </div>
                </div>
                :
                <div className={styles.continuousSand}>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 0 0'}}>
                    <div>
                      <span style={{padding: '5px 5px 0'}}>汇总: <span>趟数: {abnormalData.length}</span><span
                        style={{marginLeft: 8}}>方量: {getDataByParams(1, abnormalData)}</span></span>
                    </div>
                    <Button size={'small'} onClick={() => setStopOrList(false)}>返回</Button>
                  </div>
                  <div className={styles.inCirculation}>
                    <div className={styles.ticketLists} style={{height: 660}}>
                      {
                        abnormalData.length === 0 ?
                          <div style={{color: '#8A898F', backgroundColor: 'transparent', boxShadow: 'none'}}>暂无联单</div> :
                          abnormalData.map((v, i) => (
                            <div className={ticketId === v.id ? styles.active : ''} key={i}>
                              <div className={styles.carTop}>
                                <div><span>{v.car_num}</span></div>
                                <div><span>{SOURCELIST[v.source]}</span><span>{v.volumn}方</span></div>
                              </div>
                              <div className={styles.companyName}>
                                <span className={v.is_device ? styles.device : ''}>车载</span>
                                <span className={v.is_constract ? styles.device : ''}>{v.is_constract ? '合同' : '非合同'}</span>
                                <span className={v.is_directory ? styles.device : ''}>{v.is_directory ? '名录' : '非名录'}</span>
                                { v.offline_work ? <span
                                  title={'已申报卫星定位离线，被系统抓拍到还在运输作业'}
                                  className={`${styles.offline_work} ${offlineWorkTicketId.id === v.id && styles.active}`}
                                  onClick={() => setOfflineWorkTicketId({ id: v.id, index: ticketId ? 2 : 1})}>离线作业</span> : ''
                                }
                                {v.is_no_end ? <span className={!v.is_no_end ? styles.device : ''}>本单无终点</span> : ''}
                              </div>
                              <div className={styles.trashPoint}>
                                <div className={styles.trashRoute}><p title={v.start_name}>{v.start_name}</p>
                                  <span>起点</span></div>
                                <div className={styles.trashRouteLine}>{}</div>
                                <div className={styles.trashRoute}><p title={v.end_name}>{v.end_name}</p>
                                  <span>终点</span></div>
                              </div>
                              <div className={styles.inTime}><span>{v.start_time}</span>
                                <div>
                                  <span className={styles.detail} onClick={() => { setTicketId(v.id); getTicketDetail(v.id);getTicketIsAcquirer(v.id) }}>详情</span>
                                  <span className={styles.trail}  onClick={() => showTicketLine({id: v.id, car_num: v.car_num, account_source: v.account_source, start_time: v.start_time})}>轨迹</span>
                                </div>
                              </div>
                            </div>
                          ))
                      }
                    </div>
                  </div>
                </div>
            }
          </Spin>
        </div>
      </div>
      <Spin spinning={chartLoading} size={'large'} delay={500} wrapperClassName={styles.chartSpin}>
        <div className={styles.chart} ref={chartRefGetter}/>
      </Spin>
      {
        !warnVisible ?
          <div className={styles.rightBox}>
            <div className={styles.weather}>
              <div className={styles.rightWea}>
                <div>
                  <span>{environment.temperature !== '' ? environment.temperature : '无'}</span><span>{environment.weather !== '' ? environment.weather : '无'}</span>
                </div>
                <div>今日{area_name.slice(0, area_name.length - 1)}</div>
              </div>
              <Carousel autoplay dots={false} effect="fade">
                <div className={styles.pollute}>首要污染物: {environment.primary}</div>
                <div className={styles.pollute}>PM10: {environment.pm10} &nbsp; &nbsp;AQI: {environment.aqi}</div>
              </Carousel>
            </div>
            <div className={styles.dischargeBuild}>
              <div className={styles.buildTitle}>
                <div style={{flex: 1}}>
                  <img src="/images/radar/build.png" alt=""/>
                  <span>工地监管</span>
                </div>
                <div className={styles.suspiciousPoint} style={{flex: 1}}>
                  <img src="/images/radar/points.png" alt=""/>
                  <span>疑似点位</span>
                </div>
              </div>
              <div className={styles.buildContent}>
                <div>
                  <div className={styles.conTitle}>{}</div>
                  <div className={styles.conMsg}>
                    {
                      grossData.visor.map(({name, value, count}, i) => (
                        <div key={i} onClick={() => {
                          setWatchVisible(true);
                          setGrossType(value);
                          setCheckVisible(false);
                          setGrossTitle(name + '疑似点位');
                          setGrossTime(defaultGrossDates);
                          setGrossKeywords("");
                          setGrossKeyword("");
                        }}>
                          <div>
                            <Progress type="circle" percent={getPercent(count, grossData.visor, 'count')}
                                      trailColor={'#DCDCDC'} strokeColor={i === 0 ? '#49D966' : '#1491FB'} width={50}
                                      strokeWidth={2}/>
                            <div className={styles.circle}
                                 style={{backgroundColor: i === 1 ? '#1491FB' : ''}}>{count}</div>
                          </div>
                          <span>{name}</span>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div>
                  <div className={styles.conTitle}>{}</div>
                  <div className={styles.conMsg}>
                    {
                      grossData.check.map(({name, value, count}, i) => (
                        <div key={i} onClick={() => {
                          setWatchVisible(false);
                          setCheckVisible(true);
                          setCheckType(value);
                          setCheckTitle(name + '工地')
                        }}>{/**/}
                          <div>
                            <Progress type="circle" percent={getPercent(count, grossData.check, 'count')}
                                      trailColor={'#DCDCDC'} strokeColor={'#FF9316'} width={50} strokeWidth={2}/>
                            <div className={styles.circle} style={{backgroundColor: '#FF9316'}}>{count}</div>
                          </div>
                          <span>{name}</span>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.dischargeBuild}>
              <div className={styles.buildTitle}>
                <div>
                  <img style={{width: 13, height: 13}} src="/images/radar/trans.png" alt=""/>
                  <span>运输统计</span>
                </div>
                <div>
                  <span className={isArea === 1 ? styles.active : ''} onClick={() => setIsArea(1)}>区域内</span>
                  <span className={isArea === 2 ? styles.active : ''} onClick={() => setIsArea(2)}>入区域</span>
                  <span className={isArea === 3 ? styles.active : ''} onClick={() => setIsArea(3)}>出区域</span>
                </div>
              </div>
              <div className={styles.sliderClass}>
                {
                  isArea === 1 ? transCount.inner.map(({name, value, area_type, material_id}, i) => (
                      <div key={i} onClick={() => {setTransVisible(true);setTransTitle('区域内' + name);getTransportList(area_type, material_id)}}>
                        <div className={styles.sliderDes}>
                          <span>{name}</span>
                          <span>{value}方</span>
                        </div>
                        <Slider className={styles.antSlider} tipFormatter={null} value={getPercent(value, transCount.inner, 'value')} disabled={true}/>
                      </div>
                    ))
                    : isArea === 2 ? transCount.in.map(({name, value, area_type, material_id}, i) => (
                      <div key={i} onClick={() => {setTransVisible(true);setTransTitle('入区域' + name);getTransportList(area_type, material_id)}}>
                        <div className={styles.sliderDes}>
                          <span>{name}</span>
                          <span>{value}方</span>
                        </div>
                        <Slider className={styles.antSlider} tipFormatter={null} value={getPercent(value, transCount.in, 'value')} disabled={true}/>
                      </div>
                    ))
                    : transCount.out.map(({name, value, area_type, material_id}, i) => (
                      <div key={i} onClick={() => {setTransVisible(true);setTransTitle('出区域' + name);getTransportList(area_type, material_id)}}>
                        <div className={styles.sliderDes}>
                          <span>{name}</span>
                          <span>{value}方</span>
                        </div>
                        <Slider className={styles.antSlider} tipFormatter={null} value={getPercent(value, transCount.out, 'value')} disabled={true}/>
                      </div>
                    ))
                }
              </div>
            </div>
            <div className={styles.dischargeBuild}>
              <div className={styles.buildTitle}>
                <div>
                  <img src="/images/radar/car.png" style={{width: 16, height: 12}} alt=""/>
                  <span>车辆监管</span>
                </div>
              </div>
              <div className={styles.carManage}>
                {
                  carManage.map(({name, value, nameE, valueE, total}, i) => (
                    <div key={i}>
                      <span>{name}</span>
                      <div className={styles.carBar}>
                        <div>
                          <div style={{backgroundColor: !total || total === 0 ? '#eee' : ''}}>{}</div>
                          <span>{value}</span>
                        </div>
                        <div>
                          <div style={{backgroundColor: !total || total === 0 ? '#eee' : ''}}>{}</div>
                          <span>{valueE}</span>
                        </div>
                      </div>
                      <span>{nameE}</span>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className={styles.eleTicket}>
              <div className={styles.eleTitle}>
                <div>
                  <img src="/images/radar/ele.png" alt=""/>
                  <span>电子联单</span>
                </div>
                <div>
                  <DatePicker.RangePicker className={styles.date} allowClear={false} separator={'-'} size="small" format="MM-DD " suffixIcon={false} value={eleDates} onChange={next => {setEleDates(next)}}/>
                </div>
                <div>
                  <span className={eleFlag ? styles.active : ''} onClick={() => {setEleOption(1);setEleFlag(true)}}>识别率</span>
                  <span className={!eleFlag ? styles.active : ''} onClick={() => {setEleOption(1);setEleFlag(false)}}>接管率</span>
                </div>
              </div>
              <div className={styles.legend}>
                {
                  eleLegend.map((v, i) => (<div className={eleOption === (i + 1) ? styles.active : ''} onClick={() => setEleOption(i + 1)} key={i}>{v}</div>))
                }
              </div>
              <div className={styles.charts} ref={eleChartRefGetter} />
            </div>
          </div> : <AlarmCenter clickAlarm={clickAlarm} defaultDates={alarmDate} onChangeDate={(date) => setAlarmDate(date)} onClose={() => {setWarnVisible(false); setGrossCarOrMap({flag: false})}} showPoint={({...props}) => {setGrossCarOrMap({ flag: 'point', ...props })}} />
      }
      {
        !trailBtn.visible ? (
          <>
            <div className={styles.topMenu}>
              <div className={styles.linesFilter}>
                <div onClick={() => getTopFlag(1)}>
                  <div className={styles.divFirst}><span className={isInArray(top_flag, 1) ? styles.active : ''}>{}</span><span>待核实</span>
                  </div>
                  <div className={styles.divLast}>
                    <div>
                      <span>趟数</span><span>{countLines[0].sum}</span>
                    </div>
                    <div>
                      <span>方量</span><span>{countLines[0].volumn}</span>
                    </div>
                  </div>
                </div>
                <div onClick={() => getTopFlag(4)}>
                  <div className={styles.divFirst}><span className={isInArray(top_flag, 4) ? styles.active : ''}>{}</span><span>疑似偷排</span>
                  </div>
                  <div className={styles.divLast}>
                    <div>
                      <span>趟数</span><span>{countLines[4].sum}</span>
                    </div>
                    <div>
                      <span>方量</span><span>{countLines[4].volumn}</span>
                    </div>
                  </div>
                </div>
                <div onClick={() => getTopFlag(3)}>
                  <div className={styles.divFirst}><span className={isInArray(top_flag, 3) ? styles.active : ''}>{}</span><span>非法消纳</span>
                  </div>
                  <div className={styles.divLast}>
                    <div>
                      <span>趟数</span><span
                      style={{fontSize: countLines[3].sum >= 1000 ? 12 : 16}}>{countLines[3].sum}</span>
                    </div>
                    <div>
                      <span>方量</span><span>{getVolumn(countLines[3].volumn)}</span>
                    </div>
                  </div>
                </div>
                <div onClick={() => getTopFlag(2)}>
                  <div className={styles.divFirst}><span className={isInArray(top_flag, 2) ? styles.active : ''}>{}</span><span>指定接管</span>
                  </div>
                  <div className={styles.divLast}>
                    <div>
                      <span>趟数</span><span
                      style={{fontSize: countLines[1].sum >= 1000 ? 12 : 16}}>{countLines[1].sum}</span>
                    </div>
                    <div>
                      <span>方量</span><span>{getVolumn(countLines[1].volumn)}</span>
                    </div>
                  </div>
                </div>
                <div onClick={() => getTopFlag(5)}>
                  <div className={styles.divFirst}><span className={isInArray(top_flag, 5) ? styles.active : ''}>{}</span><span>砂石场接管</span>
                  </div>
                  <div className={styles.divLast}>
                    <div>
                      <span>趟数</span><span
                      style={{fontSize: countLines[2].sum >= 1000 ? 12 : 16}}>{countLines[2].sum}</span>
                    </div>
                    <div>
                      <span>方量</span><span>{getVolumn(countLines[2].volumn)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.linesLast}>
                <div className={styles.in}>
                  <div>
                    <img src="/images/radar/in.png" alt=""/>
                    <span>{plans.summary.in.name}</span>
                  </div>
                  <div>
                    <span>{plans.summary.in.num}趟</span>
                    <span>{getVolumn(plans.summary.in.volumn)}方</span>
                  </div>
                </div>
                <div className={styles.out}>
                  <div>
                    <img src="/images/radar/out.png" alt=""/>
                    <span>{plans.summary.out.name}</span>
                  </div>
                  <div>
                    <span>{plans.summary.out.num}趟</span>
                    <span>{getVolumn(plans.summary.out.volumn)}方</span>
                  </div>
                </div>
                <div className={styles.has}>
                  <div>
                    <img src="/images/radar/has.png" alt=""/>
                    <span>{plans.summary.both.name}</span>
                  </div>
                  <div>
                    <span>{plans.summary.both.num}趟</span>
                    <span>{getVolumn(plans.summary.both.volumn)}方</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.right_top_nb}>
              <div onClick={() => setMaterielType(1)} className={materiel_type === 1 ? styles.active : ''}><span>砂</span>
              </div>
              <div onClick={() => setMaterielType(4)} className={materiel_type === 4 ? styles.active : ''}><span>泥</span>
              </div>
              {/*<div onClick={() => setMaterielType(4)} className={materiel_type === 4 ? styles.active : ''}>建垃</div>*/}
            </div>
            <div className={styles.right_top_box}>
              <div>
                <div className={styles.all_div} onClick={() => getRightType(0) }>
                  <Tooltip placement="left" title="全部">
                    <span className={isInArray(right_flag, 0) ? styles.active : ''}>全</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => pointFilter(0, 1, pointsParams.build === 10 ? 0 : 10 ) }>
                  <Tooltip placement="left" title={<PointClass className={ classNames(styles.pointTip, styles[pointsClass[pointsParams.build]]) } pointType={1} onFilter={pointFilter} />} overlayClassName={styles.myTip}>
                    <span className={(pointsParams.build === 0 || isInArray(right_flag, 0)) ? styles.active : pointsParams.build === 10 ? '' : styles.other}>工</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => pointFilter(0, 2, pointsParams.sand === 10 ? 0 :10) }>
                  <Tooltip placement="left" title={<PointClass className={ classNames(styles.pointTip, styles[pointsClass[pointsParams.sand]]) } pointType={2} onFilter={pointFilter} />} overlayClassName={styles.myTip}>
                    <span className={(pointsParams.sand === 0 || isInArray(right_flag, 0)) ? styles.active : pointsParams.sand === 10 ? '' : styles.other}>砂</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => pointFilter(0, 3, pointsParams.concert === 10 ? 0 : 10) }>
                  <Tooltip placement="left" title={<PointClass className={ classNames(styles.pointTip, styles[pointsClass[pointsParams.concert]]) } pointType={3} onFilter={pointFilter} />} overlayClassName={styles.myTip}>
                    <span className={(pointsParams.concert === 0 || isInArray(right_flag, 0)) ? styles.active : pointsParams.concert === 10 ? '' : styles.other}>砼</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={ () => pointFilter(0, 4, pointsParams.consume === 10 ? 0 : 10) }>
                  <Tooltip placement="left" title={<PointClass className={ classNames(styles.pointTip, styles[pointsClass[pointsParams.consume]]) } pointType={4} onFilter={pointFilter} />} overlayClassName={styles.myTip}>
                    <span className={(pointsParams.consume === 0 || isInArray(right_flag, 0)) ? styles.active : pointsParams.consume === 10 ? '' : styles.other}>消</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={ () => pointFilter(0, 18, pointsParams.factory === 10 ? 0 : 10) }>
                  <Tooltip placement="left" title={<PointClass className={ classNames(styles.pointTip, styles[pointsClass[pointsParams.factory]]) } pointType={18} onFilter={pointFilter} />} overlayClassName={styles.myTip}>
                    <span className={(pointsParams.factory === 0 || isInArray(right_flag, 0)) ? styles.active : pointsParams.factory === 10 ? '' : styles.other}>加</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={ () => getRightType(15) }>
                  <Tooltip placement="left" title="待排查点位" overlayClassName="my_tip">
                    <span className={(isInArray(right_flag, 15) || isInArray(right_flag, 0)) && styles.active}>待</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={ () => getRightType(16) }>
                  <Tooltip placement="left" title="疑似点位" overlayClassName="my_tip">
                    <span className={(isInArray(right_flag, 16) || isInArray(right_flag, 0)) && styles.active}>疑</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={ () => getRightType(17) }>
                  <Tooltip placement="left" title="其他点位" overlayClassName="my_tip">
                    <span className={(isInArray(right_flag, 17) || isInArray(right_flag, 0)) && styles.active}>其</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => getRightType(5) }>
                  <Tooltip placement="left" title="线路" overlayClassName="my_tip">
                    <span className={(isInArray(right_flag, 5) || isInArray(right_flag, 0)) && styles.active}>线</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => getRightType(6) }>
                  <Tooltip placement="left" title="轨迹" overlayClassName="my_tip">
                    <span className={(isInArray(right_flag, 6) || isInArray(right_flag, 0)) && styles.active}>轨</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => getRightType(7) }>
                  <Tooltip placement="left" title="黑工地" overlayClassName="my_tip">
                    <span className={(isInArray(right_flag, 7) || isInArray(right_flag, 0)) && styles.active}>黑</span>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className={styles.right_top_box} style={{top: 490, left: 340, right: 'auto'}}>
              <div>
                <div className={styles.all_div} onClick={() => getRightType(1) }>
                  <Tooltip placement="right" title={`开工${countBuild[0]}`}>
                    <span className={(isInArray(right_flag, 1)) && styles.active}>开</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => getRightType(9)}>
                  <Tooltip placement="right" title={`土方开挖${countBuild[1]}`}>
                    <span className={(isInArray(right_flag, 1) || isInArray(right_flag, 9)) && styles.active}>土</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => getRightType(10) }>
                  <Tooltip placement="right" title={`主体施工${countBuild[2]}`}>
                    <span className={(isInArray(right_flag, 1) || isInArray(right_flag, 10)) && styles.active}>主</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => getRightType(11)}>
                  <Tooltip placement="right" title={`回填${countBuild[3]}`}>
                    <span className={(isInArray(right_flag, 1) || isInArray(right_flag, 11)) && styles.active}>回</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => getRightType(12) }>
                  <Tooltip placement="right" title={`其他${countBuild[4]}`} overlayClassName="my_tip">
                    <span className={(isInArray(right_flag, 1) || isInArray(right_flag, 12)) && styles.active}>其</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => getRightType(13) }>
                  <Tooltip placement="right" title={`停工${countBuild[5]}`} overlayClassName="my_tip">
                    <span className={isInArray(right_flag, 13) && styles.active}>停</span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.ticketTop}>
              <TrailRadio ticketStatus={ticketStatus} lastHour={lastHour} hour={hour} onLastChange={(e) => {setLastHour(e); getExtraTicketLine(ticketId, e)}} onChange={(e) => {setHour(e); getExtraTicketLine(ticketId, e)}} />
              <div className={styles.ticketTimes}>
                <div className={styles.ticketTitle}>
                  <div>
                    <span>{ticketCarNum}</span>
                    <Button type="primary" size="small" onClick={() => getTicketDetail(ticketId)}>详情</Button>
                    { (isZx && (trashDetailData && trashDetailData.detailInfo.ticket_account_id === 0)) && <Button type="primary" size="small" onClick={() => setMergeTicket(true)} style={{ marginLeft: 5 }}>合并</Button> }
                    { (isZx && (trashDetailData && trashDetailData.detailInfo.ticket_account_id === 0)) && <Button type="primary" size="small" onClick={() => setSplitTicket(true)} style={{ marginLeft: 5 }}>拆分</Button> }
                    { (isZx && (trashDetailData && trashDetailData.detailInfo.ticket_account_id === 0) && navNum !== 4 && isAcquirer === 0) && <Button type="danger" size="small" onClick={() => getAccountSource() } style={{ marginLeft: 5 }}>无法核实</Button> }
                    { (isZx && (trashDetailData && trashDetailData.detailInfo.ticket_account_id === 0) && (ticketStatus === 1 || ticketStatus === 2) && navNum !== 4) && <Button type="primary" size="small" onClick={() => setChangePoint(true) } style={{ marginLeft: 5 }}>起终点调换</Button> }
                  </div>
                  <span>{`共${ticketTimes.length}趟`}</span>
                </div>
                <div className={styles.ticketContent}>
                    {
                      ticketTimes.map(({start_time, end_time, account_source, id, number, today, balance_accounts}, i) => (
                        <div
                          key={i}
                          onClick={() => {setAccountSource(account_source); setHour(); setLastHour(); JudgeTicketStatus(id); getTicketIsAcquirer(id); getOtherTicketLines(id); if(trash_show_detail) {getTicketDetail(id);}}}
                          className={`${ticketId === id ? styles.active : today === 2 ? styles.todayTicket : account_source ? styles.account_source : ''} ${splitTicketnumbers.includes(number) ? styles.numbers : ''}`}>
                          <span>{`${GetTicketShowTime(start_time)} - ${GetTicketShowTime(end_time)}`}</span>
                          {balance_accounts !== 5 ? <span className={styles.chekcs}>核</span> : null}
                        </div>
                      ))
                    }
                  </div>
              </div>
            </div>
            <div className={styles.right_top_box} style={{top: 470}}>
              <div>
                <div className={styles.all_div} onClick={() => {getRightType1(0)}}>
                  <Tooltip placement="left" title="全部" overlayClassName={styles.my_tip}>
                    <span className={isInArray(right_flag1, 0) && styles.active}>全</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => pointFilter(1, 1, trailParams.build === 10 ? 0 : 10) }>
                  <Tooltip placement="left" title={<PointClass className={ classNames(styles.pointTip, styles[pointsClass[trailParams.build]]) } pointType={1} siteType={1} onFilter={pointFilter} />} overlayClassName={styles.myTip}>
                    <span className={(trailParams.build === 0 || isInArray(right_flag1, 0)) ? styles.active : trailParams.build === 10 ? '' : styles.other}>工</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => pointFilter(1, 2, trailParams.sand === 10 ? 0 : 10) }>
                  <Tooltip placement="left" title={<PointClass className={ classNames(styles.pointTip, styles[pointsClass[trailParams.sand]]) } pointType={2} siteType={1} onFilter={pointFilter} />} overlayClassName={styles.myTip}>
                    <span className={(trailParams.sand === 0 || isInArray(right_flag1, 0))? styles.active : trailParams.sand === 10 ? '' : styles.other}>砂</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => pointFilter(1, 3, trailParams.concert === 10 ? 0 : 10) }>
                  <Tooltip placement="left" title={<PointClass className={ classNames(styles.pointTip, styles[pointsClass[trailParams.concert]]) } pointType={3} siteType={1} onFilter={pointFilter} />} overlayClassName={styles.myTip}>
                    <span className={(trailParams.concert === 0 || isInArray(right_flag1, 0)) ? styles.active : trailParams.concert === 10 ? '' : styles.other}>砼</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={ () => pointFilter(1, 4, trailParams.consume === 10 ? 0 : 10) }>
                  <Tooltip placement="left" title={<PointClass className={ classNames(styles.pointTip, styles[pointsClass[trailParams.consume]]) } pointType={4} siteType={1} onFilter={pointFilter} />} overlayClassName={styles.myTip}>
                    <span className={(trailParams.consume === 0 || isInArray(right_flag1, 0)) ? styles.active : trailParams.consume === 10 ? '' : styles.other}>消</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={ () => pointFilter(1, 18, trailParams.factory === 10 ? 0 : 10) }>
                  <Tooltip placement="left" title={<PointClass className={ classNames(styles.pointTip, styles[pointsClass[trailParams.factory]]) } pointType={18} onFilter={pointFilter} />} overlayClassName={styles.myTip}>
                    <span className={(trailParams.factory === 0 || isInArray(right_flag1, 0)) ? styles.active : trailParams.factory === 10 ? '' : styles.other}>加</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={ () => getRightType1(15) }>
                  <Tooltip placement="left" title="待排查点位" overlayClassName="my_tip">
                    <span className={(isInArray(right_flag1, 15) || isInArray(right_flag1, 0)) && styles.active}>待</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={ () => getRightType1(16) }>
                  <Tooltip placement="left" title="疑似点位" overlayClassName="my_tip">
                    <span className={(isInArray(right_flag1, 16) || isInArray(right_flag1, 0)) && styles.active}>疑</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={ () => getRightType1(17) }>
                  <Tooltip placement="left" title="其他点位" overlayClassName="my_tip">
                    <span className={(isInArray(right_flag1, 17) || isInArray(right_flag1, 0)) && styles.active}>其</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => setNear(!near)}>
                    <Tooltip placement="left" title="轨迹附近的固定源" overlayClassName="my_tip">
                      <span className={near ? styles.active : ''}>附</span>
                    </Tooltip>
                  </div>
              </div>
            </div>
            <div className={styles.right_top_box} style={{top: 490, left: 340, right: 'auto'}}>
              <div>
                <div className={styles.all_div} onClick={() => getRightType1(1)}>
                  <Tooltip placement="right" title={`开工${countBuild[0]}`} overlayClassName="my_tip">
                    <span className={isInArray(right_flag1, 1) && styles.active}>开</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => getRightType1(9)}>
                  <Tooltip placement="right" title={`土方开挖${countBuild[1]}`} overlayClassName="my_tip">
                    <span className={(isInArray(right_flag1, 1) || isInArray(right_flag1, 9)) && styles.active}>土</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => getRightType1(10)}>
                  <Tooltip placement="right" title={`主体施工${countBuild[2]}`} overlayClassName="my_tip">
                    <span className={(isInArray(right_flag1, 1) || isInArray(right_flag1, 10)) && styles.active}>主</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => getRightType1(11)}>
                  <Tooltip placement="right" title={`回填${countBuild[3]}`} overlayClassName="my_tip">
                    <span className={(isInArray(right_flag1, 1) || isInArray(right_flag1, 11)) && styles.active}>回</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => getRightType1(12)}>
                  <Tooltip placement="right" title={`其他${countBuild[4]}`} overlayClassName="my_tip">
                    <span className={(isInArray(right_flag1, 1) || isInArray(right_flag1, 12)) && styles.active}>其</span>
                  </Tooltip>
                </div>
                <div className={styles.all_div} onClick={() => getRightType1(13) }>
                  <Tooltip placement="right" title={`停工${countBuild[5]}`} overlayClassName="my_tip">
                    <span className={isInArray(right_flag1, 13) && styles.active}>停</span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </>
        )
      }
      <div className={styles.warnCenter}>
        <span onClick={() => {setWarnVisible(true);setCasesVisible(false);}}>
          <img src="/images/radar/warn.png" alt=""/>
          <span>告警中心</span>
          <span>{ alarmCountOmit(AlarmTotal) }</span>
        </span>
      </div>
      {!casesVisible ? null : <CasesCenter onClose={() => setCasesVisible(false)}/>}
      <WaitTicketDeal />
      <div className={styles.cases}>
        <span onClick={() => {setCasesVisible(true);setWarnVisible(false);}}>
          <img src="/images/cases.png" alt=""/>
          <span>案件中心</span>
          <span>{ alarmCountOmit(CasesTotal) }</span>
        </span>
      </div>
      { call_driver && <CallDriver data={{ msg: msg, call_flag: call_flag }} rtcCallEnd={rtcCallEnd} /> }
      { pointMsg && <PointPreview params={ pointMsg } onClose={ () => setPointMsg() } />}
      <Drawer
        title={transTitle}
        closable={true}
        mask={false}
        destroyOnClose={true}
        className={transVisible ? styles.transport : ''}
        style={{height: 800, top: 90}}
        onClose={() => setTransVisible(false)}
        visible={transVisible}
        width={330}
        bodyStyle={{padding: 10}}
      >
        <div className={styles.searchPart}>
          <Search defaultValue={keywords} className={styles.antInput}
                  placeholder="请输入关键字"
                  onPressEnter={(e) => {
                    setTransKeyword(transKeywords)
                  }}
                  onChange={(e) => {
                    e.persist();
                    setTransKeywords(e.target.value)
                  }}
                  enterButton={false}
          />
        </div>
        <div className={styles.transContent}>
          {
            showTransList.map(({start_name, end_name, count, volumn}, i) => (
              <div key={i}>
                <div><span>{start_name}</span>
                  <div><span>{count}趟</span><span>{volumn}方</span></div>
                </div>
                <div>{end_name}</div>
              </div>
            ))
          }
        </div>
      </Drawer>
      { trailBtn.visible && (
        <ControlSlider
          trailBtn={trailBtn}
          stopPoint={stopPoint}
          onChange={(e) => setTrailBtn(e)}
          onClose={() => {setTicketList([]); setTrailBtn((btn) => ({ ...btn, play_begin: false, movePos: 0, visible: false, playIndex: 0 }))}}
          showStopPoint={(e) => !e && setStopPoint()}
        />
      )}
      {/*疑似点位*/}
      <Drawer
        title={'疑似点位'}
        placement="right"
        onClose={() => {
          setCheckType();
          setCheckVisible(false);
          setCheckId()
        }}
        visible={checkVisible}
        closable={true}
        mask={false}
        destroyOnClose={true}
        className={checkVisible ? styles.transport : ''}
        style={{height: 800, top: 90}}
        width={330}
        bodyStyle={{padding: 10}}
      >
        <div className={styles.pointsPart}>
          <div>
            <span className={checkType === 1 ? styles.active : ''} onClick={() => {
              setCheckType(1);
              setCheckParams({...checkParams, page: 1})
            }}>待排查</span>
            <span className={checkType === 1 ? '' : styles.active} onClick={() => {
              setCheckType(2);
              setCheckParams({...checkParams, page: 1})
            }}>已完结</span>
          </div>
          <div>总点位：<span style={{color: checkType === 1 ? '#FF9316' : '#1491fb'}}>{checkData.total}</span></div>
        </div>
        <Spin spinning={checkSpin}>
          <div className={styles.checkList}>
            {
              checkData.data.length > 0 ?
                checkData.data.map(({name, address, area_name, consum_type, checked_time, id, create_time, ...item}, i) => (
                  <div key={i}
                       className={consumColor === id ? `${styles.checkHas} ${styles.active}` : `${styles.checkHas}`}>
                    <p>{name}</p>
                    {
                      checkType !== 1 ? <div><span>点位定性: {consumType[consum_type]}</span></div> : null
                    }
                    <div><span>所在区域: {area_name}</span></div>
                    <div><span>结案时间: {create_time}</span></div>
                    <Link to={`./${id}/1/suspicious-points/`} onClick={() => setConsumColor(id)}>
                      <div className={styles.showDetail}>查看详情 <img src="/images/radar/leftArrow.png" alt=""/></div>
                    </Link>
                  </div>
                ))
                : <span style={{
                  color: '#26292F',
                  fontSize: 12,
                  display: 'inline-block',
                  textAlign: 'center',
                  width: '100%'
                }}>暂无数据</span>
            }
          </div>
        </Spin>
        <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 10}}>
          <Pagination size="small" total={checkData.total} current={checkParams.page}
                      onChange={(page) => setCheckParams({...checkParams, page: page})}/>
        </div>
      </Drawer>
      <Drawer
        title={grossTitle}
        placement="right"
        onClose={() => {
          setInoutDetailVisible(false);
          setInoutVisible(false);
          setWatchVisible(false);
          setGrossCarOrMap({id: '', department_id: '', flag: false})
        }}
        visible={watchVisible}
        closable={true}
        mask={false}
        destroyOnClose={true}
        className={watchVisible ? styles.transport : ''}
        style={{height: 800, top: 90}}
        width={330}
        bodyStyle={{padding: 10}}
      >
        <div className={styles.searchPart}>
          <div style={{marginBottom: 5}}>
            <DatePicker.RangePicker className={styles.date} allowClear={false} separator={'至'} size="default" format="YYYY-MM-DD " suffixIcon={false} value={grossTime} onChange={next => {setGrossPageLimit({page: 1, limit: 10});setGrossTime(next)}}/>
          </div>
          <Search value={grossKeywords} className={styles.antInput}
                  placeholder="请输入关键字"
                  onChange={(e) => setGrossKeywords(e.target.value)}
                  onPressEnter={(e) => {
                    setGrossPageLimit({page: 1, limit: 10});
                    setGrossKeyword(e.target.value)
                  }}
                  enterButton={false}
          />
        </div>
        <Spin spinning={grossSpan}>
          <div className={styles.grossList}>
            <p>共：{grossListData.total} 个</p>
            {
              grossListData.data.map(({name, address, count, manager, phone, id, department_id, ...item}, i) => (
                <div key={i} className={grossCarOrMap.id === id ? styles.active : ''}>
                  <p onClick={() => {setGrossCarOrMap({id: id, flag: 'map', department_id: department_id})}}>{name}</p>
                  <div className={styles.grossCar}><span>进出车辆：</span><span className={styles.inOrOut} onClick={() => {
                    setInoutVisible(true);
                    setGrossCarOrMap({id: id, flag: 'car'});
                    setInoutTitle(name)
                  }}>{count}</span></div>
                  <div className={styles.grossPeople}><span>负责人：</span><span>{manager}</span></div>
                  <div className={styles.grossPhone}><span>联系电话：</span><span>{phone}</span></div>
                  <div className={styles.grossLocation}><span title={address}>{address}</span></div>
                </div>
              ))
            }
          </div>
        </Spin>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Pagination size="small" total={grossListData.total}
                      onChange={(page) => setGrossPageLimit({page: page, limit: 10})}/>
        </div>
      </Drawer>
      <Drawer
        title={inoutTitle}
        placement="right"
        onClose={() => {
          setInoutDetailVisible(false);
          setInoutVisible(false)
        }}
        closable={true}
        mask={false}
        destroyOnClose={true}
        className={inoutVisible ? styles.inoutClass : ''}
        style={{height: 800, top: 90}}
        width={330}
        bodyStyle={{padding: 10}}
        visible={inoutVisible}
      >
        <div className={styles.searchPart}>
          <Search defaultValue={inoutKey} className={styles.antInput}
                  placeholder="请输入关键字"
                  onPressEnter={(e) => {
                    setInoutPage({page: 1, limit: 10});
                    setInoutKey(e.target.value)
                  }}
                  enterButton={false}
          />
        </div>
        <div>
          <p>共：{inoutData.total} 辆</p>
          <Spin spinning={inoutSpan}>
            <div className={styles.inoutCarClass}>
              {
                inoutData.data.map(({car_num, area_name, transport_name, ...item}, i) => (
                  <div key={i} className={inoutCarNum === car_num ? styles.active : ''} onClick={() => {
                    setInoutDetailVisible(true);
                    setInoutCarNum(car_num)
                  }}>
                    <div><span>{car_num}</span><span>{area_name}</span></div>
                    <div style={{color: '#5C606B'}}>{transport_name}</div>
                  </div>
                ))
              }
            </div>
          </Spin>
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Pagination size="small" total={inoutData.total} onChange={(page) => setInoutPage({page: page, limit: 10})}/>
        </div>
      </Drawer>
      <Drawer
        title={inoutCarNum + '进出详情数据'}
        placement="right"
        onClose={() => setInoutDetailVisible(false)}
        closable={true}
        mask={false}
        destroyOnClose={true}
        className={inoutDetailVisible ? styles.inoutDetailClass : ''}
        style={{height: 800, top: 90}}
        width={330}
        bodyStyle={{padding: 10}}
        visible={inoutDetailVisible}
      >
        <div className={styles.inoutDetailCarClass}>
          {
            inoutDetailData.data.map(({name, time}, i) => (
              <div key={i}><span>{name}: </span><span>{time}</span></div>
            ))
          }
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Pagination size="small" total={inoutDetailData.total}
                      onChange={(page) => setInoutDetailPage({page: page, limit: 10})}/>
        </div>
      </Drawer>
      <Modal
        title={visibleTitle}
        visible={visibleTable}
        width={1200}
        onCancel={() => setVisibleTable(false)}
        footer={[
          <Button key="back" onClick={() => setVisibleTable(false)}>
            关闭
          </Button>,
        ]}
        bodyStyle={{
          maxHeight: '70vh',
          overflowY: 'auto', position: 'relative'
        }
        }
      >
        {
          concertData.flag ?
            <div style={{display: 'flex', marginBottom: 15}}>
              <div><span>消纳场名称: </span> <span>{concertData.name}</span></div>
              <div style={{marginLeft: 15}}><span>消纳场地址: </span> <span>{concertData.address}</span></div>
              <div style={{marginLeft: 15}}><span>消纳场图片: </span> <span style={{cursor: 'pointer', color: '#1491fb'}}
                                                                       onClick={() => preview.image(concertData.images)}>查看图片</span>
              </div>
            </div> : null
        }
        <div className={styles.titleFirst}>
          <Button style={{width: 80, marginBottom: 10}} onClick={() => OutToFile()}><Icon type="download"/>{'导出'}
          </Button>
          <div style={{width: 340}}>
            <Radio.Group value={filterFlag} buttonStyle="solid" style={{marginRight: 15}} onChange={(e) => {
              setFilterFlag(e.target.value)
            }}>
              <Radio.Button value="0">全部</Radio.Button>
              <Radio.Button value="1">{area_name}内</Radio.Button>
              <Radio.Button value="2">入{area_name}</Radio.Button>
              <Radio.Button value="3">出{area_name}</Radio.Button>
            </Radio.Group>
          </div>
          <div className={styles.report}>
            <Link to={`1/download-report`}><Icon type="copy"/>{'周报'}</Link>
            <Link to={`2/download-report`}><Icon type="copy"/>{'月报'}</Link>
          </div>
        </div>
        <div style={{marginBottom: 15, display: 'flex', flexFlow: 'column'}}>
          <Radio.Group value={sourceFlag} buttonStyle="solid" onChange={(e) => {
            setSourceFlag(e.target.value)
          }}>
            <Radio.Button value="0">全部</Radio.Button>
            <Radio.Button value="4">疑似偷排</Radio.Button>
            <Radio.Button value="3">非法消纳</Radio.Button>
            <Radio.Button value="1">指定接管</Radio.Button>
            <Radio.Button value="2">砂石场接管</Radio.Button>
          </Radio.Group>
        </div>
        <div><span>合计: </span><span>方量: {getDataByParams(1, filterDataSource(dataSource))}</span><span
          style={{marginLeft: 10}}>趟数: {getDataByParams(2, filterDataSource(dataSource))}</span></div>
        <div id={'div1'}>
          <Table columns={columns} dataSource={getRowSpan(filterDataSource(dataSource))} bordered pagination={false}/>
        </div>
      </Modal>
      <div className={styles.right_top_box} style={{top: 730, left: 340, right: 'auto'}}>
        <div>
          <div className={styles.all_div} onClick={() => setMapType(false)}>
            <Tooltip placement="right" title="地图" overlayClassName="my_tip">
              <img src={!mapType ? "/images/radar/earth_h.png" : "/images/radar/earth.png"} alt=""/>
            </Tooltip>
          </div>
          <div className={styles.all_div} onClick={() => setMapType(true)}>
            <Tooltip placement="right" title="卫星" overlayClassName="my_tip">
              <img src={mapType ? "/images/radar/third_h.png" : "/images/radar/third.png"} alt=""/>
            </Tooltip>
          </div>
          <div className={styles.all_div} onClick={() => setAreaBorder(!areaBorder)}>
            <Tooltip placement="right" title="边界" overlayClassName="my_tip">
              <img
                src={ areaBorder ? "/images/radar/line_h.png" : "/images/radar/line.png" }
                style={{width: 16.5, height: 16}} alt=""/>
            </Tooltip>
          </div>
        </div>
      </div>

      <Modal
        title="车辆停靠趋势图"
        visible={visibleBuild}
        width={1200}
        onCancel={() => setVisibleBuild(false)}
        footer={[
          <Button key="back" onClick={() => setVisibleBuild(false)}>
            关闭
          </Button>,
        ]}
        bodyStyle={{maxHeight: '70vh', padding: 0,}}
      >
        <div id={'consumChart'} style={{width: '100%', height: 500}}/>
      </Modal>
      <div className='load-plan' onClick={() => setPlanVisible(true)}>拉运计划</div>
      {planVisible && <拉运计划列表 areaId={areaId} dayStr={dates[1].format('YYYY-MM-DD')} onClose={() => setPlanVisible(false)} />}
      {trash_show_detail && (
        <TrashTicketDetail
          areaId={areaId}
          dayStr={dates[1].format('YYYY-MM-DD')}
          trashSpin={trashLoading}
          data={trashDetailData}
          ticketType={(isZx && isAcquirer === 0) ? navNum : 10}
          carModify={(isZx && isAcquirer === 0)}
          style={{ height: 800, left: offlineWorkTicketId.index === 2 ? 340 : 670 }}
          ticketId={ticketId}
          radar={true}
          modifyCarFunc={modifyCarFunc}
          closeTicketDetail={closeTicketDetail}
          startCallDriver={startCallDriver}
        />
      )}
      { call_driver && <CallDriver data={{ msg: msg, call_flag: call_flag }} rtcCallEnd={rtcCallEnd} /> }
      { pointMsg && <PointPreview params={ pointMsg } onClose={ () => setPointMsg() } />}
      { mergeTicket && <MergeTicket ticketTimes={ticketTimes} ticketCarNum={ticketCarNum} ticketId={ticketId} onSuccessT={(id) => {setMergeTicket(); setTicketId(id); getTicketTimes(ticketCarNum, ticketStartTime)}} onClose={() => setMergeTicket()} />}
      { splitTicket && <SplitTicket ticketId={ticketId} onSuccessT={(ids) => {setSplitTicket(); getTicketTimes(ticketCarNum, ticketStartTime); setSplitTicketNumbers(ids)}} onClose={() => setSplitTicket()} />}
      { (overPoints.length > 0 && overTimes.length > 0 && (trashDetailData && trashDetailData.detailInfo.ticket_account_id === 0))  && <OverTicket onClose={(e, flag) => JudgeOverTicketClose(e, flag)} arr={overPoints} m_overDates={overTimes} ticket_id={ticketId} ticketTimes={ticketTimes} ticket_status={ticket_status} />}
      { overByInstances && (trashDetailData && trashDetailData.detailInfo.ticket_account_id === 0) && <OverTicketByInstance onClose={(e, flag) => { setOverByInstances(); setTicketTimes(e); if(flag){ setOverFlag(!overFlag)} } } point={overByInstances} ticket_id={ticketId} ticketTimes={ticketTimes} ticket_status={ticket_status} />}
      { overByPoints && (trashDetailData && trashDetailData.detailInfo.ticket_account_id === 0) && <OverTicketByPoints onClose={(e, flag) => { setTicketTimes(e); setOverByPoints(); if(flag){ setOverFlag(!overFlag)} } } onChange={(e) => setNewConsum(e)} point={overByPoints} ticket_id={ticketId} ticketTimes={ticketTimes} ticket_status={ticket_status} /> }
      { unableVerify && <UnableToVerify ticketId={ticketId} onSuccess={() => {setUnableVerify(); setAccountSource(1);getTicketTimes(ticketCarNum, ticketStartTime)}} onClose={() => setUnableVerify() } />}
      { offlineWorkTicketId.id && <OfflineWork id={offlineWorkTicketId.id} style={{ left: (offlineWorkTicketId.index === 1 || !trash_show_detail) ? 340 : 830 }} onClose={() => setOfflineWorkTicketId({ id: null, index: 2})} />}
      { changePoint && <UseChangePoint id={ticketId} onClose={() => setChangePoint(false)} onChange={() => {setChangePoint(false); getTicketTimes(ticketCarNum, ticketStartTime)}} />}
    </div>
  );
}

export default previewable(TransportRadar)
