import { useState, useEffect } from "react";

class Observable {
  constructor(initialData){
    this.data = initialData;
    this.listeners = [];
  }

  subscribe(listener){
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter(f => Object.is(f, listener));
    };
  }

  refresh = (data) => {
    console.log(data);
    this.data = data;
    this.listeners.forEach(f => f(data));
  }
}

export function useObservable(observable) {
  const [data, setData] = useState(observable.data);
  useEffect(() => observable.subscribe(setData), []);
  return data;
}

export const modifyCarNum = new Observable();



