import React from 'react';
import { Table, Pagination } from 'antd';
import Query from './Query';
import useTable from './useTable';
import styles from './TablePage.scss';

export default function TablePage({
  buttons,
  radioSelect,
  querys,
  columns,
  footer,
  dataSource,
  rowKey = 'id',
  extraParams = {},
  defaultLimit = 15,
  defaultQuery = {},
  backTitle,
  onBack,
  extraTotal=false,
  ratio,
  extraTip=false,
  lineFeed=false,
  rowSelection,
  showOverAlarm=false,
  showNewTicket=false,
  showVolume=false,
  gpsText = false,
  ...props
}) {
  const [data, pagination, setQuery, getQuery, rest] = useTable(dataSource, extraParams, defaultLimit, defaultQuery);
  const showTotal = rest.total_price === undefined ? t => `共 ${t} 条` : t => `运费合计 ${rest.total_price} 元;  共 ${t} 条`;
  const showExtra = (extraTip && rest.is_have_deal) ? extraTip : '';
  const showOver = showOverAlarm && `共${pagination.total}车次，共${rest.car_count}辆车`;
  const showTicket = showNewTicket && `共${rest.volumn_count}方，共${pagination.total}单，共${rest.car_count}辆车`;
  const yardVolume = showVolume && `共${rest.countVolume}方，共${pagination.total}单`; //河道堆场台账
  const cols = columns.map(({ sort, sortAsKey, sortDefaultValue, ...column }) => {
    const sortValue = getQuery()[sortAsKey];
    if (sortValue === undefined && sortDefaultValue !== undefined) {
      setQuery({ [sortAsKey]: sortDefaultValue });
    }
    return {
      ...column,
      sorter: sort,
      sortOrder: !sort ? undefined : ['ascend', 'descend'][sortValue],
      onHeaderCell: !sort ? undefined : () => ({
        onClick: () => setQuery({ [sortAsKey]: sortValue ? 0 : 1 }),
      }),
    };
  });
  return (
    <div className={styles.container}>
      <Query
        className={styles.query}
        querys={querys}
        buttons={buttons}
        radioSelect={radioSelect}
        setQuery={setQuery}
        getQuery={getQuery}
        defaultQuery={defaultQuery}
        backTitle={backTitle}
        onBack={onBack}
        lineFeed={lineFeed}
        rate={ratio ? rest.online_rate : null}
      />
      { extraTotal && <div style={{fontSize: 16}}>{extraTotal + pagination.total}</div> }
      { showExtra && <div style={{fontSize: 16, color: '#F59A23'}}>{extraTip}</div> }
      { showOverAlarm && <div style={{fontSize: 16}}>{showOver}</div> }
      { showNewTicket && <div style={{fontSize: 16}}>{showTicket}</div> }
      { showVolume && <div style={{fontSize: 16}}>{yardVolume}</div> }
      { gpsText && (
        <>
          <p>一、2020年05月20日至2020年05月22日各运输企业执行整改</p>
          <p>二、在线率低于90%的车辆从05月20日开始3天内不予以线路报备</p>
        </>
      )}
      <Table
        className={styles.table}
        bordered
        size="middle"
        rowKey={rowKey}
        scroll={{ x: 'max-content' }}
        columns={cols}
        dataSource={data}
        pagination={false}
        rowSelection={rowSelection}
        {...props}
      />
      <div className={styles.pagination}>
        {footer}
        <Pagination
          {...pagination}
          showTotal={showTotal}
        />
        <div className={styles.mask} data-disabled={pagination.disabled} />
      </div>
    </div>
  );
}
