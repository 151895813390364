import React from 'react';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';

import debug from 'utils/debug';

const dataSource = new DataSource('/web/ticket/deletedIndex');

export default function DeleteRecords() {
  debug.render('DeleteRecords');

  const columns = [
    serial(true),
    { title: '单号', dataIndex: 'number', width: width(0, 18), fixed: 'left' },
    { title: '起点时间', dataIndex: 'start_time', width: width(0, 19) },
    { title: '起点', dataIndex: 'start_name', width: width(20) },
    { title: '运输车', dataIndex: 'car_num', width: width(10) },
    { title: '物品', dataIndex: 'materiel_name', width: width(10) },
    { title: '方量', dataIndex: 'volumn', width: width(0, 10) },
    { title: '删除人', dataIndex: 'deleted_name', width: width(10) },
    { title: '删除时间', dataIndex: 'deleted_time', width: width(0, 19) },
    { title: '删除原因', dataIndex: 'deleted_reason' },
  ];

  const querys = [
    { title: '关键字', key: 'keyword' },
    { title: '起点部门', key: 'start_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', style: { width: 120 } },
    { title: '起点', key: 'start_id', rely: 'start_department_id', relyAs: 'id', type: 'select', options: '/web/instance/getStartOrEnd', valueKey: 'id', labelKey: 'name', showSearch: true, style: { width: 240 } },
    { title: ['起点', '时间'], key: 'start_time', type: 'date-range' },
  ];

  const buttons = [
    { title: '导出', download: '/web/ticket/deletedExportExcel' },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}
