// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ygfqQ1xEdzbk7pUXk9LSJ > span {\n  font-size: 14px;\n  margin-right: 10px;\n  margin-bottom: 10px;\n}\n._3ygfqQ1xEdzbk7pUXk9LSJ > div {\n  margin-top: 10px;\n}\n._3ygfqQ1xEdzbk7pUXk9LSJ > div > span {\n  margin-right: 10px;\n  color: #1491FB;\n  background-color: #F2F2F2;\n  padding: 5px 8px;\n  font-size: 14px;\n}\n\n._2N8EBs30GnDpQQLaN5yry_ {\n  margin-top: 30px;\n  display: flex;\n  flex-direction: column;\n}\n._2N8EBs30GnDpQQLaN5yry_ > span {\n  font-size: 14px;\n  margin-right: 10px;\n  padding: 10px 0;\n}\n._2N8EBs30GnDpQQLaN5yry_ > span > label {\n  font-size: 12px;\n  color: #7F7F7F;\n}", ""]);
// Exports
exports.locals = {
	"carList": "_3ygfqQ1xEdzbk7pUXk9LSJ",
	"greyClass": "_2N8EBs30GnDpQQLaN5yry_"
};
module.exports = exports;
