import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'antd';
import request from 'utils/request';
import store from 'stores/user';
import styles from './WaitTicketDeal.scss';

export default function WaitTicketDeal() {
  const [day, setDay] = useState();
  const [visible, setVisible] = useState(false);
  const storeInfo = store.getState();
  const roleId = storeInfo.roles.id;
  const isZx = (roleId === 111 || roleId === 115 || roleId === 0 || roleId === '111');
  return (
    <React.Fragment>
      {(day > 0 && isZx) && <div className={styles.container} onClick={() => setVisible(true)}>
        你有{day}天数据需要处理
      </div>}
      {<WaitModal visible={visible} onchange={setDay} onClose={setVisible} />}
    </React.Fragment>
  );
}

function WaitModal({ visible, onchange, onClose }) {
  const [data, setData] = useState();
  useEffect(() => {
    request('web/ticket_map/pendCount', {})
      .then(res => res.data.data)
      .then((data) => {
        setData(data);
        onchange(data.dayCount);
      })
  }, [visible]);

  return (   
    <Modal
      title={
        <div>
          待处理提醒
          <span style={{ fontSize: 12, marginLeft: 10 }}>显示近七天数据（不含当日）</span>
        </div>
      }
      visible={visible}
      onCancel={() => onClose(false)}
      onOk={() => onClose(false)}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto'}}
    >
      <Table
        columns={[
          {title: '序号', dataIndex: 'area_name', render: (_, $, i) => i + 1},
          {title: '联单所属区县', dataIndex: 'area_name'},
          {title: '待处理（天）', dataIndex: 'day'},
          {title: '待处理（条）', dataIndex: 'count'},
        ]}
        dataSource={data ? data.data : []}
        pagination={false}
      />
    </Modal>
  );
}

