import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, locales, message } from 'antd';
import store from 'stores/user';
import App from './pages/App';
import './index.theme.less';
import './index.global.scss';

moment.locale('zh-cn');
message.config({ duration: 1 });

const container = document.body.appendChild(document.createElement('div'));
container.setAttribute('id', 'root');

// // HTTP 强转 HTTPS
// if (HTTPS && window.location.protocol === 'http:') {
//   window.location.replace(window.location.href.replace('http://', 'https://'));
// } else {
// }

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ConfigProvider locale={locales.zh_CN}>
        <App />
      </ConfigProvider>
    </Provider>
  </BrowserRouter>,
  container,
);
