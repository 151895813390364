import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Input, Button } from 'antd';
import store from 'stores/user';
import styles from './GetLngLat.scss';

export default function GetLngLat({ onChange, onClose}) {
  const storeInfo = store.getState();
  const {center_one_lng, center_one_lat} = storeInfo.info;
  const [map, setMap] = useState();
  const mapRef = useCallback((element) => !!element && setMap(new BMap.Map(element)), []);
  const [address, setAddress] = useState();
  const [addressArr, setAddressArr] = useState([]);
  const [addressUId, setAddressUId] = useState();
  const [label, setLabel] = useState();
  useEffect(() => {
    if(!map) return;
    map.centerAndZoom(new BMap.Point(center_one_lng, center_one_lat), 16);  // 初始化地图,设置中心点坐标和地图级别
    map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
    map.setDefaultCursor("default");   //设置地图默认的鼠标指针样式
    map.addEventListener("mousemove", function(e) {
      //创建右键菜单
      const opts = {
        position: new BMap.Point(e.point.lng, e.point.lat),// 指定文本标注所在的地理位置
        offset: new BMap.Size(20, 10)    //设置文本偏移量
      };
      setLabel(new BMap.Label(e.point.lng + ',' + e.point.lat, opts));  // 创建文本标注对象
    });
    map.addEventListener("click", function(e){
      onChange(e.point.lng + "," + e.point.lat);
      onClose();
    });
  }, [map]);
  useEffect(() => {
    if(!label || !map) return;
    label.setStyle({
      color : '#333',
      fontSize : '12px',
      height : '20px',
      lineHeight : '20px',
      fontFamily: '微软雅黑',
      border: '1px solid #333'
    });
    map.addOverlay(label);
    return () => map.removeOverlay(label);
  }, [label])
  function addressSearch() {
    console.log(map);
    var local = new BMap.LocalSearch(map, { //智能搜索
      onSearchComplete: myFun
    });
    local.search(address);
    function myFun(){
      setAddressArr(local.getResults().Qq);
      setAddressUId(local.getResults().getPoi(0).uid);
      const pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
      addMarker(pp.lng, pp.lat)
      map.panTo(new BMap.Point(pp.lng, pp.lat))
    }

  }
  function addMarker(lng, lat) {
    map.clearOverlays();
    var marker = new BMap.Marker(new BMap.Point(lng, lat)); // 创建点
    map.addOverlay(marker);
  }
  return (
    <Modal
      visible={true}
      title='获取经纬度'
      footer={null}
      onCancel={onClose}
      width={720}
      bodyStyle={{ height: 500, overflow: 'auto', padding: 0 }}
    >
      <div ref={mapRef} style={{ height: '100%', width: '100%'}} />
      <div className={styles.addressSearch}>
        <Input.Group>
          <Input value={address} placeholder="输入地点搜索" clearable onChange={(e) => setAddress(e.target.value)} />
          <Button type="primary" onClick={() => addressSearch()}>搜索</Button>
        </Input.Group>
        <div className={styles.searchAddress}>
          {addressArr && addressArr.map((item, i) => (
            <div key={i} style={{ padding: 5, cursor: 'pointer', color: addressUId === item.uid ? '#1491fb' : '', background: addressUId === item.uid ? 'rgba(238, 247, 255, 1)' : '' }}>
              <span title={item.title} onClick={() => {setAddressUId(item.uid); addMarker(item.point.lng, item.point.lat); map.panTo(new BMap.Point(item.point.lng, item.point.lat))}}>{item.title}</span>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}
