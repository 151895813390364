import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import { dataSource } from './List';
import { CarInfoByEdit } from './CarInfo';

export default function CreateCar({ history, match }) {
  const {id, car_num} = match.params;
  const back = () => history.goBack();
  const onFinish = () => {
    dataSource.refresh();
    back();
  };
  return (
    <ModalFormPage
      title={`修改${car_num}车辆`}
      init={['web/keep_on_record/getInfoById', { car_num }]}
      submit={['/web/keep_on_record/updateInfo', { id }]}
      inputs={CarInfoByEdit}
      visible={false}
      onCancel={back}
      onFinish={onFinish}
      modalProps={{
        width: 800,
      }}
      formProps={{
        labelCol: { xs: { span: 6 } },
        wrapperCol: { xs: { span: 18 } },
      }}
    />
  )
}
