import React from 'react';
import ReactDOM from 'react-dom';
import '../ToBeConfirmed/index.global.scss';
import store from 'stores/user';
import request, { open } from 'utils/request';
import {
  Input,
  Table,
  DatePicker,
  Select,
  Button,
  Modal,
  Icon,
  Tooltip,
  Pagination,
  Spin,
  Divider
} from 'antd';
import moment from 'moment'
import LinkButton from 'ui/TableCell/LinkButton';
import { Route } from 'react-router-dom';
import Update from './Update';
import controller from "./controller";
import PrintTicket from '../PrintTicket';
import { outInstanceIds } from '../ToBeConfirmed/List';
import Detail from '../ElectronicStrip/Detail';
import PrintModel from '../WareHousing/PrintModel';

const { confirm } = Modal;
const {RangePicker} = DatePicker;
const {Option} = Select;
let start_time = moment().subtract(0, 'day').startOf('day');
let end_time = moment().endOf('day');
let LODOP = null;
let selectPrinter = null;

const date = new Date();
const CreateOneFormPage = function (type) {
  LODOP = getLodop();
  LODOP.PRINT_INIT("打印控件功能演示_Lodop功能_表单一");
  LODOP.SET_PRINT_STYLE("FontSize", 18);
  LODOP.SET_PRINT_STYLE("Bold", 1);
  LODOP.SET_PRINT_PAGESIZE(1, 2160, 930, '');
  LODOP.SET_PRINT_MODE("POS_BASEON_PAPER", true);
  //LODOP.ADD_PRINT_TEXT(50, 231, 260, 39);
  var strStyleCSS = '<link href="/_flex.min.css" type="text/css" rel="stylesheet">';
  var strFormHtml = '<head>' + strStyleCSS + '</head><body><div style="width:750px;">' + document.getElementById("print_t1").innerHTML + '</div></body>';
  LODOP.ADD_PRINT_HTM(25, 25, 150, "BottomMargin:0", strFormHtml);
};

// const oldInstance = [1784, 2999, 3135, 3292, 3323, 2254, 3324, 1770, 3295, 3325, 2218, 3297, 3298, 3326, 3300, 3301, 3302, 3250, 3303, 3142, 3304, 3327, 3308, 3105, 3005, 3088, 3328, 3329, 3309, 3314, 2994, 3210, 3222, 3205, 3221, 3317, 3223, 3321, 3225, 3322, 3056, 2417, 3573, 3214, 7033, 3212];
const oldInstance = [1198,1506,1549,1565,1576,1585,1726,1730,1770,1784,1988,2218,2220,2254,2346,2357,2358,2417,2433,2463,2464,2624,2659,2755,2811,2880,2994,2996,2999,3002,3005,3065,3095,3102,3105,3106,3107,3108,3109,3120,3121,3135,3142,3203,3210,3212,3213,3222,3250,3266,3273,3282,3295,3296,3298,3301,3302,3308,3309,3314,3323,3324,3325,3326,5588,5675,5703,5884,6294,6359,6920,7032, 7033, 7637, 7638,7639,7644,7656,7657,7665,7819,7820,7821,7823, 5894];

const isInArray = function (arr, value) {
  for (var i = 0; i < arr.length; i++) {
    if (value === arr[i]) {
      return true;
    }
  }
  return false;
};

const printOption = [
  {id: 0, name: '未打票'},
  {id: 1, name: '已打票'}
];

const carTypeOptions = [
  {id: 1, name: '名录车'},
  {id: 2, name: '非名录车'},
  {id: 3, name: '合同车'},
  {id: 4, name: '非合同车'},
  {id: 5, name: '车载车'},
  {id: 6, name: '非车载车'}
];

export default class SandStoneReceiver extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      autoCompleteResult: [],
      checkedList: [],
      dataSource: [],
      reason: false,
      keyword: '',
      cancel: '',
      limit: 10,
      page: 1,
      total: 0,
      start_volumn: 0,
      deduction_volume: 0,
      total_volume: 0,
      time: [start_time.format('YYYY-MM-DD HH:mm:ss'), end_time.format('YYYY-MM-DD HH:mm:ss')],
      receiverName: store.getState().info.name,
      userAreaId: store.getState().info.area_id,
      roleId: store.getState().roles.id, 
      car_num: '',
      printCompany: '成都宏信投建材有限公司',
      printJson: {
        number: '',
        car_num: '',
        transport_count: 0,
        volume: 0,
        reason: '',
        driver_name: '',
        build_name: '',
        real_volumn: 24,
        deduction_volume: 0,
        date: '',
        car_directory: '',
        car_plan: '',
        deduction_reason: '',
        car_type: '',
        materielName: '连砂石',
        acquirer_name: ''
      },
      buildingArr: [],
      building_id: '',
      userInstance: [],
      instance_id: 0,
      department_id: '',
      instance_name: '',
      materielType: 1,
      modal_flag: false,
      secondReceiver: JSON.parse(sessionStorage.getItem('secondReceiver')) ? JSON.parse(sessionStorage.getItem('secondReceiver')).name : '',
      printing_count: '',
      license: '',
      selectedRowKeys: [],
      rowSelection: {
        onChange: this.onSelectChange,
        selections: [],
      },
      spinLoading: false
    }
  };
  columns = [
    {title: '序号', dataIndex: 'serial', key: 'serial', width: 70},
    {title: '联单号', dataIndex: 'number', key: 'number'},
    {title: '车牌号', dataIndex: 'car_num', key: 'car_num', width: 150},
    {title: '车辆类型', dataIndex: 'car_type', key: 'car_type', width: 100, render: function(v, t) {return <span style={{color: v === '非名录' ? '#FF3B30' : v === '非砂石名录' ? '#FE9400' : v === '合同' ? '#4BD863' : '#0079FE'}}>{v}</span>}},
    {title: '工地项目', dataIndex: 'building_name', key: 'building_name'},
    {title: '签收时间', dataIndex: 'end_time', key: 'end_time'},
    // {title: '保存时间', dataIndex: 'end_time', key: 'end_time'},
    {title: '收货地点', dataIndex: 'end_name', key: 'end_name'},
    {title: '收货人', dataIndex: 'acquirer_name', key: 'acquirer_name'},
    {
      title: '货箱方量', dataIndex: 'car_volumn', key: 'car_volumn', render: function (e) {
        return parseFloat(e).toFixed(2)
      }
    },
    {
      title: '扣方', dataIndex: 'deduction_volume', key: 'deduction_volume', render: function (e) {
        return parseFloat(e).toFixed(2)
      }
    },
    {
      title: '实际方量', dataIndex: 'volumn', key: 'volumn', render: function (e) {
        return parseFloat(e).toFixed(2)
      }
    },
    {title: '扣方原因', dataIndex: 'reason', key: 'reason'},
    {
      title: '联单来源', dataIndex: 'source', key: 'source', render: function (t, e) {
        return t === '补录' ?
          <Tooltip placement="top" title={e.add_reason}>
            <Button type={'default'} size={'small'}>{t}</Button>
          </Tooltip> : <span>{t}</span>
      }
    },
    {
      title: '是否退货', dataIndex: 'cancel', key: 'cancel', render: function (t) {
        return t === '是' ? <span style={{color: '#ff0000'}}>是</span> : <span>否</span>
      }
    },
    // {title: '打印时间', dataIndex: 'print_time', key: 'print_time'},
    // {
    //   title: '打印次数', dataIndex: 'printing_count', key: 'printing_count', render: function(t){
    //     return t === 0 ? <span style={{color: '#ff0000'}}>{t}</span> : t
    //   }
    // },
    {
      title: '操作', key: 'actions', render: (t, v) => {
        return <>
        {/* <Button size="small" onClick={() => {
          this.getCarTransportNumber(v)
        }}>补打</Button> */}
        {this.state.roleId !== 118 ? (
          <>
            <Divider type="vertical" />
            <LinkButton to={`./${v.ht_id}/deal`}>电子出门条</LinkButton>
          </>
        ) : null}
          <Divider type="vertical" />
          <LinkButton
            to={{
              pathname: './update',
              state: {params: {id: v.id, start_department_id: v.building_department_id, materielName: v.materiel_name, area_id: v.buidling_area_id, start_id: v.building_id, car_num: v.car_num,
                car_volumn: v.car_volumn, deduction_volume: v.deduction_volume, volumn: v.volumn, reason: v.reason === '' ? '泥巴' : v.reason, acquirer_name: v.acquirer_name}
              }
            }
            }>修改</LinkButton>
        </>;
      }
    }
  ];

  componentDidMount() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `/LodopFuncs.js`;
    document.head.appendChild(script);
    request('/web/building/history', {})
      .then(body => body.data.data)
      .then(data => this.setState({buildArr: data}));
    this.getUserInstance();
    // const setSandStone = JSON.parse(sessionStorage.getItem('setSandstone'));
    // if (setSandStone) {
    //   this.setState({
    //     instance_id: setSandStone.instance_id,
    //     department_id: setSandStone.department_id,
    //     instance_name: setSandStone.instance_name,
    //     materielType: setSandStone.materielType,
    //   })
    // }
    setTimeout(() => {
      this.getRightTableData({current: this.state.page, pageSize: this.state.limit});
    }, 1000)
    controller.onRefresh(() => {
      const sessionData = JSON.parse(sessionStorage.getItem('ticketAddAndPrint'));
      this.setState({
        printCompany: !isInArray(oldInstance, sessionData.building_id) ? '成都宏信投建材有限公司' : '成都宏图华信投资有限公司',
        printJson: {
          number: sessionData.number,
          car_num: sessionData.car_num,
          volume: sessionData.volume,
          reason: sessionData.reason,
          driver_name: sessionData.driver_name,
          build_name: sessionData.build_name,
          real_volumn: sessionData.real_volumn,
          deduction_volume: sessionData.deduction_volume,
          date: sessionData.date,
          transport_count: sessionData.transport_count,
          deduction_reason: sessionData.reason,
          car_type: sessionData.car_type,
          car_directory: sessionData.car_directory,
          car_plan: sessionData.car_plan,
          materielName: sessionData.materielName,
          acquirer_name: sessionData.acquirer_name
        }
      });
      // this.printTicket();
      // this.printingCount(sessionData.ticket_id);
      this.getRightTableData();
    })
  };

  onSelectChange = (selectedRowKeys, v) => {
    this.setState({selectedRowKeys: v})
  };
  printTicket = () => {
    CreateOneFormPage();
    if (selectPrinter) {
      LODOP.PRINTA();
    }
    else {
      LODOP.PRINT();
    }
    selectPrinter = false;
  };
  dateChange = (date, dateString) => {
    this.setState({time: dateString, page: 1});
  };
  getUserInstance = () => {
    let that = this;    
    const storeInfo = store.getState();
    const roleId = storeInfo.roles.id;
    request({
      url: '/web/instance/getUserInstance',
      data: {area_id: store.getState().info.area_id}
    }).then(res => {
      const data = res.data.data;
      const signal = data.filter(({ instance_id }) => outInstanceIds.includes(instance_id));
      const arr = roleId === 118 ? (signal ? signal : []) : data.filter(({ instance_id, ...rest }) => !outInstanceIds.includes(instance_id));

      // if (roleId === 118) {
      //   that.onInstanceChange('', { props: { ...signal, value: signal.instance_id, title: signal.department_id } });
      // }
      that.setState({
        userInstance: arr,        
      });
    })
  };
  onInstanceChange = (option, e) => {
    let that = this;
    let instance_name = '';
    if (that.state.userInstance.length > 0) {
      that.state.userInstance.map((v) => {
        if (v.instance_id === e.props.value) {
          instance_name = v.instance_name;
        }
      });
    }
    else {
      instance_name = e.props.instance_name
    }
    this.setState({
      modal_flag: false,
      instance_id: e.props.value,
      department_id: e.props.title,
      instance_name: instance_name,
      materielType:e.props.materielType
    });
    sessionStorage.setItem('setSandstone', JSON.stringify({
      instance_id: e.props.value,
      department_id: e.props.title,
      instance_name: instance_name,
      materielType: e.props.materielType,
    }))
    setTimeout(() => {
      this.getRightTableData({current: this.state.page, pageSize: this.state.limit});
    }, 1000)
  };
  getCarTransportNumber = (v, muil = false) => {
    this.printingCount(v.id);
    return request('/web/ticket/getCarTransportNumber', {ticket_id: v.id})
      .then(body => body.data.data)
      .then((data) => {this.AddPrint(v, data.transport_count, muil)})
  };
  getCarTransportNumbers = (v, muil = false) => {
    const that = this;
    this.printingCount(v.id);
    return request('/web/ticket/getCarTransportNumber', {ticket_id: v.id})
      .then(body => body.data.data)
      .then(async (data) => {
        await new Promise(() => {
          that.AddPrint(v, data.transport_count, muil)
        })
      })
  };
  AddPrint = (v, transport_count, muil = false) => {
    this.setState({
      printCompany: !isInArray(oldInstance, v.building_id) ? '成都宏信投建材有限公司' : '成都宏图华信投资有限公司',
      printJson: {
        number: v.number,
        car_num: v.car_num,
        volume: v.volumn,
        reason: v.reason,
        driver_name: v.driver_name,
        build_name: v.building_name,
        real_volumn: v.car_volumn,
        deduction_volume: v.deduction_volume ? v.deduction_volume : 0,
        date: v.end_time,
        transport_count: transport_count,
        deduction_reason: v.reason,
        car_directory: v.car_directory,
        car_plan: v.car_plan,
        car_type: v.car_type,
        materielName: v.materiel_name,
        acquirer_name: v.acquirer_name
      }
    });
    this.printTicket();
    if (!muil) { // 如果是单选打印，每打印一次重新请求一次数据
      this.getRightTableData({current: this.state.page, pageSize: this.state.limit})
    }
    return true;
  };

  printingCount = (ticket_id) => {
    request('web/ticket/PrintingCount', {ticket_id})
      .then(body => {})
  };

  getRightTableData = (e) => {
    let that = this;
    request({
      url: '/web/ticket/getLeftInfo',
      data: {
        account_source: 1,
        keyword: that.state.keyword,
        time: that.state.time,
        cancel: that.state.cancel,
        instance_id: that.state.instance_id,
        department_id: that.state.department_id,
        building_id: that.state.building_id,
        printing_count: that.state.printing_count,
        license: that.state.license,
        limit: e ? e.pageSize : that.state.limit,
        page: e ? e.current : that.state.page
      }
    }).then(res => {
      const data = res.data.data;
      const source = data.data.map((v, i) => ({
        serial: (((e ? e.current : that.state.page) - 1) * (e ? e.pageSize : that.state.limit)) + (i + 1),
        ...v,
      }));
      that.setState({
        dataSource: source,
        total: data.total,
        buildingArr: data.buildings,
        start_volumn: data.start_volumn,
        deduction_volume: data.deduction_volume,
        total_volume: data.total_volume,
        page: e ? e.current : that.state.page,
      });
    })
  };

  printingMore = () => {
    const that = this;
    confirm({
      title: `确认批量打印${that.state.selectedRowKeys.length}张联单?`,
      width: 300,
      onOk() {
        that.setState({
          spinLoading: true
        });
        const promises = that.state.selectedRowKeys.map(async (v) => {
          return await new Promise((resolve, reject) => {
            that.printingCount(v.id);
            request('/web/ticket/getCarTransportNumber', {ticket_id: v.id})
              .then(body => body.data.data)
              .then(async (data) => {
                resolve({...v, transport_count: data.transport_count})
                // await new Promise(() => {
                //   that.AddPrint(v, data.transport_count, muil)
                // })
              }).catch(() => reject());
          })
          // await new Promise((resolve) => {
          //   that.getCarTransportNumbers(v, true).then(() => {
          //     resolve();
          //   })
          // });
        });
        const ddd = Promise.all(promises).finally((res) => res)
          .then((res) => {
            res.map(({ transport_count, ...rest }) => {
              that.AddPrint({...rest}, transport_count, true)
            })
          })
        .finally(() => {
          that.setState({
            spinLoading: false
          });
          that.getRightTableData({current: that.state.page, pageSize: that.state.limit})
        });
      },
      onCancel() {
      },
    });
  };

  render() {
    const state = {...this.state};
    const write = false; // state.instance_id === 17;
    const borderLeft = !write && {borderLeft: '1px solid black'};
    const borderRight = !write && {borderRight: '1px solid black'};
    const isKf = state.userAreaId !== 11; // 判断是否是青白江账号，青白江的没有扣方选项，那么打票也不应该有

    return (
      <>
        <div className="SandStoneReceiver flex flex-column">
          {/* <div className="topSelect" onClick={() => this.setState({modal_flag: true})}>
            <span>当前开票地点是：{state.instance_name}</span>
            <span style={{fontSize: 12}}>点击这里可重新选择</span>
          </div> */}
          <div className="flex flex-grow">
            <div className="RightSide">
              <div className="RightSearch flex flex-main-between">
                <div>
                  <Button
                    onClick={() => open('/web/ticket/getLeftInfoExcel', {
                      keyword: state.keyword,
                      time: state.time,
                      cancel: state.cancel,
                      instance_id: state.instance_id,
                      department_id: state.department_id,
                      building_id: state.building_id,
                      printing_count: state.printing_count,
                      license: state.license,
                      account_source: 1
                    })}><Icon
                    type="download"/>导出</Button>
                  <PrintModel params={{
                      keyword: state.keyword,
                      time: state.time,
                      cancel: state.cancel,
                      instance_id: state.instance_id,
                      department_id: state.department_id,
                      building_id: state.building_id,
                      printing_count: state.printing_count,
                      license: state.license,
                      account_source: 1,
                      limit: 1000,
                      page: 1
                    }} />
                </div>
                
                <div className={"flex flex-cross-center"}>
                  <div>第二收货人：{state.secondReceiver === '' ? '暂无' : state.secondReceiver}</div>
                  <Input placeholder="请输入车牌/单号/运企搜索" onChange={(e) => this.setState({keyword: e.target.value, page: 1})}
                         style={{marginRight: 10}}/>
                  <Select placeholder="请选择是否已打票" allowClear style={{width: 150, marginRight: 10}}
                          onChange={(e) => this.setState({printing_count: e, page: 1})}>
                    {
                      printOption.map(({id, name}, i) => (
                        <Option value={id} key={i}>{name}</Option>
                      ))
                    }
                  </Select>
                  <Select placeholder="请选择收货地点" allowClear style={{width: 180, marginRight: 10}}
                          onChange={(e) => this.setState({instance_id: e, page: 1})}>
                    {
                      state.userInstance.map((v, i) => {
                        return <Option value={v.instance_id} title={v.department_id} materielType={v.materielType} key={i}>{v.instance_name}</Option>
                      })
                    }
                  </Select>
                  <Select placeholder="请选择工地项目" allowClear style={{width: 180, marginRight: 10}}
                          onChange={(e) => this.setState({building_id: e, page: 1})}>
                    {
                      state.buildingArr.map(({id, name}, i) => (
                        <Option value={id} key={i}>{name}</Option>
                      ))
                    }
                  </Select>
                  <Select placeholder="请选择是否整车退货" allowClear style={{width: 220, marginRight: 10}}
                          onChange={(e) => this.setState({cancel: e})}>
                    <Option value={1} key={1}>整车退货</Option>
                    <Option value={0} key={0}>正常收货</Option>
                  </Select>
                  <Select placeholder="车辆类型" allowClear style={{width: 120}}
                          onChange={(e) => this.setState({license: e, page: 1})}>
                    {
                      carTypeOptions.map(({id, name}, i) => (
                        <Option value={id} key={i}>{name}</Option>
                      ))
                    }
                  </Select>
                  <RangePicker allowClear={false}
                               style={{width: 350, marginLeft: 10}}
                               defaultValue={[start_time, end_time]}
                               showTime={{format: 'HH:mm:ss'}}
                               format="YYYY-MM-DD HH:mm:ss"
                               placeholder={['开始日期', '结束日期']}
                               onChange={this.dateChange}
                  />
                  <Button type="primary" onClick={() => {
                    this.setState({selectedRowKeys: []});
                    this.getRightTableData({current: state.page, pageSize: state.limit})
                  }}>搜索</Button>
                </div>
              </div>
              <div style={{marginBottom: 10}}>
                {isKf ? <span style={{color: '#F95E5A'}}>已入库{state.total}单/总货箱方量{state.start_volumn}方/总扣方量{state.deduction_volume}方/总实际方量{state.total_volume}方</span>
                  : <span style={{color: '#F95E5A'}}>已入库{state.total}单/总货箱方量{state.start_volumn}方/总实际方量{state.total_volume}方</span>}
                {/*<span style={{color: '#F95E5A'}}>已入库{state.total}单/ {state.total_volume}方</span>*/}
              </div>
              <Table className="table" rowKey={"id"} rowSelection={this.state.rowSelection} 
                     dataSource={state.dataSource} bordered={true} columns={this.columns}
                     pagination={false}
              />
              <div className="flex flex-main-between">
                {/* <div>

                </div> */}
                <Button type="primary" onClick={this.printingMore} disabled={state.selectedRowKeys.length > 0 ? false : true}>批量打印</Button>
                <Pagination
                  current={state.page} pageSize={state.limit} total={state.total} showSizeChanger
                  onChange={(e) => {
                    this.setState({page: e});
                    this.getRightTableData({current: e, pageSize: state.limit})
                  }}
                  onShowSizeChange={(e, limit) => {
                    this.setState({limit: limit});
                    this.getRightTableData({current: 1, pageSize: limit})
                  }} showTotal={() => {
                  return `共${state.total}条`
                }
                }/>
              </div>
            </div>
          </div>
          <Modal
            title={'选择实例'}
            visible={state.modal_flag}
            centered={true}
            onCancel={() => this.setState({modal_flag: false})}
            width={400}
            footer={null}
            maskClosable={false}
            destroyOnClose={true}
            bodyStyle={{height: 100, overflow: 'auto'}}>
            <div className='flex flex-main-end'>
              <Select placeholder="选择实例" value={state.instance_id} style={{width: 350}} onChange={this.onInstanceChange}>
                {
                  state.userInstance.map((v, i) => {
                    return <Option value={v.instance_id} title={v.department_id} materielType={v.materielType} key={i}>{v.instance_name}</Option>
                  })
                }
              </Select>
            </div>
          </Modal>
          <PrintTicket write={write} state={state} isKf={isKf} borderLeft={borderLeft} borderRight={borderRight} />
          { ReactDOM.createPortal(<Spin spinning={state.spinLoading} size={'large'} style={{position: 'absolute', top: 0, margin: 'auto', bottom: 0, left: 0, right: 0, width: 50, height: 50, zIndex: 1111111111}} />, document.getElementById('root'))}
        </div>
        <Route path={this.props.path + 'update'} component={Update} />
        <Route path={this.props.path + ':id/deal'} component={Detail} />
      </>
    );
  }
};
