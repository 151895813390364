/* 车牌纠错弹窗 */
import React, { useState } from 'react'
import { Modal, Input, message } from 'antd'
import styles from './LicensePlateCorrection.scss'
import request from 'utils/request';
import {modifyCarNum} from 'components/Previewer';

export default function ChangePlateNumber({id, onClose}) {
  const [content, setContent] = useState('');
  const [msg, setMsg] = useState('');
  function showConfirm() {
    request('web/count_alarm/modifyCarNum', { id, car_num: content })
      .then(body => {
        if(body.data.code === 200){
          setMsg('车牌纠错成功');
          modifyCarNum.refresh({car_num: content, id: id});
          setTimeout(() => {
            onClose()
          }, 1000)
        }else{
          setMsg(body.data.msg)
        }
      })
  }
  return (
    <div>
      <Modal title="车牌纠错" visible={true} onOk={showConfirm} destroyOnClose={true}
             wrapClassName={styles.warpAlarm}
             onCancel={onClose}
             okText="保存"
             cancelText="取消"
             centered={true}
             style={{ textAlign: 'center' }}
             width='400px'
             className={styles.PlateModal} >
        <h3 style={{ marginBottom: 19, fontSize: 20 }}>纠正车牌信息</h3>
        <p style={{color: '#ff0000', fontSize: 16}}>{msg}</p>
        <Input placeholder="输入车牌号码" value={content} onChange={(e) => setContent(e.target.value)} className={styles.input} />
      </Modal>
    </div>
  )
}
