import React, {useState, useEffect} from 'react';
import request from 'utils/request';
import {Modal, Row, Col, Button} from 'antd';
import { previewable } from 'components/Previewer';
import styles from "./points.scss";

function getHistoryDetail(id){
  const [data, setData] = useState({});
  useEffect(() => {
    request('/web/suspect_point/historyDetail', {id})
      .then(body => body.data.data)
      .then(setData)
  }, [id]);
  return data;
}

function HistoryDetail({history, match, location, preview}) {
  const back = () => history.goBack();
  const {params : {history_id}} = match;
  const historyDetail = getHistoryDetail(history_id);

  return (
    <Modal
      title="排查详情"
      bodyStyle={{maxHeight: '80vh', overflowY: 'auto'}}
      centered={true}
      width={1000}
      visible={true}
      onOk={back}
      onCancel={back}
      footer={<Button onClick={back}>关闭</Button>}
    >
      <Row className={styles.row}>
        <Col span={2} className={styles.colRight}>点位定性</Col>
        {/*1非法点位2非违规区域*/}
        {
          historyDetail.consum_type === 1 ?
            <Col span={4} className={styles.colRight}>非法点位</Col> :
            historyDetail.consum_type === 2 ?
              <Col span={4} className={styles.colRight}>非违规区域</Col> : <Col span={4} className={styles.colRight}>{}</Col>
        }
        <Col span={2} className={styles.colRight}>地址信息</Col>
        <Col span={14}>{historyDetail.address}</Col>
      </Row>
      <Row className={styles.row}>
        <Col span={2} className={styles.colRight}>其他信息</Col>
        <Col span={14}>{historyDetail.other}</Col>
      </Row>
      <Row className={styles.row}>
        <Col span={2} className={styles.colRight}>结案时间</Col>
        <Col span={14}>{historyDetail.created_time}</Col>
      </Row>
      <Row className={styles.row}>
        <Col span={2} className={styles.colRight}>处罚结果</Col>
        {/*1现金处罚2维护现场3暂不处罚4其他*/}
        <Col span={4} className={styles.colRight}>
          {
            historyDetail.punished_status === 1 ? '现金处罚' :
              historyDetail.punished_status === 2 ? '维护现场' :
                historyDetail.punished_status === 3 ? '暂不处罚' : '其他'
          }
        </Col>
        <Col span={2} className={styles.colRight}>描述</Col>
        <Col span={14}>{historyDetail.desc}</Col>
      </Row>
      <Row className={styles.row} style={{height: 60}}>
        <Col span={2} className={styles.colRight}>处罚单据</Col>
        <Col span={14}>
          {
            historyDetail.ticket && historyDetail.ticket !== '' ?
              <span style={{cursor: 'pointer', color: '#1491FB'}} onClick={() => preview.image(historyDetail.ticket)}>查看单据</span> : '暂无处罚单据'
          }
        </Col>
      </Row>
    </Modal>
  )
}

export default previewable(HistoryDetail)
