import React, {useState, useEffect} from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import store from 'stores/user';
import request from 'utils/request';
import {message} from 'antd';
import {dataSource} from './List';
const ReportType = [{value: 1, label: '新增'}, {value: 2, label: '退出'}];
const inputs = [
  { label: '标题', placeholder: '请输入标题', key: 'title', required: false },
  { label: '备案类型', options: ReportType, type: 'radio', key: 'report_type', required: true, value: 1 },
  { label: '车牌号', placeholder: '请选择报备车辆', key: 'car_list', showSearch: true, option_type: 'report_type', type: 'select', options: 'web/keep_on_record/tmpCarList', valueKey: 'car_num', labelKey: 'car_num', required: true, mode: 'multiple' },
  { label: '报备要件', key: 'files', type: 'upload-dragger', required: true, officialMsg: 'report_type', showMsg: '' },
  { label: '备注', placeholder: '请输入备注', key: 'remark', type: 'textarea', autoSize: { minRows: 4, maxRows: 10 }},
];
export default function ListReport({ history, match }) {
  const back = () => history.goBack();
  function onFinish(data){
    const transport_id = store.getState().info.user_instance[0].instance_id;
    const area_id = store.getState().info.area_id;
    const title = (!data.title || data.title === '') ? data.report_type === 1 || data.report_type === '1' ? '新增车辆名录报备' : '退出车辆名录报备' : data.title;
    const json = {...data, title, car_list: data.car_list.join(','), car_count: data.car_list.length, transport_id: transport_id, area_id: area_id};
    request('/web/report/createOfficialReport', json)
      .then(body => {
        if(body.data.code !== 200){
          message.warn(body.data.msg)
        }else{
          message.success(body.data.msg);
          dataSource.refresh();
          history.goBack();
        }
      })
  }
  return (
    <ModalFormPage
      title="名录报备"
      inputs={inputs}
      onCancel={back}
      onFinish={ onFinish }
      modalProps={{
        width: 800,
      }}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}
    />
  );
}
