import React from 'react';
import { Link } from 'react-router-dom';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import serial from 'ui/TableCell/serial';

export const dataSource = new DataSource('/web/car/sandCarDirectoryList');

export default function List() {
  const columns = [
    serial(true),
    { title: '运企名称', dataIndex: 'transport_name' },
    { title: '车牌号', dataIndex: 'car_num' },
    { title: '核定方量', dataIndex: 'volumn', render: ($, { id, car_num }) => <Link to={`./${id}/volume?car_num=${car_num}`}>{$}</Link> },
    { title: '方量最近修改时间', dataIndex: 'volumn_record_time' },
    { title: '方量最近修改人', dataIndex: 'volumn_record_user_name' },
  ];

  const querys = [
    { title: '请选择运企', key: 'transport_id', type: 'select', options: '/river/dc_ticket/searchTransportLists', extraData: { transport_type: 2 }, valueKey: 'id', labelKey: 'name', showSearch: true, allowClear: true, style: { width: 300 } },
    { title: '请输入车牌号模糊搜索', key: 'keyword' },
  ];

  const buttons = [];

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}
