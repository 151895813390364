/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Timeline, Card, Spin } from 'antd';
import { previewable } from 'components/Previewer';
import request from 'utils/request';
import styles from './Flow.scss';

function footer(value, placeholder = '无') {
  return value || placeholder;
}

function FormComponent({ preview, children }) {
  if (!children || children.length === 0) return null;

  function types(type, value) {
    switch (type) {
      case 103: return <span onClick={() => preview.image(value)}>查看</span>;
      default: return footer(value);
    }
  }

  return (
    <div className={styles.forms}>
      {children.map(({ name, items }, key) => (
        <Card
          className={styles.form}
          key={key}
          size="small"
          type="inner"
          title={name}
          bodyStyle={{ color: 'black' }}
        >
          {items.map(({ name, type, submit }, key) => (
            type !== 116 ?
              <div className={styles.cell} key={key}>
                <span>{name}</span>
                <span className={styles.submit} data-type={type}>{types(type, submit)}</span>
              </div> : null
          ))}
        </Card>
      ))}
    </div>
  );
}

const Form = previewable(FormComponent);

function Node({ node_name, status_text, status, created_time, deal_time, user_name, forms }) {
  return (
    <Card
      className={styles.node}
      data-status={status}
      size="small"
      bordered={false}
      title={node_name}
      extra={<span className={styles.status} data-status={status}>{status_text}</span>}
      bodyStyle={{ color: 'black' }}
    >
      <div className={styles.cell}><span>创建时间</span><span>{footer(created_time)}</span></div>
      <div className={styles.cell}><span>处理时间</span><span>{footer(deal_time)}</span></div>
      <div className={styles.cell}><span>处理人员</span><span>{footer(user_name)}</span></div>
      <Form>{forms}</Form>
    </Card>
  );
}

export default function Flow({ id, history, match }) {
  const [nodes, setNodes] = useState([]);
  const [spinning, setSpinning] = useState(false);
  useEffect(() => {
    setSpinning(true)
    request('/web/count_alarm/getNoticeDetail', { id: id }).then(body => setNodes(body.data.data.nodes)).finally(() => setSpinning(false));
  }, [id]);
  return (
    <Spin spinning={spinning}>
      <Timeline size={'large'} className={styles.content}>
        {nodes.map((node, key) => (
          <Timeline.Item key={key}>
            <Node {...node} />
          </Timeline.Item>
        ))}
      </Timeline>
    </Spin>
  );
}
