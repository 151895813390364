import React from 'react';
import './failureReport.scss';
import {
  Table,
  Input,
  Button,
  Form,
  Select,
  DatePicker,
  Radio,
  InputNumber,
  Checkbox,
  Icon,
  Divider,
} from 'antd';
import request from 'utils/request';
import Uploader from 'components/Uploader';
import moment from 'moment';
import _ from 'lodash';

const FormItem = Form.Item;
const {TextArea} = Input;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;

const changeData = [];
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

export default class FailureReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form_id: props.data.form_id,
      allData: [],
      sourceData: [],
      columnsData: [],
      changeData: [],
      editValue: '',
      editIndex: '',
      editIndexJ: '',
      tableWidth: 200,
      visibility: false,
      addRow: false,
      delRow: false,
      note: '',
      defaultDate: '',
    }
  };

  getInput = (text) => {
    let type = text.types, answers = text.answers, value = text.value, values = text.values, index = text.index, indexJ = text.indexJ, fixIndexJ = text.fixIndexJ;
    let that = this;
    if(!answers){
      answers = '';
    }
    if (type === 1 || type === '1') {
      return <Input value={answers} data-index={index} data-indexj={indexJ} onChange={(e) => this.onChange(e.target.value, index, indexJ)}/>;
    }
    else if (type === 2 || type === '2') {
      return <TextArea value={answers} data-index={index} data-indexj={indexJ} onChange={(e) => this.onChange(e.target.value, index, indexJ)}/>;
    }
    else if (type === 3 || type === '3') {
      const option = [];
      for (let i = 0; i < values.length; i++) {
        option.push({value: values[i]})
      }
      return <Select style={{width: '180px'}} value={answers} onChange={(value)=>this.onSelectChange(value, index, indexJ)}>
        {
          option.map((v, i) => {
            return (
              <Option key={i} value={v.value} title={index} className={indexJ}>{v.value}</Option>
            )
          })
        }
      </Select>
    }
    else if (type === 4 || type === '4') {
      const fileList = typeof(answers) === 'string'?answers.length === 0?[]:[{
        uid: 1,
        name: '文件',
        status: 'done',
        url: answers,
      }]:answers;
      return (
        <div>
          <Uploader fileList={fileList} onChange={(files)=>this.onUploadChange(files, index, indexJ)}>
            <Button disabled={answers.length >= 1}>
              <Icon type="upload"/> 上传
            </Button>
          </Uploader>
        </div>
      )
    }
    else if (type === 5 || type === '5') {
      return (
        <DatePicker showTime
                    format="YYYY-MM-DD HH:mm:ss"
                    value={answers === ''?'':moment(answers, dateFormat)}
                    style={{width: '160px'}}
                    onChange={(value, valueStr) => this.onDateChange(valueStr, index, indexJ)}
        />
      )
    }
    else if (type === 6 || type === '6') {
      const option = [];
      for (let i = 0; i < values.length; i++) {
        option.push({value: values[i]})
      }
      return <RadioGroup value={answers} name={index + ',' + indexJ} onChange={(e)=>this.onRadioChange(e.target.value, index, indexJ)}>
        {
          option.map((v, i) => {
            return (
              <Radio key={i} value={v.value}>{v.value}</Radio>
            )
          })
        }
      </RadioGroup>
    }
    else if (type === 7 || type === '7') {
      const options = [];
      for (let i = 0; i < values.length; i++) {
        options.push({label: values[i], value: values[i]})
      }
      const checkData = answers.split('|');
      return (
        <div>
          <CheckboxGroup options={options} value={checkData} onChange={(value) => this.onCheckChange(value, index, indexJ)}/>
        </div>
      )
    }
    else if (type === 8 || type === '8') {
      return <div><InputNumber value={answers} onChange={(value) => this.onNumberChange(value, index, indexJ)}/></div>
    }
    else if (type === 9 || type === '9') {
      const fixData = that.state.allData.fixedData;
      return <Input value={fixData[index][fixIndexJ]} disabled/>
    }
    return null;
  };

  _dataChange = (value, i, j) => {
    this.setState(state => ({
      sourceData: state.sourceData.map((item, index) => index !== i ? item : {
        ...item,
        [`data${j}`]: {
          ...item[`data${j}`],
          answers: value
        }
      }),
    }));
  };
  formate = (values) => {
    return values.map(item => {
      return [...Object.entries(item)].map(([key, value]) => {
        switch ('' + value.types) {
          case '4':
            try{
              return value.answers[0].response.src;
            }catch{
              try {
                return value.answers;
              }catch {
                return '';
              }
            }
            break;
          case '5':
            try{
              return value.answers;
            }catch{
              return '';
            }
            break;
          case '7':
            try{
              return value.answers;
            }catch{
              return '';
            }
            break;
          case '9':
              return this.state.allData.fixedData[value.index][value.indexJ];
            break;
          default:
            return value.answers;
            break;
        }
      })
    });
  };

  onChange = (value, i, j) =>  {
    this._dataChange(value, i, j);
  };
  onSelectChange = (value, i, j) => {
    this._dataChange(value, i, j);
  };
  onUploadChange = (files, i, j) => {
    this._dataChange(files, i, j);
  };
  onDateChange = (value, i, j) => {

    this._dataChange(value, i, j);
  };
  onRadioChange = (value, i, j) => {
    this._dataChange(value, i, j);
  };
  onCheckChange = (value, i, j) => {
    this._dataChange(value.join('|'), i, j);
  };
  onNumberChange = (value, i, j) => {
    this._dataChange(value, i, j);
  };

  /*dateFocus = (index, indexJ) => {
    this.setState({
      editIndex: index,
      editIndexJ: indexJ,
    })
  };
  onCheckClick = (index, indexJ) => { //点击获取index indexJ
    this.setState({
      editIndex: index,
      editIndexJ: indexJ,
    })
  };
  onChange = (e) => {
    e.persist();
    let target = e.target;
    let index = target.dataset.index;
    let indexJ = target.dataset.indexj;
    changeData[index][indexJ] = target.value;
  };
  onSelectChange = (option, e) => {
    let val = e.props;
    changeData[val.title][val.className] = val.value;
  };
  onDateChange = (e, str) => {
    changeData[this.state.editIndex][this.state.editIndexJ] = str;
  };
  onCheckChange = (e) => {
    let that = this;
    let str = e.join('|');
    setTimeout(function () {
      changeData[that.state.editIndex][that.state.editIndexJ] = str;
    }, 1000)

  };
  onRadioChange = (e) => {
    let val = e.target.name;
    let index = val.split(',')[0];
    let indexJ = val.split(',')[1];
    changeData[index][indexJ] = e.target.value;
  };
  onUploadChange = (e) => {
    let that = this;
    setTimeout(function () {
      changeData[that.state.editIndex][that.state.editIndexJ] = e.file.name;
    }, 1000)
  };
  onNumberChange = (e) => {
    let that = this;
    setTimeout(function () {
      changeData[that.state.editIndex][that.state.editIndexJ] = e;
    }, 1000)
  };*/

  romanceHeader = (data) => {
    let that = this;
    let index = 0;
    const head = data.head;
    let tableWidth = 0;
    const titleArr = [];

    data.titles.detail_title.map((v, i) => {
      titleArr.push({
        title: v.count_name,
        children: [],
        width: 200 * (v.cross_column)
      });
      for (let j = 0; j < v.cross_column; j++) {
        titleArr[i].children.push(
          {
            title: head[j + index],
            dataIndex: 'data' + (j + index),
            key: 'data' + (j + index),
            width: 200,
            render: (text, record) => {
              return that.getInput(text);
              // let key = 'data' + j;
              // return that.getInput(record[key],);
            }
          })
      }
      index += v.cross_column;
    });

    if (that.state.addRow === true ) {
      titleArr.push({
        title: '操作',
        width: 200,
        render: (text, record, key) => {
          return <Button type="danger" onClick={() => this.delRow(key)}>删除</Button>;
        }
      })
    }

    that.setState({
      columnsData: titleArr,
      changeData: changeData
    });
  };
  romanceTableData = () => {
    let that = this;
    let bodyData = [];

    request({
      url: '/web/Form/getForm',
      data: {
        id: that.state.form_id,
        types: 2,
      }
    })
      .then(body=>body.data)
      .then(({data})=>{
        if ((data.fill === 2 || data.fill === '2') && data.fixed === false) {
          that.setState({
            addRow: true,
            delRow: true,
          })
        }
        that.romanceHeader(data);

        data.body.map((item, i) => {
          bodyData.push({});
          changeData.push([]);
          let keyData = '';
          let indexJ = 0;
          let fixIndexJ = 0;
          let extraArr = [];
          for (let j = 0; j < item.length; j++) {
            extraArr.push(item[j].answers);
            keyData = 'data' + j;
            item[j]['index'] = i;
            item[j]['indexJ'] = indexJ;
            if (item[j].types === '9' || item[j].types === 9) {
              item[j]['fixIndexJ'] = fixIndexJ;
              fixIndexJ++;
            }
            indexJ++;
            bodyData[i][keyData] = item[j];
          }
          changeData[i] = extraArr;
        });

        that.setState({
          allData: data,
          sourceData: bodyData,
          changeData: changeData,
          note: data.titles.note,
        });
      });
  };


  saveTable = () => {
    console.log('编辑后的数据', this.formate(this.state.sourceData));
    this.props.saveForm(this.formate(this.state.sourceData));
  };
  cancelBtn = () => {
    this.props.closeModal(this.state.visible)
  };

  addRow = () => {
    const source = this.state.sourceData[this.state.sourceData.length - 1];
    this.setState({
      sourceData: [...this.state.sourceData, _.fromPairs([...Object.entries(source)].map(([key, value]) => { return [key, {...value, index: value.index + 1}] }))],
    });
  };
  delRow =(i) => {
    this.setState({
      sourceData: this.state.sourceData.filter((value, key) => key !== i)
    });
  };

  componentDidMount() {
    this.romanceTableData();
  }

  render() {
    const state = {...this.state};
    const {visibility} = this.props;
    return (
      <div className='failure'>
        <Table
          pagination={false}
          size="middle"
          bordered
          scroll={{x: 3600, y: 450}}
          columns={state.columnsData}
          dataSource={state.sourceData}
          rowClassName="editable-row"
        />

        {
          state.addRow==true ? <Button onClick={()=>this.addRow()} type="primary" size="small">添加</Button> : ''
        }

        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10}}>
          <div>
            <span style={{fontSize: 14, color: '#808080'}}>备注： {state.note}</span>
          </div>
          <div>
            <Button type='primary' onClick={() => {this.saveTable()}} >暂存</Button>
            <Divider type="vertical" />
            <Button onClick={() => {this.cancelBtn()}} >取消</Button>
          </div>
        </div>
      </div>
    )
  }
}

