import React from 'react';
import { Route } from 'react-router-dom';
import List from './List';
import Detail from './Detail';
import Deal from './Deal';
import Verify from './Verify';
import Create from './Create';

export default ({ path }) => (
  <>
    <Route path={path} component={List} />
    <Route path={path + 'create'} component={Create} />
    <Route path={path + ':id/detail'} component={Detail} />
    <Route path={path + ':id/deal'} component={Deal} />
    <Route path={path + ':id/verify'} component={Verify} />
  </>
);
