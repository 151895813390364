import dataSource from './dataSource';
import ThreeSteps from './ThreeSteps';

const [Section, Create, Update] = ThreeSteps(dataSource, {
  title: '安全员',
  name: 'securities',
  deleteApi: '/web/Transport/deleteOneSecurity',
  createApi: '/web/Transport/createOneSecurity',
  updateApi: {
    init: '/web/Transport/getOneSecurity',
    submit: '/web/Transport/updateOneSecurity',
  },
  indexs: [
    { label: '姓名', required: true, key: 'name' },
    { label: '身份证号', required: true, key: 'id_card' },
    { label: '手机号', required: true, key: 'phone' },
    { label: '任命书文号', required: false, key: 'number' },
    { label: '任命书有效期开始', required: false, key: 'start_time', type: 'date', format: 'YYYY-MM-DD', render: ds => (ds ? ds.slice(0, 10) : '') },
    { label: '任命书有效期结束', required: false, key: 'end_time', type: 'date', format: 'YYYY-MM-DD', render: ds => (ds ? ds.slice(0, 10) : '') },
    { label: '培训证证号', required: false, key: 'certificate' },
  ],
});

export {
  Section as SecuritiesSection,
  Create as SecuritiesCreate,
  Update as SecuritiesUpdate,
};
