import React, { useState, useEffect } from 'react';
import { List } from 'immutable';
import DatePicker from 'ui/FormItem/DatePicker';
import Selector from 'ui/FormItem/Selector';
import Uploader from 'ui/FormItem/Uploader';
import { Button } from 'antd';
import styles from './MediasList.scss';

export default function MediasList({ value, onChange, initList, lists, modify, ...props }) {
  const types = [
    { id: 6,	name: '空载至满载状态变化' },
    { id: 7,	name: '满载至空载状态变化' },
    { id: 11,	name: '起点进场照片' },
    { id: 12,	name: '终点进入' },
    { id: 13,	name: '起点离场照片' },
    { id: 14,	name: '终点离场图片' },
  ];
  const [values, setValues] = useState(List(value ? [...value] : []));

  const updateData = (key, value, row, index) => {
    const json = { ...row };
    json[key] = value;
    setValues((v) => (v.set(index, { ...json })));
  }
  useEffect(() => {
    onChange && onChange([...values]);
  }, [JSON.stringify(values)]);

  function renderList({ type, key, label, item, value, index }) {
    switch (type) {
      case 'date': return <DatePicker key={key} value={value} showTime={true} format={'YYYY-MM-DD HH:mm:ss'} onChange={(e) => updateData(key, e, item, index)} />;//onChange={setter(key)}
      case 'select': return <Selector key={key} options={types} value={value} labelKey={'name'} valueKey={'id'} onChange={(e) => updateData(key, e, item, index)} />;// onChange={onChange}
      case 'upload': return <Uploader key={key} maxLength={1} value={value} onChange={(e) => updateData(key, e, item, index)} />;//onChange={setter(key)} disabled={disabled}
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.btns}><Button type={'primary'} onClick={() => setValues((data) => data.push(initList))}>添加</Button></div>
      {values && values.size > 0 && [...values].map((item, i) => (
        <div className={styles.rows}>
          {lists.map(({ type, key, label }) => (
            renderList({ type, key, label, value: item[key], item, index: i })
          ))}
          <div className={styles.del}>
            <Button type={'danger'} onClick={() => setValues((data) => data.delete(i))}>删除</Button>
          </div>
        </div>
      ))}
    </div>
  )
}
