import React from 'react';
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts';

export default function Line({data}) {
  if (data.length === 0) {
    return null;
  }

  let legend = [];
  let dataArr = [];
  let labels = [];
  data.map((item) => {
    legend.push(item.name);
    dataArr.push(item.data);
  });
  dataArr[0].map((item) => {
    labels.push(item.label);
  });

  let series = [];
  data.map((item) => {
    series.push({
      type: "line",
      data: item.data.map((val) => val.value),
      name: item.name,
      lineStyle: {
        width: 3,
      },
      label: { //在折线上显示相对应的数值
        normal: {
          show: true,
          position: 'top'
        }
      },
    })
  });

  const getOption = () => {
    return {
      color: ['#EF476F','#FFD23F','#35A7FF'],
      title: {
        text: ''
      },
      tooltip: {
        trigger: 'axis',
        formatter: function(params){
          let result = '日期：' + params[0].name + '<br/>';
          for(let i = 0;i < params.length;++i){
            if(params[i].seriesIndex == 0){
              result += params[i].seriesName + '：' + (params[i].value) + '<br/>';
            }else{
              result += params[i].seriesName + '：' + params[i].value + '<br/>';
            }
          }
          return  result;
        }
      },
      legend: {
        data: legend
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '50',
        containLabel: true
      },
      dataZoom: [{
        show: true,
        realtime: true,
        start: 0,
        end: 100
      },{
        type: 'inside',
        realtime: true,
        start: 0,
        end: 100
      }],
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: labels
      },
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
      ],
      series: series
    }
  };

  return (
    <ReactEcharts
      option={getOption()}
      style={{height: '500px', width: '100%'}}
    />
  );
}
