// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1WlNvYIKHT4nRka2S0ANsj {\n  display: flex;\n}\n\n._2B98S5XJLpQ4RaVgfxBvkK {\n  flex-flow: column;\n}\n\n.BYPkPXdrE2tDBbPn1gcAk {\n  align-items: center;\n}\n\n.JDXh7tg31dOKVIbFeDavc {\n  flex-shrink: 0;\n}\n\n._2jhp7griQhz8rWmrK6KOgA {\n  width: 2px;\n  background-color: #1491FB;\n}\n\n.ZlJl_MQmjr4L6xstIhVjM {\n  display: flex;\n  flex-wrap: wrap;\n}\n.ZlJl_MQmjr4L6xstIhVjM > span {\n  font-size: 14px;\n  cursor: pointer;\n  color: #ffffff;\n  background-color: #1491FB;\n  border-radius: 4px;\n  margin-left: 10px;\n  padding: 2px 10px;\n  margin-bottom: 10px;\n}\n.ZlJl_MQmjr4L6xstIhVjM > span > span > a {\n  color: #ffffff;\n}\n\n._2U8AxVroi6RafBnnW_HJc {\n  border-radius: 5px;\n  border: 1px solid #f8f8f8;\n  background-color: #F2F2F2;\n}\n._2U8AxVroi6RafBnnW_HJc ._10mQmsXKOSeDNZyaV5Gx6i {\n  background-color: #008080;\n  font-size: 14px;\n  color: #ffffff;\n  padding: 5px 4px;\n  border-radius: 5px 5px 0 0;\n}\n._2U8AxVroi6RafBnnW_HJc .vKMld7jRJnOtSokJwjSs9 {\n  padding: 5px 15px;\n}\n._2U8AxVroi6RafBnnW_HJc .vKMld7jRJnOtSokJwjSs9 > div > p {\n  cursor: pointer;\n  padding: 2px 10px;\n  margin: 0;\n}\n._2U8AxVroi6RafBnnW_HJc .vKMld7jRJnOtSokJwjSs9 > div > p:hover {\n  background-color: #edfaff;\n}\n\n._2U8AxVroi6RafBnnW_HJc + ._2U8AxVroi6RafBnnW_HJc {\n  margin-top: 15px;\n}\n\n._2yYjHUrqAAkpgiRDB3uBDR {\n  padding: 0 !important;\n}\n._2yYjHUrqAAkpgiRDB3uBDR > span {\n  padding: 12px 8px !important;\n}\n._2yYjHUrqAAkpgiRDB3uBDR > div {\n  padding: 8px !important;\n  box-sizing: border-box;\n}", ""]);
// Exports
exports.locals = {
	"flex": "_1WlNvYIKHT4nRka2S0ANsj",
	"flexColumn": "_2B98S5XJLpQ4RaVgfxBvkK",
	"flexCrossCenter": "BYPkPXdrE2tDBbPn1gcAk",
	"flexFixed": "JDXh7tg31dOKVIbFeDavc",
	"cross-line": "_2jhp7griQhz8rWmrK6KOgA",
	"carNumbers": "ZlJl_MQmjr4L6xstIhVjM",
	"checkStatus": "_2U8AxVroi6RafBnnW_HJc",
	"nodeTitle": "_10mQmsXKOSeDNZyaV5Gx6i",
	"checkName": "vKMld7jRJnOtSokJwjSs9",
	"tablePadding": "_2yYjHUrqAAkpgiRDB3uBDR"
};
module.exports = exports;
