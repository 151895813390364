import React, {useState, useEffect} from 'react';
import {Modal} from 'antd';
import request from "utils/request";
import styles from '../index.scss';
function getCheckRecord(id, type){
  const [data, setData] = useState([]);
  useEffect(() => {
    request('/web/report/pShowReportFlow', {relation_report_id: id, relation_report_type: type})
      .then(body => body.data.data)
      .then(setData)
  }, [id])
  return data
}
import { ImagePreviewer } from 'components/Previewer';
export default function CheckRecord({history, match, preview}) {
  const {params: {id, type}} = match;
  const checkRecord = getCheckRecord(id, type);
/*const checkRecord = [
  {id: 3, node: 1, status: 1, created_time: '2019-07-26 02:35:51', remark: 'xxxxxx', name: 'xx', files: [{file_url: 'http://ticketapitest.shomes.cn/uploads/tmp/20190725/f50d0dc1dc8912b842725925480297b4.pdf', file_name: '文件名称.pdf'}, {file_url: 'http://ticketapitest.shomes.cn/uploads/tmp/20190729/a637e4dc606954e3ef1f4993cacc3abf.jpg', file_name: '文件名称'}]},
  {id: 4, node: 2, status: 1, created_time: '2019-07-26 02:38:23', remark: 'xxxxxxx', name: '屠明'}
];*/
  const nodeName = ['', '区县扬尘办', '市扬尘办', '更新平台系统', '市1级审核员', '市2级审核员', '市3级审核员']; //1区市县扬尘办 2市级扬尘办 3更新平台系统 4市一级审核员 5市二级审核员 6市三级审核员
  const statusName = ['', '通过', '不通过', '未审核'];
  const checkColor = ['', '#1bc85e', '#FD322C', '#b8becc'];
  const [img_flag, setImgFlag] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const showImageFiles = function(src){
    console.log(src)
    if(src.indexOf('.pdf') > -1 || src.indexOf('.xls') > -1 || src.indexOf('.xlsx') > -1 ||  src.indexOf('.txt') > -1 || src.indexOf('.doc') > -1 || src.indexOf('.docx') > -1) {
      window.open(src)
    }
    else{
      setImgSrc(src)
      setImgFlag(true)
    }
  };
  const hideImage = function({ visiable }){
    setImgFlag(visiable)
  };
  return (
    <Modal
      title={'审核记录'}
      visible={true}
      centered={true}
      bodyStyle={{maxHeight: '80vh', overflow: 'auto', padding: '20px 10px'}}
      footer={null}
      destroyOnClose={true}
      onCancel={() => history.goBack()}
    >
      {
        checkRecord.map((v, i) => {
          return (
            <div className={`${styles.flex} ${styles.flexColumn} ${styles.flexFixed} ${styles.checkStatus}`} key={i}>
              <div className={styles.nodeTitle} style={{backgroundColor: v['node'] === 2 ? '#6D000E' : ''}}>
                <span>{nodeName[v['node']]}</span>(审核人: {v['name']},审核间: {v['created_time']})
              </div>
              <div className={styles.checkName}>
                <p>审核结果: <span style={{color: checkColor[v['status']]}}>{statusName[v['status']]}</span></p>
                {
                  v['node'] === 1 ?
                    v['status'] !== 2 ?
                        <div>相关函件:{
                          v['files'] && v['files'].length > 0 ?
                            v['files'].map((k, j) => (
                              <p key={j} onClick={() => showImageFiles(k['file_url'])}>{k['file_name']}</p>
                            )) : <></>
                        }</div>
                        : <p>审核意见: <span>{v.remark}</span></p>
                    : <p>审核意见: <span>{v.remark}</span></p>
                }
              </div>
            </div>
          )
        })
      }
      {
        img_flag ? <ImagePreviewer images={ imgSrc } visiable={img_flag} onChange={visiable => hideImage({ visiable })} />
          : null
      }
    </Modal>
  )
}
