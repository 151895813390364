import React from 'react';
import store from 'stores/user';
import { Button } from 'antd';
import map from 'utils/map';
import styles from './StepsEditor.scss';

export default class StepsEditor extends React.PureComponent {
  static getDerivedStateFromProps({ steps = [], ...props }, state) {
    // console.log('getDerivedStateFromProps');
    // 没有起点或终点，地图不可用
    let { editing, start, end } = state;
    const previewable = steps.length > 0;
    const valiable = props.start && props.end;
    if (valiable) {
      start = map.obj2arr(map.baidu2amap(props.start));
      end = map.obj2arr(map.baidu2amap(props.end));
    }
    // 没有编辑的时候，且没有路线steps不可预览，自动进入编辑状态
    if (!editing && !previewable) {
      editing = true;
    }
    return {
      valiable,
      previewable,
      editing,
      start,
      end,
    };
  }

  stepEditorRef = React.createRef()
  state = {}

  componentDidMount() {
    //地图中心
    const storeInfo = store.getState();
    const {center_one_lng, center_one_lat} = storeInfo.info;
    this.stepEditor = new window.AMap.Map(this.stepEditorRef.current, {
      center: [center_one_lng, center_one_lat],
      mapStyle: 'amap://styles/0a493f72570af5809790a84e7515515a',
    });
    // 加载路径规划插件
    this.stepEditor.plugin('AMap.DragRoute', () => {
      this.setState({ initialed: true });
    });
  }

  // 卸载地图
  componentWillUnmount() {
    this.stepEditor.clearMap();
    this.stepEditor.destroy();
  }

  toggleEditStatus = () => {
    this.setState(state => ({ editing: !state.editing }));
  }

  onSave = () => {
    const { distance, steps } = this.routeCache;
    // 解析经过的大道
    const roads = new Set();
    const roadSteps = steps.reduce((final, { road, path }) => [
      ...final,
      ...path.map(point => ({
        lng: point.lng,
        lat: point.lat,
        address: roads.has(road) ? '' : (roads.add(road) && road),
      })),
    ], []);
    // 坐标系转换 高德->百度
    const baiduSteps = map.amaps2baidus(roadSteps);
    // 显示方法
    // console.log(steps.map(step => step.address).filter(v => v).join(' -> '));
    this.props.onChange(baiduSteps, distance);
    this.toggleEditStatus();
  }

  reRenderMap() {
    const { steps = [] } = this.props;
    const { initialed, valiable, editing, start, end } = this.state;
    // 插件未加载完成，或没有起止点坐标
    if (!initialed || !valiable) return;

    // 清空地图
    this.stepEditor.clearMap();
    if (!editing) {
      // 坐标系转换 百度->高德
      // 数据类型转换 [对象] -> [数组]
      this.stepEditor.add(new window.AMap.Polyline({
        path: map.objs2arrs(map.baidus2amaps(steps)),
        strokeColor: '#1890FF',
        strokeWeight: 6,
        lineJoin: 'bevel',
        lineCap: 'round',
        showDir: true,
      }));
      this.stepEditor.setFitView();
    } else {
      this.dragRouteEditor = new window.AMap.DragRoute(this.stepEditor, [start, end], window.AMap.DrivingPolicy.LEAST_FEE);
      this.dragRouteEditor.search();
      this.dragRouteEditor.on('complete', ({ data }) => {
        this.routeCache = data.routes[0];
      });
    }
  }

  render() {
    const { valiable, editing, previewable } = this.state;
    // console.log(this.state);
    // 重新渲染地图
    this.reRenderMap();
    return (
      <div className={[styles.map, this.props.className].join(' ')}>
        <div className={styles.canvas} ref={this.stepEditorRef} />
        <div className={styles.buttons}>
          {valiable && (editing ? [
            <Button className={styles.button} onClick={this.toggleEditStatus} disabled={!previewable} key="cancel">取消</Button>,
            <Button className={styles.button} onClick={this.onSave} key="save" type="primary">保存</Button>,
          ] : (
            <Button className={styles.button} onClick={this.toggleEditStatus} type="primary">重新编辑路线</Button>
          ))}
        </div>
      </div>
    );
  }
}
