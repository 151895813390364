import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { previewable } from 'components/Previewer';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';

import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';

import debug from 'utils/debug';

const defaultOptions = {
  keyword: '车牌、地点',
};

export default function createAlarm(alarmType, cols = [], options = {}) {
  const extraParams = { alarm_type: alarmType };
  const dataSource = new DataSource('/web/ticket_alarm/index', extraParams);
  const opts = Object.assign({}, defaultOptions, options);

  function Alarm({ preview }) {
    debug.render('Alarm');

    const previewIllegalMap = (ticketId, alarmId) => () => {
      preview.illegalMap(ticketId, alarmType, alarmId);
    };

    const columns = [
      serial(true),
      ...cols,
      actions({
        isFixed: true,
        width: 84,
        render: ($, { id: alarmId, ticket_id: ticketId }) => (
          <Button size="small" type="default" onClick={previewIllegalMap(ticketId, alarmId)}><FontAwesomeIcon icon="map-marked-alt" /><span style={{ marginLeft: 5 }}>详情</span></Button>
        ),
      }),
    ];

    const querys = [
      { title: opts.keyword, key: 'keyword' },
      { title: ['报警', '时间'], key: 'start_time', type: 'date-range', showTime: true },
    ];

    const buttons = [
      { title: '导出', download: '/web/ticket_alarm/indexExport', extraParams },
    ];

    return (
      <TablePage
        dataSource={dataSource}
        buttons={buttons}
        querys={querys}
        columns={columns}
        defaultLimit={10}
        defaultQuery={{
          start_time: [
            moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          ],
        }}
      />
    );
  }

  return previewable(Alarm);
}
