import React, {useState, useEffect} from 'react';
import {DatePicker, Icon, Spin, Input, Select, Pagination} from 'antd';
import moment from "moment";
import request from 'utils/request';
import immutable from 'immutable';
import styles from './index.scss';
const { Search } = Input;
const { Option } = Select;
import {Types, ALARMIMAGES, ALARMNAME, ALARMSTATUS, COLOR, ALARMTIME, ALARMSTATUSOPTIONS} from './FilterData';
import {setNoticeText, flowNotice} from './FilterFunction'
import {modifyCarNum, useObservable} from "components/Previewer";
// 获取告警数据
function obtainAlarmData(params, id, counts){
  const [data, setData] = useState([]);
  const [count, setCount] = useState([]);
  const [alarmSpin, setAlarmSpin] = useState(false);
  const [important, setImportant] = useState(0);
  const [total, setTotal] = useState(0);
  const begin_date = params.get('dates')[0].format('YYYY-MM-DD');
  const end_date = params.get('dates')[1].format('YYYY-MM-DD');
  useEffect(() => {
    setAlarmSpin(true);
    request('/web/count_alarm/data', {...params.delete('dates').toJS(), time: [begin_date, end_date]})
      .then(b => b.data.data)
      .then(data => {setData(data.data.data); setTotal(data.data.total); setCount(data.count); setImportant(data.important)})
      .finally(() => {
        setAlarmSpin(false);
      });
  }, [JSON.stringify(params), id, counts]);
  return [data, total, count, important, alarmSpin]
}
function makeImportant(id){
  request('/web/count_alarm/makeImportant', {id}).then(() => console.log)
}

export default function AlarmBroad({param, id, count, notice_count, onChangeAlarmId}){
  const [keyword, setKeyword] = useState('');
  const date = JSON.parse(param.dates);
  const dates = [moment(date[0]), moment(date[1])]
  const [params, setParams] = useState(immutable.Map({...param, dates: dates}));
  const [data, total, alarmType, important, alarmSpin] = obtainAlarmData(params, id, count);
  const [alarmData, setAlarmData] = useState([]);
  const [focus, setFocus] = useState(0);
  const [alarmId, setAlarmId] = useState(id);
  const newCarNum = useObservable(modifyCarNum);
  useEffect(() => {
    setAlarmId(id)
  }, [id]);
  useEffect(() => {
    console.log(newCarNum);
  }, [newCarNum]);
  useEffect(() => {
    setAlarmData(data);
    setFocus(important)
  }, [data, important]);
  const markPoints = (alarmId, alarmPoint) => {
    makeImportant(alarmId);
    setFocus(alarmPoint ? (focus - 1) : (focus + 1))
    setAlarmData(alarmData.map(({id, important, ...d}) => ({
      ...d,
      important: id === alarmId ? !alarmPoint : important,
      id,
    })))
  };

  const onAlarmId = (id, status, notice_count, dispatch_task_id) => {
    setAlarmId(id);
    onChangeAlarmId(id, status, notice_count, dispatch_task_id);
  };

  return <>
    <div className={styles.container}>
      <div className={styles.dates}>
        <DatePicker.RangePicker className={styles.date} size={'large'} allowClear={false} value={params.get('dates')}
                                separator={'~'} format="MM-DD "  suffixIcon={<img src="/images/alarm/black_arrow.png" alt=""/>}
                                onChange={(e) => setParams(params.set('dates', e).set('page', 1))}  />
        <Search value={keyword} placeholder="输入车牌号、工地名称" onChange={(e) => setKeyword(e.target.value)}
                enterButton={false} onPressEnter={() => setParams(params.set('keyword', keyword).set('page', 1))}
        />
      </div>
      <div className={styles.alarmType}>
        {
          alarmType.map(({name, count, status}, i) => (
            <div key={i} onClick={() => setParams(params.set('status', status).set('page', 1))}>
              <span>{count}</span>
              <span className={params.get('status') === status ? styles.active : ''}>{name}</span>
            </div>
          ))
        }
      </div>
      <div className={styles.navNum}>
        <span className={params.get('nav_num') === 1 ? styles.active : ''}>结算告警</span>
        <span className={params.get('nav_num') === 2 ? styles.active : ''}>车辆告警</span>
      </div>
      <div className={styles.warnTotal}>
        <span>共{total}次告警</span>
        <div className={styles.keyPoint}><img src="/images/alarm/marker.png" alt=""/><span>重点关注 {focus}</span></div>
        <div className={styles.types}> {/*  土方开挖 */}
          <Select placeholder={'全部'} allowClear style={{width: 130}} value={params.get('out_dig')} onChange={(e) => setParams(params.set('out_dig', e).set('page', 1))}
                  suffixIcon={<img src="/images/alarm/grey_arrow.png" alt=""/>}>
            {
              ALARMTIME.map(({name, value}, i) => (
                <Option key={i} value={value}>{name}</Option>
              ))
            }
          </Select>
        </div>
        {
          params.get('status') !== 1 && params.get('status') !== 2 ?
            <div className={styles.types}>
              <Select placeholder={'状态'} allowClear value={params.get('new_status')} onChange={(e) => setParams(params.set('new_status', e).set('page', 1))}
                      suffixIcon={<img src="/images/alarm/grey_arrow.png" alt=""/>}>
                {
                  ALARMSTATUSOPTIONS.map(({name, id}, i) => (
                    <Option key={i} value={id}>{name}</Option>
                  ))
                }
              </Select>
            </div> : null
        }
        <div className={styles.types}>
          <Select placeholder={'类型'} allowClear style={{width: 90}} value={params.get('alarm_type')} onChange={(e) => setParams(params.set('alarm_type', e).set('page', 1))}
                  suffixIcon={<img src="/images/alarm/grey_arrow.png" alt=""/>}>
            {
              Types.map(({name, id}, i) => (
                <Option key={i} value={id}>{name}</Option>
              ))
            }
          </Select>
        </div>
      </div>
      <div className={styles.alarmData}>
        <Spin spinning={alarmSpin}>
          {
            alarmData.length > 0 ?
              alarmData.map(({id, building_name, type, car_num, device_type, out_dig, created_time, address, node_names, important, range, notice_count, status, dispatch_task_id}, i) => (
                <div key={i} className={(params.get('status') === 1 && id === alarmId) ? ` ${styles.waiting} ${styles.active}` : (params.get('status') === 1 && id !== alarmId) ? `${styles.waiting}` : (params.get('status') !== 1 && id === alarmId) ? `${styles.active}` : ''}
                     onClick={() => onAlarmId(id, status, notice_count, dispatch_task_id)}
                >
                  <div className={styles.alarmTitle}>
                    <img src={ALARMIMAGES[type]} alt=""/>
                    <span title={type === 8 ? address : ((type === 9 && device_type === 1) || type === 10) ? (newCarNum ? (id === newCarNum.id ? newCarNum.car_num : car_num) : car_num) : building_name}>{type === 8 ? address : ((type === 9 && device_type === 1) || type === 10) ? (newCarNum ? (id === newCarNum.id ? newCarNum.car_num : car_num) : car_num) : building_name}</span>
                    <span>{ALARMNAME[type]} <img src="/images/alarm/right_arrow.png" alt=""/></span>{/*onClick={(e) => {e.stopPropagation()}*/}
                  </div>
                  <p style={{color: !out_dig ? '#46D868' : '#FE4442' }}>告警时间: {created_time}</p>
                  {
                    params.get('status') === 1 || params.get('status') === 2 ?
                      <div className={styles.alarmMsg}>
                        <p>{address}</p>
                        <span><img onClick={e => {e.stopPropagation(); markPoints(id, important)}} src={`/images/alarm/${important ? `marker.png` : `point.png`}`} alt=""/></span>
                      </div>
                      : <div className={styles.alarmMsg2}>
                        <p>{address}</p>
                        <div>
                          <p>当前状态: <span style={{color: COLOR[status]}}>{node_names}</span></p>
                          <div>{status === 4 ? <span onClick={() => flowNotice(id)}>提醒</span> : (status === 2 || status === 5) ? <span>{ setNoticeText(notice_count) }</span> : null}</div>
                          <span><img onClick={e => {e.stopPropagation();markPoints(id, important)}} src={`/images/alarm/${important ? `marker.png` : `point.png`}`} alt=""/></span>
                        </div>
                      </div>
                  }
                </div>
              ))
              :
              <div className={styles.empty}>
                <img src="/images/alarm/empty.png" alt=""/> <span>当前暂无数据</span>
              </div>
          }
        </Spin>
      </div>
      <div className={styles.pagination}><Pagination size="small" total={total} onChange={(page) => setParams(params.set('page', page))} /></div>
    </div>
  </>;
}
