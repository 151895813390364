import React from 'react';
import { Route } from 'react-router-dom';
import List from './List';
import Detail from './Detail';
import Trail from './Trail';

export default ({ path }) => (
  <>
    <Route path={path} component={List} />
    <Route path={path + ':id/detail'} component={Detail} />
    <Route path={path + ':id/trail'} component={Trail} />
  </>
);
