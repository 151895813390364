import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import debug from 'utils/debug';
import store from 'stores/user';
import dataSource from '../dataSource';
import { LOG_IN } from '../../../../redux/types/user';

export default function Update({ history }) {
  debug.render('Update');
  const storeInfo = store.getState();
  const roles = storeInfo.roles;
  const back = () => history.goBack();
  const onFinish = () => {
    const user = {
      ...storeInfo,
      roles: {
        ...roles,
        info_complete: 1
      },
    };
    // 本地缓存用户信息
    window.localStorage.setItem('USER', JSON.stringify(user));
    // 发布事件
    store.dispatch({
      type: LOG_IN,
      payload: user,
    });
    dataSource.refresh();
    back();
  };

  const inputs = [
    { label: '运企id', key: 'id', required: true, hide: true },
    { label: '企业名称', key: 'name', required: true, disabled: true },
    { label: '企业描述', key: 'desc', required: false, type: 'textarea', autosize: { minRows: 4, maxRows: 10 } },
    { label: '注册时间', key: 'register_time', required: true, type: 'date', format: 'YYYY-MM-DD' },
    { label: '注册资金', key: 'register_money', required: true, type: 'number', style: { width: 170 } },
    { label: '注册地址', key: 'address', required: true },
    { label: '法定代表人', key: 'corporation', required: true, style: { width: 170 } },
    { label: '法定代表人手机号', key: 'corporation_phone', required: true, style: { width: 170 } },
    { label: '负责人', key: 'contacts', required: true, style: { width: 170 } },
    { label: '负责人手机号', key: 'telephone', required: true, style: { width: 170 } },
    { label: '所属区域', key: 'area_id', required: true },
    { label: '邮政编码', key: 'zip_code', required: false, type: 'number', style: { width: 170 } },
    { label: '办公电话', key: 'office_phone', required: true, style: { width: 170 } },
    { label: '办公面积', key: 'office_acreage', required: false, type: 'number', style: { width: 170 } },
    { label: '办公地址', key: 'office_address', required: true },
    { label: '停车场地址', key: 'park_address', required: true },
    { label: '专营区域', key: 'franchise_area', required: false },
    { label: '核准年份', key: 'approved_year', required: false, type: 'date', mode: 'year', format: 'YYYY' },
    { label: '资质编号', key: 'qua_num', required: false, style: { width: 340 } },
    { label: '标准资质名称', key: 'stand_qua_name', required: false, style: { width: 340 } },
    { label: '统一社会信用代码', key: 'credit_code', required: true, style: { width: 340 } },
    { label: '道路运输经营许可证编号', key: 'road_transport_license', required: true, style: { width: 340 } },
    { label: '营业期限开始', key: 'business_start_date', required: true, type: 'date', format: 'YYYY-MM-DD' },
    { label: '营业期限截止', key: 'business_end_date', required: true, type: 'date', format: 'YYYY-MM-DD', rely: 'business_forever' },
    { label: '永久', key: 'business_forever', required: true, type: 'checkbox' },
    { label: '工商营业执照', key: 'business_license_photo', required: true, type: 'upload', placeholder: '上传正、副本扫描件或照片' },
    { label: '道路运输经营许可证', key: 'road_trans_op_permit', required: true, type: 'upload', placeholder: '上传正、副本扫描件或照片' },
    { label: '法定代表人身份证', key: 'legal_id_card_photo', required: true, type: 'upload', placeholder: '上传正、反面扫描件或照片' },
  ];

  const transformer = ({ base }) => base;

  return (
    <ModalFormPage
      init={['/web/Transport/getInfo', {}, transformer]}
      submit={['/web/Transport/updateInfo']}
      title="修改运企信息"
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
      modalProps={{
        width: 800,
      }}
      formProps={{
        labelCol: { xs: { span: 6 } },
        wrapperCol: { xs: { span: 18 } },
      }}
    />
  );
}
