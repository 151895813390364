import React, {useCallback, useEffect, useState} from 'react';
import { Modal } from 'antd';
import styles from "./index.scss";
import Flv from "flv.js";

export default function ShowVideo({ videoSrc, onClose }){
  console.log(videoSrc)
  const [video, setVideo] = useState();
  const videoRef = useCallback((element) => setVideo(element), []);
  let flvPlayer = null;
  useEffect(() => {
    if (videoSrc && video) {
      if (videoSrc.indexOf('.flv') > -1) {
        play(videoSrc)
      } else {
        video.src = videoSrc;
        video.setAttribute('object-fit', 'fill');
        video.load();
        video.play();

      }
    }
  }, [videoRef, videoSrc, video]);

  function play(src) {
    if (Flv.isSupported()) {
      flvPlayer = Flv.createPlayer({
          type: 'flv',
          url: src,
          hasAudio: true,
          hasVideo: true,
          isLive: true,
          withCredentials: false,
          cors: true,
        },
        {
          enableWorker: false,
          lazyLoadMaxDuration: 3 * 60,
          seekType: 'range',
          fixAudioTimestampGap: false,  //主要是这个配置，直播时音频的码率会被降低，直播游戏时背景音会有回响，但是说话声音没问题
        });
      flvPlayer.attachMediaElement(video);
      flvPlayer.load();
      setTimeout(function () {
        flvPlayer.play();
      }, 100);
    }
  }
  return (
    <Modal
      title={false}
      closable={false}
      width={'auto'}
      bodyStyle={{padding: 0, width: 'auto', height: '640px'}}
      visible={true} onCancel={ onClose }
      centered={true}
      footer={null}
      destroyOnClose={true}
    >
      <div className={styles.video}>
        <video
          ref={videoRef}
          autoPlay
          controls
          width={480}
          height={640}
          style={{objectFit: !videoSrc ? '' : videoSrc.indexOf('.flv') > -1 ? '' : 'fill'}}/>
      </div>
    </Modal>
  )
}
