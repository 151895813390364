import React from 'react';
import PropTypes from 'prop-types';
import map from 'utils/map';

export default class RoutesPreview extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    colors: PropTypes.array,
    planRoute: PropTypes.object,
    realRoute: PropTypes.array,
    parks: PropTypes.array,
  }
  static defaultProps = {
    className: '',
    colors: ['#06D6A0', '#EF476F'],
    planRoute: {},
    realRoute: [],
    parks: [],
  }

  static polyline(route, strokeColor) {
    if (!route) return null;
    const path = map.objs2arrs(map.baidus2amaps(route));
    return new window.AMap.Polyline({
      path,
      lineCap: 'round',
      lineJoin: 'round',
      strokeColor,
      strokeWeight: 6,
      showDir: true,
    });
  }

  static marker(lng, lat, content = undefined) {
    const position = map.obj2arr(map.baidu2amap({ lng, lat }));
    return new window.AMap.Marker({
      // position: new window.AMap.LngLat(lng, lat),
      position,
      offset: new window.AMap.Pixel(0, 0),
      anchor: 'center',
      content: `
        <div style="
          width: 12px;
          height: 12px;
          border-radius: 6px;
          background: #FF3B30;
        "></div>
      `,
      label: {
        content,
        offset: new window.AMap.Pixel(-15, 35),
        direction: 'top',
      },
    });
  }

  mapRef = React.createRef()

  async componentDidMount() {
    const { planRoute, realRoute, parks } = this.props;

    this.map = new window.AMap.Map(this.mapRef.current, { mapStyle: 'amap://styles/macaron' });
    if (planRoute && !Array.isArray(planRoute)) {
      const { steps: planRouteSteps, ...plan } = planRoute;
      this.map.add(RoutesPreview.polyline(planRouteSteps, '#EF476F'));
      this.map.add(RoutesPreview.marker(plan.start_lng, plan.start_lat, plan.start_address));
      this.map.add(RoutesPreview.marker(plan.end_lng, plan.end_lat, plan.end_address));
    }
    this.map.add(RoutesPreview.polyline(realRoute, '#06D6A0'));
    this.map.setFitView();
  }

  render() {
    const { className, width, height } = this.props;
    return <div className={className} ref={this.mapRef} style={{ width, height }} />;
  }
}
