// https://ant.design/components/date-picker-cn/#%E5%85%B1%E5%90%8C%E7%9A%84-API
// https://ant.design/components/date-picker-cn/#DatePicker
import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import moment from 'moment';

export default function DatePicker({ value, onChange, format = 'YYYY-MM-DD', ...props }) {
  return (
    <AntDatePicker
      {...props}
      format={format}
      value={value ? moment(value, format) : null}
      onChange={(m, dateString) => onChange(dateString)}
      onPanelChange={m => onChange(m.format(format))}
    />
  );
}
