// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3pc3O2uwVTQ8QT7ELqXLJY {\n  border-bottom: 2px solid rgba(34, 36, 38, 0.15);\n}\n._3pc3O2uwVTQ8QT7ELqXLJY ._3ZsgaT5Cd1tSezUpUsoJYK {\n  color: #999999;\n  font-weight: bold;\n}\n._3pc3O2uwVTQ8QT7ELqXLJY .q0fyWtYNvNaF6g7V053F9 {\n  color: #333333;\n}\n._3pc3O2uwVTQ8QT7ELqXLJY .i7irXcmZUccTfplzcBada {\n  margin: 10px 0;\n}\n\n._2TF6M9R4vH1jT3xtsr_C3U {\n  display: inline-block;\n  padding: 3px;\n  border-radius: 3px;\n  color: #fff;\n  background-color: green;\n  margin-right: 5px;\n}", ""]);
// Exports
exports.locals = {
	"first": "_3pc3O2uwVTQ8QT7ELqXLJY",
	"labels": "_3ZsgaT5Cd1tSezUpUsoJYK",
	"count": "q0fyWtYNvNaF6g7V053F9",
	"rowStyle": "i7irXcmZUccTfplzcBada",
	"record": "_2TF6M9R4vH1jT3xtsr_C3U"
};
module.exports = exports;
