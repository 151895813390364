// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3AbFN7JoCnp6TAlqrHzHFu {\n  font-weight: 500;\n  line-height: 2;\n}\n\n.kvU8cYJxdHvFHzDRQu7P- .q9xHrp1royVwfZtSd8dIh {\n  margin-bottom: 1em;\n  display: flex;\n}\n.kvU8cYJxdHvFHzDRQu7P- .q9xHrp1royVwfZtSd8dIh img {\n  cursor: pointer;\n  display: block;\n  border-radius: 4px;\n  margin-right: 1em;\n  width: 0;\n  padding: 36px 64px;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-origin: border-box;\n  background-clip: border-box;\n}\n\n._3dVG5L4K1DIlKaSWLWYtLb ._3AbFN7JoCnp6TAlqrHzHFu {\n  display: flex;\n}\n._3dVG5L4K1DIlKaSWLWYtLb ._3AbFN7JoCnp6TAlqrHzHFu i {\n  color: gray;\n}\n._3dVG5L4K1DIlKaSWLWYtLb ._1epSt2HXCsIxCyG8Ltfpc5 {\n  margin: 1em 0;\n  border: 1px solid #DDD;\n  border-radius: 4px;\n}\n._3dVG5L4K1DIlKaSWLWYtLb ._1epSt2HXCsIxCyG8Ltfpc5 ._1Og_tpWn2sesmyxeMY-9kO {\n  color: #999999;\n  font-weight: bold;\n}\n._3dVG5L4K1DIlKaSWLWYtLb ._1epSt2HXCsIxCyG8Ltfpc5 ._2r8D_7LTJWgtPhc4DLPIX5 {\n  color: #333333;\n}\n._3dVG5L4K1DIlKaSWLWYtLb ._1epSt2HXCsIxCyG8Ltfpc5 ._1-BYXPBAwT0nMLlggzzi5S {\n  margin: 10px 0;\n}\n._3dVG5L4K1DIlKaSWLWYtLb ._1epSt2HXCsIxCyG8Ltfpc5 ._9Bi9eX7y-xZV6ALW6IiQ1 {\n  display: inline-block;\n  padding: 3px;\n  border-radius: 3px;\n  color: #fff;\n  background-color: green;\n  margin-right: 5px;\n}", ""]);
// Exports
exports.locals = {
	"title": "_3AbFN7JoCnp6TAlqrHzHFu",
	"photos": "kvU8cYJxdHvFHzDRQu7P-",
	"items": "q9xHrp1royVwfZtSd8dIh",
	"mapContainer": "_3dVG5L4K1DIlKaSWLWYtLb",
	"map": "_1epSt2HXCsIxCyG8Ltfpc5",
	"labels": "_1Og_tpWn2sesmyxeMY-9kO",
	"count": "_2r8D_7LTJWgtPhc4DLPIX5",
	"rowStyle": "_1-BYXPBAwT0nMLlggzzi5S",
	"record": "_9Bi9eX7y-xZV6ALW6IiQ1"
};
module.exports = exports;
