import React from 'react';
import { Route } from 'react-router-dom';
import List from './List';
import Create from './Create';
import Detail from '../ElectronicStrip/Detail';

export default ({ path }) => (
  <>
    <Route path={path} component={List} />
    <Route path={path + 'create'} component={Create} />
    <Route path={path + ':id/ele-detail'} component={Detail} />
    <Route path={path + 'create/:id/ele-detail'} component={Detail} />
  </>
);
