import React from 'react';
import { Route } from 'react-router-dom';
import List from './List';
import RouteEditor from  './RouteEditor/RouteEditor';
import RouteDetail from  './DetailComponent/CommonDetails';

export default ({ path }) => (
  <>
    <Route exact path={path} component={List} />
    <Route path={path + ':types/create'} component={RouteEditor} />
    <Route path={path + ':types/:id/update'} component={RouteEditor} />
    <Route path={path + ':types/:id/detail'} component={RouteDetail} />
  </>
);
