import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import debug from 'utils/debug';

import { dataSource } from './List';

const inputs = [
  { label: '方量', key: 'volumn', type: 'number', style: { width: 200 } },
  { label: '修改原因', key: 'edit_reason', type: 'textarea', autosize: { minRows: 10, maxRows: 12 }, required: true },
];

export default function Volumn({ history, match }) {
  debug.render('Volumn');

  const back = () => history.goBack();
  const onFinish = () => {
    dataSource.refresh();
    back();
  };

  const id = match.params.id;

  return (
    <ModalFormPage
      init={['/web/ticket/getVolumn', { id }]}
      submit={['/web/ticket/editVolumn', { id }]}
      title="修改方量"
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
      modalProps={{
        width: 800,
      }}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}
    />
  );
}
