import { logOut } from 'actions/user';

export default (data) => {
  switch (data.code) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5: console.error(data.msg); break;
    case 6: logOut('您的账号可能已在其它地方登陆，请重新登陆!'); break;
    case 7: logOut('非法用户，请重新登陆!'); break;
    case 100: logOut('登陆过期，请重新登陆!'); break;
    default: break;
  }
  return data;
};
