import React from 'react';
import { Route } from 'react-router-dom';

import Info from './Info';
import BaseUpdate from './Base/Update';
import { DocersCreate, DocersUpdate } from './Docers';
import { SecuritiesCreate, SecuritiesUpdate } from './Securities';

export default ({ path }) => (
  <div>
    <Route path={path} component={Info} />
    <Route path={path + 'base/update'} component={BaseUpdate} />
    <Route path={path + 'docers/create'} component={DocersCreate} />
    <Route path={path + 'docers/:id/update'} component={DocersUpdate} />
    <Route path={path + 'securities/create'} component={SecuritiesCreate} />
    <Route path={path + 'securities/:id/update'} component={SecuritiesUpdate} />
  </div>
);
