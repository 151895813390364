import React, { useState, useEffect, useCallback, useMemo, useRef, useImperativeHandle, forwardRef } from 'react';
import moment from 'moment';
import request from 'utils/request';
import styles from './index.scss';
import { DatePicker, Button, Input, message, Icon } from "antd";
const defaultDates = [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')];//当天日期前一天
const isArrayAllValid = array => array.findIndex(item => item === undefined) === -1;

/** 获取右侧下标 */
function useSingle(mapList) {
  const [index, setIndex] = useState();
  const dependencies = [mapList, index];
  const plan = useMemo(() => {
    if (!isArrayAllValid(dependencies)) return {};
    const single = { ...mapList[index], index: (index + 1) }
    return single || {};
  }, [dependencies]);
  return [plan, setIndex];
}
/** 超过一周的算法 */
function getDays(strDateStart, strDateEnd) {
  let strSeparator = "-"; //日期分隔符
  let oDate1; let oDate2; let iDays;
  oDate1 = strDateStart.split(strSeparator);
  oDate2 = strDateEnd.split(strSeparator);
  let strDateS = new Date(oDate1[0], oDate1[1] - 1, oDate1[2]);
  let strDateE = new Date(oDate2[0], oDate2[1] - 1, oDate2[2]);
  iDays = parseInt(Math.abs(strDateS - strDateE) / 1000 / 60 / 60 / 24)//把相差的毫秒数转换为天数
  return iDays;//最终获得的天数
}
/** getRightList */
function getMapListData(dates, points, showSeries, countryPoint, flag, sureGetCar, countryPointSure) {
  const [leftLoading, setLeftLoading] = useState(false);
  const [data, setData] = useState([]);
  if (points === undefined) { points = [] }
  if (showSeries) { points = [] }
  useEffect(() => {
    if (getDays(dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD')) > 6) {
      message.warn('右侧选择日期不能大于一周！')
      return
    }
    if (!sureGetCar) return
    if (points.length == 2 || points.length == 1) {
      message.warn('请至少画出三个点！')
      return
    }
    let arr = []
    let a = 0
    setLeftLoading(true);//开始转圈圈
    let rightDates = [dates[0].format('YYYY-MM-DD HH:mm:ss'), dates[1].format('YYYY-MM-DD HH:mm:ss')]
    if (countryPoint.length > 0 && countryPointSure) {
      countryPoint.forEach(item => {
        request('/web/car_transport/getCar', { points: JSON.stringify(item.points), time: rightDates })
          .then(body => {
            (body.data.data).forEach(item => {
              arr.push(item)
            })
          })
          .finally(() => {
            a++
            if (a === 8 && countryPoint.length > 0) {
              setData(arr)
              setLeftLoading(false);
            }
          });
      })
    } else if (flag) {
      request('/web/car_transport/getCar', { points: JSON.stringify(points), time: rightDates })
        .then(body => {
          setData(body.data.data)
        })
        .finally(() => {
          setLeftLoading(false);
        });
    }
  }, [sureGetCar, flag, countryPointSure, dates]);

  return [data, leftLoading];
}
/** 源头回溯-Left*/
export default function RightList({ getRightListInfo, closebottom, props }, ref) {
  let { showSeries, mapPoints, flage, sureGetCar, finishe, singleFlag, countryPointF } = props
  const [sureGetCars, setSureGetCars] = useState(false)
  const [flag, setFlag] = useState(false)
  const [keyword, setKeyword] = useState('');
  const [leftData, setLeftData] = useState([]);
  const [countryPoint, setCountryPoint] = useState([])
  const [dates, setDates] = useState(defaultDates);
  const [countryPointSure, setCountryPointSure] = useState(false)
  const [mapList, leftLoading] = getMapListData(dates, mapPoints, showSeries, countryPoint, flag, sureGetCars, countryPointSure);
  const [single, setSingle] = useSingle(mapList);
  const [timeFlag, setTimeFlag] = useState(false)
  useEffect(() => { if (singleFlag) setSingle() }, [singleFlag]);
  useEffect(() => { setSureGetCars(sureGetCar) }, [sureGetCar]);
  useEffect(() => { if (finishe) { setCountryPoint([]) } }, [finishe]);
  useEffect(() => { setFlag(flage); if (flage) setCountryPoint([]) }, [flage]);
  useEffect(() => { //八个国标
    request('/web/car_transport/getCountryPoint')
      .then(body => {
        setCountryPoint(body.data.data)
        setSureGetCars(true)
        setCountryPointSure(true)
      })
    setTimeout(() => {
      setSureGetCars(false)
      // setCountryPointSure(false)//如果为true，则国标在第一次加载后不再加载
    }, 1000);
  }, []);
  useEffect(() => { setLeftData(mapList) }, [mapList]);
  useEffect(() => { getRightListInfo({ countryPoint, mapList, leftLoading }) }, [mapList, leftLoading]);
  useEffect(() => { if (countryPointF && countryPointF.length === 0) { setCountryPoint([]) } }, [countryPointF]);
  /** RightListFilter */
  function filterChange() {
    let newArr = [];
    mapList.map((item) => {
      if (item.car_number.indexOf(keyword) > -1) { newArr.push(item) }
    });
    closebottom()
    setLeftData(newArr)
  }
  /** about RightListFilter onKeyUp */
  function filterChanges(e) { if (e.keyCode === 13) { filterChange() } }
  /** 日期长度限制 */
  function disabledDate(current) {
    if (timeFlag) {
      let time = dates[0]
      if (!current) {
        return false
      } else {
        return current < moment(time).subtract(6, 'days') || current > moment(time).add(6, 'day').endOf('day')
      }
    }
  }
  /** 日期重置按钮 */
  function renderExtraFooter() { return (<Button onClick={() => setTimeFlag(false)} type='primary' size="small">重置</Button>) }
  /** 右侧时间更改时（onChange）*/
  function rightTime(e) { setDates(e); setTimeFlag(true); }
  /** 操作其他右侧数据关闭 */
  useEffect(() => {
    if (countryPoint.length === 0 && mapPoints.length === 0) {
      setLeftData([])
    }
  }, [countryPoint, mapPoints]);
  return (
    <div className={styles.overlays}  >
      <div className={styles.overTop} >
        <div className={styles.flexFixed} >
          <div className={styles.flexFixed, styles.bottomTop} style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Input type="text" placeholder="输入车牌号" onChange={(e) => setKeyword(e.target.value)} onKeyUp={filterChanges} className={styles.input} />
            <div className={styles.overSearch} onClick={filterChange} >
              <div className={styles.search} ></div>
            </div>
          </div>
        </div>
        <div className={styles.bottomTop}>
          <DatePicker.RangePicker className={styles.dates} style={{ width: 284 }} allowClear={false}
            size="default" showTime={true} format="YYYY-MM-DD HH:mm:ss" suffixIcon={<Icon type="down" />} disabledDate={disabledDate} renderExtraFooter={renderExtraFooter}
            value={dates} onChange={rightTime} onOk={() => {
              setSureGetCars(true);
              setFlag(true)
              closebottom()
              setSingle()//右侧选项清除
              // setCountryPoint([])
              setTimeout(() => {
                setSureGetCars(false);
              }, 1000);
            }} onOpenChange={() => setTimeFlag(false)} />
        </div>
        <div style={{ fontSize: 14, marginTop: 27, }}>
          <span style={{ fontSize: 18 }}> 作业车辆:{leftData.length > 10 ? leftData.length : '0' + leftData.length}</span>
        </div>
      </div>
      <div className={`${styles.flexGrow} ${styles.webTum}`} style={{ overflowY: 'auto',maxHeight:576 }}>
        <div style={{ }}>
          {
            leftData.length > 0 ?
              leftData.map((item, i) => (
                <div className={styles.overSpeedItem} style={{ padding: 0, marginTop: 10, color: single.index === (i + 1) ? '#FF930A' : '#1a1a1a' }} key={i} onClick={() => {
                  setSingle(i)
                  getRightListInfo({ countryPoint, mapList, single: i, singleSure: true, leftLoading: false })
                }}>
                  <div className={styles.aaiName}>{item.transport_name}</div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><span className={styles.aaiLevel}>{item.car_number}</span></div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><span className={styles.aaiLast}>{item.time}</span><span>属地:{item.area_name}</span></div>
                </div>
              )) : <div style={{ textAlign: 'center' }}>暂无数据 </div>
          }
        </div>
      </div>
    </div>
  )
}
RightList = forwardRef(RightList)