import React from 'react';
import { Route } from 'react-router-dom';
import List from './List'; // 列表
import Create from './CreateCar'; //
// import Create from './Create'; //
import Edit from './EditCar'; //
import ShowDetail from './ShowDetail'; //
export default ({ path }) => (
  <>
    <Route path={path} component={List} />
    <Route path={path + 'create'} component={Create} />
    <Route path={path + ':id/:car_num/edit'} component={Edit} />
    <Route path={path + ':id/:car_num/show-detail/'} component={ShowDetail} />
  </>
);
