import React from 'react';
import { previewable } from 'components/Previewer';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import NewDeleteButton from 'ui/TableCell/NewDeleteButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';

export const dataSource = new DataSource('/river/river_base/getRiverTransportCarLists');

function List({ priview }) {
  const CarEffect = [
    { label: '生效', value: 1 },
    { label: '失效', value: 2 },
  ];
  const columns = [
    serial(true),
    { title: '车辆号牌', dataIndex: 'plate_num', width: width(7) },
    { title: '是否生效', dataIndex: 'is_active', render: (v) => v === 1 ? '是' : '否' },
    { title: '运企名称', dataIndex: 'transport_name' },
    { title: '运企地址', dataIndex: 'transport_address' },
    { title: '运企负责人', dataIndex: 'manager', width: width(6) },
    { title: '负责人电话', dataIndex: 'manager_tel', width: width(8) },
    { title: '创建时间', dataIndex: 'created_time', width: width(14) },
    { title: '创建人', dataIndex: 'created_name', width: width(6) },
    actions({
      width: width(8),
      render: ($, {id}) => (
        <NewDeleteButton title="确认删除该车辆信息?" type="danger" api="/river/river_base/deleteRiverTransportCarList" river_transport_car_id={id} dataSource={dataSource}>删除</NewDeleteButton>
      )
    })
  ];

  const querys = [
    { title: ['创建', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
    { title: '请选择运企', key: 'transport_id', type: 'select', options: '/river/river_base/getRiverTransportOption', valueKey: 'id', labelKey: 'name', style: { width: 300 } },
    { title: '请选择车辆是否生效', key: 'is_active', type: 'select', options: CarEffect, style: { width: 220 } },
    { title: '请输入车辆号牌、负责人、负责人电话、创建人模糊搜索', key: 'keyword', style: { width: 400 } },
  ];

  const buttons = [
    { title: '新增', link: './create', icon: 'plus'},
    { title: '导出', download: '/river/river_base/exportRiverTransportCarLists' },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}

export default previewable(List)
