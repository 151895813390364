import React from 'react';
import { Route } from 'react-router-dom';
import List from './List';
import Edit from './Edit';

export default ({ path }) => (
  <>
    <Route path={path} component={List} />
    <Route path={path + ':name/:id/:types/edit'} component={Edit} />
  </>
);
