import React from 'react';
import { Route } from 'react-router-dom';
import { Divider } from 'antd';
import { previewable } from 'components/Previewer';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import ConfirmButton from 'ui/TableCell/ConfirmButton';
import LinkButton from 'ui/TableCell/LinkButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';

export const dataSource = new DataSource('/river/river_alarm/getTicketLists');

function List({ priview }) {
  const CarEffect = [
    { label: '是', value: 1 },
    { label: '否', value: 2 },
  ];
  const columns = [
    serial(true),
    { title: '联单编号', dataIndex: 'number' },
    { title: '项目名称', dataIndex: 'project_name' },
    { title: '运企名称', dataIndex: 'transport_name' },
    { title: '车辆号牌', dataIndex: 'plate_num' },
    { title: '自编号', dataIndex: 'car_id' },
    { title: '核定方量', dataIndex: 'volumn' },
    { title: '起点', dataIndex: 'start_name' },
    { title: '起点时间', dataIndex: 'ticket_start_time' },
    { title: '运输物品', dataIndex: 'materiel_name' },
    { title: '结束时间', dataIndex: 'ticket_end_time' },
    { title: '运输时长', dataIndex: 'transport_time' },
    { title: '开单人', dataIndex: 'op_name' },
  ];

  const querys = [
    { title: ['起点', '时间'], key: 'begin_times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
    { title: ['结束', '时间'], key: 'end_times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
    { title: '请选择项目', key: 'project_id', type: 'select', options: '/river/river_project/getRiverProjectOption', valueKey: 'id', labelKey: 'name', style: { width: 240 } },
    { title: '请选择运企', key: 'transport_id', type: 'select', options: '/river/river_base/getRiverTransportOption', valueKey: 'id', labelKey: 'name', style: { width: 300 } },
    // { title: '请选择起点', key: 'start_department_id', type: 'select', options: CarEffect, style: { width: 220 } },
    { title: '请输入车辆号牌、自编号、开单人模糊搜索', key: 'keyword', style: { width: 360 } },
  ];

  const buttons = [
    { title: '导出', download: '/river/river_alarm/exportTicketLists' },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
      showNewTicket={true}
    />
  );
}

export default previewable(List)
