import dataSource from './dataSource';
import ThreeSteps from './ThreeSteps';

const [Section, Create, Update] = ThreeSteps(dataSource, {
  title: '资料员',
  name: 'docers',
  deleteApi: '/web/Transport/deleteOneDocer',
  createApi: '/web/Transport/createOneDocer',
  updateApi: {
    init: '/web/Transport/getOneDocer',
    submit: '/web/Transport/updateOneDocer',
  },
  indexs: [
    { label: '姓名', required: true, key: 'name' },
    { label: '身份证号', required: true, key: 'id_card' },
    { label: '手机号', required: true, key: 'phone' },
    { label: '任命书文号', required: false, key: 'number' },
    { label: '任命书有效期开始', required: false, key: 'start_time', type: 'date', format: 'YYYY-MM-DD', render: ds => ds && ds.slice(0, 10) },
    { label: '任命书有效期结束', required: false, key: 'end_time', type: 'date', format: 'YYYY-MM-DD', render: ds => ds && ds.slice(0, 10) },
  ],
});

export {
  Section as DocersSection,
  Create as DocersCreate,
  Update as DocersUpdate,
};
