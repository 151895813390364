import React from 'react';
import { Modal } from 'antd';

import map from 'utils/map';
import request from 'utils/request';

export default (GET, PUT) => class LocationPicker extends React.PureComponent {
  state = {}

  mapRef = React.createRef()

  componentDidMount() {
    // const { match: { params: { id } } } = this.props;
    // 获取经纬度与名字
    request(GET, this.props.match.params).then(body => body.data.data).then(({ name, ...coord }) => {
      // 百度转高德
      const { lat, lng } = map.baidu2amap(coord);
      // 初始化地图
      this.map = new window.AMap.Map(this.mapRef.current, {
        mapStyle: 'amap://styles/macaron',
      });
      this.setState({ lat, lng, name });
      if (lat && lng) {
      // if (false) {
        // 已定位
        const marker = new window.AMap.Marker({
          position: new window.AMap.LngLat(lng, lat),
          animation: 'AMAP_ANIMATION_BOUNCE',
          label: {
            content: name,
            direction: 'center',
            offset: new window.AMap.Pixel(0, -35),
          },
        });
        this.map.add(marker);
        this.map.setFitView([marker], false, [0, 0, 0, 0], 14);
      } else {
        // 未定位
        const marker = new window.AMap.Marker({ visible: false });
        this.map.add(marker);
        // eslint-disable-next-line no-shadow
        this.map.on('click', ({ lnglat: coord }) => {
          this.setState({ coord });
          marker.setPosition(coord);
          marker.show();
        });
      }
    });
  }

  goBack = () => this.props.history.goBack()

  onConfirm = () => {
    // 高德转百度
    const { lng, lat } = map.amap2baidu(this.state.coord);
    const { match: { params: { id } } } = this.props;
    request(PUT, { id, lng, lat }).then(this.goBack);
  }

  render() {
    const { lat, lng, name, coord } = this.state;
    const needLocate = !lat || !lng;
    return (
      <Modal
        visible
        width={1000}
        bodyStyle={{ padding: 0 }}
        title={needLocate ? '未定位，请在地图中点击进行定位!' : name}
        cancelText={needLocate ? '取消' : '返回'}
        onCancel={this.goBack}
        okText={needLocate ? '提交' : '确定'}
        onOk={coord ? this.onConfirm : this.goBack}
        okButtonProps={{
          type: needLocate ? 'primary' : 'default',
          disabled: needLocate && !coord,
        }}
      >
        <div ref={this.mapRef} style={{ width: '100%', height: 600 }} />
      </Modal>
    );
  }
};
