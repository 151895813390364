import React, { useState, useCallback, useEffect } from 'react';
import { Modal } from 'antd';
import aMap from 'utils/map';

function createAMap() {
  const [amap, setAmap] = useState();
  const mapRef = useCallback((element) => !!element && setAmap(new window.AMap.Map(element, {
    mapStyle: 'amap://styles/macaron',
  })), []);
  const isMapUsable = useCallback(() => (!!amap), [amap]); // 判断echart实例是否可用
  useEffect(() => () => { (isMapUsable() && amap.destroy( )) }, [amap]); // 卸载echart实例
  return [mapRef, isMapUsable, amap];
}

export default function Map({ history, match, location, ...props }){
  const [mapRef, isMapUsable, map] = createAMap();
  const { name, lng, lat } = location.state;
  const back = () => history.goBack();
  //
  useEffect(() => {
    if(!isMapUsable()) return;
    const data = aMap.baidu2amap({lng, lat});
    const ln = data.lng;
    const la = data.lat;
    const marker = new window.AMap.Marker({
      position: new window.AMap.LngLat(ln, la),
      animation: 'AMAP_ANIMATION_BOUNCE',
      label: {
        content: name,
        direction: 'center',
        offset: new window.AMap.Pixel(0, -35),
      },
    });
    map.add(marker);
    map.setFitView([marker], false, [0, 0, 0, 0], 14);
  }, [isMapUsable, map]);

  return (
    <Modal
      visible
      width={1000}
      bodyStyle={{ padding: 0 }}
      footer={null}
      title={name}
      onCancel={back}
    >
      <div ref={mapRef} style={{ width: '100%', height: 600 }} />
    </Modal>
  )
}
