import React from 'react';
import { Route } from 'react-router-dom';
import { previewable } from 'components/Previewer/index';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import LinkButton from 'ui/TableCell/LinkButton';
import ImagePreviewButtonRender from 'ui/TableCell/ImagePreviewButtonRender';
import width from 'ui/TableCell/width';
import actions from 'ui/TableCell/actions';
import serial from 'ui/TableCell/serial';
import debug from 'utils/debug';
import Details from "./Details";

export const dataSource = new DataSource('/web/blacklist/getDriverBlacklist', {list_type: 2});

const typeArr = [
  '',
  '违反信号灯',
  '逆向行驶',
  '连续1周GPS在线率低于90%',
  '连续1周GPS超速超过5次',
  '温江区沙石、商砼及扬尘信息化监管平台认证信息不全',
  '采用黑名单驾驶员',
  '吸毒驾车',
  '饮酒驾驶',
  '车辆智能车载监控设备连续1周在线率低于90%',
  '连续1周摄像头被发现2次',
  '连续1周不按规定行驶被发现2次',
];
function List({ preview }) {
  console.log(preview)
  debug.render('List');
  const render = ImagePreviewButtonRender(preview);
  const columns = [
    serial(true),
    { title: '驾驶员', dataIndex: 'driver_name' },
    { title: '身份证照片', dataIndex: 'id_card_photo', render },
    { title: '身份证', dataIndex: 'id_card' },
    { title: '事由', dataIndex: 'reason', render: function(v){return typeArr[v]} },
    { title: '进入时间', dataIndex: 'created_time' },
    { title: '驾驶证照片', dataIndex: 'driver_license_photo', render },
    { title: '所属运企', dataIndex: 'transport_name' },
    { title: '所属区域', dataIndex: 'area_name'},
    actions({
      width: 100,
      render: ($, { id }) => (
        <LinkButton to={`./2/${id}/details`}>详情</LinkButton>
      ),
    }),
  ];

  const querys = [
    { title: '关键字', key: 'keyword', style: { width: 200 } },
  ];

  const buttons = [
    { title: '导出', download: '/web/blacklist/exportDriverBlacklist', extraParams: {list_type: 2} },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
      extraTotal={'共计驾驶员数量: '}
    />
  );
}

const PreviewableList = previewable(List);

export default function Driver({path}) {
  return (
    <>
      <Route path={path} component={PreviewableList}/>
      <Route path={path + ':types/:id/details'} component={Details}/>
    </>
  );
}
