// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._51qBG8hunntGKP46Eajc {\n  padding: 20px;\n}\n._51qBG8hunntGKP46Eajc ._38B0XT8nXUj2F_J8jUtxK5 {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 1rem;\n  padding: 1rem 0;\n}\n._51qBG8hunntGKP46Eajc ._38B0XT8nXUj2F_J8jUtxK5 .f4BiSlE6k6RNAwcHcheCf {\n  width: auto;\n}\n._51qBG8hunntGKP46Eajc ._38B0XT8nXUj2F_J8jUtxK5 ._3_wryhVPmAfU7Wruwr0Gva:last-child {\n  margin-right: 0;\n}\n._51qBG8hunntGKP46Eajc ._38B0XT8nXUj2F_J8jUtxK5 ._1GVgz0OWuzvBppdVi6Inx5 {\n  margin-left: 15px;\n}\n._51qBG8hunntGKP46Eajc ._3hgDTYOSSdbdwxgMKWbUbk button {\n  margin-left: 5px;\n}", ""]);
// Exports
exports.locals = {
	"Form": "_51qBG8hunntGKP46Eajc",
	"topLine": "_38B0XT8nXUj2F_J8jUtxK5",
	"ant-input-search": "f4BiSlE6k6RNAwcHcheCf",
	"ant-form-item": "_3_wryhVPmAfU7Wruwr0Gva",
	"search": "_1GVgz0OWuzvBppdVi6Inx5",
	"item": "_3hgDTYOSSdbdwxgMKWbUbk"
};
module.exports = exports;
