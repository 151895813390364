// https://ant.design/components/date-picker-cn/#%E5%85%B1%E5%90%8C%E7%9A%84-API
// https://ant.design/components/date-picker-cn/#RangePicker
import React, {useState} from 'react';
import { DatePicker, message } from 'antd';
import moment from 'moment';
import { JudgeWeekDay } from 'components/Previewer/FilterFunc';
function start2end(m1, m2) {
  return [moment.isMoment(m1) && m1.startOf('date'), moment.isMoment(m2) && m2.endOf('date')].filter(value => value);
}
let num = 0;
export default function RangePicker({ value, format = 'YYYY-MM-DD', disabledDate, judgeIsWeekDay, placeholder, onChange, ...props }) {
  const [waitDate, setWaitDate] = useState();
  const date = moment();
  const weekOfday = date.format('E'); //计算今天是这周第几天
  const lastMonday = date.subtract(weekOfday - 1, 'days').startOf('day');//周一日期
  const lastSunday = moment().add(7 - weekOfday, 'days').endOf('day'); //周末日期
  const nowDate = moment();
  const nowYear = nowDate.get('year');
  // const startGq = moment(`${nowYear}-09-30 14:59:59`)
  // const endGq = moment(`${nowYear}-10-08 23:59:59`)
  const startGq = moment(`2021-02-10 14:59:59`) || moment('2021-04-02 14:59:59');
  const endGq = moment(`2021-02-17 23:59:59`) || moment('2021-04-05 23:59:59`');
  function disabledDateFunByNotWeekDay(current) { // 非工作日可选30天
    if(disabledDate) {
      if(!current) {
        return false
      } else {
        if(waitDate && waitDate.length > 0) {
          if(judgeIsWeekDay) { // 如果是工作日 做不可选时间判断
            if(waitDate[0].diff(lastMonday, 'day') > 13) {
              num ++;
              setWaitDate();
              if(num === 1) {
                return message.warn({
                  content: '工作日申报，离线开始时间为本周一至下周日23:59:59,请重新选择',
                  duration: 5
                });
              }
            } else {
              num = 0;
              return current < moment(lastMonday) || current > moment(waitDate[0]).add(29, 'day').endOf('day');
            }
          }
          else { // 非工作日申报
            // if(!(JudgeWeekDay(waitDate[0].day(), 15))) {
            //   setWaitDate();
            //   if(num === 1) {
            //     num ++;
            //     return message.warn({
            //       content: '非工作日申报，离线开始时间为本周五15:01至本周日23:59:59,请重新选择',
            //       duration: 5
            //     });
            //   }
            // }else {
            //   num = 1;
            //   return (!(JudgeWeekDay(current.day(), 15) && (current > lastMonday) && (current < lastSunday)) && (current < lastSunday)) || current > moment(waitDate[0]).add(29, 'day').endOf('day');
            // }
          }
        } else {
          if(judgeIsWeekDay) {
            return current < moment(lastMonday) || current > moment(date).add(13, 'day').endOf('day');
          } else {
            return !(JudgeWeekDay(current.day(), 15) && (current > lastMonday) && (current < lastSunday));
            // return !(JudgeWeekDay(current.day(), 15) && (current > lastMonday)) && !(current < lastSunday);
            // return (!(JudgeWeekDay(current.day(), 15) && (current > lastMonday) && (current < lastSunday)) && (current < lastSunday));
            // return !(JudgeWeekDay(current.day(), 15) && (current > lastMonday) && (current < lastSunday));
          }
        }
      }
    }
  }
  function disabledDateFun(current) {
    if(disabledDate) {
      if(!current) {
        return false
      } else {
        if(!judgeIsWeekDay) {
          if(nowDate > startGq && nowDate < endGq) {
            if(current > startGq && current < endGq) {
              return false
            }
            else return true
          }
          return !(JudgeWeekDay(current.day(), 15) && (current > lastMonday) && (current < lastSunday));
        } else  {
          if(waitDate && waitDate.length > 0) {
            if(waitDate[0].diff(lastMonday, 'day') > 13) {
              num ++;
              setWaitDate();
              if(num === 1) {
                return message.warn({
                  content: '工作日申报，离线开始时间为本周一至下周日23:59:59,请重新选择',
                  duration: 5
                });
              }
            } else {
              num = 0;
              return current < moment(lastMonday) || current > moment(waitDate[0]).add(29, 'day').endOf('day');
            }
          } else {
            return current < moment(lastMonday) || current > moment(date).add(13, 'day').endOf('day');
          }
        }
      }
    }
  }

  function changeDate(moments) {
    const ms = props.showTime ? moments : start2end(...moments);
    if(moments.length === 0) {
      const ds = ms.map(m => m.format('YYYY-MM-DD HH:mm:ss'));
      onChange(ds);
      return;
    }
    if(disabledDate) {
      if(!judgeIsWeekDay) {
        if(lastSunday.diff(ms[0], 'hour') < 2) {
          ms[0].set('hours', 21);
          ms[1].set('hours', 23)
        } else {
          if(ms[1].diff(ms[0], 'hour') < 2) {
            ms[1].add(2, 'hour');
          }
          if(nowDate < startGq || nowDate > endGq) {
            if(!JudgeWeekDay(ms[0].day(), ms[0].hour())) {
              message.warn('非工作日申报，选择车辆GPS离线开始时间必须是本周非工作日时间，请重新选择！');
              return;
            }
            if(ms[1].diff(ms[0], 'day') > 30) {
              return  message.warn('选择车辆GPS离线时间必须小于30天，请重新选择！');
            }
          }
        }
      }else {
        if(ms[0].diff(ms[1], 'hour') === 0 ) {
          ms[1].add(4, 'hour');
        }
        if(ms[1].diff(ms[0], 'hour') < 4) {
          message.warn('选择车辆GPS离线时间必须大于4小时，请重新选择！');
          return;
        }
        if(ms[1].diff(ms[0], 'day') > 30) {
          message.warn('选择车辆GPS离线时间必须小于30天，请重新选择！');
          return;
        }
      }
    }
    const ds = ms.map(m => m.format('YYYY-MM-DD HH:mm:ss'));
    onChange(ds);
  }

  function onCalendarChange(moment) {
    if(disabledDate) {
      if(judgeIsWeekDay) {
        if(!(moment[0].diff(lastMonday, 'day') > 13)) {
          setWaitDate(moment)
        }else {
          setWaitDate()
        }
      }else {
        const current = moment[0];
        if((JudgeWeekDay(current.day(), 15) && (current > lastMonday) && (current < lastSunday))) {
          // if((JudgeWeekDay(moment[0].day(), 15))) {
          setWaitDate(moment)
        }else {
          setWaitDate()
        }
      }
    }
  }
  return (
    <DatePicker.RangePicker
      {...props}
      allowClear={disabledDate ? false : props.allowClear ? true : props.allowClear}
      format={format}
      placeholder={placeholder || []}
      disabledDate={disabledDateFun}
      value={(value || []).map(item => (item ? moment(item, 'YYYY-MM-DD HH:mm:ss') : null))}
      onChange={(moments) => changeDate(moments)}
      onCalendarChange={(moment) => onCalendarChange(moment)}
      // onChange={(moments) => {
      //   const ms = props.showTime ? moments : start2end(...moments);
      //   const ds = ms.map(m => m.format('YYYY-MM-DD HH:mm:ss'));
      //   onChange(ds);
      //   // const start = moments[0] && moments[0].format('YYYY-MM-DD HH:mm:ss');
      //   // const end = moments[1] && moments[1].format('YYYY-MM-DD HH:mm:ss');
      //   // const dateStrings = [start, end];
      //   // onChange(dateStrings.filter(v => v).length > 0 ? dateStrings : undefined);
      // }}
    />
  );
}
