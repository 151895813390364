import React from 'react';
import common from 'utils/common';
import 'components/css/TableClass.global.scss';
import ShowImages from "components/PopoverPage/ShowImages";
import {Form, Row, Col, Input, Button, Icon, Collapse, Upload, message, Select, DatePicker, InputNumber,} from "antd";
import axios from "axios";
import request from "utils/request";

const Panel = Collapse.Panel;
const Option = Select.Option;
import moment from 'moment';
const dateFormat = 'YYYY-MM-DD';
const driver_arr = [];
export default class TransPortCarEditMsg extends React.PureComponent{
  static getDerivedStateFromProps(nextProps, presState){
    return {

    }
  }
  constructor(props) {
    super(props);
    this.state = {
      _whatever: [],
      data: props.data.data,
      activeKey: [],
      previewVisible: false,
      previewImage: '',
      dataImages: [],
      img_flag: false,
      driver_type: 0,
      driver_id: '',
      driver_arr: [],
    }
  }
  componentDidMount() {
    this.questWhatever();
    let that = this;
    this.state.data.driver.map((v, i) => {
      that.state.driver_arr.push(this.state.data.driver[i][0].value)
    });
  }
  questWhatever() {
    request({
      url: '/web/transport_car/getDriverList',
      data: {},
    })
      .then(res => res.data.data)
      .then(_whatever => {
        this.setState({ _whatever });
      });
  }
  checkPhoneAccount = (rule, value, callback) => {
    var re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
    if (value.length === 11 || re.test(value)) {
      callback();
    } else {
      callback('请填写正确的手机号');
    }
  };
  handleChange = ({ fileList }) => this.setState({ fileList })
  upLoadImg = (i) => {
    return (
      <div className="clearfix" style={{display: 'inline-block'}}>
        <Upload
          customRequest={({file, filename, onSuccess, onError,}) => {
            const formData = new FormData();
            formData.append(filename, file);
            axios({
              url: URLS.FILE,
              method: 'post',
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: formData,
            }).then((res) => {
              const { data: src, msg } = res.data || {};
              if (res.status === 200 && res.data.code === 200) {
                console.log(i, this.state.data);
                if(this.state.data.base[i].value.split(',').length <= 4){
                  this.onChange(i, src)
                }else{
                  message.warn('最多课上传5张照片')
                }

              } else {
                message.error('上传失败');
              }
            }).catch(console.error);
          }}
          listType="picture-card"
          onChange={this.handleChange}
        >
          <div>
            <Icon type="plus" />
            <div className="ant-upload-text">Upload</div>
          </div>
        </Upload>
      </div>
    )
  };
  upLoadDriverImg = (i, j) => {
    return (
      <div className="clearfix" style={{display: 'inline-block'}}>
        <Upload
          customRequest={({file, filename, onSuccess, onError,}) => {
            const formData = new FormData();
            formData.append(filename, file);
            axios({
              url: 'http://ticketapi.shomes.cn/libraries/Uploder/upload',
              method: 'post',
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: formData,
            }).then((res) => {
              const { data: src, msg } = res.data || {};
              if (res.status === 200 && src) {
                this.onDriverChange(i, j, src)
              } else {
                onError(msg);
              }
            }).catch(console.error);
          }}
          listType="picture-card"
          onChange={this.handleChange}
        >
          <div>
            <Icon type="plus" />
            <div className="ant-upload-text">Upload</div>
          </div>
        </Upload>
      </div>
    )
  };
  onChange = (i, v, flag) => {
    if(flag) {
      this.setState(({ data }) => ({
        data: {
          ...data,
          base: data.base.map((item, index) => ({
            ...item,
            value: index === i ? v : item.value
          })),
        },
      }));
    }
    else{
      this.setState(({ data }) => ({
        data: {
          ...data,
          base: data.base.map((item, index) => ({
            ...item,
            value: index === i ? item.extra ? ( item.value !=='' ? (item.value + ',' + v ) : v) : v : item.value
          })),
        },
      }));
    }
  };
  onDriverChange = (i, j, v) => {
    this.setState(({ data }) => ({
      data: {
        ...data,
        driver: data.driver.map((item, index) => i !== index ? item : item.map((el, x) => x !== j ? el : {...el, value: (!el.extra ? v : [el.value, v].join(',')) })),
      },
    }));
  };
  showImages = (val = '') => {
    let images = [];
    if(val.indexOf(',') > -1){
      images = val.split(',');
    }else {
      images.push(val)
    }
    this.setState({
      dataImages: images,
      img_flag: true
    });
  };
  deleteImg = (i, v = '', item) => {
    let arr = []
    if(v.indexOf(',') > -1){
      arr = v.split(",")
    }else{
      arr = [v]
    }
    arr.map( (el, j) => {
      if(el === item) {
        arr.splice(j, 1)
      }
    });
    this.onChange(i, arr.join(","), true)
  };
  getFields = () => {
    const children = [];
    const state = {...this.state};
    const { getFieldDecorator } = this.props.form;
    state.data.base.map((v, i) => {
      if(!v.hide) {
        if(v.extra){
          if(!!v.value){
            let arr = [];
            let showImgs = [];
            if(v.value.indexOf(',') > -1){
              arr = v.value.split(',')
            }else{
              arr.push(v.value)
            }
            arr.map((v) => {
              if(v.indexOf('http') > -1){
                showImgs.push(v)
              }else{
                showImgs.push(common.pic_url + v)
              }
            });
            children.push(
              <Col span={8} key={i}>
                <Form.Item label={v.label} style={{minHeight: 100, maxHeight: 200}} required={v.required}>
                  {
                    showImgs.map((item, j) => {
                      return <div style={{display: 'inline-block', marginRight: 13, position: 'relative'}}><img onClick={ () => { this.showImages(item) } } style={{ width: 60, cursor: 'pointer'}} src={item} key={j} alt=""/>
                        <img src={"/out.png"} onClick={ () => { this.deleteImg(i, v.value, item)}} style={{width: 13, cursor: 'pointer', position: 'absolute', right: '-6px', top: '-4px'}} alt=""/> </div>
                    })
                  }
                  {this.upLoadImg(i)}
                </Form.Item>
              </Col>
            );
          }
          else{
            children.push(
              <Col span={8} key={i}>
                <Form.Item label={v.label} required={v.required}>
                  {this.upLoadImg(i)}
                </Form.Item>
              </Col>
            )
          }
        }
        else if(v.number) {
          children.push(
            <Col span={8} key={i}>
              <Form.Item label={v.label}>
                {getFieldDecorator(v.label, {
                  initialValue: v.value,
                  rules: [ {
                    required: v.required, message: '请填写' + v.label,
                  }],
                })(
                  <InputNumber placeholder={`请填写${v.label}`} style={{width: '100%'}} onChange={(e) => this.onChange(i, e)} disabled={v.disabled} />
                )}
              </Form.Item>
            </Col>
          );
        }
        else if(v.select) {
          children.push(
            <Col span={8} key={i}>
              <Form.Item label={v.label}>
                {getFieldDecorator(v.label, {
                  initialValue: v.value,
                  rules: [ {
                    required: v.required, message: '请选择' + v.label,
                  }],
                })(
                  <Select style={{width: '100%'}} placeholder={`请选择${v.label}`} onChange={(option, e) =>{ this.onChange(i, e.props.value) }} disabled={v.disabled}>
                    {
                      v.select.map((item, j) => {
                        return <Option key={j} value={item.value} title={i}>{item.label}</Option>
                      })
                    }
                  </Select>
                )}
              </Form.Item>
            </Col>
          );
        }
        else if(v.date){
          children.push(
            <Col span={8} key={i}>
              <Form.Item label={v.label}>
                {getFieldDecorator(v.label, {
                  initialValue: v.value && v.value !== '' ? moment(v.value, dateFormat) : '',
                  rules: [ {
                    required: v.required, message: '请选择' + v.label,
                  }],
                })(
                <DatePicker style={{width: '100%'}} placeholder={`请选择${v.label}`} format={dateFormat} onChange={ (d, str) => this.onChange(i, str) } />
                )}
              </Form.Item>
            </Col>
          );
        }
        else{
          if(v.phone) {
            children.push(
              <Col span={8} key={i}>
                <Form.Item label={v.label}>
                  {getFieldDecorator(v.label, {
                    initialValue: v.value,
                    rules: [ {
                      required: v.required, message: '请填写' + v.label,
                    },{
                      validator: this.checkPhoneAccount,
                    }],
                  })(
                    <Input placeholder={'请填写' + v.label} onChange={(e) => this.onChange(i, e.target.value)} disabled={v.disabled} />
                  )}
                </Form.Item>
              </Col>
            );
          }
          else{
            children.push(
              <Col span={8} key={i}>
                <Form.Item label={v.label}>
                  {getFieldDecorator(v.label, {
                    initialValue: v.value,
                    rules: [ {
                      required: v.required, message: '请填写' + v.label,
                    }],
                  })(
                    <Input placeholder={'请填写' + v.label} onChange={(e) => this.onChange(i, e.target.value)} disabled={v.disabled} />
                  )}
                </Form.Item>
              </Col>
            );
          }
        }
      }
    });
    return children;
  };
  getDriverFields = () => {
    const children = [];
    const state = {...this.state};
    if(state.data.driver.length === 0){
      children.push(
        <div style={{textAlign: 'center', fontSize: 14, padding: 10}}> 暂无司机信息 </div>
      )
    }
    state.data.driver.map((v, i) => {
      children.push(
        <Panel header={'司机: ' + state.data.driver[i][1].value} key={i}>
          {
            v.map((item, j) => {
              if(!item.hide) {
                if(item.extra){
                  if(item.value != '' && item.value != null){
                    let arr = [];
                    let showImgs = [];
                    if(item.value.indexOf(',') > -1){
                      arr = item.value.split(',')
                    }else{
                      arr.push(item.value)
                    }
                    arr.map((v) => {
                      if(v.indexOf('http') > -1){
                        showImgs.push(v)
                      }else{
                        showImgs.push(common.pic_url + v)
                      }
                    });
                    return (
                      <Col span={8} key={j} >
                        <Form.Item label={item.label}>
                          {
                            showImgs.map((s, x) => {
                              return <img onClick={ () => { this.showImages(s) } } style={{marginRight: 5, width: 40, cursor: 'pointer'}} src={s} key={x} alt=""/>
                            })
                          }
                        </Form.Item>
                      </Col>
                    );
                  }
                  else{
                    return (
                      <Col span={8} key={j}>
                        <Form.Item label={item.label}>
                          <span>暂无{item.label}</span>
                        </Form.Item>
                      </Col>
                    )
                  }
                }
                else if(v.select) {
                  return (
                    <Col span={8} key={i}>
                      <Form.Item label={v.label}>
                        <Select style={{width: '100%'}} value={v.value} onChange={this.onSelectChange} disabled={v.disabled}>
                          {
                            v.select.map((item, j) => {
                              return (
                                <Option key={j} value={item.value} title={i}>{item.label}</Option>
                              )
                            })
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                  );
                }
                else if(v.date){
                  return (
                    <Col span={8} key={i}>
                      <Form.Item label={v.label}>
                        <DatePicker style={{width: '100%'}} onChange={ this.onDateChange } onOpenChange={() => {this.dateFocus(i)}} />
                      </Form.Item>
                    </Col>
                  );
                }
                else{
                  return <Col span={8} key={j}>
                    <Form.Item label={item.label}>
                      <Input placeholder={`请填写${item.label}`} value={item.value} onChange={(e) => this.onDriverChange(i, j, e.target.value)} disabled={item.disabled} />
                    </Form.Item>
                  </Col>
                }
              }
            })
          }
          <div className="driver-handle">
            {/*<Button onClick={() => {this.EditCarDriverMsg(v.id, i)}} type={'primary'}>修改</Button>*/}
            <Button onClick={() => {this.DeleteCarDriverMsg(v[0].value, i, v[1].value)}} type={'primary'}>解除绑定</Button>
          </div>
        </Panel>
      )
    });
    return children ;
  };
  handleSearch = (e) => {
    this.props.editTransCarData(this.state.data);
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
    });
  };
  //解除绑定
  DeleteCarDriverMsg = (driver_id, i, driver_name) => {
    let that = this;
    that.props.DeleteCarDriverMsg(that.state.data.base[0].value, that.state.data.id, driver_id, driver_name, (data) => {
      let driver_arr = [];
      data.driver.map((v, i) => {
        driver_arr.push(v[0].value)
      });
      that.setState({
        data: data,
        driver_arr: driver_arr
      })
    });
  };
  AddDriver = (type) => {
    this.setState({
      driver_type: type
    })
  };
  BindDriver = () => {
    const children = [];
    this.state._whatever.map((v, i) => {
      children.push(<Option key={v.id} value={v.id} data_value={v.phone}>{v.phone}</Option>);
    });
    return <div style={{display: 'flex', marginBottom: 10 }}>
      <Select
        mode="multiple"
        placeholder="请选择需要绑定的司机"
        maxTagCount={3}
        value={this.state.driver_arr}
        onChange={ this.DriverHandleChange }
        style={{ width: '100%' }}
        optionFilterProp={'data_value'}
      >
        {children}
      </Select>
      <Button style={{ marginLeft: 10}} type="primary" onClick={() => { this.BindDriverInfo() }}>保存</Button>
    </div>
  };
  DriverHandleChange = (e) => {
    if(e.length > 3) {
      message.warn('已超过最多关联司机数');
      return false;
    }
    this.setState({
      driver_arr: e,
      driver_id: e.join(',')
    });
  };
  BindDriverInfo = () => {
    let that = this;
    this.props.BindDriverInfo(that.state.driver_id, that.state.data.id, (data) => {
      this.setState({
        data: data
      });
    })
  };
  callback = () => {

  };
  HiddenImages = () => {
    this.setState({
      img_flag: false
    })
  };
  render () {
    const state = {...this.state};
    return (
      <div className='car-table-class'>
        <Form
          className="ant-advanced-search-form"
          onSubmit={this.handleSearch}
        >
          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="车辆基础信息" key="1">
              <div className="driver-handle">
                <Button type="primary" htmlType="submit">保存</Button>
                {/*<Button onClick={() => {this.AddDriver(2)}} type={'primary'}>新增</Button>*/}
              </div>
              <Row gutter={24}>{this.getFields()}</Row>
            </Panel>
            <Panel header="司机信息" key="2">
              <div className="driver-handle">
                <Button onClick={() => {this.AddDriver(1)}} type={'primary'}>绑定</Button>
                {/*<Button onClick={() => {this.AddDriver(2)}} type={'primary'}>新增</Button>*/}
              </div>
              {
                state.driver_type === 1 ?  this.BindDriver() : null
              }
              <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                {this.getDriverFields()}
              </Collapse>
            </Panel>
          </Collapse>
        </Form>
        {
          state.img_flag ? <ShowImages data={{ data: state.dataImages, width: 480}} callback={this.HiddenImages.bind(this)} /> : null
        }
      </div>
    )
  }
}
TransPortCarEditMsg = Form.create({})(TransPortCarEditMsg);
