import React from 'react';

export const inputs = [
  { label: '区市县', placeholder: '请选择区市县', key: 'area_id', hide: true, type: 'select', options: '/rest/dispatch_task/area', valueKey: 'id', labelKey: 'name', required: true, size: 'large', style: { width: 400 } },
  { label: '部门', placeholder: '请选择部门', key: 'department_id', type: 'select', options: 'web/count_alarm/flowPostDepartment', valueKey: 'id', labelKey: 'name', required: true, size: 'large', rely: 'area_id', relyAs: 'area_id', style: { width: 400 } },
  { label: '职位', placeholder: '请选择职位', key: 'position_id', type: 'select', options: '/web/count_alarm/flowPostPosition', valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['area_id', 'department_id'], reliesAs:['area_id', 'department_id'], required: true, size: 'large', style: { width: 400 } },
  { label: '人员', placeholder: '请选择人员', key: 'users_id', type: 'select', options: '/web/count_alarm/flowPostUser', valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['area_id', 'department_id', 'position_id'], reliesAs:['area_id', 'department_id', 'position_id'], required: true, size: 'large', style: { width: 400 } },
];
export const dispatch = [
  { label: '区市县', placeholder: '请选择区市县', key: 'area_id', hide: true, type: 'select', options: '/rest/dispatch_task/area', valueKey: 'id', labelKey: 'name', required: true, size: 'large', style: { width: 400 } },
  { label: '部门', placeholder: '请选择部门', key: 'department_id', type: 'select', options: '/rest/dispatch_task/department', valueKey: 'id', labelKey: 'name', required: true, size: 'large', rely: 'area_id', relyAs: 'area_id', style: { width: 400 } },
  { label: '职位', placeholder: '请选择职位', key: 'position_id', type: 'select', options: '/rest/dispatch_task/position', valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['area_id', 'department_id'], reliesAs:['area_id', 'department_id'], required: true, size: 'large', style: { width: 400 } },
  { label: '人员', placeholder: '请选择人员', key: 'users_id', type: 'select', options: '/rest/dispatch_task/user', valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['area_id', 'department_id', 'position_id'], reliesAs:['area_id', 'department_id', 'position_id'], required: true, size: 'large', style: { width: 400 } },
  { label: '任务描述', placeholder: '请填写任务描述', type: 'textarea', key: 'description', required: true, style: {width: 400}},
  { label: '图片上传', type: 'upload', key: 'images', styles: {width: 64}, required: true }
];
export const dispatchResend = [
  { label: '区市县', placeholder: '请选择区市县', key: 'area_id', hide: true, type: 'select', options: '/rest/dispatch_task/area', valueKey: 'id', labelKey: 'name', required: true, size: 'large', style: { width: 400 } },
  { label: '部门', placeholder: '请选择部门', key: 'department_id', type: 'select', options: '/rest/dispatch_task/department', valueKey: 'id', labelKey: 'name', required: true, size: 'large', rely: 'area_id', relyAs: 'area_id', style: { width: 400 } },
  { label: '职位', placeholder: '请选择职位', key: 'position_id', type: 'select', options: '/rest/dispatch_task/position', valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['area_id', 'department_id'], reliesAs:['area_id', 'department_id'], required: true, size: 'large', style: { width: 400 } },
  { label: '人员', placeholder: '请选择人员', key: 'users_id', type: 'select', options: '/rest/dispatch_task/user', valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['area_id', 'department_id', 'position_id'], reliesAs:['area_id', 'department_id', 'position_id'], required: true, size: 'large', style: { width: 400 } },
  { label: '任务描述', placeholder: '请填写任务描述', type: 'textarea', key: 'description', required: true, style: {width: 400}}
];
