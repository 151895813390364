import React from 'react';
import ModalFormAddPage from 'ui/ModalFormAddPage';
import debug from 'utils/debug';
import request from "utils/request";
import {Button, message} from "antd";
import controller from "./controller";
const ReasonOptions = [
  {value: '泥巴', label: '泥巴'},
  {value: '垃圾', label: '垃圾'},
  {value: '未装满', label: '未装满'},
  {value: '其他', label: '其他'},
];



export default function Create({ history, location, match }) {
  debug.render('Create');
  const {params} = location.state;
  const {params: {id, area_id, department_id, start_id}} = location.state;
  const inputs = [
    {
      label: '起点',
      children: [
        { placeholder: '起点部门', key: 'start_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
        { placeholder: '起点', key: 'start_id', type: 'select', options: '/web/instance/getBuildingAdd', extraData: { types: 1 }, valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['start_department_id'], reliesAs:['id'], required: true, style: { width: 240 } },
        { placeholder: '运输车', key: 'car_num', type: 'select', options: '/web/Car/getCarList', realTimeSearch: 'keywords', valueKey: 'car_num', labelKey: 'car_num', relies: ['start_department_id', 'start_id'], reliesAs: ['id', 'instance_id'], required: true, style: { width: 130 } },
      ],
     /* children: [
        { placeholder: '起点部门', key: 'start_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
        { placeholder: '起点', key: 'start_id', type: 'select', options: '/web/instance/getBuildingAdd', extraData: { types: 1 }, valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'start_department_id', relyAs: 'id', required: true, style: { width: 240 } },
        // { placeholder: '起点', key: 'start_id', type: 'select', options: '/web/instance/getBuildingAdd', extraData: { types: 1, area_id, id: department_id }, valueKey: 'id', labelKey: 'name', showSearch: true, required: true, style: { width: 240 } },
        { placeholder: '运输车', key: 'car_num', type: 'select', options: '/web/Car/getCarList', showSearch: true, extraData: {id: department_id, instance_id: start_id}, realTimeSearch: 'keywords', valueKey: 'car_num', labelKey: 'car_num', relies: ['start_id'], reliesAs: ['instance_id'], required: true, style: { width: 130 } },
      ],*/
    },
    { label: '方量',
      children: [
        { placeholder: '货箱方量', key: 'car_volumn', disabled: true, options: '/web/ticket/getCarVolumn', precision: 2, rely: 'car_num', type: 'number-options', style: { width: 165 } },
        { placeholder: '扣方', min: 0, step: 0.1, hasZero: true, key: 'deduction_volume', precision: 2, type: 'number-options', disabled: false, style: { width: 165 } },
        { placeholder: '实际方量', key: 'volumn', type: 'number-options', precision: 2, style: { width: 165 }, maxValueRely: 'car_volumn', required: true }
    ]
    },
    { label: '扣方原因', key: 'reason', type: 'radioVolume', options: ReasonOptions, showOrHide: 'deduction_volume', hide: true, required: true },
  ];
  const back = () => history.goBack();
  const onFinish = (data, type) => {

    if(data.deduction_volume !== 0 && data.deduction_volume !== 0.0 && data.deduction_volume !== '0.0' && data.deduction_volume !== '0.00' && !data.reason){
      data.reason = '泥巴'
    }
    const second = JSON.parse(sessionStorage.getItem('secondReceiver')) ? JSON.parse(sessionStorage.getItem('secondReceiver')).name : '';
    if(data.reasonVolumnKey){
      data.reason = data.reasonVolumnKey;
    }
    if(!data.volumn){
      data.volumn = parseFloat(parseFloat(data.car_volumn) - (data.deduction_volume ? parseFloat(data.deduction_volume).toFixed(2) : 0)).toFixed(2);
    }
    request('/web/ticket/editEnterTicket', {...data, id: id, second_receiver: second, transport_id: '0'})
      .then(body => {
        if(body.data.code !== 200){
          message.warn(body.data.msg)
        }else{
          const d = body.data.data;
          sessionStorage.setItem('ticketAddAndPrint', JSON.stringify( {
            types: type,
            ticket_id: d.ticket_id,
            car_type: d.car_type,
            car_directory: d.car_directory,
            car_plan: d.car_plan,
            number: d.number,
            transport_count: d.transport_count,
            building_id: data.start_id,
            car_num: data.car_num,
            volume: data.volumn,
            reason: (data.deduction_volume === 0 || data.deduction_volume === '0.0' || data.deduction_volume === '0.00' || data.deduction_volume === 0.0) ? '' : data.reason,
            driver_name: d.driver_name,
            build_name: d.start_name,
            real_volumn: data.car_volumn,
            deduction_volume: data.deduction_volume,
            date: d.date,
            materielName: data.materielName,
            acquirer_name: data.acquirer_name
          }))
          controller.refresh();
          back();
        }
      })
  };

  return (
    <ModalFormAddPage
      defaultValue={params}
      title="修改电子联单"
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
      modalProps={{
        width: 800,
      }}
      footer={true}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}
    />
  );
}
