import React from 'react';
import request from "utils/request";
import {Modal} from 'antd';
const {confirm} = Modal;

export const setNoticeText = (notice_count) => {
  let text = '通知';
  switch (parseInt(notice_count)) {
    case 0: text = '通知'; break;
    default: text = '再次通知'; break;
  }
  return text;
};

export const flowNotice = (id) =>{
  confirm({
    title: '确定提醒？',
    centered: true,
    onOk() {
      request('web/count_alarm/flowNotice', {id})
        .then(d => {
          const b = d.data;
          if(b.code === 200){
            message.success('提醒成功！');
          }else{
            message.warn(b.msg)
          }
        })
    },
    onCancel() { console.log('Cancel'); },
  })
}
