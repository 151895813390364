import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

export default function LinkButton({ primary, danger, to, children = '修改' }) {
  let type = 'default';
  if (primary) type = 'primary';
  if (danger) type = 'danger';
  return <Button size="small" type={type}><Link to={to}>{children}</Link></Button>;
}
