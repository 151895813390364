import React from 'react';
import {Button} from 'antd';
import style from '../css/CallDriver.scss';
let conn = {};
let rtcCall = {};
export default class CallDriver extends React.PureComponent{
  static getDerivedStateFromProps(props, prevState){
    return {
      msg: props.data.msg,
      call_flag: props.data.call_flag
    }
  };
  constructor(props){
    super(props);
    this.state = {
      msg: props.data.msg,
      call_flag: props.data.call_flag
    }
  }
  componentDidMount(){

  };
  callEnd = () => {
    this.props.rtcCallEnd()
  };
  render () {
    const state = {...this.state};
    return (
      <div className={style.all}>
       <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img src='http://t.cn/RCzsdCq' style={{width: 120,borderRadius: 12, marginRight: 10, marginBottom: 30}} alt='' />
          <span>{state.msg}</span>
          <div style={{width: 80, textAlign: 'center', display: 'inline-block', marginTop: '20px'}}>
            <span style={{display: 'inline-block', background: 'url(/images/phone.png) rgb(239, 35, 60) center no-repeat', borderRadius: 80, width: 80, height: 80, backgroundSize: '50px 22px', cursor: 'pointer', marginRight: 20}} onClick={() => {this.callEnd()}}>{}</span>
          </div>
        </div>
      </div>
    )
  }
}
