import React from 'react';
import { Button, Icon } from 'antd';
import styles from './RouteEditor.scss';

export default function Actions({ steps, current, prevable, nextable, finished, onCancel, onSubmit, onRouteDelete, onRouteCreate, onStepChange, types, onSubmitSure }) {
  return (
    <div className={styles.actions}>
      {!prevable ? (
        <Button className={styles.action} type="danger" onClick={onCancel}><Icon type="close" />返回</Button>
      ) : (
        <Button className={styles.action} type="primary" onClick={() => onStepChange(current - 1)}><Icon type="left" />上一步</Button>
      )}
      <div className={styles.full} />
      {
        types === '1' ?
        <>
          {steps.size > 4 && current !== 0 && current !== (steps.size - 2) && current !== (steps.size - 1) && (
            <Button className={styles.action} type="danger" onClick={onRouteDelete}><Icon type="delete" />删除此运输线路</Button>
          )}
          {current === steps.size - 3 && (
            <Button className={styles.action} type="primary" onClick={onRouteCreate} disabled={!finished}><Icon type="plus" />继续添加运输线路</Button>
          )}
        </>
        :
        <>
          {steps.size > 5 && current !== 0 && current !== (steps.size - 3) && current !== (steps.size - 2) && current !== (steps.size - 1) && (
            <Button className={styles.action} type="danger" onClick={onRouteDelete}><Icon type="delete" />删除此运输线路</Button>
          )}
          {current === steps.size - 4 && (
            <Button className={styles.action} type="primary" onClick={onRouteCreate} disabled={!finished}><Icon type="plus" />继续添加运输线路</Button>
          )}
        </>

      }

      {!nextable ? (
        <>
          <Button className={styles.action} type="primary" onClick={onSubmit}><Icon type="save" />存为草稿</Button>
          <Button className={styles.action} type="primary" onClick={onSubmitSure}><Icon type="save" />确认提交</Button>
        </>
      ) : (
        <Button className={styles.action} type="primary" onClick={() => onStepChange(current + 1)} disabled={!finished}>下一步<Icon type="right" /></Button>
      )}
    </div>
  );
}
