import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import user from 'stores/user';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import LinkButton from 'ui/TableCell/LinkButton';
import DeleteButton from './DeleteButton';
import serial from 'ui/TableCell/serial';
import width from 'ui/TableCell/width';
import actions from 'ui/TableCell/actions';
import { rowSpanRender as render } from 'utils/render';
import { statusOption, statusColors } from 'variables/auditStatus';
import { Divider, Modal, Button } from 'antd';
import styles from "./index.scss";

export const dataSource = new DataSource('/web/route/getSsPlanLists');
const Status = [ ////1草稿 2待审核 3已通过 4已驳回 5已失效
  { value: 1, label: '草稿' },
  { value: 2, label: '待审核' },
  { value: 3, label: '已通过' },
  { value: 4, label: '已驳回' },
  { value: 5, label: '已失效' },
];
const Type = [
  { value: 1, label: '临时线路备案' },
  { value: 2, label: '运输线路申报' },
];

export default function List() {
  const [type, setType] = useState();
  const [visible, setVisible] = useState(false);
  const columns = [
    serial(true),
    { title: '运输起点名称', dataIndex: 'start_name' },
    { title: '运输终点名称', dataIndex: 'end_name_string' },
    { title: '运输品类', dataIndex: 'materiel_name' },
    { title: '排放总量(方)', dataIndex: 'out_volume', width: width(6) },
    { title: '车辆调用', dataIndex: 'car_count', render: (t) => {
      const self = t.split(',')[0];
      const other = t.split(',')[1];
      return (
        <>
          <p>本运企：{self}辆</p>
          <p>外调运企：{other || 0}辆</p>
        </>
      )
    }},
    { title: '申请运输时限', dataIndex: 'times', render: (t, {start_date, end_date}) => (
      <div style={{textAlign: 'center'}}>
        <p>{start_date}</p>
        <p>至</p>
        <p>{end_date}</p>
      </div>
      )
    },
    { title: '备案类别', dataIndex: 'plan_type', width: width(7), render: (t) => t === 1 ? '临时线路备案' : '运输线路申报' },
    { title: '发起时间', dataIndex: 'created_time', width: width(0,19) },
    { title: '备案状态', dataIndex: 'status', width: width(4), render: (t) => (<span style={{ color: statusColors[t]}}>{statusOption[t]}</span>) },
    { title: '审核时间', dataIndex: 'audit_time' },
    { title: '审核人', dataIndex: 'audit_name', width: width(3) },
    //1草稿 2待审核 3已通过 4已驳回 5已失效
    actions({
      width: 230,
      isFixed: true,
      render: render(($, { id, status, plan_type }) => (
        <>
          <LinkButton to={`./${plan_type}/${id}/detail`}>查看详情</LinkButton>
          {(user.getState().roles.id === 101 && (status === 1 || status === 2)) && (
            <>
              <Divider type="vertical" />
              <LinkButton to={`./${plan_type}/${id}/update`}>修改</LinkButton>
            </>
          )}
          {user.getState().roles.id === 101 && (status === 1 || status === 2 || status === 4) && (
            <>
              <Divider type="vertical" />
              <DeleteButton api="/web/route/deleteSsPlanList" plan_id={id} dataSource={dataSource} />
            </>
          )}
        </>
      )),
    }),
  ];
  const querys = [
    { title: '备案类别', key: 'plan_type', type: 'select', options: Type, style: { width: 200 } },
    { title: '备案状态', key: 'plan_status', type: 'select', options: Status, style: { width: 150 } },
    { title: ['时间', '范围'], key: 'times', type: 'time-range', format: 'YYYY-MM-DD' },
    { title: '关键字', key: 'keyword' },
  ];
  const buttons = [];
  if (user.getState().roles.id === 101) {
    buttons.push({
      title: '新增', element: (<Button icon='plus' onClick={() => setVisible(true)}>新 增</Button>)
    });
  }

  return (
    <>
      <TablePage
        dataSource={dataSource}
        buttons={buttons}
        querys={querys}
        columns={columns}
        defaultLimit={10}
      />
      <Modal
        visible={visible}
        title="请选择备案类别"
        centered
        onCancel={() => {setVisible(false);setType();}}
        footer={null}
      >
        <div className={styles.container}>
          <Link to={'./1/create'}><div className={type === 1 ? styles.active : ''} onClick={() => {setType(1);setVisible(false);}}>临时线路备案</div></Link>
          <Link to={'./2/create'}><div className={type === 2 ? styles.active : ''} onClick={() => {setType(2);setVisible(false);}}>运输线路申报</div></Link>
        </div>
      </Modal>
    </>
  )
}
