const SettlementType = [
  {value: 0, label: '全部'},
  {value: 1, label: '砂石场接管'},
  {value: 2, label: '商砼站接管'},
  {value: 3, label: '工地接管'},
  {value: 4, label: '应安但未安装车载设备'},
  {value: 5, label: '及时告知-疑似偷排'},
  {value: 6, label: '及时告知-非名录车'},
  {value: 7, label: '及时告知-非合同车'},
  {value: 8, label: '及时告知-非法消纳'},
  {value: 9, label: '及时告知-设备遮挡'},
  {value: 10, label: '及时告知-GPS故障'},
  {value: 11, label: '及时告知-私挖盗采'},
];

export const create = {
  inputs: [
    {
      label: '起点',
      children: [
        { placeholder: '起点部门', key: 'start_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', style: { width: 120 } },
        { placeholder: '起点', key: 'start_id', type: 'select', options: '/web/instance/getStartOrEndSupplement', extraData: { types: 1 }, valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'start_department_id', relyAs: 'id', required: false, style: { width: 240 } },
        { placeholder: '运输车', key: 'car_num', mode: 'tags', maxTagCount: 1, maxLength: 1,
        type: 'select', options: '/web/Car/getCarList', realTimeSearch: 'keywords', valueKey: 'car_num', labelKey: 'car_num', relies: ['start_department_id', 'start_id'], reliesAs: ['id', 'instance_id'], required: false, style: { width: 160 } },
        { placeholder: '司机', key: 'driver_id', type: 'select', options: '/web/Driver/getDriverByCarNum', valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'car_num', style: { width: 120 } },
      ],
    },
    { label: '开单时间', key: 'start_time', type: 'date', showTime: true, required: false },
    {
      label: '终点',
      children: [
        { placeholder: '终点部门', key: 'end_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: false, style: { width: 120 } },
        { placeholder: '终点', key: 'end_id', type: 'select', options: '/web/instance/getStartOrEndSupplement', extraData: { types: 2 }, valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'end_department_id', relyAs: 'id', required: false, style: { width: 240 } },
      ],
    },
    { label: '结算类型', placeholder: '结算类型', key: 'account_type', required: true, type: 'select', options: '/web/ticket/getAccountType', valueKey: 'id', labelKey: 'name', style: { width: 195 }},
    { label: '入库时间', key: 'end_time', type: 'date', showTime: true, required: false },
    { label: '收货人', placeholder: '收货人', key: 'acquirer_id', type: 'select', options: '/web/user/getUsers', valueKey: 'id', labelKey: 'name', showSearch: true, required: false, style: { width: 195 } },
    {
      label: '物料',
      children: [
        { placeholder: '物料', key: 'materiel_id', type: 'select', options: '/web/route/initMateriel', valueKey: 'id', labelKey: 'name', required: false, style: { width: 195 } },
        { placeholder: '方量', key: 'volumn', type: 'number', style: { width: 165 } },
      ],
    },
    { label: '补录原因', key: 'added_reason', type: 'textarea', autoSize: { minRows: 4, maxRows: 10 }, required: true },
    { label: '补充证据', key: 'medias', type: 'lists',
      initList: {created_time: '', type: '', url: ''},
      lists: [
        { type: 'date', key: 'created_time', label: '创建时间' },
        { type: 'select', key: 'type', label: '证据类型' },
        { type: 'upload', key: 'url', label: '证据图片' },
      ]
    }
  ],
};

export const update = {
  inputs: [
    {
      label: '起点',
      children: [
        { placeholder: '区域', key: 'start_area_id', type: 'select', options: '/web/Gps_Fault/area_list', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
        { placeholder: '起点部门', key: 'start_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, rely: 'start_area_id', relyAs: 'area_id', style: { width: 120 } },
        { placeholder: '起点', key: 'start_id', type: 'select', options: '/web/instance/getStartOrEndSupplement', extraData: { types: 1 }, valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['start_area_id', 'start_department_id'], reliesAs:['area_id', 'id'], required: true, style: { width: 240 } },
        { placeholder: '运输车', key: 'car_num', type: 'select', mode: 'tags', maxTagCount: 1, maxLength: 1, options: '/web/Car/getCarList', realTimeSearch: 'keywords', valueKey: 'car_num', labelKey: 'car_num', relies: ['start_department_id', 'start_id'], reliesAs: ['id', 'instance_id'], required: true, style: { width: 130 } },
        { placeholder: '司机', key: 'driver_id', type: 'select', options: '/web/Driver/getDriverByCarNum', valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'car_num', style: { width: 120 } },
      ],
    },
    { label: '开单时间', key: 'start_time', type: 'date', showTime: true, required: true },
    {
      label: '终点',
      children: [
        { placeholder: '区域', key: 'end_area_id', type: 'select', options: '/web/Gps_Fault/area_list', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
        { placeholder: '终点部门', key: 'end_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', rely: 'end_area_id', relyAs: 'area_id', style: { width: 120 } },
        { placeholder: '终点', key: 'end_id', type: 'select', options: '/web/instance/getStartOrEndSupplement', extraData: { types: 2 }, valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['end_area_id', 'end_department_id'], reliesAs:['area_id', 'id'], style: { width: 240 } },
      ],
    },
    { label: '结算类型', placeholder: '结算类型', key: 'account_type', type: 'select', required: true, options: '/web/ticket/getAccountType', valueKey: 'id', labelKey: 'name', style: { width: 195 }},
    { label: '入库时间', key: 'end_time', type: 'date', showTime: true, required: true },
    { label: '收货人', placeholder: '收货人', key: 'acquirer_id', type: 'select', options: '/web/user/getUsers', valueKey: 'id', labelKey: 'name', showSearch: true, style: { width: 195 } },
    {
      label: '物料',
      children: [
        { placeholder: '物料', key: 'materiel_id', type: 'select', options: '/web/route/initMateriel', valueKey: 'id', labelKey: 'name', required: false, style: { width: 195 } },
      ],
    },
    { label: '补充证据', key: 'medias', type: 'lists', modify: true,
      initList: {created_time: '', type: '', url: ''},
      lists: [
        { type: 'date', key: 'created_time', label: '创建时间' },
        { type: 'select', key: 'type', label: '证据类型' },
        { type: 'upload', key: 'url', label: '证据图片' },
      ]
    }
  ],
};


