import React from 'react';
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts';

export default function Line({data, type}) {
  if (!data) {
    return null;
  }
  const optionsColor = () => {
    let color = [];
    switch (type) {
      case 'emissions': color= ['#EF476F','#35A7FF']; break;
      case 'transport': color= ['#FE7F02']; break;
      case 'consume': color= ['#31C932','#FE7F02']; break;
      case 'ticket': color= ['#35A7FF']; break;
      case 'abnormal': color= ['#35A7FF']; break;
      default: break;
    }
    return color;
  };

  let legend = [];
  let dataArr = [];
  let labels = [];
  let selected = {};
  data.data.map(({name}, i) => {
    selected[name] = i < 1
  });
  data.data.map((item) => {
    legend.push(item.name);
    dataArr.push(item.data);
  });
  dataArr[0].map((item) => {
    labels.push(item.label);
  });

  let series = [];
  data.data.map((item) => {
    series.push({
      type: "line",
      smooth: true,
      data: item.data.map((val) => val.value),
      name: item.name,
      lineStyle: {
        width: 3,
      },
      label: { //在折线上显示相对应的数值
        normal: {
          show: true,
          position: 'top'
        }
      },
    })
  });

  const getOption = () => {
    return {
      color: optionsColor(),
      tooltip: {
        trigger: 'axis',
        formatter: function(params){
          let result = '日期：' + params[0].name + '<br/>';
          for(let i = 0;i < params.length;++i){
            if (type === 'transport') {
              if(params[i].seriesIndex == 0){
                result += '车辆数量：' + (params[i].value) + '<br/>';
              }else{
                result += '车辆数量：' + params[i].value + '<br/>';
              }
            } else if (type === 'ticket') {
              if(params[i].seriesIndex == 0){
                result += '电子联单数量：' + (params[i].value) + '<br/>';
              }else{
                result += '电子联单数量：' + params[i].value + '<br/>';
              }
            } else if (type === 'abnormal') {
              if(params[i].seriesIndex == 0){
                result += '数量：' + (params[i].value) + '<br/>';
              }else{
                result += '数量：' + (params[i].value) + '<br/>';
              }
            } else {
              if(params[i].seriesIndex == 0){
                result += params[i].seriesName + '：' + (params[i].value) + '<br/>';
              }else{
                result += params[i].seriesName + '：' + params[i].value + '<br/>';
              }
            }

          }
          return  result;
        }
      },
      legend: {
        data: legend,
        selected: selected
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '50',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: labels,
        axisLine: {
          lineStyle: {
            color: "#7E7E7E"
          }
        },
      },
      yAxis: [
        {
          type: 'value',
          axisLine: {
            show: false,
            lineStyle: {
              width: 0,
              color: "#B1B1B1"
            }
          },
        },
      ],
      series: series
    }
  };

  return (
    <ReactEcharts
      option={getOption()}
      style={{height: '100%', width: '95%'}}
    />
  );
}
