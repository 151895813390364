// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._31qejYYHCHt3TBl66FbXyC {\n  position: absolute;\n  bottom: 60px;\n  left: 350px;\n  padding: 6px 12px 7px;\n  background-color: #FF940E;\n  color: #fff;\n  border-radius: 4px;\n  cursor: pointer;\n}", ""]);
// Exports
exports.locals = {
	"container": "_31qejYYHCHt3TBl66FbXyC"
};
module.exports = exports;
