import React, {useState, useEffect} from 'react';
import {Button, Modal, Table} from 'antd';
import request from 'utils/request';

const cheatColumns = [
  { title: '电子联单总趟次', dataIndex: 'total_ticket_count',
    render: (value, row, index) => {
      const obj = {
        children: value,
        props: {},
      };
      obj.props.rowSpan = row.total_num;
      return obj;
    }},
  { title: 'GPS天网总趟次', dataIndex: 'total_gps_count',
    render: (value, row, index) => {
      const obj = {
        children: value,
        props: {},
      };
      obj.props.rowSpan = row.total_num;
      return obj;
    } },
  { title: '偷排趟次', dataIndex: 'total',
    render: (value, row, index) => {
      const obj = {
        children: value,
        props: {},
      };
      obj.props.rowSpan = row.total_num;
      return obj;
    } },
  { title: '参与运企名称', dataIndex: 'transport_name' },
  { title: '电子联单趟次', dataIndex: 'ticket_count' },
  { title: 'GPS天网趟次', dataIndex: 'gps_count' },
  { title: '偷排趟次', dataIndex: 'cheat_count' },
];
const stealColumns = [
  { title: '参与运企名称', dataIndex: 'transport_name', render: (value, row, index) => {
      const obj = {
        children: value,
        props: {},
      };
      obj.props.rowSpan = row.transport_num;
      return obj;
    } },
  { title: '所属区县', dataIndex: 'area_name', render: (value, row, index) => {
      const obj = {
        children: value,
        props: {},
      };
      obj.props.rowSpan = row.transport_num;
      return obj;
    }},
  { title: '车牌号码', dataIndex: 'car_num'},
  { title: '趟次', dataIndex: 'count'},
  { title: '方量', dataIndex: 'volumn'},


];
function getDataSource(id, type){
  const [data, setData] = useState([]);
  const url = type === 1 ? '/web/count_alarm/cheat_ticket_count' : '/web/count_alarm/steal_sand_count';
  useEffect(() => {
    request(url, {id})
      .then(d => d.data.data)
      .then(setData)
  }, [id]);
  return data
}

export default function SuspectedStealing({location, history, ...props}){
  const {state: {alarmId, type}} = location;
  const dataSource = getDataSource(alarmId, type);
  const back = () => history.goBack();
  return (
    <Modal
      title={type === 1 ? '疑似偷排' : '私挖盗采'}
      visible={true}
      width={1200}
      onCancel={back}
      footer={[
        <Button key="back" onClick={back}>
          关闭
        </Button>,
      ]}
      bodyStyle = {{
        maxHeight: '70vh',
        overflowY: 'auto', position: 'relative'}
      }
    >
      <Table columns={type === 1 ? cheatColumns : type === 2 ? stealColumns : []} dataSource={dataSource} bordered pagination={false} />
    </Modal>
  )
}
