import React, { useState, useEffect } from 'react';
import moment from 'moment';
import debug from 'utils/debug';
import { reasonArr, punishArr } from '../share'
import store from 'stores/user';
import TablePage from 'ui/TablePage';
import DataSource from 'ui/DataSource';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';
import ImagePreviewButtonRender from './operationJS/ImagePreviewButtonRender';
import { Button, message } from 'antd';
import { previewable } from 'components/Previewer/index';
import Details from './operationJS/Details'
import Blacklist from './operationJS/Blacklist'
import ReportList from './operationJS/ReportList'
import Detele from './operationJS/Detele'
import GreyList from './operationJS/GreyList'
import Track from './operationJS/Track'
import styles from './index.scss'
import LinkButton from 'ui/TableCell/LinkButton';

// 111  = 1
export const dataSource = new DataSource('web/gb_list/getCarAlarmLists', { role_type: 1 });
const penalty_time = [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().add(0, 'days').format('YYYY-MM-DD')];
function List({ preview }) {
  debug.render('List');
  const roleId = store.getState().roles && store.getState().roles.id;
  const render = ImagePreviewButtonRender(preview);
  const [state, setState] = useState({
    selectedRowKeys: [],
    flage: true,
  })
  const { flage, selectedRowKeys } = state;
  const [selectedRows, setSelectedRows] = useState([]) //传给操作按钮里的数据 
  const [leftSelectedRow, setLeftSelectedRow] = useState([]) //全选时【单个/多个的数据】
  const [detailsInfo, setDetailsInfo] = useState(false)
  const [greyListInfo, setGreyListInfo] = useState(false)
  const [blacklistInfo, setBlacklistInfo] = useState(false)
  const [reportListInfo, setReportListInfo] = useState(false)
  const [trackInfo, setTrackInfo] = useState(false)
  const [greyListKey, setGreyListKey] = useState(1)

  const rowSelection = {
    selectedRowKeys,
    onChange,
    onSelect,
    onSelectAll,
    getCheckboxProps: (e) => ({
      disabled: (e.is_deal || e.is_report) == 1
    })

  };
  //下拉选择的options
  const isRecordArr = [
    { value: 1, label: '是' },
    { value: 2, label: '否' },
  ];

  const columns = [
    serial(true),
    { title: '车牌', dataIndex: 'car_number', align: 'center' },
    { title: '是否温江名录', dataIndex: 'is_car_directory', align: 'center', render: v => v == 1 ? '是' : '否' },
    { title: '运企名称', dataIndex: 'transport_name', align: 'center' },
    { title: '所属区域', dataIndex: 'area_name', align: 'center' },
    { title: '关联驾驶员', dataIndex: 'driver_name', align: 'center' },
    { title: '事由', dataIndex: 'reason', align: 'center', render: v => reasonArr[v].label },
    { title: '处罚', dataIndex: 'deal_result', align: 'center', render: v => <span style={{ color: 'red' }}>{v == 1 ? '停运' : '其他'}</span> },
    { title: '告警时间', dataIndex: 'alarm_time', align: 'center' },
    { title: '告警地点', dataIndex: 'address', align: 'center', render: v => v ? v : '无' },
    { title: '抓拍图片', dataIndex: 'images', align: 'center', render },
    { title: '轨迹', dataIndex: 'is_route', align: 'center', render: (v, e) => v == 1 ? <Button type="link" onClick={() => { setSelectedRows([e]); setTrackInfo(true) }}>查看</Button> : '无' },
    actions({
      isFixed: true,
      width: roleId === 111 ? 200 : 100,
      align: 'center',
      render: (e, { is_deal, is_report, type, id }) => {
        return (
          <>
            {/* <LinkButton to={`./details/${id}`} children='详情' className={styles.operation} type='primary'/> */}
            {
              roleId === 111 ?
                <>
                  {
                    is_deal === 1 ?

                      <Button id={e.id} onClick={(i) => { setDetailsInfo(true); if (e.id == i.target.id) { setSelectedRows([e]) } }} className={styles.operation} type='primary'>详情</Button>
                      : <>{is_report == 1 ?
                        <>
                          {type == 1 ? <Button id={e.id} onClick={(i) => { setGreyListInfo(true); if (e.id == i.target.id) { setSelectedRows([e]) }; setGreyListKey(Date.now()) }} className={styles.operationGrey}>继续灰名单</Button> : null}
                          {type == 1 ? <Button id={e.id} onClick={(i) => { setBlacklistInfo(true); if (e.id == i.target.id) { setSelectedRows([e]); setGreyListKey(Date.now()) } }} className={styles.operationBlack}>转黑名单</Button> : null}
                        </> : null}
                        {(is_report == 0) ?
                          <>
                            <Button id={e.id} onClick={(i) => { setReportListInfo(true); if (e.id == i.target.id) { setSelectedRows([e]) } }} className={styles.operationReport}>上报</Button>
                            < Button id={e.id} onClick={(i) => { if (e.id == i.target.id) { Detele(e) } }} className={styles.operationDetele} type='danger' > 删除</Button >
                          </> : null}
                      </>}
                </>
                :
                <Button id={e.id} onClick={(i) => { setDetailsInfo(true); if (e.id == i.target.id) { setSelectedRows([e]) } }} className={styles.operation} type='primary'>详情</Button>
            }
          </>
        )
      },
    }),
  ];
  const querys = [
    { title: '搜索车牌、驾驶员', key: 'keyword', style: { width: 200 } },
    { title: '运企', key: 'transport_name', type: 'select', options: 'web/blacklist/getTransportLists', valueKey: 'id', labelKey: 'name', style: { width: 200 }, },
    { title: '事由', key: 'reason', type: 'select', options: reasonArr, valueKey: 'value', labelKey: 'label', style: { width: 200 }, },
    { title: '是否温江名录', key: 'is_car_directory', type: 'select', options: isRecordArr, valueKey: 'value', labelKey: 'label', style: { width: 150 }, },
    { title: '处罚', key: 'deal_result', type: 'select', options: punishArr, valueKey: 'value', labelKey: 'label', style: { width: 174 }, },
    { title: ['处罚', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
  ];
  const buttons = [
    { title: '导出', download: '/web/gb_list/exportCarAlarmLists', extraParams: { role_type: 1 } },
  ];

  function onChange(selectedRowKeys) { setState({ selectedRowKeys }); };
  function onSelect(record, selected, selectedRows, nativeEvent) { setSelectedRows(selectedRows); setLeftSelectedRow(selectedRows) }
  function onSelectAll(selected, selectedRows, changeRows) { setLeftSelectedRow(selectedRows) }
  function DetailsInfo({ visible }) { if (!visible) { setDetailsInfo(false) } }
  function GreyListInfo({ visible }) { if (!visible) { setGreyListInfo(false) } }
  function BlacklistInfo({ visible }) { if (!visible) { setBlacklistInfo(false) } }
  function ReportListInfo({ visible }) { if (!visible) { setReportListInfo(false) } }
  function TrackInfo({ visible }) { if (!visible) { setTrackInfo(false) } }
  function rightTop() { setReportListInfo(true); setSelectedRows(leftSelectedRow) }

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setState({ ...state, flage: false });
    } else {
      setState({ ...state, flage: true });
    }
  }, [selectedRowKeys]);
  return (
    <div className={styles.list}>
      <Details props={{ detailsInfo, DetailsInfo, selectedRows }} />
      <Blacklist key={greyListKey + 1} props={{ blacklistInfo, BlacklistInfo, selectedRows }} />
      <GreyList key={greyListKey} props={{ greyListInfo, GreyListInfo, selectedRows }} />
      <ReportList props={{ reportListInfo, ReportListInfo, selectedRows, dataSource }} />
      <Track props={{ trackInfo, TrackInfo, selectedRows }} />
      <div style={{ padding: '0 32px' }}>
        {roleId == 111 ? <Button onClick={rightTop} className={styles.operationReports} disabled={flage}>上报</Button> : null}
      </div>
      <TablePage
        rowSelection={roleId == 111 ? rowSelection : null}
        dataSource={dataSource}
        buttons={buttons}
        querys={querys}
        columns={columns}
        defaultLimit={10}
        defaultQuery={{ times: penalty_time }}
      />
    </div >
  );
}

export default previewable(List);
