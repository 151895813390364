// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1JUhux_XRhWfw8QTMb-R23 * {\n  box-sizing: border-box;\n}\n._1JUhux_XRhWfw8QTMb-R23 ._3cSTeCAoiuQNWRdELw5DGO > tr > th, ._1JUhux_XRhWfw8QTMb-R23 ._1XxuZifOqBKz5A1jyTTrpk > tr > td {\n  width: 200px;\n  box-sizing: border-box;\n}\n._1JUhux_XRhWfw8QTMb-R23 ._19KpJvFPth-dZVwUR1oABX {\n  width: 160px;\n}\n._1JUhux_XRhWfw8QTMb-R23 .mh6wZiY5Qvqff1ysRFxAw ._19KpJvFPth-dZVwUR1oABX {\n  width: 195px;\n}", ""]);
// Exports
exports.locals = {
	"failure": "_1JUhux_XRhWfw8QTMb-R23",
	"ant-table-thead": "_3cSTeCAoiuQNWRdELw5DGO",
	"ant-table-tbody": "_1XxuZifOqBKz5A1jyTTrpk",
	"ant-input": "_19KpJvFPth-dZVwUR1oABX",
	"ant-calendar-picker": "mh6wZiY5Qvqff1ysRFxAw"
};
module.exports = exports;
