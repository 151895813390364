// https://ant.design/components/input-cn/#Input
import React from 'react';
import { InputNumber } from 'antd';

export default function Number({ value, onChange, ...props }) {
  return (
    <InputNumber
      {...props}
      value={value}
      onChange={e => onChange(e)}
    />
  );
}
