import React, {useState, useEffect, useCallback} from 'react';
import {Modal, Button} from 'antd';
import request from 'utils/request';
import echarts from 'echarts';
// import 'echarts/extension/bmap/bmap';

const logChartDispose = () => console.log('Echarts实例已卸载') || true;
function useEcharts(){
  const [chart, setChart] = useState(null);
  const refGetter = useCallback(element => !!element && setChart(echarts.init(element)), []); // 创建echart实例
  const isChartUsable = useCallback(() => (!!chart && !chart.isDisposed()), [chart]); // 判断echart实例是否可用
  useEffect(() => () => (isChartUsable() && logChartDispose() && chart.dispose()), [chart]); // 卸载echart实例
  return [chart, isChartUsable, refGetter];
}

const coordObjectsToArrays = objects => objects.map(({ lng, lat }) => [lng, lat]);

function useChartMap(chart, isChartUsable, series, center){
  useEffect(() => {
    if(!isChartUsable()) return;
    chart.clear();
    const option = {
      tooltip : {
        trigger: 'item'
      },
      animation: false,
      bmap: {
        center: center || [103.836472, 30.719426],
        zoom: 13,
        roam: true,
      },
      series,
    };
    chart.setOption(option)
  }, [chart, series])
}
const getSeriesPointer =(data, tooltip, color) => {
  return {
    type: 'scatter',
    coordinateSystem: 'bmap',
    labelName: 'label',
    // symbol: 'image:///images/center/green1.png',
    symbolSize: 15,
    zlevel: 2,
    name: 'plansStopPoints',
    tooltip: {
      formatter: function(a) {
        console.log(a)
        return tooltip + ': ' + a.data.location
      }
    },
    label: {

    },
    itemStyle: {
      normal: {
        color: color,
        opacity: 1,
      },
    },
    data: data
  }
};

const getSeriesLines = (data, name='lines', color='#ff0000') => {
  return {
    type: 'lines',
    zlevel: 8,
    name: name,
    coordinateSystem: 'bmap',
    polyline: true,
    silent: true,
    lineStyle: {
      normal: {
        color,
        opacity: 1,
        width: 4,
      },
    },
    progressiveThreshold: 500,
    progressive: 200,
    data: [{
      coords: data.length > 1 ? coordObjectsToArrays(data || []) : [],
    }]
  }
};

export default function MapDetail({history, match}){
  const [chart, isChartUsable, refGetter] = useEcharts();
  const [mapData, setMapData] = useState();
  const [series, setSeries] = useState([]);
  const back = () => history.goBack();
  const {params: { id, car_num }} = match;

  useEffect(() => {
    request('/web/Gps_Fault/route_detail', {id})
      .then((res) => {
        setMapData(res.data.data)
      })
  }, [id]);

  useEffect(() => {
    if(!mapData) return;
    const offLine = mapData.offLine.map(({lng, lat, location}) => ({location, value: [lng, lat]}));
    const onLine = mapData.onLine.map(({lng, lat, location}) => ({location, value: [lng, lat]}));
    let newSeries = [getSeriesPointer(offLine, '离线', '#FF3B30'), getSeriesPointer(onLine, '上线', '#4BD863'),];
    if(mapData.pointers.length > 1){
      newSeries.push(getSeriesLines(mapData.pointers, 'pointersLines', '#1491fb'))
    }
    setSeries(newSeries)
  }, [mapData]);

  useChartMap(chart,isChartUsable, series);

  return (
    <Modal
      visible={true}
      onCancel={back}
      title={`${car_num} -- 异常路段`}
      width={1200}
      centered={true}
      bodyStyle={{padding: 0}}
      footer={<Button onClick={back}>取消</Button>}
    >
      <div style={{width: '100%', height: 700}} ref={refGetter} />
    </Modal>
  )
}
