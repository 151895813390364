import React, {useState} from 'react';
import {Modal, Icon} from 'antd';
import AlarmBroad from './AlarmBroad';
import ShowDetail from './ShowDetail';
import Inform from './Inform';
import styles from './index.scss'

export default function AlarmDetail({history, location, path, ...props}){
  const back = () => history.goBack();
  console.log(location)
  const [alarmId, setAlarmId] = useState(location.state.id);
  const [status, setStatus] = useState(location.state.status);
  const [notice_count, setNoticeCount] = useState(0);
  const [dispatch_task_id, setDispatchTaskId] = useState(location.state.dispatch_task_id);
  const [count, setCount] = useState(0);
  return <Modal
    visible={true}
    wrapClassName={styles.warpAlarm}
    destroyOnClose={true}
    onCancel={back}
    title={false}
    width={'100%'}
    footer={null}
    closable={false}
  >
    <div className={styles.app}>
      <AlarmBroad param={location.state.params} count={count} id={alarmId} style={{width: 480}} onChangeAlarmId={ (id, status, notice_count, dispatch_task_id) => {
        setAlarmId(id); setStatus(status); setDispatchTaskId(dispatch_task_id);
        setNoticeCount(notice_count);
        setCount(count + 1);

      }} />
      {
        alarmId ? <Inform id={alarmId} status={status} notice_count={notice_count} dispatch_task_id={dispatch_task_id} onChangeAlarmId={() => {setAlarmId();  history.replace(path, {params: location.state.params});}} /> : null
      }
      {
        alarmId ? <ShowDetail id={alarmId} state={location.state} /> : null
      }
    </div>
    <div className={styles.closeItem} onClick={back}><Icon type="close" /></div>
  </Modal>
}
