import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Select, Input, InputNumber, message, Button } from 'antd';
import { dataSource } from './List';
import request from 'utils/request';
import styles from './../Create.scss';

const { Option } = Select;

function getTransport() {
  const [arr, setArr] = useState([]);
  useEffect(() => {
    request('/river/river_project/getRiverProjectOption')
      .then(body => body.data.data)
      .then(setArr)
  }, []);
  return arr;
}
function getCars() {
  const [arr, setArr] = useState([]);
  useEffect(() => {
    request('/river/river_base/getRiverTransportCarOption',)
      .then(body => body.data.data)
      .then(setArr)
  }, []);
  return arr;
}

export default function Create({ history, match }) {
  const back = () => history.goBack();
  const projectArr = getTransport();
  const carArr = getCars();
  const [projectId, setProjectId] = useState();
  const [carNumbers, setCarNumbers] = useState(1);
  const [carMsg, setCarMsg] = useState([{ plate_num: '', car_id: '', volumn: '' }]);
  const onFinish = () => {
    request('/river/river_project/createRiverProjectCarInfo', {project_id: projectId, cars: carMsg})
      .then(body => body.data)
      .then(data => {
        if(data.code === 200) {
          message.success(data.msg);
          dataSource.refresh();
          back();
        }else {
          message.warn(data.msg)
        }
      });
  };
  const ModifyMsg = (msg, serial, type) => {
    switch (type) {
      case 1: setCarMsg((s) => s.map((v, i) => (
        {
          ...v,
          plate_num: serial === i ? msg : v.plate_num
        }
      ))); break;
      case 2: setCarMsg((s) => s.map((v, i) => (
        {
          ...v,
          car_id: serial === i ? msg : v.car_id
        }
      ))); break;
      case 3: setCarMsg((s) => s.map((v, i) => (
        {
          ...v,
          volumn: serial === i ? msg : v.volumn,
        }
      ))); break
    }
  };

  const deleteCar = useCallback((e) => {
    const deleteId = Number(e.target.dataset.value);
    setCarNumbers((v) => (v - 1));
    setCarMsg((s) => s.map((v, i) => i === deleteId ? false : {...v}).filter((v) => v))
  }, []);

  const [dis, setDis] = useState(false);
  useEffect(() => {
    carMsg.map(({ plate_num, car_id, volumn }) => {
      if(plate_num !== '' && car_id !== '' && volumn !== '') {setDis(true)}
      else setDis(false)
    })
  }, [JSON.stringify(carMsg)]);
  return (
    <Modal
      title={"新增项目车辆"}
      visible={true}
      width={650}
      onCancel={back}
      onOk={onFinish}
      okButtonProps={{ disabled: !projectId || !dis }}
    >
      <div className={styles.title}>
        <Select placeholder={'请选择项目'} value={projectId} style={{ width: 250 }} onChange={(e) => setProjectId(e)} >
          {
            projectArr.map(({ id, name }, i) => (
              <Option key={i} value={id}>{name}</Option>
            ))
          }
        </Select>
        <div className={styles.changeNumber}>
          <span>车辆数量：{carNumbers}</span><span onClick={() => {setCarNumbers((v) => (v + 1)); setCarMsg((s) => { s.push({ plate_num: '', car_id: '', volumn: '' }); return s } )}}>+</span>
        </div>
      </div>
      <div className={styles.content}>
        <div>
          <span>序号</span>
          <span>车辆号牌</span>
          <span>自编号</span>
          <span>核定方量</span>
          <span />
        </div>
        {
          carMsg.map(({ plate_num, car_id, volumn }, i) => (
            <div key={i}>
              <span><Input value={i + 1} readOnly /></span>
              <span>
                <Select placeholder="请选择车牌号" value={plate_num} style={{ width: 110 }} onChange={(e) => ModifyMsg(e, i, 1)}>
                  {
                    carArr.map(({ id, plate_num }, i) => (
                      <Option key={i} value={plate_num}>{plate_num}</Option>
                    ))
                  }
                </Select>
                {/*<Input value={plate_num} onChange={(e) => ModifyMsg(e.target.value, i, 1)} />*/}
              </span>
              <span><Input value={car_id} onChange={(e) => ModifyMsg(e.target.value, i, 2)} /></span>
              <span><InputNumber value={volumn} onChange={(e) => ModifyMsg(e, i, 3)} /></span>
              <Button data-value={i} onClick={deleteCar} type="danger" disabled={carNumbers === 1}>删除</Button>
              {/*<span data-value={i} onClick={deleteCar}>删除</span>*/}
            </div>
          ))
        }
      </div>
    </Modal>
  );
}
