import React from 'react';
import { Route } from 'react-router-dom';
import TransportRadar from './TransportRadar';
import WarnList from './WarnList';
import DisPatch from './Dispatch';
import SuspiciousPoints from './SuspiciousPoints';
import HistoryList from './HistoryList';
import HistoryDetail from './HistoryDetail';
import SatelliteMap from './SatelliteMap';
import DownloadReport from './DownloadReport';
import AlarmDetail from './Alarm/AlarmDetail';
import SuspectedStealing from './Alarm/SuspectedStealing';
import HistoryAlarm from './Alarm/HistoryAlarm';
import HistoryAlarmDetail from './Alarm/HistoryAlarmDetail';

export default ({ path }) => (
  <>
    <Route path={path} component={TransportRadar} />
    <Route path={path + 'warn-list'} component={WarnList} />
    <Route path={path + ':id/:name/disPatch'} component={DisPatch} />
    {/*<Route path={path + ':id/suspicious-points/'} component={SuspiciousPoints} />*/}
    <Route path={path + ':id/:point_type/suspicious-points/'} component={SuspiciousPoints} />
    <Route path={path + ':id/:point_type/suspicious-points/history-list/'} component={HistoryList} />
    <Route path={path + ':id/:point_type/suspicious-points/history-list/:history_id/history-detail'} component={HistoryDetail} />
    <Route path={path + ':id/:point_type/suspicious-points/satellite-map'} component={SatelliteMap} />
    <Route path={path + 'satellite-map'} component={SatelliteMap} />
    <Route path={path + ':type/download-report'} component={DownloadReport} />
    <Route path={path + 'alarm-detail/'} component={AlarmDetail} />
    <Route path={path + 'alarm-detail/suspected-stealing'} component={SuspectedStealing} />
    <Route path={path + 'alarm-detail/history-alarm'} component={HistoryAlarm} />
    <Route path={path + 'alarm-detail/history-alarm/history-detail'} component={HistoryAlarmDetail} />
  </>
);

