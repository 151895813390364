import React, {useState, useEffect} from 'react';
import { InputNumber } from 'antd';

import request from 'utils/request';
export default function NumberByOptions({ valueKey, value: originalValue, optionValue = false, options, onChange, ...props }) {
  const [value, setValue] = useState(originalValue);
  useEffect(() => {
    setValue(originalValue);
    onOptionChange(originalValue)
  }, [originalValue])
  useEffect(() => {
    if(typeof options === 'object'){
      request(options.url, options.data)
        .then(body => body.data.data)
        .then(data => {
          setValue(data[valueKey]);
          // onChange(optionValue ? options.get(value) : value, data);
          onOptionChange(data[valueKey], data)
        })
    }
  }, [JSON.stringify(options)]);
  function onOptionChange(value, data) {
    onChange(optionValue ? options.get(value) : value, data);
  }
  
  return (
    <InputNumber
      {...props}
      value={value ? value : originalValue}
      onChange={onOptionChange}
    />
  );
}
