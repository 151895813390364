import React from 'react';
import {message} from 'antd';
import ModalFormPage from 'ui/ModalFormPage';
import debug from 'utils/debug';

const inputs = [
  { label: '部门', key: 'department_id', type: 'select', options: '/web/Dispatchs/department', valueKey: 'id', labelKey: 'name', required: true},
  { label: '职位', key: 'position_id', type: 'select', options: '/web/Dispatchs/position', valueKey: 'id', labelKey: 'name', required: true, rely: 'department_id', relyAs: 'department_id'},
  { label: '人员', key: 'executor', type: 'select', options: '/web/Dispatchs/user', valueKey: 'id', labelKey: 'name', required: true, rely: 'position_id', relyAs: 'position_id'},
  { label: '任务描述', key: 'description', type: 'textarea', required: true },
  ];

export default function Update({ history, match }) {
  debug.render('Update');

  const back = () => history.goBack();
  const onFinish = () => {
    message.success('派工成功');
    back();
  };

  const id = match.params.id;
  const name = match.params.name;

  return (
    <ModalFormPage
      submit={['/web/Dispatchs/release', { black_point_id: id }]}
      title={`${name}派工`}
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
      modalProps={{
        width: 800,
      }}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}
    />
  );
}
