import React, {useState, useEffect} from 'react';

import { previewable } from 'components/Previewer';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';

import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';

import {Modal} from "antd";
import moment from "moment";
export const dataSource = new DataSource('/web/radar/alarmList', {area_id: 13});
const defaultDates = [moment().subtract(0, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')];

const columns = [
  serial(true),
  { title: '告警类型', dataIndex: 'alarm_type_text', width: width(10) },
  { title: '车牌号', dataIndex: 'car_num', width: width(6) },
  { title: '所属运企', dataIndex: 'transport_name'},
  { title: '司机', dataIndex: 'name', width: width(7) },
  { title: '联系电话', dataIndex: 'phone', width: width(10) },
  { title: '告警时间', dataIndex: 'created_time', width: width(14) },
  ];
const radioSelect = 'web/radar/alarmCount'
export default function WarnList({history, location}){
  const querys = [
    { title: '请输入车牌号搜索', key: 'car_num' },
    { title: '', key: 'alarm_type', type: 'radioSelect', options: 'web/radar/alarmCount', style: { width: 150 }},
    { title: ['告警', '时间'], key: 'time', type: 'date-range', format: 'YYYY-MM-DD HH:mm:ss', style: { width: 360 }, showTime: true },
  ];
  return (
    <Modal
    title={'查看车辆'}
    onCancel={() => history.goBack()}
    visible={true}
    centered={true}
    bodyStyle={{maxHeight: '80vh', overflow: 'auto'}}
    footer={null}
    width={1600}
    destroyOnClose={true}
    >
      {
        <TablePage
          dataSource={dataSource}
          querys={querys}
          columns={columns}
          radioSelect={radioSelect}
          defaultQuery={{alarm_type: '', time: [] }}
          defaultLimit={10}
        />
      }
    </Modal>
  )
}

