import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import debug from 'utils/debug';
import { create } from '../share';
import { dataSource } from './List';

// const inputs = [
//   {
//     label: '起点',
//     children: [
//       { placeholder: '起点部门', key: 'start_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', style: { width: 120 } },
//       { placeholder: '起点', key: 'start_id', type: 'select', options: '/web/instance/getBuilding', extraData: { types: 1 }, valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'start_department_id', relyAs: 'id', required: true, style: { width: 240 } },
//       { placeholder: '运输车', key: 'car_num', type: 'select', options: '/web/Car/getCarList', realTimeSearch: 'keywords', valueKey: 'car_num', labelKey: 'car_num', relies: ['start_department_id', 'start_id'], reliesAs: ['id', 'instance_id'], required: true, style: { width: 120 } },
//       { placeholder: '司机', key: 'driver_id', type: 'select', options: '/web/Driver/getDriverByCarNum', valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'car_num', required: true, style: { width: 120 } },
//     ],
//   },
//   { label: '开单时间', key: 'start_time', type: 'date', showTime: true, required: true },
//   {
//     label: '终点',
//     children: [
//       { placeholder: '终点部门', key: 'end_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
//       { placeholder: '终点', key: 'end_id', type: 'select', options: '/web/instance/getBuilding', extraData: { types: 2 }, valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'end_department_id', relyAs: 'id', required: true, style: { width: 240 } },
//     ],
//   },
//   { label: '入库时间', key: 'end_time', type: 'date', showTime: true, required: true },
//   { label: '收货人', key: 'acquirer_id', type: 'select', options: '/web/user/getUsers', valueKey: 'id', labelKey: 'name', showSearch: true, required: true, style: { width: 195 } },
//   {
//     label: '物料',
//     children: [
//       { placeholder: '物料', key: 'materiel_id', type: 'select', options: '/web/route/initMateriel', valueKey: 'id', labelKey: 'name', required: true, style: { width: 195 } },
//       { placeholder: '方量', key: 'volumn', type: 'number', style: { width: 165 } },
//     ],
//   },
//   { label: '补录原因', key: 'added_reason', type: 'textarea', autosize: { minRows: 4, maxRows: 10 }, required: true },
// ];

export default function Create({ history }) {
  debug.render('Create');

  const back = () => history.goBack();
  const onFinish = () => {
    message.success('补录成功!');
    dataSource.refresh();
    back();
  };

  return (
    <ModalFormPage
      submit={['/web/ticket/add', { transport_id: '0', reason: '除收货人界面补录'  }]}
      title="补录电子联单"
      inputs={create.inputs}
      onCancel={back}
      onFinish={onFinish}
      modalProps={{
        width: 1000,
      }}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}
    />
  );
}
