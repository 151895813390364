import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { previewable }  from 'components/Previewer';
import GetLngLat from './GetLngLat';
// import styles from './Sandwich.scss';

const InputGroup = Input.Group;

export default function InputPointer ({value, onChange, ...props}) {
  const [flag, setFlag] = useState(false);
  const [point, setPoint] = useState(value);
  useEffect(() => {
    setPoint(value)
  }, [value])
  return (
    <>
      <InputGroup compact style={{display: 'flex'}}>
        <Input value={point} {...props} onFocus={() => setFlag(true) } onChange={e => onChange(e.target.value)} />
        {/*<Button type="primary" size="default" onClick={() => {window.open('http://api.map.baidu.com/lbsapi/getpoint/index.html')}}>拾取</Button>*/}
      </InputGroup>
      { flag && <GetLngLat onClose={() => setFlag(false)} onChange={(e) => {setPoint(e); onChange(e); setFlag(false)}} /> }
    </>
)};
