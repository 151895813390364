import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';

import { dataSource } from './List';

const inputs = [
    { label: '审核状态', key: 'pass', type: 'radio', options: [{label: '已通过', value: 1}, {label: '未通过', value: 0}], required: true },
];

export default function Evidence({ history, match }) {
    const id = match.params.id;
    const back = () => history.goBack();
    const onFinish = () => {
        dataSource.refresh();
        back();
    };

    return (
        <ModalFormPage
            submit={['/web/ticket/checkAccountSource', { id }]}
            title="联单审核状态"
            inputs={inputs}
            onCancel={back}
            onFinish={onFinish}
            modalProps={{
                width: 500,
            }}
            formProps={{
                labelCol: { xs: { span: 4 } },
                wrapperCol: { xs: { span: 20 } },
            }}
        />
    );
}




/*
import React from 'react';
import request from 'utils/request';
import {Button, Modal, Carousel} from "antd";
import styles from './Evidence.scss';

export default class Detail extends React.PureComponent {
    state = {
        timestamp: (new Date()).getTime(),
        detailInfo: {},
        linesData: [{
            coords: []
        }],
        planLinesData: [{
            coords: []
        }],
        imgFlag: false,
        previewImage: [],

        videoFlag: false,
        video: '',
    };
    back = () => this.props.history.goBack();
    componentDidMount () {
        request('/web/homemap/anotherTicketDetail', {
            id: this.props.match.params.id,
        }).then(body => body.data.data).then((data) => {
            this.setState({
                visible: false,
                detailInfo: data.detailInfo,
                planRoute: data.planRoute,
                realRoute: data.realRoute,
                parks: data.parks,
                timestamp: (new Date()).getTime(),
            });
        });
    }

    render () {
        const {detailInfo, timestamp, imgFlag, previewImage, videoFlag, video} = this.state;
        return (
            <div>
                <Modal
                    visible
                    width={900}
                    bodyStyle={{height: 500, overflow: 'auto'}}
                    title="证据详情"
                    onCancel={this.back}
                    onOk={this.back}
                    footer={[<Button key="back" onClick={this.back}>关闭</Button>,]}>

                    <div className={styles.label}>
                        <span>工地进场证据</span>
                        <div className={styles.item}>
                            <span style={{width: '60%'}}>工地进场文本证据</span>
                            <div className={styles.itemSpan}>
                                {
                                    detailInfo.open_photo != '' ? <span style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.setState({imgFlag: true, previewImage: detailInfo.open_photo.split(',')}) } }>查看图片</span> : <span>暂无图片</span>
                                }
                                {
                                    detailInfo.open_photo != '' ? <span style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.setState({videoFlag: true, video: 'C:\\Users\\ASUS\\Desktop\\小天才2019新年广告_高清.kux'}) } }>查看视频</span> : <span>暂无视频</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.label}>
                        <span>工地出场证据</span>
                        <div className={styles.item}>
                            <span style={{width: '60%'}}>工地进场文本证据</span>
                            <div className={styles.itemSpan}>
                                {
                                    detailInfo.open_photo != '' ? <span style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.setState({imgFlag: true, previewImage: detailInfo.open_photo.split(',')}) } }>查看图片</span> : <span>暂无图片</span>
                                }
                                {
                                    detailInfo.open_photo != '' ? <span style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.setState({imgFlag: true, previewImage: detailInfo.open_photo.split(',')}) } }>查看视频</span> : <span>暂无视频</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.label}>
                        <span>装车证据</span>
                        <div className={styles.item}>
                            <span style={{width: '60%'}}>工地进场文本证据</span>
                            <div className={styles.itemSpan}>
                                {
                                    detailInfo.open_photo != '' ? <span style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.setState({imgFlag: true, previewImage: detailInfo.open_photo.split(',')}) } }>查看图片</span> : <span>暂无图片</span>
                                }
                                {
                                    detailInfo.open_photo != '' ? <span style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.setState({imgFlag: true, previewImage: detailInfo.open_photo.split(',')}) } }>查看视频</span> : <span>暂无视频</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.label}>
                        <span>GPS轨迹证据</span>
                        <div className={styles.item}>
                            <span style={{width: '60%'}}>工地进场文本证据</span>
                            <div className={styles.itemSpan}>
                                {
                                    detailInfo.open_photo != '' ? <span style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.setState({imgFlag: true, previewImage: detailInfo.open_photo.split(',')}) } }>查看图片</span> : <span>暂无图片</span>
                                }
                                {
                                    detailInfo.open_photo != '' ? <span style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.setState({imgFlag: true, previewImage: detailInfo.open_photo.split(',')}) } }>查看视频</span> : <span>暂无视频</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.label}>
                        <span>砂石场进场证据</span>
                        <div className={styles.item}>
                            <span style={{width: '60%'}}>工地进场文本证据</span>
                            <div className={styles.itemSpan}>
                                {
                                    detailInfo.open_photo != '' ? <span style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.setState({imgFlag: true, previewImage: detailInfo.open_photo.split(',')}) } }>查看图片</span> : <span>暂无图片</span>
                                }
                                {
                                    detailInfo.open_photo != '' ? <span style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.setState({imgFlag: true, previewImage: detailInfo.open_photo.split(',')}) } }>查看视频</span> : <span>暂无视频</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.label}>
                        <span>砂石场出场证据</span>
                        <div className={styles.item}>
                            <span style={{width: '60%'}}>工地进场文本证据</span>
                            <div className={styles.itemSpan}>
                                {
                                    detailInfo.open_photo != '' ? <span style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.setState({imgFlag: true, previewImage: detailInfo.open_photo.split(',')}) } }>查看图片</span> : <span>暂无图片</span>
                                }
                                {
                                    detailInfo.open_photo != '' ? <span style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.setState({imgFlag: true, previewImage: detailInfo.open_photo.split(',')}) } }>查看视频</span> : <span>暂无视频</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.label}>
                        <span>其他证据</span>
                        <div className={styles.item}>
                            <span style={{width: '60%'}}>工地进场文本证据</span>
                            <div className={styles.itemSpan}>
                                {
                                    detailInfo.open_photo != '' ? <span style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.setState({imgFlag: true, previewImage: detailInfo.open_photo.split(',')}) } }>查看图片</span> : <span>暂无图片</span>
                                }
                                {
                                    detailInfo.open_photo != '' ? <span style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.setState({imgFlag: true, previewImage: detailInfo.open_photo.split(',')}) } }>查看视频</span> : <span>暂无视频</span>
                                }
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    wrapClassName='modal-img'
                    centered={true}
                    visible={imgFlag}
                    onCancel={() => this.setState({imgFlag: false, previewImage: []})}
                    footer={null}>

                    <Carousel autoplay={true}>
                        {
                            previewImage.map( (v,i) => {
                                return (
                                    <div key={i}><img src={v} style={{width: '100%'}} alt=""/></div>
                                )
                            })
                        }
                    </Carousel>
                </Modal>

                <Modal
                    wrapClassName='modal-img'
                    centered={true}
                    visible={videoFlag}
                    onCancel={() => this.setState({videoFlag: false, video: []})}
                    footer={null}>

                    <video>
                        <source />
                    </video>
                </Modal>
            </div>
        )
    }
}
*/
