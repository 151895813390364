export default [
  ['指挥中心', 15, 'command-center', {}, require('../../paths/CommandCenter')],
  ['运输雷达', 223, 'transport-radar', {}, [
    ['运输雷达', 224, 'transport-radar', { 'icon': 'glasses' }, require('../../paths/TransportRadar/TransportRadar')],
    ['源头回溯', 224, 'transport-recall', { 'icon': 'glasses' }, require('../../paths/TransportRadar/TransportRecall')],
    ['运输天网', 224, 'transport-skynet', { 'icon': 'glasses' }, require('../../paths/TransportRadar/TransportSkynet')],
    ['异常联单', 225, 'exception-ticket', { 'icon': 'exclamation-triangle' }, require('../../paths/TransportRadar/ExceptionTicket')],
    ['排运消大数据', 224, 'takeover-center', {'icon': 'truck-loading'}, require('../../paths/TransportRadar/TakeoverCenter')]
  ]],
  ['砂石场入库', 202, 'sandstone-receiver', {}, [
    ['待确认收货', 202, 'to-be-confirmed', {'icon': 'glasses'}, require('../../paths/SandstoneReceiver/ToBeConfirmed')],
    ['电子出门条', 202, 'electronic-exit-strip', {'icon': 'truck'}, require('../../paths/SandstoneReceiver/ElectronicStrip')],
    ['已入库', 202, 'ware-housing', {'icon': 'flag-checkered'}, require('../../paths/SandstoneReceiver/WareHousing')]    
  ]],
  ['砂石场入库', 244, 'sandstone-receiver', {}, [
    ['待确认收货', 244, 'to-be-confirmed', {'icon': 'glasses'}, require('../../paths/SandstoneReceiver/ToBeConfirmed')],
    ['已入库', 244, 'ware-housing', {'icon': 'flag-checkered'}, require('../../paths/SandstoneReceiver/WareHousing')]    
  ]], // 温江销售
  ['砂石场入库', 243, 'sandstone-receiver', {}, [
    ['已入库', 243, 'manager-receiver', {'icon': 'glasses'}, require('../../paths/SandstoneReceiver/MangerReceiver')],
    ['电子出门条', 243, 'electronic-exit-strip', {'icon': 'truck'}, require('../../paths/SandstoneReceiver/ElectronicStrip')],
  ]], // 管理员
  ['砂石场入库', 242, 'sandstone-receiver', {}, [
    ['待确认收货', 242, 'to-be-confirmed', {'icon': 'glasses'}, require('../../paths/SandstoneReceiverByQBJ/ToBeConfirmed')],
    ['已入库', 242, 'ware-housing', {'icon': 'glasses'}, require('../../paths/SandstoneReceiverByQBJ/WareHousing')]
  ]],
  ['运输雷达', 229, 'transport-radar', {}, require('../../paths/TransportRadar/CompanyRadar')],
  ['驾驶员信息', 10, 'drivers', {}, require('../../paths/Drivers')],
  ['车辆信息', 12, 'concretation-vehicles', {}, require('../../paths/ConcretationVehicles')],
  ['车辆信息', 9, 'transportation-vehicles', {}, [
    ['基本信息', 31, 'basic', { 'icon': 'glasses' }, require('../../paths/TransportationVehicles/Basic')],
    ['车辆违法信息', 32, 'illegals', { 'icon': 'exclamation-triangle' }, require('../../paths/TransportationVehicles/Illegals')],
    ['车辆事故信息', 33, 'accidents', { 'icon': 'car-crash' }, require('../../paths/TransportationVehicles/Accidents')]
  ]],
  ['砂石运输名录', 219, 'sand-vehicle-list', {}, require('../../paths/SandCehicleList')],
  ['运企信息', 8, 'transportation', {}, require('../../paths/Infomation/Transportation')],
  ['商砼站信息', 11, 'concretation', {}, require('../../paths/Infomation/Concretation')],
  ['固定源管理', 16, 'fixed-sources', {}, [
    ['工地', 40, 'worksite', { 'icon': 'street-view' }, require('../../paths/FixedSources/Worksite')],
    ['商砼站', 41, 'concretation', { 'icon': 'industry' }, require('../../paths/FixedSources/Concretation')],
    ['砂石场', 42, 'sandstone-factory', { 'icon': 'snowplow' }, require('../../paths/FixedSources/SandstoneFactory')]
  ]],
  ['砂石接管', 1, 'sandstone-takeover', {}, [
    ['接管方案', 21, 'takeover-plans', { 'icon': 'flag-checkered' }, require('../../paths/SandstoneTakeover/Plans')],
    ['接管路线', 20, 'takeover-routes', { 'icon': 'route' }, require('../../paths/SandstoneTakeover/Routes')],
    ['车辆黑名单', 20, 'vehicle-blacklist', { 'icon': 'ban' }, require('../../paths/SandstoneTakeover/VehicleBlacklist')]
  ]],
  ['商混运输', 3, 'concret-transport', {}, [
    ['运输方案', 3, 'transport-plans', { 'icon': 'flag-checkered' }, require('../../paths/ConcretTransport/Plans')],
    ['运输路线', 3, 'transport-routes', { 'icon': 'route' }, require('../../paths/ConcretTransport/Routes')]
  ]],
  ['成品砂石运输', 2, 'finished-sandstone-transport', {}, [
    ['接管方案', 218, 'takeover-plans', { 'icon': 'flag-checkered' }, require('../../paths/FinishedSandstoneTransport/Plans')],
    ['方量修改记录', 216, 'volume-modification-records', { 'icon': 'file-signature' }, require('../../paths/FinishedSandstoneTransport/VolumeModificationRecords')],
    ['车辆管理', 217, 'cars', { 'icon': 'truck' }, require('../../paths/FinishedSandstoneTransport/Cars')]
  ]],
  // ['临时线路', 14, 'temporary-routes', {}, require('../../paths/TemporaryRoutes')],
  // ['申报路线', 4, 'report-sandstone-takeover-routes', {}, require('../../paths/ReportSandstoneTakeoverRoutes')],
  ['运输申报', 4, 'report-management', {}, [
    ['运输备案', 4, 'report-sandstone-takeover-routes', { 'icon': 'map-marked' }, require('../../paths/NewSandstoneTakeoverRoutes')],
  ]],
  // ['申报路线', 210, 'report-concret-transport-routes', {}, require('../../paths/ReportConcretTransportRoutes')],
  // ['报备临时路线', 13, 'report-temporary-routes', {}, require('../../paths/ReportTemporaryRoutes')],
  //交警运输审核菜单--old
  /*['运输审核', 5, 'transportation-review', {}, [
    ['沙石接管', 5, 'sandstone-takeover', { 'icon': 'cube' }, require('../../paths/TransportationReview/SandstoneTakeover')],
    ['商混运输', 5, 'concret-transport', { 'icon': 'water' }, require('../../paths/TransportationReview/ConcretTransport')]
  ]],*/
  //交警运输备案菜单--new
  ['运输备案', 5, 'transportation-review', {}, [
    ['运输线路审核', 5, 'transport-check', { 'icon': 'cube' }, require('../../paths/NewTransportationReview/TransportCheck')],
    ['临时运输备案', 5, 'temporary-route', { 'icon': 'water' }, require('../../paths/NewTransportationReview/TemperoryRoute')]
  ]],
  ['车辆违法事故信息', 6, 'vehicle-traffic', {}, [
    ['车辆违法信息', 27, 'illegals', { 'icon': 'exclamation-triangle' }, require('../../paths/VehicleTraffic/Illegals')],
    ['车辆事故信息', 28, 'accidents', { 'icon': 'car-crash' }, require('../../paths/VehicleTraffic/Accidents')]
  ]],
  ['车辆台账', 19, 'vehicle-ledger', {}, [
    ['GPS在线', 50, 'gps-online', { 'icon': 'map-marked' }, require('../../paths/VehicleLedger/GPSOnline')],
    ['非名录车', 51, 'non-listed', { 'icon': 'clipboard' }, require('../../paths/VehicleLedger/NonListed')],
    ['非合同车', 52, 'non-contract', { 'icon': 'file-contract' }, require('../../paths/VehicleLedger/NonContract')],
    ['疑似超速', 53, 'overspeed', { 'icon': 'tachometer-alt' }, require('../../paths/VehicleLedger/Overspeed')],
    ['路线偏离', 54, 'deviation', { 'icon': 'road' }, require('../../paths/VehicleLedger/Deviation')],
    ['疑似违规倾倒', 55, 'dumping', { 'icon': 'truck-loading' }, require('../../paths/VehicleLedger/Dumping')],
    ['篷布未密闭', 56, 'not-sealed-tarpaulin', { 'icon': 'door-open' }, require('../../paths/VehicleLedger/NotSealedTarpaulin')],
    ['疑似违规出料', 57, 'discharge', { 'icon': 'tint' }, require('../../paths/VehicleLedger/Discharge')],
    ['商砼车停靠过长', 58, 'parked-too-long', { 'icon': 'parking' }, require('../../paths/VehicleLedger/ParkedTooLong')],
    ['高峰期上路', 59, 'rush-hour-on-road', { 'icon': 'traffic-light' }, require('../../paths/VehicleLedger/RushHourOnRoad')],
    ['车辆进出', 59, 'car-in-out', { 'icon': 'truck' }, require('../../paths/VehicleLedger/CarInOut')]
  ]],
  ['电子联单', 17, 'orders', {}, [
    ['联单列表', 43, 'list', { 'icon': 'clipboard-list' }, [
      ['全部', 43, 'all', {}, require('../../paths/Orders/List')],
      ['砂石入库', 43, 'ware-housing', {}, require('../../paths/Orders/WareHousing')],
      ['可结算', 43, 'settlement', {}, require('../../paths/Orders/Settlement')],
      ['待核实', 43, 'verify', {}, require('../../paths/Orders/Verify')],
      ['审核中', 43, 'audit', {}, require('../../paths/Orders/Audit')],
      ['已作废', 43, 'invalid', {}, require('../../paths/Orders/Invalid')]
    ]],
    ['方量修改记录', 44, 'volume-modification-records', { 'icon': 'file-signature' }, require('../../paths/Orders/VolumeModificationRecords')],
    ['联单补录记录', 45, 'supplement-records', { 'icon': 'file-medical' }, require('../../paths/Orders/SupplementRecords')],
    ['联单删除记录', 46, 'delete-records', { 'icon': 'file-excel' }, require('../../paths/Orders/DeleteRecords')]
  ]],
  ['电子联单', 227, 'orders', {}, require('../../paths/Orders/List')],
  ['结算中心', 18, 'settlement-center', {}, [
    ['结算报表', 47, 'settlement-reports', { 'icon': 'align-justify' }, require('../../paths/SettlementCenter/SettlementReports')],
    ['运输结算清单', 48, 'transport-reports', { 'icon': 'align-left' }, require('../../paths/SettlementCenter/TransportReports')],
    ['运营结算清单', 49, 'operate-reports', { 'icon': 'align-right' }, require('../../paths/SettlementCenter/OperateReports')]
  ]],
  ['数据填报', 203, 'fill-output', {}, [
    ['已填报', 205, 'finished', { 'icon': 'check-circle' }, require('../../paths/FillOutput/Finished')],
    ['未填报', 204, 'unfinished', { 'icon': 'pause-circle' }, require('../../paths/FillOutput/Unfinished')]
  ]],
  ['备案管理', 220, 'register-management', {}, [
    ['待报备车辆', 221, 'forecast-preparation-car', { 'icon': 'check-circle' }, require('../../paths/RegisterManagement/ForecastPreparationCar')],
    ['预报备', 221, 'forecast-preparation', { 'icon': 'check-circle' }, require('../../paths/RegisterManagement/ForecastPreparation')],
    ['正式报备', 222, 'reporting', { 'icon': 'pause-circle' }, [
      ['车辆正式报备', 221, 'official-reporting', {}, require('../../paths/RegisterManagement/OfficialReporting')],
      ['运企信息变更', 221, 'message-change', {}, require('../../paths/RegisterManagement/MessageChange')]]
    ],
    // ['GPS离线申报', 226, 'gps-off-line-declaration', { 'icon': 'pause-circle' }, require('../../paths/RegisterManagement/GPSOffLineDeclaration')]
  ]],
  ['结算台账', 220, 'settlement-case', {}, require('../../paths/SettlementCase')],
  ['证照提醒', 220, 'document-reminder', {}, [
    ['车辆审期', 221, 'vehicle-audit-period', { 'icon': 'check-circle' }, require('../../paths/DocumentReminder/VehicleAuditPeriod')],
    ['司机审期', 222, 'driver-trial-period', { 'icon': 'check-circle' }, require('../../paths/DocumentReminder/DriverTrialPeriod')]
  ]],
  ['GPS异常', 228, 'exception-gps', {}, require('../../paths/ExceptionGPS')],
  ["违规名单", 228, "illegal-list", {}, [
    ["灰名单", 228, "grey-list", {"icon": "exclamation-circle"}, [
      ["车辆", 228, "car", {}, require("../../paths/IllegalList/GreyList/Car")],
      ["驾驶员", 228, "driver", {},  require("../../paths/IllegalList/GreyList/Driver")],
      // ["核实台账", 228, "verification-accounts", {},  require("../../paths/IllegalList/GreyList/VerificationAccounts")]
    ]],
    ["黑名单", 228, "black-list", {"icon": "ban"}, [
      ["车辆", 228, "car", {},  require("../../paths/IllegalList/BlackList/Car")],
      ["驾驶员", 228, "driver", {},  require("../../paths/IllegalList/BlackList/Driver")],
      // ["核实台账", 228, "verification-accounts", {},  require("../../paths/IllegalList/BlackList/VerificationAccounts")]
    ]],
    ["核实台账", 228, "verification-accounts", {"icon": "tasks"},  require("../../paths/IllegalList/VerificationAccounts")],
    ["处罚告警", 228, "penalty-alarm", {"icon": "bell"},  require("../../paths/IllegalList/PenaltyAlarm")]
    ]
  ],
  ['河道基础信息', 230, 'basic-information', {}, [
    ['运企', 231, 'transport', { 'icon': 'flag-checkered' }, require('../../paths/BasicInformation/Transport')],
    ['驾驶员', 232, 'driver', { 'icon': 'street-view' }, require('../../paths/BasicInformation/Driver')],
    ['车辆', 233, 'car', { 'icon': 'truck' }, require('../../paths/BasicInformation/Car')],
    ['项目基础信息', 234, 'basic', { 'icon': 'clipboard-list' }, require('../../paths/BasicInformation/Basic')],
    ['项目点位信息', 236, 'points', { 'icon': 'map-marked' }, require('../../paths/BasicInformation/Points')],
    ['项目车辆信息', 237, 'project-car', { 'icon': 'snowplow' }, require('../../paths/BasicInformation/ProjectCar')],
  ]],
  ['河道电子联单', 238, 'new-ticket', {}, require('../../paths/NewTicket')],
  ['河道监测点台账', 239, 'over-speed-warn', {}, require('../../paths/OverSpeedWarn')],
  ['堆场台账', 240, 'storage-yard', {}, require('../../paths/StorageYard')],
  ['运输名录', 241, 'riverway-transport', {}, require('../../paths/RiverwayTransport')],
]
