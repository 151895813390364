/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import qs from 'qs';
import immutable from 'immutable';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Table,
  Button,
  Input,
  Icon,
  message,
  Popconfirm,
} from 'antd';
import Uploader from 'components/Uploader';
import request, { open } from 'utils/request';
import styles from './Table.scss';

// const inputs = [
//   {
//     key: 'keyword',
//     defaultValue: '',
//     beforeSubmit: v => v,
//     render: (value, onChange) => <Input value={value} onChange={onChange} />,
//   },
// ];

// const buttons = [
//   {
//     title: '',
//     type: 'primary',
//     onClick: v => v,
//   },
// ];

class OnceUploader extends React.PureComponent {
  state = {
    fileList: [],
  }

  onFileListChange = (fileList) => {
    const [url] = fileList.filter(({ status }) => status === 'done').map(({ response }) => response.src);
    if (url) {
      this.props.onChange(url);
      this.setState({ fileList: [] });
    } else {
      this.setState({ fileList });
    }
  }

  render() {
    const { onChange, ...rest } = this.props;
    return (
      <Uploader
        fileList={this.state.fileList}
        showUploadList={false}
        onChange={this.onFileListChange}
        {...rest}
      />
    );
  }
}

export default class QueryTable extends React.PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
    transformRequest: PropTypes.arrayOf(PropTypes.func),
    transformResponse: PropTypes.arrayOf(PropTypes.func),
    limit: PropTypes.number,
    rowKey: PropTypes.string,
    columns: PropTypes.array,
    inputs: PropTypes.array,
    buttons: PropTypes.array,
  }

  static defaultProps = {
    transformRequest: [],
    transformResponse: [],
    limit: 10,
    rowKey: 'id',
    columns: [],
    inputs: [],
    buttons: [],
  }

  state = {
    dataSource: [],
    query: immutable.Map({
      page: 1,
      limit: this.props.limit,
    }),
  }
  get(...path) {
    return this.state.query.getIn(path);
  }
  setter = (...path) => (value) => {
    this.setState(state => ({
      query: state.query.setIn(path, value),
    }));
  }

  componentDidMount() {
    this.query();
  }

  get params() {
    return this.props.inputs.reduce((query, {
      key,
      defaultValue = '',
      beforeSubmit = v => v,
    }) => {
      const value = beforeSubmit(query[key] || defaultValue);
      if (value !== undefined && value !== '') {
        // eslint-disable-next-line no-param-reassign
        query[key] = value;
      }
      return query;
    }, this.state.query.toJS());
  }

  query = async () => {
    request({
      url: this.props.url,
      transformRequest: this.props.transformRequest,
      transformResponse: this.props.transformResponse,
      data: this.params,
    })
      .then(body => body.data.data)
      .then(({ current_page, total, data }) => {
        const query = this.state.query.set('page', current_page);
        const dataSource = data.map((item, index) => ({ ...item, index: (index + 1) + (query.get('page') - 1) * query.get('limit') }));
        this.setState({ query, dataSource, total });
      });
  }

  onPaginationChange = (page, limit) => {
    this.setState(({ query }) => ({
      query: query.set('page', page).set('limit', limit),
    }), this.query);
  }

  onUpload = url => (src) => {
    request({ url, data: { file: src } })
      .then(body => body.data)
      .then(({ code, msg }) => {
        if (code === 200) {
          this.query();
        } else {
          const err = typeof msg === 'string' ? msg : '上传出错';
          message.warning(err);
        }
      });
  }

  render() {
    const hasInputs = this.props.inputs.length > 0;
    const hasButtons = this.props.buttons.length > 0;
    const query = this.state.query.toJS();
    const columns = this.props.columns.map(({ render, ...rest }) => ({
      ...rest,
      render: typeof render === 'function' && ((cell, row) => render(cell, row, {
        query: this.query,
      })),
    }));

    return (
      <div className={this.props.className || styles.container}>
        {(hasInputs || hasButtons) && (
          <div className={styles.query}>
            <div className={styles.left}>
              {this.props.buttons.map(({
                to,
                download: downloadURL,
                upload: uploadURL,
                icon,
                title = '',
                type = 'primary',
                onClick = v => v,
                ...rest
              }, index) => {
                const content = !icon ? title : [<Icon type={icon} />, title];
                if (uploadURL) {
                  return (
                    <OnceUploader key={index} onChange={this.onUpload(uploadURL)}>
                      <Button type={type}>{content}</Button>
                    </OnceUploader>
                  );
                }
                return (
                  <Button key={index} {...rest} type={type} onClick={() => (downloadURL ? open(downloadURL) : onClick(query))}>
                    {!to ? content : (
                      <Link to={to}>{content}</Link>
                    )}
                  </Button>
                );
              })}
            </div>
            <div className={styles.right}>
              <Input.Group compact>
                {this.props.inputs.map(({
                  key,
                  placeholder = '',
                  defaultValue = '',
                  render = ({ onChange, ...props }) => <Input {...props} onChange={e => onChange(e.target.value)} />,
                  renderProps = {},
                }) => {
                  const value = this.get(key) || defaultValue;
                  const onChange = this.setter(key);
                  return render({ key, value, placeholder, onChange, ...renderProps });
                })}
                {hasInputs && <Button type="primary" onClick={this.query}><Icon type="search" /></Button>}
              </Input.Group>
            </div>
          </div>
        )}
        <Table
          bordered
          rowKey={this.props.rowKey}
          columns={columns}
          dataSource={this.state.dataSource}
          pagination={{
            showSizeChanger: true,
            pageSize: this.get('limit'),
            current: this.get('page'),
            total: this.state.total,
            onChange: this.onPaginationChange,
            onShowSizeChange: this.onPaginationChange,
          }}
        />
      </div>
    );
  }
}
