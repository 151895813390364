import React from 'react';
import { Button, Popconfirm } from 'antd';
import request from 'utils/request';

export default function ConfirmButton({ title, type, children, api, dataSource, ...data }) {
  async function confirm() {
    await request(api, data);
    dataSource.refresh();
  }

  return (
    <Popconfirm title={title} onConfirm={confirm}>
      <Button type={type} size="small">{children}</Button>
    </Popconfirm>
  );
}
