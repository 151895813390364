import React, { useState, useEffect } from 'react'
import { Modal, Steps, List, Button } from 'antd'
import { previewable } from 'components/Previewer/index';
import request from 'utils/request';
import ImagePreviewButtonRender from 'ui/TableCell/ImagePreviewButtonRender';
import styles from './index.scss'
const { Step } = Steps;


/* 详情按钮 */
function Details({ props, preview }) {
  let { detailsInfo, DetailsInfo, selectedRows } = props
  const render = ImagePreviewButtonRender(preview);
  const [info, setInfo] = useState([])
  const [car_num, setCarNum] = useState('')
  const [old_time, setOld_time] = useState('')
  const [visible, setVisible] = useState(false)
  const nodeName = ['', '告警通知', '上报扬尘办', '转灰名单', '灰名单延期', '转黑名单', '']
  useEffect(() => { if (detailsInfo) { setVisible(true) } }, [detailsInfo])
  useEffect(() => { if (!visible) { DetailsInfo({ visible }) } }, [visible])
  useEffect(() => { if (selectedRows.length > 0) { setCarNum(selectedRows[0].car_number) } }, [selectedRows])
  function handleCancel(e) { setVisible(false) };
  function Circle({ props }) { return <div className={styles.Circle}>{props}</div> }
  useEffect(() => {
    if (!visible) return
    request('/web/gb_list/getAlarmDetails', { list_id: selectedRows[0].id })
      .then(res => {
        setInfo(res.data.data.flows)
      })
  }, [visible])
  function RightContent({ props, item }) {
    let { node_time, created_name, reason, images, old_time, new_time, node } = item
    if (old_time && old_time) { setOld_time(old_time) }
    let files = images && images.split(',')
    return (
      <List bordered style={{ borderRadius: 12 }}>
        <List.Item style={{ background: '#1890ff', color: '#fff', borderRadius: '12px 12px 0 0' }}> {nodeName[props]} </List.Item>
        {props > 2 ? <List.Item>处理结果<span style={{ float: 'right' }}>{nodeName[props]}</span></List.Item> : null}
        {
          node == (3 && 4) ?
            <>
              {old_time ? <List.Item>原灰名单延期<span style={{ float: 'right' }}>{old_time}</span></List.Item> : null}
              {new_time ? <List.Item>现灰名单延期<span style={{ float: 'right' }}>{new_time}</span></List.Item> : null}
            </> : null
        }
        <List.Item>创建时间<span style={{ float: 'right' }}>{node_time}</span></List.Item>
        <List.Item>创建人员<span style={{ float: 'right' }}>{created_name ? created_name : '系统默认'}</span></List.Item>
        {reason ? <List.Item>上报描述<span style={{ float: 'right' }}>{reason}</span></List.Item> : null}
        {
          files ?
            <List.Item style={{ lineHeight: '44px' }}>证据照片
              <span style={{ float: 'right' }}>
                {
                  files.map((item, index) => {
                    if (item.indexOf('.pdf') > -1) { return <Button key={index} size="small" type="link" onClick={() => open(item)}>查看PDF</Button> }
                    if ((item.indexOf('.doc') > -1) || (item.indexOf('.docx') > -1)) { return <Button key={index} size="small" type="link" onClick={() => open(item)}>查看文档</Button> }
                    if ((item.indexOf('.xls') > -1) || (item.indexOf('.xlsx') > -1)) { return <Button key={index} size="small" type="link" onClick={() => open(item)}>查看表格</Button> }
                    if ((item.indexOf('.jpg') > -1) || (item.indexOf('.png') > -1) || (item.indexOf('.gif') > -1)) { return <img key={index} style={{ width: 50, marginLeft: 5 }} src={item} onClick={() => preview.image(item)} /> }
                  })
                }
              </span>
            </List.Item>
            : null
        }
      </List>
    )
  }
  const [allIndex, setAllIndex] = useState([])
  useEffect(() => {
    let i = []
    for (let a = 1; a < info.length + 1; a++) {
      i.push(a)
    }
    i = i.reverse()
    setAllIndex(i)
  }, [info]);

  return (
    <Modal
      title={car_num + '详情'}
      centered
      footer={null}
      visible={visible}
      maskClosable={false}
      onCancel={handleCancel}
      width={old_time == '' ? null : 550}
    >
      {
        info.length > 0 ?
          <Steps direction="vertical" current={3} className={styles.stepsAll}>
            {
              info.map((item, index) => {
                return <Step key={index} icon={<Circle props={allIndex[index]} />} description={<RightContent props={item.node} item={item} />} />
              })
            }
          </Steps>
          : '暂无数据'
      }
    </Modal>
  )
}
export default previewable(Details);
