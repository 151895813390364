/* eslint-disable no-undef */
import axios from 'axios';
import debug from '../debug';

import authorize from './transformers/authorize';
import data2form from './transformers/data2form';
import json2data from './transformers/json2data';
import expirekey from './transformers/expirekey';

export { default as upload } from './upload';
export { default as open } from './open';

export default function request(opts, data = {}) {
  const options = typeof opts === 'object' ? opts : { url: opts, data };
  const { transformRequest = [], transformResponse = [], ...rest } = options;

  debug.http(options.url, data);

  return axios({
    baseURL: URLS.DATA,
    method: 'POST',
    transformRequest: [...transformRequest, authorize, data2form],
    transformResponse: [json2data, expirekey, ...transformResponse],
    ...rest,
  }).catch(() => {
    const params = data2form(authorize(options.data));
    debug.httpError(`${URLS.DATA}${options.url}?${params}`);
  });
}
