import React from 'react';
import { message } from 'antd';
import { dataSource } from './List';
import ModalFormPage from 'ui/ModalFormPage';
import { CarInfo } from './CarInfo';

export default function CreateCar({ history }) {
  function onFinish() {
    dataSource.refresh();
    message.success('新增成功');
    history.goBack();
  }
  return (
    <ModalFormPage
      title={'新增车辆'}
      submit={['/web/keep_on_record/addInfo']}
      inputs={CarInfo}
      visible={false}
      onFinish={onFinish}
      onCancel={() => history.goBack()}
      modalProps={{
        width: 800,
      }}
      formProps={{
        labelCol: { xs: { span: 6 } },
        wrapperCol: { xs: { span: 18 } },
      }}
    />
  )
}
