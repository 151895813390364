import React, { useState, useRef, useEffect } from 'react'
import { Button, Cascader, Input, Upload, Icon, Modal, message, Select } from 'antd'
import moment from 'moment';
import request from 'utils/request/index';
import styles from './Inform.scss';
import AlarmForm from './AlarmForm';
import Flow from './Flow';
import DispatchFlow from './DispatchFlow';
import store from 'stores/user';
import {setNoticeText, flowNotice} from './FilterFunction';
import {inputs, dispatch, dispatchResend} from './InformData';

const { confirm } = Modal;
function getDataDetail({id, url, rely, defaultData}){
  const [data, setData] = useState(defaultData);
  useEffect(() => {
    request(url, {id})
      .then(b => b.data.data)
      .then(setData)
  }, [rely]);
  return data
}
function getFastNoticeUser(id){
  const [data, setData] = useState([]);
  useEffect(() => {
    request('/web/count_alarm/fastNoticeUser', {id})
      .then(b => b.data.data)
      .then(setData)
  }, [id]);
  return data
}

function setInformText(status, notice_count){
  let text = '通知';
  switch (status) {
    case 1: text = '告警通知'; break;
    case 2: text = setNoticeText(notice_count); break;
    case 3: text = ''; break;
    case 4: text = '提醒'; break;
    case 5: text = setNoticeText(notice_count); break;
    case 6: text = ''; break;
  }
  return text;
}
export default function inform({id, status, dispatch_task_id, notice_count, onChangeAlarmId}) {
  //左侧标题： 一.待处理   标题按钮：通知 和 督办派单
  //         二.已忽略
  //         三.已通知  四.已督办
  const { info: {area_id}} = store.getState();
  const roleId = store.getState().roles.id;
  const [dispatchTaskId, setDispatchTaskId] = useState(dispatch_task_id);
  const [noticeFlag, setNoticeFlag] = useState(1);
  const getFastUser = getDataDetail({id, url: '/web/count_alarm/fastNoticeUser', rely: id, defaultData: []});//getFastNoticeUser(id);
  const [title, setTitle] = useState(1); // 一.待处理    =>两个标题（可按）通知：1 和 督办派单：2
  const reportEmergency = true; // 结算告警 和 车辆告警  =>单个标题 false显示
  const [userId, setUserId] = useState(); //人员选择
  const [dispatchOk, setDispatchOk] = useState(true);
  useEffect(() => {
    setNoticeFlag(1);
    setDispatchTaskId(dispatch_task_id);
  }, [id]);

  //选择快速通知人员
  const userChoose = (id) => {
    if(id === userId){
      setUserId();
    }else{
      setUserId(id)
    }
  };

  function ignore() { //忽略告警弹窗
    confirm({
      title: '确定忽略此次告警？',
      centered: true,
      onOk() {
        request('/web/count_alarm/flowCancel', {id})
          .then(body => body.data)
          .then(d => {
            if(d.code === 200){
              message.success('已成功忽略此告警！');
              onChangeAlarmId();
            }else{
              message.warn('忽略告警失败！')
            }
          })
         },
      onCancel() { console.log('Cancel'); },
    });
  }
  // 通知
  const onFinish = (data) => {
    const json = userId ? {users_id: userId} : {...data}
    request('web/count_alarm/flowPost', {...json, id})
      .then(b => {
        if(b.data.code === 200){
          message.success('通知成功');
          onChangeAlarmId();
        }else{
          message.warn(b.data.msg)
        }
      })
  };
  //督办派单
  const dispatchTask = (data) => {
    console.log(data)
    request('/rest/dispatch_task/add_ajax', {...data, id})
      .then(d => {
        const data = d.data;
        if(data.code === 200){
          message.success('督办派单成功！');
          // setDispatchOk(true)
          onChangeAlarmId();
          setDispatchTaskId(1);
          // setDispatchData(getDispatchTaskDetail());
        }else{
          message.warn(data.msg);
        }
      })
  };
  // 转发督办派单
  const dispatchResendTask = (data) => {
    request('/rest/dispatch_task/resend_ajax', {...data, id})
      .then(d => {
        const data = d.data;
        if(data.code === 200){
          message.success('转交成功！');
            // setDispatchOk(true);
          onChangeAlarmId();
        }else{
          message.warn(data.msg);
        }
      })
  };

  return (
    <div>
      <div className={styles.informWrap}>
        {
          reportEmergency ?
            <div className={styles.title}>
              <Button type="primary" onClick={() => setTitle(1)} style={{ backgroundColor: title === 1 ? '#1491FB' : '#fff', color: title === 1 ? '#fff' : '#1491FB' }}>通知</Button>
              <Button type="primary" onClick={() => setTitle(2)} style={{ backgroundColor: title === 2 ? '#1491FB' : '#fff', color: title === 2 ? '#fff' : '#1491FB' }}>督办派单</Button>
            </div> :
            <div style={{ textAlign: 'center' }}><h3 style={{ fontSize: 20, lineHeight: '42px', fontWeight: 'border' }}>督办派单</h3></div>
        }
        {
          title === 1 ?
            <div className={styles.choosePart}>
              {
                noticeFlag === 1 ?
                  <div>
                    <div className={styles.choose}>
                      <Flow id={id} />
                    </div>
                  </div> :
                  <div>
                    {
                      getFastUser.length > 0  ? <div className={styles.choose}>
                        <p>快速通知</p>
                        <div className={styles.chooseUser}>
                          {
                            getFastUser.map(({id, name}, i) => (
                              <div key={i} onClick={() => userChoose(id)} className={id === userId ? styles.active : ''}>{name}</div>
                            ))
                          }
                        </div>
                      </div>
                        : null
                    }
                    <div className={styles.choose}>
                      <AlarmForm inputs={inputs} userId={userId} defaultValue={!userId ? {area_id: area_id} : {}} onFinish={onFinish} />
                    </div>
                  </div>
              }
              <div className={styles.btns}>
                {
                  noticeFlag === 1 && (roleId === 111 || roleId === '111') ? status === 1 || status === 3 || status === 6 ?
                    null : <div className={styles.sure} >
                      {
                        status === 4 ? <Button type="primary" style={{ background: '#FF940E', border: 'none' }} onClick={() => flowNotice(id)}>提醒</Button> :
                          <Button type="primary" style={{ background: '#FF940E', border: 'none' }} onClick={() => setNoticeFlag(2)}>{setInformText(status, notice_count)}</Button>
                      }
                    </div> : null
                }
                {
                  roleId === 111 || roleId === '111' ?
                    status === 2 || status === 5 ? <div className={styles.neglect}>
                      <p onClick={ignore}>忽略此次告警</p>
                    </div> : null : null
                }
              </div>
            </div> :
            <>
              {
                dispatchTaskId === 0 ?
                  <div>
                    <div className={styles.choose}>
                      {
                        roleId === 111 || roleId === '111' ? <AlarmForm inputs={dispatch} defaultValue={{area_id: area_id}} onFinish={dispatchTask} />
                          : <div className={styles.empty}>
                            <img src="/images/alarm/empty.png" alt=""/> <span>暂无督办数据</span>
                          </div>
                      }

                    </div>
                  </div> :
                  <>
                    {
                      dispatchOk ?
                        <DispatchFlow id={id} ignore={ignore} status={status} setDispatchOk={() => setDispatchOk(false)} />
                        :
                        <div className={styles.choose}>
                          <AlarmForm inputs={dispatchResend} defaultValue={{area_id: area_id}} onFinish={dispatchResendTask} />
                        </div>
                    }
                  </>
              }
            </>
        }
      </div>
    </div >
  )
}
