import React, { useState } from 'react';
import { Modal, Table, DatePicker, message } from 'antd';
import Uploader from "../Uploader";
import styles from './index.scss';
import {dataSource} from '../GreyList/Car';
import request from 'utils/request';

const columns = [
  { title: '车牌', dataIndex: 'car_number' },
  { title: '原灰名单时间', dataIndex: 'in_time', render: (v, {in_time, out_time}) => (in_time + '~' + out_time) }
];

export default function Delay({ history, location, ...props}){
  const { state : { data } } = location;
  const [delayTime, setDelayTime] = useState();
  const [fileList, setFileList] = useState([]);
  const back = () => history.goBack();
  const onSubmit = () => {
    const car_ids = data.map(({id}) => id);
    const json = {
      car_ids: car_ids,
      delay_time: delayTime,
      images: fileList
    };
    request('/web/gb_list/carDelayTime', { ...json})
      .then(body => {
        if(body.data.code === 200){
          message.success(body.data.msg);
          dataSource.refresh();
          back();
        }else{
          message.warn(body.data.msg)
        }
      })
  };

  const disabled = !delayTime || fileList.length === 0;

  return (
    <Modal
      title={'灰名单时间延期'}
      visible={true}
      onCancel={back}
      onOk={onSubmit}
      width={750}
      bodyStyle={{ height: '70vh', overflow: 'auto'}}
      okButtonProps={{ disabled }}
    >
      <Table dataSource={data} columns={columns} rowKey={'id'} scroll={{ y: 225 }} pagination={false} />
      <div className={styles.greyClass}>
        <span>灰名单延期</span>
        <DatePicker
          format={'YYYY-MM-DD HH:mm:ss'}
          placeholder={'请选择延期时间'}
          showTime
          style={{ width: 200}}
          onChange={(date, moments) => setDelayTime(moments) }
        />
      </div>
      <div className={styles.greyClass}>
        <span>证据上传<label>(最多上传5条)</label> </span>
        <Uploader value={fileList} maxUploadLength={5} onChange={(e) => setFileList(e)} />
      </div>
    </Modal>
  )
}
