import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import moment from 'moment';
import echarts from 'echarts';
import request from 'utils/request';
import debug from 'utils/debug';
import styles from './index.scss';
import { DatePicker, Button, Input, Slider, Switch, Drawer, Radio, message, Spin, Icon, Modal, Pagination, Tooltip } from "antd";
import h_common from 'utils/h_common';
const defaultDates = [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')];//当天日期前一天
const defaultDatesPoint = [moment().subtract(0, 'day').startOf('day'), moment().endOf('day')];
import { UseMapScatter, UseMapInstance, StartOrEndPoint, UseMapLines, UseMapLinesTooltip, GetTicketShowTime, UseMapAnchorage, PointClass } from 'components/Previewer';
const logChartDispose = () => debug.action('Echarts实例已卸载') || true;
const isArrayAllValid = array => array.findIndex(item => item === undefined) === -1;
const coordObjectsToArrays = objects => objects.map(({ lng, lat }) => [lng, lat]);
const coordObjectsToArraysByTrans = objects => objects.map(({ lng, lat }) => h_common.GPS_transformation(lat, lng));
const coordObjectsToArraysByTicket = objects => objects.map(({ lng, lat, stay_time, ticket_id, time, }) => ({ value: h_common.GPS_transformation(lat, lng), stay_time, ticket_id, time }));
const coordObjectsToArraysByPoints = objects => objects.map(({ lng, lat, speed, time, ticket_id }) => ({ value: h_common.GPS_transformation(lat, lng), speed, time, ticket_id }));
const isInArray = function (arr, value) {
  for (var i = 0; i < arr.length; i++) {
    if (value === arr[i]) {
      return true
    }
  }
  return false
};
import store from 'stores/user';


let plansData = [], plansListData = [], plansStopData = [], plansElseList = [];
let planFlag = false; let detailData = [], ticket_status = null;
function usePlans(dates) {
  const [data, setData] = useState({ elseList: [], list: [], lines: [], sourceList: [], stopPoints: [], ticketLines: [], ticketSource: [], suspectConsum: [] });
  const begin_date = typeof (dates[0]) === 'string' ? dates[0] : dates[0].format('YYYY-MM-DD HH:mm:ss');
  const end_date = typeof (dates[1]) === 'string' ? dates[1] : dates[1].format('YYYY-MM-DD HH:mm:ss');
  useEffect(() => {
    request('web/ticket_map/transportDistribution', { begin_date: begin_date, end_date: end_date })
      .then(body => body.data.data)
      .then(setData);
  }, [begin_date, end_date]);
  plansData = data.sourceList;
  plansListData = data.list;
  plansStopData = data.stopPoints;
  plansElseList = data.elseList;
  let sum = 0;
  data.list.map(({ num, volumn }) => {
    sum += parseInt(volumn);
  });
  return data;
}
function usePlan(plans) {
  const [index, setIndex] = useState();
  const dependencies = [plans, index];
  const plan = useMemo(() => {
    if (!isArrayAllValid(dependencies)) return {};
    return plans[index] || {};
  }, dependencies);
  if (plan.id) {
    planFlag = true
  } else {
    planFlag = false
  }
  return [plan, setIndex];
}
function useDetails(plan, dates, hour) {
  const [data, setData] = useState();
  // const start_id = plan.start_id;
  const start_id = plan.id;
  // const start_department_id = plan.start_department_id;
  const start_department_id = plan.department_id;
  const begin_date = typeof (dates[0]) === 'string' ? dates[0] : dates[0].format('YYYY-MM-DD HH:mm:ss');
  const end_date = typeof (dates[1]) === 'string' ? dates[1] : dates[1].format('YYYY-MM-DD HH:mm:ss');
  const dependencies = [start_id, start_department_id, begin_date, end_date, hour];
  useEffect(() => {
    if (!isArrayAllValid(dependencies)) return;
    setData();
    request('/web/ticket_map/getMapDetail', { start_id, start_department_id, begin_date, end_date, hour: hour }).then(body => setData(body.data.data));
  }, dependencies);
  detailData = data;
  return data;
}
const DuplicateRemoval = (data) => {
  let arr = [];
  data.map(v => {
    let f = true;
    arr.map(e => {
      if ((v.id === e.id) && (v.department_id === e.department_id)) {
        f = false;
      }
    })
    if (f) {
      arr.push(v)
    }
  });
  return arr;
};
const pointsImages = [
  'image:///images/center/wait.png',
  [
    '',
    'image:///images/center/consum0.png',
    ['', 'image:///images/center/b1.png', 'image:///images/center/b3.png', 'image:///images/center/b5.png'],
    ['', 'image:///images/center/s1.png', 'image:///images/center/s3.png', 'image:///images/center/s5.png'],
    ['', 'image:///images/center/x1.png', 'image:///images/center/x3.png', 'image:///images/center/x5.png'],
    ['', 'image:///images/center/t1.png', 'image:///images/center/t3.png', 'image:///images/center/t5.png'],
    ['', 'image:///images/center/q1.png', 'image:///images/center/q2.png', 'image:///images/center/q3.png'],
    ['', 'image:///images/center/j1.png', 'image:///images/center/j3.png', 'image:///images/center/j5.png'],
  ],
  [
    '',
    'image:///images/center/consum0.png',
    ['', 'image:///images/center/b2.png', 'image:///images/center/b4.png', 'image:///images/center/b6.png'],
    ['', 'image:///images/center/s2.png', 'image:///images/center/s4.png', 'image:///images/center/s6.png'],
    ['', 'image:///images/center/x2.png', 'image:///images/center/x4.png', 'image:///images/center/x6.png'],
    ['', 'image:///images/center/t2.png', 'image:///images/center/t4.png', 'image:///images/center/t6.png'],
    ['', 'image:///images/center/q1.png', 'image:///images/center/q2.png', 'image:///images/center/q3.png'],
    ['', 'image:///images/center/j2.png', 'image:///images/center/j4.png', 'image:///images/center/j6.png'],
  ],
  [
    '',
    'image:///images/center/consum0.png',
    ['', 'image:///images/center/b2.png', 'image:///images/center/b4.png', 'image:///images/center/b6.png'],
    ['', 'image:///images/center/s2.png', 'image:///images/center/s4.png', 'image:///images/center/s6.png'],
    ['', 'image:///images/center/x2.png', 'image:///images/center/x4.png', 'image:///images/center/x6.png'],
    ['', 'image:///images/center/t2.png', 'image:///images/center/t4.png', 'image:///images/center/t6.png'],
    ['', 'image:///images/center/q1.png', 'image:///images/center/q2.png', 'image:///images/center/q3.png'],
    ['', 'image:///images/center/j2.png', 'image:///images/center/j4.png', 'image:///images/center/j6.png'],
  ]
];

const imagesSize = [39, 47];


function ChangeSize() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight
  })
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    })
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return (() => {
      window.removeEventListener('resize', onResize)
    })
  }, [])
  return size;
}
function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => { savedCallback.current = callback; });
  useEffect(() => { function tick() { savedCallback.current() }; if (delay !== null) { let id = setInterval(tick, delay); return () => clearInterval(id); } }, [delay]);
}
function useEcharts() {
  const [chart, setChart] = useState(null); // 存储echart实例
  const refGetter = useCallback(element => !!element && setChart(echarts.init(element)), []); // 创建echart实例
  const isChartUsable = useCallback(() => (!!chart && !chart.isDisposed()), [chart]); // 判断echart实例是否可用
  useEffect(() => () => (isChartUsable() && logChartDispose() && chart.dispose()), [chart]); // 卸载echart实例
  return [chart, isChartUsable, refGetter];
}
let TimeJson = {};
let bound = null; let bmap = null; let drawingManager = null;

function useMap(isChartUsable, chart, series, mapPoints, center, setCenterZoom, play_begin, locations, changeCenter, list, pointData, changeSave) {
  const new_series = {
    name: 'mapPoint', zlevel: 2, type: 'custom', coordinateSystem: 'bmap', tooltip: { show: false },
    renderItem: function (params, api) {
      if (mapPoints === undefined) return
      if (mapPoints.length <= 2) { return false }
      const points = mapPoints.map(({ lng, lat }) => api.coord([lng, lat]))
      return {
        type: 'polygon',
        shape: { points: echarts.graphic.clipPointsByRect(points, { x: params.coordSys.x, y: params.coordSys.y, width: params.coordSys.width, height: params.coordSys.height }) },
        style: api.style({ fill: 'rgba(151, 212, 33, 0.8)', stroke: 'rgb(106, 193, 11)', lineWidth: 3 }),
        styleEmphasis: api.style({ fill: 'rgba(151, 212, 33, 0.8)', stroke: 'rgb(106, 193, 11)', lineWidth: 3 }),
      };
    }, animation: false, data: [{ name: '', value: 0, location: mapPoints }], z: -10
  }
  const new_series1 = locations.map(v => ({
    name: v.name, zlevel: 2, type: 'custom', coordinateSystem: 'bmap',
    tooltip: { show: true, formatter: '保存名：' + v.name + '<br/>' + '操作人：' + v.by },
    renderItem: function (params, api) {
      if (v.location.length === 0) { return false }
      const points = v.location.map(({ lng, lat }) => api.coord([lng, lat]))
      return {
        type: 'polygon',
        shape: { points: echarts.graphic.clipPointsByRect(points, { x: params.coordSys.x, y: params.coordSys.y, width: params.coordSys.width, height: params.coordSys.height }) },
        style:
          api.style({
            fill: 'rgb(255, 145, 8, 0.7)',
            stroke: 'rgb(255, 145, 8)', lineWidth: 3,
          }),
        styleEmphasis: api.style({ fill: 'rgb(255, 145, 8, 0.7)', stroke: 'rgb(255, 145, 8)', lineWidth: 3 }),
      };
    },
    animation: false,
    data: [{ name: '', value: '', location: v.location, id: v.id }], z: -10
  }))
  const new_series2 = {
    name: '疑似点位',
    type: 'effectScatter',
    coordinateSystem: 'bmap',
    data: pointData,
    showEffectOn: 'emphasis',
    rippleEffect: {
      brushType: 'stroke'
    },
    symbolSize: 20,
    tooltip: {
      formatter: ({ data }) => {
        return (
          `<div>
          <div>疑似点位</div>
          <div>地址：${data.data.address}</div>
          <div>经纬度：${data.data.lng},${data.data.lat}</div>
          <div>出现天数：${data.data.num}</div>
          <div>出现日期：${data.data.begin_date}</div>
          <div>点位原因：${data.data.reason == null ? '暂无' : data.data.reason}</div>
          <div>最近固定源名称：${data.data.instance_name}</div>
          <div>`
        )
      },
    },
    encode: { value: 2 },
    hoverAnimation: true,
    itemStyle: {
      color: 'purple'
    },
  }
  useEffect(() => {
    if (!isChartUsable()) return;
    (!play_begin) ? chart.clear() : null;
    let option = {
      tooltip: { trigger: 'item' }, animation: false,
      bmap: { center: center || [103.836472, 30.719426], zoom: 13, roam: true, mapStyle: {}, },
      toolbox: {
        itemSize: 25, itemGap: 15, top: 15, right: 25, iconStyle: {
          normal: { borderColor: '#000', borderWidth: 2 },
          emphasis: { borderColor: '#FF396E', borderWidth: 2 }
        }
      },
      brush: changeSave === 3 ? null : (
        {
          geoIndex: 0,
          toolbox: ['rect', 'polygon', 'keep', 'clear'],
          brushType: 'rect',
          brushMode: "multiple",
          outOfBrush: { color: '#abc' },
          brushStyle: {
            borderWidth: 2,
            color: 'rgba(0,0,0,0.2)',
            borderColor: 'rgba(0,0,0,0.5)',
          },
          throttleType: 'debounce',
          throttleDelay: 300,
        }
        // {
        //   toolbox: ['rect', 'polygon', 'keep', 'clear'],
        //   outOfBrush: {
        //     color: '#abc'
        //   },
        //   brushStyle: {
        //     borderWidth: 2,
        //     color: 'rgba(0,0,0,0.2)',
        //     borderColor: 'rgba(0,0,0,0.5)',
        //   },
        //   seriesIndex: [0, 1],
        //   throttleType: 'debounce',
        //   throttleDelay: 300,
        //   geoIndex: 0
        // }
      ),
      series: [...series, ...new_series1, new_series, new_series2]
    };
    if (bmap) {
      option.bmap.zoom = bmap.getZoom();
      let lng = bmap.getCenter().lng;
      let lat = bmap.getCenter().lat;
      bound = bmap.getBounds();
      if (bound.containsPoint(new BMap.Point(center[0], center[1])) !== true) {
        option.bmap.center = center;
      }
      else { option.bmap.center = [lng, lat] }
    }
    option.bmap.center = center;
    if (changeCenter) {
      option.bmap.zoom = 14
    }
    chart.setOption(option);
    bmap = chart ? chart.getModel().getComponent('bmap').getBMap() : null;
    if (bmap) {
      drawingManager = new BMapLib.DrawingManager(bmap, {
        isOpen: false, //是否开启绘制模式
        enableDrawingTool: true, //是否显示工具栏
        drawingToolOptions: {
          anchor: BMAP_ANCHOR_TOP_RIGHT, //位置
          offset: new BMap.Size(5, 5), //偏离值
          drawingModes: [//显示的绘制模式
            BMAP_DRAWING_POLYLINE,//线
            BMAP_DRAWING_POLYGON, //多边形
          ]
        },
        open: open
      });
      bmap.addControl(new BMap.MapTypeControl());
    }
  }, [chart, series, mapPoints, locations, changeCenter, pointData, changeSave]);
}

function getDays(strDateStart, strDateEnd) {
  var strSeparator = "-"; //日期分隔符
  var oDate1;
  var oDate2;
  var iDays;
  oDate1 = strDateStart.split(strSeparator);
  oDate2 = strDateEnd.split(strSeparator);
  var strDateS = new Date(oDate1[0], oDate1[1] - 1, oDate1[2]);
  var strDateE = new Date(oDate2[0], oDate2[1] - 1, oDate2[2]);
  iDays = parseInt(Math.abs(strDateS - strDateE) / 1000 / 60 / 60 / 24)//把相差的毫秒数转换为天数
  return iDays;//最终获得的天数
}


function getMapListData(dates, points, showSeries, getStopLineSure) {
  const [leftLoading, setLeftLoading] = useState(false);
  const start = dates[0].format('YYYY-MM-DD HH:mm:ss');
  const end = dates[1].format('YYYY-MM-DD HH:mm:ss');
  const dependencies = [start, end, points];
  const [data, setData] = useState([]);
  // if (showSeries) { points = [] }
  useEffect(() => {
    if (getDays(dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD')) > 6) {
      message.warn('右侧选择日期不能大于一周！')
    }
  }, [dates]);

  useEffect(() => {
    if (!getStopLineSure) return
    if (!isArrayAllValid(dependencies)) return;
    if (points.length === 0) return;
    if (points.length == 2 || points.length == 1) {
      message.warn('请至少画出三个点！')
      return
    }
    const json = { start, end, points }
    setLeftLoading(true);
    TimeJson = json;
    request('/web/geo_area/getStopLine', json)
      .then(body => {
        setData(body.data.data)
      })
      .finally(() => {
        setLeftLoading(false);
      });

  }, [getStopLineSure, points]);
  return [data, leftLoading];
}
function polygonCenter(points) {//多边的中心点
  var lat = 0; var lng = 0;
  if (points === undefined) { return }
  points.forEach(element => {
    lat += Number(element.lat); lng += Number(element.lng)
  });
  return [lng / points.length, lat / points.length]
}
function useSingle(mapList) {
  const [index, setIndex] = useState();
  const dependencies = [mapList, index];
  const plan = useMemo(() => {
    if (!isArrayAllValid(dependencies)) return {};
    const single = { ...mapList[index], index: (index + 1) }
    return single || {};
  }, [dependencies]);
  return [plan, setIndex];
}
let stopPoints = [];//有停靠点就push；

export default function TransportRecall() {
  const { RangePicker } = DatePicker
  const [showSeries, setShowSeries] = useState(false) //显示轨迹，false显示，true清空
  const [changeCenter, setChangeCenter] = useState(false) //更改中心点的zoom
  const [chart, isChartUsable, chartRefGetter] = useEcharts(); //echarts实例
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);//每页条数
  const [point, setPoint] = useState([]);//点位
  const [total, setTotal] = useState();//总数
  const [pointData, setPointData] = useState();
  const [newPointData, setNewPointData] = useState(pointData);
  const [dates, setDates] = useState(defaultDates);//时间
  const [mapPoints, setMapPoints] = useState([]);//绿色区域
  const [series, setSeries] = useState([]); //轨迹
  const [centerZoom, setCenterZoom] = useState({ center: [103.836472, 30.719426], });//中心点
  const [getStopLineSure, setGetStopLineSure] = useState(false) //为true获取右侧列表
  const [mapList, leftLoading] = getMapListData(dates, mapPoints, showSeries, getStopLineSure);//获取右侧列表
  const [allLoading, setAllLoading] = useState(leftLoading) //全局loading
  const [leftData, setLeftData] = useState([]);//右侧列表
  const [single, setSingle] = useSingle(mapList);
  const [keyword, setKeyword] = useState('');
  const [center, setCenter] = useState([103.836472, 30.719426]);
  const [ticket_lines, setTicketLines] = useState();
  const [ticketSeries, setTicketSeries] = useState();
  const [hour, setHour] = useState("1");
  const [rectify, setRectify] = useState(true); //是否轨迹纠偏
  const [show_points, setShowPoints] = useState(false); // 是否显示原始轨迹点
  const [show_stops, setShowStops] = useState(false); //是否显示停靠点
  const [trailBtn, setTrailBtn] = useState({ play_begin: false, visible: false, play_restart: false, slider: 0, moveSpeed: '400', playIndex: 0, movePos: 0 });
  const [save, setSave] = useState({ flag: false, confirmLoading: false, types: 0, name: '' });//历史回溯 长期监控
  const [list, setList] = useState([])
  const [saveFlag, setSaveFlag] = useState(false)
  const [savename, setSavename] = useState('')
  const [changeSave, setChangeSave] = useState(1)
  const [isDel, setIsDel] = useState(false)
  const [editTolong, setEditTolong] = useState(null)
  const [isEditTolong, setIsEditTolong] = useState(false)
  const [del, setDel] = useState()
  const [locations, setLocations] = useState([])
  const [saveTime, setSaveTime] = useState([])
  const [loading, setLoading] = useState(true)
  const [suerDel, setSuerDel] = useState(false)
  const [delOkId, setDelOkId] = useState(null)
  const [singleSure, setSingleSure] = useState(false)
  const [delName, setDelName] = useState('');
  const [pointDelReason, setDelReason] = useState(); // 疑似点位排查的原因
  const reasonOptions = [
    { value: '交通路口', label: '交通路口' },
    { value: '停车场', label: '停车场' },
    { value: '无车辆大量聚集', label: '无车辆大量聚集' },
    { value: '已有点位', label: '已有点位' },
    { value: '建立点位', label: '建立点位' },
  ];
  const [lookSave, setLookSave] = useState();
  const [showButton, setShowButton] = useState(false) //控制下面右侧按钮是否显示
  const [isChangeList, setIsChangeList] = useState(false)
  const [timeFlag, setTimeFlag] = useState(false);

  useEffect(() => {
    setNewPointData(pointData)
  }, [pointData]);


  useEffect(() => {
    const webRTC1 = document.createElement('link');
    webRTC1.rel = 'stylesheet';
    webRTC1.href = `https://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.css`;
    document.head.appendChild(webRTC1);
    const webRTC2 = document.createElement('script');
    webRTC2.type = 'text/javascript';
    webRTC2.src = `https://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js`;
    document.head.appendChild(webRTC2);
  }, []);
  function overlayComplete(e) { //画完之后的操作
    const map_points = e.overlay.getPath();
    setMapPoints(map_points); setLookSave(null); closebottom();
    setCenter(polygonCenter(map_points)); setCenterZoom({ center: polygonCenter(map_points) })
    if (map_points.length > 2) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
    setChangeCenter(true); setGetStopLineSure(true); setShowSeries(false)
    setTimeout(function () { setTicketSeries(); setChangeCenter(false); setGetStopLineSure(false) }, 500)
  }
  useEffect(() => {
    if (singleSure) {
      setAllLoading(true);
      const data = newLeftData.length > 0 ? newLeftData : leftData;
      if (single.index <= data.length) {
        const e = data[single.index - 1];
        request('/web/ticket_map/getTicketLineByCar', { car_num: e.plate_num, start_time: e.time, hour: hour })
          .then(body => {
            const data = body.data.data;
            let ticket_dates = [];
            if (data.ticket_lines.length === (0 || 1) || data.stop_points.length === 0 || data.bind_lines.length === (0 || 1)) {
              message.warn('暂无联单路线');
              closebottom()
            } else {
              setTicketLines(data);
              ticket_dates = data.ticket_lines.map(({ time }) => time);
              setTrailBtn({ ...trailBtn, play_begin: false, visible: true, play_restart: false, slider: ticket_dates, playIndex: 0, movePos: 0 });
            }

          })
          .finally(() => {
            setAllLoading(false);
            if (brushPoint.length > 0) {
              chart.dispatchAction({
                type: 'brush',
                areas: brushPoint.map((e) => {
                  return { brushType: e.brushType, geoIndex: e.geoIndex, range: e.range, }
                })
              })
            }
          })
      }
    }
    setTimeout(() => { setSingleSure(false) }, 500);
  }, [singleSure, hour]);
  useEffect(() => { //控制显示
    if (ticket_lines) {
      let series_data = [];
      rectify ? series_data.push({  // 显示纠偏轨迹 ？ 原始轨迹
        type: 'lines', zlevel: 8, name: 'trailLines', coordinateSystem: 'bmap', polyline: true, silent: true,
        lineStyle: { normal: { color: '#46bee9', opacity: 1, width: 4, }, },
        progressiveThreshold: 500,
        progressive: 200,
        data: [{ coords: coordObjectsToArrays(ticket_lines.bind_lines || []), }]
      }) : series_data.push({
        type: 'lines', coordinateSystem: 'bmap', polyline: true, silent: true, zlevel: 8,
        lineStyle: { normal: { color: '#03d5a0', opacity: 1, width: 3 } },
        progressiveThreshold: 500, progressive: 200,
        data: [{ coords: coordObjectsToArraysByTrans(ticket_lines.ticket_lines || []), }]
      });
      show_points ? series_data.push({//是否显示原始轨迹点
        name: 'carPoints', type: 'scatter', symbol: 'circle', coordinateSystem: 'bmap',
        zlevel: 8, data: coordObjectsToArraysByPoints(ticket_lines.ticket_lines || []),
        symbolSize: [10, 10],
        tooltip: { formatter: function (a, b, c) { return a.data.time + '<br />' + a.data.speed + 'km/h' } },
        itemStyle: { normal: { color: "#5D59E8", opacity: 0.8 } },
      }) : series_data.push({
        name: 'carPoints', type: 'scatter', symbol: 'circle',
        coordinateSystem: 'bmap', zlevel: 5,
        data: coordObjectsToArraysByPoints(ticket_lines.ticket_lines || []),
        symbolSize: [10, 10],
        tooltip: { formatter: function (a, b, c) { return a.data.time + '<br />' + a.data.speed + 'km/h' } },
        itemStyle: { normal: { color: "#5D59E8", opacity: 0 } },
      });
      show_stops ? series_data.push({//是否显示停靠点
        type: 'scatter', coordinateSystem: 'bmap', name: 'overTicket', symbol: 'circle', symbolSize: 15, zlevel: 8,
        itemStyle: { color: '#1491FB', opacity: 1, },
        tooltip: { show: true, formatter: function (a) { return a.data.time + '\n停靠：' + a.data.stay_time + '分钟' } },
        label: {
          show: true, position: 'top', backgroundColor: 'rgba(255, 255, 255, .85)', padding: [2, 5],
          formatter: function (a) { return a.data.time + '\n停靠：' + a.data.stay_time + '分钟' }
        },
        data: coordObjectsToArraysByTicket(ticket_lines.stop_points || []),
      }) : series_data.push({
        type: 'scatter', coordinateSystem: 'bmap', name: 'overTicket',
        symbol: 'circle', symbolSize: 15, itemStyle: { color: '#1491FB', opacity: 0, },
        label: {
          show: true, position: 'top', backgroundColor: 'rgba(255, 255, 255, .65)', padding: [2, 5],
          formatter: function (a) { return a.data.time + '\n停靠：' + a.data.stay_time + '分钟' }
        },
        data: coordObjectsToArraysByTicket(ticket_lines.stop_points || []),
      });
      trailBtn.play_begin ? series_data.push({
        name: 'currscatter', type: 'scatter',
        symbol: 'image:///images/center/a2.png',
        symbolRotate: (360 - ticket_lines.ticket_lines[trailBtn.movePos].direction),
        symbolSize: [15, 42.5],
        coordinateSystem: 'bmap',
        zlevel: 8,
        data: [{ time: ticket_lines.ticket_lines[trailBtn.movePos].time, direction: ticket_lines.ticket_lines[trailBtn.movePos].direction, speed: ticket_lines.ticket_lines[trailBtn.movePos].speed, value: h_common.GPS_transformation(ticket_lines.ticket_lines[trailBtn.movePos].lat, ticket_lines.ticket_lines[trailBtn.movePos].lng) }],
        itemStyle: { normal: { opacity: 1, shadowBlur: 1, shadowColor: '#999966', shadowOffsetX: 1, shadowOffsetY: 1 } },
        tooltip: { formatter: function (a) { return a.name } },
        label: { normal: { show: true, position: 'top', formatter: function (a) { return a.data.time + '\n' + a.data.speed + 'km/h' }, backgroundColor: '#fff', padding: 5 } },
      }) : null;
      if (ticket_lines.ticket_lines.length > 0) {
        series_data.push({
          type: "scatter",
          symbol: 'path://M100 100,A50 50,0 1 1 100 101z M175 100,A25 25,0 1 0 175 101z',
          name: 'start_pointer', symbolSize: 15, zlevel: 9, coordinateSystem: 'bmap',
          data: [{ name: '起点', value: h_common.GPS_transformation(ticket_lines.ticket_lines[0].lat, ticket_lines.ticket_lines[0].lng) }],
          tooltip: { show: false, formatter: function (parmas) { return '起点:' } },
          label: {
            normal: {
              show: true, position: 'top', formatter: function (a, b, c) { return '{arrow|起点}' },
              rich: {
                arrow: {
                  color: '#fff', fontSize: 12, width: 40, height: 26, align: 'center',
                  padding: [5, 0, 0, 0], backgroundColor: { image: '/images/center/diago1.png', repeat: 'no-repeat' },
                  shadowBlur: 1, shadowColor: '#d4d4d4', shadowOffsetX: 1, shadowOffsetY: 1,
                }
              }
            }
          },
          itemStyle: { normal: { color: '#03d5a0', opacity: 1, } }
        });
        series_data.push({
          type: "scatter",
          symbol: 'path://M100 100,A50 50,0 1 1 100 101z M175 100,A25 25,0 1 0 175 101z',
          name: 'end_pointer',
          coordinateSystem: 'bmap',
          symbolSize: 15,
          zlevel: 9,
          tooltip: { show: false, formatter: function (parmas) { return '终点:' } },
          data: [{ name: '终点', value: h_common.GPS_transformation(ticket_lines.ticket_lines[ticket_lines.ticket_lines.length - 1].lat, ticket_lines.ticket_lines[ticket_lines.ticket_lines.length - 1].lng) }],
          label: {
            normal: {
              show: true,
              position: 'top',
              formatter: function (a, b, c) { return '{arrow|终点}' },
              rich: { arrow: { color: '#fff', fontSize: 12, width: 40, height: 26, align: 'center', padding: [5, 0, 0, 0], backgroundColor: { image: '/images/center/diago3.png', repeat: 'no-repeat' }, shadowBlur: 1, shadowColor: '#d4d4d4', shadowOffsetX: 1, shadowOffsetY: 1, } }
            }
          },
          itemStyle: { normal: { color: '#EB3873', opacity: 1, } }
        });
      }
      if (trailBtn.play_begin) {
        let m_c = h_common.GPS_transformation(ticket_lines.ticket_lines[trailBtn.movePos].lat, ticket_lines.ticket_lines[trailBtn.movePos].lng);
        if (!bound.containsPoint(new BMap.Point(m_c[0], m_c[1]))) {
          setCenter(m_c)
        }
      } else {
        if (ticket_lines.ticket_lines.length > 0) {
          setCenter(h_common.GPS_transformation(ticket_lines.ticket_lines[0].lat, ticket_lines.ticket_lines[0].lng))
        }
      }
      setTicketSeries([...series_data])
    }
  }, [ticket_lines, rectify, show_points, show_stops, trailBtn]);
  useEffect(() => { }, [trailBtn]);
  useInterval(() => {
    setTrailBtn({ ...trailBtn, movePos: (trailBtn.movePos + 1), playIndex: (trailBtn.movePos + 1) })
  }, (trailBtn.play_begin && (trailBtn.movePos !== trailBtn.slider.length - 1)) ? trailBtn.moveSpeed : null);
  const SeriesLinesEffect = (data, name, symbolType = 'image:///images/center/a4.png', symbolSize, symbol = ['none', 'arrow'], color, silent = false) => {
    return {
      type: 'lines', name: name, polyline: true, coordinateSystem: 'bmap', symbol: symbol,
      zlevel: 5,
      effect: { show: true, period: 6, trailLength: 0, symbol: symbolType, symbolSize: symbolSize, },
      tooltip: { show: false, },
      lineStyle: {
        normal: {
          color: color,//'#FF9316',
          opacity: 1, width: 2, curveness: 0.5
        },
      },
      progressiveThreshold: 500, progressive: 200, data: data,
      silent,
    }
  };
  const SeriesPoints = (data, name, label, color, symbol, symbolSize, offsetArr, labelShow, silent = false) => {
    return {
      type: 'scatter',
      coordinateSystem: 'bmap',
      labelName: 'label', symbol: symbol, symbolSize: symbolSize,
      zlevel: 3, rippleEffect: { brushType: 'stroke', },
      name,
      label: { normal: { show: labelShow, position: 'inside', offset: offsetArr, color: '#ffffff', formatter: function (a) { return label }, }, },
      itemStyle: { normal: { color, opacity: 1, }, },
      tooltip: { formatter: '{b}' }, data: data,
      silent,
    }
  };

  useEffect(() => {
    stopPoints = [];
    let coords = [];
    let newstopPointsArr = [];
    console.log('mapList,', mapList)

    mapList.forEach(({ points, stop_points, ...e }, index) => {
      if (Array.isArray(points) && points.length > 1) { coords.push({ coords: points.map(({ lng, lat }) => ([lng, lat])) }) }

      if (e.plate_num === '川AAR289') {
        console.log(e,stop_points)
      }
      if (stop_points.length > 0) {
        stop_points.forEach(({ lng, lat, ...res }) => { stopPoints.push({ ...res, index, value: [lng, lat] }) })
      }
    });
    console.log('stopPoints,', stopPoints)
    for (let i = 0; i < stopPoints.length; i += 400) {
      newstopPointsArr.push(stopPoints.slice(i, i + 400))
    }
    if (!showSeries) {
      const arr = newstopPointsArr.map((e, i) => {
        return SeriesPoints(e, 'stopPoints' + i, '停靠点', '#fd322c', 'circle', 10, [], false, true)
      })
      setSeries([
        ...pointSerise,
        ...arr,
        SeriesLinesEffect(coords, 'trailLines', 'image:///images/center/a4.png', [15, 42.5], ['none', 'arrow'], '#FF5152', true)
      ])
    } else { //true
      setSeries([...pointSerise])
      // setMapPoints([])
      setLookSave(null)
    }
    return () => {
      coords = [];
      stopPoints = [];
      // setAllLoading(false);
    }
  }, [mapList, showSeries, brushPoint]);
  useMap(isChartUsable, chart, ticketSeries ? ticketSeries : series, mapPoints, center, centerZoom, trailBtn.play_begin, locations, changeCenter, list, newPointData, changeSave);
  // useOneEchart(isChartUsable1, chart1, oneData)
  useEffect(() => {//地图的操作
    if (drawingManager) { drawingManager.addEventListener('overlaycomplete', overlayComplete); }
  });
  useEffect(() => {//区域图形点击
    if (chart) {
      chart.on('click', function (e) {
        if (e.seriesName === '疑似点位') return;
        let location = e.data.location
        let id = e.data.id
        setMapPoints(location)
        setChangeCenter(true)
        setShowSeries(false)
        setLookSave(id)
        closebottom()
        setShowButton(false)
        setTicketLines(null)
        setGetStopLineSure(true)
        setFilterTime();
        if (location === undefined) {
          closebottom()
        } else {
          setCenter(polygonCenter(location))
        }
        setTimeout(() => {
          setGetStopLineSure(false)
        }, 1000);
      })
    }
  }, [mapPoints]);
  const [isPointOn, setIsPointOn] = useState();
  if (chart) {
    chart.on('click', function (e) {
      if (e.seriesName !== '疑似点位') return;
      setIsPointOn(e);
      const newData = pointData.filter((event) => {
        const a = `${e.value[0]},${e.value[1]}`
        const b = `${event.value[0]},${event.value[1]}`
        return a === b
      }
      )

      setShowButton(true);
      setNewPointData(newData);
      point.forEach((event) => {
        if (`${event.lng},${event.lat}` === `${e.value[0]},${e.value[1]}`) {
          setLookSave(event.id)
        }
      })
    })
  }
  useEffect(() => {
    if (!isPointOn) return;
    setCenter([isPointOn.value[0], isPointOn.value[1]])
    setChangeCenter(true)
    return () => {
      setChangeCenter(false)
    };
  }, [isPointOn]);
  useEffect(() => {//选择，筛选框选停靠点
    if (chart) { chart.on('brushselected', renderBrushed) }
  }, [chart, series, mapPoints, locations, changeSave, leftData]);
  const [brushPoint, setBrushPoint] = useState([]);
  const [newLeftData, setNewLeftData] = useState([]);
  function renderBrushed(params) {
    if (params.batch.length === 0) return;

    setBrushPoint(params.batch[0].areas);
    /** 数据超过400 */
    let newstopPointsArr = [];
    for (let i = 0; i < stopPoints.length; i += 400) { newstopPointsArr.push(stopPoints.slice(i, i + 400)) }
    const arr = newstopPointsArr.map((e, i) => {
      return params.batch[0].selected.filter(({ seriesName }) => seriesName === ('stopPoints' + i))[0];
    });
    let index = [], newStop = [], newLeftData = [];
    arr.forEach((e, i) => {
      const newE = e.dataIndex.map((one) => {
        return one + (400 * i);
      })
      index.push(...newE);
    });
    if (params.batch[0].areas.length === 0) { setNewLeftData([]) };
    if (index.length > 0) {
      index.forEach((v) => {
        newStop.push(stopPoints[v]);
      });
      let arr = [];
      let filterData = []
      newStop.forEach(({ index, ...res }, i) => {
        if (!isInArray(arr, index)) { //看到这里了
          arr.push(index);
          filterData.push({ index, ...res })
        }
      });
      console.log(newStop, filterData, leftData, mapList);

      leftData.forEach((v, i) => {
        filterData.forEach(({ index, ...res }) => {
          if (i === index) { newLeftData.push({ ...v, index }) }
        })
      });
      setNewLeftData(newLeftData);

    } else {
      // mapPoints.length===0?  setLeftData([]):setLeftData(mapList)
    }
  };
  useEffect(() => {
    setLeftData(mapList)
  }, [mapList]);
  function filterChange() {//过滤右侧名单
    let newArr = [];
    if ((keyword === '') || (keyword === undefined)) {
      setLeftData(mapList);
    } else {
      leftData.map((item) => { if (item.plate_num.indexOf(keyword) > -1) { newArr.push(item) } });
      setLeftData(newArr);
    }

  }
  function filterChanges(e) { if (e.keyCode === 13) { filterChange() } }
  function handleOk() { //保存
    if (savename == '') { message.warn('区域名称不能为空!'); return }
    const start1 = dates[0].format('YYYY-MM-DD HH:mm:ss');
    const end1 = dates[1].format('YYYY-MM-DD HH:mm:ss');
    setSave({ //存图形所需
      confirmLoading: true,
      isSave: true,
      name: savename,
      created_by: localStorage.getItem('USER') ? JSON.parse(localStorage.getItem('USER')).id : null,
      created_time: moment().format("YYYY-MM-DD HH:mm:ss"),//当前时间
      times: [start1, end1],
      location: mapPoints,
      types: save.types
    })
    setSaveFlag(false)
    setTimeout(() => { setSave({ ...save, isSave: false, confirmLoading: false, }, setMapPoints([])), setSavename('') }, 1000);
  }

  //查看历史回溯，长期监控
  useEffect(() => {
    if (!isChangeList) return;
    if (changeSave == 3) return;
    setLoading(true);
    request('/web/ticket_map/look', { types: changeSave, times: saveTime })
      .then(body => {
        setList(body.data.data);
        setLoading(false);
        setIsChangeList(false);
      })
  }, [isChangeList, changeSave]);
  const [pointName, setPointName] = useState('');
  function deletePoint(e) {
    setPointDel(true);
    setLookSave(e.id)
    setPointName(e.address)
  }
  function pointOk() {
    if (!pointDelReason) {
      return message.warning('请选择删除原因！')
    }
    request('/rest/ai_point/delete', { id: lookSave, reason: pointDelReason })
      .then((body) => {
        message.success('删除成功！');
        setPointDel(false);
      })
  }
  function pointNo() {
    setPointDel(false);

  }
  const [pointDel, setPointDel] = useState(false);
  useEffect(() => {
    if (changeSave !== 3) return;
    setLoading(true);
    request('/rest/ai_point/list', { page, limit: pageSize, times: saveTime })
      .then(body => {
        const list = body.data.data;
        setPoint(list.data);
        setTotal(list.total);
        setLoading(false);
        if (list.data.length > 0) {
          const a = (list.data).map((item) => {
            return { name: item.address, value: [item.lng, item.lat, item.num], data: item }
          })
          setPointData(a)
          setIsChangeList(false)
        }
      })
  }, [changeSave, pageSize, page, saveTime]);
  useEffect(() => {
    if (!list) return;
    if (changeSave == 2) {
      let allLoation = [];
      list.forEach(item => {
        allLoation.push({
          location: item.location.split('|').map(item => { let a = item.split(','); return ({ 'lng': a[0], 'lat': a[1] }) }),
          name: item.name,
          by: item.created_by,
          id: item.id
        })
      });
      setLocations(allLoation)
    } else if (changeSave == 3) {

    }
  }, [changeSave, list]);
  //添加历史回溯和长期监控
  useEffect(() => {
    if (save.isSave) {
      request('/web/ticket_map/add_to_long', { name: save.name, created_by: save.created_by, created_time: save.created_time, location: save.location, types: save.types, times: save.times }).then(body => {
        if (body.data.code == 200) { message.success('添加成功！'); setShowButton(false) }; setIsChangeList(true)
      })
    }
  }, [save.isSave]);
  //历史回溯添加至长期监控
  useEffect(() => {
    if (isEditTolong) {
      request('/web/ticket_map/edit_to_long', { id: editTolong }).then(body => {
        if (body.data.code == 200) {
          message.success('转移成功!')
          setLookSave(null); setMapPoints([]); setIsChangeList(true); setIsEditTolong(false);
        }
      })
    }
  }, [editTolong]);
  useEffect(() => {//当没有区域的时候，
    if (mapList.length === 0) { setLeftData([]); setNewLeftData([]) }
  }, [mapPoints]);
  //取消保存
  function handleCancel() { setSaveFlag(false), setSavename(''); };
  function onOks(value) {
    setSaveTime([value[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'), value[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')]);
    setPointDate([value[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'), value[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')])
    setLookSave(null)
    setMapPoints([])
    closebottom()
    setShowSeries(true);
    setIsChangeList(true);
  }
  /** 取消按钮 */
  function CancelSave() {
    setMapPoints([]);
    setLookSave(null);
    closebottom();
    setShowSeries(true);//这一行，先closebottom，再setShowSeries（true）
    setShowButton(false);
    setLeftData([]);
  }
  function leftTime(value) {
    if (value.length === 0) {
      setSaveTime([])
      setIsChangeList(true);
      setPointDate(defaultDatesPoint)
    }
  }
  //删除历史回溯，长期监控
  useEffect(() => {
    if (isDel) { request('/web/ticket_map/del', { id: del }).then(body => { if (body.data.code == 200) { message.success('删除成功!') } }) }
    setMapPoints([]); setTimeout(() => { setIsDel(false) }, 1000); setLookSave(null); setShowButton(false); closebottom(); setIsChangeList(true)
  }, [del]);
  useEffect(() => { setMapPoints([]), setPointData([]), setLocations([]) }, [changeSave]);
  function confirms(id) {
    setSuerDel(true); setDelOkId(id);
    list.forEach(item => { if (item.id == id) { setDelName(item.name) } });
  }
  //更新中心点
  useEffect(() => {
    if (changeCenter) {
      list.forEach(item => {
        if (item.id == lookSave) {
          let loc = item.location.split('|').map(i => { let a = i.split(','); return ({ 'lng': Number(a[0]), 'lat': Number(a[1]) }) })
          setCenter(polygonCenter(loc))
        }
      })
    }
    setTimeout(() => { setChangeCenter(false) }, 500);
  }, [changeCenter]);
  function handleClose() { }
  function handleOks() { setSuerDel(false), setDel(delOkId), setIsDel(true) };
  function handleCancels() { setSuerDel(false) }
  function disabledDate(current) {
    if (timeFlag) {
      let time = dates[0]
      if (!current) {
        return false
      } else {
        return current < moment(time).subtract(6, 'days') || current > moment(time).add(6, 'day').endOf('day')
      }
    }
  }
  //右侧时间更改时（onChange）
  function rightTime(e) {
    setDates(e); setTimeFlag(true); closebottom();

  }
  //右侧时间选择重置
  function renderExtraFooter() { return (<Button onClick={() => setTimeFlag(false)} type='primary' size="small">重置</Button>) }
  //关闭轨迹（下侧组件）
  function closebottom() {
    setTrailBtn({ play_begin: false, visible: false, play_restart: false, slider: 0, moveSpeed: '400', playIndex: 0, movePos: 0 });
    setSingle();
    // setShowSeries(false);
    setTimeout(function () {
      setTicketSeries();
    }, 10);
    // setTimeout(function () {
    //   if (brushPoint.length > 0) {
    //     // brushPoint[0].brushType === 'rect' ? chart.dispatchAction({
    //     //   type: 'brush',
    //     //   areas: [{
    //     //     brushType: 'rect',
    //     //     geoIndex: 0,
    //     //     range: brushPoint[0].range,
    //     //   },
    //     //   ]
    //     // }) : null;
    //     chart.dispatchAction({
    //       type: 'brush',
    //       areas: brushPoint.map((e) => {
    //         return { brushType: e.brushType, geoIndex: e.geoIndex, range: e.range, }
    //       })
    //     })
    //     // [{
    //     //   brushType: 'rect',
    //     //   geoIndex: 0,
    //     //   range: brushPoint[0].range,
    //     // },
    //     // ]
    //   }
    // }, 500);


  }
  //查看，回看
  function lookButton(e) {
    let id = e.target.id
    list.forEach(item => {
      if (item.id == id) {
        setLookSave(id)
        setGetStopLineSure(true);
        setChangeCenter(true);
        setShowButton(false);
        closebottom();
        setMapPoints(item.location.split('|').map(item => { let a = item.split(','); return ({ 'lng': a[0], 'lat': a[1] }) }))
      }
    })
    setTimeout(() => {
      setGetStopLineSure(false);
    }, 1000);
  }
  useEffect(() => {//转圈圈
    setAllLoading(leftLoading)
  }, [leftLoading]);
  /** 更改标题 */
  function ChangeType() {
    setTrailBtn({ play_begin: false, visible: false, play_restart: false, slider: 0, moveSpeed: '400', playIndex: 0, movePos: 0 });
    setSingle();
    setTimeout(function () {
      setTicketSeries();
    }, 10);
    setLookSave(null); setShowSeries(true); setIsChangeList(true); setShowButton(false);
    setNewLeftData([]);
    setLeftData([]);
    setFilterTime();
    setBrushPoint([]);
    setTimeout(() => {
      chart.dispatchAction({
        type: 'brush',
        areas: []
      })

    }, 550);

  }
  /** 加至长期监控 */
  function lookButtonToLang(id) { setEditTolong(id), setIsEditTolong(true); setShowButton(false) }

  const [exportList, setExpportList] = useState([]);
  useEffect(() => {
    if (leftData.length === 0) return;
    const list = [];
    if (newLeftData.length > 0) {
      newLeftData.forEach(item => {
        list.push({
          company_name: item.info.company_name,
          plate_num: item.info.plate_num,
          time: item.time,
          area_name: item.info.area_name,
        })
      })
    } else {
      leftData.forEach(item => {
        list.push({
          company_name: item.info.company_name,
          plate_num: item.info.plate_num,
          time: item.time,
          area_name: item.info.area_name,
        })
      })
    }

    setExpportList(list)
  }, [newLeftData, leftData]);
  //
  const [filterTime, setFilterTime] = useState();
  function timeChange(e) { if (e.keyCode === 13) { timeChangeTrue() } }
  function timeChangeTrue(e) {
    setAllLoading(true);
    request('/web/geo_area/getStopLine', { ...TimeJson, polymerize: filterTime })
      .then(body => {
        setLeftData(body.data.data)
        setShowSeries(false)
      })
      .finally(() => {
        setAllLoading(false);
      });
  }
  function changeTime(e) {
    const { value } = e.target;
    const reg = /[1-9]{1,3}/;
    if ((!isNaN(value) && reg.test(value) && value.indexOf('.') < 0) || value === '') {
      setFilterTime(value)
    }
  }
  function tableToExcel(exportList) {
    //列标题
    let str = '<tr><td>运企名称</td><td>车牌号</td><td>起点时间</td><td>属地</td></tr>';
    //循环遍历，每行加入tr标签，每个单元格加td标签
    for (let i = 0; i < exportList.length; i++) {
      str += '<tr>';
      for (let item in exportList[i]) {
        //增加\t为了不让表格显示科学计数法或者其他格式
        str += `<td>${exportList[i][item] + '\t'}</td>`;
      }
      str += '</tr>';
    }
    //Worksheet名
    let worksheet = '源头回溯数据'
    let uri = 'data:application/vnd.ms-excel;base64,';
    //下载的表格模板数据
    let template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" 
    xmlns:x="urn:schemas-microsoft-com:office:excel" 
    xmlns="">
    <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"> 
    <!--[if gte mso 9]>
    <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
      <x:Name>${worksheet}</x:Name>
      <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
      </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
      </head><body><table>${str}</table></body></html>`;
    //下载模板
    var link = uri + base64(template);
    var a = document.createElement("a");
    a.download = '源头回溯数据' + ".xls";
    a.href = link;
    a.click();
    a.remove();
  }
  const size = ChangeSize();
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (!size) return;
    const a = size.height - 350
    setHeight(a)
  }, [size]);
  //输出base64编码
  function base64(s) { return window.btoa(unescape(encodeURIComponent(s))) }
  function pointBtn(item) {
    setLookSave(item.id);
    setCenter([item.lng, item.lat]);
    setShowButton(true);
    const newData = pointData.filter((event) => {
      const a = `${item.lng},${item.lat}`
      const b = `${event.value[0]},${event.value[1]}`
      return a === b
    }
    )
    setNewPointData(newData);
    setChangeCenter(true);
    setTimeout(() => {
      setChangeCenter(false)
    }, 300);
  }
  function pointGoBack() {
    setMapPoints([]); setLookSave(null);
    closebottom();
    setShowSeries(true);//这一行，先closebottom，再setShowSeries（true）
    setShowButton(false);
    setNewPointData(pointData);
  }
  const [right_flag, setRightFlag] = useState([]);
  const [pointsParams, setPointsParams] = useState({ build: 10, sand: 10, concert: 10, consume: 10 });
  const [top_flag, setTopFlag] = useState([1, 2, 3, 4, 5]);//顶部四条线路的显示与隐藏
  const [linesFlag, setLinesFlag] = useState(false);
  const [pointDate, setPointDate] = useState(defaultDatesPoint)
  const plans = usePlans(pointDate);
  const [plan, selectPlan] = usePlan(plans.sourceList);//疑似消纳场
  const details = useDetails(plan, pointDate, hour);
  const [dataSource, setDataSource] = useState([]);//useSource(plans.list);
  const [ticketOver, setTicketOver] = useState();
  const [linesSource, setLinesSource] = useState();
  const [countLines, setCountLines] = useState([{ sum: 0, volumn: 0 }, { sum: 0, volumn: 0 }, { sum: 0, volumn: 0 }, { sum: 0, volumn: 0 }, { sum: 0, volumn: 0 }]);
  const storeInfo = store.getState();
  const { info: { area_name } } = storeInfo;
  const [pointSerise, setPointSerise] = useState([]);
  const returnLineWidth = (num) => { return num < 10 ? 1 : num >= 10 && num < 20 ? 2 : num >= 20 && num < 30 ? 3 : num >= 40 && num < 50 ? 4 : 5; }

  function getRightType(type) {
    let [...newType] = right_flag;
    if (!isInArray(newType, type)) {
      if (type === 0) {
        newType = [0];
        setPointsParams({ build: 10, sand: 10, consume: 10, concert: 10 })
      }

    } else {
      newType.remove(type)
    }
    setLinesFlag(false);
    setRightFlag(newType);
  }
  const sourceList = (color, data, linesFlag) => {
    let sourceListArr = [], pointsData = [], sandData = [], concertData = [], consumeData = [], waitData = [], suspiciousData = [], otherData = [], factoryData = [], l1 = 0, l6 = 0, l7 = 0, l8 = 0, l9 = 0;
    const build = pointsParams.build, sand = pointsParams.sand, consume = pointsParams.consume, concert = pointsParams.concert, factory = pointsParams.factory;
    if (linesFlag) {
      const r0 = isInArray(right_flag, 0), r1 = isInArray(right_flag, 1), r9 = isInArray(right_flag, 9),
        r10 = isInArray(right_flag, 10), r11 = isInArray(right_flag, 11), r12 = isInArray(right_flag, 12),
        r13 = isInArray(right_flag, 13), r15 = isInArray(right_flag, 15), r16 = isInArray(right_flag, 16),
        r17 = isInArray(right_flag, 17);
      const { start_id, start_department_id, end_id, end_department_id } = linesSource;
      data.map(({ consum_type, time_status, point_type, open_status, status, type, lng, lat, ...item }, i) => {
        //  consum_type 0 - 待排查 1 - 非法点位 2 - 非违规区域
        //  point_type 点位特征值 1 - 疑似点位 2 - 工地 3 - 砂石厂 4 - 消纳厂 5 - 商砼站 6 - 其他
        //  time_status 1 - 无时间期限 2 - 有时间期限 3 - 超时间期限
        if ((item.id === start_id && item.department_id === start_department_id) || (item.id === end_id && item.department_id === end_department_id)) {
          if (point_type === 2) {
            if (open_status === 1) {
              if (status === 2) {
                l6++;
                (r1 || r9) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/green1.png', symbolSize: [42, 42] });
              } else if (status === 3) {
                l7++;
                (r1 || r10) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/orange1.png', symbolSize: [42, 42] })
              } else if (status === 7) {
                l8++;
                (r1 || r11) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/red1.png', symbolSize: [42, 42] })
              } else {
                l1++;
                (r1 || r12) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/yellow1.png', symbolSize: [42, 42] })
              }
            } else {
              l9++;
              r13 && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/gray1.png', symbolSize: [42, 42] })
            }
          }
          if (consum_type === 0) {
            (r0 || r15) && waitData.push({ symbol: pointsImages[0], symbolSize: [31, 40], value: [lng, lat], point_type, open_status, status, consum_type, time_status, type, ...item })
          } else {
            if (consum_type && point_type && time_status) {
              const point = { symbol: pointsImages[consum_type][point_type][time_status], symbolSize: imagesSize, point_type, open_status, status, value: [lng, lat], consum_type, time_status, type, ...item }
              switch (point_type) {
                case 1: (r0 || r16) && suspiciousData.push(point); break;
                case 2: (r0 || build === 0) ? pointsData.push(point) : build === consum_type ? pointsData.push(point) : null; break;
                case 3: (r0 || sand === 0) ? sandData.push(point) : sand === consum_type ? sandData.push(point) : null; break;
                case 4: (r0 || consume === 0) ? consumeData.push(point) : consume === consum_type ? consumeData.push(point) : null; break;
                case 5: (r0 || concert === 0) ? concertData.push(point) : concert === consum_type ? concertData.push(point) : null; break;
                case 6: (r0 || r17) && otherData.push({ ...point, symbolSize: [31, 40] }); break;
                case 7: (r0 || factory === 0) ? factoryData.push(point) : concert === consum_type ? factoryData.push(point) : null; break;
              }
            }
          }
        }
      });
    } else {
      [pointsData, sandData, concertData, consumeData, waitData, suspiciousData, otherData, factoryData, l1, l6, l7, l8, l9] = instanceClassify(0, data);
    }
    sourceListArr.push(
      UseMapAnchorage({ data: DuplicateRemoval(pointsData.slice(0, 2000)), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(sandData), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(concertData), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(consumeData), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(waitData), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(suspiciousData), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(otherData), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(pointsData.slice(2000, 4000)), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(pointsData.slice(4001, 6000)), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
      UseMapAnchorage({ data: DuplicateRemoval(factoryData), symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }),
    );
    return sourceListArr
  };
  useEffect(() => {
    let new_series = [];
    let LinesSum = [];
    if (!plan.id) {
      setDataSource(plans.list);
      new_series = sourceList('#22B74F', plans.sourceList, linesFlag); // 获取地图上的点位显示与否
      const r0 = isInArray(right_flag, 0), r1 = isInArray(right_flag, 1), r4 = isInArray(right_flag, 4), r5 = isInArray(right_flag, 5), r6 = isInArray(right_flag, 6)
      const t1 = isInArray(top_flag, 1), t2 = isInArray(top_flag, 2), t3 = isInArray(top_flag, 3), t4 = isInArray(top_flag, 4), t5 = isInArray(top_flag, 5);

      // plans.elseList 未知终点联单 没有终点 点击查看该联单详情
      if (plans.elseList.length > 0 && (r5 || r0)) {
        let coords = [], endPointer = [];
        let instanceData = [];
        plans.elseList.map(({ start_id, start_department_id, end_id, end_department_id, volumn, num = 1, ...data }) => {
          endPointer.push({ value: [data.end_address.lng, data.end_address.lat], start_id, start_department_id, end_id, end_department_id, volumn, num, ...data });
          if (data.start_address && data.start_address.lng > 3 && data.end_address.lng > 3) {
            coords.push({
              name: data.transport_name,
              start_name: data.start_address.name,
              lineStyle: { width: returnLineWidth(num) },
              end_name: data.end_address.name,
              volumn: volumn,
              coords: [[data.start_address.lng, data.start_address.lat], [data.end_address.lng, data.end_address.lat]], ...data
            });
            if (r5 && r4) {
              plans.sourceList.map(({ id, department_id, lng, lat, type, status, open_status, consum_type, point_type, time_status, ...list }) => {
                if ((start_id === id && start_department_id === department_id) || (end_id === id && end_department_id === department_id)) {
                  if (consum_type === 0) {
                    instanceData.push({ symbol: pointsImages[0], symbolSize: [31, 40], id, department_id, value: [lng, lat], type, open_status, status, consum_type, point_type, time_status, ...list })
                  }
                  if (consum_type && point_type && time_status) {
                    const point = { value: [lng, lat], id, department_id, type, status, open_status, consum_type, point_type, time_status, symbol: pointsImages[consum_type][point_type][time_status], symbolSize: imagesSize, ...list }
                    if (pointsParams.build === 10) {
                      if (type === 'building') {
                        if (open_status === 1) {
                          if (status === 2) {
                            instanceData.push({ ...point, symbol: 'image:///images/center/green1.png', symbolSize: [42, 42] });
                          } else if (status === 3) {
                            instanceData.push({ ...point, symbol: 'image:///images/center/orange1.png', symbolSize: [42, 42] });
                          } else if (status === 7) {
                            instanceData.push({ ...point, symbol: 'image:///images/center/red1.png', symbolSize: [42, 42] })
                          } else {
                            instanceData.push({ ...point, symbol: 'image:///images/center/yellow1.png', symbolSize: [42, 42] })
                          }
                        } else {
                          instanceData.push({ ...point, symbol: 'image:///images/center/gray1.png', symbolSize: [42, 42] })
                        }
                      }
                      else {
                        instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
                      }
                    } else {
                      instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
                    }
                  }
                }
              })
            }
          }
        });
        // t4 && new_series.push(simplePass({
        //   name: 'plans_elseList', lineColor: '#FF9B1D', label: {
        //     show: true, formatter: (a) => {
        //       return `方量: ${a.data.volumn}`
        //     }
        //   }, data: coords, tooltip: {
        //     formatter: function (a) {
        //       return '起点: ' + a.data.start_name + '</br>终点: ' + a.data.end_name + '</br>方量: ' + a.data.volumn
        //     }
        //   }
        // }));
        r5 && new_series.push({ ...UseMapAnchorage({ data: instanceData, symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }) });
      }
      if (plans.ticketLines.length > 0 && (r6 || r0)) {
        let m_arr = [], pointer = [];
        let m_index = 0;
        plans.ticketLines.map((v, i) => {
          if (v.points.length > 1) {
            m_arr.push({ coords: [] });
            v.points.map(({ lng, lat, ...data }, j) => {
              m_arr[m_index].coords.push(h_common.GPS_transformation(lat, lng));
              if (j === 0) {
                pointer.push({ value: h_common.GPS_transformation(lat, lng), ...data, name: '起点' })
              }
            });
            m_index++;
          }
        });
        if (t1) {
          // new_series.push(UseMapLines({ name: 'plansTicketLines', polyline: true, silent: true, symbol: ['none', 'arrow'], effect: true, lineColor: '#FF5152', lineWidth: 2, curveness: 0.5, trailLength: 0, effectSymbol: 'image:///images/center/a4.png', effectSymbolSize: [15, 42.5], data: m_arr }));
          // new_series.push(StartOrEndPoint({ name: 'start_pointer', symbol: 'circle', data: DuplicateRemoval(pointer), format: '起点', backImage: '/images/center/diago1.png', itemColor: '#03d5a0' }));
        }
        if ((r6 || r0) && t1) {
          let instanceData = [];
          plans.sourceList.map(({ id, department_id, type, lng, lat, open_status, status, consum_type, point_type, time_status, ...list }) => {
            plans.ticketSource.map(({ instance_id, ...data }) => {
              if (id === instance_id && department_id === data.department_id) {
                if (consum_type === 0) {
                  instanceData.push({ symbol: pointsImages[0], symbolSize: [31, 40], id, department_id, value: [lng, lat], type, open_status, status, consum_type, point_type, time_status, ...list })
                }
                if (consum_type && point_type && time_status) {
                  const point = { value: [lng, lat], id, department_id, type, status, open_status, consum_type, point_type, time_status, symbol: pointsImages[consum_type][point_type][time_status], symbolSize: imagesSize, ...list }
                  if (pointsParams.build === 10) {
                    if (type === 'building') {
                      if (open_status === 1) {
                        if (status === 2) {
                          instanceData.push({ ...point, symbol: 'image:///images/center/green1.png', symbolSize: [42, 42] });
                        } else if (status === 3) {
                          instanceData.push({ ...point, symbol: 'image:///images/center/orange1.png', symbolSize: [42, 42] });
                        } else if (status === 7) {
                          instanceData.push({ ...point, symbol: 'image:///images/center/red1.png', symbolSize: [42, 42] })
                        } else {
                          instanceData.push({ ...point, symbol: 'image:///images/center/yellow1.png', symbolSize: [42, 42] })
                        }
                      } else {
                        instanceData.push({ ...point, symbol: 'image:///images/center/gray1.png', symbolSize: [42, 42] })
                      }
                    }
                    else {
                      instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
                    }
                  } else {
                    instanceData.push({ ...point, symbolSize: point_type === 0 || point_type === 6 ? [31, 40] : point.symbolSize })
                  }
                }
              }
            })
          });
          // new_series.push(...returnSourceList(data1, data2, data3, data4, data5, data6, data7, data8, data9, 'new_ticketPoint'))
          r6 && new_series.push({ ...UseMapAnchorage({ data: instanceData, symbol: 'image:///images/center/green1.png', name: 'new_ticketPoint' }) });
        }
      }
      let data_b = [], data_s = [], data_c = [], data_e = [];
      LinesSum.push({ sum: plans.ticketLines.length, volumn: getTicketLinesVolumn(plans.ticketLines) });
      plans.list.map(({ end_address: { type }, ...data }) => {
        if (type === 'building' || type === 'concrete') {
          data_b.push(data)
        }
        else if (type === 'sand_factory') {
          data_s.push(data)
        }
        else if (type === 'consum') {
          data_c.push(data)
        }
        else {
          data_e.push(data)
        }
      });
      const a = getDataByParams(1, data_b), b = getDataByParams(1, data_s), c = getDataByParams(1, data_c), d = getDataByParams(1, data_e);

      LinesSum.push({ sum: getDataByParams(2, data_b), volumn: !isDot(a) ? a : a.toFixed(2) });
      LinesSum.push({ sum: getDataByParams(2, data_s), volumn: !isDot(b) ? b : b.toFixed(2) });
      LinesSum.push({ sum: getDataByParams(2, data_c), volumn: !isDot(c) ? c : c.toFixed(2) });
      LinesSum.push({ sum: getDataByParams(2, data_e), volumn: !isDot(d) ? d : d.toFixed(2) });
      setCountLines(LinesSum);
      if (plans.suspectConsum.length > 0 && (isInArray(right_flag, 7) || isInArray(right_flag, 0))) {
        new_series.push(UseMapScatter({
          data: plans.suspectConsum.map(({ point, ...data }) => ({ value: point, ...data })),
          name: 'suspectConsum',
          symbolSize: 16,
          color: '#000000', zlevel: 3,
          tooltip: {
            formatter: function (a, b, c) {
              return a.data.name;
            }
          },
          type: 'effectScatter',
          symbol: 'path://M100 100,A50 50,0 1 1 100 101z M175 100,A25 25,0 1 0 175 101z',
          // itemStyle: {
          //   color: '#000000'
          // },
        }))
      }
      setPointSerise([...new_series]);
      const newSeries = series.filter(({ name }) => name === 'trailLines');
      // const 
      /** 数据超过400 */
      let newstopPointsArr = [];
      for (let i = 0; i < stopPoints.length; i += 400) { newstopPointsArr.push(stopPoints.slice(i, i + 400)) }
      const pointarr = newstopPointsArr.map((e, i) => {
        return series.filter(({ name }) => name === 'stopPoints' + i)[0];
      });
      setSeries([...newSeries, ...pointarr, ...new_series]);

    }
    else {
      let arr = [];
      let points = [];
      if (details) {
        details.ticket_lines.map(line => {
          line.map((v) => { arr.push(h_common.GPS_transformation(v.lat, v.lng)) })
        })
      }
      const detailSeries = !details ? [] : [
        // 无线路联单
        {
          type: 'lines',
          coordinateSystem: 'bmap',
          // zlevel: 2,
          symbol: ['none', 'arrow'],
          symbolSize: 10,
          effect: {
            show: true,
            period: 6,
            trailLength: 0,
            symbol: 'image:///images/center/a1.png',
            symbolSize: [15, 42.5],
          },
          lineStyle: {
            normal: {
              color: '#5D59E8',
              width: 3,
              opacity: 0.6,
              curveness: 0.2,
            },
          },
          data: (details.tickets || []).map(({ lng, lat }) => ({
            coords: [[plan.lng, plan.lat], [lng, lat]],
          })),
        },
        // 联单线路
        {
          type: 'lines',
          coordinateSystem: 'bmap',
          effect: {
            show: true,
            period: 12,
            trailLength: 0,
            symbol: 'image:///images/center/a2.png',
            symbolSize: [15, 42.5],
          },
          polyline: true,
          silent: true,
          lineStyle: {
            normal: {
              color: '#5A94DF',
              opacity: 1,
              width: 4,
            },
          },
          progressiveThreshold: 500,
          progressive: 200,
          data: (details.ticket_lines || []).map(line => ({
            coords: coordObjectsToArraysByTrans(line),
          })),
        },
        {
          type: 'scatter',
          coordinateSystem: 'bmap',
          symbol: 'circle',
          symbolSize: 2,
          itemStyle: {
            color: '#FF9316',
            opacity: 1,
          },
          tooltip: {
            formatter: '{b}'
          },
          data: arr,
        },
        // 疑似停靠
        {
          type: 'scatter',
          coordinateSystem: 'bmap',
          symbol: 'circle',
          symbolSize: 8,
          itemStyle: {
            color: '#FA2925',
            opacity: 1,
          },
          tooltip: {
            formatter: '{b}'
          },
          data: coordObjectsToArraysByTrans(details.stop_points || []),
        },
      ];

      setSeries([
        // 起点：工地
        location('#D81159', [{
          type: 'start',
          name: plan.name,
          value: [plan.lng, plan.lat],
        }]),
        ...detailSeries,
        ...newSeries,
      ]);
    }

  }, [chart, plans, plan, details, ticketOver, right_flag, linesSource, top_flag, JSON.stringify(pointsParams)], series);
  function instanceClassify(type, data) {
    const pointsData = [], concertData = [], suspiciousData = [], otherData = [], waitData = [], sandData = [], consumeData = [], factoryData = []; //工地icon 商砼 疑似点位 其他点位 待排查点位
    let l1 = 0, l6 = 0, l7 = 0, l8 = 0, l9 = 0;
    const r0 = isInArray(type ? right_flag1 : right_flag, 0), r1 = isInArray(type ? right_flag1 : right_flag, 1), r9 = isInArray(type ? right_flag1 : right_flag, 9),
      r10 = isInArray(type ? right_flag1 : right_flag, 10), r11 = isInArray(type ? right_flag1 : right_flag, 11), r12 = isInArray(type ? right_flag1 : right_flag, 12),
      r13 = isInArray(type ? right_flag1 : right_flag, 13), r15 = isInArray(type ? right_flag1 : right_flag, 15), r16 = isInArray(type ? right_flag1 : right_flag, 16),
      r17 = isInArray(type ? right_flag1 : right_flag, 17);
    const build = type ? trailParams.build : pointsParams.build, sand = type ? trailParams.sand : pointsParams.sand,
      consume = type ? trailParams.consume : pointsParams.consume, concert = type ? trailParams.concert : pointsParams.concert,
      factory = type ? trailParams.factory : pointsParams.factory;

    data.map(({ consum_type, time_status, point_type, open_status, status, type, lng, lat, ...item }, i) => {
      //  consum_type 0 - 待排查 1 - 非法点位 2 - 非违规区域
      //  point_type 点位特征值 1 - 疑似点位 2 - 工地 3 - 砂石厂 4 - 消纳厂 5 - 商砼站 6 - 其他
      //  time_status 1 - 无时间期限 2 - 有时间期限 3 - 超时间期限
      //
      if (point_type === 2 && item.area_name === area_name) {
        if (open_status === 1) {
          if (status === 2) {
            l6++;
            (r1 || r9) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/green1.png', symbolSize: [42, 42] });
          } else if (status === 3) {
            l7++;
            (r1 || r10) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/orange1.png', symbolSize: [42, 42] })
          } else if (status === 7) {
            l8++;
            (r1 || r11) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/red1.png', symbolSize: [42, 42] })
          } else {
            l1++;
            (r1 || r12) && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/yellow1.png', symbolSize: [42, 42] })
          }
        } else {
          l9++;
          r13 && pointsData.push({ consum_type, time_status, point_type, open_status, status, type, value: [lng, lat], ...item, symbol: 'image:///images/center/gray1.png', symbolSize: [42, 42] })
        }
      }
      if (consum_type === 0) {
        (r0 || r15) && waitData.push({ symbol: pointsImages[0], symbolSize: [31, 40], value: [lng, lat], point_type, open_status, status, consum_type, time_status, type, ...item })
      } else {
        if (point_type === 1) {
          (r0 || r16) && suspiciousData.push({ symbol: pointsImages[consum_type][point_type], symbolSize: [31, 40], value: [lng, lat], point_type, open_status, status, consum_type, time_status, type, ...item });
        }
        else if (consum_type && point_type && time_status) {
          const point = { symbol: pointsImages[consum_type][point_type][time_status], symbolSize: imagesSize, point_type, open_status, status, value: [lng, lat], consum_type, time_status, type, ...item }
          switch (point_type) {
            // case 1: (r0 || r16) && suspiciousData.push(point); break;
            case 2: (r0 || build === 0) ? pointsData.push(point) : build === consum_type ? pointsData.push(point) : null; break;
            case 3: (r0 || sand === 0) ? sandData.push(point) : sand === consum_type ? sandData.push(point) : null; break;
            case 4: (r0 || consume === 0) ? consumeData.push(point) : consume === consum_type ? consumeData.push(point) : null; break;
            case 5: (r0 || concert === 0) ? concertData.push(point) : concert === consum_type ? concertData.push(point) : null; break;
            case 6: (r0 || r17) && otherData.push({ ...point, symbolSize: [31, 40] }); break;
            case 7: (r0 || factory === 0) ? factoryData.push(point) : concert === consum_type ? factoryData.push(point) : null; break;
          }
        }
      }
    });
    return [pointsData, sandData, concertData, consumeData, waitData, suspiciousData, otherData, factoryData, l1, l6, l7, l8, l9]
  }
  function getTicketLinesVolumn(data) {
    let num = 0;
    data.map((v) => {
      if (v.volumn) {
        num += parseFloat(v.volumn)
      }
    });
    return num === 0 ? 0 : num.toFixed(1);
  }
  // 合计方量/趟数 1 - 方量 2 - 趟数
  function getDataByParams(type, dataSource) {
    let number = 0;
    switch (type) {
      case 1:
        dataSource.map(({ volumn }) => { volumn ? number += parseFloat(volumn) : number });
        break;
      case 2:
        dataSource.map(({ num }) => { num ? number += parseFloat(num) : number })
        break;
    }
    return number;
  }
  function isDot(num) {
    return (num.toString()).indexOf(".") > -1;
  }
  useEffect(() => {
    if (brushPoint.length > 0) {
      chart.dispatchAction({
        type: 'brush',
        areas: brushPoint.map((e) => {
          return { brushType: e.brushType, geoIndex: e.geoIndex, range: e.range, }
        })
      })
    }
  }, [series, brushPoint]);
  return (
    <div className={styles.container}>
      <Spin size="large" wrapperClassName={styles.mySpin} style={{ width: '100%', height: '100%' }} spinning={allLoading} > </Spin>
      <div style={{ display: 'flex', flexGrow: 1, width: '100%', position: 'relative' }}  >
        <div className={styles.save}>
          <div style={{ borderBottom: '1px solid #d4d4d4', marginBottom: 10 }}>
            <p style={{ marginBottom: 18 }}>
              <span onClick={() => { setChangeSave(1); ChangeType() }} style={{ color: changeSave == 1 ? 'inherit' : '#AAAAAA', cursor: 'pointer' }}>历史回溯</span>
              <span onClick={() => { setChangeSave(2); ChangeType() }} style={{ marginLeft: 23, color: changeSave == 2 ? 'inherit' : '#AAAAAA', cursor: 'pointer' }}>长期监控</span>
              <span onClick={() => { setChangeSave(3); ChangeType() }} style={{ marginLeft: 23, color: changeSave == 3 ? 'inherit' : '#AAAAAA', cursor: 'pointer' }}>疑似点位排查</span>
            </p>
            <RangePicker onChange={leftTime} showTime={{ format: 'HH' }} onOk={onOks} suffixIcon={<Icon type="down" />}
              format="YYYY-MM-DD" style={{ width: 312, marginBottom: 18 }} allowClear={true} placeholder={['请选择开始时间', '请选择结束时间']} />
          </div>
          <div style={{ height: (changeSave === 3 && trailBtn.visible) ? height + 6 : height + 54, overflowY: 'auto', paddingTop: 10 }} className={styles.saveOver}>
            <Spin spinning={loading}>
              {
                (list.length > 0 || point.length > 0) ?
                  ((changeSave == 3) ?
                    (<div>
                      {point.map((item, index) => {
                        return (
                          <div className={styles.history} style={{ background: item.id == lookSave ? '#EBF9FF' : '#fafafa', height: 151, }} key={item.id}>
                            <p onClick={() => { deletePoint(item) }}><Icon type="delete" /></p>
                            <div className={styles.name} style={{ marginBottom: 20 }} title={item.address}>点位名称：{item.address}</div>
                            <div className={styles.smallName}>最近固定源名称：<span className={styles.ellipsis} title={item.instance_name}>{item.instance_name}</span></div>
                            <div className={styles.smallName}>出现日期：{item.begin_date}~{item.end_date}</div>
                            <div className={styles.smallName}>发现天数：{item.num}</div>
                            <div className={styles.smallName}>经纬度：{item.lng},{item.lat}</div>
                            <div className={styles.smallName} title={item.car_lisences} style={{ width: 284, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>车辆：{item.car_lisences}</div>
                            <Button className={styles.botton} style={{ right: 0, position: 'absolute', bottom: 27, background: '#973196' }} onClick={() => { pointBtn(item) }}>查看位置</Button>
                          </div>
                        )
                      })}

                    </div>) :
                    (list.map((item, index) => {
                      return <div className={styles.history} key={item.id} style={{ background: item.id == lookSave ? '#EBF9FF' : '#fafafa' }}>
                        <div style={{ height: 126 }}>
                          <p onClick={confirms.bind(this, item.id)}><Icon type="delete" /></p>
                          <div className={styles.name} title={item.name}>保存名:{item.name}</div>
                          <div className={styles.smallName}>保存时间:{item.created_time}</div>
                          <div className={styles.smallName}>
                            <span>操作人:{item.created_by}</span>
                            <div style={{ width: 143, float: 'right' }}>
                              {(changeSave === 1) ? <Button className={styles.botton} style={{ background: '#F59A23' }} onClick={() => { lookButtonToLang(item.id) }}>长期监控</Button> : <div style={{ width: 76, height: 24, float: 'left' }} className={styles.langdiv}></div>}
                              <Button id={item.id} className={styles.botton} style={{ marginRight: 5, background: '#02A7F0' }} onClick={lookButton}>回看</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    }))) :
                  <div style={{ textAlign: 'center' }}>暂无数据</div>
              }
            </Spin>
          </div>
          <Pagination
            size="small"
            className={styles.paging}
            total={total}
            style={{ display: changeSave === 3 ? 'block' : 'none' }}
            current={page}
            pageSize={pageSize}
            onChange={(page) => { setPage(page); pointGoBack() }}
            onShowSizeChange={(_, size) => { setPageSize(size); pointGoBack() }}
            showSizeChanger
          />
        </div>
        <div className={styles.chart} ref={chartRefGetter} />
        <div className={styles.overlays}  >
          <div className={styles.all_div} onClick={() => { getRightType(0) }}>
            <Tooltip placement="left" title="全部点位">
              <span className={isInArray(right_flag, 0) ? styles.active : ''}>全</span>
            </Tooltip>
          </div>
          <div className={styles.overTop} >
            <div className={styles.flexFixed} >
              <div className={styles.flexFixed, styles.bottomTop} style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Input type="text" placeholder="输入车牌号" onChange={(e) => setKeyword(e.target.value)} onKeyUp={filterChanges} className={styles.input} />
                <div className={styles.overSearch} onClick={filterChange} >
                  <div className={styles.search} ></div>
                </div>
              </div>
            </div>
            <div className={styles.bottomTop}>
              <DatePicker.RangePicker className={styles.dates} style={{ width: 284 }} allowClear={false}
                size="default" showTime={true} format="YYYY-MM-DD HH:mm:ss" suffixIcon={<Icon type="down" />}
                disabledDate={disabledDate}
                renderExtraFooter={renderExtraFooter}
                value={dates}
                onChange={rightTime}
                onOk={() => {
                  setGetStopLineSure(true);
                  setTimeout(() => {
                    setGetStopLineSure(false)
                  }, 1000);
                }} onOpenChange={() => setTimeFlag(false)} />
            </div>
            <div style={{ fontSize: 14, marginTop: 27 }}>
              <span> 车次:{newLeftData.length > 0 ? newLeftData.length : leftData.length}</span>
              <span style={{ marginLeft: 27 }}> 预估方量：{(newLeftData.length > 0 ? newLeftData.length : leftData.length) * 24}方</span>
              <Button title='导出源头回溯数据' style={{ float: 'right', marginTop: '-11px' }} type="primary" onClick={() => { tableToExcel(exportList); }} disabled={leftData.length === 0}>导出</Button>
            </div>
          </div>
          <div className={`${styles.flexGrow} ${styles.webTum}`} style={{ overflowY: 'auto', maxHeight: height }}>
            <div className={styles.flexFixed, styles.bottomTop} style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Input value={filterTime} title='搜索去重时间' type="text" placeholder="去重时间(单位：分钟)，已默认30分钟" onChange={(e) => { changeTime(e) }} onKeyUp={timeChange} className={styles.input} style={{ width: 280, marginLeft: 2 }} disabled={leftData.length === 0} />
              <div className={styles.overSearch} onClick={leftData.length === 0 ? null : timeChangeTrue} >
                <div className={styles.search} ></div>
              </div>
            </div>
            <div style={{ padding: '10px 10px 0', maxHeight: trailBtn.visible ? 575 : 576 }}>
              {
                leftData.length > 0 ?
                  newLeftData.length > 0 ?
                    newLeftData.map(({ info: { plate_num, area_name, company_name, car_type }, time, speed, ...data }, i) => (
                      <div className={styles.overSpeedItem} style={{ padding: 0, marginTop: 10, color: single.index === (i + 1) ? '#FF930A' : '#1a1a1a' }} key={i} onClick={() => { setSingle(i); setSingleSure(true); setRightFlag([]) }}>
                        <div className={styles.aaiName}>{company_name}</div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><span className={styles.aaiLevel}>{plate_num}</span><span >车辆类型:{car_type}</span></div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><span className={styles.aaiLast}>{time}</span><span>属地:{area_name}</span></div>
                      </div>
                    )) : leftData.map(({ info: { plate_num, area_name, company_name, car_type }, time, speed, ...data }, i) => (
                      <div className={styles.overSpeedItem} style={{ padding: 0, marginTop: 10, color: single.index === (i + 1) ? '#FF930A' : '#1a1a1a' }} key={i} onClick={() => { setSingle(i); setSingleSure(true); setRightFlag([]) }}>
                        <div className={styles.aaiName}>{company_name}</div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><span className={styles.aaiLevel}>{plate_num}</span><span >车辆类型:{car_type}</span></div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><span className={styles.aaiLast}>{time}</span><span>属地:{area_name}</span></div>
                      </div>
                    ))
                  : <div style={{ textAlign: 'center' }}>暂无数据 </div>
              }
            </div>
            <div className={styles.overButton} style={{ bottom: trailBtn.visible ? 55 : 0, background: '#fff', overflow: 'hidden', display: showButton ? 'flex' : 'none' }}>
              {changeSave === 3 ? <Button style={{ background: '#ff686a' }} className={styles.button} onClick={pointGoBack}>返回</Button>
                : (
                  <>
                    <Button style={{ background: '#ff920b' }} className={styles.button} onClick={() => { setSave({ ...save, types: 2 }); setSaveFlag(true) }} >长期监控</Button>
                    <Button style={{ background: '#00b1ff' }} className={styles.button} onClick={() => { setSave({ types: 1 }); setSaveFlag(true) }} >保存</Button>
                    <Button style={{ background: '#ff686a' }} className={styles.button} onClick={CancelSave}>取消</Button>
                  </>
                )}

            </div>
          </div>

        </div>
      </div >
      {
        trailBtn.visible ?
          <div className={styles.radio}>
            <Radio.Group defaultValue={hour} buttonStyle="solid" onChange={(e) => { setHour(e.target.value); setSingleSure(true) }}>
              <Radio.Button value="1">1小时</Radio.Button>
              <Radio.Button value="2">2小时</Radio.Button>
              <Radio.Button value="3">3小时 </Radio.Button>
              <Radio.Button value="12">12小时 </Radio.Button>
              <Radio.Button value="24">24小时 </Radio.Button>
            </Radio.Group>
          </div> :
          null
      }
      < Drawer title={false} placement="bottom" closable={true} mask={false} height={55} bodyStyle={{ padding: 10 }
      } maskClosable={false}
        onClose={() => { closebottom(); setRightFlag([0]); }} visible={trailBtn.visible} >
        <div className={styles.Drawer}>
          <div style={{ display: 'flex' }}>
            <div className={styles.btns}>
              <Button onClick={() => { setTrailBtn({ ...trailBtn, play_begin: !trailBtn.play_begin }) }} className={`${styles.play_begin} ${trailBtn.play_begin ? styles.active : ''} `} type="dashed" title={trailBtn.play_begin ? '停止' : '播放'} />
              <Button onClick={() => { setTrailBtn({ ...trailBtn, movePos: 0, play_restart: !trailBtn.play_restart, play_begin: trailBtn.play_begin }) }} className={`${styles.play_restart} ${trailBtn.play_restart ? styles.active : ''}`} type="dashed" />
            </div>
            <div className={styles.control}>
              <Radio.Group value={trailBtn.moveSpeed} buttonStyle="solid" onChange={(e) => { setTrailBtn({ ...trailBtn, moveSpeed: e.target.value }) }}>
                <Radio.Button value="800">0.5倍</Radio.Button>
                <Radio.Button value="400">1倍</Radio.Button>
                <Radio.Button value="200">2倍</Radio.Button>
                <Radio.Button value="100">4倍</Radio.Button>
                <Radio.Button value="50">8倍</Radio.Button>
              </Radio.Group>
              <Slider min={0} max={trailBtn.slider.length - 1} value={trailBtn.playIndex} tipFormatter={(v) => { return trailBtn.slider[v] }} onChange={e => setTrailBtn({ ...trailBtn, playIndex: e })} onAfterChange={(e) => setTrailBtn({ ...trailBtn, movePos: e })} />
            </div>
          </div>
          <div style={{ marginRight: 40, display: 'flex', alignItems: 'center' }}>
            <Switch defaultChecked={rectify} checkedChildren={'轨迹纠偏'} unCheckedChildren={'未纠偏轨迹'} onChange={e => { setRectify(e) }}> </Switch>
            <Switch defaultChecked={show_points} checkedChildren={'原始轨迹点'} unCheckedChildren={'原始轨迹点'} onChange={e => { setShowPoints(e) }}> </Switch>
            <Switch defaultChecked={show_stops} checkedChildren={'停靠点'} unCheckedChildren={'停靠点'} onChange={e => { setShowStops(e) }}> </Switch>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}><span style={{ backgroundColor: '#EB3873', display: 'inline-block', width: 20, height: 2, marginRight: 5 }}>{ }</span><span>规划线路</span></div>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}><span style={{ backgroundColor: '#46bee9', display: 'inline-block', width: 20, height: 2, marginRight: 5 }}>{ }</span><span>纠偏轨迹</span></div>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}><span style={{ backgroundColor: '#03d5a0', display: 'inline-block', width: 20, height: 2, marginRight: 5 }}>{ }</span><span>未纠偏轨迹</span></div>
            </div>
          </div>
        </div>
      </Drawer >
      <Modal title="请输入区域名称" visible={saveFlag} onOk={handleOk} confirmLoading={save.confirmLoading} onCancel={handleCancel} width='300px' style={{ marginTop: '15%' }} afterClose={handleClose}>
        <Input type="text" value={savename} onChange={(e) => setSavename(e.target.value)} />
      </Modal>
      <Modal title={`警告！你确定要删除<${delName}>吗？`} visible={suerDel} onOk={handleOks} onCancel={handleCancels} width='300px' style={{ marginTop: '15%' }} afterClose={handleClose}>
        <p>如果确认，你将不再监控此区域</p>
      </Modal>
      <Modal title={`警告！你确定要删除<${pointName}>吗？`} visible={pointDel} onOk={pointOk} onCancel={pointNo} width='526px' style={{ marginTop: '15%' }} afterClose={handleClose}>
        <p>请选择删除原因：</p>
        <Radio.Group value={pointDelReason} buttonStyle="solid" onChange={(e) => { setDelReason(e.target.value) }}>
          {reasonOptions && reasonOptions.map(({ value, label }) => (
            <Radio.Button value={value} key={value}>{label}</Radio.Button>
          ))}
        </Radio.Group>
        {/*<p>如果确认，你将不再看到该点位!</p>*/}
      </Modal>
    </div >
  )
}
