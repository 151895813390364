import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import { dataSource, inputs } from './List';
import { message } from 'antd';

export default function Update({ history, match }) {
  const back = () => history.goBack();
  const onFinish = () => {
    message.success('新增项目信息成功');
    dataSource.refresh();
    back();
  };
  return (
    <ModalFormPage
      submit={['/river/river_project/CreateRiverProjectInfo']}
      title="新增项目信息"
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
    />
  );
}
