import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faMapMarkedAlt,
  faMapMarked,
  faClipboard,
  faFileContract,
  faTachometerAlt,
  faRoad,
  faRoute,
  faTruckLoading,
  faDoorOpen,
  faTint,
  faParking,
  faTrafficLight,
  faClipboardList,
  faFileSignature,
  faFileMedical,
  faFileExcel,
  faHeadset,
  faCoins,
  faChartLine,
  faBan,
  faFlagCheckered,
  faTruck,
  faGlasses,
  faExclamationTriangle,
  faCarCrash,
  faCheckCircle,
  faPauseCircle,
  faClock,
  faSnowplow,
  faIndustry,
  faStreetView,
  faCube,
  faWater,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBell,
  faTasks,
  faExclamationCircle

} from '@fortawesome/free-solid-svg-icons';

library.add(
  faMapMarkedAlt,
  faMapMarked,
  faClipboard,
  faFileContract,
  faTachometerAlt,
  faRoad,
  faRoute,
  faTruckLoading,
  faDoorOpen,
  faTint,
  faParking,
  faTrafficLight,
  faClipboardList,
  faFileSignature,
  faFileMedical,
  faFileExcel,
  faHeadset,
  faCoins,
  faChartLine,
  faBan,
  faFlagCheckered,
  faTruck,
  faGlasses,
  faExclamationTriangle,
  faCarCrash,
  faCheckCircle,
  faPauseCircle,
  faClock,
  faSnowplow,
  faIndustry,
  faStreetView,
  faCube,
  faWater,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBell,
  faTasks,
  faExclamationCircle
);
