import React, {useState, useEffect} from 'react';
import { Route, Link } from 'react-router-dom';
import LinkButton from 'ui/TableCell/LinkButton';
import actions from 'ui/TableCell/actions';
import request from 'utils/request';
import {Table, Modal, Button} from 'antd';
import styles from "./points.scss";

export default function ({history, match}) {
  const {params: {id, type}} = match;
  const back = () => history.goBack();
  const status = ['', '线下排查', '执法排查']; //结案部门1线下排查2执法排查
  const consum_type = ['', '非法点位', '非违规区域']; //类别1非法点位2非违规区域
  const columns = [
    { title: '结案部门', dataIndex: 'status', render: (t) => status[t] },
    { title: '结案时间', dataIndex: 'created_time' },
    { title: '类别', dataIndex: 'consum_type', render: (t) => consum_type[t] },
    actions({
      isFixed: true,
      width: 150,
      render: ($, row, index) => (
        index !== 0 ?
          <LinkButton to={`./${row.id}/history-detail`} type={'primary'} children="查看"/> :
          <span style={{color: '#1491FB'}}>最终结果</span>
      ),
    }),
  ];

  const [dataSource, setData] = useState([]);
  const [counts, setCounts] = useState({});

  useEffect(() => {
    request('/web/suspect_point/historyList', {id})
      .then(body => {
        console.log(body.data.data)
        setData(body.data.data.data);
        setCounts(body.data.data.meta);
      });
  }, []);

  return (
    <Modal
      title="历史排查"
      bodyStyle={{maxHeight: '80vh', overflowY: 'auto'}}
      centered={true}
      width={1000}
      visible={true}
      onOk={back}
      onCancel={back}
      footer={<Button onClick={back}>关闭</Button>}
    >
      <div style={{display: 'flex', marginBottom: 20}}>
        <span>线下部门排查：</span>
        <span style={{marginRight: 20}}>{counts.offlineCount}</span>
        <span>执法部门排查：</span>
        <span>{counts.lawEnforcementCount}</span>
      </div>
      <Table bordered size="middle" rowKey='id' className={styles.table} columns={columns} dataSource={dataSource} pagination={false} />
    </Modal>
  )
}
