import React from 'react';
import {Modal} from 'antd';
import Flow from './Flow';
export default function HistoryAlarmDetail({location, history}){
  const {state: {historyId}} = location;
  const back = () => history.goBack();
  return (
    <Modal
      title={'历史告警详情'}
      visible={true}
      width={600}
      onCancel={back}
      footer={null}
      bodyStyle = {{
        maxHeight: '70vh',
        overflowY: 'auto'}}
    >
      <Flow id={historyId} />
    </Modal>
  )
}
