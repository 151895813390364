import React from 'react';
import echarts from "echarts";

export function Convert(data, params, api){
  var m_coords = data['poly_line'] ? data['poly_line'].split(";") : '';
  var coords=[];
  for (var i=0;i<m_coords.length;i++){
    coords.push(m_coords[i].split(","))
  }
  var points = [];
  for (var j = 0;j < coords.length;++j) {
    points[j] = api.coord(coords[j]);
  }
  return {
    type: 'polygon',
    shape: {
      points: echarts.graphic.clipPointsByRect(points, {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height
      })
    },
    style: api.style({
      fill: '#1491fb',
      stroke: '#0B1747',
      opacity: 0.1,
    }),
    styleEmphasis: api.style({
      fill: '#1491fb',
      stroke: '#0B1747',
      opacity: 0.1,
    }),
  };
};
