/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { Upload, Icon } from 'antd';
import { upload } from 'utils/request';
const { Dragger } = Upload;
import { ImagePreviewer } from 'components/Previewer';
const uploadButton = (
  <div>
    <Icon type="plus" style={{ fontSize: 32, color: '#999' }} />
    <div style={{ marginTop: 8, color: '#666' }}>上传</div>
  </div>
);

function prevent(e) {
  e.preventDefault();
  e.stopPropagation();
}

export default function UploaderDragger({ value, onChange, placeholder = uploadButton, showMsg='请上传运企公司盖章的证明文件', extraMsg='', multiple = true, name = 'file', disabled, maxUploadLength, ...rest }) {
  const [fileList, setFileList] = useState([]);
  const [img_flag, setImgFlag] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  // 不受控,value只用于初始化filrList
  useEffect(() => {
    if (!value) return;
    setFileList(value.map(({ file_url, file_name, ...url }, i) => ({ ...url, file_url: file_url, id: url.id ? url.id : i, uid:  url.id ? url.id : i, status: 'done', name: file_name })));
  }, []);

  function onFileListChange({ fileList }) {
    setFileList([...fileList]);
    onChange(fileList.filter(({ status }) => status === 'done').map(({ file_url, id, response, ...data }) => ( id ? {id: id, file_url: file_url || response.src, file_name: data.name} : {file_url: file_url || response.src, file_name: data.name})));
  }
  function onPreview({file_url, ...data}) {
    const src = file_url ? file_url : data.response.src;
    if(src.indexOf('.pdf') > -1 || src.indexOf('.xls') > -1 || src.indexOf('.xlsx') > -1 || src.indexOf('.doc') > -1 || src.indexOf('.docx') > -1 || src.indexOf('.txt') > -1){
      window.open(src)
    }
    else{
      setImgSrc(src);
      setImgFlag(true);
    }
  }
  function hideImage({visiable}){
    setImgFlag(visiable)
  }

  const nextable = maxUploadLength ? fileList.length < maxUploadLength : true;
  const preventer = nextable ? undefined : prevent;

  return (
    <>
      <Dragger
        {...rest}
        fileList={fileList}
        onChange={onFileListChange}
        onPreview = {onPreview}
        disabled={disabled || fileList.length >= maxUploadLength}
        previewFile = {(file) =>  {
          return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
            method: 'POST',
            body: file,
          })
            .then(res => res.json())
            .then(({ thumbnail }) => thumbnail);
        }}
        customRequest={({
                          file,
                          filename,
                          onSuccess,
                          onError,
                        }) => {
          const formData = new FormData();
          formData.append(filename, file);
          upload(formData).then((res) => {
            const { data: src, msg } = res.data || {};
            if (res.status === 200 && src) {
              onSuccess({ src, thumbUrl: src });
            } else {
              onError(msg);
            }
          }).catch(console.error);
        }}
      >
        <p style={{color: '#ff0000', paddingLeft: 10, textAlign: 'left', position: ''}}>{showMsg}</p>
        {/* {placeholder} */}
        {placeholder || (
          <div onMouseDown={preventer} onMouseUp={preventer} onClick={preventer} style={{ cursor: nextable ? 'pointer' : 'not-allowed'}}>
            <Icon type="plus" style={{ fontSize: 32, color: '#999' }} />
            <div style={{ marginTop: 8, color: '#666' }}>上传</div>
          </div>
        )}
        <p className="ant-upload-hint">
          {rest.showLoadText ? rest.showLoadText : '支持文件类型：图片、WORD、EXCEL、PDF'}
        </p>
      </Dragger>
      {extraMsg}
      {
        img_flag ? <ImagePreviewer images={ imgSrc } visiable={img_flag} onChange={visiable => hideImage({ visiable })} />
          : null
      }
    </>
  );
}
