// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2SE_oe7zl45M5KKcqkIh9B .ant-modal-body {\n  padding: 0;\n}", ""]);
// Exports
exports.locals = {
	"mapContainer": "_2SE_oe7zl45M5KKcqkIh9B"
};
module.exports = exports;
