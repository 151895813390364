// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3XrsP25EEqyk-Nv_e6NIdg {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  z-index: 9999999999;\n  background-color: rgba(0, 0, 0, 0.9);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n._3XrsP25EEqyk-Nv_e6NIdg > div > span {\n  font-size: 18px;\n  color: #FFFFFF;\n}", ""]);
// Exports
exports.locals = {
	"all": "_3XrsP25EEqyk-Nv_e6NIdg"
};
module.exports = exports;
