const amap2baidu = ({ lng: x, lat: y, ...rest }) => {
  const X_PI = Math.PI * 3000.0 / 180.0;
  const z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
  const theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
  const lng = z * Math.cos(theta) + 0.0065;
  const lat = z * Math.sin(theta) + 0.006;
  if (!lng || !lat) console.log('ok');
  return { lng, lat, ...rest };
};
const amaps2baidus = points => points.map(amap2baidu);

const baidu2amap = ({ lng: bx, lat: by, ...rest }) => {
  const X_PI = Math.PI * 3000.0 / 180.0;
  const x = bx - 0.0065;
  const y = by - 0.006;
  const z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
  const theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
  const lng = z * Math.cos(theta);
  const lat = z * Math.sin(theta);
  return { lng, lat, ...rest };
};
const baidus2amaps = points => points.map(baidu2amap);

const obj2arr = ({ lng, lat }) => [lng, lat];
const objs2arrs = points => points.map(obj2arr);

export default {
  amap2baidu,
  amaps2baidus,
  baidu2amap,
  baidus2amaps,
  obj2arr,
  objs2arrs,
};
