import React from 'react';
import { Divider } from 'antd';
import { previewable } from 'components/Previewer';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import NewDeleteButton from 'ui/TableCell/NewDeleteButton';
import LinkButton from 'ui/TableCell/LinkButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';

export const dataSource = new DataSource('/river/river_base/getRiverTransportLists');

export const inputs = [
  { label: '运企名称', placeholder: '请填写运企名称', key: 'name', required: true },
  { label: '运企地址', placeholder: '请填写运企地址', key: 'address', required: true },
  { label: '运企负责人', placeholder: '请填写负责人', key: 'manager', required: true },
  { label: '负责人电话', placeholder: '请填写负责人电话', key: 'manager_tel', required: true },
];

function List({ priview }) {
  const columns = [
    serial(true),
    { title: '区域', dataIndex: 'area_name', width: width(4) },
    { title: '运企名称', dataIndex: 'name' },
    { title: '运企地址', dataIndex: 'address' },
    { title: '运企负责人', dataIndex: 'manager', width: width(6) },
    { title: '负责人电话', dataIndex: 'manager_tel', width: width(8) },
    { title: '驾驶员数量', dataIndex: 'driver_num', width: width(5) },
    { title: '车辆数量', dataIndex: 'car_num', width: width(5) },
    { title: '更新时间', dataIndex: 'updated_time', width: width(14) },
    { title: '更新人', dataIndex: 'updated_name', width: width(6) },
    actions({
      width: width(8),
      render: ($, {id}) => (
        <>
          <LinkButton to={`./${id}/update`} />
          <Divider type="vertical" />
          <NewDeleteButton title="确认删除该运企信息?" type="danger" api="/river/river_base/deleteRiverTransportList" river_transport_id={ id } dataSource={dataSource}>删除</NewDeleteButton>
        </>
      )
    })
  ];
  const querys = [
    { title: ['更新', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
    { title: '请选择运企', key: 'transport_id', type: 'select', options: '/river/river_base/getRiverTransportOption', valueKey: 'id', labelKey: 'name', style: { width: 300 } },
    { title: '请输入负责人、电话、更新人模糊搜索', key: 'keyword', style: { width: 300 } },
  ];

  const buttons = [
    { title: '新增', link: './create', icon: 'plus'},
    { title: '导出', download: '/river/river_base/exportRiverTransportLists' },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}

export default previewable(List)
