import React from 'react';
import { previewable } from 'components/Previewer';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import ImagePreviewButtonRender from 'ui/TableCell/ImagePreviewButtonRender';
import LinkButton from 'ui/TableCell/LinkButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import { Modal} from 'antd';
import {ALARMSTATUSOPTIONS} from './FilterData';
import actions from "ui/TableCell/actions";

function HistoryAlarm({ location, history, preview }) {
  const {state: {alarmId, alarmType, deviceType}} = location;
  const back = () => history.goBack();
  const dataSource = new DataSource('/web/count_alarm/historyData', {id: alarmId});
  const render = ImagePreviewButtonRender(preview);
  const columns = alarmType === 5 ? [
    serial(false),
    { title: '工地名称', dataIndex: 'building_name' },
    { title: '告警时间', dataIndex: 'created_time', width: width(12) },
    { title: '土方开挖时间', dataIndex: 'start_dig_date', width: width(12), render: (s, {start_dig_date, end_dig_date}) => {return `${start_dig_date}至${end_dig_date}`} },
    { title: '告警地点', dataIndex: 'address', width: width(4), align: 'center' },
    { title: '疑似偷排趟次', dataIndex: 'cheat_ticket_count', width: width(5) },
    { title: '告警处理节点', dataIndex: 'node_names' },
    actions({
      isFixed: true,
      width: 100,
      render: ($, { id }) => (
        <>
          <LinkButton to={{pathname: './history-detail', state: {...location.state, historyId: id}}}>详情</LinkButton>
        </>
      ),
    }),
  ] : alarmType === 6 ? [
    serial(false),
    { title: '工地名称', dataIndex: 'building_name' },
    { title: '车牌号码', dataIndex: 'car_num' },
    { title: '告警时间', dataIndex: 'created_time', width: width(12) },
    { title: '土方开挖时间', dataIndex: 'start_dig_date', render: (s, {start_dig_date, end_dig_date}) => {return `${start_dig_date}至${end_dig_date}`} },
    { title: '告警地点', dataIndex: 'address', align: 'center' },
    { title: '车牌照片', dataIndex: 'photo', width: width(4), render},
    { title: '告警处理节点', dataIndex: 'node_names' },
    actions({
      isFixed: true,
      width: 100,
      render: ($, { id }) => (
        <>
          <LinkButton to={{pathname: './history-detail', state: {...location.state, historyId: id}}}>详情</LinkButton>
        </>
      ),
    }),
    ] : alarmType === 7 ? [
    serial(false),
    { title: '工地名称', dataIndex: 'building_name' },
    { title: '车牌号码', dataIndex: 'car_num' },
    { title: '告警时间', dataIndex: 'created_time', width: width(12)},
    { title: '土方开挖时间', dataIndex: 'start_dig_date', render: (s, {start_dig_date, end_dig_date}) => {return `${start_dig_date}至${end_dig_date}`} },
    { title: '告警地点', dataIndex: 'address', align: 'center' },
    { title: '所属区县', dataIndex: 'area_name'},
    { title: '所属运企', dataIndex: 'transport_name'},
    { title: '车牌照片', dataIndex: 'photo', width: width(4), render},
    { title: '告警处理节点', dataIndex: 'node_names' },
    actions({
      isFixed: true,
      width: 100,
      render: ($, { id }) => (
        <>
          <LinkButton to={{pathname: './history-detail', state: {...location.state, historyId: id}}}>详情</LinkButton>
        </>
      ),
    }),
    ] : alarmType === 8 ? [
    serial(false),
    { title: '告警地点', dataIndex: 'address' },
    { title: '车牌号码', dataIndex: 'car_num' },
    { title: '告警时间', dataIndex: 'created_time', width: width(12) },
    { title: '所属区县', dataIndex: 'area_name'},
    { title: '所属运企', dataIndex: 'transport_name'},
    { title: '车牌照片', dataIndex: 'photo', width: width(4), render},
    { title: '告警处理节点', dataIndex: 'node_names' },
    actions({
      isFixed: true,
      width: 100,
      render: ($, { id }) => (
        <>
          <LinkButton to={{pathname: './history-detail', state: {...location.state, historyId: id}}}>详情</LinkButton>
        </>
      ),
    }),
    ] : alarmType === 10 ? [
    { title: '车牌号码', dataIndex: 'car_num' },
    { title: '告警时间', dataIndex: 'created_time', width: width(12) },
    { title: '告警地点', dataIndex: 'address' },
    { title: '所属区县', dataIndex: 'area_name'},
    { title: '所属运企', dataIndex: 'transport_name'},
    { title: '告警处理节点', dataIndex: 'node_names' },
    actions({
      isFixed: true,
      width: 100,
      render: ($, { id }) => (
        <>
          <LinkButton to={{pathname: './history-detail', state: {...location.state, historyId: id}}}>详情</LinkButton>
        </>
      ),
    }),
    ] : alarmType === 9 ?
      ( deviceType === 1 ? [
        { title: '车牌号码', dataIndex: 'car_num' },
        { title: '告警时间', dataIndex: 'created_time', width: width(12) },
        { title: '告警地点', dataIndex: 'address' },
        { title: '所属区县', dataIndex: 'area_name'},
        { title: '所属运企', dataIndex: 'transport_name'},
        { title: '车牌照片', dataIndex: 'photo', width: width(4), render},
        { title: '告警处理节点', dataIndex: 'node_names' },
        actions({
          isFixed: true,
          width: 100,
          render: ($, { id }) => (
            <>
              <LinkButton to={{pathname: './history-detail', state: {...location.state, historyId: id}}}>详情</LinkButton>
            </>
          ),
        }),
      ] : [
        { title: '工地名称', dataIndex: 'building_name' },
        { title: '告警时间', dataIndex: 'created_time', width: width(12) },
        { title: '告警地点', dataIndex: 'address' },
        { title: '设备名称', dataIndex: 'door_name'},
        { title: '设备照片', dataIndex: 'photo', render},
        { title: '告警处理节点', dataIndex: 'node_names' },
        actions({
          isFixed: true,
          width: 100,
          render: ($, { id }) => (
            <>
              <LinkButton to={{pathname: './history-detail', state: {...location.state, historyId: id}}}>详情</LinkButton>
            </>
          ),
        }),
      ])
   : alarmType === 11 ? [
    { title: '工地名称', dataIndex: 'building_name' },
    { title: '告警时间', dataIndex: 'created_time', width: width(12) },
    { title: '告警地点', dataIndex: 'address' },
    { title: '土方开挖时间', dataIndex: 'start_dig_date', render: (s, {start_dig_date, end_dig_date}) => {return `${start_dig_date}至${end_dig_date}`} },
    { title: '私挖盗采(趟)', dataIndex: 'steal_sand_count', width: width(7)},
    { title: '装车照片', dataIndex: 'photo', render, width: width(5)},
    { title: '告警处理节点', dataIndex: 'node_names' },
      actions({
        isFixed: true,
        width: 100,
        render: ($, { id }) => (
          <>
            <LinkButton to={{pathname: './history-detail', state: {...location.state, historyId: id}}}>详情</LinkButton>
          </>
        ),
      }),
    ] : [];
  console.log(columns)
  const querys = [
    { title: '关键字', key: 'keyword', style: {width: 120} },
    { title: '完成状态',key: 'status', type: 'select', options: ALARMSTATUSOPTIONS, labelKey: 'name', valueKey: 'id', style: {width: 120} },
    { title: ['告警', '时间'], key: 'time', type: 'date-range', showTime: false, style: {width: 320} },
  ];
  return (
    <Modal
      title={'历史告警'}
      visible={true}
      width={1800}
      onCancel={back}
      footer={null}
      bodyStyle = {{
        maxHeight: '80vh',
        overflowY: 'auto'}}
    >
      <TablePage
        dataSource={dataSource}
        querys={querys}
        columns={columns}
        defaultLimit={10}
      />
    </Modal>
  );
}
export default previewable(HistoryAlarm);
