import React, { useState, useEffect } from 'react';
import { Radio } from 'antd';
import request from 'utils/request';

export default function RadioGroup({
  props,
  onChange, 
  value: originalValue,
  options: originalOptions,
  valueKey = 'value',
  labelKey = 'label',
  ...rest
}) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (!originalOptions) return;
    if (Array.isArray(originalOptions)) {
      setOptions(originalOptions);
    } else {
      let url = '';
      let data = {};
      if (typeof originalOptions === 'string') {
        url = originalOptions;
      } else if (typeof originalOptions === 'object') {
        url = originalOptions.url;
        data = originalOptions.data;
      }
      request(url, data)
        .then(body => body.data.data)       
        .then(pairs => setOptions(pairs));
    }
  }, [JSON.stringify(originalOptions)]);

  console.log('options', options, valueKey, labelKey);
  
  return (
    <Radio.Group defaultValue={originalValue} buttonStyle="solid" onChange={(e) => onChange(e.target.value)}>
      {options && options.map((item) => (
        <Radio.Button value={item[valueKey]}>{item[labelKey]}</Radio.Button>
      ))}      
    </Radio.Group>
  );
}
