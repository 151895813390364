import React from 'react';
import { Steps as AntSteps } from 'antd';

export default function Steps({ steps, current, types }) {
  switch (types) {
    case "1": {
      const getTitle = (index, title) => ((index === 0 || index === (steps.size - 2) || steps.size === 4) ? title : (title + index));
      return (
        <AntSteps current={current}>
          {steps.valueSeq().map((step, index) => (
            <AntSteps.Step key={step.get('key') || step.get('title')} title={getTitle(index, step.get('title'))} />
          ))}
        </AntSteps>
      );
    }
    case "2": {
      const getTitle = (index, title) => ((index === 0 || index === (steps.size - 3) || steps.size === 5) ? title : (title + index));
      return (
        <AntSteps current={current}>
          {steps.valueSeq().map((step, index) => (
            <AntSteps.Step key={step.get('key') || step.get('title')} title={getTitle(index, step.get('title'))} />
          ))}
        </AntSteps>
      );
    }
    default: return null;
  }
}
