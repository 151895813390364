/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import immutable from 'immutable';
import debug from 'utils/debug';
import request from 'utils/request';

// judgeRepeat - GPS离线申报，用于判断是否可以重复选择已经选过的车辆
// judgeDataTable - GPS离线申报，已经选中的值
export default function Selector({
  value: originalValue,
  options: originalOptions,
  valueKey = 'value',
  labelKey = 'label',
  optionValue = false,
  realTimeSearch,
  placeholder,
  onChange,
  showSearch,
  disabled,
  showMsg=false,
  judgeDataTable,
  judgeRepeat=false,
  ...props
}) {
  const [options, setOptions] = useState(immutable.OrderedMap());
  const { mode, maxLength = false} = props;
  function onOptionChange(value) {
    if (mode === 'tags' && maxLength && Array.isArray(value)) {
      if (value.length > maxLength) value.splice(0, maxLength);
      return onChange(optionValue ? options.get(value) : value[0]);
    }
    onChange(optionValue ? options.get(value) : value);
  }

  useEffect(() => {
    debug.effect('Selector');
    if (!originalOptions) return;
    if (Array.isArray(originalOptions)) {
      setOptions(immutable.OrderedMap(originalOptions.map((opt) => {
        const option = typeof opt === 'string' ? { [valueKey]: opt, [labelKey]: opt } : opt;
        return [option[valueKey], option];
      })));
    } else if (!disabled && !realTimeSearch) {
      let url = '';
      let data = {};
      if (typeof originalOptions === 'string') {
        url = originalOptions;
        if(url === '/web/keep_on_record/carList') {
          data = { report_type: 3 }
        } else if(url === '/web/keep_on_record/carList?') {
          data = { report_type: 2 }
        }
      } else if (typeof originalOptions === 'object') {
        onOptionChange();
        url = originalOptions.url;
        data = originalOptions.data;
      }
      request(url, data)
        .then(body => body.data.data)
        .then(data => {
          if (judgeRepeat) {
            const carData = (judgeDataTable ? judgeDataTable : []).reduce((value, { car_list }) => {
              const val = car_list.split(',');
              return value.concat(val)
            }, []);
            return Array.isArray(data) ? data.map(option => {
              return [option[valueKey], {...option, disabled: (carData ? carData : '').includes(option[valueKey])}];
            }) : [];
          }
          return Array.isArray(data) ? data.map(option => [option[valueKey], option]) : [];
        })
        .then(pairs => setOptions(immutable.OrderedMap(pairs)));
    }
  }, [JSON.stringify(originalOptions)], JSON.stringify(judgeDataTable));

  // 实时搜索延时
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    if (!realTimeSearch) return;
    let url = '';
    let data = {};
    if (typeof originalOptions === 'string') {
      url = originalOptions;
    } else if (typeof originalOptions === 'object') {
      url = originalOptions.url;
      data = originalOptions.data;
    }
    setIsSearching(true);
    setOptions(immutable.OrderedMap());
    const timer = setTimeout(() => {
      request(url, { ...data, [realTimeSearch]: searchValue ? searchValue : '' })
        .then(body => body.data.data)
        .then(data => Array.isArray(data) ? data.map(option => ([option[valueKey], option])) : [])
        .then(pairs => setOptions(immutable.OrderedMap(pairs)))
        .then(() => setIsSearching(false));
    }, 500);
    return () => clearTimeout(timer);
  }, [JSON.stringify(originalOptions), searchValue]);

  const searchProps = (() => {
    if (!realTimeSearch) {
      // 本地搜索，默认搜索标签
      return {
        showSearch,
        optionFilterProp: showSearch && 'children',
        filterOption: showSearch && ((input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0),
      };
    } else {
      // 实时搜索
      return {
        showSearch: true,
        filterOption: false,
        onSearch: setSearchValue,
        notFoundContent: isSearching ? <Spin size="small" /> : null,
      };
    }
  })();

  const value = optionValue ? (originalValue && originalValue[valueKey]) : originalValue;

  return (
    <>
      <Select
        {...searchProps}
        {...props}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onOptionChange}
      >
        {options.valueSeq().map(option => (
          <Select.Option key={option[valueKey]} value={option[valueKey]} disabled={option['disabled']} data_value={option[labelKey]}>{option[labelKey]}</Select.Option>
        ))}
      </Select>
      <div style={{ color: '#f00', lineHeight: '24px' }}>{showMsg}</div>
    </>
  );
}
