import React, {useState, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import {DatePicker, Icon, Spin, Input, Select} from 'antd';
import moment from "moment";
import request from 'utils/request';
import immutable from 'immutable';
import styles from './AlarmCenter.scss';
import {Types, ALARMIMAGES, ALARMNAME, ALARMSTATUS, COLOR} from './FilterData';
import {modifyCarNum, useObservable} from "components/Previewer";
import {setNoticeText, flowNotice} from './FilterFunction'
const { Search } = Input;
const { Option } = Select;
// const defaultDates = [moment().subtract(0, 'day').startOf('day'), moment().endOf('day')];
// 获取告警数据
function obtainAlarmData(params){
  const [data, setData] = useState([]);
  const [count, setCount] = useState([]);
  const [alarmSpin, setAlarmSpin] = useState(false);
  const [important, setImportant] = useState(0);
  const [total, setTotal] = useState(0);
  const begin_date = params.get('dates')[0].format('YYYY-MM-DD');
  const end_date = params.get('dates')[1].format('YYYY-MM-DD');
  useEffect(() => {
    setAlarmSpin(true);
    request('/web/count_alarm/data', {...params.delete('dates').toJS(), time: [begin_date, end_date]})
      .then(b => b.data.data)
      .then(data => {setData(data.data.data); setTotal(data.data.total); setCount(data.count); setImportant(data.important)})
      .finally(() => {
        setAlarmSpin(false);
      });
  }, [JSON.stringify(params)])
  return [data, total, count, important, alarmSpin]
}
function makeImportant(id){
  request('/web/count_alarm/makeImportant', {id}).then(() => console.log)
}

export default function AlarmCenter({onClose, defaultDates, showPoint, onChangeDate, clickAlarm}){
  const [keyword, setKeyword] = useState('');
  const [params, setParams] = useState(immutable.Map({ dates: defaultDates, keyword: '', status: 1, nav_num: 1, page: 1, limit: 10 }));
  const [data, total, alarmType, important, alarmSpin] = obtainAlarmData(params);
  const [alarmData, setAlarmData] = useState([]);
  const [focus, setFocus] = useState(0);
  const newCarNum = useObservable(modifyCarNum);
  const [pointId, setPointId] = useState();
  const history = useHistory();
  useEffect(() => {
    setAlarmData(data);
    setFocus(important)
  }, [data, important])
  useEffect(() => {
  }, [newCarNum]);
  const markPoints = (alarmId, alarmPoint) => {
    makeImportant(alarmId);
    setFocus(alarmPoint ? (focus - 1) : (focus + 1))
    setAlarmData(alarmData.map(({id, important, ...d}) => ({
      ...d,
      important: id === alarmId ? !alarmPoint : important,
      id,
    })))
  };
  const getMapPoint = ({id, ...props}) => {
    setPointId(id);
    showPoint({id, ...props})
  };
  useEffect(() => {
    if(clickAlarm){
      history.push({
        "pathname":'./alarm-detail/',
        state: {id: clickAlarm.id, status: clickAlarm.status, dispatch_task_id: clickAlarm.dispatch_task_id, notice_count: clickAlarm.notice_count, params: {...params.toJS(), dates: JSON.stringify(params.get('dates'))}}
      })
    }
  }, [clickAlarm])
  return <>
    <div className={styles.container}>
      <div className={styles.topIcon}><Icon type="close" onClick={onClose} /></div>
      <div className={styles.dates}>
        <DatePicker.RangePicker className={styles.date} size={'large'} allowClear={false} value={params.get('dates')}
                                separator={'~'} format="MM-DD" suffixIcon={<img src="/images/alarm/black_arrow.png" alt=""/>}
                                onChange={(e) => {onChangeDate(e); setParams(params.set('dates', e).set('page', 1))}}  />
        <Search value={keyword} placeholder="输入车牌号、工地名称" onChange={(e) => setKeyword(e.target.value)}
                enterButton={false} onPressEnter={() => setParams(params.set('keyword', keyword).set('page', 1))}
        />
      </div>
      <div className={styles.alarmType}>
        {
          alarmType.map(({name, count, status}, i) => (
            <div key={i} onClick={() => setParams(params.set('status', status).set('page', 1))}>
              <span>{count}</span>
              <span className={params.get('status') === status ? styles.active : ''}>{name}</span>
            </div>
          ))
        }
      </div>
      <div className={styles.navNum}>
        <span className={params.get('nav_num') === 1 ? styles.active : ''}>结算告警</span>
        <span className={params.get('nav_num') === 2 ? styles.active : ''}>车辆告警</span>
      </div>
      <div className={styles.warnTotal}>
        <span>共{total}次告警</span>
        <div className={styles.keyPoint}><img src="/images/alarm/marker.png" alt=""/><span>重点关注 {focus}</span></div>
        <div className={styles.types}>
          <Select placeholder={'类型'} allowClear value={params.get('alarm_type')} onChange={(e) => setParams(params.set('alarm_type', e).set('page', 1))}
                  suffixIcon={<img src="/images/alarm/grey_arrow.png" alt=""/>}
          >
            {
              Types.map(({name, id}, i) => (
                <Option key={i} value={id}>{name}</Option>
              ))
            }
          </Select>
        </div>
      </div>
      <div className={styles.alarmData}>
        <Spin spinning={alarmSpin}>
          {
            alarmData.length > 0 ?
              alarmData.map(({id, building_name, car_num, transport_name, type, type_text, out_dig, created_time, node_names, device_type, address, important, status, dispatch_task_id, notice_count, lng, lat}, i) => (
                <div key={i} className={pointId === id ? `${styles.alarm} ${styles.active}` : styles.alarm } onClick={() => getMapPoint({id, building_name, car_num, transport_name, type, type_text, out_dig, created_time, node_names, device_type, address, important, status, dispatch_task_id, notice_count, lng, lat, name: type === 8 ? address : ((type === 9 && device_type === 1) || type === 10) ? (newCarNum ? (id === newCarNum.id ? newCarNum.car_num : car_num) : car_num) : building_name})}>
                  <div className={styles.alarmTitle}>
                    <Link to={{pathname: './alarm-detail/', state: {id: id, status: status, dispatch_task_id: dispatch_task_id, notice_count: notice_count, params: {...params.toJS(), dates: JSON.stringify(params.get('dates'))}}}}>
                      <img src={ALARMIMAGES[type]} alt=""/>
                    </Link>
                    <span title={ type === 8 ? address : ((type === 9 && device_type === 1) || type === 10) ? (newCarNum ? (id === newCarNum.id ? newCarNum.car_num : car_num) : car_num) : building_name}>{ type === 8 ? address : ((type === 9 && device_type === 1) || type === 10) ? (newCarNum ? (id === newCarNum.id ? newCarNum.car_num : car_num) : car_num) : building_name}</span>
                    <span><Link to={{pathname: './alarm-detail/', state: {id: id, status: status, dispatch_task_id: dispatch_task_id, notice_count: notice_count, params: {...params.toJS(), dates: JSON.stringify(params.get('dates'))}}}}>详情</Link></span>
                    <span>{ALARMNAME[type]} <img src="/images/alarm/right_arrow.png" alt=""/></span>
                  </div>
                  <p style={{color: !out_dig ? '#46D868' : '#FE4442' }}>告警时间: {created_time}</p>
                  {
                    params.get('status') === 1 || params.get('status') === 2 ?
                      <div className={styles.alarmMsg}>
                        <p>{address}</p>
                        <span><img onClick={() => markPoints(id, important)} src={`/images/alarm/${important ? `marker.png` : `point.png`}`} alt=""/></span>
                      </div>
                      : <div className={styles.alarmMsg2}>
                        <p>告警地点：{address}</p>
                        <div>
                          <p>当前状态: <span style={{color: '#FE4442'}}>{node_names}</span></p>
                          <div>{status === 4 ? <span onClick={() => flowNotice(id)}>提醒</span> : (status === 2 || status === 5) ? <span>{ setNoticeText(notice_count) }</span> : null}</div>
                          <span><img onClick={() => markPoints(id, important)} src={`/images/alarm/${important ? `marker.png` : `point.png`}`} alt=""/></span>
                        </div>

                      </div>
                  }
                </div>
              ))
              : <div className={styles.empty}>
                <img src="/images/alarm/empty.png" alt=""/> <span>当前暂无数据</span>
              </div>
          }
        </Spin>
      </div>
      <div className={styles.btn} ><Link  to={{ pathname: './alarm-detail/', state: { params: { ...params.toJS(), dates: JSON.stringify(params.get('dates')) } } }}><img
        src="/images/alarm/btn.png" alt=""/></Link></div>
    </div>
    </>;
}
