
import React from 'react';
import { outInstanceIds } from './ToBeConfirmed/List';

const topStyle = {paddingBottom: 3, borderBottom: '6px double black', fontSize: 20, letterSpacing: '5px', fontWeight: 'bolder'};
export default function PrintTicket({ write, state, isKf, borderLeft, borderRight }) {
  const inout = !outInstanceIds.includes(state.instance_id); // state.instance_id !== 277 && state.instance_id !== 281 && state.instance_id !== 284; // 是入库单或是出库单   true - 入库单  false - 出库单
  const borderBottom = { borderBottom: '1px solid black' };

  return (
    <>
      <object id="LODOP_OB" classID="clsid:2105C259-1E0C-4534-8141-A753534CB4CA" style={{width: 0, height: 0}}>
        <embed id="LODOP_EM" type="application/x-print-lodop" style={{width: 0, height: 0}}></embed>
      </object>
      <div id="print_t1" style={{ display: 'none', paddingLeft: 0 }}>
        <div style={{textAlign: 'center', paddingBottom: 2, height: 76, marginTop: 4}}>
          <h3>
            <span style={{ paddingBottom: 0, fontSize: 20, letterSpacing: '5px', fontWeight: 'bolder' }}>{state.printCompany}</span>
          </h3>
          {!write && (
            <h3>
              <span style={{ ...topStyle }}>{inout ? `${state.instance_name}砂石入库单` : `砂石处置出库（入库）联单`}</span>
            </h3>
          )}
        </div>
        {inout ? (
          <>
            <div className="flex" style={{display: 'flex', padding: '0 10px', height: 40}}>
              <div className="lj" style={{display: 'inline', width: '38%', fontWeight: 'bold', marginLeft: write ? 40 : 0 }}>{!write && '收到 '}{state.printJson.build_name}</div>
              <div className="lj flex-grow" style={{display: 'inline', width: '57%', fontWeight: 'bold'}}>
                <span style={{ paddingLeft: write ? 43 : 0, display: 'inline-block', width: '49%', boxSizing: 'border-box'}}>{!write && '日期: '}<span style={{fontSize: write && 14}}>{state.printJson.date}</span></span>
                <span style={{ paddingLeft: write ? 70 : 10, paddingRight: write ? 15 : 0, display: 'inline-block', width: '49%', boxSizing: 'border-box' }}>{!write && 'NO: '}<span style={{fontSize: !write ? 20 : 16, color: '#D37663'}}>{state.printJson.number}</span></span>
              </div>
            </div>
            <div className="flex" style={{ marginTop: 5, marginLeft: 8 }}>
              <table border={ write ? 0 : 1 } cellSpacing="0" cellPadding="5" style={{ borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'center', width: '99%' }}>
                <tbody> 
                <tr>
                  <td style={{height: 35, width: '17%'}}>{!write && <span>车&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;牌</span>}</td>
                  <td style={{width: '17%'}}>{state.printJson.car_num}</td>
                  <td style={{width: '17%'}}>{!write && <span>车&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数</span>}</td>
                  <td style={{width: '17%'}}>{state.printJson.transport_count}</td>
                  <td style={{width: '16%'}}>{!write && `货箱方量(m³)`}</td>
                  <td style={{width: '16%'}}>{state.printJson.real_volumn}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div className="flex" style={{ marginLeft: 8 }}>
              <table border="0" cellSpacing="0" cellPadding="5" style={{ borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'center', width: '99%' }}>
                <tbody>
                  <tr style={{borderTop: 'none',}}>
                  <td style={{width:'17%', ...borderLeft}}>{state.printJson.car_type}车</td>
                  <td style={{height: 35, width: '17%', ...borderLeft, ...borderRight}}>{!write && <span>货&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名</span>}</td>
                  <td style={{width: '17%', ...borderRight}}>{!write && state.printJson.materielName}</td>
                  <td style={{width: '17%', ...borderRight}}>{state.printJson.deduction_reason.length < 6 ? state.printJson.deduction_reason : (state.printJson.deduction_reason.substr(0, 6) + '...') }</td>
                  <td style={{width: '16%', ...borderRight}}>{(!write || isKf) ? `扣方(m³)` : '-'}</td>
                  <td style={{width: '16%', ...borderRight}}>{isKf ? state.printJson.deduction_volume : '-'}</td>               
                </tr>               
                </tbody>
              </table>
            </div>
            <div className="flex" style={{ marginLeft: 8 }}>
              <table border={ write ? 0 : 1 } cellSpacing="0" cellPadding="5" style={{ borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'center', width: '99%' }}>
                <tbody>
                <tr style={{height: 35, borderTop: 'none'}}>
                  <td style={{width: '17%'}}>{!write && `送货人`}</td>
                  <td style={{width: '17%'}}>{}</td>
                  <td style={{width: '17%'}}>{!write && `收货人`}</td>
                  <td style={{width: '17%'}}>{state.receiverName}{state.secondReceiver === '' ? '' : ',' + state.secondReceiver}</td>
                  <td style={{width: '16%'}}>{!write && `实际方量(m³)`}</td>
                  <td style={{width: '16%'}}>{state.printJson.volume}</td>
                </tr>
                </tbody>
              </table>
            </div>
            {!write && (
              state.instance_id === 308 ? <>
                <div style={{fontSize: 12}}>第一联：存根联（白），第二联：公司财务（红），第三联：移交砂石加工单位（蓝）</div>
                <div style={{fontSize: 12}}>第四联：移交砂石加工单位（绿），第五联：移交砂石单位（黄），第六联：移交砂石单位（灰）</div>
              </> : <>
                <div style={{fontSize: 12}}>第一联：存根联（白），第二联：公司财务（红）</div>
                <div style={{fontSize: 12}}>第三联：移交砂石加工单位（蓝），第四联：移交砂石单位（黄）</div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="flex" style={{display: 'flex', padding: '0 10px', height: 30}}>
              <div style={{ display: 'inline', width: '80%', fontWeight: 'bold', marginLeft: write ? 80 : 0 }}>
                <div>{!write && '项目名称（全称）: '}{state.instance_name}</div>
                {/* <div>{!write && `收货方:${state.printJson.build_name}`}</div> */}
              </div>
              {/* <div className="lj flex-grow" style={{display: 'inline', width: '57%', fontWeight: 'bold', paddingBottom: 15 }}>
                <span style={{ paddingLeft: write ? 43 : 0, display: 'inline-block', width: '49%', boxSizing: 'border-box', fontSize: 13 }}>{!write && '日期: '}<span>{state.printJson.date}</span></span>
                <span style={{ paddingLeft: write ? 70 : 10, paddingRight: write ? 15 : 0, display: 'inline-block', width: '49%', boxSizing: 'border-box' }}>{!write && 'NO: '}<span style={{fontSize: 14, color: '#D37663'}}>{state.printJson.number}</span></span>
              </div> */}
            </div>
            <div  style={{ marginTop: 5, marginLeft: 8 }}>
              <table border={ write ? 0 : 1 } cellSpacing="0" cellPadding="5" style={{ borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'center', width: '99%' }}>
                <tbody>
                <tr>
                  <td style={{height: 35, width: '16.6%', ...borderLeft, borderBottom: '1px solid black'}}>{!write && <span>日&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;期</span>}</td>
                  <td colSpan={2} style={{width: '33.4%', ...borderLeft, borderBottom: '1px solid black'}}>{state.printJson.date}</td>
                  <td style={{width: '16.6%', ...borderLeft, borderBottom: '1px solid black'}}>{!write && <span>票&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号</span>}</td>
                  <td colSpan={2} style={{width: '34.4%', ...borderLeft, ...borderRight, borderBottom: '1px solid black'}}>{state.printJson.number}</td>
                </tr>
                <tr>
                  <td style={{height: 35, width: '16.6%', ...borderLeft, borderBottom: '1px solid black'}}>{!write && <span>车&nbsp;&nbsp;&nbsp;牌&nbsp;&nbsp;&nbsp;号</span>}</td>
                  <td colSpan={2} style={{width: '33.4%', ...borderLeft, borderBottom: '1px solid black'}}>{state.printJson.car_num}</td>
                  <td style={{width: '16.6%', ...borderLeft, borderBottom: '1px solid black'}}>{!write && <span>货&nbsp;&nbsp;物&nbsp;&nbsp;名&nbsp;&nbsp;称</span>}</td>
                  <td colSpan={2} style={{width: '33.4%', ...borderLeft, ...borderRight, borderBottom: '1px solid black'}}>{state.printJson.materielName}</td>
                </tr>                
                {/* </tbody>
              </table> */}
              {/* <table border={ write ? 0 : 1 } cellSpacing="0" cellPadding="5" style={{ borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'center', width: '99%', borderTop: 0 }}>
                <tbody> */}
                <tr style={{borderTop: 'none',}}>
                  <td style={{width: '16.6%', ...borderLeft, borderBottom: '1px solid black'}}>{!write && `标准货箱方量(m³)`}</td>
                  <td style={{width: '16.6%', ...borderLeft, borderBottom: '1px solid black', fontSize: 16, fontWeight: 'bold'}}>{state.printJson.real_volumn}</td>
                  <td style={{width: '16.8%', ...borderLeft, borderBottom: '1px solid black'}}>{!write && `标记线货箱方量(m³)`}</td>
                  <td style={{width: '16.6%', ...borderLeft, borderBottom: '1px solid black', fontSize: 16, fontWeight: 'bold'}}>{state.printJson.marked_volumn}</td>
                  <td style={{width: '16.6%', ...borderLeft, borderBottom: '1px solid black'}}>{!write && `出库（入库）实际方量(m³)`}</td>
                  <td style={{width: '16.8%', ...borderLeft, ...borderRight, borderBottom: '1px solid black', fontSize: 16, fontWeight: 'bold'}}>{state.printJson.volume}</td>
                </tr>
                <tr style={{height: 35, borderTop: 'none'}}>
                  <td style={{width: '16.6%', ...borderLeft}}>{!write && `项目单位`}</td>
                  <td style={{width: '16.6%', ...borderLeft}}>{}</td>
                  <td style={{width: '16.8%', ...borderLeft}}>{!write && `砂石竞得方`}</td>
                  <td style={{width: '16.6%', ...borderLeft}}>{}</td>
                  <td style={{width: '16.6%', ...borderLeft}}>{!write && `砂石出让方`}</td>
                  <td style={{width: '16.8%', ...borderLeft, ...borderRight}}>{}</td>
                </tr>
                </tbody>
              </table>
            </div>            
            {!write && (
              <>
                <div style={{fontSize: 12}}>第一联：存根联（白），第二联：公司财务（红），第三联：项目方（蓝），第四联：收货方（黄）</div>
                <div style={{fontSize: 12}}>说明：砂石处置，在项目现场三方（砂石堆场二方）代表人共同确认车次方量，入库和出库方量一致。</div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
