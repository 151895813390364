import React, {useState, useEffect} from 'react';
import Line from './Line';
import Materiel from './MaterielPie';
import Project from './ProjectPie';
import request, {open} from 'utils/request';
import {Button, Icon, Pagination, Select, Table, Input, DatePicker} from 'antd';
import styles from './index.scss';
import moment from "moment";
import Selector from '../../modules/ui/FormItem/Selector';
import {func} from "prop-types";

export default function SettlementCase() {
  const columns = [
    { title: '序号', key: 'serial', dataIndex: 'serial', align: 'center', width: 70},
    { title: '车牌号', dataIndex: 'car_num' },
    { title: '物品', dataIndex: 'materiel_name' },
    { title: '运距', dataIndex: 'distance', sorter: true, render: $ => ($ && ($ === '-1.00') ? '未计算' : ($ <= 1000 ? `${$}米` : `${($ / 1000).toFixed(2)}公里`)) },
    { title: '方量（m³）', dataIndex: 'volumn', sorter: true },
    { title: '趟数', dataIndex: 'count', sorter: true },
  ];
  const [query, setQuery] = useState(
    {
      page: 1,
      limit: 10,
      search: '',
      start_id: undefined,
      end_id: undefined,
      materiel_id: undefined,
      time: [
        moment().subtract(1, 'months').startOf('month'),
        moment().subtract(1, 'months').endOf('month')
      ],
      distance: 0,
      volumn: 0,
      count: 0
    }
  );

  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [materiel, setMateriel] = useState();
  const [timeStatus, setStatus] = useState(1);
  const [searchTime, setTime] = useState([
    moment().subtract(1, 'months').startOf('month'),
    moment().subtract(1, 'months').endOf('month')]);

  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [volume, setVolume] = useState(0);
  const [counts, setCounts] = useState(0);
  const [distance, setDistance] = useState(0);

  const [startArr, setArr1] = useState([]);
  const [endArr, setArr2] = useState([]);
  const [materielArr, setArr3] = useState([]);

  const [line, setLine] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [materielData, setMaterielData] = useState({});

  useEffect(() => {
    request('/web/ticket/SettlementStart')
      .then(result => setArr1(result.data.data.map(item => {
          return {start_id: item.start_department_id + ',' + item.start_id, start_name: item.start_name};
      })));
    request('/web/ticket/SettlementEnd')
      .then(result => setArr2(result.data.data.map(item => { return {end_id: item.end_department_id + ',' + item.end_id, end_name: item.end_name};})));
    request('/web/route/initMateriel', {stt: 1})
      .then(result => setArr3(result.data.data));
  }, []);

  useEffect(() => {
    let timeArr = [];
    if (query.time.length !== 0){
      timeArr = [query.time[0].format('YYYY-MM-DD'), query.time[1].format('YYYY-MM-DD')];
    }
    request('/web/ticket/SettlementAccount', {...query, time: timeArr, ...formatQuery(query.start_id, query.end_id)})
      .then(body => body.data.data)
      .then(data => {
        let arr = [];
        data.data.map((e, serial) => {
          arr.push({...e, serial: (((query.page - 1) * query.limit) + (serial + 1))})
        });
        setDataSource(arr);
        setTotal(data.total);
        setVolume(data.volumn);
        setDistance(data.distance);
        setCounts(data.count);
      });
  }, [JSON.stringify(query)]);

  useEffect(() => {
    let timeArr =[];
    if (searchTime.length !== 0) {
      timeArr = [searchTime[0].format('YYYY-MM-DD'), searchTime[1].format('YYYY-MM-DD')]
    }

    const params = {
      materiel_id: materiel,
      time_status: timeStatus,
      time: timeArr,
        ...formatQuery(start, end)
    };
    request('/web/ticket/Line', params)
      .then(result => setLine(result.data.data));
  }, [searchTime, materiel, start, end, timeStatus]);

  useEffect(() => {
    let timeArr =[];
    if (searchTime.length !== 0) {
      timeArr = [searchTime[0].format('YYYY-MM-DD'), searchTime[1].format('YYYY-MM-DD')]
    }
    request('/web/ticket/Pie', {time: timeArr})
    .then(result => {
      setProjectData(result.data.data.startData);
      setMaterielData(result.data.data.materielData);
    });
  }, [searchTime]);

  function downloadExcel() {
    let timeArr = [];
    if (query.time.length !== 0){
      timeArr = [query.time[0].format('YYYY-MM-DD'), query.time[1].format('YYYY-MM-DD')];
    }
    open("/web/ticket/SettlementAccountExcel", {...query, time: timeArr,...formatQuery(query.start_id, query.end_id)})
  }

  function formatQuery(start_value, end_value) {
      let start_department_id = '';
      let start_id = '';
      let end_department_id = '';
      let end_id = '';
      if(start_value !== undefined) {
          start_department_id = start_value.split(",")[0];
          start_id = start_value.split(",")[1]
      }
      if(end_value !== undefined) {
          end_department_id = end_value.split(",")[0];
          end_id = end_value.split(",")[1]
      }
      return {start_department_id, start_id, end_department_id, end_id};
  }

  function timeChange(value) {
    setQuery({...query, time: value});
    setTime(value);
  }
  function sort(pagination, filters, {columnKey, order}) {
    if(order === undefined) {
      setQuery({...query, distance: 0, volumn: 0, count: 0})
    }else{
      setQuery({...query, distance: 0, volumn: 0, count: 0, [columnKey]: order === 'ascend'?2:1})
    }
  }
  return (

    <div className={styles.container}>
      <div className={styles.query}>
        <div>
          <Button onClick={downloadExcel}><Icon type="download" />导出</Button>
        </div>
        <div>
          <Input.Group compact>
              <Select
                  allowClear
                  value={query.start_id}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  style={{ width: 150 }}
                  placeholder="请选择起点"
                  onChange={value => setQuery({...query, start_id: value})}>
                  {startArr.map((v, i) => {
                      return (
                          <Select.Option key={i} value={v.start_id} title={v.start_name}>{v.start_name}</Select.Option>
                      )
                  })}
              </Select>
            <Select
              allowClear
              value={query.end_id}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              style={{ width: 150 }}
              placeholder="请选择终点"
              onChange={value => setQuery({...query, end_id: value})}>
              {endArr.map((v, i) => {
                return (
                  <Select.Option key={i} value={v.end_id} title={v.end_name}>{v.end_name}</Select.Option>
                )
              })}
            </Select>
            <Select
              allowClear
              value={query.materiel_id}
              style={{ width: 150 }}
              placeholder="请选择物品"
              onChange={value => setQuery({...query, materiel_id: value})}>
              {materielArr.map((v, i) => {
                return (
                  <Select.Option key={i} value={v.id} title={v.name}>{v.name}</Select.Option>
                )
              })}
            </Select>
            <DatePicker.RangePicker
              style={{width: 300}}
              allowClear={false}
              format='YYYY-MM-DD'
              placeholder={['开始时间', '结束时间']}
              value={(query.time || []).map(item => (item ? moment(item, 'YYYY-MM-DD') : null))}
              onChange={timeChange}
            />
            <Input style={{width: 200}} placeholder={'请输入车牌号/驾驶员搜索'} onChange={e => setQuery({...query, search: e.target.value})} />
            <Button type="primary" onClick={() => setQuery({...query, page: 1})}><Icon type="search" /></Button>
          </Input.Group>
        </div>
      </div>
      <div className={styles.table}>
        <div style={{display: 'inline-block', margin: '10px 0'}}>
          运输总方量
          <span style={{margin: '0 5px'}}>{volume}方</span>
          运距
          <span style={{margin: '0 5px'}}>{(distance && (distance === '-1.00') ? '未计算' : (distance <= 1000 ? `${distance}米` : `${(distance / 1000).toFixed(2)}公里`))}</span>
          趟数
          <span style={{margin: '0 5px'}}>{counts}趟</span>
        </div>
        <Table bordered size="middle" rowKey={'id'} className={styles.table} columns={columns} dataSource={dataSource} pagination={false} onChange={sort} />
        <div className={styles.pagination}>
          <Pagination
            total={total}
            current={query.page}
            pageSize={query.limit}
            showSizeChanger={true}
            onChange={(page, limit) => setQuery({...query, page, limit})}
            onShowSizeChange={(page, limit) => setQuery({...query, page, limit})}
            showTotal={t => `共 ${t} 条`}
            disabled
          />
        </div>
      </div>
      <div className={styles.queryChart}>
        <div className={styles.queryItem}>
          <Select
            allowClear
            value={start}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            style={{ width: 150 }}
            placeholder="请选择起点"
            onChange={value => setStart(value)}>
            {startArr.map((v, i) => {
              return (
                <Select.Option key={i} value={v.start_id} title={v.start_name}>{v.start_name}</Select.Option>
              )
            })}
          </Select>
        </div>
        <div className={styles.queryItem}>
          <Select
            allowClear
            value={end}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            style={{ width: 150 }}
            placeholder="请选择终点"
            onChange={value => setEnd(value)}>
            {endArr.map((v, i) => {
              return (
                <Select.Option key={i} value={v.end_id} title={v.end_name}>{v.end_name}</Select.Option>
              )
            })}
          </Select>
        </div>
        <div className={styles.queryItem}>
          <Select
            allowClear
            value={materiel}
            style={{ width: 150 }}
            placeholder="请选择物品"
            onChange={value => setMateriel(value)}>
            {materielArr.map((v, i) => {
              return (
                <Select.Option key={i} value={v.id} title={v.name}>{v.name}</Select.Option>
              )
            })}
          </Select>
        </div>
        <div className={styles.queryItem}>
          <Select
            style={{ width: 150 }}
            value={timeStatus}
            placeholder="请选择时间"
            onChange={value => setStatus(value)}>
            <Select.Option value={2}>按天统计</Select.Option>
            <Select.Option value={1}>按月统计</Select.Option>
          </Select>
        </div>
      </div>
      <div>
          <Line data={line} />
      </div>
      <div className={styles.pie}>
        <div>
          <Project data={projectData}/>
        </div>
        <div>
          <Materiel data={materielData} />
        </div>
      </div>
    </div>
  )
}
