import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import debug from 'utils/debug';
import { dataSource } from './List';
import {reasonArr} from '../share'

const inputs = [
  { label: '所属区县', placeholder: '请选择区县', key: 'area_id', type: 'select', options: '/web/blacklist/getAreaLists', valueKey: 'id', labelKey: 'name', required: true, style: { width: 350 } },
  { label: '运企名称', placeholder: '请选择运企', key: 'transport_id', type: 'select', options: '/web/blacklist/getTransportLists', rely: 'area_id', valueKey: 'id', labelKey: 'name', required: true, style: { width: 350 } },
  { label: '车辆', placeholder: '请选择车辆', key: 'car_number', type: 'select', options: '/web/blacklist/getCarLists', valueKey: 'car_num', labelKey: 'car_num', relies: [ 'transport_id'],  required: true, style: { width: 350 } },
  { label: '关联驾驶员', placeholder: '请选择关联驾驶员', key: 'driver_id', type: 'select', options: 'web/gb_list/getDrivers', relies: ['transport_id', 'car_number'], valueKey: 'driver_id', labelKey: 'driver_name', style: { width: 350 } },
  { label: '违规事由', placeholder: '请选择违规事由', key: 'reason', type: 'select', options: reasonArr, valueKey: 'value', labelKey: 'label', required: true, style: { width: 350 } },
  { label: '创建时间', placeholder: '请选择创建时间', key: 'created_time', type: 'date', showTime: true, required: true, style: { width: 350 } },
];

export default function Create({ history }) {
  debug.render('Create');

  const back = () => history.goBack();
  const onFinish = () => {
    dataSource.refresh();
    back();
  };

  return (
    <ModalFormPage
      submit={['/web/gb_list/createVerifyBill']}
      title="新增"
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
      modalProps={{ width: 600 }}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}
    />
  );
}
