import React, { useState, useEffect, useMemo } from 'react';
import { Icon, Input, message, Modal, Spin, Table, Button, Text } from "antd";
import echarts from 'echarts';
import request from 'utils/request';
import ModalFormPage from 'ui/ModalFormPage';
import TrashPhotos from './TrashPhotos';
import store from 'stores/user';
import '../css/trash.global.scss';
import '../css/CommandCenter.scss';

const { confirm } = Modal;
import { ImagePreviewer } from 'components/Previewer';
import moment from 'moment';

const drawDetailMap = function(linesData, planLinesData, detailMap){
  let series = [];
  if(planLinesData[0].coords.length === 0){
    planLinesData = [];
  }
  let center = [103.836776, 30.697996];
  if(linesData[0].coords.length > 1){
    center =linesData[0].coords[0];
    series =[
      {
        type: 'lines',
        coordinateSystem: 'bmap',
        name: 'plan_line',
        polyline: true,
        data: planLinesData,
        effect: {
          show: false
        },
        tooltip:{

        },
        silent: true,
        lineStyle: {
          normal: {
            color: "#ef466e",
            width: 5,
            opacity: 1,
            shadowColor: 'rgba(0, 0, 0, 0.2)',
            shadowBlur: 4
          }
        },
        progressiveThreshold: 400,
        progressive: 3000
      },
      {
        type: 'lines',
        coordinateSystem: 'bmap',
        name: 'line',
        polyline: true,
        data: linesData,
        effect: {
          show: true,
          period: 11,
          symbol:'image://https://tfblue.shomes.cn/static/images/gov/car/fushi.png',
          trailLength: 0,
          symbolSize: [14, 34],
        },
        silent: true,
        lineStyle: {
          color: "#03d5a0",
          width: 5,
          opacity: 1,
          shadowColor: 'rgba(0, 0, 0, 0.2)',
          shadowBlur: 4,

        },
        progressiveThreshold: 400,
        progressive: 3000
      },

    ]
  }
  var option = {
    tooltip : {
      trigger: 'item'
    },
    bmap: {
      center: center,
      zoom: 14,
      roam: true,
      mapStyle: {}
    },
    series : series
  };
  detailMap.setOption(option, true);
};

const inputs = [
  {
    label: '起点',
    children: [
      { placeholder: '区域', allowClear: false, key: 'start_area_id', type: 'select', options: '/web/Gps_Fault/area_list', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
      { placeholder: '起点部门', allowClear: false, key: 'start_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, rely: 'start_area_id', relyAs: 'area_id', style: { width: 120 } },
      { placeholder: '起点', allowClear: false, key: 'start_id', type: 'select', options: '/web/instance/getStartOrEndSupplement', extraData: { types: 1 }, valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['start_area_id', 'start_department_id'], reliesAs:['area_id', 'id'], required: true, style: { width: 240 } },
    ],
  }
];
const endInputs = [
  {
    label: '终点',
    children: [
      { placeholder: '区域', allowClear: false, key: 'end_area_id', type: 'select', options: '/web/Gps_Fault/area_list', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
      { placeholder: '终点部门', allowClear: false, key: 'end_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, rely: 'end_area_id', relyAs: 'area_id', style: { width: 120 } },
      { placeholder: '终点', allowClear: false, key: 'end_id', type: 'select', options: '/web/instance/getStartOrEndSupplement', extraData: { types: 1 }, valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['end_area_id', 'end_department_id'], reliesAs:['area_id', 'id'], required: true, style: { width: 240 } },
    ],
  }
];
const materialInputs = [
  { label: '物料', placeholder: '物料', key: 'materiel_id', type: 'select', options: '/web/route/initMateriel', valueKey: 'id', labelKey: 'name', required: true, style: { width: 195 } }
];

function useEditShow(id) {
  const [data, setData] = useState({});
  useEffect(() => {
    request('/web/ticket/editShow', { id })
      .then((body) => body.data.data)
      .then(setData);
  }, [id]);
  return data;
}

export default function TicketDetail({areaId, dayStr, data, style, radar, ticketId, carModify, ticketType, callType=true, trashSpin=false, ...res }){
  const [detailInfo, setDetailInfo] = useState({});
  const detailes = useEditShow(ticketId);
  const [materielId, setMaterielId] = useState(detailes.materiel_id);
  const [img_flag, setImgFlag] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [newCar, setNewCar] = useState('');
  /** 是否显示修改起点 */
  const [modifyFlag, setModifyFlag] = useState(false);
  const [modifyEnd, setModifyEnd] = useState(false);
  const [addProof, setAddProof] = useState(false);
  const [modifyMaterial, setModifyMaterial] = useState(false);
  /** 联单操作记录是否显示 */
  const [ticketLog, setTicketLog] = useState(false);
  /** 是否确认重车密闭 */
  const [shelterOut, setShelterOut] = useState(false);

  useEffect(() => {
    if (!detailes) return;
    setMaterielId(detailes ? detailes.materiel_id : 0);
    setNewCar(detailes ? detailes.car_num : 0);
  }, [JSON.stringify(detailes)]);
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.src = `https://api.map.baidu.com/getscript?v=3.0&ak=qUVLyYPbiGRhc6p8w9zHMxYscd0No22v&services=&t=${(new Date).getTime()}`;
  //   document.head.appendChild(script);
  // });
  useEffect(() => {
    setDetailInfo(data.detailInfo);
    let detailMap = echarts.init(document.getElementById("detail_map"));
    drawDetailMap(data.linesData, data.planLinesData, detailMap);
  }, [data]);
  function getIllegal(arr) {
    if(arr){
      if(arr.length === 0){
        return '无违规行为'
      }
      return arr.join(',')
    }
    else{
      return ''
    }
  }
  function showImages(val) {
    setImgFlag(true);
    setImgSrc(val);
  }
  function hideImage(){
    setImgFlag(false);
  }
  function getCallDriver(phone, id){
    return callType ? <span style={{color: "#03d5a0",marginLeft: '5px', cursor: 'pointer'}} onClick={() => {startCallDriver(phone, id)}}>可呼叫</span> : ''
  }
  function startCallDriver(phone, id){
    res.startCallDriver(phone, id);
  }
  function closeTicketDetail(){
    res.closeTicketDetail();
  }
  function modifyCar(car_num){
    let new_car_num = car_num;
    confirm({
      title: '修改联单车牌?',
      width: 600,
      content: (
        <div>
          <span>请输入车牌号: </span>
          <Input style={{width: 300}} defaultValue={new_car_num} onChange={(e) => {new_car_num = e.target.value}}/>
        </div>
      ),
      onOk() {
        if(new_car_num === ''){
          message.warn('请输入修改后的车牌号！');
        }else {
          request('/web/ticket/editCarNumber', {id: ticketId, car_num: new_car_num })
            .then(body => { body.data.code === 200 ? message.success('修改车牌号成功') : message.warn(body.data.msg)})
            .finally(() => {setNewCar(new_car_num);res.modifyCarFunc(2, ticketId, new_car_num)})
        }
      },
      onCancel() {
      },
    });
  }
  function deleteTicket(){
    confirm({
      title: '提示',
      content: '确认删除该联单',
      width: 400,
      onOk(){
        request('web/ticket/deleted', {id: ticketId, deleted_reason: '运输雷达删除联单'})
          .then(body => body.data.code === 200 ? message.success('删除成功') : message.warn(body.data.msg))
          .finally(() => {res.modifyCarFunc(1, ticketId);res.closeTicketDetail()})
      },
      onCancel() {
      },
    })
  }
  const onFinish = (data) => {
    const params = {
      id: ticketId,
      start_department_id: data.start_department_id,
      start_id: data.start_id,
      start_area_id: data.start_area_id,
    };
    request('/web/ticket/edit', params)
      .then(body => body.data)
      .then(({ code, msg, ...item }) => {
        if (code === 200) {
          setModifyFlag(false);
          setDetailInfo({...detailInfo, start_name: item.data.start_name});
          res.modifyCarFunc(3, ticketId, item.data.start_name);
          message.success('修改起点成功');
        } else {
          const warn = typeof msg === 'string' ? msg : '保存失败!';
          message.warn(warn);
        }
      })
  };
  const onFinishEnd = (data) => {
    const params = {
      id: ticketId,
      end_department_id: data.end_department_id,
      end_id: data.end_id,
      end_area_id: data.end_area_id,
    };
    request('/web/ticket/edit', params)
      .then(body => body.data)
      .then(({ code, msg, ...item }) => {
        if (code === 200) {
          setModifyEnd(false);
          setDetailInfo({...detailInfo, end_company: item.data.end_name});
          res.modifyCarFunc(4, ticketId, item.data.end_name);
          message.success('修改终点成功');
        } else {
          const warn = typeof msg === 'string' ? msg : '保存失败!';
          message.warn(warn);
        }
      })
  };
  const onFinishMaterial = (data) => {
    const params = {
      id: ticketId,
      materiel_id: data.materiel_id,
    };
    request('/web/ticket/edit', params)
      .then(body => body.data)
      .then(({ code, msg, ...item }) => {
        if (code === 200) {
          setMaterielId(data.materiel_id);
          setModifyMaterial(false);
          setDetailInfo({...detailInfo, materiel_name: item.data.materiel_name});
          res.modifyCarFunc(5, ticketId, item.data.materiel_name);
          message.success('修改物料成功');
        } else {
          const warn = typeof msg === 'string' ? msg : '保存失败!';
          message.warn(warn);
        }
      })
  };
  const onAddProof = (data) => {
    const params = {
      id: ticketId,
      medias: data.medias,
    };
    if(!data.medias) return;
    request('/web/ticket/edit', params)
      .then(body => body.data)
      .then(({ code, msg, ...item }) => {
        if (code === 200) {
          setAddProof(false);
          // setDetailInfo({...detailInfo, materiel_name: item.data.materiel_name});
          // res.modifyCarFunc(5, ticketId, item.data.materiel_name);
          message.success('补充证据成功');
        } else {
          const warn = typeof msg === 'string' ? msg : '保存失败!';
          message.warn(warn);
        }
      })
  };
  const proofInputs = [
    { label: '补充证据', key: 'medias', type: 'lists', modify: true, ticketId,
      initList: {created_time: '', type: '', url: '' },
      lists: [
        { type: 'date', key: 'created_time', label: '创建时间' },
        { type: 'select', key: 'type', label: '证据类型' },
        { type: 'upload', key: 'url', label: '证据图片' },
      ]
    }
  ];
  const [ticketConfirm, setTicketConfirm] = useState(false);
 

  /** 问题联单相关 */
  const problemArr = ['', '无', '疑似问题', '问题联单', '正常联单'];
  const [problemTicket, setProblemTicket] = useState(false);
  const onProblem = (data) => {    
    const params = { id: ticketId, ...data };  
    request('/web/homemap/editTicketProblem', params)
      .then((body) => body.data)
      .then(({ code, msg }) => {
        if (code === 200) {
          setDetailInfo({...detailInfo, problem: data.problem });
          setProblemTicket(false);
          message.success(msg);
          // res.modifyCarFunc(6, ticketId, data.balanceAccounts);
        } else {
          const warn = typeof msg === 'string' ? msg : '保存失败!';
          message.warn(warn);
          setProblemTicket(false);
        }
      })
  }
  const [photos, setPhotos] = useState();
  useEffect(() => {
    if (!detailInfo || JSON.stringify(detailInfo) === '{}') return;
    const a = detailInfo.start_in_photo.split(',');
    const b = detailInfo.start_out_photo.split(',');
    const c = detailInfo.end_in_photo.split(',');
    const d = detailInfo.end_out_photo.split(',');
    const photos = a.concat(b).concat(c).concat(d).filter((item) => item.length > 10);
    setPhotos(photos);
  }, [detailInfo]);
  const storeInfo = store.getState();
  const userId = storeInfo.id;
  const roleId = storeInfo.roles.id;
  const isZx = (roleId === 111 || roleId === 115 || roleId === '111');
  /** 坐席复核
   * 1、判断是否是昨日联单
   * 2、对auth进行判断
  */
  const [ticketAuth, setTicketAuth] = useState(false);
  const [ticketAuthReason, setTicketAuthReason] = useState(false);
  const [isFh, setIsFh] = useState(false); // 是否复核复核错误

  const zxAuth = useMemo(() => {
    const time = detailInfo.opLogCreatedAt ? detailInfo.opLogCreatedAt : Math.ceil(moment() / 1000);
    const { auth, auth_text, balance_accounts, opLogCreatedBy, auth2, authReason2 } = detailInfo;
    const n = moment(time * 1000); // 联单的处理时间
    const s = moment().startOf('day'); // 今日零点
    const e = moment().subtract(1, 'day').startOf('day').subtract(1, 'second'); // 昨日零点
    const flag = n.isBetween(e, s);
    const judge = (flag && auth === 1 && roleId === 115 && [2, 3, 4].includes(balance_accounts)) || auth === 3;
    return (
      <span style={{display: 'flex', alignItems: 'center', color: judge ? '#1491fb' : '#d9d9d9'}}>       
        <span style={{ cursor: judge ? 'pointer' : 'not-allowed' }} onClick={() => {
          if (judge) {
            if (flag && auth === 1 && roleId === 115) setTicketAuth(true);
            if (auth === 3) setTicketAuthReason(detailInfo.auth_reason);
          }        
        }}>{auth_text}</span>
        {(auth === 3 && auth2 === 2 && userId === opLogCreatedBy) && <span onClick={() => setIsFh({authReason: authReason2, isMofify: true})} style={{ marginLeft: 7, color: '#FF2626', borderColor: '1px solid #FF2626', borderRadius: 2, padding: '2px 4px'}}>复核人复核错误</span>}
        {(auth === 3 && auth2 === 3) && <span onClick={() => setIsFh({authReason: authReason2, isMofify: false})} style={{ marginLeft: 7, color: '#fff', backgroundColor: '#FF2626', borderRadius: 2, padding: '2px 4px'}}>已验证复核人复核错误</span>}
      </span>
    );
  }, [JSON.stringify(detailInfo)]);
  const balanceAccountsArr = ['', '待确认', '无需结算', '未结算', '纳入结算', '无'];
  // 是否已轧账  非0就是已轧账，不可以再进行其他操作
  const isGz = useMemo(() => detailInfo.ticket_account_id === 0, [JSON.stringify(detailInfo)]);  

  return (
    <>
      <div id="detail_box" className="right-box" style={style}>
        <Spin spinning={trashSpin}>
          <div className="flex flex-main-between" style={{height: 20, marginBottom: 10}}><span style={{height: 20, color:'#1ea3ef'}}>联单详情</span>
            <div>
              { (ticketType !== 10 && isGz) ? <span style={{marginRight: 10, cursor: 'pointer', color: '#1491fb'}} onClick={() => setModifyFlag(true)}>修改起点</span> : null}
              { (ticketType !== 10 && isGz) ? <span style={{marginRight: 10, cursor: 'pointer', color: '#1491fb'}} onClick={() => setModifyEnd(true)}>修改终点</span> : null}
              { isZx ? <span style={{marginRight: 10, cursor: 'pointer', color: '#1491fb'}} onClick={() => setAddProof(true)}>补充证据</span> : null}
              { (isZx && isGz) ? <span style={{marginRight: 10, cursor: 'pointer', color: '#1491fb'}} onClick={() => setTicketConfirm(true)}>结算确认</span> : null}
              { (ticketType !== 10 && radar && isGz) ? <span style={{marginRight: 10, cursor: 'pointer', color: '#1491fb'}} onClick={() => deleteTicket()}>删除</span> : null}
              <Icon type="close" style={{cursor: 'pointer'}} onClick={() => {closeTicketDetail()}} /></div>
          </div>
          {photos && photos.length > 0 && <TrashPhotos src={photos} />}
          <table id="detail_table" style={{marginTop: 10}}>
            <tbody>
            <tr>
              <td style={{width: 80}}>联单号</td>
              <td style={{width: 123}} className="detail-td" data-value="uuid">{detailInfo['number']}</td>
              <td style={{width: 70}}>生成方式</td>
              <td className="detail-td" data-value="create_type">{detailInfo['added_ticket']}</td>
            </tr>
            <tr>
              <td>联单结算状态</td>
              <td className="detail-td">{balanceAccountsArr[detailInfo['balance_accounts']]}</td>    
              <td>问题联单</td>
              <td>
                {(!isZx || !isGz) ? problemArr[detailInfo['problem']] : (
                  <span style={{ cursor: 'pointer', color: '#1491fb'}} onClick={() => setProblemTicket(true)}>{problemArr[detailInfo['problem']]}</span>
                )}
              </td>
            </tr>
            <tr>
              <td>重车篷布密闭出场</td>
              {detailInfo.shelterOut === 1 ? (
                <td colSpan={isZx ? 1 : 3}>是</td> ) :
                <td colSpan={isZx ? 1 : 3} style={{ color: '#1491fb', cursor: 'pointer' }} onClick={() => setShelterOut(true)}>否</td>
              }
                           
              {isZx && (
                <>
                  <td>联单操作记录</td>
                  <td>
                    <span style={{cursor: 'pointer', color: '#1491fb'}} onClick={() => setTicketLog(true)}>查看</span>
                  </td>
                </>
              )}
            </tr>
            {isZx ? (
              <tr>
                <td>内审复核</td>
                <td colSpan={3}>
                  {zxAuth}
                </td>
              </tr>
            ) : null}
            <tr>
              <td>轧账状态</td>
              <td colSpan={3}>{isGz ? '未轧账' : '已轧账'}</td>
            </tr>
            <tr>
              <td>排放单位</td>
              <td colSpan="3" className="detail-td" data-value="pfdw">{detailInfo['startDevice'] ? <img src='/images/video.png' style={{ width: 20 }} /> : null}{detailInfo['start_name']}</td>
            </tr>
            <tr>
              <td>排放证</td>
              <td className="detail-td" data-value="pfz">{detailInfo['emission_num']}</td>
              <td>排放有效期</td>
              <td className="detail-td" data-value="pfyxq">{detailInfo['emission_expiry_date']}</td>
            </tr>
            <tr>
              <td>开单员</td>
              <td className="detail-td" data-value="open_user_name">{detailInfo['opener']}</td>
              <td>电话</td>
              <td className="detail-td" data-value="open_user_phone">{detailInfo['opener_phone']}</td>
            </tr>
            {/*<tr>*/}
              {/*<td>是否安装摄像头</td>*/}
              {/*<td colSpan="3">{detailInfo['startDevice'] ? '是' : '否' }</td>*/}
            {/*</tr>*/}
            <tr>
              <td>运输单位</td>
              <td className="detail-td" data-value="transport_name">{detailInfo['transport']}</td>
              <td>运输车</td>
              {
                (carModify && isGz) ? <td className="detail-td" data-value="car_num" onClick={() => {radar ? modifyCar(newCar ? newCar : detailInfo['car_num']) : null}}><span style={{color: radar ? '#1491fb' : '', cursor: radar ? 'pointer' : 'default'}}>{newCar ? newCar : detailInfo['car_num']}</span></td> : <td>{detailInfo['car_num']}</td>
              }
            </tr>
            <tr>
              <td>运输证</td>
              <td className="detail-td" data-value="ysz">{detailInfo['transport_license']}</td>
              <td>运输有效期</td>
              <td className="detail-td" data-value="ysyxq">{detailInfo['transport_license_time']}</td>
            </tr>
            <tr>
              <td>司机</td>
              <td className="detail-td" data-value="driver_name">{detailInfo['driver']}{detailInfo['driver'] !== '' && detailInfo['driver'] !== null ? getCallDriver(detailInfo['driver_phone'], detailInfo['user_id']) : ''}</td>
              <td>电话</td>
              <td className="detail-td" data-value="driver_phone">{detailInfo['driver_phone']}</td>
            </tr>
            <tr>
              <td>处置单位</td>
              <td colSpan="3" className="detail-td" data-value="czdw">{detailInfo['endDevice'] ? <img src='/images/video.png' style={{ width: 20 }} /> : null}{detailInfo['end_company']}</td>
            </tr>
            <tr>
              <td>处置地点</td>
              <td className="detail-td emphasis" title={detailInfo['end_address']}>{detailInfo['end_address']}</td>
              <td>处置方式</td>
              <td className="detail-td" data-value="czfs">{detailInfo['end_type']}</td>
            </tr>
            <tr>
              <td>收单员</td>
              <td className="detail-td" data-value="confirm_user_name">{detailInfo['acquirer_name']}</td>
              <td>电话</td>
              <td className="detail-td" data-value="confirm_user_phone">{detailInfo['acquirer_phone']}</td>
            </tr>
            {/*<tr>*/}
              {/*<td>是否安装摄像头</td>*/}
              {/*<td colSpan="3">{detailInfo['endDevice'] ? '是' : '否' }</td>*/}
            {/*</tr>*/}
            <tr>
              <td colSpan="4">排放</td>
            </tr>
            <tr>
              <td>开单时间</td>
              <td className="detail-td" data-value="create_time">{detailInfo['open_time']}</td>
              <td>运输物料</td>
              <td className="detail-td" data-value="open_load_type">
                {
                  (ticketType !== 10 && isGz && detailInfo.acquirer !== 1) ? (
                    detailInfo['materiel_name'] === '' ?
                      <span style={{ color: '#1491fb', cursor: 'pointer' }} onClick={() => setModifyMaterial(true)}>修改</span> :
                      <span style={{ color: '#1491fb', cursor: 'pointer' }} onClick={() => setModifyMaterial(true)}>{detailInfo['materiel_name']}</span>
                  ) : detailInfo['materiel_name']
                }
              </td>
            </tr>
            <tr>
              <td>进场照片</td>
              <td id="start_in_photo">{detailInfo.start_in_photo !== '' && detailInfo.start_in_photo !== '无' ?  <span onClick={ () => { showImages(detailInfo.start_in_photo)} } style={{color: '#1491fb', cursor: 'pointer'}} className="look_out_pic">点击查看</span> :  <span className="no-pic">无</span> }</td>
              <td>离场照片</td>
              <td id="open_out_pic">{detailInfo.start_out_photo !== '' && detailInfo.start_out_photo !== '无' ? <span onClick={ () => { showImages(detailInfo.start_out_photo)} } style={{color: '#1491fb', cursor: 'pointer'}} className="look_out_pic">点击查看</span> :  <span className="no-pic">无</span> }</td>
            </tr>
            <tr>
              <td>装车照片</td>
              <td id="open_out_pic" colSpan={(detailInfo.number === 'WJW202008310044' || detailInfo.number === 'FS202009030059') ? 1 : 3}>{detailInfo.open_photo !== '' && detailInfo.open_photo !== '无' ? <span onClick={ () => { showImages(detailInfo.open_photo)} } style={{color: '#1491fb', cursor: 'pointer'}} className="look_out_pic">点击查看</span> :  <span className="no-pic">无</span> }</td>
              {(detailInfo.number === 'WJW202008310044' || detailInfo.number === 'FS202009030059') && (
                <>
                  <td>RFID</td>
                  <td ><span onClick={ () => {
                    const img = detailInfo.number === 'WJW202008310044' ?
                      'https://file.shomes.cn/minio/file/3779881/e82aa977-d764-4d7c-bcc1-df0ac73277e2.jpg,https://file.shomes.cn/minio/file/3820624/e98d0c13-2d66-420c-bda4-9b74ec48efeb.jpg' :
                      'https://file.shomes.cn/minio/file/3982921/f59c7041-c498-47a3-b0e9-d2ad6c3ea3d3.jpg,https://file.shomes.cn/minio/file/3982923/1b0d3de5-0052-4f74-bab6-f8102a2c9e28.jpg';
                    showImages(img)}
                  } style={{color: '#1491fb', cursor: 'pointer'}} className="look_out_pic">
                    {detailInfo.number === 'WJW202008310044' ? '6ceca1ffecc9' : '6ceca1feaac6'}
                  </span></td>
                </>
              )}
            </tr>            
            <tr>
              <td colSpan="4">处置</td>
            </tr>
            <tr>
              <td>收单时间</td>
              <td className="detail-td" data-value="finish_time" style={{whiteSpace: 'nowarp'}}>{detailInfo['acquirer_time']}</td>
              <td>接收物料</td>
              <td className="detail-td" data-value="confirm_load_type">{detailInfo['acquirer_materiel']}</td>
            </tr>
            <tr>
              <td>进场照片</td>
              <td>{ detailInfo.end_in_photo !== '' && detailInfo.end_in_photo !== '无' ? <span className="look_out_pic" style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { showImages(detailInfo.end_in_photo)}}>点击查看</span> : <span className="no-pic">无</span> } </td>
              <td>离场照片</td>
              <td id="confirm_out_pic">{ detailInfo.end_out_photo !== '' && detailInfo.end_out_photo !== '无' ? <span className="look_out_pic" style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { showImages(detailInfo.end_out_photo)}}>点击查看</span> : <span className="no-pic">无</span> } </td>
            </tr>
            <tr>
              <td>实际方量</td>
              <td className="detail-td" data-value="load">{detailInfo['volumn']}方</td>
              <td>卸货照片</td>
              <td id="confirm_out_pic">{ detailInfo.put_photo !== '' && detailInfo.put_photo !== '无' ? <span className="look_out_pic" style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { showImages(detailInfo.put_photo)}}>点击查看</span> : <span className="no-pic">无</span> } </td>
            </tr>
            <tr>
              {(detailInfo.number === 'FS202009030059' || detailInfo.number === 'WJW202008310044') && (
                <>
                  <td>RFID</td>
                  <td colSpan={3}><span onClick={() => {
                    const img = detailInfo.number === 'WJW202008310044' ?
                      'https://file.shomes.cn/minio/file/3785644/fe291be9-a486-49fd-81cd-c3754b6817bc.jpg,https://file.shomes.cn/minio/file/3792225/b2cfc120-f264-4658-9635-5c70427135ea.jpg' :
                      'https://file.shomes.cn/minio/file/3989639/a0850666-5aa5-4484-ad2b-c157e0d8f8d1.jpg,https://file.shomes.cn/minio/file/3989645/c03b71e1-c79e-4c7e-bb1b-b661dca62dd6.jpg';
                    showImages(img)}
                  } style={{color: '#1491fb', cursor: 'pointer'}} className="look_out_pic">{
                    detailInfo.number === 'WJW202008310044' ? '6ceca1ffeccc' : '6ceca1feaa97'
                  }</span></td>
                </>
              )}
            </tr>
            <tr>
              <td colSpan="4">结算</td>
            </tr>
            <tr>
              <td>状态</td>
              <td className="detail-td" data-value="js_status">{detailInfo['account_status']}</td>
              <td>总金额</td>
              <td className="detail-td" data-value="js_money">{detailInfo['account_price']}</td>
            </tr>
            <tr>
              <td>时间</td>
              <td className="detail-td" data-value="js_time">{detailInfo['account_time']}</td>
              <td>卡口照片</td>
              <td id="confirm_out_pic">{ detailInfo.bayonet !== '' && detailInfo.bayonet !== '无' ? <span className="look_out_pic" style={{color: '#1491fb', cursor: 'pointer'}} onClick={ () => { showImages(detailInfo.bayonet)}}>点击查看</span> : <span className="no-pic">无</span> } </td>
            </tr>
            <tr>
              <td colSpan="4">运输（*绿色线路为实际线路，红色线路为规划线路）</td>
            </tr>
            <tr>
              <td colSpan="4" style={{height:140}}>
                <div id="detail_map" style={{width:430, height:130}}>{}</div>
                <div id="illegal_box">
                  <div className="illegal-item" style={{background: 'green'}}>{getIllegal(detailInfo["alarm_record"])}</div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          {
            img_flag ? <ImagePreviewer images={ imgSrc } visiable={img_flag} onChange={visiable => hideImage({ visiable })} />
              : null
          }
        </Spin>
      </div>
      {/* <div className='trans-road'>
        {(data && data.planRoute && data.planRoute.length === 0) ? (
          <div className="none-route">未申报运输线路</div>
        ) : null}
        {(data && data.planRoute && data.planRoute.length > 0) &&<div className="has-route">已申报运输线路</div>}
        <div className="plans">
          {(data && data.planRoute) && data.planRoute.map((item, i) => (
            <div key={i}>
              <span className="titles">{`线路${i + 1}终点`}</span>
              <span className="items" title={item}>{item}</span>
            </div>
          ))}
        </div>
      </div> */}      
      {modifyFlag ? (
        <ModalFormPage
          init={['/web/ticket/editShow', { id: ticketId }]}
          title="修改电子联单"
          inputs={inputs}
          onFinish={onFinish}
          onCancel={() => setModifyFlag(false)}
          modalProps={{
            width: 800,
          }}
          formProps={{
            labelCol: { xs: { span: 4 } },
            wrapperCol: { xs: { span: 20 } },
          }}
        />
      ) : null}
      {modifyEnd ? (
        <ModalFormPage
          init={['/web/ticket/editShow', { id: ticketId }]}
          title="修改电子联单"
          inputs={endInputs}
          onFinish={onFinishEnd}
          onCancel={() => setModifyEnd(false)}
          modalProps={{
            width: 800,
          }}
          formProps={{
            labelCol: { xs: { span: 4 } },
            wrapperCol: { xs: { span: 20 } },
          }}
        />
      ) : null}
      {modifyMaterial ? (
        <ModalFormPage
          init={['/web/ticket/editShow', { id: ticketId }]}
          title="修改电子联单"
          inputs={materialInputs}
          onFinish={onFinishMaterial}
          onCancel={() => setModifyMaterial(false)}
          modalProps={{
            width: 800,
          }}
          formProps={{
            labelCol: { xs: { span: 4 } },
            wrapperCol: { xs: { span: 20 } },
          }}
        />
       ) : null}
      {addProof && (
        <ModalFormPage
          title={'补充证据'}
          init={['/web/ticket/editShow', { id: ticketId }]}
          inputs={proofInputs}
          onFinish={onAddProof}
          onCancel={() => setAddProof(false)}
          modalProps={{
            width: 800,
          }}
          formProps={{
            labelCol: { xs: { span: 4 } },
            wrapperCol: { xs: { span: 20 } },
          }}
        />
      )}
      {ticketConfirm && (
        <结算确认Modal
          ticketId={ticketId}
          materielId={materielId}
          detailInfo={detailInfo}
          onFinishMaterial={(materiels) => onFinishMaterial(materiels)}
          onClose={() => setTicketConfirm(false)}
          onSuccess={() => {
            setDetailInfo({...detailInfo, is_arrive: data.is_arrive, is_offline: data.isOffline, remark: data.remark, opLogFile: data.file, balance_accounts: data.balanceAccounts });
            setTicketConfirm(false);
            message.success('结算确认成功');
            res.modifyCarFunc(6, ticketId, data.balanceAccounts);
          }}
        />
      )}
      {problemTicket && (
        <ModalFormPage
          title="处理问题联单"
          inputs={[
            {key: 'problem', label: '是否问题联单', required: true, type: 'radioGroup', options: [{ id: 3, name: '问题联单'}, { id: 4, name: '正常联单'}], valueKey: 'id', labelKey: 'name'}
          ]}
          defaultValue={{problem: detailInfo['problem']}}
          onFinish={onProblem}
          onCancel={() => setProblemTicket(false)}
          modalProps={{
            width: 800,
          }}
          formProps={{
            labelCol: { xs: { span: 4 } },
            wrapperCol: { xs: { span: 20 } },
          }}
        />
      )}
      {ticketLog && <联单操作记录 id={ticketId} onClose={() => setTicketLog(false)} />}
      {ticketAuth && <坐席复核 id={ticketId} onClose={() => setTicketAuth(false)} onSuccess={({auth, auth_text, auth_reason}) => {setTicketAuth(false); setDetailInfo((e) => ({ ...e, auth, auth_text, auth_reason }));}} />}
      {ticketAuthReason && <复核错误 authReason={ticketAuthReason} onClose={() => setTicketAuthReason(false)} />}
      {isFh && <复核复核错误 id={ticketId} authReason={isFh.authReason} isMofify={isFh.isMofify} onSuccess={({ auth2, authReason2 }) => {setIsFh(false); setDetailInfo((e) => ({ ...e, auth2, authReason2 }));}} onClose={() => setIsFh(false)} />}
      {shelterOut && <篷布密闭 id={ticketId} onClose={() => setShelterOut(false)} onSuccess={({ shelterOut }) => {setShelterOut(false); setDetailInfo((e) => ({ ...e, shelterOut }));} } />}
    </>
  );
}

function 联单操作记录({ id, onClose }) {
  const [data, setData] = useState();
  const [imgSrc, setImgSrc] = useState();
  const [files, setFiles] = useState();

  useEffect(() => {
    request('/web/ticket/logs', { id, logType: 'ticket' })
      .then((res) => setData(res.data.data))
  }, [id]);

  function ShowFiles(src){
    if(src.indexOf('.pdf') > -1 || src.indexOf('.xls') > -1 || src.indexOf('.xlsx') > -1 || src.indexOf('.doc') > -1 || src.indexOf('.docx') > -1 || src.indexOf('.txt') > -1){
      window.open(src)
    }
    else{
      setImgSrc(src);
    }
  }

  function ShowFile({file_url, file_name}) {
    return <span style={{color: '#1491f8', cursor: 'pointer', whiteSpace: 'nowrap'}} onClick={() => ShowFiles(file_url)}>{file_name}</span>
  }

  return (
    <React.Fragment>
      <Modal
        title="联单操作记录"
        visible={true}
        onCancel={onClose}
        onOk={onClose}
        width={800}
        bodyStyle={{ maxHeight: '70vh', overflow: 'auto'}}
      >
        <Table
          columns={[
            {title: '操作项', dataIndex: 'deal_type'},
            {title: '操作时间', dataIndex: 'created_at', render: (time) => moment(time * 1000).format('YYYY-MM-DD HH:mm:ss')},
            {title: '操作人账号', dataIndex: 'username'},
            {title: '备注', dataIndex: 'remark', render: (remark, { file }) => {
              if (!file || file.length === 0) return remark;
              else if(file && file.length === 1) return ShowFile({file_url: file[0].file_url, file_name: remark});
              else if (file && file.length > 1) return <span style={{color: '#1491f8', cursor: 'pointer', whiteSpace: 'nowrap'}} onClick={() => setFiles(file)}>{remark}</span>
            }},
            {title: '操作人', dataIndex: 'user_name', render: (_, row) => `${row.area_name}-${row.department_name}-${row.position_name}-${row.user_name}`},
          ]}
          scroll={true}
          dataSource={data ? data : []}
          pagination={false}
        />
      </Modal>
      {imgSrc && <ImagePreviewer images={imgSrc} visiable={imgSrc} onChange={(visiable) => setImgSrc(false)} />}
      {files && (
        <Modal
          title="查看附件"
          visible={true}
          onCancel={() => setFiles()}
          onOk={() => setFiles()}
          width={700}
          bodyStyle={{ maxHeight: '70vh', overflow: 'auto'}}
        >
          <div style={{ display: "grid", rowGap: 5 }}>
            {files && files.map(({ file_url, file_name }) => (
              <ShowFile key={file_url} file_url={file_url} file_name={file_name} />
            ))}
          </div>          
        </Modal>
      )}
    </React.Fragment>
  );
}

function 复核错误({ authReason, onClose }) {

  return (
    <ModalFormPage
      title="复核结果"
      inputs={[{key: 'auth_reason', label: '错误原因', type: 'textarea', placeholder: '复核选择“错误”，必须填写错误原因'}]}
      defaultValue={{ auth_reason: authReason }}
      onFinish={onClose}
      onCancel={onClose}
      modalProps={{
        width: 400,
      }}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}
    />
  );
}

function 复核复核错误({ id, authReason, isMofify, onClose, onSuccess }) {
  const inputs = [
    {key: 'authReason', label: '备注', type: 'textarea', maxLength: 50, placeholder: '请50个字以内填写复核人复核错误原因（必填项）', required: true}
  ];
  const onProblem = (data) => {
    const { authReason } = data;
    const json = { id, authReason };
    request('/web/ticket/auth2', json)
      .then((res) => res.data)
      .then((res) => {
        message.success(res.msg);
        onSuccess({auth2: 3, authReason2: authReason });
      });
  }
  return (
    <ModalFormPage
      title="备注"
      inputs={inputs}
      defaultValue={{ authReason }}
      onFinish={(e) => isMofify ? onProblem(e) : onClose()}
      onCancel={onClose}
      modalProps={{
        width: 800,
      }}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}
    />
  );
}
function 坐席复核({ id, onClose, onSuccess }) {
  const 复核备注 = [
    {value: "调换起终点未操作或错误操作"},
    {value: "补齐起终点未操作或错误操作"},
    {value: "修改车牌未操作或错误操作"},
    {value: "纳入结算未操作或错误操作"}
  ];
  const inputs = [
    {key: 'auth', label: '复核结果', required: true, type: 'radioGroup', options: [{ id: 2, name: '正确'}, { id: 3, name: '错误'}], valueKey: 'id', labelKey: 'name'},
    {key: 'authReason', label: '错误原因', type: 'textarea', placeholder: '复核选择“错误”，必须填写错误原因', extraRemark: 复核备注}
  ];
  const onProblem = (data) => {
    const { auth, authReason } = data;   
    if (auth === 3 && (!authReason || authReason === '')) {
      return message.warn('复核选择“错误”，必须填写错误原因！');
    }
    const json = auth === 3 ? { id, auth, authReason } : { id, auth }
    request('/web/ticket/auth', json)
      .then((res) => res.data)
      .then((res) => {
        message.success(res.msg);
        onSuccess({auth, auth_text: auth === 2 ? '复核正确' : '复核错误', auth_reason: authReason });
      });
  }
  return (
    <ModalFormPage
      title="坐席复核"
      inputs={inputs}
      onFinish={onProblem}
      onCancel={onClose}
      modalProps={{
        width: 800,
      }}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}
    />
  );
}

function 篷布密闭({ id, onClose, onSuccess }) {
  function onSubmit() {
    request('/web/ticket/shelterOut', { id })
    .then((res) => res.data)
    .then((res) => {
      message.success(res.msg);
      onSuccess({ shelterOut: 1 });
    });
  }

  return (
    <Modal
      title="重车篷布密闭出场"
      visible={true}
      onCancel={onClose}
      onOk={onSubmit}
    >
      确认该车为重车篷布密闭出场？
    </Modal>
  );
}

function 结算确认Modal({ ticketId, detailInfo, materielId, onClose, onSuccess, onFinishMaterial }) {
  const storeInfo = store.getState();
  const {info: {area_id }} = storeInfo;
  const [[planId, planArr], setPlanIds] = useState([undefined, []]);
  const 路线偏移 = useMemo(() => [{ id: 1, name: '待确认'}, { id: 2, name: '未规划' }, { id: 3, name: '否' }, { id: 4, name: '是' }], []);
  const 到达终点 = useMemo(() => [{ id: 1, name: '待确认'}, { id: 2, name: '未指定' }, { id: 3, name: '否' }, { id: 4, name: '是' }], []);
  const 纳入结算 = useMemo(() => [{id: 1, name: '待确认' }, { id: 2, name: '无需结算' }, { id: 3, name: '未结算' }, { id: 4, name: '纳入结算' }], []);
  const 结算备注 = useMemo(() => [{value: "空车出场"}, {value: "无法辨别车辆"}, {value: "非渣土车"}, {value: "非本区域挖运"}, {value: "出红线内转"}, {value: "非可结算物料"}], []);
  useEffect(() => {
    if (!ticketId) return;
    request('web/ticket/getPlanList', { ticketId })
      .then(body => body.data)
      .then((res) => {
        if (res.code === 200) {
          const planId = res?.data?.[0]?.planId ?? 0;
          setPlanIds([planId, res.data]);
        }
      })
  }, [ticketId]);
  const confirmInput = area_id === 11 ? [
    detailInfo.acquirer === 1 ? false : { key: 'materiel_id', label: '物料', placeholder: '物料', type: 'radioGroup', options: '/web/route/initMateriel', valueKey: 'id', labelKey: 'name', required: false, style: { width: 195 } },
    { key: 'isOffline', label: '是否线路偏移', placeholder: '请选择是否路线偏移', type: 'radioGroup', options: 路线偏移, valueKey: 'id', labelKey: 'name', required: true },
    { key: 'isArrive', label: '是否到达指定终点', placeholder: '请选择是否到达指定终点', type: 'radioGroup', options: 到达终点, valueKey: 'id', labelKey: 'name', required: true },
    { key: 'balanceAccounts', label: '是否纳入结算', placeholder: '请选择是否纳入结算', type: 'radioGroup', options: 纳入结算, valueKey: 'id', labelKey: 'name', required: true },
    { key: 'planId', label: '绑定拉运计划', placeholder: '请选择绑定拉运计划', type: 'select', options: planArr, valueKey: 'planId', labelKey: 'planName', required: false,
      extraTip: '联单未绑定拉运计划，请下拉选择绑定。', hideAccount: 'balanceAccounts' },
    { key: 'remark', label: '备注', placeholder: '备注', type: 'textarea', maxLength: 50, extraRemark: 结算备注, extraTip: '当选择无需确认、未结算时，请必须填写或快速选择备注（2选1）' },
    { key: 'file', label: '上传附件', placeholder: '上传附件', showMsg: '', showLoadText: '支持上传PDF、JPG、PNG，最多可上传5张', type: 'upload-dragger', hide: true, showOrHide: 'balanceAccounts', required: true, maxUploadLength: 5, accept: ".jpg, .jpeg, .png, .pdf"},
  ] : [
    detailInfo.acquirer === 1 ? false : { key: 'materiel_id', label: '物料', placeholder: '物料', type: 'radioGroup', options: '/web/route/initMateriel', valueKey: 'id', labelKey: 'name', required: false, style: { width: 195 } },
    { key: 'isOffline', label: '是否线路偏移', placeholder: '请选择是否路线偏移', type: 'radioGroup', options: 路线偏移, valueKey: 'id', labelKey: 'name', required: true },
    { key: 'isArrive', label: '是否到达指定终点', placeholder: '请选择是否到达指定终点', type: 'radioGroup', options: 到达终点, valueKey: 'id', labelKey: 'name', required: true },
    { key: 'balanceAccounts', label: '是否纳入结算', placeholder: '请选择是否纳入结算', type: 'radioGroup', options: 纳入结算, valueKey: 'id', labelKey: 'name', required: true },
    { key: 'remark', label: '备注', placeholder: '备注', type: 'textarea', maxLength: 50, extraRemark: 结算备注, extraTip: '当选择无需确认、未结算时，请必须填写或快速选择备注（2选1）'},
    { key: 'file', label: '上传附件', placeholder: '上传附件', showMsg: '', showLoadText: '支持上传PDF、JPG、PNG，最多可上传5张', type: 'upload-dragger', hide: true, showOrHide: 'balanceAccounts', required: true, maxUploadLength: 5, accept: ".jpg, .jpeg, .png, .pdf"},
  ];
  const confirmInputs = confirmInput.filter((item) => item);
  const onAccounts = (data) => {
    const params = {
      id: ticketId,
      ...data,
    };
    const { balanceAccounts } = data;
    if (data.materiel_id) {
      const materiels = { materiel_id: data.materiel_id };
      onFinishMaterial(materiels);
    }
    if(balanceAccounts === '无') {
      return message.warn('不能选择无');
    }
    if ((balanceAccounts === 2 || balanceAccounts === 3) && (!data.remark || data.remark === '')) {
      return message.warn(`当选择是否纳入结算是${balanceAccounts === 2 ? '无需结算' : '未结算'}时，必须填写备注！`);
    }
    
    request('web/ticket/accounts', params)
      .then((body) => body.data)
      .then(({ code, msg }) => {
        if (code === 200) {
          onSuccess();
        } else {
          const warn = typeof msg === 'string' ? msg : '保存失败!';
          message.warn(warn);
          onClose()
        }
      })
  }
  return planId !== undefined ? (
    <ModalFormPage
      title={
        <div >
          <span>结算确认</span><span></span>
          {detailInfo.balance_accounts !== 5 ? <span style={{ color: '#f00', fontSize: 12 }}>已操作结算确认，修改请慎重！</span> : ''}
        </div>
      }
      inputs={confirmInputs}
      defaultValue={{ planId, isOffline: detailInfo.is_offline, isArrive: detailInfo.is_arrive, balanceAccounts: detailInfo.balance_accounts === 5 ? '无' : detailInfo.balance_accounts, remark: detailInfo.remark, materiel_id: materielId ? materielId : 0, file: detailInfo.opLogFile }}
      onFinish={onAccounts}
      onCancel={onClose}
      modalProps={{
        width: 800,
      }}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}
    />
  ) : null; 
}

export function 拉运计划列表({ areaId, dayStr, onClose }) {
  const [data, setData] = useState();  
  const [keyword, setKeyword] = useState('');
  const [keys, setKeys] = useState('');

  useEffect(() => {
    request('/Web/Ticket/PlanByDay', { areaId, dayStr, keyword })
      .then((res) => setData(res.data.data))
  }, [areaId, dayStr, keyword]);

  return (
    <Modal
      visible={true}
      title="拉运计划"
      onCancel={onClose}
      onOk={onClose}
      width={1400}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto'}}
    >
      <div className="search">
        <span>{dayStr}</span>
        <div className='btns'>
          <Input placeholder='请输入项目名称' value={keys} onChange={(e) => setKeys(e.target.value)} />
          <Button type="primary" onClick={() => setKeyword(keys)}><Icon type="search" /></Button>
        </div>
      </div>
      <Table
          columns={[
            {title: '序号', dataIndex: 'index', render: (_, $, i) => i + 1},
            {title: '拉运起点', dataIndex: 'start_name'},
            {title: '拉运终点', dataIndex: 'end_name'},
            {title: '计划类型', dataIndex: 'case_type', render: (e) => ['', "收储", "销售", "无", "内转", "土方外运", "回填"][e]},
            {title: '是否结算', dataIndex: 'is_bill', render: (e) => ['-', '不结算', '纳入结算', '-', '-'][e]},
            {title: '拉运时效', dataIndex: 'created_at', render: (time, { start_time, end_time }) => `${start_time}-${end_time}`},
            {title: '物料', dataIndex: 'material_name'}
          ]}
          scroll={true}
          dataSource={data ? data : []}
          pagination={false}
        />
    </Modal>
  );
}
