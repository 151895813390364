/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Modal, Timeline, Card } from 'antd';
import { previewable } from 'components/Previewer';
import request from 'utils/request';
import styles from './Flow.scss';

function footer(value, placeholder = '无') {
  return value || placeholder;
}

function FormComponent({ preview, children }) {
  if (!children || children.length === 0) return null;

  function types(type, value) {
    switch (type) {
      case 103: return <span onClick={() => preview.image(value)}>查看</span>;
      default: return footer(value);
    }
  }

  return (
    <div className={styles.forms}>
      {children.map(({ name, items }, key) => (
        <Card
          className={styles.form}
          key={key}
          size="small"
          type="inner"
          title={name}
          bodyStyle={{ color: 'black' }}
        >
          {items.map(({ name, type, submit }, key) => (
            <div className={styles.cell} key={key}>
              <span>{name}</span>
              <span className={styles.submit} data-type={type}>{types(type, submit)}</span>
            </div>
          ))}
        </Card>
      ))}
    </div>
  );
}

const Form = previewable(FormComponent);

function Node({ node_name, status_text, status, created_time, deal_time, user_name, forms }) {
  return (
    <Card
      className={styles.node}
      data-status={status}
      size="small"
      bordered={false}
      title={node_name}
      extra={<span className={styles.status} data-status={status}>{status_text}</span>}
      bodyStyle={{ color: 'black' }}
    >
      <div className={styles.cell}><span>创建时间</span><span>{footer(created_time)}</span></div>
      <div className={styles.cell}><span>处理时间</span><span>{footer(deal_time)}</span></div>
      <div className={styles.cell}><span>处理人员</span><span>{footer(user_name)}</span></div>
      <Form>{forms}</Form>
    </Card>
  );
}

export default function Flow({ history, match }) {
  const [nodes, setNodes] = useState([]);
  useEffect(() => {
    request('web/car_alarm/getFlowDetail', { id: match.params.id }).then(body => setNodes(body.data.data));
  }, []);

  // console.log(JSON.stringify(nodes, null, 2));

  return (
    <Modal
      visible
      centered
      width={400}
      footer={null}
      title="流程详情"
      onCancel={() => history.goBack()}
      bodyStyle={{
        maxHeight: '80vh',
        overflow: 'auto',
        background: '#e8e8e8',
        borderRadius: '0 0 4px 4px',
      }}
    >
      <Timeline className={styles.content}>
        {nodes.map((node, key) => (
          <Timeline.Item key={key}>
            <Node {...node} />
          </Timeline.Item>
        ))}
      </Timeline>
    </Modal>
  );
}
