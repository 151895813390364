import React, { useState, useEffect } from 'react'
import { Modal, message } from 'antd'
import styles from './index.scss'
import request from 'utils/request';
import { dataSource } from '../List'
import Uploader from '../../Uploader'
const { confirm } = Modal;
/* 转黑名单按钮 */
export default function BlackList({ props }) {
  let { blacklistInfo, BlacklistInfo, selectedRows } = props
  const [list_id, setCarId] = useState(0);
  const [car_num, setCarNum] = useState('')
  const [images, setImgValue] = useState([]);
  const [visible, setVisible] = useState(false)
  useEffect(() => { if (!visible) { BlacklistInfo({ visible }) } }, [visible])
  useEffect(() => { if (blacklistInfo) { setVisible(true) } }, [blacklistInfo])
  useEffect(() => { if (selectedRows.length > 0) { setCarNum(selectedRows[0].car_number); setCarId(selectedRows[0].id) } }, [selectedRows])
  function handleOk(e) { showConfirm() };
  function handleCancel(e) { setVisible(false) };
  function showConfirm() {
    if (images.length == 0) {
      message.warn('请上传证据！！')
      return
    }
    confirm({
      title: '确定要转黑名单吗？',
      okText: '确定',
      cancelText: '取消',
      centered: true,
      onOk() {
        request('/web/gb_list/switchBlackList', { list_id, images })
          .then(body => {
            if (body.data.code === 200) {
              message.success('转黑名单成功！')
              setVisible(false)
              dataSource.refresh()
              setImgValue([])
            } else {
              message.warn(body.data.msg)
            }
          })
      },
    });
  }
  return (
    <Modal
      title='转黑名单'
      centered
      onOk={handleOk}
      visible={visible}
      maskClosable={false}
      onCancel={handleCancel}
    >
      <p>已选车辆</p>

      <span className={styles.selectCar}>{car_num}</span>
      <br />
      <br />
      <p>证据上传<span style={{ color: '#c4c4c4', fontSize: 12 }}>(最多可以上传5张)</span></p>
      <Uploader value={images} onChange={(e) => setImgValue(e)} maxUploadLength={5} />
    </Modal >
  )
}
