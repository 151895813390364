/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { Button, Table, message } from 'antd';

import Query from 'ui/Query';

import debug from 'utils/debug';
import request from 'utils/request';

import styles from './TransportReports.scss';
import ModalReports from './ModalReports';

const defaultQuery = {
  time: [
    moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
  ],
};

export default function TransportReports() {
  debug.render('TransportReports');
  const [data, setData] = useState({});
  const [query, setQuery] = useState(defaultQuery);
  const [selection, setSelection] = useState({});
  const paperRef = useRef(null);

  function onQueryChange(params) {
    setQuery(params);
  }

  function onMaterielClick(selection) {
    setSelection(selection);
  }

  function onClose() {
    setSelection('');
  }

  useEffect(() => {
    if (!query) return;
    request('/web/settlement/planTransportSettlement', query).then((body) => {
      if (body.data.code === 150) {
        message.warn(body.data.msg);
      } else {
        const { rows, total, ...rest } = body.data.data;
        const rowsWithTotal = rows.map((row, index) => ({ ...row, serial: index + 1 }));
        rowsWithTotal.push({ serial: '合计', ...total });
        setData({ ...rest, rows: rowsWithTotal });
      }
    });
  }, [query]);

  const buttons = [{
    element: (
      <ReactToPrint
        key="print-btn"
        trigger={() => <Button type="primary">打印</Button>}
        content={() => paperRef.current}
      />
    ),
  }];
  const querys = [
    { title: '项目', key: 'plan_id', type: 'select', options: '/web/settlement/getPlanName', valueKey: 'plan_id', labelKey: 'plan_name', showSearch: true, style: { width: 300 } },
    { title: ['结算', '日期'], key: 'time', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 240 } },
  ];
  const columns = [
    { align: 'center', title: '序号', dataIndex: 'serial' },
    { align: 'center', title: '运企', dataIndex: 'transport_name' },
    { align: 'center', title: '物品', dataIndex: 'materiel_name', render: ($, $$) => <span className={styles.materiel} onClick={() => onMaterielClick($$)}>{$}</span> },
    { align: 'center', title: '运输趟数', dataIndex: 'count' },
    { align: 'center', title: '计量单位', dataIndex: 'units' },
    { align: 'center', title: '结算方量', dataIndex: 'volumn' },
    { align: 'center', title: '单价（元/方）', dataIndex: 'unit_price' },
    { align: 'center', title: '违规扣款（元）', dataIndex: 'discount' },
    { align: 'center', title: '总价（元）', dataIndex: 'total_price' },
  ];

  return (
    <div className={styles.container}>
      <Query
        querys={querys}
        setQuery={onQueryChange}
        defaultQuery={defaultQuery}
        buttons={buttons}
      />
      <div className={styles.content}>
        <div className={styles.box}>
          <div className={styles.print} ref={paperRef}>
            <h2 className={styles.title}>{data.plan_name}项目运输费用结算清单</h2>
            <h3 className={styles.time}>制表日期：{data.table_time}</h3>
            <Table
              className={styles.table}
              bordered
              size="small"
              rowKey="serial"
              pagination={false}
              dataSource={data.rows}
              columns={columns}
              title={() => (
                <h4 className={styles.header}>
                  <span>服务提供单位：{data.building_name}</span>
                  <span>结算日期：{data.settlement_time}</span>
                  <span>服务使用单位：{data.service_name}</span>
                </h4>
              )}
              footer={() => (
                <h3 className={styles.footer}>大写金额：{data.money_string}</h3>
              )}
            />
            <h4 className={styles.signs}>
              <span>服务提供单位（公章）：</span>
              <span>服务使用单位（公章）：</span>
            </h4>
            <h4 className={styles.signs}>
              <span>服务提供单位经办人：</span>
              <span>服务使用单位经办人：</span>
            </h4>
          </div>
        </div>
      </div>
      <ModalReports {...selection} query={query} onClose={onClose} />
    </div>
  );
}
