// https://ant.design/components/switch-cn/#API
import React from 'react';
import { Radio } from 'antd';

export default function RadioKey({ props, options, value, onChange, ...rest} ) {
    return (
        <Radio.Group
            {...props}
            options={options}
            value={value}
            onChange={e => onChange(e.target.value)}
        />
    );
}
