import React from 'react';

export function ConvertCoords(data){
  var coords = [];
  var m_coords = data ? data.split(";") : '';
  for (var i=0;i<m_coords.length;i++){
    coords.push(m_coords[i].split(","))
  }
  return coords;
};
