/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { Divider, Modal, Table, Button } from 'antd';
import { previewable } from 'components/Previewer';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import ModalFormPage from 'ui/ModalFormPage';
import Selector from 'ui/FormItem/Selector';

import ImagePreviewButtonRender from 'ui/TableCell/ImagePreviewButtonRender';
import LinkButton from 'ui/TableCell/LinkButton';
import DeleteButton from 'ui/TableCell/DeleteButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';

import debug from 'utils/debug';
import request from 'utils/request';

import styles from './Cars.scss';

const dataSource = new DataSource('/web/plan_car/sandList');

function List() {
  debug.render('List');
  const columns = [
    serial(true),
    { title: '车牌号', dataIndex: 'car_num', fixed: 'left', width: width(7) },
    { title: '砂石场', dataIndex: 'sand_factory_name' },
    { title: '货箱内径尺寸-长(m)', dataIndex: 'inner_long' },
    { title: '货箱内径尺寸-宽(m)', dataIndex: 'inner_width' },
    { title: '货箱内径尺寸-高(m)', dataIndex: 'inner_height' },
    { title: '方量(m³)', dataIndex: 'volumn' },
    { title: '修改人', dataIndex: 'created_by' },
    { title: '修改时间', dataIndex: 'created_time' },
    actions({
      isFixed: true,
      width: 264,
      render: ($, { id }) => (
        <>
          <LinkButton to={`./${id}/detail`}>详情</LinkButton>
          <Divider type="vertical" />
          <LinkButton to={`./${id}/update`}>修改</LinkButton>
          <Divider type="vertical" />
          <LinkButton to={`./${id}/driver`}>司机</LinkButton>
          <Divider type="vertical" />
          <DeleteButton api="/web/plan_car/delSandCar" id={id} dataSource={dataSource} />
        </>
      ),
    }),
  ];

  const querys = [
    { title: '关键字', key: 'keyword' },
  ];

  const buttons = [
    { title: '新增', link: './create', icon: 'plus' },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}

function Create({ history }) {
  debug.render('Create');

  const back = () => history.goBack();
  const onFinish = () => {
    dataSource.refresh();
    back();
  };

  const inputs = [
    { label: '砂石场', key: 'sand_factory_id', type: 'select', options: '/web/plan_car/getSandFactoryList', valueKey: 'id', labelKey: 'name', required: true },
    { label: '车牌号', key: 'car_num', required: true },
    { label: '货箱内径尺寸-长(m)', key: 'inner_long', type: 'number', required: true },
    { label: '货箱内径尺寸-宽(m)', key: 'inner_width', type: 'number', required: true },
    { label: '货箱内径尺寸-高(m)', key: 'inner_height', type: 'number', required: true },
  ];

  return (
    <ModalFormPage
      title="新增车辆"
      submit={['/web/plan_car/createSandNewInfo']}
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
    />
  );
}

function Update({ history, match }) {
  debug.render('Update');

  const back = () => history.goBack();
  const onFinish = () => {
    dataSource.refresh();
    back();
  };

  const inputs = [
    { label: '车牌号', key: 'car_num', required: true },
    { label: '货箱内径尺寸-长(m)', key: 'new_inner_long', type: 'number', required: true },
    { label: '货箱内径尺寸-宽(m)', key: 'new_inner_width', type: 'number', required: true },
    { label: '货箱内径尺寸-高(m)', key: 'new_inner_height', type: 'number', required: true },
  ];

  const id = match.params.id;
  const transformer = ({ base }) => ({
    car_num: base.car_num,
    new_inner_height: base.inner_height,
    new_inner_long: base.inner_long,
    new_inner_width: base.inner_width,
  });
  return (
    <ModalFormPage
      title="修改车辆信息"
      init={['/web/sand_car/getInfoById', { id }, transformer]}
      submit={['/web/plan_car/saveSandNewInfo', { id }]}
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
    />
  );
}

function Driver({ history, match }) {
  debug.render('Driver');
  const id = match.params.id;
  const [carNum, setCarNum] = useState();
  const [driverOptions, setDriverOptions] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [currentDriverId, setCurrentDriverId] = useState();

  const back = () => history.goBack();

  useEffect(() => {
    request('/web/concrete_car/getDriverList')
      .then(body => body.data.data)
      .then(driverOptions => setDriverOptions(driverOptions));
  }, []);

  function refreshBindedDrivers() {
    request('/web/sand_car/getInfoById', { id })
      .then(body => body.data.data)
      .then(({ base, driver }) => {
        setDrivers(driver);
        if (!carNum) {
          setCarNum(base.car_num);
        }
      });
  }

  function bindDriver() {
    request('/web/sand_car/bindOneDriver', {
      sand_factory_car: id,
      driver_id: currentDriverId,
    }).then(() => {
      setCurrentDriverId(undefined);
      refreshBindedDrivers();
    });
  }

  function unbindDriver(driver_id) {
    request('/web/sand_car/unBindOneDriver', {
      car_num: carNum,
      driver_id,
    }).then(() => {
      refreshBindedDrivers();
    });
  }

  useEffect(() => {
    refreshBindedDrivers();
  }, []);

  const driversNotSelected = driverOptions.filter(({ id }) => !drivers.find(driver => driver.id === id));
  return (
    <Modal
      title="修改车辆司机绑定关系"
      visible
      centered
      width={800}
      footer={null}
      onCancel={back}
    >
      <div className={styles.adder}>
        <Selector showSearch valueKey="id" labelKey="phone" options={driversNotSelected} value={currentDriverId} onChange={setCurrentDriverId} style={{ width: 500 }} placeholder="请选择您要绑定的司机" />
        {currentDriverId && <Button className={styles.addBtn} type="primary" onClick={bindDriver}>绑定</Button>}
      </div>
      <Table
        bordered
        pagination={false}
        rowKey="id"
        dataSource={drivers}
        columns={[
          { title: '姓名', dataIndex: 'name' },
          { title: '手机号', dataIndex: 'phone' },
          { title: '操作', key: 'actions', render: ($, { id }) => <Button size="small" type="danger" onClick={() => unbindDriver(id)}>解除绑定</Button> },
        ]}
      />
    </Modal>
  );
}

function Detail({ history, match, preview }) {
  debug.render('Driver');
  const id = match.params.id;
  const back = () => history.goBack();

  const [data, setData] = useState({ base: {}, driver: [] });

  useEffect(() => {
    request('/web/sand_car/getInfoById', { id })
      .then(body => body.data.data)
      .then(setData);
  }, []);

  const render = ImagePreviewButtonRender(preview);

  return (
    <Modal
      visible
      centered
      title={data.base.car_num}
      width={1200}
      footer={null}
      onCancel={back}
    >
      <div className={styles.info}>
        <div className={styles.left}>货箱内径：</div>
        <div className={styles.right}>{data.base.inner_long} × {data.base.inner_width} × {data.base.inner_height} = {data.base.volumn}</div>
      </div>
      <Table
        bordered
        pagination={false}
        scroll={{ x: 'max-content' }}
        rowKey="id"
        dataSource={data.driver}
        columns={[
          { title: '姓名', dataIndex: 'name', fixed: 'left' },
          { title: '电话', dataIndex: 'phone' },
          { title: '身份证号', dataIndex: 'id_card' },
          { title: '司机资格证到期日', dataIndex: 'deadline' },
          { title: '司机驾驶证有效期', dataIndex: 'expiry_date' },
          { title: '车辆行驶证年检日期', dataIndex: 'check_date' },
          { title: '准驾车型', dataIndex: 'type' },
          { title: '从业资格证号', dataIndex: 'work_license_num' },
          // { title: '驾驶证号', dataIndex: 'driver_license_num' },
          { title: '民族', dataIndex: 'nation' },
          { title: '籍贯', dataIndex: 'birth_address' },
          { title: '常住地', dataIndex: 'address' },
          { title: '备注', dataIndex: 'remark' },
          { title: '照片', dataIndex: 'image', align: 'center', render },
          { title: '身份证图片', dataIndex: 'id_card_photo', align: 'center', render },
          { title: '驾驶证照片', dataIndex: 'driver_license_photo', align: 'center', render },
          { title: '从业资格证号图片', dataIndex: 'work_license_photo', align: 'center', render },
          { title: '近期体检表图片', dataIndex: 'physical_form', align: 'center', render },
        ]}
      />
    </Modal>
  );
}

const PreviewableDetail = previewable(Detail);

export default function Cars({ path }) {
  debug.render('Cars');
  return (
    <>
      <Route path={path} component={List} />
      <Route path={path + 'create'} component={Create} />
      <Route path={path + ':id/update'} component={Update} />
      <Route path={path + ':id/driver'} component={Driver} />
      <Route path={path + ':id/detail'} component={PreviewableDetail} />
    </>
  );
}
