import { deleteBtn } from './width';

export default function actions({ title = '操作', isFixed, width = deleteBtn, render }) {
  const fixed = isFixed ? 'right' : undefined;
  return {
    key: 'actions',
    title,
    fixed,
    width,
    render,
  };
}
