import React from 'react';
import { previewable } from 'components/Previewer';
import { useEffect, useState } from 'react';
import request from 'utils/request';
import styles from './List.scss';
import {Button, Modal, Row, Col, Icon, Carousel} from "antd";
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'

function ExceptionLogs(props) {
  const { id, onClose } = props;

  const [detailInfo, setDetailInfo] = useState({});
  useEffect(() => {
    if (!id) return;
    request('/web/human_ticket/detail', { id: id }).then(body => body.data.data).then((data) => {
      setDetailInfo(data)
    });
  }, [id]);

  return (
    <Modal
      centered
      visible={true}
      title="签收信息"
      width={500}
      onCancel={() => onClose()}
      footer={<Button onClick={onClose}>取消</Button>}
    >
      <Row gutter={32} className={styles.rowStyle}>
        <Col span={6} className={styles.labels}>终点签收</Col>
        <Col span={18} className={styles.count}>{detailInfo.status === 1 ? '待签收' :
          <span style={{ color: detailInfo.status === 2 ? '#00C77D' : 'rgb(255, 37, 37)', display: 'flex', alignItems: 'center', gap: 6 }}>
            <img src={detailInfo.status === 2 ? `/images/isok.png` : `/images/isno.png`} />
            {detailInfo.status === 2 ? '终点已签收' : '异常处理'}
          </span>}
        </Col>
      </Row>
      <Row gutter={32} className={styles.rowStyle}>
        <Col span={6} className={styles.labels}>{detailInfo.status === 2 ? '签收时间' : '处理时间'}</Col>
        <Col span={18} className={styles.count}>{detailInfo.status === 2 ? detailInfo.dealt_time : detailInfo.exception_logs ? detailInfo.exception_logs[detailInfo.exception_logs.length - 1].created_time : '-'}</Col>
      </Row>
      <Row gutter={32} className={styles.rowStyle}>
        <Col span={6} className={styles.labels}>异常处理原因</Col>
        <Col span={18} className={styles.count}>{detailInfo.exception_logs ? detailInfo.exception_logs[detailInfo.exception_logs.length - 1].reason_text : '-'}</Col>
      </Row>
      <Row gutter={32} className={styles.rowStyle}>
        <Col span={6} className={styles.labels}>处理人</Col>
        <Col span={18} className={styles.count}>{detailInfo.exception_logs ? detailInfo.exception_logs[detailInfo.exception_logs.length - 1].created_name : '-'}</Col>
      </Row>
      <Row gutter={32} className={styles.rowStyle}>
        <Col span={6} className={styles.labels}>备注</Col>
        <Col span={18} className={styles.count}>{detailInfo.exception_logs ? detailInfo.exception_logs[detailInfo.exception_logs.length - 1].remark : '-'}</Col>
      </Row>
    </Modal>
  );
}
export function ExceptionLogs1(props) {
  const { detailInfo, onClose } = props;

  // const [detailInfo, setDetailInfo] = useState({});
  // useEffect(() => {
  //   if (!id) return;
  //   request('/web/human_ticket/detail', { id: id }).then(body => body.data.data).then((data) => {
  //     setDetailInfo(data)
  //   });
  // }, [id]);

  return (
    <Modal
      centered
      visible={true}
      title="签收信息"
      width={500}
      onCancel={() => onClose()}
      footer={<Button onClick={onClose}>取消</Button>}
    >
      <Row gutter={32} className={styles.rowStyle}>
        <Col span={6} className={styles.labels}>终点签收</Col>
        <Col span={18} className={styles.count}>{detailInfo.status === 1 ? '待签收' :
          <span style={{ color: detailInfo.status === 2 ? '#00C77D' : 'rgb(255, 37, 37)', display: 'flex', alignItems: 'center', gap: 6 }}>
            <img src={detailInfo.status === 2 ? `/images/isok.png` : `/images/isno.png`} />
            {detailInfo.status === 2 ? '终点已签收' : '异常处理'}
          </span>}
        </Col>
      </Row>
      <Row gutter={32} className={styles.rowStyle}>
        <Col span={6} className={styles.labels}>{detailInfo.status === 2 ? '签收时间' : '处理时间'}</Col>
        <Col span={18} className={styles.count}>{detailInfo.status === 2 ? detailInfo.dealt_time : detailInfo.created_time}</Col>
      </Row>
      <Row gutter={32} className={styles.rowStyle}>
        <Col span={6} className={styles.labels}>异常处理原因</Col>
        <Col span={18} className={styles.count}>{detailInfo.reason_text}</Col>
      </Row>
      <Row gutter={32} className={styles.rowStyle}>
        <Col span={6} className={styles.labels}>处理人</Col>
        <Col span={18} className={styles.count}>{detailInfo.created_name}</Col>
      </Row>
      <Row gutter={32} className={styles.rowStyle}>
        <Col span={6} className={styles.labels}>备注</Col>
        <Col span={18} className={styles.count}>{ detailInfo.remark}</Col>
      </Row>
    </Modal>
  );
}

export default previewable(ExceptionLogs)