/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {Timeline, Modal, Card, Spin, Button, message} from 'antd';
import { previewable } from 'components/Previewer';
import request from 'utils/request';
import styles from './Flow.scss';
import store from 'stores/user';
const nodeType = ['处理派单', '处理派单', '处理派单', '转交派单', '拒绝派单', '打回重做', '确认派单',' 打回转交']
const statusName = ['', '否', '是'];
function footer(value, placeholder = '无') {
  return value || placeholder;
}
const { confirm } = Modal;
function FormComponent({ preview, children }) {
  if (!children || children.length === 0) return null;

  function types(type, value) {
    switch (parseInt(type)) {
      case 5: return <span style={{color: '#1491fb', cursor: 'pointer'}} onClick={() => preview.image(value)}>查看</span>;
      default: return footer(value);
    }
  }
  return (
    <div className={styles.forms}>
      {children.map(({ list_content, item }, key) => (
        <Card
          className={styles.form}
          key={key}
          size="small"
          type="inner"
          title={list_content}
          bodyStyle={{ color: 'black' }}
        >
          {item.map(({ title, content, data_type, remark, submit }, key) => (
            <div className={styles.cell} key={key}>
              <span>{title}</span>
              <span className={styles.submit} data-type={data_type}>{types(data_type, content)}</span>
            </div>
          ))}
        </Card>
      ))}
    </div>
  );
}

const Form = previewable(FormComponent);

function Node({ node_name, node_type, status_text, status, create_time, deal_time, user_name, forms, list }) {
  return (
    <Card
      className={styles.node}
      data-status={status}
      size="small"
      bordered={false}
      title={nodeType[node_type]}
      extra={<span className={styles.status} data-status={status}>{statusName[status]}</span>}
      bodyStyle={{ color: 'black' }}
    >
      <div className={styles.cell}><span>创建时间</span><span>{footer(create_time)}</span></div>
      <div className={styles.cell}><span>处理时间</span><span>{footer(deal_time)}</span></div>
      <div className={styles.cell}><span>处理人员</span><span>{footer(user_name)}</span></div>
      <Form>{list}</Form>
    </Card>
  );
}

export default function DispatchFlow({ id, status, setDispatchOk, ignore, history, match }) {
  const [nodes, setNodes] = useState([]);
  const [btn, setBtn] = useState({urge: true, forward: true});
  const [spinning, setSpinning] = useState(false);
  const roleId = store.getState().roles.id;
  useEffect(() => {
    setSpinning(true)
    request('/rest/dispatch_task/getDispatchTaskDetail', { id: id }).then(body => {setNodes(body.data.data.data); setBtn(body.data.data.auth)}).finally(() => setSpinning(false));
  }, [id]);
  const showConfirm = () => {
    confirm({
      title: '确定进行催办？',
      centered: true,
      onOk() { request('/rest/dispatch_task/pressDispatchTask', {id})
        .then(d => d.data)
        .then(data => {
          if(data.code === 200){
            message.success('催办成功！')
          }else{
            message.warn(data.msg)
          }
        })
      },
      onCancel() { message.success('取消催办！'); },
     });
  };
  return (
    <div className={styles.news}>
      <Spin spinning={spinning}>
        <div className={styles.timeLine}>
          <Timeline size={'large'} className={styles.content}>
            {nodes.map((node, key) => (
              <Timeline.Item key={key}>
                <Node {...node} />
              </Timeline.Item>
            ))}
          </Timeline>
        </div>

        <div className={styles.sure} >
          {
            roleId === 111 || roleId === '111' ?
              btn.urge && btn.forward ? <>
                <Button type="danger" style={{ background: '#FF940E', border: 'none', width: 160 }} onClick={showConfirm} >催办</Button>
                <Button type="primary" style={{ width: 160, float: 'right' }} onClick={setDispatchOk}>转交</Button>
              </> : btn.urge ? <Button style={{ width: '100%', color: "#fff", background: '#FF940E', border: 'none'}} onClick={showConfirm} >催办</Button> :
                btn.forward ? <Button type="primary" style={{ width: '100%', color: "#fff"}} onClick={setDispatchOk}>转交</Button> : null : null
          }
          {
            roleId === 111 || roleId === '111' ?
              status === 2 || status === 5 ? <div className={styles.neglect}>
                <p onClick={ignore}>忽略此次告警</p>
              </div> : null : null
          }
        </div>
      </Spin>
    </div>
  );
}
