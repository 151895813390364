import React from 'react';
import common from 'utils/common';
import ShowImages from "components/PopoverPage/ShowImages";
import {Form, Row, Col, Input, Button, Icon, Collapse, Upload, message, Select, DatePicker, InputNumber} from "antd";
import axios from "axios";
import request from "utils/request";
import 'components/css/TableClass.global.scss';
const Panel = Collapse.Panel;
const Option = Select.Option;

export default class AddStationCarMsg extends React.PureComponent{
  static getDerivedStateFromProps(nextProps, presState){
    return {
      // data: nextProps.data.data,
    }
  }
  constructor(props) {
    super(props);
    console.log(props.data.data)
    this.state = {
      data: props.data.data,
      activeKey: [],
      previewVisible: false,
      previewImage: '',
      dataImages: [],
      img_flag: false,
      editIndex: 0,
      driver_type: 0,
    }
  }
  handleChange = ({ fileList }) => this.setState({ fileList })
  upLoadImg = (i) => {
    return (
      <div className="clearfix" style={{display: 'inline-block'}}>
        <Upload
          customRequest={({file, filename, onSuccess, onError,}) => {
            const formData = new FormData();
            formData.append(filename, file);
            axios({
              url: URLS.FILE + '/libraries/Uploder/upload',
              method: 'post',
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: formData,
            }).then((res) => {
              const { data: src, msg } = res.data || {};
              if (res.status === 200 && res.data.code === 200) {
                this.onChange(i, src)
              } else {
                /*onError(msg);*/
                message.error('上传失败');
              }
            }).catch(console.error);
          }}
          listType="picture-card"
          onChange={this.handleChange}
        >
          <div>
            <Icon type="plus" />
            <div className="ant-upload-text">Upload</div>
          </div>
        </Upload>
      </div>
    )
  };
  dateFocus = (i) => {
    this.setState({
      editIndex: i,
    })
  };
  onDateChange = (e, str) => {
    this.onChange(this.state.editIndex, str);
  };
  onChange = (i, v, flag) => {
    if(flag) {
      this.setState(({ data }) => ({
        data: {
          ...data,
          base: data.base.map((item, index) => ({
            ...item,
            value: index === i ? v : item.value
          })),
        },
      }));
    }
    else{
      this.setState(({ data }) => ({
        data: {
          ...data,
          base: data.base.map((item, index) => ({
            ...item,
            value: index === i ? item.extra ? ( item.value !=='' ? (item.value + ',' + v ) : v) : v : item.value
          })),
        },
      }));
    }
  };
  showImages = (val) => {
    let images = [];
    if(val.indexOf(',') > -1){
      images = val.split(',');
    }else {
      images.push(val)
    }
    this.setState({
      dataImages: images,
      img_flag: true
    });
  };
  deleteImg = (i, v, item) => {
    let arr = []
    if(v.indexOf(',') > -1){
      arr = v.split(",")
    }else{
      arr = [v]
    }
    arr.map( (el, j) => {
      if(el === item) {
        arr.splice(j, 1)
      }
    });
    this.onChange(i, arr.join(","), true)
  };
  checkPhoneAccount = (rule, value, callback) => {
    var re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
    if (value.length === 11 || re.test(value)) {
      callback();
    } else {
      callback('请填写正确的手机号');
    }
  };
  checkCardAccount = (rule, value, callback) => {
    var re = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (value.length === 15 || re.test(value)) {
      callback();
    } else {
      callback('请填写正确的身份证号');
    }
  };
  getFields = () => {
    const children = [];
    const state = {...this.state};
    const { getFieldDecorator } = this.props.form;
    state.data.base.map((v, i) => {
      if(v.extra){
        if(v.value !== '' && v.value !== null){
          let arr = [];
          let showImgs = [];
          if(v.value.indexOf(',') > -1){
            arr = v.value.split(',')
          }else{
            arr.push(v.value)
          }
          arr.map((v) => {
            if(v.indexOf('http') > -1){
              showImgs.push(v)
            }else{
              showImgs.push(common.pic_url + v)
            }
          });
          children.push(
            <Col span={8} key={i}>
              <Form.Item label={v.label} style={{height: 100}} required={true}>
                {
                  showImgs.map((item, j) => {
                    return <div style={{display: 'inline-block', marginRight: 13, position: 'relative'}}><img onClick={ () => { this.showImages(item) } } style={{ width: 60, cursor: 'pointer', maxHeight: 50}} src={item} key={j} alt=""/>
                      <img src={"/out.png"} onClick={ () => { this.deleteImg(i, v.value, item)}} style={{width: 13, cursor: 'pointer', position: 'absolute', right: '-6px', top: '-4px'}} alt=""/> </div>
                  })
                }
                {this.upLoadImg(i)}
              </Form.Item>
            </Col>
          );
        }
        else{
          children.push(
            <Col span={8} key={i}>
              <Form.Item label={v.label} required={true}>
                {this.upLoadImg(i)}
              </Form.Item>
            </Col>
          )
        }
      }
      else if(v.number) {
        children.push(
          <Col span={8} key={i}>
            <Form.Item label={v.label}>
              {getFieldDecorator(v.label, {
                rules: [ {
                  required: true, message: '请填写' + v.label,
                }],
              })(
              <InputNumber placeholder={`请填写${v.label}`}  style={{width: '100%'}} onChange={(e) => this.onChange(i, e)} disabled={v.disabled} />
              )}
            </Form.Item>
          </Col>
        );
      }
      else if(v.select) {
        children.push(
          <Col span={8} key={i}>
            <Form.Item label={v.label}>
              {getFieldDecorator(v.label, {
              rules: [ {
                required: true, message: '请选择' + v.label,
              }],
            })(
              <Select style={{width: '100%'}} placeholder={`请选择${v.label}`} onChange={(option, e) =>{ this.onChange(i, e.props.value) }} disabled={v.disabled}>
                {
                  v.select.map((item, j) => {
                    return <Option key={j} value={item.value} title={i}>{item.label}</Option>
                  })
                }
              </Select>
              )}
            </Form.Item>
          </Col>
        );
      }
      else if(v.date){
        children.push(
          <Col span={8} key={i}>
            <Form.Item label={v.label}>
              {getFieldDecorator(v.label, {
                rules: [ {
                  required: true, message: '请选择' + v.label,
                }],
              })(
              <DatePicker placeholder={`请选择${v.label}`} style={{width: '100%'}} onChange={ (d, str) => this.onChange(i, str) } />
              )}
            </Form.Item>
          </Col>
        );
      }
      else{
        if(v.phone){
          children.push(
            <Col span={8} key={i}>
              <Form.Item label={v.label}>
                {getFieldDecorator(v.label, {
                  rules: [ {
                    required: true, message: '请填写' + v.label,
                  },{
                    validator: this.checkPhoneAccount,
                  }],
                })(
                  <Input placeholder={v.label} onChange={(e) => this.onChange(i, e.target.value)} disabled={v.disabled} />
                )}
              </Form.Item>
            </Col>
          );
        }
        else if(v.card_validator){
          children.push(
            <Col span={8} key={i}>
              <Form.Item label={v.label}>
                {getFieldDecorator(v.label, {
                  rules: [ {
                    required: true, message: '请填写' + v.label,
                  },{
                    validator: this.checkCardAccount,
                  }],
                })(
                  <Input placeholder={`请填写${v.label}`} onChange={(e) => this.onChange(i, e.target.value)} disabled={v.disabled} />
                )}
              </Form.Item>
            </Col>
          );
        }
        else{
          children.push(
            <Col span={8} key={i}>
              <Form.Item label={v.label}>
                {getFieldDecorator(v.label, {
                  rules: [ {
                    required: true, message: '请填写' + v.label,
                  }],
                })(
                  <Input placeholder={`请填写${v.label}`} onChange={(e) => this.onChange(i, e.target.value)} disabled={v.disabled} />
                )}
              </Form.Item>
            </Col>
          );
        }

      }
    });
    return children;
  };
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.addTransCarData(this.state.data);
      }else{

      }
    });

  };
  handleReset = () => {
    this.props.onCancel();
    this.props.form.resetFields();
  };
  callback = () => {
  };
  HiddenImages = () => {
    this.setState({
      img_flag: false
    })
  };
  render () {
    const state = {...this.state};
    return (
      <div className='car-table-class'>
        <Form
          className="ant-advanced-search-form"
          onSubmit={this.handleSearch}
        >
          <Row gutter={24}>{this.getFields()}</Row>
          <Row style={{marginTop: 10}}>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit">新增</Button>
              <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>取消</Button>
            </Col>
          </Row>
        </Form>
        {
          state.img_flag ? <ShowImages data={{ data: state.dataImages, width: 480}} callback={this.HiddenImages.bind(this)} /> : null
        }
      </div>
    )
  }
}
AddStationCarMsg = Form.create({})(AddStationCarMsg);
