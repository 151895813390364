const common = {
  pic_url: 'https://file.shomg.cn',
  fuel_type: [
    {
      label: '汽油',
      value: 1
    },
    {
      label: '柴油',
      value: 2
    }
  ],
  emission_standards: [
    {
      label: '国四',
      value: 4
    },
    {
      label: '国五',
      value: 5
    },
    {
      label: '国六',
      value: 6
    }
  ],
  car_model: [
    {
      label: 'A1',
      value: 'A1'
    },
    {
      label: 'A2',
      value: 'A2'
    },
    {
      label: 'A3',
      value: 'A3'
    },
    {
      label: 'B1',
      value: 'B1'
    },
    {
      label: 'B2',
      value: 'B2'
    },
    {
      label: 'C1',
      value: 'C1'
    },
    {
      label: 'C2',
      value: 'C2'
    },
    {
      label: 'C3',
      value: 'C3'
    },
    {
      label: 'C4',
      value: 'C4'
    },
   /* {
      label: 'D',
      value: 'D'
    },
    {
      label: 'E',
      value: 'E'
    },
    {
      label: 'F',
      value: 'F'
    },
    {
      label: 'M',
      value: 'M'
    },
    {
      label: 'N',
      value: 'N'
    },
    {
      label: 'P',
      value: 'P'
    }*/
  ],
  nation: [
    {
      label: '汉族',
      value: '汉族'
    },
    {
      label: '壮族',
      value: '壮族'
    },
    {
      label: '裕固族',
      value: '裕固族'
    },
    {
      label: '回族',
      value: '回族'
    },
    {
      label: '苗族',
      value: '苗族'
    },
    {
      label: '彝族',
      value: '彝族'
    },
    {
      label: '瑶族',
      value: '瑶族'
    },
    {
      label: '土族',
      value: '土族'
    },
  ],
  obturator: [
    {
      label: '无',
      value: 0
    },
    {
      label: '有',
      value: 1
    }
  ],
  leakproof: [
    {
      label: '无',
      value: 0
    },
    {
      label: '完好',
      value: 1
    },
    {
      label: '破损',
      value: 2
    },
  ],
  ToFormatDate: function (datetime, method) {
    if(!datetime || datetime==null){
      return '';
    }
    var date = new Date(datetime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    var d = date.getDate();
    var h = date.getHours();
    var mm = date.getMinutes();
    var s = date.getSeconds();
    m = m < 10 ? '0' + m : m;
    d = d < 10 ? '0' + d : d;
    h = h < 10 ? '0' + h : h;
    mm = mm < 10 ? '0' + mm : mm;
    s = s < 10 ? '0' + s : s;
    if(method === 'YY-MM-DD'){
      return y + '-' + m + '-' + d
    }
    else if(method === 'YYYY-MM-DD HH:mm:ss'){
      return y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + s
    }
    return m + '月' + d + '日'
  }
}
export default common
