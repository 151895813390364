// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LGiuj96nYvxectX0XHAz- {\n  border: 1px solid #e6e6e6;\n  padding: 5px;\n}\n.LGiuj96nYvxectX0XHAz- > div {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n}\n\n._1IP_-ghApzT20s1MhDOaRV {\n  width: 2px;\n  background-color: #1491FB;\n  height: 72px;\n}\n\n._2O_vijyx_Q75O-siH0SgQ {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-shrink: 0;\n  flex-grow: 0;\n  background-color: #1491FB;\n  color: #fff;\n  width: 20px;\n  height: 20px;\n  border-radius: 20px;\n  font-size: 14px;\n}\n\n.g1BYjx6u1oGi0fjTfVUg5 {\n  opacity: 0;\n}\n\n._3uSbHjGT6JVvrnfRDPPFJ4 {\n  width: 0;\n  height: 0;\n  border-bottom: 15px solid #fff;\n  border-right: 15px solid transparent;\n  z-index: 1;\n  transform: rotate(45deg);\n  box-shadow: -2px 2px 2px 0 rgba(150, 150, 150, 0.1);\n  margin-right: -8px;\n  margin-top: 74px;\n}\n\n.TGK0RPaQQFjXwRa2M0Cdv {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  position: relative;\n  background-color: #fff;\n  margin: 20px 0;\n  border-radius: 10px;\n  box-shadow: 0 0 6px 2px rgba(100, 100, 100, 0.1);\n}\n\n._1OyRHVmLbmf1gV4sjsZiLu {\n  display: flex;\n  align-items: center;\n  color: #fff;\n  border-radius: 10px 10px 0 0;\n  padding: 10px 20px;\n}\n\n.ZpOV5WXO9eYJBH0XRf8DB {\n  display: flex;\n  margin: 0 15px;\n  padding: 10px 0;\n  border-bottom: 1px solid #F4F4F4;\n  box-sizing: border-box;\n  justify-content: space-between;\n}\n.ZpOV5WXO9eYJBH0XRf8DB :last-child {\n  border-bottom: none;\n}\n\n.ruVFeFUyGjiuFAvLr2wl- {\n  min-width: 80px;\n  color: #B3B3B3;\n}\n\n._2b9hxyMdrSz_gCTQrcriwG {\n  width: 30px;\n  height: 40px;\n  cursor: pointer;\n  margin-left: 8px;\n}\n\n._3aRWNXNC4RJ_2UIPVmZLZT {\n  cursor: pointer;\n  margin-left: 8px;\n  font-size: 14px !important;\n  padding: 6px 23px;\n  color: #fff !important;\n}", ""]);
// Exports
exports.locals = {
	"base": "LGiuj96nYvxectX0XHAz-",
	"crossLine": "_1IP_-ghApzT20s1MhDOaRV",
	"number": "_2O_vijyx_Q75O-siH0SgQ",
	"opacity": "g1BYjx6u1oGi0fjTfVUg5",
	"triangleLeft": "_3uSbHjGT6JVvrnfRDPPFJ4",
	"row": "TGK0RPaQQFjXwRa2M0Cdv",
	"itemTop": "_1OyRHVmLbmf1gV4sjsZiLu",
	"itemBottom": "ZpOV5WXO9eYJBH0XRf8DB",
	"itemLeft": "ruVFeFUyGjiuFAvLr2wl-",
	"imgs": "_2b9hxyMdrSz_gCTQrcriwG",
	"showFile": "_3aRWNXNC4RJ_2UIPVmZLZT"
};
module.exports = exports;
