// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3x19hUszevWvXlavyzLUol {\n  cursor: pointer;\n  position: relative;\n  color: #8e8e93;\n}\n._3x19hUszevWvXlavyzLUol:hover {\n  color: #1e88e5;\n}", ""]);
// Exports
exports.locals = {
	"container": "_3x19hUszevWvXlavyzLUol"
};
module.exports = exports;
