import React, { useState, useEffect } from 'react'
import { Modal} from 'antd'
import Gg from './Gg'
import request from 'utils/request';
import styles from './index.scss'
/* 查看轨迹 */
export default function Details({ props }) {
  let { trackInfo, TrackInfo, selectedRows } = props
  const [car_num, setCarNum] = useState('')
  const [visible, setVisible] = useState(false)
  const [arr, setArr] = useState([]);
  useEffect(() => { if (!visible) { TrackInfo({ visible }) } }, [visible])
  useEffect(() => { if (trackInfo) { setVisible(true) } }, [trackInfo])
  useEffect(() => { if (selectedRows.length > 0) { setCarNum(selectedRows[0].car_number);  } }, [selectedRows])
  function handleCancel(e) { setVisible(false) };
  useEffect(() => {
    if (!visible) return
    request('/web/gb_list/getCarAlarmRoute', { car_number: car_num, alarm_time: selectedRows[0].alarm_time })
      .then(res => {
        setArr(res.data.data)
      })
    }, [visible]);
  return (
    <Modal
      title='轨迹查看'
      centered
      visible={visible}
      maskClosable={false}
      onCancel={handleCancel}
      footer={null}
      width='90%'
      height='90%'
      className={styles.modal}
    >
      <Gg arr={arr}/>
    </Modal >
  )
}
