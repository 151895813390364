import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import styles from './ShowDetail.scss';
import request from 'utils/request';
import {message} from 'antd';
import { previewable, UseEcharts, LicensePlateCorrection, StartOrEndPoint, UseMapLines, useObservable,  modifyCarNum } from 'components/Previewer';
import {ALARMNAME, ALARMIMAGES} from './FilterData';
import TrashTicketDetail from '../CommandCenter/TrashTicketDetail';
import store from "stores/user";

function shoeAlarmDetail(id){
  const [data, setData] = useState();
  useEffect(() => {
    request('/web/count_alarm/detail', {id})
      .then(d => d.data.data)
      .then(setData)
  }, [id]);
  return data;
}
function useMap(chart, isChartUsable, series, center, zoom){
  useEffect(() => {
    if(!isChartUsable()) return;
    let option = {
      tooltip: {
        trigger: 'item'
      },
      animation: false,
      bmap: {
        center: center || [103.836472, 30.719426],
        zoom,
        roam: true,
        mapStyle: {
          // styleJson: mapStyleJson,
        },
      },
      series
    }
    chart.setOption(option);
  }, [chart, isChartUsable, series, center, zoom])
}
function setOtherLines(data){
  return UseMapLines({
    polyline: true,
    silent: true,
    symbol: ['none', 'arrow'],
    effect: true,
    lineColor: '#FF5152',
    lineWidth: 2,
    curveness: 0.5,
    trailLength: 0,
    effectSymbol: 'image:///images/center/a4.png',
    effectSymbolSize: [15, 42.5],
    data,
    name: 'uselines',
    label: {show: false},
    constantSpeed: 20,
    tooltip: {show: false},
    zlevel: 5
  });
}
function ShowDetail({id, state, preview}){
  const alarmData = shoeAlarmDetail(id);
  const alarmType = alarmData ? alarmData.type : 0;
  const {info: {area_id, area_name}} = store.getState();
  const roleId = store.getState().roles.id;
  const [chart, isChartUsable, chartRefGetter] = UseEcharts();
  const [series, setSeries] = useState([]);
  const [center, setCenter] = useState([103.836472, 30.719426]);
  const newCarNum = useObservable(modifyCarNum);
  const [zoom, setZoom] = useState(13);
  const [trashDetailSpin, setTrashDetailSpin] = useState();
  const [trashDetailData, setTrashDetailData] = useState();

  useEffect(() => {
    if(alarmData){
      if(alarmData.type === 7 || alarmData.type === 8 || alarmData.type === 10){
        console.log('有轨迹')
        if(alarmData.realRoute.length > 1){
          setZoom(15);
          let series_data = [];
          console.log(alarmData.realRoute.map(({lng, lat}) => [lng, lat]))
          series_data.push(setOtherLines([{coords: alarmData.realRoute.map(({lng, lat}) => [lng, lat])}]));
          series_data.push(StartOrEndPoint({
            name: 'start_pointer',
            symbol: 'circle',
            data: [{name: '起点', value: [alarmData.realRoute[0].lng, alarmData.realRoute[0].lat]}],
            format: '起点',
            backImage: '/images/center/diago1.png',
            itemColor: '#03d5a0'
          }));
          series_data.push(StartOrEndPoint({
            name: 'end_pointer',
            symbol: 'circle',
            data: [{name: '终点', value: [alarmData.realRoute[alarmData.realRoute.length - 1].lng, alarmData.realRoute[alarmData.realRoute.length - 1].lat]}],
            format: '终点',
            backImage: '/images/center/diago3.png',
            itemColor: '#EB3873'
          }));
          setSeries(series_data);
          setCenter([alarmData.realRoute[0].lng, alarmData.realRoute[0].lat]);
          console.log(series_data, [alarmData.realRoute[0].lng, alarmData.realRoute[0].lat])
        }else if(alarmData.realRoute.length === 1){
          setZoom(15);
          const series = setOtherLines([{coords: [[alarmData.realRoute[0].lng, alarmData.realRoute[0].lat], [alarmData.realRoute[0].lng, alarmData.realRoute[0].lat]]}])
          setSeries(series);
          setCenter([alarmData.realRoute[0].lng, alarmData.realRoute[0].lat])
        }else{
          setZoom(13);
          message.warn('暂无轨迹');
          setSeries([StartOrEndPoint({
            data: [{value: [alarmData.lng, alarmData.lat]}],
            type: 'effectScatter',
            symbol: 'path://M100 100,A50 50,0 1 1 100 101z M175 100,A25 25,0 1 0 175 101z',
            symbolSize: 16,
            itemColor: '#FE4442',
            backImage: '/images/alarm/back.png',
            tooltipLabel: alarmData.created_time,
            format: alarmData.created_time,
            width: 145,
            height: 45,
            tooltip:{
              show: true,
              formatter: function(parmas){
                return `告警时间：${alarmData.created_time}`
              }
            },
          })]);
          setCenter([alarmData.lng, alarmData.lat])
        }
      }
      else{
        setZoom(13)
        setSeries([StartOrEndPoint({
          data: [{value: [alarmData.lng, alarmData.lat]}],
          type: 'effectScatter',
          symbol: 'path://M100 100,A50 50,0 1 1 100 101z M175 100,A25 25,0 1 0 175 101z',
          symbolSize: 16,
          itemColor: '#FE4442',
          backImage: '/images/alarm/back.png',
          tooltipLabel: alarmData.created_time,
          format: alarmData.created_time,
          width: 145,
          height: 45,
          tooltip:{
            show: true,
            formatter: function(parmas){
              return `告警时间：${alarmData.created_time}`
            }
          },
        })]);
        setCenter([alarmData.lng, alarmData.lat]);
      }
    }
  }, [alarmData])
  useMap(chart, isChartUsable, series, center, zoom);

  const getTicketDetail = (ticket_id) => {
    setTrashDetailSpin(true);
    request('web/homemap/ticketDetail', {id: ticket_id, area_id, area_name})
      .then(body => {
        setTrashDetailData(body.data.data);
      })
      .finally(() => {
        setTrashDetailSpin(false)
      })
  }
  const warnImage = <img src="/images/alarm/warn.png" alt=""/>;
  const showImage = (src, id) => <span className={styles.showPhoto} onClick={() => preview.image(src, roleId === 111 || roleId === '111', id)}>查看</span>;
  useEffect(() => {
    console.log(newCarNum);
  }, [newCarNum]);

  const HistoryAlarmLink = (alarm_count) => {
    return  <Link to={{pathname: './history-alarm/', state: {...state, alarmId: id, alarmType: alarmType, deviceType: alarmData.device_type}}}>
      <span>{alarm_count + '次'} {warnImage}</span>
    </Link>
  };
  const alarmTitle = () => {
    return alarmType ? (alarmType === 8 ? alarmData.address :
      ((alarmType === 9 && alarmData.device_type === 1) || (alarmData.alarmType === 10 && type === 1)) ?( newCarNum ? newCarNum.car_num : alarmData.car_num ):
        alarmData.building_name) : null
  };

  return <>
      <div className={styles.container}>
        <div className={styles.detail}>
          <div className={styles.title}>{alarmTitle() }{(alarmData && alarmData.ticket_id > 0) && <span onClick={() => getTicketDetail(alarmData.ticket_id)}>联单详情</span>}</div>
          <div className={styles.content}>
            <div className={styles.alarmDetail}>
              {
                alarmType === 5 ?
                  <>
                    <div>
                      <div><label>告警时间</label><span>{alarmData.created_time}</span></div>
                      <div><label>土方开挖时间</label><span>{alarmData.start_dig_date}至{alarmData.end_dig_date}</span></div>
                      <div><label>告警地点</label><span>{alarmData.address}</span></div>
                    </div>
                    <div>
                      <div><label>疑似偷排趟次</label>
                        <Link to={{pathname: './suspected-stealing', state: {...state, type: 1, alarmId: id}}}>
                          <span>{alarmData.cheat_ticket_count + '次'} {warnImage}</span>
                        </Link>
                      </div>
                      <div><label>历史告警</label>{HistoryAlarmLink(alarmData.alarm_count)}</div>
                      <div />
                    </div>
                  </>
                  : alarmType === 6 ?
                  <>
                    <div>
                      <div><label>告警时间</label><span>{alarmData.created_time}</span></div>
                      <div><label>土方开挖时间</label><span>{alarmData.start_dig_date}至{alarmData.end_dig_date}</span></div>
                      <div><label>告警地点</label><span>{alarmData.address}</span></div>
                    </div>
                    <div>
                      <div><label>车牌照片</label><span>{showImage(alarmData.photo, alarmData.id)}</span></div>
                      <div><label>车牌号码</label><span>{newCarNum ? newCarNum.car_num : alarmData.car_num}</span></div>
                      <div><label>历史告警</label>{HistoryAlarmLink(alarmData.alarm_count)}</div>
                    </div>
                  </> : alarmType === 7 ?
                    <>
                      <div>
                        <div><label>告警时间</label><span>{alarmData.created_time}</span></div>
                        <div><label>土方开挖时间</label><span>{alarmData.start_dig_date}至{alarmData.end_dig_date}</span></div>
                        <div><label>车牌号码</label><span>{newCarNum ? newCarNum.car_num : alarmData.car_num}</span></div>
                        <div><label>告警地点</label><span>{alarmData.address}</span></div>
                      </div>
                      <div>
                        <div><label>车牌照片</label><span>{showImage(alarmData.photo, alarmData.id)}</span></div>
                        <div><label>所属运企</label><span>{alarmData.transport_name}</span></div>
                        <div><label>所属区县</label><span>{alarmData.area_name}</span></div>
                        <div><label>历史告警</label>{HistoryAlarmLink(alarmData.alarm_count)}</div>
                      </div>
                    </> : alarmType === 8 ?
                      <>
                        <div>
                          <div><label>告警时间</label><span>{alarmData.created_time}</span></div>
                          <div><label>车牌号码</label><span>{newCarNum ? newCarNum.car_num : alarmData.car_num}</span></div>
                          <div><label>车牌照片</label><span>{showImage(alarmData.photo, alarmData.id)}</span></div>
                        </div>
                        <div>
                          <div><label>所属运企</label><span>{alarmData.transport_name}</span></div>
                          <div><label>所属区县</label><span>{alarmData.area_name}</span></div>
                          <div><label>历史告警</label>{HistoryAlarmLink(alarmData.alarm_count)}</div>
                        </div>
                      </>: alarmType === 9 ?
                        alarmData.device_type === 1 ?
                          <>
                            <div>
                              <div><label>告警时间</label><span>{alarmData.created_time}</span></div>
                              <div><label>车牌号码</label><span>{newCarNum ? newCarNum.car_num : alarmData.car_num}</span></div>
                              <div><label>车牌照片</label><span>{showImage(alarmData.photo, alarmData.id)}</span></div>
                              <div><label>告警地点</label><span>{alarmData.address}</span></div>
                            </div>
                            <div>
                              <div><label>所属运企</label><span>{alarmData.transport_name}</span></div>
                              <div><label>所属区县</label><span>{alarmData.area_name}</span></div>
                              <div><label>历史告警</label>{HistoryAlarmLink(alarmData.alarm_count)}</div>
                              <div />
                            </div>
                          </> : <>
                            <div>
                              <div><label>告警时间</label><span>{alarmData.created_time}</span></div>
                              <div><label>告警地点</label><span>{alarmData.address}</span></div>
                              <div><label>土方开挖时间</label><span>{alarmData.start_dig_date}至{alarmData.end_dig_date}</span></div>
                            </div>
                            <div>
                              <div><label>设备名称</label><span>{alarmData.door_name}</span></div>
                              <div><label>设备照片</label><span style={{color: '#1491fb', cursor: 'pointer'}} onClick={() => preview.image(alarmData.photo)}>查看</span></div>
                              <div><label>历史告警</label>{HistoryAlarmLink(alarmData.alarm_count)}</div>
                            </div>
                          </> : alarmType === 10 ?
                      <>
                        <div>
                          <div><label>告警时间</label><span>{alarmData.created_time}</span></div>
                          <div><label>所属运企</label><span>{alarmData.transport_name}</span></div>
                          <div><label>告警地点</label><span>{alarmData.address}</span></div>
                        </div>
                        <div>
                          <div><label>所属运企</label><span>{alarmData.area_name}</span></div>
                          <div><label>历史告警</label>{HistoryAlarmLink(alarmData.alarm_count)}</div>
                          <div />
                        </div>
                      </>
                       : alarmType === 11 ?
                      <>
                        <div>
                          <div><label>告警时间</label><span>{alarmData.created_time}</span></div>
                          <div><label>告警地点</label><span>{alarmData.address}</span></div>
                          <div><label>土方开挖时间</label><span>{alarmData.start_dig_date}至{alarmData.end_dig_date}</span></div>
                        </div>
                        <div>
                          <div><label>装车照片</label><span style={{color: '#1491fb', cursor: 'pointer'}} onClick={() => preview.image(alarmData.photo)}>查看</span></div>
                          <div><label>私挖盗采</label>
                            <Link to={{pathname: './suspected-stealing', state: {...state, type: 2, alarmId: id}}}>
                              <span>{alarmData.steal_sand_count + '趟'} {warnImage}</span>
                            </Link>
                          </div>
                          <div><label>历史告警</label>{HistoryAlarmLink(alarmData.alarm_count)}</div>
                        </div>
                      </> : null
              }
              </div>
            <div className={styles.alarmType}>
              <span>告警类型</span>
              <img src={ALARMIMAGES[alarmType]} alt=""/>
              <span>{ALARMNAME[alarmType]}</span>
            </div>
          </div>

        </div>
        <div className={styles.map} ref={chartRefGetter} />
      </div>
    {
      trashDetailData && <TrashTicketDetail
        data={trashDetailData}
        ticketType={10}
        callType={false}
        trashSpin={trashDetailSpin}
        style={{top: 30, margin: 'auto', left: 0, right: 0, zIndex: '898989898989898'}}
        ticketId={id}
        radar={true}
        closeTicketDetail={() => setTrashDetailData()}
      />
    }
    </>
}

export default previewable(ShowDetail)
