import React, { useState, useEffect } from 'react';
import { Icon } from 'antd';
import request from 'utils/request';
import styles from './OfflineWork.scss';

function getOffLineReason(id) {
  const [data, setData] = useState();
  useEffect(() => {
    request('/web/ticket/OffLineReason', { ticket_id: id })
      .then((res) => res.data.data)
      .then(setData)
  }, [id]);
  return data;
}
export default function OfflineWork({ id, style, onClose }) {
  const data = getOffLineReason(id);
  return (
    <div className={styles.offline} style={{ ...style }}>
      {
        data && (
          <>
            <div>
              <span style={{height: 20, color:'#1ea3ef'}}>{data.car_number}</span>
              <Icon type="close" style={{cursor: 'pointer'}} onClick={() => onClose()} />
            </div>
            <div className={styles.content}>
              <span>离线申报原因</span>
              <p>{data.reason}</p>
            </div>
            <div className={styles.content}>
              <span>离线时段</span>
              <p>{data.offline_time}</p>
            </div>
          </>
        )
      }
    </div>
  )
}
