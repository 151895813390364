import React, {useState, useEffect} from 'react';
import {Button, Divider, Popconfirm, message} from 'antd';
import { previewable } from 'components/Previewer';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';

import LinkButton from 'ui/TableCell/LinkButton';
import LinkText from 'ui/TableCell/LinkText';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';
import debug from 'utils/debug';
import request from "utils/request";
import styles from '../index.scss';
import moment from 'moment';

const CheckStatus = [
  { name: '待区（市）县扬尘办审核', id: 1 },
  { name: '待市1级审核', id: 2 },
  { name: '待市2级审核', id: 5 },
  { name: '待市3级审核', id: 6 },
  { name: '通过', id: 3 },
  { name: '不通过', id: 4 }
];
const STATUS = ['', '待区（市）县扬尘办审核', '待市1级审核', '通过', '不通过', '待市2级审核', '待市3级审核'];
export const dataSource = new DataSource('/web/keep_on_record/transportRecordList');
function DeleteMsg(id){
  request('/web/keep_on_record/transportRecordDelete', {id: id})
    .then(body => {if(body.data.code === 200){message.success('删除成功！'); dataSource.refresh()}else{message.warn('删除失败！')}})
}

function judgeShow (){
  const [flag, setFlag] = useState(true);
  const [notice, setNotice] = useState('');
  useEffect(() => {
    request('/web/report/isReportChecked', { report_type: 4 })
      .then(body => body.data)
      .then(data => {setFlag(data.status); setNotice(data.notice)})
  }, []);
  return [flag, notice];
}

function List({ preview }) {
  debug.render('List');
  const [showFlag, notice] = judgeShow();
  const columns = [
    serial(true),
    { title: '报备时间', dataIndex: 'reported_to_the_time', width: width(14)},
    { title: '报备人', dataIndex: 'reported_to_the_people', width: width(14)},
    { title: '类别', dataIndex: 'created_time', width: width(5), className: styles.tablePadding, render: (v, data) => (
      <><div style={{height: 40, borderBottom: '1px solid #e8e8e8', whiteSpace: 'nowrap'}}>变更前</div><div style={{whiteSpce: 'nowrap'}}>变更后</div></>
      )},
    { title: '运企名称', dataIndex: 'shipping_enterprise_name', className: styles.tablePadding, style: {padding: 0}, width: width(20), render: (v, {shipping_enterprise_name, old_shipping_enterprise_name}) => (
        <><div style={{height: 40, borderBottom: '1px solid #e8e8e8', whiteSpace: 'nowrap'}}>{old_shipping_enterprise_name}</div><div style={{whiteSpce: 'nowrap'}}>{shipping_enterprise_name}</div></>
      )},
    { title: '法人', dataIndex: 'as_a_legal_person', className: styles.tablePadding, width: width(6), style: {padding: 0}, render: (v, {as_a_legal_person, old_as_a_legal_person}) => (
        <><div style={{height: 40, borderBottom: '1px solid #e8e8e8', whiteSpace: 'nowrap'}}>{old_as_a_legal_person}</div><div style={{whiteSpce: 'nowrap'}}>{as_a_legal_person}</div></>
      )},
    { title: '联系电话', dataIndex: 'contact_phone_number', className: styles.tablePadding, width: width(8), style: {padding: 0}, render: (v, {contact_phone_number, old_contact_phone_number}) => (
        <><div style={{height: 40, borderBottom: '1px solid #e8e8e8', whiteSpace: 'nowrap'}}>{old_contact_phone_number}</div><div style={{whiteSpce: 'nowrap'}}>{contact_phone_number}</div></>
      )},
    { title: '具体地址', dataIndex: 'the_specific_address', className: styles.tablePadding, width: width(14), style: {padding: 0}, render: (v, {the_specific_address, old_the_specific_address}) => (
        <><div style={{height: 40, borderBottom: '1px solid #e8e8e8', whiteSpace: 'nowrap'}}>{old_the_specific_address}</div><div style={{whiteSpce: 'nowrap'}}>{the_specific_address}</div></>
      )},
    { title: '报备要件', dataIndex: 'files', width: width(7), render: ($, {id}) => (
        <>
          <LinkText to={`./${id}/show-files`}>查看</LinkText>
        </>
      ) },
    { title: '备注', dataIndex: 'note', render: (v) => (<div title={v} style={{width: 200,overflow: 'hidden',
        textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{v}</div>), },
    { title: '审核状态', dataIndex: 'review_the_status', render: (t, value) => (<span>{STATUS[t]}</span>) },
    { title: '审核记录', dataIndex: 'id', width: width(7) , render: ($, {id}) => (
        <>
          <LinkText to={`./${id}/check-record`}>查看</LinkText>
        </>
      )},
    actions({
      isFixed: true,
      width: 290,
      render: ($, { id, review_the_status }) => (
        <>
          {
            review_the_status === 1 || review_the_status === 4 ? <>
            <LinkButton to={`./${id}/replay-list-reporting`}>重新报备</LinkButton>
            <Divider type="vertical" />
          </> : null
          }
          <Popconfirm placement="top" title={'确认删除?'} onConfirm={() => { DeleteMsg(id) }} okText="Yes" cancelText="No">
            <Button type={'danger'} size={'small'}>删除</Button>
          </Popconfirm>
        </>
      ),
    }),
  ];

  const querys = [
    { title: '请选择审核状态', key: 'status', type: 'select', options: CheckStatus, valueKey: 'id', labelKey: 'name', style: { width: 200 } },
    { title: ['报备', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD HH:mm:ss', style: { width: 360 }, showTime: true },
  ];

  const buttons = [
    { title: '新增', link: './list-reporting', icon: 'plus', disabled: !showFlag, extraNotice: notice },
  ];


  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}

export default previewable(List);
