/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { List, Map } from 'immutable';
import { Icon, Button } from 'antd';
import debug from 'utils/debug';
import { open } from 'utils/request';
import styles from './Image.scss';
import LicensePlateCorrection from './LicensePlateCorrection';
function getFitSize(imageWidth = 1, imageHeight = 1, canvasWidth = 1, canvasHeight = 1) {
  const proportion = imageWidth / imageHeight;
  let width = Math.min(imageWidth, canvasWidth);
  let height = Math.min(imageHeight, canvasHeight);
  if (height * proportion > width) {
    height = width / proportion;
  } else {
    width = height * proportion;
  }
  return { width, height };
}

function generateDots(length, current) {
  const output = [];
  for (let i = 0; i < length; i += 1) {
    output.push(i === current);
  }
  return output;
}

export default function ImagePreviewer({ images, visiable = false, onChange, showCar, id }) {
  debug.render('PreviewImage');
  const [sources, setSources] = useState(List());
  const [step, setStep] = useState(0);
  const [canvas, setCanvas] = useState({});
  const [visible, setVisible] = useState();
  // 当新的图片组传入
  useEffect(() => {
    // 处理各种格式的输入
    let imgs = [];
    if (!images) return;
    if (typeof images === 'string') {
      imgs = images.split(',').map(src => ({ src })).filter(v => v);
    } else if (Array.isArray(images)) {
      imgs = images.map((img) => {
        if (!img) return false;
        if (typeof img === 'string') return { src: img };
        if (typeof img === 'object') return img;
        return false;
      }).filter(v => v);
    } else if (typeof images === 'object') {
      imgs = [images];
    }
    if (imgs.length === 0) return;
    // 预渲染
    setSources(List(imgs.map(({ src, ...rest }) => Map(rest))));
    // 加载图片资源
    imgs.forEach(({ src }, i) => {
      const loader = new Image();
      loader.onload = function onLoad() {
        setSources(sources => sources.update(i, source => (
          source.set('src', this.src).set('width', this.width).set('height', this.height)
        )));
      };
      loader.src = src;
    });
  }, [images]);
  // 当步数受控
  useEffect(() => {
    setStep(0);
  }, [images]);
  // 获取画布大小
  const canvasRef = useCallback((node) => {
    if (node !== null) {
      setCanvas({
        width: node.getBoundingClientRect().width,
        height: node.getBoundingClientRect().height,
      });
    }
  }, []);

  const prevable = step > 0;
  const nextable = step < sources.size - 1;
  const source = sources.get(step) || Map();
  const get = path => source.get(path);

  const src = get('src');
  const title = get('title');
  const width = get('width');
  const height = get('height');

  const size = getFitSize(width, height, canvas.width, canvas.height);
  const dots = generateDots(sources.size, step);

  return (
    <div className={classNames(styles.container, { [styles.visiable]: visiable })}>
      <div className={styles.toolbar}>
        <div className={styles.downloadBtn} onClick={() => open(src, null, true)}><Icon type="download" /></div>
        <div className={styles.title}>{title}</div>
        <div className={styles.closeBtn} onClick={() => onChange(false)}><Icon type="close" /></div>
      </div>
      <div className={classNames(styles.prev, prevable ? styles.enabled : styles.disabled)} onClick={() => prevable && setStep(step - 1)}><svg width="12" height="42"><polyline points="11,41 1,21 11,0" /></svg></div>
      <div className={classNames(styles.next, nextable ? styles.enabled : styles.disabled)} onClick={() => nextable && setStep(step + 1)}><svg width="12" height="42"><polyline points="1,41 11,21 1,1" /></svg></div>
      <div className={styles.image} ref={canvasRef}>
        <img src={src} alt={title} style={size} />
      </div>
      <div className={styles.footer}>
        <div className={styles.dots}>
          {dots.map((isCurrent, index) => (
            <div key={index} className={classNames(styles.dot, { [styles.active]: isCurrent })} />
          ))}
        </div>
      </div>
      {
        showCar ? <div className={styles.showCar}><Button type="primary" onClick={() => setVisible(true)}>车牌纠错</Button></div> : null
      }
      {
        visible ? <LicensePlateCorrection id={id} onClose={() => setVisible(false)} /> : null
      }
    </div>
  );
}
