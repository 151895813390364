// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2e40L_TDUw9CW_DOiDHk3O {\n  color: #000;\n  font-size: 16px;\n  text-align: center;\n}\n._2e40L_TDUw9CW_DOiDHk3O div {\n  border-radius: 4px;\n  padding: 30px;\n  background-color: #e8e8e8;\n  border: 2px solid #D7D7D7;\n  cursor: pointer;\n  color: #000;\n  margin-top: 10px;\n}\n._2e40L_TDUw9CW_DOiDHk3O ._1GIh5jRpNx2SOLOv_z-pNC {\n  background-color: #81D3F8;\n  border: 2px solid #02A7F0;\n  color: #FFFFFF;\n}", ""]);
// Exports
exports.locals = {
	"container": "_2e40L_TDUw9CW_DOiDHk3O",
	"active": "_1GIh5jRpNx2SOLOv_z-pNC"
};
module.exports = exports;
