import React, {useState, useEffect, useCallback} from 'react';
import { previewable } from 'components/Previewer';
import request from 'utils/request';
import { Link } from 'react-router-dom';
import store from 'stores/user';
import {Modal, Pagination, Radio, Table, Row, Col, Icon, Button} from 'antd';
import styles from './points.scss';
import width from 'ui/TableCell/width';
import ShowVideo from "./ShowVideo";

const punishedStatus = ['', '现金处罚', '维护现场', '暂不处罚', '其他'];
const baseLabel = ['', '线下排查', '手动上传'];
const baseConsumType = ['待排查', '非法点位', '合法点位'];

function getConsumeDetails(id, point_type, car_data_type, checkParams){
  const [data, setData] = useState({
    base_info: {},
    car_info: {
      total: 0,
      data: [],
      counts: [
        { number: 0, count_volumn: 0 },
        { number: 0, count_volumn: 0 },
        { number: 0, count_volumn: 0 },
        { number: 0, count_volumn: 0 }
      ]
    },
    result_info: { history_count: [0, 0] },
    materiels: [],
  });
  useEffect(() => {
    request('/web/stationary_source/getConsumeDetails', {id, point_type, car_data_type, ...checkParams})
      .then(body => body.data.data)
      .then(setData)
  }, [id, car_data_type, JSON.stringify(checkParams)]);
  return data;
}

function SuspiciousPoints({history, match, preview}) {
  const { info: { area_name} } = store.getState();
  const [filterFlag, setFilterFlag] = useState('0');
  const [videoSrc, setVideoSrc] = useState();
  const [checkParams, setCheckParams] = useState({ limit: 10, page: 1 });
  const { params: { id, point_type } } = match;
  const consumData = getConsumeDetails(id, point_type, filterFlag, checkParams);
  const columns = [
    { title: '起点', dataIndex: 'start_name' },
    { title: '终点', dataIndex: 'end_name' },
    { title: '运企', dataIndex: 'transport_name' },
    { title: '所属区域', dataIndex: 'area_name', width: width(5) },
    { title: '车牌号', dataIndex: 'car_num', width: width(7) },
    { title: '方量(m³)', dataIndex: 'volumn', width: width(5) },
  ];
  const materielsColumns = [
    { title: '物料名称', dataIndex: 'material_name' },
    { title: '预估方量', dataIndex: 'volume' },
  ];
  const back = () => {
    history.goBack();
  };

  return (
    <>
      <Modal title={`点位详情( (${baseLabel[consumData.base_info.label]})${consumData.base_info.name})`} visible={true} onCancel={back} bodyStyle={{maxHeight: '90vh', overflow: 'auto'}} width={1200} centered={true} footer={null} destroyOnClose={true}>
        <div className={styles.pointTitle}>
          <span>定位地址：{consumData.base_info.address}</span>
          <span>发现时间：{consumData.base_info.create_time}</span>
          <span>所属区域：{consumData.base_info.area_name}</span>
          <span>卫星地址：{
            consumData.base_info.lat && consumData.base_info.lng ?
              <Link to={{pathname: `./satellite-map`, state: {lat: consumData.base_info.lat, lng: consumData.base_info.lng, name: consumData.base_info.name, types: 'satellite'}}}>查看位置</Link> :
              <span>暂无位置</span>
          }</span>
          <span>卫星截图：<span style={{cursor: 'pointer', color: '#1491FB'}} onClick={() => preview.image(consumData.base_info.plan)}>查看点位</span></span>
        </div>
        <div className={styles.pointFilter}>
          <span>疑似车辆</span>
          <div className={styles.filterP}>
            <Radio.Group value={filterFlag} buttonStyle="solid" style={{marginRight: 15}} onChange={(e) => {setFilterFlag(e.target.value)}}>
              <Radio.Button value="0">全部</Radio.Button>
              <Radio.Button value="1">{area_name}内{consumData.car_info.counts[1].number}</Radio.Button>
              <Radio.Button value="2">入{area_name}{consumData.car_info.counts[2].number}</Radio.Button>
              <Radio.Button value="3">出{area_name}{consumData.car_info.counts[3].number}</Radio.Button>
            </Radio.Group>
          </div>
          <div>{}</div>
        </div>
        <div><span>合计: </span><span>方量: {consumData.car_info.counts[filterFlag].count_volumn}</span><span style={{marginLeft: 10}}>趟数: {consumData.car_info.counts[filterFlag].number}</span></div>
        <div id={'div1'}>
          <Table bordered size="middle" rowKey='id' className={styles.table} columns={columns} dataSource={consumData.car_info.data} pagination={false} />
          {/*<Table columns={columns} dataSource={consumData.car_info.data} scroll={{y: 200}} bordered pagination={false} />*/}
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Pagination size="small" total={consumData.car_info.total} onChange={(page) => setCheckParams({...checkParams, page: page})} />
        </div>
        <div className={styles.title}>
          <span>排查结果{ consumData.base_info.consum_type === 0 && <span style={{color: '#F59A23'}}>（待上传）</span> }</span>
          <div>
            <span className={styles.history}>历史排查</span>
            <span className={styles.line}>{}</span>
            <span>线下排查：</span>
            <span style={{marginRight: 20}}>{Object.keys(consumData.result_info).length > 0 ? consumData.result_info.history_count[0] : 0}</span>
            <span>执法排查：</span>
            <span style={{marginRight: 20}}>{Object.keys(consumData.result_info).length > 0 ? consumData.result_info.history_count[1] : 0}</span>
            <Link to={`./history-list/`}><Button size="small" type={'primary'}>查看历史</Button></Link>
          </div>
        </div>
        <Row className={styles.row}>
          <Col span={2} className={styles.colRight}>点位定性</Col>
          <Col span={4} className={styles.colRight}>{ baseConsumType[consumData.base_info.consum_type] }</Col>
          <Col span={2} className={styles.colRight}>地址信息</Col>
          <Col span={14}>{consumData.result_info.address}</Col>
        </Row>
        <Row className={styles.row}>
          <Col span={2} className={styles.colRight}>点位特性</Col>
          <Col span={4} className={styles.colRight}>{ consumData.base_info.point_type_name }</Col>
          <Col span={2} className={styles.colRight}>其他信息</Col>
          <Col span={14}>{consumData.base_info.other_info}</Col>
        </Row>
        <Row className={styles.row}>
          <Col span={2} className={styles.colRight}>结案时间</Col>
          <Col span={14}>{consumData.result_info.created_time}</Col>
        </Row>
        {
          consumData.result_info.history_count && consumData.result_info.history_count[1] > 0 &&
            <>
              <Row className={styles.row}>
                <Col span={2} className={styles.colRight}>处罚结果</Col>
                {/*1现金处罚2维护现场3暂不处罚4其他*/}
                <Col span={4} className={styles.colRight}>{ punishedStatus[consumData.result_info.punished_status] }</Col>
                <Col span={2} className={styles.colRight}>描述</Col>
                <Col span={14}>{consumData.result_info.desc}</Col>
              </Row>
              <Row className={styles.row} style={{height: 60}}>
                <Col span={2} className={styles.colRight}>处罚单位</Col>
                {/*1现金处罚2维护现场3暂不处罚4其他*/}
                <Col span={4} className={styles.colRight}>{ consumData.result_info.punished_department }</Col>
                <Col span={2} className={styles.colRight}>处罚单据</Col>
                <Col span={14}>
                  {
                    !consumData.result_info.ticket || consumData.result_info.ticket.length > 3 ?
                      '暂无处罚单据' : <span style={{cursor: 'pointer', color: '#1491FB'}} onClick={() => preview.image(consumData.result_info.ticket)}>查看单据</span>
                  }
                </Col>
              </Row>
            </>
        }
        <Row className={styles.row} style={{height: 60}}>
          <Col span={2} className={styles.colRight}>照片</Col>
          <Col span={14}>
            {
              consumData.base_info.images && consumData.base_info.images !== '' ?
                <span onClick={() => preview.image( consumData.base_info.images)} >{
                  consumData.base_info.images.split(',').map((item, i) => <img key={i} className={styles.imgs} src={item} alt=""/>)
                }</span> :
                <span>暂无照片</span>
            }
          </Col>
        </Row>
        <Row className={styles.row} style={{height: 60}}>
          <Col span={2} className={styles.colRight}>视频</Col>
          <Col span={14}>
            {
              consumData.base_info.videos ?
                consumData.base_info.videos.split(',').map((item, i) => <img key={i} className={styles.imgs} style={{width: 71}} src="/images/radar/live_video.jpg" onClick={() => setVideoSrc(item)} alt="" />) :
                <span>暂无视频</span>
            }
          </Col>
        </Row>
        {
          consumData.base_info.point_type_name === '消纳厂' && (
            <>
              <div className={styles.title}>
                <span>消纳预估</span>
              </div>
              <Table bordered size="middle" rowKey='id' className={styles.table} columns={materielsColumns} dataSource={consumData.materiels} pagination={false} />
            </>
          )
        }
      </Modal>
      { videoSrc && <ShowVideo videoSrc={videoSrc} onClose={ () => setVideoSrc() } /> }
    </>
  )
}

export default previewable(SuspiciousPoints)
