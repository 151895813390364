// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1fbtzJMOIPFql9SUhfZ5Pj .ant-modal-close,\n._1fbtzJMOIPFql9SUhfZ5Pj .ant-modal-footer {\n  display: none;\n}\n._1fbtzJMOIPFql9SUhfZ5Pj .ant-modal-body {\n  padding: 0;\n}\n._1fbtzJMOIPFql9SUhfZ5Pj ._6gFk672OJNqjay2tPPWUx {\n  border-radius: 4px;\n}", ""]);
// Exports
exports.locals = {
	"modal": "_1fbtzJMOIPFql9SUhfZ5Pj",
	"map": "_6gFk672OJNqjay2tPPWUx"
};
module.exports = exports;
