import React from 'react';
import {
  Table,
  Input,
  Button,
  Select,
  Modal,
  message,
  DatePicker,
} from 'antd';
import styles from '../Unfinished/index.scss';
import request from 'utils/request';

import FinishedReport from 'components/Form/finished';

const { RangePicker } = DatePicker;

const pageSize = 10;

const { Option } = Select;

export default class Unfinished extends React.PureComponent {
  state = {
    dataSource: [],
    currentPage: 1,
    keyword: '',
    level: '',
    date: '',
    status: 1,
    form_id: '',

    visible: false,
  };

  columns = [{
    title: '序号',
    dataIndex: 'index',
  }, {
    title: '任务标题',
    dataIndex: 'task_name',
  }, {
    title: '备注',
    dataIndex: 'describe',
  }, {
    title: '级别',
    dataIndex: 'level',
    render: (cell, row) => {
      if (row.level == 1) {
        return '一般';
      } else {
        return '紧急';
      }
    }
  }, {
    title: '填报起止时间',
    dataIndex: 'time',
  }, {
    title: '操作',
    key: 'actions',
    render: (cell, row) => {
      return <Button style={{ marginLeft: '5px' }} size="small" onClick={() => {
        this.formDetails(row.id);
      }}>查看</Button>;
    }
  }];

  componentDidMount() {
    this.getList(this.state.currentPage);
  }

  getList = (currentPage) => {
    request({
      url: '/web/Form/isAsk',
      data: {
        limit: pageSize,
        page: currentPage,
        keyword: this.state.keyword,
        level: this.state.level,
        begin_date: this.state.date[0],
        end_date: this.state.date[1],
      },
    })
      .then(body => body)
      .then(({ data }) => {
        const list = data.data;
        const limit = 10;
        const current_page = parseInt(list.current_page, 10);
        const total = parseInt(list.total, 10);
        const dataSource = list.data.map((row, index) => ({
          key: row.id,
          index: (index + 1) + (current_page - 1) * limit,
          ...row,
        }));
        this.setState({
          currentPage: current_page,
          totalPages: total,
          dataSource: dataSource,
        });
      });

  };

  keywordChange = (e) => {
    this.setState({ keyword: e.target.value });
  };

  selectChange = (val) => {
    this.setState({ level: val });
  };

  dateChange = (date, dateString) => {
    this.setState({
      date: dateString
    });
  };

  searchChange = () => {
    this.state.currentPage = 1;
    this.getList(this.state.currentPage);
  };

  paginationChange = (current_page) => {
    this.setState({
      currentPage: current_page
    });
    this.getList(current_page);
  };

  formDetails = (id) => {
    this.setState({
      form_id: id,
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const { currentPage, totalPages, dataSource, visible, } = this.state;
    return (
      <div className={styles.Form}>
        <div className={styles.topLine}>
          <div className={styles.search}>
            <Input placeholder="关键字" onChange={e => {
              this.keywordChange(event);
            }}/>
          </div>
          <div className={styles.search}>
            <Select showSearch style={{ width: 150 }} placeholder="请选择级别" onChange={this.selectChange.bind(this)}>
              <Option value="">请选择级别</Option>
              <Option value="1">一般</Option>
              <Option value="2">紧急</Option>
            </Select>
          </div>
          <div className={styles.search}>
            <RangePicker showTime format={'YYYY-MM-DD HH:mm:ss'} onChange={this.dateChange}/>
          </div>
          <div className={styles.search}>
            <Button type="primary" onClick={() => {
              this.searchChange();
            }}>搜索</Button>
          </div>
        </div>
        <Table
          bordered
          columns={this.columns}
          dataSource={dataSource}
          pagination={{
            pageSize,
            current: currentPage,
            total: totalPages,
            onChange: this.paginationChange,
            showTotal: () => {
              return `共${totalPages}条`;
            }
          }}
        />

        {
          <Modal
            destroyOnClose={true}
            visible={visible}
            title="查看"
            width={1200}
            footer={[<Button key="back" onClick={this.handleCancel}>关闭</Button>,]}
            onCancel={this.handleCancel}
          >
            <FinishedReport data={{ form_id: this.state.form_id }}/>
          </Modal>
        }

      </div>
    );
  }
}
