import React, {useState, useEffect} from 'react';
import { Select } from 'antd';
import request from 'utils/request';
import styles from '../Query.scss';
export default function RadioSelect({onChange, options, value, area_id, optionValue = false, useRadio, filter, ...props}){
  const [radioBtn, setRadioBtn] = useState([]);
  useEffect(() => {
    if(typeof options === 'string'){
      let data = {area_id, ...filter}
      request(options, data)
        .then(body => setRadioBtn(body.data.data))
    }
  }, [useRadio]);

  function onOptionChange(e) {
    onChange(optionValue ? value : e);
  }
  {/*<Radio.Group onChange={onOptionChange} className={styles.radioBtn} defaultValue={value}>
    {
      radioBtn.map(({param, name, value}, index) => (
        <Radio.Button value={param} key={index}>{name + '  ' + value}</Radio.Button>
      ))
    }
  </Radio.Group>*/}
  return (
    <Select
      defaultValue={value}
      placeholder={'请选择告警类型'}
      onChange={onOptionChange}
      {...props}
    >
      {radioBtn.map(({param, name, value}, index) => (
        <Select.Option key={index} value={param} >{name + '  ' + value}</Select.Option>
      ))}
    </Select>

  )
}
