import React, { useRef, useEffect, useState } from 'react';
import { Button, Table, message } from 'antd';
import styles from './PrintModel.scss';
import ReactToPrint from 'react-to-print';
import request from 'utils/request';

export default function PrintModel({ params }) {
  const paperRef = useRef(null);
  const columns = [
    { align: 'center', title: '联单号', dataIndex: 'number' },
    { align: 'center', title: '车牌号', dataIndex: 'car_num' },
    { align: 'center', title: '车辆类型', dataIndex: 'car_type', render: (v, t) => <span style={{color: v === '非名录' ? '#FF3B30' : v === '非砂石名录' ? '#FE9400' : v === '合同' ? '#4BD863' : '#0079FE'}}>{v}</span> },
    { align: 'center', title: '工地项目', dataIndex: 'building_name' },
    { align: 'center', title: '收货地点', dataIndex: 'end_name' },
    { align: 'center', title: '物料类型', dataIndex: 'materiel_name' },
    { align: 'center', title: '货箱方量', dataIndex: 'car_volumn' },
    { align: 'center', title: '扣方', dataIndex: 'deduction_volume' },
    { align: 'center', title: '实际方量', dataIndex: 'volumn' },
    { align: 'center', title: '扣方原因', dataIndex: 'reason' },
    { align: 'center', title: '签收时间', dataIndex: 'end_time' },
    { align: 'center', title: '收货人', dataIndex: 'acquirer_name' },
    { align: 'center', title: '车数', dataIndex: 'total_price' },
  ];
  const [dataSource, setDataSource] = useState({num1: 0, num2: 0, num3: 0, data: [] });

  useEffect(() => {
    request({
      url: '/web/ticket/getLeftInfo',
      data: { ...params }
    }).then((res) => {
      const data = res.data.data;
      const arr = [];
      const source = data.data.map((v, i) => {
        if (!arr.includes(v.building_name)) {
          arr.push(v.building_name)
        }
        return {
          serial: (i + 1),
          ...v,
        }
      });
      setDataSource({ num1: arr.length, num2: source.length, num3: data.total_volume, data: source ? source : []});      
    })
  }, [JSON.stringify(params)]);

  return (
    <React.Fragment>
      <ReactToPrint
        key="print-btn"
        trigger={() => <Button type="danger">打印</Button>}
        content={() => paperRef.current}
      />
      <div className={styles.content}>
        <div className={styles.box}>
          <div className={styles.print} ref={paperRef}>
            <div className={styles.header}>
              <h2 className={styles.title}>成都宏信投建材有限公司</h2>
              <h2 className={styles.title}>砂石入库单</h2>
            </div>
            <div className={styles.summary}>{`共${dataSource.num1}项目，${dataSource.num2}单，${dataSource.num3}方`}</div>
            <Table
              className={styles.table}
              bordered
              size="small"
              rowKey="serial"
              pagination={false}
              dataSource={dataSource.data}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
