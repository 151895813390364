import React, { useState, useEffect } from 'react';
import styles from "../../paths/TransportRadar/TransportRadar/index.scss";
import { Modal, message } from "antd";
import { GetTicketShowTime } from "components/Previewer";
import request from 'utils/request';

const isInArray = (arr, value) => {
  for (var i = 0; i < arr.length; i++) {
    if (value === arr[i]) {
      return true;
    }
  }
  return false;
};

Array.prototype.remove = (val) => {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};
export default function MergeTicket({ticketCarNum, ticketTimes, ticketId, onClose, onSuccessT}){
  const [params, setParams] = useState([ticketId]);

  function selectMoreTicket(ids){
    const id = Number(ids);
    let arr = new Set([...params]);
    if(arr.has(id)){
      arr.delete(id)
    }else{
      arr.add(id)
    }
    setParams([...arr])
  }
  function onSuccess(){
    if(params.length < 2){ message.warn('联单合并至少需要两条联单！', 6) }
    request('/web/ticket/mergeTicket', {id: [...params]})
      .then(body => body.data)
      .then(data => {
        if(data.code === 200){
          message.success(data.msg, 6);
          onSuccessT(data.data.ticketId)
        }else{
          message.warn(data.msg)
        }
      })
  }
  return (
    <Modal
      title={'合并联单'}
      width={800}
      bodyStyle={{ maxHeight: '60vh', overflow: 'auto', padding: 0 }}
      destroyOnClose={true}
      visible={true}
      onCancel={() => onClose()}
      onOk={onSuccess}
    >
      <div className={styles.ticketTimes} style={{ width: '100%' }}>
        <div className={styles.ticketTitle}>
          <div>
            <span>{ticketCarNum}</span>
          </div>
          <span>{`共${ticketTimes.length}趟`}</span>
        </div>
        <div className={styles.ticketContent} style={{justifyContent: 'flex-start'}}>
          {
            ticketTimes.map(({start_time, end_time, id}, i) => (
              <div key={i} onClick={() => selectMoreTicket(id, start_time, end_time)} style={{ marginLeft: 10 }} className={ isInArray(params, id) ? styles.active : '' }>{`${GetTicketShowTime(start_time)} - ${GetTicketShowTime(end_time)}`}</div>
            ))
          }
        </div>
      </div>
    </Modal>
  )
}
