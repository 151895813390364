import axios from 'axios';

export default function upload(formData) {
  return axios({
    // eslint-disable-next-line no-undef
    url: URLS.FILE,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}
