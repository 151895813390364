export const reasonArr = [
  { value: 0, label: '' },
  { value: 1, label: '违反信号灯' },
  { value: 2, label: '逆向行驶' },
  { value: 3, label: '连续1周GPS在线率低于90%' },
  { value: 4, label: '连续1周GPS超速超过5次' },
  { value: 5, label: '温江区沙石、商砼及扬尘信息化监管平台认证信息不全' },
  { value: 6, label: '采用黑名单驾驶员' },
  { value: 7, label: '吸毒驾车' },
  { value: 8, label: '饮酒驾驶' },
  { value: 9, label: '车辆智能车载监控设备连续1周在线率低于90%' },
  { value: 10, label: '连续1周摄像头被发现2次' },
  { value: 11, label: '连续1周不按规定行驶被发现2次' },
];

export const punishArr = [
  { value: 1, label: '停运' },
  { value: 2, label: '其他' },
];

export const isDirectory = [
  { value: 1, label: '是' },
  { value: 2, label: '否' },
];

export const statusArr = [
  { value: 1, label: '待运企确认' },
  { value: 2, label: '进入灰名单' },
  { value: 3, label: '待坐席审核' },
  { value: 4, label: '申诉未通过-进入灰名单' },
  { value: 5, label: '进入黑名单' },
  { value: 6, label: '申诉未通过-进入黑名单' },
];

export const transportStatus = [
  { value: 1, label: '运企核实' },
  { value: 2, label: '坐席审核' },
  { value: 3, label: '上报成功' },
]
export const typeArr = [
  '',
  '违反信号灯',
  '逆向行驶',
  '连续1周GPS在线率低于90%',
  '连续1周GPS超速超过5次',
  '温江区沙石、商砼及扬尘信息化监管平台认证信息不全',
  '采用黑名单驾驶员',
  '吸毒驾车',
  '饮酒驾驶',
  '车辆智能车载监控设备连续1周在线率低于90%',
  '连续1周摄像头被发现2次',
  '连续1周不按规定行驶被发现2次',
];

export const NodeName = ['', '进入灰名单', '灰名单延期', '退出灰名单', '进入黑名单', '黑名单延期', '退出黑名单'];

export const NodeTime = ['', '进入时间', '延期时间', '退出时间', '进入时间', '延期时间', '退出时间'];

export const NodeColor = ['', '#F59A23', '#262626', '#49D966', '#F59A23', '#262626', '#49D966'];

export function ShowFile(item){
  window.open(item)
}
