import React, {useState, useEffect} from 'react';
import request from 'utils/request';
import { Modal } from 'antd';
import classNames from 'classnames';
import styles from './Details.scss';

const reasonsArr = [
  '',
  '违反信号灯',
  '逆向行驶',
  '连续1周GPS在线率低于90%',
  '连续1周GPS超速超过5次',
  '温江区沙石、商砼及扬尘信息化监管平台认证信息不全',
  '采用黑名单驾驶员',
  '吸毒驾车',
  '饮酒驾驶',
  '车辆智能车载监控设备连续1周在线率低于90%',
  '连续1周摄像头被发现2次',
  '连续1周不按规定行驶被发现2次',
];
export default function Details({history, match}) {
  const back = () => history.goBack();
  const list_id = match.params.id;
  const list_type = match.params.types;
  const [base, setBase] = useState({});
  const [flow, setFlow] = useState([]);
  useEffect(() => {
    request('/web/blacklist/getDetails', {list_id, list_type})
      .then(res => {
        const data = res.data.data;
        setBase(data.base_info);
        setFlow(data.flows);
      });
  }, []);

  return (
    <Modal
      title="详情"
      visible={true}
      centered={true}
      width={380}
      bodyStyle={{maxHeight: '70vh', overflowY: 'auto',}}
      footer={null}
      onCancel={back}
    >
      <div>
        <div className={styles.base}>
          {
            base.id_card ?
              <div>
                <span>驾驶员：{base.driver_name}</span>
                <span>身份证号：{base.id_card}</span>
              </div> :
              <div>
                <span>车牌号：{base.car_num}</span>
                <span>关联驾驶员：{base.driver_name}</span>
              </div>
          }

          <div>所属运企：{base.area_name}</div>
          <div>所属区域：{base.transport_name}</div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          {
            flow.map((item, i) => (
              <div key={i} style={{display: 'flex', padding: '0 25px', fontSize: 12}}>
                <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  {i !== 0 ? <div className={styles.crossLine}> </div> : <div className={classNames(styles.crossLine, styles.opacity)}> </div>}
                  <div className={styles.number}>{flow.length - i}</div>
                  {i !== flow.length - 1 ?
                    <div className={styles.crossLine} style={{display: 'flex', flexGrow: 1}}> </div> :
                    <div className={classNames(styles.crossLine, styles.opacity)} style={{display: 'flex', flexGrow: 1}}> </div>
                  }
                </div>
                <div>
                  <div className={styles.triangleLeft}> </div>
                </div>
                <div className={styles.row}>
                  {
                    item.type === 1 ? <div className={styles.itemTop} style={{backgroundColor: '#F59A23'}}>进入灰名单</div> : <div className={styles.itemTop} style={{backgroundColor: '#D9001B'}}>进入黑名单</div>
                  }
                  <div className={styles.itemBottom}>
                    <div className={styles.itemLeft}>创建时间</div>
                    <div style={{flexGrow: 1}}> </div>
                    <div style={{color: '#333'}}>{item.created_time}</div>
                  </div>
                  <div className={styles.itemBottom}>
                    <div className={styles.itemLeft}>事由</div>
                    <div style={{flexGrow: 1}}> </div>
                    <div style={{color: '#333'}}>{reasonsArr[item.reason]}</div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </Modal>
  )
}
