import React, {useState, useEffect} from 'react';
import {Modal} from 'antd';
import request from "utils/request";
import styles from '../index.scss';
import LinkText from 'ui/TableCell/LinkText';
const getCarNum = (id, type) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    request('/web/report/pShowCarList', {relation_report_id: id, relation_report_type: type})
      .then(body => body.data.data)
      .then(data => {const arr = data.split(','); setData(arr)})
  }, [id]);
  return data
};
export default function ShowCars({history, match}) {
  const {params: {id, type}} = match;
  const carNumList = getCarNum(id, type);
  return (
    <Modal
      title={'查看车辆'}
      onCancel={() => history.goBack()}
      visible={true}
      centered={true}
      bodyStyle={{maxHeight: '80vh', overflow: 'auto'}}
      footer={null}
      destroyOnClose={true}
     >
      <div className={styles.carNumbers}>
        {
          carNumList.map((v, i) => (
            <span key={i}><LinkText to={`./${v}/show-car-detail`}>{v}</LinkText></span>
          ))
        }
      </div>
    </Modal>
  )
}
