import React, {useState, useEffect} from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import store from 'stores/user';
import request from 'utils/request';
import {message} from 'antd';
import {dataSource} from './List';
const inputs = [
  { label: '标题', placeholder: '请输入', key: 'title', required: true },
  { label: '车牌号', placeholder: '请选择报备车辆', key: 'car_list', options: '/web/keep_on_record/tmpCarList',optionFilterProp: 'car_num', valueKey: 'car_num', showSearch: 'car_num', labelKey: 'car_num', required: true, show_type: 'string-to-array', type: 'select', mode: 'multiple' },
  { label: '报备要件', key: 'files', type: 'upload-dragger', showMsg: '1、运企报备申请（公司签章）；2、车辆行驶证照片（以上文件未全部上传，审核将不会通过）', required: true },
  { label: '备注', placeholder: '请输入备注', key: 'remark', type: 'textarea', autosize: { minRows: 4, maxRows: 10 },},
];
export default function ReplayListReporting({ history, match }) {
  const back = () => history.goBack();
  const {params: {id}} = match;
  function onFinish(data){
    const transport_id = store.getState().info.user_instance[0].instance_id;
    const area_id = store.getState().info.area_id;
    const json = {id: id, ...data, car_list: Array.isArray(data.car_list) ? data.car_list.join(',') : data.car_list,
      car_count: Array.isArray(data.car_list) ? data.car_list.length : data.car_list.split(',').length, transport_id: transport_id, area_id: area_id};
    request('/web/report/createTempReport', json)
      .then(body => {
        if(body.data.code !== 200){
          message.warn(body.data.msg)
        }else{
          message.success(body.data.msg);
          dataSource.refresh();
          history.goBack();
        }
      })
  }
  return (
    <ModalFormPage
      title="名录报备"
      init={['/web/report/againTempReport', { car_temp_report_id: id }]}
      inputs={inputs}
      onCancel={back}
      onFinish={ onFinish }
      modalProps={{
        width: 800,
      }}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}

    />
  );
}
