import React from 'react';
import common from 'utils/common'
import {Form, Row, Col, Input, Button, Icon, Collapse, Modal, Select, DatePicker} from "antd";
import ShowImages from "components/PopoverPage/ShowImages";
const Panel = Collapse.Panel;
const Option = Select.Option;
import moment from 'moment';
const dateFormat = 'YYYY-MM-DD';
export default class TransPortCarShowMsg extends React.PureComponent{
  static getDerivedStateFromProps(nextProps, presState){
    return {
      data: nextProps.data.data,
      flag: true,
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      data: props.data.data,
      flag: true,
      activeKey: [],
      dataImages: [],
      img_flag: false,
    }
  }
  showImages = (val) => {
    let images = [];
    this.setState({
      dataImages: []
    });
    if(val.indexOf(',') > -1){
      images = val.split(',');
    }else {
      images.push(val)
    }
    this.setState({
      dataImages: images,
      img_flag: true
    });
  };
  getFields = () => {
    const children = [];
    const state = {...this.state};
    state.data.base.map((v, i) => {
      if(!v.hide){
        if(v.extra){
          if(v.value != '' && v.value != null){
            let arr = [];
            let showImgs = [];
            if(v.value.indexOf(',') > -1){
              arr = v.value.split(',')
            }else{
              arr.push(v.value)
            }
            arr.map((v) => {
              if(v.indexOf('http') > -1){
                showImgs.push(v)
              }else{
                showImgs.push(common.pic_url + v)
              }
            });
            children.push(
              <Col span={8} key={i}>
                <Form.Item label={v.label}>
                  {
                    showImgs.map((v, j) => {
                      return <img onClick={ () => { this.showImages(v) } } style={{marginRight: 5, width: 40, cursor: 'pointer'}} src={v} key={j} alt=""/>
                    })
                  }
                </Form.Item>
              </Col>
            );
          }
          else{
            children.push(
              <Col span={8} key={i}>
                <Form.Item label={v.label}>
                  <span>暂无图片</span>
                </Form.Item>
              </Col>
            )
          }
        }
        else if(v.select){
          children.push(
            <Col span={8} key={i}>
              <Form.Item label={v.label}>
                <Select style={{width: '100%'}} value={v.value} disabled={state.flag}>
                  {
                    v.select.map((item, j) => {
                      return (
                        <Option key={j} value={item.value} title={i} >{item.label}</Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </Col>
          );
        }
        else if(v.date){
          children.push(
            <Col span={8} key={i}>
              <Form.Item label={v.label}>
                <DatePicker style={{width: '100%'}} defaultValue={v.value ? moment(v.value, dateFormat) : ''} format={dateFormat} disabled={state.flag} />
              </Form.Item>
            </Col>
          );
        }
        else{
          children.push(
            <Col span={8} key={i}>
              <Form.Item label={v.label}>
                <Input placeholder={v.value} value={v.value} disabled={state.flag} />
              </Form.Item>
            </Col>
          );
        }
      }
    });
    return children;
  };

  getDriverFields = () => {
    const children = [];
    const state = {...this.state};
    state.data.driver.map((v, i) => {
      children.push(
        <Panel header={'司机: ' + state.data.driver[i][1].value} key={i}>
          {
            v.map((item, j) => {
              if(!v.hide){
                if(item.extra){
                  if(item.value != '' && item.value != null){
                    let arr = [];
                    let showImgs = [];
                    if(item.value.indexOf(',') > -1){
                      arr = item.value.split(',')
                    }else{
                      arr.push(item.value)
                    }
                    arr.map((v) => {
                      if(v.indexOf('http') > -1){
                        showImgs.push(v)
                      }else{
                        showImgs.push(common.pic_url + v)
                      }
                    });
                    return (
                      <Col span={8} key={j}>
                        <Form.Item label={item.label}>
                          {
                            showImgs.map((s, x) => {
                              return <img onClick={ () => { this.showImages(s) } } style={{marginRight: 5, width: 40, cursor: 'pointer'}} src={s} key={x} alt=""/>
                            })
                          }
                        </Form.Item>
                      </Col>
                    );
                  }
                  else{
                    return (
                      <Col span={8} key={j}>
                        <Form.Item label={item.label}>
                          <span>暂无图片</span>
                        </Form.Item>
                      </Col>
                    )
                  }
                }
                else if(v.select) {
                  return (
                    <Col span={8} key={i}>
                      <Form.Item label={v.label}>
                        <Select style={{width: '100%'}} value={v.value} onChange={this.onSelectChange} disabled={v.disabled}>
                          {
                            v.select.map((item, j) => {
                              return (
                                <Option key={j} value={item.value} title={i}>{item.label}</Option>
                              )
                            })
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                  );
                }
                else if(v.date){
                  return (
                    <Col span={8} key={i}>
                      <Form.Item label={v.label}>
                        <DatePicker style={{width: '100%'}} onChange={ this.onDateChange } onOpenChange={() => {this.dateFocus(i)}} />
                      </Form.Item>
                    </Col>
                  );
                }
                else{
                  return <Col span={8} key={j}>
                    <Form.Item label={item.label}>
                      <Input placeholder={item.value} value={item.value} disabled={state.flag} />
                    </Form.Item>
                  </Col>
                }
              }
            })
          }
        </Panel>
      )
    });
    return children ;
  }
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
    });
  }

  handleReset = () => {
    this.props.form.resetFields();
  }
  HiddenImages = () => {
    this.setState({
      img_flag: false
    })
  };
  callback = () => {

  }
  render () {
    const state = {...this.state};
    return (
      <div>
        <Form
          className="ant-advanced-search-form"
          onSubmit={this.handleSearch}
        >
          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="车辆基础信息" key="1">
              <Row gutter={24}>{this.getFields()}</Row>
            </Panel>
            <Panel header="司机信息" key="2">
              <Row gutter={24}>
                <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                  {this.getDriverFields()}
                </Collapse>
              </Row>
            </Panel>
          </Collapse>
        </Form>
        {
          state.img_flag ? <ShowImages data={{ data: state.dataImages, width: 480}} callback={this.HiddenImages.bind(this)} /> : null
        }
      </div>
    )
  }
}
TransPortCarShowMsg = Form.create({})(TransPortCarShowMsg);
