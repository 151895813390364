import React from 'react';
import { Route, Link } from 'react-router-dom';
import Table, { RangePicker } from 'ui/Table';
import LocationPicker from 'components/Map/LocationPicker';

const List = () => (
  <Table
    url="/web/Instance/showConcreteList"
    inputs={[
      { key: 'keyword', placeholder: '关键字' },
      // { key: 'time', placeholder: ['开始日期', '结束日期'], ...RangePicker() },
    ]}
    columns={[
      { title: '序号', dataIndex: 'index', width: 80 },
      { title: '商砼名称', dataIndex: 'name' },
      { title: '商砼地址', dataIndex: 'address', render: (address, { id }) => <Link to={`./${id}/map`}>{address}</Link> },
      { title: '占地面积', dataIndex: 'area' },
      // { title: '施工阶段', dataIndex: 'open_status', render: status => ['停工', '开工'][status] },
      { title: '负责人', dataIndex: 'principal' },
      { title: '负责人电话', dataIndex: 'principal_tel' },
    ]}
  />
);

export default ({ path }) => (
  <>
    <Route path={`${path}`} component={List} />
    <Route path={`${path}:id/map`} component={LocationPicker('/web/Instance/getConcreteCoordinateById', '/web/Instance/saveConcreteCoordinate')} />
  </>
);
