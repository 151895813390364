/*/!* eslint-disable no-shadow *!/
import React, { useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import immutable from 'immutable';
import debug from 'utils/debug';
import request from 'utils/request';


export default function Selector({
  value: originalValue,
  options: originalOptions,
  valueKey = 'value',
  labelKey = 'label',
  optionValue = false,
  realTimeSearch,
  placeholder,
  onChange,
  showSearch,
  disabled,
  ...props
}) {
  const [options, setOptions] = useState(immutable.OrderedMap());
  function onOptionChange(value) {
    onChange(optionValue ? options.get(value) : value);
  }

  useEffect(() => {
    debug.effect('Selector');
    if (!originalOptions) return;
    if (Array.isArray(originalOptions)) {
      setOptions(immutable.OrderedMap(originalOptions.map((opt) => {
        const option = typeof opt === 'string' ? { [valueKey]: opt, [labelKey]: opt } : opt;
        return [option[valueKey], option];
      })));
    } else if (!disabled && !realTimeSearch) {
      let url = '';
      let data = {};
      if (typeof originalOptions === 'string') {
        url = originalOptions;
      } else if (typeof originalOptions === 'object') {
        onOptionChange();
        url = originalOptions.url;
        data = originalOptions.data;
      }
      request(url, data)
        .then(body => body.data.data)
        .then(data => Array.isArray(data) ? data.map(option => ([option[valueKey], option])) : [])
        .then(pairs => setOptions(immutable.OrderedMap(pairs)));
    }
  }, [JSON.stringify(originalOptions)]);

  // 实时搜索延时
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    if (!realTimeSearch) return;
    let url = '';
    let data = {};
    if (typeof originalOptions === 'string') {
      url = originalOptions;
    } else if (typeof originalOptions === 'object') {
      url = originalOptions.url;
      data = originalOptions.data;
    }
    setIsSearching(true);
    setOptions(immutable.OrderedMap());
    const timer = setTimeout(() => {
      request(url, { ...data, [realTimeSearch]: searchValue })
        .then(body => body.data.data)
        .then(data => Array.isArray(data) ? data.map(option => ([option[valueKey], option])) : [])
        .then(pairs => setOptions(immutable.OrderedMap(pairs)))
        .then(() => setIsSearching(false));
    }, 500);
    return () => clearTimeout(timer);
  }, [JSON.stringify(originalOptions), searchValue]);

  const searchProps = (() => {
    if (!realTimeSearch) {
      // 本地搜索，默认搜索标签
      return {
        showSearch,
        optionFilterProp: showSearch && 'optionProp',
        // filterOption: showSearch && ((input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0),
      };
    } else {
      // 实时搜索
      return {
        showSearch: true,
        filterOption: false,
        optionFilterProp: 'optionProp',
        onSearch: setSearchValue,
        notFoundContent: isSearching ? <Spin size="small" /> : null,
      };
    }
  })();
console.log({...searchProps})
  const value = optionValue ? (originalValue && originalValue[valueKey]) : originalValue;
  return (
    <Select
      {...searchProps}
      optionFilterProp={'optionProp'}
      {...props}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={onOptionChange}
    >
      {options.valueSeq().map(option => (
        option.pinyin ? <Select.Option key={option[valueKey]} value={option[valueKey]} optionProp={option[labelKey] + option.pinyin} className={option.acquirer ? styles.optionsClass : ''}>{option[labelKey]}</Select.Option> :
        <Select.Option key={option[valueKey]} value={option[valueKey]} optionProp={option[labelKey]}>{option[labelKey]}</Select.Option>)
      )}
    </Select>
  );
}*/

/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import immutable from 'immutable';
import debug from 'utils/debug';
import request from 'utils/request';
import styles from './SelectorNew.scss';
export default function Selector({
                                   value: originalValue,
                                   options: originalOptions,
                                   valueKey = 'value',
                                   labelKey = 'label',
                                   optionValue = false,
                                   realTimeSearch,
                                   placeholder,
                                   onChange,
                                   showSearch,
                                   disabled,
                                   ...props
                                 }) {
  const [options, setOptions] = useState(immutable.OrderedMap());

  function onOptionChange(value) {
    onChange(optionValue ? options.get(value) : value);
  }

  useEffect(() => {
    debug.effect('Selector');
    if (!originalOptions) return;
    if (Array.isArray(originalOptions)) {
      setOptions(immutable.OrderedMap(originalOptions.map((opt) => {
        const option = typeof opt === 'string' ? { [valueKey]: opt, [labelKey]: opt } : opt;
        return [option[valueKey], option];
      })));
    } else if (!disabled && !realTimeSearch) {
      let url = '';
      let data = {};
      if (typeof originalOptions === 'string') {
        url = originalOptions;
      } else if (typeof originalOptions === 'object') {
        // onOptionChange();
        url = originalOptions.url;
        data = originalOptions.data;
      }
      request(url, data)
        .then(body => body.data.data)
        .then(data => Array.isArray(data) ? data.map(option => ([option[valueKey], option])) : [])
        .then(pairs => setOptions(immutable.OrderedMap(pairs)));
    }
  }, [JSON.stringify(originalOptions)]);

  // 实时搜索延时
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    if (!realTimeSearch) return;
    let url = '';
    let data = {};
    if (typeof originalOptions === 'string') {
      url = originalOptions;
    } else if (typeof originalOptions === 'object') {
      url = originalOptions.url;
      data = originalOptions.data;
    }
    setIsSearching(true);
    setOptions(immutable.OrderedMap());
    const timer = setTimeout(() => {
      request(url, { ...data, [realTimeSearch]: searchValue })
        .then(body => body.data.data)
        .then(data => Array.isArray(data) ? data.map(option => ([option[valueKey], option])) : [])
        .then(pairs => setOptions(immutable.OrderedMap(pairs)))
        .then(() => setIsSearching(false));
    }, 500);
    return () => clearTimeout(timer);
  }, [JSON.stringify(originalOptions), searchValue]);

  const searchProps = (() => {
    if (!realTimeSearch) {
      // 本地搜索，默认搜索标签
      return {
        showSearch,
        optionFilterProp: showSearch && 'optionProp',
        // filterOption: showSearch && ((input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0),
      };
    } else {
      // 实时搜索
      return {
        showSearch: true,
        filterOption: false,
        optionFilterProp: 'optionProp',
        onSearch: setSearchValue,
        notFoundContent: isSearching ? <Spin size="small" /> : null,
      };
    }
  })();

  const value = optionValue ? (originalValue && originalValue[valueKey]) : originalValue;

  return (
    <Select
      {...searchProps}
      {...props}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={onOptionChange}
    >
      {options.valueSeq().map(option => (
        option.pinyin ? <Select.Option key={option[valueKey]} value={option[valueKey]} optionProp={option[labelKey] + option.pinyin} className={option.acquirer ? styles.optionsClass : ''}>{option[labelKey]}</Select.Option> :
          <Select.Option key={option[valueKey]} value={option[valueKey]} optionProp={option[labelKey]}>{option[labelKey]}</Select.Option>)
      )}
    </Select>
  );
}


