import React, { useState, useEffect } from 'react';
import { previewable } from 'components/Previewer';

import BaseSection from './Base/Section';
import { SecuritiesSection } from './Securities';
import { DocersSection } from './Docers';

import dataSource from './dataSource';

function Info({ preview }) {
  const [data, setData] = useState({});

  useEffect(() => {
    const id = dataSource.subscribe(setData);
    dataSource.query();
    return () => dataSource.unsubscribe(id);
  }, []);

  const { base = {}, security = [], docer = [] } = data;

  return (
    <div>
      <BaseSection data={base} preview={preview} />
      <SecuritiesSection data={security} />
      <DocersSection data={docer} />
    </div>
  );
}

export default previewable(Info);
