import React, {useState} from 'react';
import {Button, Modal, DatePicker, Icon, Radio} from 'antd';
import moment from "moment";
import { open } from 'utils/request';
const {MonthPicker} = DatePicker;

export default function DownloadReport({match, history}){
  const {params: {type}} = match;
  const back = () => history.goBack();
  const defaultDates = moment().subtract(0, 'day').startOf('day');
  const [year, setYear] = useState(defaultDates.format('YYYY'));
  const [month, setMonth] = useState(defaultDates.format('MM'));
  const [week, setWeek] = useState('1');
  return <Modal
  width={600}
  title={`导出${type === '1' ? '周' : '月'}报`}
  visible={true}
  centered={true}
  footer={[
    <Button key="back" onClick={back}>
      关闭
    </Button>,
    <Button style={{width: 80}} onClick={() => open('/web/ticket/MonthExcel', {year, month, week: type === '1' ? week : ''})}><Icon type="download" />{'导出'}</Button>,
  ]}
  onCancel={back}
  destroyOnClose={true}
  bodyStyle = {{
    maxHeight: '70vh',
    overflowY: 'auto', position: 'relative'}
  }
  >
    <div>
      <MonthPicker placeholder="请选择月份" defaultValue={defaultDates} onChange={(e, s) => {setYear(e.format('YYYY')); setMonth(e.format("MM"))} } />
      {
        type === '1' ?
          <div style={{display: 'flex', marginTop: 15, alignItems: 'center' }}>
            <span style={{marginRight: 10}}>选择导出的周: </span>
            <Radio.Group defaultValue={week} buttonStyle="solid" onChange={(e) => setWeek(e.target.value)}>
              <Radio.Button value="1">第1周</Radio.Button>
              <Radio.Button value="2">第2周</Radio.Button>
              <Radio.Button value="3">第3周</Radio.Button>
              <Radio.Button value="4">第4周</Radio.Button>
              <Radio.Button value="5">第5周</Radio.Button>
            </Radio.Group>
          </div> : null
      }
    </div>
  </Modal>
}
