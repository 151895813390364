import request from 'utils/request';

const dataSource = {
  listeners: [],
  subscribe(listener) {
    this.listeners.push(listener);
    return this.listeners.length - 1;
  },
  unsubscribe(index) {
    this.listeners.splice(index, 1);
  },
  query() {
    return request('/web/Concrete/getInfo')
      .then(body => body.data.data)
      .then((data) => {
        this.listeners.forEach((listener) => {
          listener(data);
        });
      });
  },
  refresh() {
    this.query();
  },
};

export default dataSource;
