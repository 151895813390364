import React, { useMemo } from 'react';

const styles = {
  normal: {
    fontWeight: 'bold',
  },
  primary: {
    backgroundColor: 'yellow',
    fontWeight: 'bold',
  },
};
export default function Highlight({ source, search }) {
  const children = useMemo(() => {
    const matches = [...source.matchAll(search.toUpperCase())].map(({ index }) => [index, index + search.length]);
    if (matches.length === 0) return source;

    const segments = [[0,0], ...matches, [source.length, source.length]];

    const items = [];
    for (let i = 1; i < segments.length; i += 1) {
      const lastEndIndex = segments[i - 1][1];
      const [thisStartIndex, thisEndIndex] = segments[i];
      const defaultPart = source.slice(lastEndIndex, thisStartIndex);
      const primaryPart = source.slice(thisStartIndex, thisEndIndex);
      items.push(
        <React.Fragment key={i}>
          {defaultPart && <span style={styles.normal}>{defaultPart}</span>}
          {primaryPart && <span style={styles.primary}>{primaryPart}</span>}
        </React.Fragment>
      );
    }

    return items;
  }, [source, search]);

  return (
    <span style={styles.normal}>
      {children}
    </span>
  );
}
