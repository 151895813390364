import React from 'react';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';

import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';

import debug from 'utils/debug';

const dataSource = new DataSource('/web/transport_car/showAccidentList');

function Accidents() {
  debug.render('Accidents');

  const columns = [
    serial(true),
    { title: '车牌号', key: 'car_number', width: width(7), fixed: 'left' },
    { title: '事故时间', key: 'start_time', width: width(0, 20) },
    { title: '事故地点', key: 'acci_address' },
    { title: '驾驶员', key: 'acci_driver', width: width(5) },
    { title: '车型', key: 'car_type', width: width(3), align: 'center' },
    { title: '死亡人数', key: 'death_num', width: width(5) },
    { title: '受伤人数', key: 'injury_num', width: width(5) },
    { title: '责任认定', key: 'acci_type' },
    { title: '确认时间', key: 'end_time', width: width(0, 20) },
    { title: '车辆所属单位', key: 'transport_name' },
  ];

  const querys = [
    { title: '车牌、地点', key: 'keyword' },
    { title: ['违法', '时间'], key: 'start_time', type: 'date-range', showTime: true },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}

export default Accidents;
