import React, { useState, useEffect } from 'react';
import { Modal, Form, message, Input, Checkbox, Button } from 'antd';
import immutable from 'immutable';
import useImmutable from 'ui/useImmutable';
import Text from 'ui/FormItem/Text';
import TextArea from 'ui/FormItem/TextArea';
import Selector from 'ui/FormItem/Selector';
import Uploader from 'ui/FormItem/Uploader';
import UploaderDragger from 'ui/FormItem/UploaderDragger';
import store from "stores/user";

const getFormProps = (props = {}) => ({
  ...props,
});
export default function AlarmForm({init, extraData, userId, initTo, localModel, submit, title, onCancel, onFinish, modalProps, formProps, inputs: originalInputs, defaultValue}){
  let { get, set, sets, setter, toJS } = useImmutable(immutable.Map(defaultValue));
  const [initialed, setInitialed] = useState(false);
  const roleId = store.getState().roles.id;
  const finalFormProps = getFormProps(formProps);
  const initialing = init && !initialed;
  const inputs = originalInputs.reduce((flatten, input) => {
    if (Array.isArray(input.children)) return [...flatten, ...input.children.map(child => ({ required: input.required, ...child }))];
    return [...flatten, input];
  }, []);

  function renderInput(key, { type, relies, reliesAs, rely, relyAs, extraData = {}, afterChange = (v, sets) => sets([key, v]), ...rest }) {
    const inputProps = { key, allowClear: true };
    switch (type) {
      case 'select': {
        const onChange = value => afterChange(value, sets);
        let disabled = false;
        let { options } = rest;
        const relyKeys = relies || (rely && [rely]);
        const dataKeys = reliesAs || relies || (relyAs && [relyAs]) || (rely && [rely]);
        const {option_type} = rest;
        if (relyKeys) {
          const relyValues = relyKeys.map(key => get(key));
          disabled = relyValues.filter(value => value === undefined).length > 0;
          // 如果依赖重置，自己也重置
          if (disabled && get(key) !== undefined) {
            set(key, undefined);
          }
          if (typeof options === 'string') {
            const data = dataKeys.reduce((final, dataKey, index) => {
              return { ...final, [dataKey]: relyValues[index] };
            }, {});
            options = { url: options, data: { ...extraData, ...data } };
          }
        }
        if(option_type){
          const optionValues = get(option_type);// || [option_type];//relyKeys.map(key => get(key));
          const relyValues = get(option_type);
          options = get(option_type) === 2 ? '/web/keep_on_record/carList' : 'web/keep_on_record/tmpCarList';
        }
        return <Selector {...inputProps} disabled={userId ? true : disabled} {...rest} options={options} value={get(key)} onChange={onChange} />;
      }
      case 'upload': {
        const disabled = rely && !get(rely);
        return <Uploader {...inputProps} {...rest} value={get(key)} onChange={setter(key)} disabled={disabled} />;
      }
      case 'upload-dragger': return <UploaderDragger {...inputProps} {...rest} value={get(key)} onChange={setter(key)} />;
      case 'textarea': return <TextArea key={key} {...rest} value={get(key)} onChange={setter(key)} />;
      default:return <Text {...inputProps} {...rest} value={get(key)} onChange={setter(key)} />;
    }
  }
  const disabled = userId ? false : inputs.find(({ key, required, hide }) => !hide && required && (get(key) === undefined || get(key) === ''));
  function renderItem({ label, key, required, hide, children, showOrHide, ...rest }) {
    if(rest.type === 'radioVolume' && (get(showOrHide) !== 0 &&  get(showOrHide) !== 0.0)){
      hide = false;
    }
    if (hide) return null;
    return (
      <Form.Item key={key || label} label={label} required={required}>
        {!Array.isArray(children) ? renderInput(key, rest) : (
          <Input.Group compact>
            {children.map(({ key, ...rest }) => renderInput(key, rest))}
          </Input.Group>
        )}
      </Form.Item>
    );
  }
  function onSubmit() {
    const data = toJS();
    onFinish(data);
  }
  return (
    <>
      <Form {...finalFormProps}>
        {!initialing && originalInputs.map(renderItem)}
      </Form>
      {
        roleId === 111 || roleId === '111' ?
          <Button type="primary" style={{width: 400, marginTop: 35}} size={'large'} disabled={disabled} onClick={onSubmit} >确认</Button> : null
      }
    </>
  )
}
