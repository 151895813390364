/* eslint-disable no-undef */
import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  message,
} from 'antd';
import md5 from 'blueimp-md5';
import { logOut } from 'actions/user';
import debug from 'utils/debug';
import request from 'utils/request';
import styles from './index.scss';

export default function Password() {
  debug.render('Password');
  const [prev, setPrev] = useState();
  const [next, setNext] = useState();

  function onChangePassword(e) {
    e.preventDefault();
    if (prev && next) {     
      const [num, str] = judgeStrongPassword(next);
      if (num === 12) return message.warning(`不能使用连续字符${str}`);
      if (num === 11) return message.warning(`不能使用连续重复字符${str}`);
      if (num === 10) return message.warning('新密码必须大于等于八位');
      if (num < 3) return manager.warning('新密码必须为强密码！');
      request('/web/User/saveNewPassword', {
        old_password: md5(prev),
        new_password: md5(next),
      }).then(body => body.data).then(({ code, msg }) => {
        if (code !== 200) {
          message.error(msg, 1);
        } else {
          message.success('修改密码成功，请重新登陆', 1);
          logOut();
        }
      });
    }
  }

  return (
    <div className={styles.container}>
      <Form className={styles.form} onSubmit={onChangePassword}>
        <div className={styles.header}>
          <img className={styles.logo} src="/logo.png" alt="" />
          <div className={styles.name}>修改密码</div>
        </div>
        <Form.Item><Input className={styles.input} value={prev} onChange={e => setPrev(e.target.value)} placeholder="旧密码" type="password" /></Form.Item>
        <Form.Item><Input className={styles.input} value={next} onChange={e => setNext(e.target.value)} placeholder="新密码" type="password" /></Form.Item>
        <Form.Item><Button className={styles.button} type="primary" htmlType="submit" onClick={onChangePassword}>确定</Button></Form.Item>
      </Form>
    </div>
  );
}
