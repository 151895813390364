// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FLicTacAB6xjeiVhYBAER {\n  height: 246px;\n}\n.FLicTacAB6xjeiVhYBAER > :nth-child(2) > :first-child {\n  color: #fff;\n}\n.FLicTacAB6xjeiVhYBAER > :nth-child(2) svg {\n  width: 1.5em;\n  height: 1.5em;\n}\n.FLicTacAB6xjeiVhYBAER > :nth-child(2) > :nth-child(2) {\n  height: 40px;\n  background-color: #1890ff !important;\n}\n.FLicTacAB6xjeiVhYBAER > :nth-child(2) > :nth-child(4) {\n  border: initial;\n}\n.FLicTacAB6xjeiVhYBAER > :nth-child(2) > :nth-child(4) > div {\n  width: 327px;\n  height: 50px;\n  margin: 0 auto;\n}\n.FLicTacAB6xjeiVhYBAER > :nth-child(2) > :nth-child(4) > div > button {\n  width: 150px;\n  height: 45px;\n}\n.FLicTacAB6xjeiVhYBAER > :nth-child(2) > :nth-child(4) > div > :nth-child(1) {\n  float: right;\n}\n.FLicTacAB6xjeiVhYBAER > :nth-child(2) > :nth-child(4) > div > :nth-child(2) {\n  float: left;\n  margin-left: auto;\n}\n.FLicTacAB6xjeiVhYBAER input {\n  width: 327px;\n  height: 36px;\n}\n\n._2k0Jq3srzrXNCAssU7XQwn {\n  z-index: 100000000;\n}", ""]);
// Exports
exports.locals = {
	"PlateModal": "FLicTacAB6xjeiVhYBAER",
	"warpAlarm": "_2k0Jq3srzrXNCAssU7XQwn"
};
module.exports = exports;
