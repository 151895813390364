import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import debug from 'utils/debug';
import { dataSource } from './List';
import { message } from 'antd';

export default function UnusualDeal({ history, match }) {
  debug.render('Create');
  const reasonArr = [
    { value: 1, label: '扣车' },
    { value: 2, label: '外调' },
    { value: 3, label: '其它' },
  ]
  const inputs = [
    { label: '异常处理原因', placeholder: '请选择原因', key: 'reason', required: true, type: 'select', options: reasonArr, style: { width: 195 }},
    { label: '备注', key: 'remark', type: 'textarea', required: false, maxLength: 100 },
  ]
  const back = () => history.goBack();
  const onFinish = () => {
    message.success('异常处理成功!');
    dataSource.refresh();
    back();
  };

  return (
    <ModalFormPage
      submit={['/web/human_ticket/exceptionHandle', { id: match.params.id }]}
      title="异常处理"
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
      modalProps={{
        width: 500,
      }}
      formProps={{
        labelCol: { xs: { span: 6 } },
        wrapperCol: { xs: { span: 18 } },
      }}
    />
  );
}
