/* eslint-disable no-shadow */
import React, { useEffect, useState, useCallback } from 'react';
import { Route } from 'react-router-dom';
import { Modal } from 'antd';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import serial from 'ui/TableCell/serial';
import LinkButton from 'ui/TableCell/LinkButton';
import debug from 'utils/debug';
import request from 'utils/request';
import map from 'utils/map';
import { rowSpanRender as render } from 'utils/render';
import styles from './Plan.scss';

const dataSource = new DataSource('/web/plan/getSandPlanLists', {}, (data) => {
  const source = data.reduce((flatten, { materiel, ...plan }, index) => {
    const serial = index + 1;
    const key = i => `${plan.id}-${i}`;
    const span = i => (i === 0 ? materiel.length : 0);
    return [
      ...flatten,
      ...materiel.map((mater, i) => ({
        ...mater,
        ...plan,
        serial,
        key: key(i),
        span: span(i),
      })),
    ];
  }, []);
  console.log(source);
  return source;
});

function List() {
  debug.render('VolumeModificationRecords');

  const columns = [
    serial(true),
    { title: '起点', dataIndex: 'start_name', render },
    { title: '终点', dataIndex: 'end_name', render },
    { title: '物品', dataIndex: 'materiel_name' },
    { title: '方量', dataIndex: 'volumn' },
    { title: '单价', dataIndex: 'unit_price' },
    { title: '路线', key: 'routes', render: render(($, { id }) => <LinkButton to={`./${id}/routes`}>查看</LinkButton>) },
  ];

  const querys = [
    { title: '关键字', key: 'keyword' },
  ];

  return (
    <TablePage
      rowKey="key"
      dataSource={dataSource}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}

function Routes({ match, history }) {
  const sand_plan_id = match.params.id;

  const [routes, setRoutes] = useState();

  useEffect(() => {
    request('/web/route/getSandPlanRouteLists', { sand_plan_id })
      .then(body => body.data.data.routes)
      .then(routes => setRoutes(routes.map(({ steps }) => map.objs2arrs(map.baidus2amaps(steps)))));
  }, []);

  const mapRef = useCallback((node) => {
    if (!node) return;
    const map = new window.AMap.Map(node, { mapStyle: 'amap://styles/macaron' });
    const colors = ['#EF476F', '#FFD166', '#06D6A0', '#E71D36', '#118AB2', '#E55934', '#073B4C'];
    routes.forEach((path, index) => {
      map.add(new window.AMap.Polyline({
        path,
        strokeColor: colors[index],
        strokeWeight: 6,
        lineJoin: 'bevel',
        lineCap: 'round',
        showDir: true,
      }));
      // map.add([start, end].map(location => new window.AMap.Marker({
      //   position: new window.AMap.LngLat(location.lng, location.lat),
      //   label: {
      //     content: location.name,
      //     direction: 'center',
      //     offset: new window.AMap.Pixel(0, -35),
      //   },
      // })));
    });
    map.setFitView();
  }, [routes]);

  return !routes ? null : (
    <Modal
      visible
      centered
      footer={null}
      width={1200}
      onCancel={history.goBack}
      className={styles.mapContainer}
    >
      <div style={{ height: 800, borderRadius: 4 }} ref={mapRef} />
    </Modal>
  );
}

export default function VolumeModificationRecords({ path }) {
  debug.render('Drivers');  
  return (
    <>
      <Route path={path} component={List} />
      <Route path={path + ':id/routes'} component={Routes} />
    </>
  );
}
