import React from 'react';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import serial from 'ui/TableCell/serial';
import debug from 'utils/debug';

const dataSource = new DataSource('web/plan_car/getSandRecord');

export default function VolumeModificationRecords() {
  debug.render('VolumeModificationRecords');

  const columns = [
    serial(true),
    { title: '车牌号', dataIndex: 'car_num' },
    { title: '修改前方量(m³)', dataIndex: 'old_volumn' },
    { title: '修改后方量(m³)', dataIndex: 'volumn' },
    { title: '修改时间', dataIndex: 'created_time' },
  ];

  const querys = [
    { title: '请输入车牌号搜索', key: 'keyword' },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}
