import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

import styles from './index.scss';

export const Section = ({ title, buttonName, buttonLink, children, width = 1200 }) => (
  <div className={styles.section}>
    <div className={styles.header}>
      <div className={styles.content} style={{ width }}>
        <div className={styles.title}>{title}</div>
        <div className={styles.button}><Link to={buttonLink}><Button size="small" type="primary">{buttonName}</Button></Link></div>
      </div>
    </div>
    <div className={styles.body} style={{ width }}>
      {children}
    </div>
  </div>
);

export const Item = ({ title, children }) => (
  <div className={styles.item}>
    <div className={styles.title}>{title}</div>
    <div className={styles.content}>{children}</div>
  </div>
);
