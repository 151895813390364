import React, {useState} from 'react';
import { Route } from 'react-router-dom';
import { previewable } from 'components/Previewer/index';
import Details from '../Details';
import ChangeToGrey from '../ChangeToGrey';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import ImagePreviewButtonRender from 'ui/TableCell/ImagePreviewButtonRender';
import LinkButton from 'ui/TableCell/LinkButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';
import { reasonArr, punishArr, isDirectory, typeArr } from "../../share";
import store from "stores/user";
// export const dataSource = new DataSource('web/blacklist/getCarBlacklist', {list_type: 2});
export const dataSource = new DataSource('/web/gb_list/getGbCarLists', {car_type: 2});

function List({ preview }) {
  const render = ImagePreviewButtonRender(preview);
  const roleId = store.getState().roles.id;
  const area_id = store.getState().info.area_id;
  const [selectedRow, setSelectedRow] = useState([]);
  const [multipleData, setMultipleData] = useState([]);
  const columns = [
    serial(true),
    { title: '车牌号', dataIndex: 'car_number', width: width(7)},
    { title: '车辆照片', dataIndex: 'photos', render },
    { title: '是否温江名录', dataIndex: 'is_car_directory', render: (v) => v ? '是' : '否' },
    { title: '运企名称', dataIndex: 'transport_name' },
    { title: '所属区域', dataIndex: 'area_name' },
    { title: '关联驾驶员', dataIndex: 'driver_name' },
    { title: '事由', dataIndex: 'reason', render: function(v){ return typeArr[v]} },
    { title: '进入时间', dataIndex: 'in_time' },
    { title: '提交人', dataIndex: 'created_name' },
    { title: '退出时间', dataIndex: 'out_time' },
    actions({
      width: 100,
      render: ($, { id }) => (
        <LinkButton to={`./1/${id}/details`}>详情</LinkButton>
      ),
    }),
  ];
  const querys = roleId === 111 ? [
    { title: '搜索车牌、驾驶员', key: 'keyword', style: { width: 200 } },
    { title: '运企', key: 'transport_id', type: 'select', options: 'web/blacklist/getTransportLists', extraData: { area_id }, valueKey: 'id', labelKey: 'name', style: { width: 200 } },
    { title: '事由', key: 'reason', type: 'select', options: reasonArr, valueKey: 'value', labelKey: 'label', style: { width: 200 }, },
    { title: '是否温江名录', key: 'is_car_directory', type: 'select', options: isDirectory, valueKey: 'value', labelKey: 'label', style: { width: 150 }, },
    { title: '处罚', key: 'deal_result', type: 'select', options: punishArr, valueKey: 'value', labelKey: 'label', style: { width: 120 }, },
    { title: ['进入', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } }
  ] : [
    { title: '搜索车牌、驾驶员', key: 'keyword', style: { width: 200 } },
    { title: '事由', key: 'reason', type: 'select', options: reasonArr, valueKey: 'value', labelKey: 'label', style: { width: 200 }, },
    { title: '是否温江名录', key: 'is_car_directory', type: 'select', options: isDirectory, valueKey: 'value', labelKey: 'label', style: { width: 150 }, },
    { title: '处罚', key: 'deal_result', type: 'select', options: punishArr, valueKey: 'value', labelKey: 'label', style: { width: 120 }, },
    { title: ['进入', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } }
  ];

  const disabled = multipleData.length === 0;
  const buttons = [
    { title: '导出', download: '/web/gb_list/exportGbCarLists', extraParams: {car_type: 2} },
    roleId === 111 && { title: '转灰名单', link: {pathname: './change-to-grey', state: { data: multipleData }}, disabled: disabled }
  ];
  const onSelectChange = (rows) => {
    setSelectedRow(rows);
    const tableData = dataSource.dataSource.data;
    let data = [];
    tableData.map(({ id, ...d }) => {
      rows.map((v) => {
        if(id === v) {
          data.push({ id, ...d })
        }
      })
    })
    setMultipleData(data);
  };
  const rowSelection = {
    selectedRow,
    selectedRowKeys: selectedRow,
    onChange: onSelectChange,
    selections: []
  };
  return (
    <>
      {
        roleId === 111 ?
          <TablePage
            dataSource={dataSource}
            buttons={buttons}
            querys={querys}
            columns={columns}
            defaultLimit={10}
            rowSelection={rowSelection}
          /> :
          <TablePage
            dataSource={dataSource}
            buttons={buttons}
            querys={querys}
            columns={columns}
            defaultLimit={10}
          />
      }
    </>
  );
}
const PreviewableList = previewable(List);

export default function Car({path}) {
  return (
    <>
      <Route path={path} component={PreviewableList}/>
      <Route path={path + ':types/:id/details'} component={Details}/>
      <Route path={path + 'change-to-grey'} component={ChangeToGrey}/>
    </>
  );
}
