import {useEffect, useRef} from "react";

export function UseInterval(callback, delay) {
  const savedCallback = useRef();
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function CompareTime(start, end){
  var ms = new Date(end).getTime() - new Date(start).getTime();
  return Math.floor(ms/1000);
}
export function CountTime(startTime, splitHour){
  const start = new Date(startTime);
  const endTime = start.setHours(start.getHours() + splitHour);
  const currTime = new Date();
  const spiltMinutes = CompareTime(currTime, endTime);
  return parseInt(spiltMinutes / 60 / 60) + '小时' + parseInt(spiltMinutes / 60 % 60) + '分钟' + parseInt(spiltMinutes % 60) + '秒'
}
