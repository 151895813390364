/* eslint-disable no-undef */
import React, { useState } from 'react';
import {
  Card,
  Form,
  Icon,
  Input,
  Button,
  Tooltip,
} from 'antd';
import { Redirect } from 'react-router-dom';
import { logIn } from 'actions/user';
import connector from 'connectors/user';
import debug from 'utils/debug';
import styles from './index.scss';

function TestUserOptions({ onLogIn }) {
  const password = '123456';
  const icons = [
    <svg width="17.553" height="14.978" viewBox="-1.321 -0.015 17.553 14.978"><path fill="#40E2A0" d="M15.998,14.727L7.504,0.204l-8.486,14.521L15.998,14.727z M7.48,4.057l4.889,8.569H2.637L7.48,4.057z" /></svg>,
    <svg width="17.553" height="14.978" viewBox="-1.269 -0.006 17.553 14.978"><circle fill="none" stroke="#FF6666" strokeWidth="2" cx="7.5" cy="7.5" r="6.25" /></svg>,
    <svg width="17.553" height="14.978" viewBox="-1.301 -0.015 17.553 14.978"><g><line fill="none" stroke="#7CB2E8" strokeWidth="2" x1="1" y1="1" x2="14" y2="14" /><line fill="none" stroke="#7CB2E8" strokeWidth="2" x1="14" y1="1" x2="1" y2="14" /></g></svg>,
    <svg width="17.553" height="14.978" viewBox="-1.766 -0.471 17.553 14.978"><rect x="1" y="1" fillOpacity="0" stroke="#FF69F8" strokeWidth="2" width="12" height="12" /></svg>,
  ];

  return !TEST_USER_OPTIONS ? null : (
    <Card className={styles.test} title="测试账号快速登录">
      <ul className={styles.groups}>
        {[...Object.entries(TEST_USER_OPTIONS)].map(([groupname, users]) => (
          <li className={styles.group} key={groupname}>
            <div className={styles.name}>{groupname}</div>
            <div className={styles.users}>
              {users.map((username, i) => (
                <Tooltip title={username} key={username}>
                  <Button className={styles.button} onClick={() => onLogIn(username, password)}>{icons[i]}</Button>
                </Tooltip>
              ))}
            </div>
          </li>
        ))}
      </ul>
    </Card>
  );
}

function Login({ user }) {
  debug.render('Login');
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  function onLogIn(e) {
    e.preventDefault();
    if (username && password) {
      logIn(username, password);
    }
  }

  return user.id ? <Redirect to="/" /> : (
    <div className={styles.container}>
      <Form className={styles.form} onSubmit={onLogIn}>
        <div className={styles.header}>
          <img className={styles.logo} src="/logo.png" alt="" />
          <div className={styles.name}>{SITE_NAME}</div>
        </div>
        <Form.Item><Input className={styles.input} value={username} onChange={e => setUsername(e.target.value)} prefix={<Icon type="user" className={styles.icon} />} placeholder="请输入账号" /></Form.Item>
        <Form.Item><Input className={styles.input} value={password} onChange={e => setPassword(e.target.value)} prefix={<Icon type="lock" className={styles.icon} />} placeholder="请输入密码" type="password" /></Form.Item>
        <Form.Item><Button className={styles.button} type="primary" htmlType="submit" onClick={onLogIn}>登录</Button></Form.Item>
      </Form>
      <TestUserOptions onLogIn={logIn} />
    </div>
  );
}

export default connector(Login);
