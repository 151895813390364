import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import request from 'utils/request';
import styles from './OfflineWork.scss';

function getOffLineReason(id) {
  const [data, setData] = useState({ car_number: '', reason: '', offline_time: '' });
  useEffect(() => {
    request('/web/ticket/OffLineReason', { ticket_id: id })
      .then((res) => res.data.data)
      .then(setData)
  }, [id]);
  return data;
}
export default function OfflineWork({ match, history, ...prop }) {
  console.log(prop)
  const { id } = match.params
  const data = getOffLineReason(id);
  const back = () => history.goBack();
  return (
    <Modal
      visible={true}
      title={data.car_number}
      footer={null}
      onCancel={back}
    >
      <div className={styles.content}>
        <span>离线申报原因</span>
        <p>{data.reason}</p>
      </div>
      <div className={styles.content}>
        <span>离线时段</span>
        <p>{data.offline_time}</p>
      </div>
    </Modal>
  )
}
