import React, {useState, useEffect} from 'react';
import { Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {Button, Divider, Icon, Input, Pagination, Table, DatePicker, Select} from 'antd';
import { previewable } from 'components/Previewer';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import user from 'stores/user';
import styles from "./index.scss";
import request from 'utils/request';
import MapDetail from './MapDetail';
import moment from 'moment';

const FaultStatus = [
  { id: 0, name: '所有异常'},
  { id: 1, name: '有联单无GPS(坐标)'},
  { id: 2, name: 'GPS漂移'},
  { id: 3, name: 'GPS长期离线'},
  { id: 4, name: 'GPS短期离线'},
  { id: 5, name: 'GPS瞬时离线'},
];
const colsColors = ['#02A7F0', '#F59A23', '#8080FF', '#D9001B', '#E55365', '#EC808D'];
const defaultDates = [moment().subtract(0, 'day').startOf('day'), moment().endOf('day')];
const defaultDates2 = [moment().subtract(0, 'day').startOf('day').format('YYYY-MM-DD'), moment().endOf('day').format('YYYY-MM-DD')];
export default function ExceptionGps({path}){
  const [query, setQuery] = useState({keyword: '', created_time: defaultDates2, area_id: '', fault_type: 0, page: 1, limit: 10});
  const [time, setTime] = useState(defaultDates);
  const [keyword, setKeyword] = useState('');
  const [area_id, setAreaId] = useState(26);
  const [fault_type, setFaultType] = useState(0);
  const [roleId, setRoleId] = useState('');
  const [statusRemark, setStatusRemark] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  useEffect(()=>{setRoleId(user.getState().roles.id)});
  const columns = roleId !== 101 ? [
    { title: '序号', dataIndex: 'serial' },
    { title: '车牌号', dataIndex: 'car_num', width: width(7) },
    { title: '司机名称', dataIndex: 'driver_name' },
    { title: '运企名称', dataIndex: 'transport_company'},
    { title: '异常类型', dataIndex: 'fault_type' },
    { title: '异常路段', dataIndex: 'remark', render: function(t, v){return v.fault_type === "有联单无GPS（坐标）" ? <Link to={`${v.id}/${v.car_num}/map-detail`}><span style={{color: '#1491fb'}}>查看</span></Link> : t} },
    { title: '异常时间', dataIndex: 'created_time' }
  ] : [
    { title: '序号', dataIndex: 'serial' },
    { title: '车牌号', dataIndex: 'car_num', width: width(7) },
    { title: '司机名称', dataIndex: 'driver_name' },
    { title: '异常类型', dataIndex: 'fault_type' },
    { title: '异常路段', dataIndex: 'remark', render: function(t, v){return v.fault_type === "有联单无GPS（坐标）" ? <Link to={`${v.id}/${v.car_num}/map-detail`}><span style={{color: '#1491fb'}}>查看</span></Link> : t} },
    { title: '异常时间', dataIndex: 'created_time', }
  ];

  function getDataInit(){
    request('/web/Gps_Fault/gpsFault', query)
      .then(body => body.data)
      .then(data => {
        let arr = [];
        data.data.data.map((e, serial) => {
          arr.push({...e, serial: (((query.page - 1) * query.limit) + (serial + 1))})
        });
        setDataSource(arr);
        setTotal(data.data.total);
        setStatusRemark(data.fault_types_num)
      })
  }

  useEffect(() => {
    getDataInit();
  }, [JSON.stringify(query)]);
  return (
    <>
      <div className={styles.part}>
        <div className={styles.container}>
          <div className={styles.remark}>
            {
              statusRemark.map(({name, num}, i) => (
                <div style={{backgroundColor: colsColors[i]}} key={i}>
                  <span>{name}</span>
                  <span style={{textAlign: 'right'}}>{num + '起 ' + (i === 0 ? '' : ('(' + (statusRemark[0].num === 0 ? 0 : (num === 0 ? 0 : ((num / statusRemark[0].num) * 100).toFixed(0))) + '%)'))}</span>
                </div>
              ))
            }
          </div>
          <Input.Group className={styles.querys} compact>
            <Input placeholder={'请输入关键字搜索'} allowClear style={{width: 200}} onChange={e => setKeyword(e.target.value)} />
            <DatePicker.RangePicker value={time} style={{width: 250}} format={'YYYY-MM-DD'} onChange={(m, dateString) => {setTime(m);}} />
            <Select onChange={e => setFaultType(e)} allowClear placeholder={'请选择异常类型'} value={fault_type} style={{width: 180}}>
              {
                FaultStatus.map(({id, name}) =>
                  <Select.Option key={id} value={id}>{name}</Select.Option>
                )
              }
            </Select>
            <Button type="primary" onClick={() => setQuery({...query, page: 1, created_time: [time[0].format('YYYY-MM-DD'), time[1].format('YYYY-MM-DD')], keyword: keyword, fault_type: fault_type, area_id: area_id})}><Icon type="search" /></Button>
          </Input.Group>
        </div>
        <div className={styles.newTable}>
          <Table size="middle" rowKey={'id'} bordered className={styles.table} columns={columns} dataSource={dataSource} pagination={false} />
        </div>
        <div className={styles.pagination}>
          <Pagination total={total} current={query.page} pageSize={query.limit} showSizeChanger={true} onChange={(page, limit) => setQuery({...query, page, limit})}
                      onShowSizeChange={(page, limit) => setQuery({...query, page, limit})}
                      showTotal={t => `共 ${t} 条`}
          />
        </div>
      </div>
      <Route path={path + ':id/:car_num/map-detail'} component={MapDetail} />
    </>
  )
}
