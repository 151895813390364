import React, {useState, useEffect} from 'react';
import request from 'utils/request';
import moment from 'moment';
import {DatePicker, Spin, Drawer, Icon, Select} from 'antd';
import styles from './CasesCenter.scss';
import store from 'stores/user';

const defaultDates = [moment().subtract(7 - 1, 'days').startOf('day'), moment().subtract(0, 'days').endOf('day')];

export default function CasesCenter({onClose}) {
  const [caseArr, setCaseArr] = useState([]);
  const { info: {area_id}} = store.getState();
  const [params, setParams] = useState({
    times: defaultDates,
    case_big_type: undefined,
    case_type: undefined,
  });
  const [total, setTotal] = useState(0);
  const [spin, setSpin] = useState(false);
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [detail, setDetail] = useState('');
  const [backs, setBacks] = useState(0);
  const [backFiles, setFiles] = useState('');
  const statusArr = ['', '未结案', '已结案'];
  const bigArr = ['', '涉尘案件', '涉砂案件'];
  const typeArr = [
    '',
    '雾炮/喷淋未开启',
    '裸土未覆盖',
    '冲洗设备未开启/道路污染',
    '夜间无证施工',
    '篷布未覆盖',
    '违规乱倾倒',
    '黑工地私挖盗采',
    '疑似偷排',
    '使用非名录车',
    '恶意躲避监管',
    '车辆超速',
    '违规作业',
  ];

  useEffect(() => {
    const begin_date = params.times[0].format('YYYY-MM-DD');
    const end_date = params.times[1].format('YYYY-MM-DD');
    const param = {
      page: 1,
      list_rows: 100,
      case_time: [begin_date, end_date],
      case_big_type: params.case_big_type,
      case_type: params.case_type,
      area_id: area_id,
    };
    request('/rest/case_report/list', param)
      .then(res => res.data.data)
        .then(data => {
          setList(data.data);
          setTotal(data.total);
          setSpin(false);
        });
  }, [params]);

  function MorePdf ({data}) {
    if(!data) return false;
    const src = data.split(',');

    return (
      <div className={styles.files}>
        {
          src.map((item, i) => {
            return (
              <object type="application/pdf" data={item} width="100%" height="100%" key={i} style={{margin: '10px 0'}}> </object>
            )
          })
        }
      </div>
    )
  }
  function bigChange(value) {
    setParams({...params, case_big_type: value, case_type: undefined});
    setCaseArr([]);
    if (value) {
      request('/rest/case_report/getCaseTypeParams', {case_big_type: value})
        .then(res => setCaseArr(res.data.data));
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <span style={{fontSize: 16, fontWeight: 'bolder'}}>案件中心</span>
          <Icon type="close" onClick={onClose} />
        </div>
        <div>
          <DatePicker.RangePicker
            size="small" allowClear={false} separator={'~'} format="YYYY-MM-DD"
            value={params.times}
            onChange={(value) => setParams({...params, times: value})}  />
        </div>
        <div className={styles.tabs}>
          <Select value={params.case_big_type} onChange={bigChange} size="small" placeholder="案件类型" allowClear className={styles.big}>
            <Select.Option value={1}>涉尘案件</Select.Option>
            <Select.Option value={2}>涉砂案件</Select.Option>
          </Select>
          <Select value={params.case_type} onChange={(value) => setParams({...params, case_type: value})} allowClear size="small" placeholder="违规行为" className={styles.big}>
            {
              caseArr.map((item, i) => (
                <Select.Option key={i} value={item.id}>{item.value}</Select.Option>
              ))
            }
          </Select>
        </div>
        <div className={styles.search}>
          <div style={{whiteSpace: 'nowrap', marginRight: 50}}>共{total}起案件</div>
        </div>
        <div className={styles.list}>
          <Spin spinning={spin}>
            {
              list.length > 0 ?
                list && list.map((item, i) => (
                  <div key={i} className={styles.items}>
                    <div className={styles.line}>
                      <div className={styles.name} title={item.case_name}>
                        <img src="/images/案件.png" alt="" style={{marginRight: 5}}/>
                        <span>{item.case_name}</span>
                      </div>
                      <div className={styles.btn} onClick={() => {setVisible(true); setDetail(item.case_file); setFiles(item.case_file2);}}>查看</div>
                    </div>
                    <div className={styles.line}>
                      <div>
                        <span>所属区县：</span>
                        <span>{item.area_name}</span>
                      </div>
                      <div>
                        <span>案件状态：</span>
                        {
                          item.case_status === 1 ?
                            <span style={{color: '#FF9D31'}}>{statusArr[item.case_status]}</span> :
                            <span style={{color: '#4AA1FB'}}>{statusArr[item.case_status]}</span>
                        }
                      </div>
                    </div>
                    <div className={styles.line}>
                      <div style={{display: 'flex', width: 110, whiteSpace: 'nowrap'}}>
                        <span>案件种类：</span>
                        <span>{bigArr[item.case_big_type]}</span>
                      </div>
                      <div style={{display: 'flex', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}} title={typeArr[item.case_type]}>
                        <span>案件类别：</span>
                        <div>
                          <span>{typeArr[item.case_type]}</span>
                        </div>
                      </div>
                    </div>
                    <div className={styles.line}>
                      <div>
                        <span>案件时间：</span>
                        <span>{item.case_time}</span>
                      </div>
                    </div>
                    <div className={styles.line} style={{borderTop: '1px solid #E7E7E7', textAlign: 'center'}}>
                      <div>
                        <span>报案人：</span>
                        <span>{item.user_name}</span>
                      </div>
                      <div>
                        <span>受理人：</span>
                        <span>{item.accept_user}</span>
                      </div>
                    </div>
                  </div>
                ))
                : <div className={styles.empty}>
                  <img src="/images/alarm/empty.png" alt=""/> <span>当前暂无数据</span>
                </div>
            }
          </Spin>
        </div>
      </div>
      {
        visible ?
          <Drawer
            title={
              <div className={styles.title}>
                <span onClick={() => setBacks(0)} className={backs === 0 ? styles.active : null}>案件详情</span>
                <span onClick={() => setBacks(1)} className={backs === 1 ? styles.active : null}>案件回执</span>
              </div>
            }
            visible={visible}
            closable={true}
            mask={false}
            destroyOnClose={true}
            onClose={() => setVisible(false)}
            width={500}
            headerStyle={{padding: '10px 20px', borderBottom: 'none'}}
            bodyStyle={{padding: '10px 20px', height: '90%'}}
            style={{height: 800, top: 90}}
            className={visible ? styles.details : ''}
          >
            <div style={{width: '100%', height: '100%', padding: 10, textAlign: 'center'}}>
              {
                backs === 0 ?
                  detail ?
                    <MorePdf data={detail}/> :
                    <div>暂无数据</div> :
                  backFiles ?
                    <MorePdf data={backFiles}/> :
                    <div>暂无数据</div>
              }
            </div>
          </Drawer> :
        null
      }
    </>
  )
}
