/* eslint-disable */
import React from 'react';
import './index.global.scss';
import ReactDOM from 'react-dom';
import store from 'stores/user';
import {Form, Input, Table, Select, Button, InputNumber, Radio, message, Modal, Icon, Tooltip, Spin} from 'antd';
import moment from 'moment'
import request from 'utils/request';
import {Link} from "react-router-dom";
import {ImagePreviewer} from 'components/Previewer';
import controller from './controller';
import HighLight from './HighLight';

const {Option} = Select;
const RadioGroup = Radio.Group;
let received_msg = [];
let start_time = moment().subtract(0, 'day').startOf('day');
let end_time = moment().endOf('day');
let LODOP = null;
let selectPrinter = null;
/** 导出 */
function base64(s) { return window.btoa(unescape(encodeURIComponent(s))) }

const CreateOneFormPage = function () {
  LODOP = getLodop();
  LODOP.PRINT_INIT("打印控件功能演示_Lodop功能_表单一");
  LODOP.SET_PRINT_STYLE("FontSize", 13);
  LODOP.SET_PRINT_STYLE("Bold", 1);
  LODOP.SET_PRINT_PAGESIZE(1, 2160, 930, '');
  LODOP.SET_PRINT_MODE("POS_BASEON_PAPER", true);
  //LODOP.ADD_PRINT_TEXT(50, 231, 260, 39);
  var strStyleCSS = '<link href="/_flex.min.css" type="text/css" rel="stylesheet">';
  var strFormHtml = '<head>' + strStyleCSS + '</head><body><div style="width:750px;">' + document.getElementById("print_t1").innerHTML + '</div></body>';
  LODOP.ADD_PRINT_HTM(5, 5, 150, "BottomMargin:0", strFormHtml);
};
// const oldInstance = [1784, 2999, 3135, 3292, 3323, 2254, 3324, 1770, 3295, 3325, 2218, 3297, 3298, 3326, 3300, 3301, 3302, 3250, 3303, 3142, 3304, 3327, 3308, 3105, 3005, 3088, 3328, 3329, 3309, 3314, 2994, 3210, 3222, 3205, 3221, 3317, 3223, 3321, 3225, 3322, 3056, 2417, 3573, 3214, 7033, 3212];
const oldInstance = [1198,1506,1549,1565,1576,1585,1726,1730,1770,1784,1988,2218,2220,2254,2346,2357,2358,2417,2433,2463,2464,2624,2659,2755,2811,2880,2994,2996,2999,3002,3005,3065,3095,3102,3105,3106,3107,3108,3109,3120,3121,3135,3142,3203,3210,3212,3213,3222,3250,3266,3273,3282,3295,3296,3298,3301,3302,3308,3309,3314,3323,3324,3325,3326,5588,5675,5703,5884,6294,6359,6920,7032,7033,7637,7638,7639,7644,7656,7657,7665,7819,7820,7821,7823, 5894];
const isInArray = function (arr, value) {
  for (var i = 0; i < arr.length; i++) {
    if (value === arr[i]) {
      return true;
    }
  }
  return false;
};

const compare = (filed, value) => {
  return (obj1, obj2) => {
    if(obj1[filed].indexOf(value) > -1){
      if(obj2[filed].indexOf(value) > -1 ){
        if(obj1['serial'] > obj2['serial']){
          return 1
        }else if(obj1['serial'] < obj2['serial']){
          return -1
        }else{
          return 0
        }
      }else{
        return -1
      }
    }else {
      return 1
    }
  }
}
const compareByOrder = () => {
  return (obj1, obj2) => {
    if(obj1['serial'] < obj2['serial']){
      return -1
    }else{
      return 1
    }
  }
}

let lockReconnect = false;//避免重复连接
// let wsUrl = "wss://file.shomes.cn:8335";		// websocket链接  "ws://106.75.154.221:8349";//
let wsUrl = "ws://221.237.182.170:8400";//"wss://admin.arrivalpay.cn/shomes";		// websocket链接
let ws = null;

export default Form.create({})(class SandStoneReceiver extends React.PureComponent {
  columns = [
    {title: '序号', dataIndex: 'serial', key: 'serial', fixed: 'left', width: 70, render: (_, $, index) => index + 1},
    {title: '车牌号', dataIndex: 'car_num', key: 'car_num', fixed: 'left', width: 150, render: (v) => <HighLight source={v} search={ this.state.keyword } />},
    {title: '工地项目', dataIndex: 'building_name', key: 'building_name'},
    {title: '识别时间', dataIndex: 'end_time', key: 'end_time', sorter: (a, b) => {
      return Math.floor(moment(a.end_time)) - Math.floor(moment(b.end_time))
    }},
    {title: '货箱方量', dataIndex: 'car_volumn', key: 'car_volumn', render: (e) => parseFloat(e).toFixed(2)},
    {
      title: '入倒场-加工商是否收货',
      dataIndex: 'end_confirm_name',
      key: 'end_confirm_name',
      render: (e) => {
        if (e === '') return '无';
        else return <span style={{ color: "#00BC95" }}>加工商已确认</span>
      }
    },
    {title: '上一趟工地项目', dataIndex: 'last_building_name', key: 'last_building_name'},
    {title: '联单来源', dataIndex: 'source', key: 'source', render: function (t, e) {
        return t === '补录' ?
          <Tooltip placement="top" title={e.add_reason}>
            <Button type={'default'} size={'small'}>{t}</Button>
          </Tooltip> : <span>{t}</span>
      }
    },
  ];
  datas = [
    {serial: 0, car_num: 'chuan', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 1, car_num: 'chuan', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 2, car_num: 'chuan', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 3, car_num: 'chuan', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 4, car_num: 'chuan', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 5, car_num: 'chuan', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 6, car_num: 'chuan', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 7, car_num: 'chuan', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 8, car_num: 'chuan', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 9, car_num: 'chuan', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 10, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 11, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 12, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 13, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 14, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 15, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 16, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 17, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 18, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 19, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 20, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 21, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 22, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 23, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 24, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 25, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 26, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 27, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 28, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 29, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 30, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 31, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 32, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 33, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 34, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 35, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 36, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 37, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 38, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 39, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 40, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 41, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 42, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 43, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 44, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 45, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 46, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 47, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 48, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 49, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
    {serial: 50, car_num: 'chuanAA', building_name: 1, end_time: 1, car_volumn: 1, end_confirm_name: 1, last_building_name: 1, source: 1, },
  ];
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      autoCompleteResult: [],
      confirm: true,
      checkedList: [],
      dataSource: [],
      reason: false,
      keyword: '',
      cancel: '',
      limit: 10,
      page: 1,
      total: 0,
      time: [start_time.format('YYYY-MM-DD HH:mm:ss'), end_time.format('YYYY-MM-DD HH:mm:ss')],
      returnGoods: [
        {label: '是', value: 1},
        {label: '否', value: 0},
      ],
      return_goods: 0,
      userInstance: [],
      instance_id: '选定一个实例',
      images: ['/images/icon.png', '/images/icon.png', '/images/icon.png'],
      showImg: '',
      img_flag: false,
      form_data: {},
      materielType: 1, // 收货类型
      department_id: '',
      total_volume: 0,
      base_volume: 0,
      modal_flag: false,
      instance_name: '',
      printYear: new Date().getFullYear(),
      printMonth: new Date().getMonth() + 1,
      printDay: new Date().getDate(),
      receiverName: store.getState().info.name,
      userAreaId: store.getState().info.area_id,
      car_num: '',
      printCompany: '成都宏图华信投资有限公司',
      printJson: {
        number: '',
        car_num: '',
        volume: 0,
        reason: '',
        driver_name: '',
        build_name: '',
        real_volumn: 24.00,
        deduction_volume: 0,
        date: '',
        car_number: 0,
        deduction_reason: '',
        car_type: '',
        car_directory: '',
        car_plan: '',
        materielName: '连砂石'
      },
      buildArr: [],
      materialArr: [], // 物料类型数组      
      reasonRadio: '泥巴',
      buildingArr: [],
      building_id: '',
      is_acquirer: {show: 0, car_num: '', end_time: ''},
      rowSelect: {},
      secondReceiver: JSON.parse(sessionStorage.getItem('secondReceiver')) ? JSON.parse(sessionStorage.getItem('secondReceiver')).name : '',
      receiverSpin: false,
      save: false,
      spinLoading: false,
      defaultDeduction: '1.0',
      localname: '', // 存储当日的localStroage数据
      endConfirmUsers: new Map([]), // 存储所有的入倒场-收货人的信息{id, name}, 每次收货后将其删除
    }
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `/LodopFuncs.js`;
    document.head.appendChild(script);
    this.getUserInstance();
    this.getHistoryBuild();
    this.getMaterialArr();
    /** 进入页面首先判断有没有当日的local 如果有则不做操作，没有则默认添加一个 */
    this.getLocal();
    const setSandStone = JSON.parse(sessionStorage.getItem('setSandstone'));
    if(setSandStone){
      this.onInstanceChange({}, {props: {value: setSandStone.instance_id, title: setSandStone.department_id, instance_name: setSandStone.instance_name, materielType: setSandStone.materielType}})
    }else{
      this.setState({
        modal_flag: true
      })
    }
    controller.onRefresh(() => {
      const that = this;
      const sessionData = JSON.parse(sessionStorage.getItem('ticketAddAndPrint'));
      // const printJson = {
      //   number: sessionData.number,
      //   car_num: sessionData.car_num,
      //   volume: sessionData.volume,
      //   reason: sessionData.reason,
      //   driver_name: sessionData.driver_name,
      //   build_name: sessionData.build_name,
      //   real_volumn: sessionData.real_volumn,
      //   deduction_volume: sessionData.deduction_volume,
      //   date: sessionData.date,
      //   transport_count: sessionData.transport_count,
      //   deduction_reason: sessionData.reason,
      //   car_type: sessionData.car_type,
      //   car_directory: sessionData.car_directory,
      //   car_plan: sessionData.car_plan,
      //   materielName: sessionData.materielName,
      // }
      const datas = [{...sessionData}, ...that.state.dataSource];
      const source = datas.map((item, i) => ({ serial: (i + 1), ...item}));
      this.setState({
        printCompany: '成都市瀚宇投资有限公司',
        form_data: sessionData,
        dataSource: source,
        rowSelect: sessionData
      });
      this.setSelectHrpFactRowClassName(sessionData)
      this.getLocalData({ localtext: '补录的联单数据', ...sessionData });
      // if(sessionData.types){
      //   this.printTicket();
      //   this.printingCount(sessionData.ticket_id)
      // }
      // setTimeout(function () {
      //   that.getRightTableData({current: that.state.page, pageSize: that.state.limit});
      // }, 3000)
    });
  }

  componentWillUnmount() {
    this.handleClose();
  }
  /** 设置当日的localStroage  */
  getLocal() {
    const localname = moment().format('YYYY-MM-DD');
    const local = localStorage.getItem(localname);
    if (!local) {
      localStorage.setItem(localname, JSON.stringify([]))
    }
    this.setState({
      localname: localname,
    })
  }
  /** 根据传入的值去对localStroage做存储 */
  getLocalData(rest) {
    const { localname } = this.state;
    const local = localStorage.getItem(localname);
    const data = JSON.parse(local);
    (data ? data : []).push({localtime: moment().format('YYYY-MM-DD HH:mm:ss'), ...rest});
    localStorage.setItem(localname, JSON.stringify(data))
  }
  /** 导出收货单日志 */
  export() {
    const local = localStorage.getItem(this.state.localname);
    const data = JSON.parse(local);      
    //Worksheet名
    let worksheet = `${this.state.localname}收货日志`;
    let uri = 'data:application/vnd.ms-excel;base64,';
    //下载的表格模板数据
    let template = data.map((item) => JSON.stringify(item) + '\n');
    //下载模板
    var link = uri + base64(template);
    var a = document.createElement("a");
    a.download = `${this.state.localname}收货日志.txt`;
    a.href = link;
    a.click();
    a.remove();
  }
  /** 清除日志 */
  clearDate = () => {
    const len = localStorage.length; // 获取长度
    for(let i = 0; i < len; i++) {
      const getKey = localStorage.key(i);
      if (getKey.includes('2021') || getKey.includes('2022') || getKey.includes('2023')) {
        localStorage.removeItem(getKey);
      }
    }
  }
  getMaterialArr = () => {
    request('/web/route/initMateriel', {})
      .then((body) => body.data.data)
      .then((data) => this.setState({ materialArr: data }))
  }

  getHistoryBuild = (ticketId) => {
    request('/web/building/history', { ticketId })
      .then(body => body.data.data)
      .then(data => this.setState({buildArr: data}));
  };

  printTicket = () => {
    CreateOneFormPage();
    if (selectPrinter) {
      LODOP.PRINTA();
    }
    else {
      LODOP.PRINT();
    }
    selectPrinter = false;
  };

  handleSubmit = (e) => {
    let that = this;
    e.preventDefault();
    const formData = that.state.form_data;
    if (formData.building_id === 0) {
      message.warn('请选择工地')
      return false;
    }
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (that.state.reason) {
        if (err) {
          return false
        }
      }
      this.setState({
        confirm: true,
        receiverSpin: true
      });
      formData.instance_id = that.state.instance_id;
      formData.department_id = that.state.department_id;
      that.getLocalData({ localtext: '点击确认收货的传到后台的联单数据', reasonRadio: that.state.reasonRadio, ...that.state.form_data });
      request({
        url: '/web/ticket/enterTicket',
        data: {
          ...that.state.form_data,
          volume: formData.cancel === 1 || formData.cancel === '1' ? 0 : formData.volume,
          reason: that.state.reasonRadio === '其他' ? formData.reason : that.state.reasonRadio,
          second_receiver: that.state.secondReceiver
        }
      }).then(res => {
        if (res.data.code !== 200) {
          this.setState({
            receiverSpin: false,
          });
          that.getLocalData({ localtext: '点击确认收货收货未成功的数据', msg: res.data.msg });
          return message.error(res.data.msg);
        }
        const response = res.data.data;
        const printBuildingId = formData.building_id;
        const printCompany = !isInArray(oldInstance, printBuildingId) ? '成都宏信投建材有限公司' : '成都宏图华信投资有限公司';
        that.getLocalData({localtext: '确认收货后的打印数据', printCompany, ...response });
        // 判断入倒场-加工商是否存在在 endConfirmUsers里，如果有则删除，避免数据过大
        const users = new Map([...this.state.endConfirmUsers]);
        if (users.has(formData.id)) {
          users.delete(formData.id)
        }
        this.setState({
          receiverSpin: false,
          reason: false,
          printCompany: printCompany,
          endConfirmUsers: new Map([...users]),
          printJson: response
        });

        this.getHistoryBuild();
        if (that.state.form_data.cancel === 1 || that.state.form_data.cancel === '1') {
          message.success('退货成功');
        }
        else {
          if(!that.state.save){
            if(response.building_name === '' || response.building_name === null) {
              message.warn('数据异常，请在已收货界面补打该联单！');
            }else {
              that.printTicket();
              that.printingCount(that.state.form_data.id);
            }
          }
          message.success('收货成功，请在“已入库”页面查看详情');
        }
        this.getRightTableData();
        that.setState({
          form_data: {},
        });
      })
    });
  };

  printingCount = (ticket_id) => {
    this.getLocalData({ localtext: '记录打印次数的请求', ticket_id })
    request('web/ticket/PrintingCount', {ticket_id})
      .then(body => console.log)
  }

  getUserInstance = () => {
    let that = this;
    request({
      url: '/web/instance/getUserInstance',
      data: {area_id: store.getState().info.area_id}
    }).then(res => {
      that.setState({
        userInstance: res.data.data
      });
    })
  }

  getRightTableData = () => {
    let that = this;
    //,time: ['2019-12-19 00:00:00', '2019-12-19 23:59:59']
    request({
      url: '/web/ticket/getNoAcquirer',
      data: {
        endConfirm: that.state.endConfirm,
        keyword: that.state.keyword,
        instance_id: that.state.instance_id,
        department_id: that.state.department_id
      }
    }).then(res => {
      const data = res.data.data;
      const source = data.data.map((v, i) => ({
        serial: (i + 1),
        ...v,
      }));
      that.setState({
        dataSource: source,
        total: source.length,
      });
    })
  }

  onInstanceChange = (option, e) => {
    let that = this;
    let instance_name = '';
    if(that.state.userInstance.length > 0){
      that.state.userInstance.map((v) => {
        if (v.instance_id === e.props.value) {
          instance_name = v.instance_name;
        }
      });
    }
    else{
      instance_name = e.props.instance_name
    }
    this.setState({
      modal_flag: false,
      materielType: e.props.materielType || 1,
      instance_id: e.props.value,
      department_id: e.props.title,
      instance_name: instance_name,
      printCompany: !isInArray(oldInstance, e.props.value) ? '成都宏信投建材有限公司' : '成都宏图华信投资有限公司'
    });
    this.getLocalData({ localtext: '切换砂石场', instance_id: e.props.value, department_id: e.props.title, instance_name: instance_name, materielType: e.props.materielType });
    sessionStorage.setItem('setSandstone', JSON.stringify({instance_id: e.props.value, department_id: e.props.title, instance_name: instance_name, materielType: e.props.materielType}))
    setTimeout(() => {
      this.getRightTableData();
    }, 1000);

    ws && this.handleClose();
    this.createWebSocket();
  }

  handleClose() {
    const that = this;
    if(ws){
      ws.onclose = () => {
        console.log('主动断开');
        that.getLocalData({ localtext: 'ws主动断开' });
      }
      ws && ws.close();
      clearTimeout(this.resetTimer);
      clearTimeout(this.reconnectTimer);
    }

  }

  createWebSocket = () => {
    const that = this;
    try {
      ws = new WebSocket(wsUrl);
      that.reload();
    } catch (e) {
      that.websocketReconnect();
    }
  }

  websocketReconnect = () => {
    const that = this;
    if (lockReconnect) {       // 是否已经执行重连
      return;
    };
    lockReconnect = true;
    //没连接上会一直重连，设置延迟避免请求过多
    tt && clearTimeout(tt);
    var tt = setTimeout(function () {
      if(ws){ws.close()}
      that.createWebSocket()
      lockReconnect = false;
    }, 5000);
  }

  //心跳检测重置
  resetHeartBeat() {
    clearTimeout(this.reconnectTimer);
    clearTimeout(this.resetTimer);
    this.resetTimer = setTimeout(() => {
      ws.send(JSON.stringify({ type: 'pong', route: "/ticket/acquirer-login", }));
      // clearTimeout(this.reconnectTimer);
      this.reconnectTimer = setTimeout(() => {
        ws.close();
        this.websocketReconnect(wsUrl);
      }, 30000);
    }, 30000);
  }

  reload() {
    const that = this;
    ws.onclose = (event) => {
      that.getLocalData({ localtext: '退出ws'})
      console.log('退出', event);
      // this.websocketReconnect(wsUrl);
    };
    ws.onopen = () => {
      that.getLocalData({ localtext: '连接ws', department_id: that.state.department_id, instance_id: that.state.instance_id})
      ws.send(JSON.stringify({
        type: 'login',
        route: "/ticket/acquirer-login",
        data: {
          department_id: that.state.department_id,
          instance_id: that.state.instance_id
        },
      }));
      this.resetHeartBeat();
    };
    // 通信发生错误时触发
    ws.onerror = function (evt) {
      that.websocketReconnect(wsUrl);
    };

    const onMessage = (evt) => {
      received_msg = JSON.parse(evt.data);
      if (received_msg.type === 'ping') {
        this.resetHeartBeat();
        // ws.send(JSON.stringify({"type": "pong"}));
      }
      else if (received_msg.type === 'endConfirm') {
        const data = received_msg.data;
        const users = new Map([...this.state.endConfirmUsers]);
        users.set(data.id, { ...data });
        this.setState({
          form_data: {
            ...this.state.form_data,
            building_id: data.building_id,
            building_name: data.building_name,
            ...data       
          },
          endConfirmUsers: new Map([...users])
        });
        that.getLocalData({localtext: 'ws获取到的加工商确认信息', ...data });
        that.getRightTableData();
      }
      else if (received_msg.type === 'enter') {
        ws.send(JSON.stringify({"type": "ok", route: "/ticket/acquirer-login",}));
        let data = received_msg.data;
        if(data.is_acquirer){
          that.setState({
            is_acquirer: {
              show: 1,
              car_num: data.car_num,
              end_time: data.end_time
            },
            form_data: {},
            confirm: data.is_acquirer ? true : false,
          });
          that.getRightTableData();
        } else {
          let flag = false;
          data.cancel = that.state.return_goods;
          data.instance_id = that.state.instance_id;//e.props.value;
          data.department_id = that.state.department_id;//e.props.title;
          let names = '连砂石';
          that.state.materialArr.map(({ id, name }) => {
            if (id === that.state.materielType) names = name;
          })
          data.materiel_id = that.state.materielType;
          data.materiel_name = names;
          if (!data.deduction_volume) {
            flag = false;
          } else if (data.deduction_volume !== '') {
            flag = true
          }

          !data.deduction_volume ? data.volume = parseFloat(data.car_volumn) : (parseFloat(data.car_volumn) - parseFloat(data.deduction_volume)).toFixed(2)

          if(data.door_type === 2 && JSON.stringify(that.state.form_data) === '{}'){
            that.getLocalData({localtext: 'ws获取到的数据联单数据', reasonRadio: '', ...data})
            that.setState({
              base_volume: data.volume,
              confirm: data.is_acquirer ? true : false,
              form_data: data,
              reason: flag,
              is_acquirer: {show: 0, car_num: '', end_time: ''}
            });
          }
          that.getHistoryBuild(data.id);
          that.getRightTableData();
        }

      }
      else if (received_msg.type === 'added') {
        message.warn('无联单，请补录！！');
        let data = received_msg.data;
        data.cancel = that.state.return_goods;
        data.instance_id = that.state.instance_id;//e.props.value;
        data.department_id = that.state.department_id;//e.props.title;
        data.deduction_volume = 0;
        that.getLocalData({ localtext: 'ws推过来后无联单让补录的默认联单数据', reasonRadio: '', ...data })
        that.setState({
          base_volume: data.volume,
          confirm: false,
          form_data: data
        });
      }
    };
    ws.onmessage = onMessage;
  }
  
  TicketClick = (e) => {
    let flag = false;
    let names = '连砂石';
    this.state.materialArr.map(({ id, name }) => {
      if (id === this.state.materielType) names = name;
    });        
    const json = {
      materiel_id: this.state.materielType,
      materiel_name: names,
      ...e,
      building_name: (e.building_name === "无" || e.building_name === "无GPS坐标") && e.has_last_building_name === 1 ? e.last_building_name : e.building_name,
      volume: e.volumn,
      cancel: e.cancel === '否' ? 0 : 1,
    };
    if (!e.deduction_volume|| e.deduction_volume === 0 || e.deduction_volume === '0.0') {
      flag = false;
    } else if (e.deduction_volume !== '') {
      flag = true
    }
    this.getLocalData({localtext: '从左侧列表选择的联单数据', ...json, reasonRadio: '' })
    this.setState({
      form_data: json,
      confirm: false,
      is_acquirer: {show: 0, car_num: '', end_time: ''},
      rowSelect: e,
      reason: flag,
    });
  }

  //根据是否被选中返回头表行的className
  setSelectHrpFactRowClassName = (record) => {
    return record.id === this.state.rowSelect.id ? "clickRowStyl" : '';
  }

  ShowImage = (src) => {
    const that = this;
    let arr = [];
    if (src && src.length > 0 && src.indexOf(',') > 0) {
      arr = src.split(",")
    } else {
      arr = [src]
    }
    const arrs = arr.map(( src ) => that.getImgSrc(src));
    this.setState({
      showImg: arrs,
      img_flag: true,
    })
  }

  getImages = (str) => {
    return <img src={this.getImgSrc(str)} style={{width: 96, marginRight: 8, cursor: 'pointer'}} onClick={() => {
      this.ShowImage(str)
    }}/>
  }

  onChangeValue = (type, e) => {
    this.setState(({form_data}) => ({
      form_data: {
        ...form_data,
        [type]: e
      }
    }));
  }

  closeModal = () => {
    this.setState({
      modal_flag: false
    })
  }

  getCreateDefaultValue = () => {
    const materielType = this.state.materielType === 1 ? 1 : 6;
    return {
      deduction_volume: 0,
      reason: '泥巴',
      car_num: '',
      end_department_id: this.state.department_id,
      end_id: this.state.instance_id,
      start_time: moment().subtract((1), 'hours').format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment().format('YYYY-MM-DD HH:mm:ss'),
      acquirer_id: store.getState().info.id,
      start_department_id: this.state.department_id,
      car_volumn: 24.00,
      volumn: 24.00,
      materiel_id: materielType
    }
  }

  getSearchKey = (e) => {
    const key = e.target.value;
    let data = [...this.state.dataSource];
    if(key.length > 2){
      data.sort(compare('car_num', key.toUpperCase()));
    }else{
      data.sort(compareByOrder());
    }
    this.setState({ keyword: key, dataSource: data });
  }

  getImgSrc = (src) => {
    if(!src) return src;
    return src.indexOf('http') > -1 ? src :(URLS.FILES + src);
  }
  
  judgeLaYun = () => { // 返回数据  1 - 无初始数据  2 - 未找到匹配的工地数据  3 - 工地无拉运计划 false 在拉运计划内  buildObj 不在拉运计划内 返回工地的数据
    const formData = this.state.form_data;
    const buildId = formData.building_id;
    const buildArr = this.state.buildArr;
    if (!formData || !buildId) return false;//1;
    const buildObj = buildArr.find(({ id }) => id === buildId);
    if (!buildObj) return false;//2;
    const { startTime, ticketTime, endTime } = buildObj;
    if (!startTime || startTime === '' || !endTime || endTime === '') return false;//3;
    const f1 = moment(startTime).subtract(1, 'second').isBefore(ticketTime);
    const f2 = moment(endTime).subtract(-1, 'second').isAfter(ticketTime);
    const flag = f1 && f2
    return flag ? false : buildObj;
  }

  render() {
    const state = {...this.state};
    const write = state.instance_id === 17;
    const borderLeft = !write && {borderLeft: '1px solid black'};
    const borderRight = !write && {borderRight: '1px solid black'};
    // 入倒场-加工商是否确认收获  true - 已确认  false - 未确认
    const confrimUser = (state.form_data.id && state.endConfirmUsers.has(state.form_data.id)) || (state.form_data.end_confirm_username && state.form_data.end_confirm_username !== '');
    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 6},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 14},
      },
    };
    const confirmName = state.form_data.end_confirm_name ? state.form_data.end_confirm_name : state.endConfirmUsers.has(state.form_data.id) ? state.endConfirmUsers.get(state.form_data.id).endConfirmName : null
    const isLy = this.judgeLaYun(); // 是否在拉运计划内

    return (
      <div className="SandStoneReceiver flex flex-column">
        <div className="topSelect" onClick={() => this.setState({modal_flag: true})}>
          <span>当前开票地点是：{state.instance_name}</span>
          <span style={{fontSize: 12}}>点击这里可重新选择</span>
        </div>
        <div className="flex flex-grow">
          <div className="RightSide">
            <div className="RightSearch flex flex-main-between">
              <div className="SearchCar flex flex-cross-end">
                <Button style={{marginLeft: 0}} type="primary"><Link to={{pathname: './create', state: {params: this.getCreateDefaultValue()}}}>{<Icon type={'plus'}/>}补录联单</Link></Button>
                <Button type="primary" style={{marginLeft: 5}} onClick={() => this.export()}>日志导出</Button>
                <Button type="danger" style={{marginLeft: 5}} onClick={() => this.clearDate()}>清除日志</Button>
                <span style={{marginLeft: 5}}>(若搜索不到联单，请点击“补录联单”进行补录)</span>
              </div>
              <div className="flex flex-cross-center">
                <Select placeholder="入倒场-加工商是否收货" allowClear style={{width: 200, marginRight: 10}}
                          onChange={(e) => this.setState({endConfirm: e, page: 1})}>
                  <Option value={1}>加工商已确认</Option>
                  <Option value={2}>加工商未确认</Option>
                </Select>
                <Input placeholder="请输入车牌/工地搜索" onChange={(e) => this.getSearchKey(e)} onPressEnter={() => this.getRightTableData()} />
                <Button type="primary" onClick={() => this.getRightTableData()}>搜索</Button>
              </div>
            </div>
            <div style={{marginBottom: 10}}>
              <span style={{color: '#F95E5A'}}>待确认{state.dataSource.length}单</span>
            </div>
            <Table
              className="table"
              rowKey={"id"}
              onRow={ (record) => {return { onClick: () => {this.TicketClick(record); this.getHistoryBuild(record.id)} }} }
              dataSource={state.dataSource}
              bordered={true}
              columns={this.columns}
              scroll={{x: 1400, y: 600 }}
              pagination={false}
              rowClassName={this.setSelectHrpFactRowClassName}
            />
          </div>
          <div className="LeftSide flex flex-column" id={'LeftSide'}>
            <Spin size="small" spinning={state.receiverSpin}>
              {state.is_acquirer.show ?
                <div style={{textAlign: 'center', color: '#7FC272', marginBottom: 5, fontSize: 18}}>
                  <span style={{fontSize: 22, marginRight: 5 }}>{state.is_acquirer.car_num}</span>
                  <span>{'在' + state.is_acquirer.end_time + '已入库开票'}</span>
                </div> : null}
              <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item label="票号">
                  <Input disabled={true} value={state.form_data.number} />
                </Form.Item>
                <Form.Item label="车牌">
                  <Input disabled={true} style={{width: 170}} value={ state.form_data.car_num } />
                  <span style={{color: !state.form_data.car_type ? '' : (state.form_data.car_type.includes('非名录') || state.form_data.car_type.includes('非合同')) ? '#FE9400' : state.form_data.car_type === '非砂石名录' ? '#FE9400' : state.form_data.car_type === '合同' ? '#4BD863' : '#0079FE'}}>{state.form_data.car_type ? state.form_data.car_type : ''}</span>
                  {/* <span style={{color: state.form_data.car_type === '非名录' ? '#FF3B30' : state.form_data.car_type === '非砂石名录' ? '#FE9400' : state.form_data.car_type === '合同' ? '#4BD863' : '#0079FE'}}>{state.form_data.car_type ? ( state.form_data.car_type + '车') : ''}</span> */}
                </Form.Item>
                <Form.Item label="车牌照片">
                  {state.form_data.pic && state.form_data.pic.length > 0 && state.form_data.pic.indexOf(',') > 0 ?
                    state.form_data.pic.split(',').map((v, i) => (
                      <img key={i} src={this.getImgSrc(v)} style={{width: 96, marginRight: 8, cursor: 'pointer'}}
                        onClick={() => this.ShowImage(state.form_data.pic)} />
                    )) : state.form_data.pic && state.form_data.pic.length > 0 ? this.getImages(state.form_data.pic)
                    : null
                  }
                </Form.Item>
                <Form.Item label="司机">
                  <Input disabled={true} value={state.form_data.driver_name} />
                </Form.Item>
                <Form.Item label="工地">
                  <Select
                    disabled
                    showSearch
                    placeholder="选择工地"
                    value={state.form_data.building_id}
                    optionFilterProp={"filterProp"}
                    onChange={(e, option) => {
                      this.setState({
                        form_data: {
                          ...state.form_data,
                          building_name: option.props.children,
                          building_id: e
                        }
                      })
                    }}
                  >
                    {state.buildArr.map(({id, name, acquirer, pinyin}, i) => (
                      <Option key={i} value={id} filterProp={name + pinyin} className={acquirer ? 'optionsClass' : ''}>{name}</Option>
                    ))}
                  </Select>
                  {state.form_data.has_last_building_name === 1 ?
                    <span style={{fontSize: 12, color: '#ff0000', marginLeft: 10}}>默认显示该运输车上一趟工地项目</span> :
                    ((state.form_data.last_building_name === '无' || state.form_data.last_building_name === '无GPS坐标') && state.form_data.building_id === 0) ?
                      <span style={{fontSize: 12, color: '#ff0000', marginLeft: 10}}>请选择工地项目</span> : ''
                  }
                </Form.Item>
                <Form.Item label="物料">
                <Radio.Group
                  onChange={(e) => {
                    this.setState({
                      form_data: {
                        ...state.form_data,
                        materiel_name: e.target.labelName,
                        materiel_id: e.target.value
                      }
                    })
                  }}
                  value={state.form_data.materiel_id}>
                  {state.materialArr.map(({id, name, }, i) => (
                    <Radio key={id} value={id} labelName={name}>{name}</Radio>
                  ))}
                </Radio.Group>
                </Form.Item>
                <Form.Item label="货箱方量" disabled={true}>
                  <Input value={state.form_data.car_volumn} disabled={true} style={{width: 100}}/>
                  {state.form_data.has_volumn === 0 ?
                    <span style={{fontSize: 12, color: '#ff0000', marginLeft: 10}}>没有找到宏信投核方的方量</span> : ''}
                </Form.Item>
                <Form.Item label="实收方量">
                  <Input disabled={true} value={state.form_data.volume} style={{width: 100}}/>
                </Form.Item>
                <div className="confirms">
                  {!confrimUser ? <div className="nones">入倒场-加工商还未确认收货，请尽快通知确认收货</div> : 
                    <div className="has">{`入倒场-加工商“${confirmName}”已确认收货`}</div>}
                </div>                
                <div className="confirms">
                  {isLy ? (
                    <div className='warns'>
                      <div className="warn">本单不在申报的拉运时段内，请谨慎签收！</div>
                      <div className="warnText">(项目申报今日拉运时段：{isLy.startTime} - {isLy.endTime})</div>
                    </div>
                   ) : 
                    null}
                </div>                
                <div style={{textAlign: 'center'}}>
                  <Button
                    style={{width: '50%'}}
                    type="danger"
                    onClick={() => this.setState({save: true})} htmlType="submit"
                    disabled={!confrimUser || (state.form_data.has === 2 ? true : state.form_data.building_id === 0 ? true : state.confirm)}
                  >确认收货</Button>
                </div>
                <div style={{textAlign: 'center', marginTop: 12}}>
                  <Button
                    style={{width: '50%'}}
                    type="primary"
                    onClick={() => this.setState({save: false})} htmlType="submit"
                    disabled={!confrimUser || (state.form_data.has === 2 ? true : state.form_data.building_id === 0 ? true : state.confirm)}
                  >
                    确认收货并打印
                  </Button>
                </div>
              </Form>
            </Spin>
          </div>
        </div>
        {state.img_flag && <ImagePreviewer images={state.showImg} visiable={state.img_flag} onChange={visiable => this.setState({img_flag: visiable})} />}
        <Modal
          title={'选择实例'}
          visible={state.modal_flag}
          centered={true}
          onCancel={this.closeModal}
          width={400}
          footer={null}
          maskClosable={false}
          destroyOnClose={true}
          bodyStyle={{height: 100, overflow: 'auto'}}
        >
          <div className='flex flex-main-end'>
            <Select placeholder="选择实例" value={state.instance_id} style={{width: 350}} onChange={this.onInstanceChange}>
              {
                state.userInstance.map((v, i) => {
                  return <Option value={v.instance_id} title={v.department_id} materielType={v.materielType} key={i}>{v.instance_name}</Option>
                })
              }
            </Select>
          </div>
        </Modal>
        <object id="LODOP_OB" classID="clsid:2105C259-1E0C-4534-8141-A753534CB4CA" style={{width: 0, height: 0}}>
          <embed id="LODOP_EM" type="application/x-print-lodop" style={{width: 0, height: 0}}></embed>
        </object>
        <div id="print_t1" style={{display: 'none', paddingLeft: 0 }}>
          <div style={{textAlign: 'center', marginTop: 4}}>
            <h4>
              <span className="printTitle" style={{ fontSize: 14, letterSpacing: '5px', fontWeight: 'bolder' }}>成都市瀚宇投资有限公司</span>
            </h4>
            {!write && (
              <h4>
                <span style={{ borderBottom: '4px double black', fontSize: 14, letterSpacing: '5px', fontWeight: 'bolder', marginBottom: 0 }}>{state.instance_name}砂石接管凭证</span>
              </h4>
            )}
          </div>
          <div className="flex" style={{display: 'flex', padding: '0 10px'}}>
            <div className="lj" style={{display: 'inline', width: '69%', fontWeight: 'bold', marginLeft: write ? 40 : 0 }}>{!write && '出料单位：'}{state.printJson.building_name}</div>
            <div className="lj flex-grow" style={{display: 'inline', width: '30%', fontWeight: 'bold'}}>
              <span style={{ paddingLeft: write ? 70 : 10, paddingRight: write ? 15 : 0, display: 'inline-block', boxSizing: 'border-box' }}>{!write && 'NO: '}<span style={{fontSize: !write ? 20 : 16, color: '#D37663'}}>{state.printJson.number}</span></span>
            </div>
          </div>
          <div className="flex" style={{ marginTop: 4, marginLeft: 3, fontSize: 13 }}>
            <table border={ write ? 0 : 1 } cellSpacing="0" cellPadding="5" style={{ borderCollapse: 'collapse', fontSize: 13, tableLayout: 'fixed', textAlign: 'center', width: '99%' }}>
              <tbody>
              <tr>
                <td style={{width: '12.5%' }}><span>车牌</span></td>
                <td style={{width: '12.5%' }}>{state.printJson.car_num}</td>
                <td style={{width: '12.5%' }}><span>车数</span></td>
                <td style={{width: '12.5%' }}>{state.printJson.transport_count}</td>
                <td style={{width: '12.5%' }}><span>物料</span></td>
                <td style={{width: '12.5%' }}>{state.printJson.materiel_name}</td>
                <td style={{width: '12.5%' }}><span>车辆属性</span></td>
                <td style={{width: '12.5%', ...borderLeft }}>{state.printJson.car_type}</td>                
              </tr>
              </tbody>
            </table>
          </div>
          <div className="flex" style={{ marginLeft: 3, fontSize: 13 }}>
            <table border={ write ? 0 : 1 } cellSpacing="0" cellPadding="5" style={{ borderCollapse: 'collapse', fontSize: 13, tableLayout: 'fixed', textAlign: 'center', width: '99%' }}>
              <tbody>
              <tr style={{borderTop: 'none'}}>
                <td style={{width: '12.5%' }}>{!write && `货箱方量(m³)`}</td>
                <td style={{width: '12.5%' }}>{state.printJson.volume && state.printJson.volume.toFixed(2)}</td>
                <td style={{width: '12.5%' }}>{!write && `出场时间`}</td>
                <td style={{width: '12.5%' }}>{state.printJson.startOutTime}</td>
                <td style={{width: '12.5%' }}>{!write && `出场质量认定`}</td>
                <td style={{width: '12.5%' }}>合格</td>
                <td style={{width: '12.5%' }}>{!write && `出场审核人`}</td>
                <td style={{width: '12.5%', ...borderLeft}}>{state.printJson.startConfirmName}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="flex" style={{ marginLeft: 3, fontSize: 13 }}>
            <table border={ write ? 0 : 1 } cellSpacing="0" cellPadding="5" style={{ borderCollapse: 'collapse', fontSize: 13, tableLayout: 'fixed', textAlign: 'center', width: '99%' }}>
              <tbody>
              <tr style={{borderTop: 'none'}}>
                <td style={{width: '12.5%'}}><span>入倒场时间</span></td>
                <td style={{width: '12.5%'}}>{state.printJson.endInTime}</td>
                <td style={{width: '12.5%'}}><span>倒场质量认定</span></td>
                <td style={{width: '12.5%'}}>合格</td>
                <td style={{width: '12.5%'}}><span>入倒场-加工商收货人</span></td>
                <td style={{width: '12.5%'}}>{state.printJson.endConfirmName}</td>
                <td style={{width: '12.5%'}}><span>入倒场-经营部收货人</span></td>
                <td style={{width: '12.5%', ...borderLeft}}>{state.printJson.acquirer_name}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="flex" style={{ marginLeft: 3, fontSize: 13 }}>
            <table border={ write ? 0 : 1 } cellSpacing="0" cellPadding="5" style={{ borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'center', width: '99%', fontSize: 13 }}>
              <tbody>
              <tr style={{borderTop: 'none'}}>
                <td style={{width: '12.5%'}}><span>实际方量(m³)</span></td>
                <td style={{width: '12.5%'}}>{state.printJson.real_volumn && state.printJson.real_volumn.toFixed(2)}</td>
                <td style={{width: '12.5%'}}><span>驾驶员</span></td>
                <td style={{width: '12.5%'}}></td>
                <td style={{width: '12.5%'}}></td>
                <td style={{width: '12.5%'}}></td>
                <td style={{width: '12.5%'}}></td>
                <td style={{width: '12.5%', ...borderLeft}}></td>
              </tr>
              </tbody>
            </table>
          </div>
          {!write && (
            <>
              <div style={{fontSize: 12}}>第一联：存根联（白），第二联：公司财务（红），第三联：移交砂石加工单位（蓝）</div>
              <div style={{fontSize: 12}}>第四联：移交砂石加工单位（绿），第五联：移交砂石单位（黄），第六联：移交砂石单位（灰）</div>
            </>
          )}
        </div>
      {ReactDOM.createPortal(<Spin spinning={state.spinLoading} size={'large'} style={{position: 'absolute', top: 0, margin: 'auto', bottom: 0, left: 0, right: 0, width: 50, height: 50, zIndex: 1111111111}} />, document.getElementById('root'))}
      </div>
    );
  }
});
