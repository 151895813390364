import immutable from 'immutable';

export default {
  areas(areas) {
    return areas.map((area) => ({
      value: area.id,
      label: area.name,
    }));
  },
  sources(sources) {
    return sources.map(source => ({
      value: source.id, //  地点ID
      label: source.name, //  地点名称
      area: source.area_id, //  区域ID
      department: source.department_id, //  部门ID
      lat: parseFloat(source.lat), //  地点经度
      lng: parseFloat(source.lng), //  地点纬度
      address: source.address, //固定源地址
    }));
  },
  plans(plans, sources) {
    return plans.map((plan) => {
      const source = sources.find(({ department, value }) => department === plan.start_department_id && value === plan.start_id);
      if (!source) return false;
      return { plan: plan.id, ...source };
    }).filter(v => v);
  },
  transports(transports) {
    return transports.map(transport => ({
      value: transport.id, // 运企ID
      label: transport.name, // 运企名称
      area: transport.area_id, // 区域ID
      department: transport.department_id, // 部门ID
    }));
  },
  materiels(materiels) {
    return materiels.map(materiel => ({
      value: materiel.id, // 物料ID
      label: materiel.name, // 物料名称
    }));
  },
  cars(cars, id) {
    return cars.map((car, index) => ({
      serial: index + 1, // 序号
      value: car.car_id, // 车辆ID
      label: car.car_num, // 车牌号
      drivers: car.drivers.map(driver => ({ // 司机可选列表
        value: driver.driver_id, // 司机ID
        label: driver.driver_name, // 司机姓名
      })),
      transport: id, // 运企ID
      long: car.car_long, // 车辆长
      width: car.car_width, // 车辆宽
      height: car.car_height, // 车辆高
      volumn: car.car_volumn, // 车辆方量
      isBlack: car.is_black, // 是否属于黑名单
    }));
  },
  docs(docs) {
    // const srcs = [];
    // const mock = index => [{ uid: -1 * (index + 1), status: 'done', url: 'http://ticketapitest.shomes.cn/uploads/20190428/fa36854f90ba2ea13f4738aec656fb2f.png' }];
    const mock = () => [];
    return immutable.OrderedMap(docs.map(({ name, require }, index) => [name, immutable.Map({ require, srcs: mock(index) })]));
  },
  encodeDocs(orderedMap) {
    return JSON.stringify(orderedMap.toArray().map(([name, doc]) => ({
      n: name,
      r: doc.get('require') ? 1 : 0,
      v: (doc.get('srcs') || []).filter(({ status }) => status === 'done').map(({ url, response }) => (url || (response && response.src) || '')).filter(v => v).join(','),
    })));
  },
  decodeDocs(string) {
    return immutable.OrderedMap(JSON.parse(string).map(({ n, v, r }) => [n, immutable.Map({ require: r, srcs: v.split(',').filter(s => s).map((url, index) => ({ uid: -1 * (index + 1), status: 'done', url })) })]));
  },
  newCars(transportIds, cars, transports) {
    let selectCarArr = [];
    const selectTransportsOptions = [...transportIds].map((id) => transports.get(id));
    const selectDriver = [...cars.values()];
    //绑定司机的车辆
    selectTransportsOptions.map((transOption) => {
      [...cars].map((carOption) => transOption.cars.get(carOption[0])).map((item) => {
        //运企所选车辆
        if (item) {
          selectDriver.map((id, index) => {
            //车辆所选司机
            if(item.drivers.has(id)) {
              selectCarArr.push([{
                rowKey: index,
                id: transOption.id,
                type: transOption.type,
                transportName: transOption.name,
                car: [item],
                driver: item.drivers.get(id)
              }]);
            }
          });
        }
      });
    });
    //未绑定司机的车辆
    const noDriverCarArr = [];
    const noDriverCars = [];
    [...cars].map((carOption) => {
      if (!carOption[1]) {
        noDriverCars.push(carOption)
      }
    });
    selectTransportsOptions.map((transOption) => {
      noDriverCars.map((carOption) => transOption.cars.get(carOption[0])).map((item) => {
        if(item) {
          noDriverCarArr.push([{
            id: transOption.id,
            type: transOption.type,
            transportName: transOption.name,
            car: [item],
          }]);
        }
      })
    });
    return [...selectCarArr,...noDriverCarArr];
  },
  newEncodeDocs(orderedMap) {
    return JSON.stringify(orderedMap.map((item) => ({
      n: item[0],
      v: item[1].filter(({ status }) => status === 'done').map(({ url, response }) => (url || (response && response.src) || '')).filter(v => v).join(','),
    })));
  },
  newDecodeDocs(string) {
    let docs = [];
    JSON.parse(string).map(({n,v}) => {
      docs.push({
        name: n,
        src: v.split(',').filter(s => s).map((url, index) => ({ uid: -1 * (index + 1), status: 'done', url })),
      })
    });
    return docs;
  },
};
