// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2-gvxCsG3oTHSUqpP619SO {\n  padding: 3rem;\n}\n._2-gvxCsG3oTHSUqpP619SO ._10lyqYYsWqazs-WMHbqm4y {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 1rem;\n}\n._2-gvxCsG3oTHSUqpP619SO ._10lyqYYsWqazs-WMHbqm4y ._1iou6q3YP1YQjxk-NV8r_8 > *:not(:last-child) {\n  margin-right: 1rem;\n}\n._2-gvxCsG3oTHSUqpP619SO ._10lyqYYsWqazs-WMHbqm4y ._2cda7IRfQJ4Bxz0UafhDZ- {\n  display: flex;\n}\n._2-gvxCsG3oTHSUqpP619SO ._10lyqYYsWqazs-WMHbqm4y ._2cda7IRfQJ4Bxz0UafhDZ- > *:not(:first-child) {\n  margin-left: 1rem;\n}\n._2-gvxCsG3oTHSUqpP619SO ._10lyqYYsWqazs-WMHbqm4y ._2cda7IRfQJ4Bxz0UafhDZ- input.ant-input {\n  min-width: 8rem;\n  max-width: 12rem;\n  width: auto;\n}", ""]);
// Exports
exports.locals = {
	"container": "_2-gvxCsG3oTHSUqpP619SO",
	"query": "_10lyqYYsWqazs-WMHbqm4y",
	"left": "_1iou6q3YP1YQjxk-NV8r_8",
	"right": "_2cda7IRfQJ4Bxz0UafhDZ-"
};
module.exports = exports;
