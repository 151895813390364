import React, { useState, useEffect } from 'react';
import {message, Modal, Radio} from 'antd';
import request from 'utils/request';
import { JudgeTicket, JudgeTicketByShow } from "components/Previewer";
import store from "stores/user";

export default function OverTicket({ arr, m_overDates, ticket_id, ticket_status, ticketTimes, onClose}){
  const roleId = store.getState().roles.id;
  const defaultDate = m_overDates[0];
  const [ticketStatus, setTicketStatus] = useState(ticket_status);
  const [overDate, setOverDate] = useState(defaultDate);
  const [isSelf, setIsSelf] = useState(0);
  const [json, setJson] = useState({
    end_type: 'source',
    ticket_id: ticket_id,
    ticket_status: ticketStatus,
    end_time: overDate,
    instance_name: arr[0].name,
    department_id: arr[0].department_id,
    instance_id: arr[0].id,
    lng: arr[0].value[0],
    lat: arr[0].value[1]
  });

  const overTicket = (json) => {
    request('/web/ticket_map/overTicket', json)
      .then(body => {
        if(body.data.code === 200 ){
          const data = ticketTimes.map(({id, start_time, end_time, ...v}) => ({
            id: id,
            start_time : json.ticket_status === 1 && (id == json.ticket_id) ? json.end_time : start_time,
            end_time : json.ticket_status === 2 && (id == json.ticket_id) ? json.end_time : end_time,
            ...v
          }));
          onClose(data, 1);
          message.success('成功结束联单', 6);
        }else{
          message.warn('结束联单失败');
        }} );
  };

  function onSuccess() {
    if(roleId !== 111 && roleId !== '111'){
      message.warn('对不起，你没有权限修改');
      onClose(ticketTimes);
    }
    if (!overDate || overDate === '') {
      message.warn(`请选择${ticketStatus === 1 ? '起点' : '结束'}时间！`);
    } else{
      const r = JudgeTicket({ overDate, ticket_status: ticketStatus, ticketTimes, ticketId: ticket_id, isSelf });
      if(r){
        overTicket(json);
      }
    }
  }
  return (
    <Modal
      title={'选择修改起点时间或确认在该点结束联单?'}
      width={800}
      bodyStyle={{ maxHeight: '60vh', overflow: 'auto'}}
      destroyOnClose={true}
      visible={true}
      onCancel={() => onClose(ticketTimes)}
      onOk={onSuccess}
    >
      <div>
        <div>
          <Radio.Group value={ticketStatus ? ticketStatus : 1} buttonStyle="solid" onChange={(e) => {setTicketStatus(e.target.value); setJson({...json,ticket_status: e.target.value }) }}>
            <Radio.Button value={1}>起点</Radio.Button>
            <Radio.Button value={2}>终点</Radio.Button>
          </Radio.Group>
          <Radio.Group value={isSelf} buttonStyle="solid" style={{marginLeft: 30}} onChange={(e) => setIsSelf(e.target.value)} >
            <Radio.Button value={0}>非内转联单</Radio.Button>
            <Radio.Button value={1}>内转联单</Radio.Button>
          </Radio.Group>
        </div>
        <div style={{display: 'flex', flexFlow: 'column', marginTop: 10}}>
          <Radio.Group value={overDate} buttonStyle="solid" onChange={(e) => {setOverDate(e.target.value); setJson({...json, end_time: e.target.value})} }>
            { m_overDates.map((v, i) => <Radio.Button value={v} disabled={ (JudgeTicketByShow({overDate: v, ticket_status: ticketStatus, ticketTimes, isSelf, ticketId: ticket_id }))} key={i}>{v}</Radio.Button>
            ) }
          </Radio.Group>
        </div>
        <div style={{marginTop: 10}}>
          <Radio.Group defaultValue={arr[0]} buttonStyle="solid" onChange={(e) => {const va = e.target.value;setJson({...json, instance_name: va.name, department_id: va.department_id, instance_id: va.id, lng: va.value[0], lat: va.value[1]})}}>
            {
              arr.map((v, i) => (<Radio.Button value={v} key={i}>{v.name}</Radio.Button>))
            }
          </Radio.Group>
        </div>
      </div>
    </Modal>
  )
}

