// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ant-select-dropdown-menu {\n  max-height: 450px;\n}\n\n._13QVCQ_HpbddIlBZMnb7Mi {\n  color: #fa2925;\n}", ""]);
// Exports
exports.locals = {
	"optionsClass": "_13QVCQ_HpbddIlBZMnb7Mi"
};
module.exports = exports;
