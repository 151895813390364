import React, { useState, useEffect } from 'react';
import {Input, message, Modal, Radio} from 'antd';
import request from 'utils/request';
import { JudgeTicket } from "components/Previewer";
import store from "stores/user";

export default function OverTicketByPoints({ point, ticket_id, ticket_status, ticketTimes, onClose }){
  const roleId = store.getState().roles.id;
  const [instance_name, setInstanceName] = useState('');
  const [isSelf, setIsSelf] = useState(0);
  const [ticketStatus, setTicketStatus] = useState(ticket_status);
  
  const overTicket = (json) => {
    request('/web/ticket_map/overTicket', json)
      .then(body => {
        if(body.data.code === 200 ){
          const data = ticketTimes.map(({id, start_time, end_time, ...v}) => ({
            id: id,
            start_time : json.ticket_status === 1 && (id == json.ticket_id) ? json.end_time : start_time,
            end_time : json.ticket_status === 2 && (id == json.ticket_id) ? json.end_time : end_time,
            ...v
          }));
          onClose(data, 1);
          onChange(body.data.data)
          message.success('成功结束联单', 6);
        }else{
          message.warn('结束联单失败');
        }} );
  }
  function onSuccess(){
    if(roleId !== 111 && roleId !== '111'){
      message.warn('对不起，你没有权限修改');
      onClose(ticketTimes)
    }
    if (instance_name === '') {
      message.warn('请输入固定源名称！');
      return false
    }
    else {
      const r = JudgeTicket({ overDate: point.time, ticket_status: ticketStatus, ticketTimes, ticketId: ticket_id, isSelf });
      if(r){
        const json = {
          end_type: 'point',
          instance_name: instance_name,
          ticket_status: ticketStatus,
          ticket_id: point.ticket_id,
          end_time: point.time,
          lat: point.value[1],
          lng: point.value[0]
        };
        overTicket(json);
      }
    }
  }
  return (
    <Modal
      title={'选择修改起点时间或确认在该点结束联单?'}
      width={600}
      bodyStyle={{ maxHeight: '60vh', overflow: 'auto'}}
      destroyOnClose={true}
      visible={true}
      onCancel={() => onClose(ticketTimes)}
      onOk={onSuccess}
    >
      <div>
        <div style={{marginBottom: 10}}>
          <Radio.Group value={ticketStatus ? ticketStatus : 1} buttonStyle="solid" onChange={(e) => setTicketStatus(e.target.value) }>
            <Radio.Button value={1}>起点</Radio.Button>
            <Radio.Button value={2}>终点</Radio.Button>
          </Radio.Group>
          <Radio.Group defaultValue={isSelf} buttonStyle="solid" style={{marginLeft: 30}} onChange={(e) => setIsSelf(e.target.value)}>
            <Radio.Button value={0}>非内转联单</Radio.Button>
            <Radio.Button value={1}>内转联单</Radio.Button>
          </Radio.Group>
        </div>        
        <div><span style={{display: 'inline-block', width: 120}}>结束时间: </span><span>{point.time}</span></div>
        {point.type && <div><span style={{display: 'inline-block', width: 120}}>点位类型: </span><span>{point.type}</span></div>}
        <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}><span style={{display: 'inline-block', width: 120}}>输入固定源名称: </span>
          <Input style={{width: 300}} defaultValue={''} onChange={ (e) => setInstanceName(e.target.value) }/>
        </div>
      </div>
    </Modal>
  )
}
