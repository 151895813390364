import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

export default function RangePicker({ value, format = 'YYYY-MM-DD', placeholder, onChange, ...props }) {
  return (
    <DatePicker.RangePicker
      {...props}
      format={format}
      placeholder={placeholder || []}
      value={(value || []).map(item => (item ? moment(item, format) : null))}
      onChange={(m, dateStrings) => onChange(dateStrings.filter(v => v).length > 0 ? dateStrings : undefined)}
    />
  );
}
