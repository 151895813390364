import React, { useEffect, useState } from 'react';
import { Form, message, Modal, Input, Select } from 'antd';
import { dataSource, inputs } from './List';
import request from 'utils/request';
const { Option } = Select;
function getTransport() {
  const [arr, setArr] = useState([]);
  useEffect(() => {
    request('/river/river_base/getRiverTransportOption')
      .then(body => body.data.data)
      .then(setArr)
  }, []);
  return arr;
}

export default function Change({ history, match, location }) {
  const transportArr = getTransport();
  const { id, plate_num, transport_id } = location.state;
  const [value, setValue] = useState(transport_id);
  const back = () => history.goBack();
  const onFinish = () => {
    request('/river/river_project/changeProjectCar', { river_project_car_id: id, transport_id: value })
      .then(body => body.data)
      .then(data => {
        if(data.code === 200) {
          message.success(data.msg);
          dataSource.refresh();
          back();
        }else {
          message.warn(data.msg);
        }
      })
  };

  return (
    <Modal
      title={`变更项目车辆(${plate_num})运企信息`}
      onCancel={back}
      onOk={onFinish}
      okButtonProps={{ disabled: value === transport_id }}
      visible={true}
      centered={true}
    >
      <Form>
        <Form.Item label={'变更运企'} required={true}>
          <Input.Group compact>
            <Select
              placeholder={"请选择运企名称"}
              value={value}
              style={{ width: 300 }}
              onChange={(e) => setValue(e)}
            >
              {transportArr.map(({id, name}) => (
                <Option key={id} value={id} >{name}</Option>
              ))}
            </Select>
          </Input.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}
