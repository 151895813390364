import React from 'react';
import { Section } from 'components/Structure';
import { Table, Button, Popconfirm, Divider } from 'antd';
import ModalFormPage from 'ui/ModalFormPage';
import LinkButton from 'ui/TableCell/LinkButton';
import actions from 'ui/TableCell/actions';
import request from 'utils/request';

export default function ThreeSteps(dataSource, { title, name, indexs, deleteApi, createApi, updateApi }) {
  const columns = indexs.map(({ label, key, render }) => ({
    title: label,
    dataIndex: key,
    render,
  }));

  const inputs = indexs.map(({ render, ...rest }) => rest);

  function Part({ data }) {
    const onDelete = id => () => request(deleteApi, { id }).then(() => dataSource.refresh());
    const cols = [
      ...columns,
      actions({
        width: 150,
        render: ($, { id }) => (
          <>
            <LinkButton to={`./${name}/${id}/update`}>修改</LinkButton>
            <Divider type="vertical" />
            <Popconfirm title="确定删除?" onConfirm={onDelete(id)}>
              <Button type="danger" size="small">删除</Button>
            </Popconfirm>
          </>
        ),
      }),
    ];

    return (
      <Section title={title} buttonName="新增" buttonLink={`./${name}/create`}>
        <div style={{ margin: '32px 0' }}>
          <Table
            bordered
            rowKey="id"
            dataSource={data}
            columns={cols}
            pagination={false}
          />
        </div>
      </Section>
    );
  }

  // eslint-disable-next-line no-shadow
  function createEditor(title, optsGetter) {
    return function Editor(props) {
      const back = () => props.history.goBack();
      const onFinish = () => {
        dataSource.refresh();
        back();
      };

      const opts = optsGetter(props);

      return (
        <ModalFormPage
          {...opts}
          title={title}
          inputs={inputs}
          onCancel={back}
          onFinish={onFinish}
        />
      );
    };
  }

  const Create = createEditor(`新增${title}`, () => ({
    submit: [createApi],
  }));

  const Update = createEditor(`修改${title}`, ({ match: { params: { id } } }) => ({
    init: [updateApi.init, { id }],
    submit: [updateApi.submit, { id }],
  }));

  return [Part, Create, Update];
}
