import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import { dataSource } from './List';
import { message } from 'antd';

export default function Update({ history, match }) {
  const back = () => history.goBack();
  const onFinish = () => {
    message.success('驾驶员信息更新成功');
    dataSource.refresh();
    back();
  };

  const id = match.params.id;

  const inputs = [
    { label: '当前运企', key: 'transport_id', type: 'select', options: '/river/river_base/getRiverTransportOption',  labelKey: 'name', valueKey: 'id', required: true },
    { label: '当前驾驶员姓名', key: 'name', required: true },
    { label: '当前驾驶员电话', key: 'phone', required: true },
    { label: '当前驾驶证号', key: 'cert_code', required: true },
  ];

  return (
    <ModalFormPage
      init={['/river/river_base/editRiverTransportDriverInfo', { river_transport_driver_id: id }]}
      submit={['/river/river_base/updateRiverTransportDriverInfo', { river_transport_driver_id: id }]}
      title="修改驾驶员信息"
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
    />
  );
}
