import React from 'react';
import debug from 'utils/debug';
import ModalFormPage from 'ui/ModalFormPage';
import { dataSource, inputs } from './List';

export default function Update({ history, match }) {
  debug.render('Update');

  const back = () => history.goBack();
  const onFinish = () => {
    dataSource.refresh();
    back();
  };

  const id = match.params.id;

  return (
    <ModalFormPage
      init={['/web/driver/newGetOne', { id }]}
      submit={['/web/driver/newUpdate', { id }]}
      title="修改驾驶员信息"
      inputs={inputs(false)}
      onCancel={back}
      onFinish={onFinish}
    />
  );
}
