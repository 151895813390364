import React, { useState, useEffect } from 'react'
import { Button, Badge, Modal, Input, message } from 'antd'
import ReportForm from './ReportForm'
import request from 'utils/request';

const { TextArea } = Input
const { confirm } = Modal;
/* 上报按钮 */
export default function ReportList({ props }) {
  let { reportListInfo, ReportListInfo, selectedRows, dataSource } = props
  const [visible, setVisible] = useState(false)
  const [textAreaValue, setTextAreaValue] = useState('')
  useEffect(() => { if (!visible) { ReportListInfo({ visible }) } }, [visible])
  useEffect(() => { if (reportListInfo) { setVisible(true) } }, [reportListInfo])
  function handleOk(e) { showConfirm() };
  function handleCancel(e) { setVisible(false); setTextAreaValue('') };
  function showConfirm() {
    if (textAreaValue === '') {
      message.warn('上报描述不能为空')
      return
    }
    confirm({
      title: '确定要上报吗？',
      okText: '确定',
      cancelText: '取消',
      centered: true,
      onOk() {
        let list_ids = []
        selectedRows.forEach(item => {
          list_ids.push(item.id)
        })
        let reason = textAreaValue
        request('/web/gb_list/reportCarAlarm', { list_ids, reason })
          .then(res => {
            if (res.data.code == 200) {
              setVisible(false)
              dataSource.refresh()
              message.success(res.data.msg)
              setTextAreaValue('')
            } else {
              message.warn(res.data.msg)
            }
          })
      },
    });
  }
  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      maskClosable={false}
      width='90%'
    >
      <h1>车辆灰名单期间告警上报扬尘办</h1>
      <ReportForm selectedRows={selectedRows} dataSource={dataSource} />
      <Badge color='blue' dot={true} showZero={true} text='上报描述' />
      <TextArea rows={4} onChange={(e) => { setTextAreaValue(e.target.value) }} value={textAreaValue} />
    </Modal >
  )
}
