import React,{useState} from 'react';
import common from 'utils/common';
import 'components/css/TableClass.global.scss';
import ShowImages from "components/PopoverPage/ShowImages";
import {Form, Row, Col, Input, Button, Icon, Upload, message, Select, DatePicker, InputNumber, Modal} from "antd";
import axios from "axios";
import request from "utils/request";
import {dataSource} from './List';
const Option = Select.Option;
import moment from 'moment';
const dateFormat = 'YYYY-MM-DD';

export default class ShowDetail extends React.PureComponent{
  constructor(props) {
    super(props);
    this.state = {
      history: this.props.history,
      car_num: '',
      data: {id: '', base: []},
      dataImages: [],
      img_flag: false,
      driver_type: 0,
      driver_id: '',
      driver_arr: [],
    }
  }
  componentDidMount() {
    let that = this;
    const {match: {params: {id}}} = this.props;
    request({
      url: '/web/keep_on_record/getInfoById',
      data: {car_num: id}
    }).then((response) => {
      if(response.data.code !== 200){
        message.error('请求错误');
        return false
      }
      let res = response.data.data;
      const dataArr = {
        id: id,
        base:[
          {
            label: '车牌',
            dataIndex: 'car_num',
            value: res.car_num,
            disabled: true
          },
          {
            label: '设备编号',
            dataIndex: 'device_num',
            value: res.device_num,
            disabled: true
          },
          {
            label: '车辆颜色',
            dataIndex: 'color',
            value: res.color,
            disabled: true
          },
          {
            label: '车辆品牌',
            dataIndex: 'brand',
            value: res.brand,
            disabled: true
          },
          {
            label: '燃料类型',
            dataIndex: 'fuel_type',
            value: res.fuel_type,
            disabled: true,
            select: common.fuel_type
          },
          {
            label: '排放标准',
            dataIndex: 'emission_standards',
            value: res.emission_standards,
            disabled: true,
            select: common.emission_standards
          },
          {
            label: '车主姓名',
            dataIndex: 'owner_name',
            value: res.owner_name,
            disabled: true
          },
          {
            label: '车主手机号',
            dataIndex: 'owner_phone',
            value: res.owner_phone,
            disabled: true,
            phone: true
          },
          /*{
            label: '车辆行驶证号',
            dataIndex: 'drive_license_num',
            value: res.drive_license_num,
            disabled: true
          },*/
          {
            label: '道路运输证号',
            dataIndex: 'ship_license_num',
            value: res.ship_license_num,
            disabled: true
          },
          {
            label: '成都市建筑垃圾处置运输证号',
            dataIndex: 'garbage_license_num',
            value: res.garbage_license_num,
            disabled: true
          },
          {
            label: '车辆型号',
            dataIndex: 'car_model',
            value: res.car_model,
            disabled: true,
            // select: common.car_model,
          },
          {
            label: '发动机号',
            dataIndex: 'motor_num',
            value: res.motor_num,
            disabled: true
          },
          {
            label: '车架号',
            dataIndex: 'carriage_num',
            value: res.carriage_num,
            disabled: true
          },
          {
            label: '密闭装置',
            dataIndex: 'obturator',
            value: res.obturator,
            disabled: true,
            select: common.obturator,
          },
          {
            label: '防漏装置',
            dataIndex: 'leakproof',
            value: res.leakproof,
            disabled: true,
            select: common.leakproof,
          },
          {
            label: '货箱内径尺寸-长-米',
            dataIndex: 'inner_long',
            value: res.inner_long,
            disabled: true,
            number: true
          },
          {
            label: '货箱内径尺寸-宽-米',
            dataIndex: 'inner_width',
            value: res.inner_width,
            disabled: true,
            number: true
          },
          {
            label: '货箱内径尺寸-高-米',
            dataIndex: 'inner_height',
            value: res.inner_height,
            disabled: true,
            number: true
          },
          {
            label: ' 核定载重量-吨',
            dataIndex: 'weight',
            value: res.weight,
            disabled: true,
            number: true
          },
          {
            label: '保险公司',
            dataIndex: 'insure_company',
            value: res.insure_company,
            disabled: true
          },
          {
            label: '保险金额（元）',
            dataIndex: 'insure_money',
            value: res.insure_money,
            disabled: true,
            number: true
          },
          {
            label: '保险日期开始',
            dataIndex: 'insure_start_date',
            value: res.insure_start_date,
            disabled: true,
            date: true,
          },
          {
            label: '保险日期截止',
            dataIndex: 'insure_end_date',
            value: res.insure_end_date,
            disabled: true,
            date: true,
          },
          {
            label: '第三者责任险（元）',
            dataIndex: 'third_party_insure',
            value: res.third_party_insure,
            disabled: true,
            number: true
          },
          {
            label: '建筑垃圾处置运输证号图片',
            dataIndex: 'garbage_license_photo',
            value: res.garbage_license_photo,
            disabled: true,
            extra: true,
          },
          {
            label: '车辆行驶证图片',
            dataIndex: 'drive_license_photo',
            value: res.drive_license_photo,
            disabled: true,
            extra: true,
          },
          {
            label: '道路运输证图片',
            dataIndex: 'ship_license_photo',
            value: res.ship_license_photo,
            disabled: true,
            extra: true,
          },
          {
            label: '车辆照片',
            dataIndex: 'car_photo',
            value: res.car_photo,
            disabled: true,
            extra: true,
          }
        ],
      };
      that.setState({
        data: dataArr,
        car_num: id
      })
    });
  }
  checkPhoneAccount = (rule, value, callback) => {
    var re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
    if (value.length === 11 || re.test(value)) {
      callback();
    } else {
      callback('请填写正确的手机号');
    }
  };

  showImages = (val = '') => {
    let images = [];
    if(val.indexOf(',') > -1){
      images = val.split(',');
    }else {
      images.push(val)
    }
    this.setState({
      dataImages: images,
      img_flag: true
    });
  };

  getFields = () => {
    const children = [];
    const state = {...this.state};
    const { getFieldDecorator } = this.props.form;
    state.data.base.map((v, i) => {
      if(!v.hide) {
        if(v.extra){
          if(!!v.value){
            let arr = [];
            let showImgs = [];
            if(v.value.indexOf(',') > -1){
              arr = v.value.split(',')
            }else{
              arr.push(v.value)
            }
            arr.map((v) => {
              if(v.indexOf('http') > -1){
                showImgs.push(v)
              }else{
                showImgs.push(common.pic_url + v)
              }
            });
            children.push(
              <Col span={8} key={i}>
                <Form.Item label={v.label} style={{height: 100}} required={true}>
                  {
                    showImgs.map((item, j) => {
                      return <div style={{display: 'inline-block', marginRight: 13, position: 'relative'}}><img onClick={ () => { this.showImages(item) } } style={{ width: 60, cursor: 'pointer'}} src={item} key={j} alt=""/></div>
                    })
                  }

                </Form.Item>
              </Col>
            );
          }
          else{

          }
        }
        else if(v.number) {
          children.push(
            <Col span={8} key={i}>
              <Form.Item label={v.label}>
                {getFieldDecorator(v.label, {
                  initialValue: v.value,
                  rules: [ {
                    required: true, message: '请填写' + v.label,
                  }],
                })(
                  <InputNumber placeholder={`请填写${v.label}`} style={{width: '100%'}} disabled={v.disabled} />
                )}
              </Form.Item>
            </Col>
          );
        }
        else if(v.select) {
          children.push(
            <Col span={8} key={i}>
              <Form.Item label={v.label}>
                {getFieldDecorator(v.label, {
                  initialValue: v.value,
                  rules: [ {
                    required: true, message: '请选择' + v.label,
                  }],
                })(
                  <Select style={{width: '100%'}} placeholder={`请选择${v.label}`} disabled={v.disabled}>
                    {
                      v.select.map((item, j) => {
                        return <Option key={j} value={item.value} title={i}>{item.label}</Option>
                      })
                    }
                  </Select>
                )}
              </Form.Item>
            </Col>
          );
        }
        else if(v.date){
          children.push(
            <Col span={8} key={i}>
              <Form.Item label={v.label}>
                {getFieldDecorator(v.label, {
                  initialValue: v.value && v.value !== '' ? moment(v.value, dateFormat) : '',
                  rules: [ {
                    required: true, message: '请选择' + v.label,
                  }],
                })(
                  <DatePicker style={{width: '100%'}} disabled={v.disabled} placeholder={`请选择${v.label}`} format={dateFormat} />
                )}
              </Form.Item>
            </Col>
          );
        }
        else{
          if(v.phone) {
            children.push(
              <Col span={8} key={i}>
                <Form.Item label={v.label}>
                  {getFieldDecorator(v.label, {
                    initialValue: v.value,
                    rules: [ {
                      required: true, message: '请填写' + v.label,
                    },{
                      validator: this.checkPhoneAccount,
                    }],
                  })(
                    <Input placeholder={'请填写' + v.label} disabled={v.disabled} />
                  )}
                </Form.Item>
              </Col>
            );
          }
          else{
            children.push(
              <Col span={8} key={i}>
                <Form.Item label={v.label}>
                  {getFieldDecorator(v.label, {
                    initialValue: v.value,
                    rules: [ {
                      required: true, message: '请填写' + v.label,
                    }],
                  })(
                    <Input placeholder={'请填写' + v.label} disabled={v.disabled} />
                  )}
                </Form.Item>
              </Col>
            );
          }
        }
      }
    });
    return children;
  };
  HiddenImages = () => {
    this.setState({
      img_flag: false
    })
  };
  render () {
    const state = {...this.state};
    return (
      <Modal
        title={'查看' + state.car_num + '车辆详情'}
        visible={true}
        onOk={() => state.history.goBack()}
        onCancel={() => state.history.goBack()}
        width={1200}
        height={600}
        footer={null}
        destroyOnClose={true}
        bodyStyle={{height: 600, overflow: 'auto'}}
      >
        <div className='car-table-class'>
          <Form
            className="ant-advanced-search-form"
          >
            <Row gutter={24}>{this.getFields()}</Row>
            <div style={{display: 'flex', justifyContent: 'flex-end' }}>
              <Button style={{marginLeft: 10}} onClick={() => state.history.goBack()}>取消</Button>
            </div>
          </Form>
          {
            state.img_flag ? <ShowImages data={{ data: state.dataImages, width: 480}} callback={this.HiddenImages.bind(this)} /> : null
          }
        </div>
      </Modal>
    )
  }
}
ShowDetail = Form.create({})(ShowDetail);
