import { message } from 'antd';
import request from 'utils/request';

// 给数据添加序号
function defaultTransformer(records, { page, limit }) {
  return records.map((record, index) => {
    const serial = ((page - 1) * limit) + (index + 1);
    return { serial, ...record };
  });
}

export default class DataSource {
  constructor(api, extraParams = {}, transformer = defaultTransformer) {
    this.api = api;
    this.extraParams = extraParams;
    this.transformer = transformer;
    this.listeners = new Set();
    this.params = {};
    this.dataSource = {};
  }

  getParams(params) {
    this.params = params;
  }

  // 订阅数据源
  // 参数：回调函数
  // 返回：订阅ID
  subscribe(listener) {
    this.listeners.add(listener);
    return () => this.unsubscribe(listener);
  }

  // 解订数据源
  // 参数：订阅ID
  unsubscribe(listener) {
    this.listeners.delete(listener);
  }

  // 查询数据
  // 参数: HTTP查询参数 或 无(使用上一次的查询参数，用于实现刷新数据)
  query(params = this.params) {
    // 记录上次的查询参数
    this.params = params;
    console.log('this.params', this.params)
    request(this.api, { ...this.extraParams, ...params })
      .then((body) => {
        if (body.data.code === 150) {
          message.warn(body.data.msg);
          throw new Error(body.data.msg);
        }
        return body.data.data;
      })
      // 数据预处理
      .then(({
        data,
        total,
        current_page: page = params.page,
        limit = params.limit,
        ...rest
      }) => ({
        rest,
        page: parseInt(page, 10),
        limit: parseInt(limit, 10),
        total: parseInt(total, 10),
        data: this.transformer(data, { page, limit, total }),
      }))
      .then((dataSource) => {
        this.dataSource = dataSource;
        // 发布数据给订阅者
        this.listeners.forEach((listener) => {
          listener(this.dataSource);
        });
      })
      .catch(console.log);
  }

  refresh = () => {
    this.query();
  }
}
