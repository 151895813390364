import React from 'react';
import styles from './TrailRadio.scss';
const lastHourArr = [-24, -12, -5, -3, -2, -1];
const nextHourArr = [1, 2, 3, 5, 12, 24];
export default function TrailRadio({ ticketStatus, lastHour, hour, onLastChange, onChange }) {
  function useLastChange(value) {
    if(value === lastHour) {
      if(ticketStatus === 0 || ticketStatus === 1) {
        onLastChange('last');
      }
    } else {
      onLastChange(value);
    }
  }
  function useChange(value) {
    if(value === hour) {
      if(ticketStatus === 0 || ticketStatus === 2) {
        onChange('next');
      }
    } else {
      onChange(value);
    }
  }
  return (
    <div className={styles.radio}>
      <div className={styles.blackRadio}>
        {lastHourArr.map((item) => <span key={item} className={lastHour === item ? styles.active : ''} onClick={() => useLastChange(item)}>{`${item}小时`}</span>)}
      </div>
      <span>0</span>
      <div className={styles.normalRadio}>
        {nextHourArr.map((item) => <span key={item} className={hour === item ? styles.active : ''} onClick={() => useChange(item)}>{`${item}小时`}</span>)}
      </div>
    </div>
  );
}
