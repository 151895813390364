import React from 'react';
import request from 'utils/request';
import {Button, Modal, Row, Col, Icon, Carousel} from "antd";
import styles from './Detail.scss';
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { previewable } from 'components/Previewer';
import common from 'utils/common';
import { CheckCircleTwoTone, HeartTwoTone, SmileTwoTone } from '@ant-design/icons';

class Detail extends React.PureComponent {
  state = {
    data: {},
    imgFlag: false,
    previewImage: ''
  };
  back = () => this.props.history.goBack();
  componentDidMount () {
    request('/web/human_ticket/detail', {
      id: this.props.match.params.id,
    }).then(body => body.data.data).then((data) => {
      this.setState({
        data: data
      });
    });
  }
  getSrc = (src) => {
    const url = src.includes('htpp') ? src : URLS.FILES + src;    
    return url;
  }

  render () {
   
    const detailInfo = this.state.data;
    return (
      <div>
        <Modal
          visible
          width={1300}
          centered
          bodyStyle={{ overflow: 'auto'}}
          title="详情"
          onCancel={this.back}
          onOk={this.back}
          footer={[<Button key="back" onClick={this.back}>关闭</Button>,]}>
          <div className={styles.first}>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>项目名称</Col>
              <Col span={8} className={styles.count}>{detailInfo.start_name}</Col>
              <Col span={4} className={styles.labels}>目的地</Col>
              <Col span={8} className={styles.count}>{detailInfo.end_name}</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>车牌号</Col>
              <Col span={8} className={styles.count}>{detailInfo.plate_num}</Col>
              <Col span={4} className={styles.labels}>所属运企</Col>
              <Col span={8} className={styles.count}>{detailInfo.transport_name}</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>物料类型</Col>
              <Col span={8} className={styles.count}>{detailInfo.material_name}</Col>
              <Col span={4} className={styles.labels}>车辆类型</Col>
              <Col span={8} className={styles.count}>{detailInfo.car_type_text}</Col>              
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>货箱方量</Col>
              <Col span={8} className={styles.count}>{detailInfo.car_directory_detail ? `${detailInfo.car_directory_detail.volumn}方` : '-'}方</Col>
              <Col span={4} className={styles.labels}>车头照片</Col>
                {detailInfo.load_img == '' ? <Col span={4} className={styles.labels}>暂无图片</Col>:
                  <Col style={{color: '#1890ff', cursor: 'pointer'}} span={8} className={styles.labels} onClick={()=>this.props.preview.image(this.getSrc(detailInfo.load_img))}>查看照片</Col>
                }
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              {/* <Col span={4} className={styles.labels}>货箱方量</Col>
              <Col span={8} className={styles.count}>{detailInfo.volume}方</Col> */}
              <Col span={4} className={styles.labels}>货箱物料照片</Col>
                {detailInfo.goods_img == '' ? <Col span={4} className={styles.labels}>暂无图片</Col>:
                  <Col style={{color: '#1890ff', cursor: 'pointer'}} span={8} className={styles.labels} onClick={()=> this.props.preview.image(this.getSrc(detailInfo.goods_img))}>查看照片</Col>
                }
                
              <Col span={4} className={styles.labels}>上传时间</Col>
              <Col span={8} className={styles.count}>{detailInfo.created_time}</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>上传人</Col>
              <Col span={20} className={styles.count}>{detailInfo.created_name}</Col>
            </Row>
          </div>
          <div className={styles.first}>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels} style={{ fontSize: 16, fontWeight: 'bold' }}>量方信息</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={5} className={styles.labels}>初始车辆货箱内径尺寸</Col>
              <Col span={7} className={styles.count}>{!detailInfo.car_directory_detail ? '-' : `${detailInfo.car_directory_detail.inner_long} x ${detailInfo.car_directory_detail.inner_width} x ${detailInfo.car_directory_detail.inner_height}m`}</Col>
              <Col span={5} className={styles.labels}>装载货物后内径高度差</Col>
              <Col span={7} className={styles.count}>{!detailInfo.check_quantities ? '-' : `${detailInfo.check_quantities.hight_diff}m`}</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={5} className={styles.labels}>装载货物后内径尺寸</Col>
              <Col span={7} className={styles.count}>{!detailInfo.check_quantities ? '-' : `${detailInfo.car_directory_detail.inner_long} x ${detailInfo.car_directory_detail.inner_width} x ${(detailInfo.car_directory_detail.inner_height - (!detailInfo.check_quantities ? 0 : detailInfo.check_quantities.hight_diff)).toFixed(2)}m`}</Col>
              <Col span={5} className={styles.labels}>量方方量</Col>
              <Col span={7} className={styles.count}>{!detailInfo.check_quantities ? '-' : `${detailInfo.check_quantities.quantities}方`}</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={5} className={styles.labels}>上传时间</Col>
              <Col span={7} className={styles.count}>{!detailInfo.check_quantities ? '-' : detailInfo.check_quantities.created_at}</Col>
              <Col span={5} className={styles.labels}>量方人</Col>
              <Col span={7} className={styles.count}>{!detailInfo.check_quantities ? '-' : detailInfo.check_quantities.created_by}</Col>
            </Row>
          </div>
          <div className={styles.first}>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels} style={{ fontSize: 16, fontWeight: 'bold' }}>扣方信息</Col>
            </Row>
            {(detailInfo && detailInfo.minus_quantities && detailInfo.minus_quantities.no_minus !== 1) ? (<>
              <Row gutter={32} className={styles.rowStyle}>
                <Col span={4} className={styles.labels}>扣方方量</Col>
                <Col span={8} className={styles.count}>{detailInfo.minus_quantities.minus_quantities}</Col>
                <Col span={4} className={styles.labels}>实收方量</Col>
                <Col span={8} className={styles.count}>{detailInfo.minus_quantities.quantities}</Col>
              </Row>
              <Row gutter={32} className={styles.rowStyle}>
                <Col span={4} className={styles.labels}>扣方原因</Col>
                <Col span={8} className={styles.count}>{detailInfo.minus_quantities.reason}</Col>
                <Col span={4} className={styles.labels}>扣方证据照片</Col>
                {detailInfo.minus_quantities.img === '' ? <Col span={4} className={styles.labels}>暂无图片</Col>:
                  <Col style={{color: '#1890ff', cursor: 'pointer'}} span={8} className={styles.labels} onClick={()=>this.props.preview.image(this.getSrc(detailInfo.minus_quantities.img))}>查看照片</Col>
                }
              </Row>
            </>) : (
              <Row gutter={32} className={styles.rowStyle}>
                <Col span={4} className={styles.labels}>是否扣方</Col>
                <Col span={8} className={styles.count}>{detailInfo.quantities_check_status === 1 ? '-' : '不扣方'}</Col>
              </Row>
            )}            
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>扣方人</Col>
              <Col span={8} className={styles.count}>{!detailInfo.minus_quantities ? '-' : detailInfo.minus_quantities.created_by}</Col>
              <Col span={4} className={styles.labels}>上传时间</Col>
              <Col span={8} className={styles.count}>{!detailInfo.minus_quantities ? '-' : detailInfo.minus_quantities.created_at}</Col>
            </Row>
          </div>
          <div className={styles.first}>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels} style={{ fontSize: 16, fontWeight: 'bold' }}>签收信息</Col>
            </Row>
            {detailInfo.exception_logs ? detailInfo.exception_logs.map(({ reason_text, created_name, created_time, remark  }) => (
              <>                
                <Row gutter={32} className={styles.rowStyle}>
                  <Col span={4} className={styles.labels}>终点签收</Col>
                  <Col span={8} className={styles.count}>
                    <span style={{ color: 'rgb(255, 37, 37)', display: 'flex', alignItems: 'center', gap: 6 }}>
                      <img src={`/images/isno.png`} />
                      {'异常处理'}
                    </span>
                  </Col>                 
                  <Col span={4} className={styles.labels}>{'处理时间'}</Col>
                  <Col span={8} className={styles.count}>{created_time}</Col>                  
                </Row>
                <Row gutter={32} className={styles.rowStyle}>
                  <Col span={4} className={styles.labels}>异常处理原因</Col>
                  <Col span={8} className={styles.count}>{reason_text ? reason_text : '-'}</Col>
                  <Col span={4} className={styles.labels}>处理人</Col>
                  <Col span={8} className={styles.count}>{created_name ? created_name : '-'}</Col>
                </Row>
                <Row gutter={32} className={styles.rowStyle}>
                  <Col span={4} className={styles.labels}>备注</Col>
                  <Col span={20} className={styles.count}>{remark ? remark : '-'}</Col>
                </Row>
                <div style={{ margin: '8px 0', border: '1px solid rgba(34,36,38,.15)' }} />
              </>
            )) : null}
            {(detailInfo.status !== 3) ? <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>终点签收</Col>
              <Col span={8} className={styles.count}>{detailInfo.status === 1 ? '待签收' :
                <span style={{ color: detailInfo.status === 2 ? '#00C77D' : 'rgb(255, 37, 37)', display: 'flex', alignItems: 'center', gap: 6 }}>
                  <img src={detailInfo.status === 2 ? `/images/isok.png` : `/images/isno.png`} />
                  {detailInfo.status === 2 ? '终点已签收' : '异常处理'}
                </span>}
              </Col>
              {detailInfo.status !== 1 ? (<>
                <Col span={4} className={styles.labels}>{detailInfo.status === 2 ? '签收时间' : '处理时间'}</Col>
                <Col span={8} className={styles.count}>{detailInfo.status === 2 ? detailInfo.dealt_time : detailInfo.exception_logs ? detailInfo.exception_logs[detailInfo.exception_logs.length - 1].created_time : '-'}</Col>
              </>) : null}
            </Row> : null}
            {detailInfo.status === 2 && (
              <Row>
                <Col span={4} className={styles.labels}>签收人</Col>
                <Col span={8} className={styles.count}>{detailInfo.dealt_name}</Col>
              </Row>
            )}
            
          </div>
        </Modal>
        
      </div>
    )
  }
}

export default previewable(Detail);
