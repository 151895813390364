import React, { useState, useEffect, useRef } from 'react';
import { Carousel } from 'antd';
import { ImagePreviewer } from 'components/Previewer';

import styles from './TrashPhotos.scss';

// /** 走马灯 */
// let timer = null;
// export function Carousel({ children, arrow = true, dots = false, width }) {
//   const [index, setIndex] = useState(0);
//   const [steps, setSteps] = useState([]);
//   const len = children.length ? children.length : 0;
//   const ref = useRef(null);
//   const [intervalNews, setNews] = useState(null);

//   useEffect(() => {
//     const s = [];
//     for(let i = 0; i < len; i ++) {
//       s.push(i);
//     }
//     setSteps(s);
//   }, [len]);
 
//   function mouseEnter() {
//     clearInterval(timer);
// 	}
// 	function mouseLeave() {
// 		setTimeout(() => timer = setInterval(() => currentIndex(), 1000), 2000); // 延迟2秒后执行scrollMove;
//   }

//   useEffect(() => {
//     if (!timer) return;
//     clearInterval(timer);
//     timer = setInterval(() => currentIndex(), 1000);
//     return() => clearInterval(timer);
//   }, [index]);

//   function currentIndex() {
//     console.log(index, index === len - 1);
//     if (index === 0) {
//       setIndex(len - 1);
//     }
//     else if (index >= len - 1) {
//       setIndex(0);
//     }
//     else { setIndex(index + 1); }
//   }

//   useEffect(() => {
//     if(!ref) return;
//     console.log('dddddddddddddddd')
//     setTimeout(() => timer = setInterval(() => currentIndex(), 1000), 2000); // 延迟2秒后执行scrollMove;
//     return() => clearInterval(timer);
//   }, [ref]);

//   function switchIndex(type){
//     const step = index;
//     let order = 0;
//     if(type === 1) {
//       if(step === 0) {
//         order = len - 1;
//       } else if (step === len - 1) {
//         order = 0;
//       } else order = step - 1;
//     }
//     else {
//       if(step === len - 1) {
//         order = 0;
//       } else if (step === 0) {
//         order = len - 1;
//       } else order = step + 1;
//     }
//     setIndex(order);
//   }

//   return (
//     <div className={styles.slickSlider} style={{ width }}>
//       <div className={styles.slickList} style={{ width }}>
//         <div className={styles.slickTrack} ref={ref} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} style={{width: (width * len), opacity: 1, transform: `translate3d(-${width * index}px, 0px, 0px)`}}>
//           {children && children.map((item, i) => (
//             <div style={{ width }} key={i}>
//               {item}
//             </div>
//           ))}
//         </div>
//       </div>
//       { arrow && (
//         <>
//           <div className={styles.slickLeft} onClick={() => switchIndex(1)}></div>
//           <div className={styles.slickRight} onClick={() => switchIndex(2)}></div>
//         </>
//       )}
//       { dots && (
//         <ul className={styles.slickDots}>
//           {
//             steps.map((i) => (
//               <li className={ i === index ? styles.slickActive : ''} onClick={() => setIndex(i)}>
//                 <button></button>
//               </li>
//             ))
//           }
//         </ul>
//       )}
//     </div>
//   );  
// }

export default function TrashPhotos({ src, preview }) {
  const [imgSrc, setImgSrc] = useState();
  
  function filterPath(path) {
    return path ? path.includes('http') ? path : `${URLS.FILES}/${path}` : '';
  }

  return (
    <React.Fragment>
      <Carousel autoplay autoplaySpeed={1000}>
      {src.map((item, i) => <img key={item} src={filterPath(item)} alt="" onClick={() => setImgSrc(item)} style={{ width: 430 }} />)}
    </Carousel>
    {
      imgSrc ? <ImagePreviewer images={imgSrc} visiable={!!imgSrc} onChange={() => setImgSrc()} />
        : null
    }
    </React.Fragment>
  ); 
}
