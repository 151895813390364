import React from 'react';
import { previewable } from 'components/Previewer/index';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import debug from 'utils/debug';
import actions from 'ui/TableCell/actions';
import moment from 'moment';
import store from 'stores/user';
import LinkButton from 'ui/TableCell/LinkButton';
import {typeArr, reasonArr, statusArr, transportStatus} from '../share';
import {Divider} from "antd";

console.log(store.getState())


export const dataSource = new DataSource('/web/gb_list/getVerifyBillLists');

const penaltyArr = [
  { value: 1, label: '进入灰名单' },
  { value: 2, label: '进入黑名单' },
  { value: 3, label: '空白' },
];
const checkStatus = ['', '运企核实', '坐席审核', '上报成功'];
const penalty_time = [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')];

function List({ preview }) {
  debug.render('List');
  const roleId = store.getState().roles.id; // 101 - 运企  104 - 国投 111 - 坐席
  const area_id = store.getState().info.area_id;
  console.log(store.getState())
  const columns = [
    serial(true),
    { title: '运企名称', dataIndex: 'transport_name' },
    { title: '创建时间', dataIndex: 'created_time' },
    { title: '创建人', dataIndex: 'created_name', render: (v) => v ? v : '系统默认' },
    { title: '当前状态', dataIndex: 'status', render: (v) => checkStatus[v] },
    { title: '处置时间', dataIndex: 'report_time' },
    actions({
      isFixed: true,
      width: width(12),
      render: ($, { id, status }) => (
        <>
         { (roleId === 101 && status === 1) && <> <LinkButton to={`./${id}/deal`} type={'danger'}>处理</LinkButton>  <Divider type="vertical" /> </>}
         { (roleId === 111 && status === 2) && <> <LinkButton to={`./${id}/verify`} type={'primary'}>审核</LinkButton>  <Divider type="vertical" /> </>}
          <LinkButton to={`./${id}/detail`}>详情</LinkButton>
        </>
      ),
    }),
  ];

  const querys = roleId === 111 ? [
    { title: '输入创建人搜索', key: 'keyword', style: { width: 200 } },
    { title: '运企', key: 'transport_id', type: 'select', options: 'web/blacklist/getTransportLists', extraData: { area_id}, valueKey: 'id', labelKey: 'name', style: { width: 250 } },
    { title: '当前状态', key: 'status', type: 'select', options: transportStatus, valueKey: 'value', labelKey: 'label', style: { width: 120 }, },
    { title: ['处罚', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
  ] : roleId === 104 ? [
      { title: '输入创建人搜索', key: 'keyword', style: { width: 200 } },
      { title: '运企', key: 'transport_id', type: 'select', options: 'web/blacklist/getTransportLists', extraData: { area_id}, valueKey: 'id', labelKey: 'name', style: { width: 250 } },
      { title: ['创建', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
    ] :
    [
    { title: '输入创建人搜索', key: 'keyword', style: { width: 200 } },
    { title: '当前状态', key: 'status', type: 'select', options: transportStatus, valueKey: 'value', labelKey: 'label', style: { width: 120 }, },
    { title: ['创建', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
  ];

  const buttons = [
    roleId === 111 && { title: '新增', link: './create' },
    { title: '导出', download: '/web/gb_list/exportVerifyBillLists', extraParams: {role_type} },
  ];

  const role_type = (store && store.getState() && store.getState().roles) ? store.getState().roles.id === 101 ? 0 : store.getState().roles.id === 111 ? 1 : 2 : 1;
  return (
    <TablePage
      dataSource={dataSource}
      extraParams={{ role_type }}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
      defaultQuery={{times: penalty_time}}
      extraTip={'温馨提醒：您的待处理事项3小时未处理，系统自动处理，请您尽快处理'}
    />
  );
}

export default previewable(List);
