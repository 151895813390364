import React from 'react';
import { Route } from 'react-router-dom';
import List from './List';
import Create from './Create';

export default function ({ path }) {
  return (
    <>
      <Route path={path} component={List} />
      <Route path={path + 'create'} component={Create} />
    </>
  )
}
