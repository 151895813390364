/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useCallback } from 'react';
import { Button, PageHeader, Table, Icon, Modal, Radio, Input } from 'antd';
import { previewable } from 'components/Previewer';
import { labels, colors, icons } from 'variables/auditStatus';
import securities from 'variables/securities';
import user from 'stores/user';
import map from 'utils/map';
import request from 'utils/request';

import styles from './RouteDetail.scss';

const Header = ({ children }) => (
  <div className={styles.header}>
    <div className={styles.content}>{children}</div>
  </div>
);

const Section = ({ title, children }) => (
  <div className={styles.section}>
    <div className={styles.title}>{title}</div>
    <div className={styles.content}>{children}</div>
  </div>
);

function RouteDetail({ preview, match, history }) {
  const concrete_plan_id = match.params.id;

  const [data, setData] = useState({});
  const [visiable, setVisiable] = useState(false);
  const [status, setStatus] = useState();
  const [advice, setAdvice] = useState();

  useEffect(() => {
    request('/web/route/getConcretePlanRouteDetails', { concrete_plan_id })
      .then(body => body.data.data)
      .then((planDetail) => {
        const id = planDetail.baseInfo.info.start_id;
        request('/web/Concrete/getInfo', { id })
          .then(body => body.data.data)
          .then((transportInfo) => {
            let planDocs = typeof planDetail.baseInfo.extraDocInfo.content === 'string' ? JSON.parse(planDetail.baseInfo.extraDocInfo.content) : [];
            planDocs.push({ n: '商砼站营业执照', v: (transportInfo.base || {}).business_license_photo });
            planDocs.push({ n: '商砼站道路运输经营许可证', v: (transportInfo.base || {}).road_trans_op_permit });
            planDocs.push({ n: '商砼站法人身份证', v: (transportInfo.base || {}).legal_id_card_photo });
            planDocs = planDocs.map(({ n, v }) => (v || '').split(',').map((src) => {
              // const title = n.indexOf('（') === -1 ? n : n.slice(0, n.indexOf('（'));
              const title = n;
              return { title, src };
            }));

            const routes = planDetail.routes.map(({ id, content, ...route }, index) => {
              const serial = index + 1;
              const waypoints = route.step.map(({ address }) => address).filter(v => v);
              const way = [route.start_address, ...waypoints, route.end_address].join(' → ');
              const dates = [route.transport_start_date, route.transport_end_date].map(ds => ds && ds.slice(0, 10));
              const docs = JSON.parse(content).reduce((final, { n, v }) => {
                return [...final, ...v.split(',').map(src => ({ title: n, src }))];
              }, []);
              const path = map.objs2arrs(map.baidus2amaps(route.step));
              const start = map.baidu2amap({
                lng: route.start_lng,
                lat: route.start_lat,
                name: route.start_address,
              });
              const end = map.baidu2amap({
                lng: route.end_lng,
                lat: route.end_lat,
                name: route.end_address,
              });
              return { id, serial, way, dates, docs, start, path, end };
            });

            const cars = planDetail.carInfo.map((car, index) => {
              const docs = [
                ['车辆行驶证照片', 'drive_license_photo'],
                ['车辆道路运输证照片', 'ship_license_photo'],
                ['车辆前方45°角照片', 'car_photo'],
                ['驾驶员身份证照片', 'id_card_photo'],
                ['驾驶员驾驶证照片', 'driver_license_photo'],
                ['驾驶员从业资格证照片', 'work_license_photo'],
              ].reduce((final, [title, key]) => {
                return [...final, ...(car[key] || '').split(',').map(src => ({ title, src }))];
              }, []);
              return { ...car, index, docs };
            });

            setData({
              initialed: true,
              advice: planDetail.auditStatus.record,
              // 审核状态
              status: planDetail.auditStatus.status,
              // 基本信息
              base: {
                ...planDetail.baseInfo.info,
                // 各种文件
                docs: planDocs,
                security: transportInfo.security,
              },
              // 运输路线
              // routes: planDetail.routes.map(({ content, ...route }) => ({ ...route, content: JSON.parse(content) })),
              routes,
              cars,
            });
          });
      });
  }, []);

  const mapRef = useCallback((node) => {
    if (!node) return;
    const map = new window.AMap.Map(node, { mapStyle: 'amap://styles/macaron' });
    const colors = ['#EF476F', '#FFD166', '#06D6A0', '#E71D36', '#118AB2', '#E55934', '#073B4C'];
    data.routes.forEach(({ start, path, end }, index) => {
      map.add(new window.AMap.Polyline({
        path,
        strokeColor: colors[index],
        strokeWeight: 6,
        lineJoin: 'bevel',
        lineCap: 'round',
        showDir: true,
      }));
      map.add([start, end].map(location => new window.AMap.Marker({
        position: new window.AMap.LngLat(location.lng, location.lat),
        label: {
          content: location.name,
          direction: 'center',
          offset: new window.AMap.Pixel(0, -35),
        },
      })));
    });
    map.setFitView();
  }, [data]);

  function updateStatus() {
    request('/web/plan/updateConcretePlanAuditStatus', { concrete_plan_id, status, advice: advice || ' ' }).then(() => {
      setVisiable(false);
      history.goBack();
    });
  }

  if (!data.initialed) return null;

  const cars = [
    { title: '车牌号', dataIndex: 'car_num' },
    { title: '车主', dataIndex: 'owner_name' },
    { title: '驾驶员', dataIndex: 'name' },
    { title: '驾驶证号码', dataIndex: 'id_card' },
    { title: '准驾车型', dataIndex: 'type' },
    { title: '联系电话', dataIndex: 'phone' },
    { title: '相关文件', dataIndex: 'docs', width: 80, align: 'center', render: docs => <Icon className={styles.docsIcon} type="folder-open" onClick={() => preview.image(docs)} /> },
  ];

  const routes = [
    { title: '序号', dataIndex: 'serial', width: 46, align: 'center' },
    { title: '途经点', dataIndex: 'way' },
    { title: '起止时间', dataIndex: 'dates', width: 100, align: 'center', render: ([start, end]) => <span><span>{start}</span><br /><span>{end}</span></span> },
    { title: '相关文件', dataIndex: 'docs', width: 80, align: 'center', render: docs => <Icon className={styles.docsIcon} type="folder-open" onClick={() => preview.image(docs)} /> },
  ];

  // 仅公安交警在审核中状态时可见
  const auditable = user.getState().roles.id === 103;
  const unsubmitable = status === undefined || (status === 3 && !advice);

  return (
    <div className={styles.container}>
      <Header>
        <PageHeader onBack={history.goBack} title="线路详情" />
        {auditable && <Button size="small" type="primary" onClick={() => setVisiable(true)}>审核</Button>}
      </Header>
      <Section title="当前状态"><div className={styles.status} style={{ color: colors[data.status], borderColor: colors[data.status] }}><Icon type={icons[data.status]} /><span>{labels[data.status]}</span></div></Section>
      <Section title="工地名称">{data.base.start_name}</Section>
      <Section title="商砼站名称">{data.base.start_name}</Section>
      <Section title="运输时间">{[data.base.start_date, data.base.end_date].map(ds => ds && ds.slice(0, 10)).join(' 至 ')}</Section>
      <Section title="相关文件">
        <div className={styles.files}>
          {data.base.docs.map((doc, index) => (
            <div className={styles.file} key={index} onClick={() => preview.image(doc)}>
              <div className={styles.image} style={{ backgroundImage: `url(${doc[0].src})` }} />
              <div className={styles.name}>{doc[0].title}</div>
            </div>
          ))}
        </div>
      </Section>
      <Section title={<p>运输企业现场的<br />安全管理人员名单</p>}>
        <Table bordered pagination={false} size="small" rowKey="id" columns={securities} dataSource={data.base.security} />
      </Section>
      <Section title="运输路线">
        <div style={{ width: '100%' }}>
          <div className={styles.map} ref={mapRef} />
          <Table bordered pagination={false} size="small" rowKey="id" columns={routes} dataSource={data.routes} />
        </div>
      </Section>
      <Section title="车辆信息">
        <Table bordered pagination={false} size="small" rowKey="index" columns={cars} dataSource={data.cars} />
      </Section>
      {(data.status === 3 || data.status === 4) && (
        <Section title="审核意见">
          <div className={styles.advice}>{data.advice}</div>
        </Section>
      )}

      {auditable && (
        <Modal
          centered
          width={800}
          title="路线审核"
          visible={visiable}
          onCancel={() => setVisiable(false)}
          okButtonProps={{ disabled: unsubmitable }}
          onOk={updateStatus}
        >
          <Radio.Group className={styles.radio} value={status} onChange={e => setStatus(e.target.value)}>
            <Radio value={4}>通过</Radio>
            <Radio value={3}>不通过</Radio>
          </Radio.Group>
          <Input.TextArea value={advice} onChange={e => setAdvice(e.target.value)} placeholder="如果审核不通过，请分别详细说明需修改的部分" autosize={{ minRows: 10, maxRows: 20 }} />
        </Modal>
      )}
    </div>
  );
}

export default previewable(RouteDetail);
