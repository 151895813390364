import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import debug from 'utils/debug';

import { dataSource } from './List';

const inputs = [
  { placeholder: '请输入删除原因', key: 'deleted_reason', type: 'textarea', autosize: { minRows: 10, maxRows: 12 }, required: true },
];

export default function Delete({ history, match }) {
  debug.render('Delete');

  const back = () => history.goBack();
  const onFinish = () => {
    dataSource.refresh();
    back();
  };

  const id = match.params.id;

  return (
    <ModalFormPage
      submit={['/web/ticket/deleted', { id }]}
      title="删除电子联单"
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
      modalProps={{
        width: 800,
      }}
    />
  );
}
