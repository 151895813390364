import React from 'react';
import { Route } from 'react-router-dom';
import LocationPicker from 'components/Map/LocationPicker';
import Plans from './Plans';
import PlanEditor from './PlanEditor';
import Cars from './Cars';

// / 方案列表
// /create 新增方案
// /:planID/update 修改方案
// /:planID/cars/ 方案关联的车辆列表
// /:planID/cars/carID/update 修改一辆车辆方量
// /:planID/cars/update-records 方案下所有车辆的所有方量修改记录

export default ({ path }) => (
  <>
    <Route path={path} component={Plans} />
    <Route path={path + 'create'} component={PlanEditor} />
    <Route path={path + ':id/update'} component={PlanEditor} />
    <Route path={path + ':id/cars/'} component={Cars} />
    <Route path={path + ':id/location'} component={LocationPicker('/web/Instance/getBuildingCoordinateById', '/web/Instance/saveBuildingCoordinate')} />
  </>
);
