import React from 'react';
import { Link } from 'react-router-dom';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';

import serial from 'ui/TableCell/serial';

import debug from 'utils/debug';
import store from 'stores/user';

export const dataSource = new DataSource('/web/car/sandCarDirectoryList');



export default function SandCehicleList() {
  debug.render('SandCehicleList');
  const {info: {area_id, area_name}} = store.getState();
  const areaName = area_name.slice(0, area_name.length - 1);
  const directories = [
    { value: 0, label: '全部' },
    { value: 1, label: `${areaName}名录` },
    { value: 2, label: `非${areaName}名录` },
  ];
  const columns = [
    serial(true),
    { title: '运企名称', dataIndex: 'transport_name' },
    { title: '车牌号', dataIndex: 'car_num' },
    { title: `是否${areaName}名录`, dataIndex: 'directory', render: $ => $ === 1 ? '是' : '否' },
    { title: '核定方量', dataIndex: 'volumn', render: ($, { id, car_num }) => <Link to={`./${id}/volumn-editor?car_num=${car_num}`}>{$}</Link> },
    { title: '方量最近修改时间', dataIndex: 'volumn_record_time' },
    { title: '方量最近修改人', dataIndex: 'volumn_record_user_name' },
  ];

  const querys = [
    { title: '车牌号', key: 'keyword' },
    { title: '运企', key: 'transport_id', type: 'select', options: '/web/Instance/getTransportList', valueKey: 'id', labelKey: 'name', showSearch: true, allowClear: true, style: { width: 300 } },
    { title: `是否${areaName}名录`, key: 'directory', type: 'select', options: directories, allowClear: true, style: { width: 200 } },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}
