import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { logOut } from 'actions/user';
import request from 'utils/request';
import store from 'stores/user';
import ModalFormPage from 'ui/ModalFormPage';

/**
 * 判断是否是强密码
 * 强密码：大于八位数且包含大小写字母+数字+特殊符号的三种以上
 * @params psd: string的密码
 * @params len: 强密码最低长度，默认为8位
 * @returns 0 - 长度不匹配 1 - 满足一个  2 - 满足两个。。。。
 */

export function judgeStrongPassword(psd, len = 8) {
  const lowerTest = /[a-z]/; // 小写字母的正则表达
  const capitalTest = /[A-Z]/; // 大写字母的正则表达
  const numberText = /[0-9]/; // 数字的正则表达
  const specialTest = /[!@#$%^*()_.]/; // 特殊符号的正则表达
  let num = 0;
  let lcontinuity = 0, conStr = '';
  if (lowerTest.test(psd)) num += 1;
  if (capitalTest.test(psd)) num += 1;
  if (numberText.test(psd)) num += 1;
  if (specialTest.test(psd)) num += 1;
  if (psd.length < len) num = 10;
  for (let i = 1; i < psd.length; i++) {
    if (psd[i] === psd[i - 1]) {
      conStr = psd[i - 1] + psd[i];
      num = 11;
      break;
    }
    if(((psd.charCodeAt(i))-(psd.charCodeAt(i-1)) === 1)||((psd.charCodeAt(i))-(psd.charCodeAt(i - 1)) === -1)){ //1正序连贯；-1倒序连贯
      // lcontinuity += 1; //存在连贯：计数+1
      num = 12;
      conStr = psd[i - 1] + psd[i]
      // return;
      break;
    }; 
  }
  // if (lcontinuity > 0) num = 12;
  return [num, conStr];
}

/** 是否需要强制修改密码 */
export default function IsModifyPassword({ history, match }) {
  const user = store.getState();
  const onFinish = (data) => {   
    const { old_password, new_password, confirmPassword } = data;
    if (!Object.is(new_password, confirmPassword)) {
      return message.warning('密码与确认密码须一致！！');
    }
    const [num, str] = judgeStrongPassword(new_password);
    if (num === 12) return message.warning(`不能使用连续字符${str}`);
    if (num === 11) return message.warning(`不能使用连续重复字符${str}`);
    if (num === 10) return message.warning('新密码必须大于等于八位');
    if (num < 3) return message.warning('新密码必须为强密码！');
    request('/web/User/saveNewPassword', {
      old_password: md5(old_password),
      new_password: md5(new_password),
    }).then(body => body.data).then(({ code, msg }) => {
      if (code !== 200) {
        message.error(msg, 1);
      } else {
        message.success('修改密码成功，请重新登陆', 1);
        logOut();
      }
    });
  };
  const [isModify, setIsModify] = useState();
  useEffect(() => {
    if (!user.password) return;
    const [judgeNum] = judgeStrongPassword(user.password);
    if ([10, 11, 12].includes(judgeNum) || judgeNum < 3) {
      return setIsModify(judgeNum);
    } 
  }, []);
  const inputs = [
    { key: 'old_password', label: '旧密码', type: 'password', placeholder: '请输入旧密码', required: true },
    { key: 'new_password', label: '新密码', type: 'password', placeholder: '请输入新密码', required: true },
    { key: 'confirmPassword', label: '确认重置密码', type: 'password', placeholder: '请输入确认重置密码', required: true },
    { key: 'remark', label: '备注', type: 'label',value: '<div style="color: red; font-size: 12px">当前密码为非强密码，请修改密码后重新登录<br />强密码必须长度大于等于八位且至少含有大小写字母、数字、特殊符号三种类型且不能使用连续字符和连续重复字符</div>' }
  
  ]
  return isModify ? (
    <ModalFormPage
      title="修改密码"
      inputs={inputs}
      onCancel={() => {}}
      onFinish={onFinish}
      style={{ zIndex: 99999 }}
    />
  ) : null;
}