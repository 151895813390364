/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { Upload, Icon, message } from 'antd';
import { upload } from 'utils/request';

function prevent(e) {
  e.preventDefault();
  e.stopPropagation();
}

function fileListToValue(fileList) {
  return fileList.filter(({ status }) => status === 'done').map(({ url, response }) => (url || response.src)).join(',');
}
function FilterUrl(url) {
  const src = url.indexOf('data:') > -1 ? url : url.indexOf('http') > -1 ? url : URLS.FILES + url;;
  return src;
}
function valueToFileList(value) {
  return value.split(',').map(url => ({ url: url, thumbUrl: FilterUrl(url), uid: FilterUrl(url), status: 'done' }));
}

export default function Uploader({ value, onChange, placeholder, listType = 'picture-card', maxUploadLength, maxLength, disabled, ...rest }) {
  const [fileList, setFileList] = useState([]);

  // 不受控,value只用于初始化fileList
  useEffect(() => {
    if (!value || typeof value !== 'string') return;
    setFileList((fileList) => {
      if (fileListToValue(fileList) === value) return fileList;
      return valueToFileList(value);
    });
  }, [value]);

  function onFileListChange({ fileList }) {
    const files = [...fileList];
    const len = files.length;
    if(len > maxLength) files.splice(0, 1);
    console.log('files', files)
    setFileList([...files]);
    onChange(fileListToValue(files));
  }
  const nextable = maxUploadLength ? fileList.length < maxUploadLength : true;
  const preventer = nextable ? undefined : prevent;

  return (
    <Upload
      {...rest}
      listType={listType}
      fileList={fileList}
      onChange={onFileListChange}
      disabled={disabled || fileList.length >= maxUploadLength}
      onPreview={(file) => {
        console.log(file, file.thumbUrl);
        if(file.response) return window.open(FilterUrl(file.response.src))
        window.open(FilterUrl(file.url))
      }}
      customRequest={({
        file,
        filename,
        onSuccess,
        onError,
      }) => {
        const formData = new FormData();
        formData.append(filename, file);
        upload(formData).then((res) => {
          const { data: src, msg } = res.data || {};
          if (res.status === 200 && src) {
            onSuccess({ src, thumbUrl: src });
          } else {
            onError(msg);
          }
        }).catch(console.error);
      }}
    >
      {placeholder || (
        <div onMouseDown={preventer} onMouseUp={preventer} onClick={preventer} style={{ cursor: nextable ? 'pointer' : 'not-allowed'}}>
          <Icon type="plus" style={{ fontSize: 32, color: '#999' }} />
          <div style={{ marginTop: 8, color: '#666' }}>上传</div>
        </div>
      )}
    </Upload>
  );
}
