import React, { useState, useEffect } from 'react';
import styles from './Deal.scss';
import Uploader from '../Uploader';
import { Modal, Table, Input, message } from 'antd';
import {UseInterval, CountTime} from './Preview';
import request from 'utils/request';
import { updateBadge } from 'actions/user';
import { dataSource } from './List';
import { typeArr } from '../share';

const columns = [
  { title: '序号', dataIndex: 'serial' },
  { title: '处罚', dataIndex: 'deal_result', render: (v) => (<span style={{ color: '#D9001B' }}>{v === 1 ? '进入灰名单' : '进入黑名单'}</span>) },
  { title: '车牌', dataIndex: 'car_number' },
  { title: '是否温江目录', dataIndex: 'is_car_directory', render: (v) => (<span>{ (v === 1 || v === '1') ? '是' : '否'}</span>) },
  { title: '关联驾驶员', dataIndex: 'driver_name' },
  { title: '事由', dataIndex: 'reason', width: 300, render: (v) => typeArr[v] },
  { title: '备注', dataIndex: 'remark', width: 300 },
];

function dealVerifyBill(id){
  const [data, setData] = useState([]);
  const [info, setInfo] = useState({});
  useEffect(() => {
    request('/web/gb_list/dealVerifyBill', { bill_id: id })
      .then(body => body.data.data)
      .then(data => {
        setData(data.cars.map((v, i) => ({ serial: (i + 1), ...v})));
        setInfo(data.info);
      })
  }, [id])
  return [data, info]
}

export default function Deal({ path, match, history }) {
  const { params: {id} } = match;
  const splitTime = 6;
  const [data, info] = dealVerifyBill(id);
  const [remainingTime, setRemainingTime] = useState(CountTime(info.time, splitTime));
  const [selectedRow, setSelectedRow] = useState([]);
  const [fileList, setFileList] = useState('');
  const [reason, setReason] = useState('');

  const back = () => history.goBack();

  UseInterval(() => { setRemainingTime(CountTime(info.time, splitTime)) }, 1000);

  const onSuccess = () => {
    const json = {
      bill_id: id,
      bill_car_ids: selectedRow,
      images: fileList,
      reason: reason
    };
    request('/web/gb_list/commitVerifyBill', { ...json })
      .then(data => {
        if(data.data.code === 200){
          message.success('申诉成功，请等待坐席审核');
          dataSource.refresh();
          updateBadge();
          back();
        }else{
          message.warn(data.data.msg)
        }
      })
  };

  const onSelectChange = (rows) => {
    setSelectedRow(rows)
  };

  const rowSelection = {
    selectedRow,
    selectedRowKeys: selectedRow,
    onChange: onSelectChange,
    selections: []
  };
  const AppealDeal = () => {
    const flag = selectedRow.length;
    return (
      <div className={styles.appeal}>
        <span className={flag && styles.active}>{flag ? '申诉已勾选' : '申诉请勾选'}</span>
        <label>{ flag ? '您只有1次申诉机会，请认真填写申诉材料' : '对进入灰名单有异议，您可以进行申诉'}</label>
      </div>
    )
  };
  const disabled = selectedRow.length === 0 ? false : (fileList === '' || reason === '');
  return (
    <Modal
    title={'车辆违规事由核实'}
    visible={true}
    destroyOnClose={true}
    maskClosable={false}
    width={1600}
    bodyStyle={{ height: '70vh', overflow: 'auto'}}
    okText={'提交'}
    onCancel={back}
    onOk={onSuccess}
    okButtonProps={{disabled}}
    >
      <div className={styles.tipPart}>
        <p>温馨提醒：6小时未处理，系统自动处理，据处理结束时间还剩<span>{remainingTime}</span></p>
        <div>
          <span>创建时间：{info.time}</span>
          <span>创建人员：{!info.operator ? '系统默认' : info.operator}</span>
        </div>
      </div>
      <Table rowSelection={rowSelection} columns={columns} dataSource={data} rowKey={'id'} scroll={{ y: 280 }} pagination={false} />
      <AppealDeal />
      {
        selectedRow.length > 0 && (
          <div className={styles.evidenceAppeal}>
            <div>
              <span>证据文件<label>(最多上传5条)</label></span>
              <div>
                <span>请上传证据文件：</span>
                <Uploader value={fileList} maxUploadLength={5} style={{width: 350, height: 150}} onChange={(e) => setFileList(e)} />
              </div>
            </div>
            <div>
              <span>申诉理由</span>
              <div>
                <Input.TextArea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="请输入申诉理由"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </div>
            </div>
          </div>
        )
      }
      <span className={styles.tipClass}>未勾选车辆默认您无异议，将自动确认进入灰名单</span>
    </Modal>
  )
}
