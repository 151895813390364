import React from 'react';
import request from 'utils/request';
import common from 'utils/common';
import 'components/css/TableClass.global.scss';
import ShowImages from "components/PopoverPage/ShowImages";

import TransPortCarShowMsg from "components/PopoverPage/TransPortCarShowMsg";
import TransPortCarEditMsg from "components/PopoverPage/StationCarEditMsg";
import AddStationCarMsg from "components/PopoverPage/AddFormMsg";
import {Table, Button, Input, DatePicker, Modal, message, Popconfirm, Icon} from 'antd';

import styles from "../../modules/ui/Query.scss";
import {Link} from "react-router-dom";
import Text from "../../modules/ui/FormItem/Text";

const RangePicker = DatePicker.RangePicker;

const start_time = new Date() - 24 * 60 * 60 * 1000;
const end_time = new Date();
export default class StationVehicleInformation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      keyword: '',
      start_time: [common.ToFormatDate(start_time, 'YYYY-MM-DD HH:mm:ss'), common.ToFormatDate(end_time, 'YYYY-MM-DD HH:mm:ss')],
      currentPage: 1,
      limit: 10,
      totalRow: 1,
      dataImages: [],
      img_flag: false,
      detailData: {
        base: [
          {
            label: '车牌',
            dataIndex: 'car_num',
            value: '',
            disabled: false
          },
          {
            label: '设备编号',
            dataIndex: 'device_num',
            value: '',
            disabled: false
          },
          {
            label: '车辆颜色',
            dataIndex: 'color',
            value: '',
            disabled: false
          },
          {
            label: '车辆品牌',
            dataIndex: 'brand',
            value: '',
            disabled: false
          },
          {
            label: '燃料类型',
            dataIndex: 'fuel_type',
            value: '',
            disabled: false,
            select: common.fuel_type,
          },
          {
            label: '排放标准',
            dataIndex: 'emission_standards',
            value: '',
            disabled: false,
            select: common.emission_standards,
          },
          {
            label: '车主姓名',
            dataIndex: 'owner_name',
            value: '',
            disabled: false
          },
          /*{
            label: '车辆行驶证号',
            dataIndex: 'drive_license_num',
            value: '',
            disabled: false
          },*/
          {
            label: '道路运输证号',
            dataIndex: 'ship_license_num',
            value: '',
            disabled: false
          },
          {
            label: '车辆型号',
            dataIndex: 'car_model',
            value: '',
            disabled: false,
            // select: common.car_model
          },
          {
            label: '发动机号',
            dataIndex: 'motor_num',
            value: '',
            disabled: false
          },
          {
            label: '车架号',
            dataIndex: 'carriage_num',
            value: '',
            disabled: false
          },
          {
            label: '核定载重量-吨',
            dataIndex: 'weight',
            value: '',
            disabled: false,
            number: true
          },
          {
            label: '方量',
            dataIndex: 'volumn',
            value: '',
            disabled: false,
            number: true
          },
          {
            label: '保险公司',
            dataIndex: 'insure_company',
            value: '',
            disabled: false
          },
          {
            label: '保险金额（元）',
            dataIndex: 'insure_money',
            value: '',
            disabled: false,
            number: true
          },
          {
            label: '保险日期开始',
            dataIndex: 'insure_start_date',
            value: '',
            disabled: false,
            date: true,
          },
          {
            label: '保险日期截止',
            dataIndex: 'insure_end_date',
            value: '',
            disabled: false,
            date: true,
          },
          {
            label: '第三者责任险（元）',
            dataIndex: 'third_party_insure',
            value: '',
            disabled: false,
            number: true
          },
          {
            label: '车辆行驶证图片',
            dataIndex: 'drive_license_photo',
            value: '',
            disabled: false,
            extra: true,
          },
          {
            label: '道路运输证图片',
            dataIndex: 'ship_license_photo',
            value: '',
            disabled: false,
            extra: true,
          },
          {
            label: '车辆照片',
            dataIndex: 'car_photo',
            value: '',
            disabled: false,
            extra: true,
          }
        ],
        driver: [
          [
            {
              label: '姓名',
              dataIndex: 'name',
              value: '',
              disabled: false,
            },
            {
              label: '电话',
              dataIndex: 'phone',
              value: '',
              disabled: false,
            },
            {
              label: '身份证号',
              dataIndex: 'id_card',
              value: '',
              disabled: false,
            }, {
              label: '准驾车型',
              dataIndex: 'type',
              value: '',
              disabled: false,
            },
            {
              label: '从业资格证号',
              dataIndex: 'work_license_num',
              value: '',
              disabled: false,
            },
            /*{
              label: '驾驶证号',
              dataIndex: 'driver_license_num',
              value: '',
              disabled: false,
            },*/
            {
              label: '民族',
              dataIndex: 'nation',
              value: '',
              disabled: false,
            },
            {
              label: '籍贯',
              dataIndex: 'birth_address',
              value: '',
              disabled: false,
            },
            {
              label: '常住地',
              dataIndex: 'address',
              value: '',
              disabled: false,
            },
            {
              label: '备注',
              dataIndex: 'remark',
              value: '',
              disabled: false,
            },
            {
              label: '照片',
              dataIndex: 'image',
              value: '',
              extra: true,
              disabled: false,
            },
            {
              label: '身份证图片',
              dataIndex: 'id_card_photo',
              value: '',
              extra: true,
              disabled: false,
            },
            {
              label: '驾驶证照片',
              dataIndex: 'driver_license_photo',
              value: '',
              extra: true,
              disabled: false,
            },
            {
              label: '从业资格证号图片',
              dataIndex: 'work_license_photo',
              value: '',
              extra: true,
              disabled: false,
            },

            {
              label: '近期体检表图片',
              dataIndex: 'physical_form',
              value: '',
              disabled: true,
              extra: false,
            }
          ]
        ]
      },
      edit_flag: false,
      add_flag: false,
      show_flag: false,
      columns: [
        {
          title: '序号',
          type: 'index',
          dataIndex: 'index',
          key: '序号',
          width: 70,
        }, {
          title: '车牌号',
          dataIndex: 'car_num',
          key: 'car_num',
          width: 120
        }, {
          title: '设备编号',
          dataIndex: 'device_num',
          key: 'device_num',
          width: 150
        }/*, {
          title: '所属运企',
          dataIndex: 'company_name',
          key: 'company_name',
          width: 200
        }*/, {
          title: '车主姓名',
          dataIndex: 'owner_name',
          key: 'owner_name',
          width: 100
        }, {
          title: '车主电话',
          dataIndex: 'owner_phone',
          key: 'owner_phone',
          width: 160
        }, {
          title: '车辆型号',
          dataIndex: 'car_model',
          key: 'car_model',
          width: 120
        }, {
          title: '车辆颜色',
          dataIndex: 'color',
          key: 'color',
          width: 160
        }, {
          title: '车架号',
          dataIndex: 'carriage_num',
          key: 'carriage_num',
          width: 160
        }, {
          title: '保险公司',
          dataIndex: 'insure_company',
          key: 'insure_company',
          width: 160
        }, {
          title: '核定载重量(吨)',
          dataIndex: 'weight',
          key: 'weight',
          width: 140,
          number: true
        }, {
          title: '方量',
          dataIndex: 'volumn',
          key: 'volumn',
          width: 120,
          number: true
        }, {
          title: '车辆图片',
          dataIndex: 'car_photo',
          key: 'car_photo',
          width: 120,
          render: (text, record) => {
            if (text === '' || text === null) {
              return <span>暂无图片</span>
            } else {
              return <span style={{ color: '#1491fb', cursor: 'pointer' }} onClick={() => { this.showImages(text) }}>查看图片</span>
            }
          }
        }, {
          title: '车辆行驶证图片',
          dataIndex: 'drive_license_photo',
          key: 'drive_license_photo',
          width: 180,
          render: (text, record) => {
            if (text === '' || text === null) {
              return <span>暂无图片</span>
            } else {
              return <span style={{ color: '#1491fb', cursor: 'pointer' }} onClick={() => { this.showImages(text) }}>查看图片</span>
            }
          }
        }, {
          title: '道路运输证图片',
          dataIndex: 'ship_license_photo',
          key: 'ship_license_photo',
          width: 180,
          render: (text, record) => {
            if (text === '' || text === null) {
              return <span>暂无图片</span>
            } else {
              return <span style={{ color: '#1491fb', cursor: 'pointer' }} onClick={() => { this.showImages(text) }}>查看图片</span>
            }
          }
        }, {
          title: '操作',
          dataIndex: 'actions',
          key: 'actions',
          width: 200,
          fixed: 'right',
          render: (text, record) => {
            return (
              <div className='data-handle'>
                <span onClick={() => { this.EditMsg(record.id, record.car_num) }}>编辑</span>
                <span onClick={() => { this.ShowDetailMsg(record.id, record.car_num) }}>详情</span>
                <Popconfirm placement="top" title={'确认删除该商砼车辆?'} onConfirm={() => { this.DeleteMsg(record.id, record.car_num) }} okText="Yes" cancelText="No">
                  <span>删除</span>
                </Popconfirm>

              </div>
            )
          }
        }],
      modal_title: ''
    }
  };
  AddStationCar = () => {
    const dataArr = {
      base: [
        {
          label: '车牌',
          dataIndex: 'car_num',
          value: '',
          disabled: false
        },
        {
          label: '设备编号',
          dataIndex: 'device_num',
          value: '',
          disabled: false
        },
        {
          label: '车辆颜色',
          dataIndex: 'color',
          value: '',
          disabled: false
        },
        {
          label: '车辆品牌',
          dataIndex: 'brand',
          value: '',
          disabled: false
        },
        {
          label: '燃料类型',
          dataIndex: 'fuel_type',
          value: '',
          disabled: false,
          select: common.fuel_type,
        },
        {
          label: '排放标准',
          dataIndex: 'emission_standards',
          value: '',
          disabled: false,
          select: common.emission_standards,
        },
        {
          label: '车主姓名',
          dataIndex: 'owner_name',
          value: '',
          disabled: false
        },
        {
          label: '车主电话',
          dataIndex: 'owner_phone',
          value: '',
          disabled: false,
          phone: true
        },
        /*{
          label: '车辆行驶证号',
          dataIndex: 'drive_license_num',
          value: '',
          disabled: false
        },*/
        {
          label: '道路运输证号',
          dataIndex: 'ship_license_num',
          value: '',
          disabled: false
        },
        {
          label: '车辆型号',
          dataIndex: 'car_model',
          value: '',
          disabled: false,
          // select: common.car_model
        },
        {
          label: '发动机号',
          dataIndex: 'motor_num',
          value: '',
          disabled: false
        },
        {
          label: '车架号',
          dataIndex: 'carriage_num',
          value: '',
          disabled: false
        },
        {
          label: '核定载重量-吨',
          dataIndex: 'weight',
          value: '',
          disabled: false,
          number: true
        },
        {
          label: '方量',
          dataIndex: 'volumn',
          value: '',
          disabled: false,
          number: true
        },
        {
          label: '保险公司',
          dataIndex: 'insure_company',
          value: '',
          disabled: false
        },
        {
          label: '保险金额（元）',
          dataIndex: 'insure_money',
          value: '',
          disabled: false,
          number: true
        },
        {
          label: '保险日期开始',
          dataIndex: 'insure_start_date',
          value: '',
          disabled: false,
          date: true,
        },
        {
          label: '保险日期截止',
          dataIndex: 'insure_end_date',
          value: '',
          disabled: false,
          date: true,
        },
        {
          label: '第三者责任险（元）',
          dataIndex: 'third_party_insure',
          value: '',
          disabled: false,
          number: true
        },
        {
          label: '车辆行驶证图片',
          dataIndex: 'drive_license_photo',
          value: '',
          disabled: false,
          extra: true,
        },
        {
          label: '道路运输证图片',
          dataIndex: 'ship_license_photo',
          value: '',
          disabled: false,
          extra: true,
        },
        {
          label: '车辆照片',
          dataIndex: 'car_photo',
          value: '',
          disabled: false,
          extra: true,
        }
      ],
      driver: [
        [
          {
            label: '姓名',
            dataIndex: 'name',
            value: '',
            disabled: false,
          },
          {
            label: '电话',
            dataIndex: 'phone',
            value: '',
            disabled: false,
          },
          {
            label: '身份证号',
            dataIndex: 'id_card',
            value: '',
            disabled: false,
          }, {
            label: '准驾车型',
            dataIndex: 'type',
            value: '',
            disabled: false,
          },
          {
            label: '从业资格证号',
            dataIndex: 'work_license_num',
            value: '',
            disabled: false,
          },
          /*{
            label: '驾驶证号',
            dataIndex: 'driver_license_num',
            value: '',
            disabled: false,
          },*/
          {
            label: '民族',
            dataIndex: 'nation',
            value: '',
            disabled: false,
          },
          {
            label: '籍贯',
            dataIndex: 'birth_address',
            value: '',
            disabled: false,
          },
          {
            label: '常住地',
            dataIndex: 'address',
            value: '',
            disabled: false,
          },
          {
            label: '备注',
            dataIndex: 'remark',
            value: '',
            disabled: false,
          },
          {
            label: '照片',
            dataIndex: 'image',
            value: '',
            extra: true,
            disabled: false,
          },
          {
            label: '身份证图片',
            dataIndex: 'id_card_photo',
            value: '',
            extra: true,
            disabled: false,
          },
          {
            label: '驾驶证照片',
            dataIndex: 'driver_license_photo',
            value: '',
            extra: true,
            disabled: false,
          },
          {
            label: '从业资格证号图片',
            dataIndex: 'work_license_photo',
            value: '',
            extra: true,
            disabled: false,
          },

          {
            label: '近期体检表图片',
            dataIndex: 'physical_form',
            value: '',
            disabled: true,
            extra: false,
          }
        ]
      ]
    };
    let that = this;
    that.setState({
      modal_title: '新增商砼车辆',
      detailData: dataArr,
      add_flag: true,
    });
  };
  getInitData = (e) => {
    let that = this;
    let json = {
      keyword: that.state.keyword,
      // start_time: that.state.start_time,
      limit: e ? e.pageSize : that.state.limit,
      page: e ? e.current : that.state.currentPage
    };
    let dataSource = [];
    request({
      url: '/web/concrete_car/getList',
      data: json
    }).then((res) => {
      /*if (res.data.data.data.length === 0) {
        return false
      }*/
      dataSource = res.data.data.data.map((row, index) => ({
        key: row.id,
        index: (index + 1) + (e.current - 1) * e.pageSize,
        ...row,
      }));
      that.setState({
        dataSource: dataSource,
        totalRow: res.data.data.total,
        limit: e.pageSize,
        currentPage: e.current
      })
    })
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState({
      limit: pageSize,
      currentPage: current
    })
    this.getInitData({pageSize: pageSize, current: current});
  };
  inputChange = (e) => {
    e.persist();
    this.setState({
      keyword: e.target.value
    })
  };
  dateChange = (date, dateString) => {
    this.setState({
      start_time: dateString
    })
  };
  showImages = (val) => {
    let images = [];
    if (val.indexOf(',') > -1) {
      images = val.split(',');
    } else {
      images.push(val)
    }
    this.setState({
      dataImages: images,
      img_flag: true
    });
  };
  EditMsg = (id, name) => {
    let that = this;
    this.getInfoById(id, (data) => {
      that.setState({
        modal_title: '修改' + name + '车辆信息',
        detailData: data,
        edit_flag: true,
      });
    })
  };
  getInfoById = (id, callback) => {
    request({
      url: '/web/concrete_car/getInfoById',
      data: { id: id }
    }).then((response) => {
      if (response.data.code !== 200) {
        message.error('请求错误');
        return false
      }
      let data = response.data.data;
      let res = data.base;
      const dataArr = {
        id: id,
        base: [
          {
            label: '车牌',
            dataIndex: 'car_num',
            value: res.car_num,
            disabled: true
          },
          {
            label: '设备编号',
            dataIndex: 'device_num',
            value: res.device_num,
            disabled: false
          },
          {
            label: '车辆颜色',
            dataIndex: 'color',
            value: res.color,
            disabled: false
          },
          {
            label: '车辆品牌',
            dataIndex: 'brand',
            value: res.brand,
            disabled: false
          },
          {
            label: '燃料类型',
            dataIndex: 'fuel_type',
            value: res.fuel_type,
            disabled: false,
            select: common.fuel_type
          },
          {
            label: '排放标准',
            dataIndex: 'emission_standards',
            value: res.emission_standards,
            disabled: false,
            select: common.emission_standards
          },
          {
            label: '车主姓名',
            dataIndex: 'owner_name',
            value: res.owner_name,
            disabled: false
          },
          {
            label: '车主电话',
            dataIndex: 'owner_phone',
            value: res.owner_phone,
            disabled: false,
            phone: true
          },
          /*{
            label: '车辆行驶证号',
            dataIndex: 'drive_license_num',
            value: res.drive_license_num,
            disabled: false
          },*/
          {
            label: '道路运输证号',
            dataIndex: 'ship_license_num',
            value: res.ship_license_num,
            disabled: false
          },
          {
            label: '车辆型号',
            dataIndex: 'car_model',
            value: res.car_model,
            disabled: false,
            // select: common.car_model
          },
          {
            label: '发动机号',
            dataIndex: 'motor_num',
            value: res.motor_num,
            disabled: false
          },
          {
            label: '车架号',
            dataIndex: 'carriage_num',
            value: res.carriage_num,
            disabled: false
          },
          {
            label: '核定载重量-吨',
            dataIndex: 'weight',
            value: res.weight,
            disabled: false,
            number: true
          },
          {
            label: '方量',
            dataIndex: 'volumn',
            value: res.volumn,
            disabled: false,
            number: true
          },
          {
            label: '保险公司',
            dataIndex: 'insure_company',
            value: res.insure_company,
            disabled: false
          },
          {
            label: '保险金额（元）',
            dataIndex: 'insure_money',
            value: res.insure_money,
            disabled: false,
            number: true
          },
          {
            label: '保险日期开始',
            dataIndex: 'insure_start_date',
            value: res.insure_start_date,
            disabled: false,
            date: true,
          },
          {
            label: '保险日期截止',
            dataIndex: 'insure_end_date',
            value: res.insure_end_date,
            disabled: false,
            date: true,
          },
          {
            label: '第三者责任险（元）',
            dataIndex: 'third_party_insure',
            value: res.third_party_insure,
            disabled: false,
            number: true
          },
          {
            label: '车辆行驶证图片',
            dataIndex: 'drive_license_photo',
            value: res.drive_license_photo,
            disabled: false,
            extra: true,
          },
          {
            label: '道路运输证图片',
            dataIndex: 'ship_license_photo',
            value: res.ship_license_photo,
            disabled: false,
            extra: true,
          },
          {
            label: '车辆照片',
            dataIndex: 'car_photo',
            value: res.car_photo,
            disabled: false,
            extra: true,
          }
        ],
        driver: []
      };
      data.driver.map((v, i) => {
        dataArr.driver.push(
          [
            {
              label: '姓名',
              dataIndex: 'id',
              value: v.id,
              disabled: true,
              hide: true
            },
            {
              label: '姓名',
              dataIndex: 'name',
              value: v.name,
              disabled: true,
            },
            {
              label: '电话',
              dataIndex: 'phone',
              value: v.phone,
              disabled: true,
            },
            {
              label: '身份证号',
              dataIndex: 'id_card',
              value: v.id_card,
              disabled: true,
            }, {
              label: '准驾车型',
              dataIndex: 'type',
              value: v.type,
              disabled: true,
            },
            {
              label: '从业资格证号',
              dataIndex: 'work_license_num',
              value: v.work_license_num,
              disabled: true,
            },
            /*{
              label: '驾驶证号',
              dataIndex: 'driver_license_num',
              value: v.driver_license_num,
              disabled: true,
            },*/
            {
              label: '民族',
              dataIndex: 'nation',
              value: v.nation,
              disabled: true,
            },
            {
              label: '籍贯',
              dataIndex: 'birth_address',
              value: v.birth_address,
              disabled: true,
            },
            {
              label: '常住地',
              dataIndex: 'address',
              value: v.address,
              disabled: true,
            },
            {
              label: '备注',
              dataIndex: 'remark',
              value: v.remark,
              disabled: true,
            },
            {
              label: '照片',
              dataIndex: 'image',
              value: v.image,
              extra: true,
              disabled: true,
            },
            {
              label: '身份证图片',
              dataIndex: 'id_card_photo',
              value: v.id_card_photo,
              extra: true,
              disabled: true,
            },
            {
              label: '驾驶证照片',
              dataIndex: 'driver_license_photo',
              value: v.driver_license_photo,
              extra: true,
              disabled: true,
            },

            {
              label: '从业资格证号图片',
              dataIndex: 'work_license_photo',
              value: v.work_license_photo,
              extra: true,
              disabled: true,
            },

            {
              label: '近期体检表图片',
              dataIndex: 'physical_form',
              value: v.physical_form,
              disabled: true,
              extra: true,
            }
          ]
        )
      });
      callback(dataArr)
    })
  };
  ShowDetailMsg = (id, name) => {
    let that = this;
    this.getInfoById(id, (data) => {
      that.setState({
        modal_title: '查看' + name + '车辆详情',
        detailData: data,
        show_flag: true,
      });
    })
  };
  DeleteMsg = (id, car_num) => {
    let that = this;
    request({
      url: '/web/concrete_car/deleteInfo',
      data: { id: id }
    }).then((res) => {
      if (res.data.code === 200) {
        message.success('删除成功');
        that.setState({
          currentPage: 1
        })
        that.getInitData({ current: 1, pageSize: that.state.limit });
      }
    })
  };
  HiddenImages = () => {
    this.setState({
      dataImages: [],
      img_flag: false,
    });
  };
  hideModal = () => {
    this.setState({
      detailData: [],
      edit_flag: false,
      show_flag: false,
      add_flag: false,
    });
  };
  editTransCarData = (data) => {
    let that = this;
    let postData = {
      id: data.id,
    };
    data.base.map((v) => {
      postData[v.dataIndex] = v.value
    })
    request({
      url: '/web/concrete_car/updateInfo',
      data: postData
    }).then((res) => {
      if (res.data.code === 200) {
        message.success('修改成功');
        that.setState({
          edit_flag: false
        });
        that.getInitData({ current: that.state.currentPage, pageSize: that.state.limit });
      }
      else {
        message.success(res.data.msg);
      }
    })
  };
  addTransCarData = (data) => {
    let that = this;
    let postData = {};
    data.base.map((v) => {
      postData[v.dataIndex] = v.value
    });
    request({
      url: '/web/concrete_car/createInfo',
      data: postData
    }).then((res) => {
      if (res.data.code === 200) {
        message.success('新增成功');
        that.setState({
          add_flag: false,
          currentPage: 1
        });
        that.getInitData({ current: 1, pageSize: that.state.limit });
      }
      else {
        message.success(res.data.msg);
      }
    })
  };
  getSearchData = () => {
    this.setState({
      currentPage: 1
    })
    this.getInitData({ current: 1, pageSize: this.state.limit, })
  }
  BindDriverInfo = (driver_id, concrete_car_id, cb) => {
    let that = this;
    request({
      url: '/web/concrete_car/bindOneDriver',
      data: { driver_id: driver_id, concrete_car_id: concrete_car_id }
    }).then(res => {
      message.success('绑定司机成功');
      that.getInfoById(concrete_car_id, function (data) {
        that.setState({
          modal_title: '修改' + name + '车辆信息',
          detailData: data,
          edit_flag: true,
        });
        cb(that.state.detailData)
      })
    })
  };
  // 司机 - 车辆解除绑定
  DeleteCarDriverMsg = (car_num, car_id, driver_id, driver_name, cb) => {
    let that = this;
    request({
      url: '/web/concrete_car/unBindOneDriver',
      data: { car_num: car_num, driver_id: driver_id }
    }).then(res => {
      message.success('解除' + driver_name + '司机成功');
      that.getInfoById(car_id, function (data) {
        that.setState({
          detailData: data,
          edit_flag: true,
        });
        cb(that.state.detailData)
      });
    });
  };
  componentDidMount() {
    this.getInitData({ current: this.state.currentPage, pageSize: this.state.limit });
  }

  render() {
    const state = { ...this.state };
    return (
      <div className="car-table-part">
        <div className="table-class flex flex-main-between" style={{display: 'flex', justifyContent: 'space-between'}}>
          <Button type = 'default' size = 'default' onClick={() => { this.AddStationCar() }}><Icon type={'plus'} />新增</Button>
          <div className="flex flex-main-end search-part">
            <Input.Group  compact>
              <Input  placeholder='请输入车牌搜索' onChange={this.inputChange} />
              <Button size = 'default' type="primary" style={{margin: 0}} onClick={() => { this.getSearchData() }}><Icon type="search" /></Button>
            </Input.Group>
          </div>
        </div>
        <Table style={{ height: 'calc(100% - 50px)' }} bordered={true} columns={state.columns} dataSource={state.dataSource}
          pagination={{
            current: state.currentPage,
            pageSize: state.limit,
            total: state.totalRow,
            showSizeChanger: state.limit,
            onShowSizeChange: () => this.onShowSizeChange,
            showTotal: () => { return `共${state.totalRow}条` }
          }}

          onChange={this.getInitData}
        />
        {
          state.img_flag ? <ShowImages data={{ data: state.dataImages, width: 480 }} callback={this.HiddenImages.bind(this)} /> : null
        }
        <Modal
          title={state.modal_title}
          visible={state.show_flag}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          width={1200}
          height={600}
          footer={null}
          destroyOnClose={true}
          bodyStyle={{ height: 600, overflow: 'auto' }}
        >
          <TransPortCarShowMsg data={{ data: state.detailData }} onCancel={this.hideModal.bind(this)} />{/*, dataArr: dataArr*/}
        </Modal>
        <Modal
          title={state.modal_title}
          visible={state.edit_flag}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          width={1200}
          height={600}
          footer={null}
          destroyOnClose={true}
          maskClosable={false}
          bodyStyle={{ height: 600, overflow: 'auto' }}
        >
          <TransPortCarEditMsg data={{ data: state.detailData }} editTransCarData={this.editTransCarData.bind(this)} onCancel={this.hideModal.bind(this)} BindDriverInfo={this.BindDriverInfo.bind(this)} DeleteCarDriverMsg={this.DeleteCarDriverMsg.bind(this)} />
        </Modal>
        <Modal
          title={state.modal_title}
          visible={state.add_flag}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          width={1200}
          height={600}
          footer={null}
          maskClosable={false}
          destroyOnClose={true}
          bodyStyle={{ height: 600, overflow: 'auto' }}
        >
          <AddStationCarMsg data={{ data: state.detailData }} addTransCarData={this.addTransCarData.bind(this)} onCancel={this.hideModal.bind(this)} />{/*, dataArr: dataArr*/}
        </Modal>
      </div>
    )
  }
}

