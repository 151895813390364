// import download from 'downloadjs';
import authorize from './transformers/authorize';
import data2form from './transformers/data2form';

export default function open(url, data = {}, isImage = false) {
  if (isImage) {
    // download(url);
    window.open(url);
  } else {
    // eslint-disable-next-line no-undef
    const uri = URLS.DATA + url;
    const params = data2form(authorize(data));
    window.open([uri, params].filter(v => !!v).join('?'));
  }
}
