/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { Upload, Icon, message } from 'antd';
import { upload } from 'utils/request/index';
import {ImagePreviewer} from "components/Previewer";

function prevent(e) {
  e.preventDefault();
  e.stopPropagation();
}

function fileListToValue(fileList) {
  return fileList.filter(({ status }) => status === 'done').map(({ url, response }) => (url || response.src)).join(',');
}

function valueToFileList(value) {
  return value.split(',').map(url => ({ url, thumbUrl: url, uid: url, status: 'done' }));
}

export default function Uploader({ value, onChange, listType = 'picture-card', maxUploadLength, disabled, ...rest }) {
  const [fileList, setFileList] = useState([]);
  const [img_flag, setImgFlag] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  // 不受控,value只用于初始化fileList
  useEffect(() => {
    if (!value || typeof value !== 'string') return;
    setFileList((fileList) => {
      if (fileListToValue(fileList) === value) return fileList;
      return valueToFileList(value);
    });
  }, [value]);

  function onFileListChange({ fileList }) {
    setFileList([...fileList]);
    onChange(fileListToValue(fileList));
  }
  console.log(fileList)
  const nextable = fileList.length < maxUploadLength;
  const preventer = nextable ? undefined : prevent;

  function onPreview({file_url, ...data}) {
    console.log(file_url)
    const src = file_url ? file_url : data.response.src;
    if(src.indexOf('.pdf') > -1 || src.indexOf('.xls') > -1 || src.indexOf('.xlsx') > -1 || src.indexOf('.doc') > -1 || src.indexOf('.docx') > -1 || src.indexOf('.txt') > -1){
      window.open(src)
    }
    else{
      setImgSrc(src);
      setImgFlag(true);
    }
  }

  return (
    <>
      {/*<Upload
        {...rest}
        listType={listType}
        fileList={fileList}
        onChange={onFileListChange}
        onPreview = {onPreview}
        previewFile = {(file) =>  {
          return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
            method: 'POST',
            body: file,
          })
            .then(res => res.json())
            .then(({ thumbnail }) => thumbnail);
        }}
        customRequest={({
          file,
          filename,
          onSuccess,
          onError,
        }) => {
          const formData = new FormData();
          formData.append(filename, file);
          upload(formData).then((res) => {
            const { data: src, msg } = res.data || {};
            if (res.status === 200 && src) {
              onSuccess({ src, thumbUrl: src });
            } else {
              onError(msg);
            }
          }).catch(console.error);
        }}
      >
        <div onMouseDown={preventer} onMouseUp={preventer} onClick={preventer} style={{ cursor: nextable ? 'pointer' : 'not-allowed'}}>
          <Icon type="plus" style={{ fontSize: 22, color: '#F59A23' }} />
        </div>
      </Upload>*/}
      <Upload.Dragger
        {...rest}
        fileList={fileList}
        onChange={onFileListChange}
        onPreview = {onPreview}
        previewFile = {(file) =>  {
          return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
            method: 'POST',
            body: file,
          })
            .then(res => res.json())
            .then(({ thumbnail }) => thumbnail);
        }}
        customRequest={({
                          file,
                          filename,
                          onSuccess,
                          onError,
                        }) => {
          const formData = new FormData();
          formData.append(filename, file);
          upload(formData).then((res) => {
            const { data: src, msg } = res.data || {};
            if (res.status === 200 && src) {
              onSuccess({ src, thumbUrl: src });
            } else {
              onError(msg);
            }
          }).catch(console.error);
        }}
      >
        {/*<p className="ant-upload-hint" style={{padding: '0 30px'}}>
          支持文件类型：图片、WORD、EXCEL、PDF
        </p>*/}
        <div onMouseDown={preventer} onMouseUp={preventer} onClick={preventer} style={{padding: '15px 30px', cursor: nextable ? 'pointer' : 'not-allowed'}}>
          <Icon type="plus" style={{ fontSize: 22, color: '#F59A23' }} />
          <p className="ant-upload-hint">
            支持文件类型：图片、WORD、EXCEL、PDF
          </p>
        </div>
      </Upload.Dragger>
      {
        img_flag ? <ImagePreviewer images={ imgSrc } visiable={img_flag} onChange={visiable => setImgFlag(visiable)} />
          : null
      }
    </>
  );
}
