/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Route } from 'react-router-dom';
import { Modal } from 'antd';
import { previewable } from 'components/Previewer';
import RoutesPreview from 'components/Map/RoutesPreview';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';

import LinkButton from 'ui/TableCell/LinkButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';

import debug from 'utils/debug';
import request from 'utils/request';

import styles from './SettlementReports.scss';

export const dataSource = new DataSource('/web/settlement/settlementIndex');

function List() {
  debug.render('List');

  const columns = [
    serial(true),
    { title: '联单编号', dataIndex: 'number', width: width(7), fixed: 'left' },
    { title: '项目', dataIndex: 'plan_name' },
    { title: '运企', dataIndex: 'transport_name' },
    { title: '车辆', dataIndex: 'car_num', width: width(7) },
    { title: '起点', dataIndex: 'start_name' },
    { title: '开始时间', dataIndex: 'start_time', width: width(10) },
    { title: '终点', dataIndex: 'end_name' },
    { title: '结束时间', dataIndex: 'end_time', width: width(10) },
    { title: '物品', dataIndex: 'materiel_name', width: width(8) },
    { title: '运费单价（元）', dataIndex: 'unit_price', width: width(8) },
    { title: '方量', dataIndex: 'volumn', width: width(4) },
    { title: '得分', dataIndex: 'score', width: width(4) },
    { title: '运费总价（元）', dataIndex: 'total_price', width: width(8) },
    { title: '运营费单价（元）', dataIndex: 'service_price', width: width(8) },
    { title: '运营费总价（元）', dataIndex: 'service_total_price', width: width(8) },
    actions({
      title: '结算要件',
      isFixed: true,
      render: ($, { id }) => (
        <LinkButton to={`./${id}/detail`}>查看</LinkButton>
      ),
    }),
  ];

  const querys = [
    { title: '关键字', key: 'keyword' },
    { title: '项目', key: 'plan_id', type: 'select', options: '/web/settlement/getPlanName', valueKey: 'plan_id', labelKey: 'plan_name', showSearch: true, style: { width: 200 } },
    { title: '物品', key: 'materiel_id', type: 'select', options: '/web/route/initMateriel', valueKey: 'id', labelKey: 'name', style: { width: 120 } },
    { title: ['开始', '时间'], key: 'time', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
  ];

  const buttons = [
    { title: '导出', download: '/web/settlement/settlementIndexExcel' },
  ];

  return (
    <TablePage
      rowKey="id"
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
      defaultQuery={{
        time: [
          moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
          moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        ],
      }}
    />
  );
}

function Photos({ title, srcs = [], onPreview }) {
  return (
    <div className={styles.photos}>
      <div className={styles.title}>{title}:</div>
      <div className={styles.items}>
        {srcs.map((src, key) => (
          <img key={key} style={{ backgroundImage: `url('${src}')` }} onClick={() => onPreview(src)} />
        ))}
      </div>
    </div>
  );
}

function Detail({ history, match, preview }) {
  debug.render('Detail');
  const [data, setData] = useState(null);

  useEffect(() => {
    debug.effect('Detail');
    request('/web/settlement/accountDetail', { id: match.params.id }).then(body => setData(body.data.data));
  }, []);

  function onPreview(src) {
    preview.image(src);
  }

  console.log(JSON.stringify(data, null, 2));

  return (
    <Modal
      visible
      centered
      footer={null}
      title="结算要件详情"
      onCancel={() => history.goBack()}
      bodyStyle={{
        maxHeight: '80vh',
        overflow: 'auto',
        borderRadius: '0 0 4px 4px',
      }}
    >
      {data && (
        <>
          <Photos onPreview={onPreview} title="工地进场照片" srcs={data.building_in} />
          <Photos onPreview={onPreview} title="工地出场照片" srcs={data.building_out} />
          <Photos onPreview={onPreview} title="消纳场进场照片" srcs={data.consum_in} />
          <Photos onPreview={onPreview} title="消纳场出场照片" srcs={data.consum_out} />
          <Photos onPreview={onPreview} title="装车照片" srcs={data.open_image} />
          <Photos onPreview={onPreview} title="电子签名" srcs={data.electric_sign} />
          <div className={styles.mapContainer}>
            <div className={styles.title}>运输情况：<i>(绿色线路为实际路线, 红色线路为规划路线)</i></div>
            <div className={styles.map} style={{ display: 'flex flex-column', position: 'relative' }}>  
              <div style={{ position: 'absolute', zIndex: 1, top: 30, left: 10 }}>
                {data.ticketDetail.detailInfo.alarm_record.length === 0 ? <div className={styles.record}>无违规行为</div> : data.ticketDetail.detailInfo.alarm_record.map(item => <div className={styles.record}>{item}</div>)}
              </div>
              <RoutesPreview
                height={400}
                planRoute={data.ticketDetail.planRoute}
                realRoute={data.ticketDetail.realRoute}
                parks={data.ticketDetail.parks}
              />
            </div>
          </div>
        </>
      )}
    </Modal>
  );
  // const [record, setRecord] = useState({});
  // const [advice, setAdvice] = useState('');
  // const [selectedAlarmIds, setSelectedAlarmIds] = useState([]);

  // useEffect(() => {
  //   debug.effect('Reply');
  //   request('/web/settlement/getApplyRecord', { ticket_id })
  //     .then(body => body.data.data)
  //     .then((data) => {
  //       setRecord(data);
  //       setAdvice(data.audit_advice);
  //     });
  // }, []);

  // const srcs = record.photo ? record.photo.split(',').filter(v => v) : [];
  // const disabled = !advice || advice === record.audit_advice;

  // const back = () => history.goBack();
  // function deal(nextStatus) {
  //   return () => request('web/settlement/dealApplyRecord', {
  //     record_id: record.record_id,
  //     status: nextStatus,
  //     audit_advice: advice,
  //     alarm: selectedAlarmIds,
  //   }).then(({ data: { code, msg } }) => {
  //     if (code === 200) {
  //       dataSource.refresh();
  //       back();
  //     } else {
  //       const warn = typeof msg === 'string' ? msg : '请求失败!';
  //       message.warn(warn);
  //     }
  //   });
  // }

  // const uneditable = !record.status || record.status === 2;

  // return (
  //   <Modal
  //     visible
  //     centered
  //     width={1000}
  //     title="详情 / 回复"
  //     okButtonProps={{ disabled }}
  //     onCancel={back}
  //     footer={uneditable ? null : (
  //       <>
  //         <Button type="danger" onClick={deal(3)}>驳回</Button>
  //         <Button type="primary" onClick={deal(2)}>通过</Button>
  //       </>
  //     )}
  //   >
  //     <div className={styles.content}>
  //       <div><strong>联单编号：</strong>{record.number}</div>
  //       <div><strong>车牌号码：</strong>{record.car_num}</div>
  //       <div><strong>申诉理由：</strong>{record.reason}</div>
  //       <div>
  //         <strong>申诉图片：</strong>
  //         {srcs.map(src => (
  //           <div key={src} className={styles.img} style={{ backgroundImage: `url(${src})` }} onClick={() => preview.image(src)} />
  //         ))}
  //       </div>
  //       <div>
  //         <strong>扣分项目：</strong>
  //         <Table
  //           bordered
  //           rowKey="id"
  //           pagination={false}
  //           className={styles.full}
  //           dataSource={record.alarm}
  //           columns={[
  //             { title: '告警类型', dataIndex: 'name' },
  //             { title: '告警时间', dataIndex: 'created_time' },
  //             { title: '告警地址', dataIndex: 'address' },
  //             { title: '扣分', dataIndex: 'score' },
  //             { title: '车速', dataIndex: 'speed' },
  //             { title: '停靠时长(分钟)', dataIndex: 'parking_time' },
  //             { title: '其他信息', dataIndex: 'remark' },
  //           ]}
  //           rowSelection={uneditable ? undefined : {
  //             selectedRowKeys: selectedAlarmIds,
  //             onChange: selectedRowKeys => setSelectedAlarmIds(selectedRowKeys),
  //           }}
  //         />
  //       </div>
  //       <div>
  //         <strong>审核意见：</strong>
  //         {uneditable ? <p>{advice}</p> : (
  //           <Input.TextArea value={advice} onChange={e => setAdvice(e.target.value)} autosize={{ minRows: 5, maxRows: 10 }} placeholder="请输入审核意见" className={styles.full} />
  //         )}
  //       </div>
  //     </div>
  //   </Modal>
  // );
}

const PreviewableDetail = previewable(Detail);

export default function SettlementReports({ path }) {
  debug.render('SettlementReports');
  return (
    <>
      <Route path={path} component={List} />
      <Route path={path + ':id/detail'} component={PreviewableDetail} />
    </>
  );
}
