// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1rGI20MBC6xmh1cY6cMc2i {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n._1rGI20MBC6xmh1cY6cMc2i span {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n}\n._1rGI20MBC6xmh1cY6cMc2i span p {\n  padding: 50px 80px;\n  border-radius: 4px;\n  border: 1px solid #d6d6d6;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-origin: border-box;\n  background-clip: border-box;\n}\n._1rGI20MBC6xmh1cY6cMc2i span strong {\n  margin-top: 12px;\n}", ""]);
// Exports
exports.locals = {
	"cover": "_1rGI20MBC6xmh1cY6cMc2i"
};
module.exports = exports;
