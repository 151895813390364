// https://ant.design/components/switch-cn/#API
import React from 'react';
import { Radio, Input } from 'antd';

export default function RadioKey({ props, options, radioKey, value, onChange, radioOnchange, ...rest} ) {
    return (
      <>
        <Radio.Group
          {...props}
          {...rest}
          options={options}
          value={value}
          onChange={e => onChange(e.target.value)}
        />
        {
          value === '其他' ?  <Input {...props} value={radioKey} onChange={e => radioOnchange(e.target.value)} /> : null
        }
      </>
    );
}
