import React from 'react';
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts';

export default function Chart({ data }) {
  if (JSON.stringify(data) === '{}') {
    return null;
  }

  let selected = {};
  data.data.map(({name}, i) => {
    selected[name] = i < 10
  });

  const pieOption = () => {
    return {
      color: ['#EF476F','#FFD23F','#06D6A0','#1EA896','#35A7FF','#26547C','#06D6A0'],
      title : {
        text: '项目占比',
        x:'center'
      },
      tooltip : {
        trigger: 'item',
        formatter: "{a} <br/>{b} : {c} ({d}%)"
      },
      legend: {
        type: 'scroll',
        orient: 'vertical',
        left: 'left',
        data: data.name,
        selected: selected
      },
      series : [
        {
          name: '项目',
          type: 'pie',
          radius : '55%',
          center: ['50%', '60%'],
          label: {
            normal: {
              show: false,
              formatter: "{b}\n{d}%",
            }
          },
          data: data.data
        }
      ]
    }
  };

  return (
    <ReactEcharts
      option={pieOption()}
      style={{height: '400px', width: '100%'}}
    />
  );
}
