/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import immutable from 'immutable';
import { Icon } from 'antd';
import request from 'utils/request';
import mapUtils from 'utils/map';
import debug from 'utils/debug';
import mapStyle from 'variables/mapStyle';
import styles from './IllegalMap.scss';

function polyline(route, strokeColor) {
  const path = mapUtils.objs2arrs(mapUtils.baidus2amaps(route));
  return new window.AMap.Polyline({
    path,
    lineCap: 'round',
    lineJoin: 'round',
    strokeColor,
    strokeWeight: 6,
    showDir: true,
  });
}

function marker(point, content = undefined, rest = {}) {
  const coord = mapUtils.obj2arr(mapUtils.baidu2amap(point));
  return new window.AMap.Marker({
    position: new window.AMap.LngLat(...coord),
    offset: new window.AMap.Pixel(0, 0),
    anchor: 'center',
    content,
    ...rest,
  });
}

function endpoint(lng, lat, addressName, typeName) {
  return marker({ lng, lat }, `
    <div class="${styles.endpoint}">
      <span>${typeName}</span>
      <span>${addressName}</span>
    </div>
  `, {
    anchor: 'bottom-left',
    offset: new window.AMap.Pixel(-15, 35),
  });
}

function bubble(lng, lat, text, time) {
  return marker({ lng, lat }, `
    <div class="${styles.bubble}" data-time="${time}">${text}</div>
  `, {
    anchor: 'middle-left',
    offset: new window.AMap.Pixel(0, 0),
  });
}

function picture(lng, lat, src, time) {
  return marker({ lng, lat }, `
    <div class="${styles.thumbnail}" data-time="${time}">
      <img src="${src}" />
    </div>
  `, {
    anchor: 'top-left',
    offset: new window.AMap.Pixel(4, -55),
  });
}

function RouteControl({ color }) {
  return <div style={{ background: color }} />;
}

function AlertControl() {
  return (
    <div>
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

const controls = {
  route: RouteControl,
  alert: AlertControl,
};

export default function IllegalMap({ id, type, alarmId, className, width, height }) {
  debug.render('IllegalMap');

  const [data, initialData] = useState(null);
  const [map, initialMap] = useState(null);
  const [groups, setGroups] = useState(immutable.List());
  const ref = useRef(null);

  // 新建地图，请求数据
  useEffect(() => {
    initialMap(new window.AMap.Map(ref.current, mapStyle));
    request('/web/ticket_alarm/getAlarmRow', { ticket_id: id, alarm_id: alarmId, status: type }).then(body => body.data.data).then(initialData);
  }, []);

  // 请求完数据后构造图形
  useEffect(() => {
    if (!map || !data) return;
    const { points = [], routes = [], rows = [] } = data;

    let $groups = groups;
    // 规划路线
    const validRoutes = routes.map(({ line: [route] }) => route).filter(isValid => isValid);
    const showIndex = validRoutes.length > 1;
    validRoutes.forEach((route, index) => {
      $groups = $groups.push(immutable.Map({
        display: true,
        overlayers: [
          polyline(route.steps, '#1EC337'),
          endpoint(route.start_lng, route.start_lat, route.start_address, '起'),
          endpoint(route.end_lng, route.end_lat, route.end_address, '终'),
        ],
        control: {
          type: 'route',
          label: `规划路线${showIndex ? index + 1 : ''}`,
          meta: {
            color: '#1EC337',
          },
        },
      }));
    });
    // 实际路线
    $groups = $groups.push(immutable.Map({
      display: true,
      overlayers: polyline(points, '#EF476F'),
      control: {
        type: 'route',
        label: '实际路线',
        meta: {
          color: '#EF476F',
        },
      },
    }));
    // 告警
    console.log(routes, rows);
    switch (type) {
      case 'overspeed': { // 超速
        $groups = $groups.push(immutable.Map({
          display: true,
          overlayers: rows.map(({ lng, lat, speed, created_time }) => bubble(lng, lat, `${speed}`, created_time)),
          control: {
            type: 'alert',
            label: '告警记录',
            meta: {},
          },
        }));
        break;
      }
      case 'illegal_dump': // 疑似违规倾倒
      case 'uncovered_tarpaulin': { // 篷布未密闭
        $groups = $groups.push(immutable.Map({
          display: true,
          overlayers: rows.map(({ lng, lat, image, created_time }) => picture(lng, lat, image, created_time)),
          // overlayers: rows.map(({ lng, lat, image, created_time }) => picture(lng, lat, 'http://file.mumayi.com/forum/201412/27/201015d652uc1l5tbxbmzb.jpg', created_time)),
          // overlayers: picture(validRoutes[0].start_lng, validRoutes[0].start_lat, 'http://file.mumayi.com/forum/201412/27/201015d652uc1l5tbxbmzb.jpg'),
          control: {
            type: 'alert',
            label: '告警记录',
            meta: {},
          },
        }));
        break;
      }
      case 'illegal_discharge': break; // 疑似违规出料
      case 'parking_too_long': break; // 商砼车停靠过长
      case 'route_offset': break; // 线路偏移
      default: break;
    }
    setGroups($groups);
  }, [data]);

  // 计算前后图形差别，增量更新
  useEffect(() => {
    if (!map) return;
    const prev = map.getAllOverlays();
    groups.forEach((group) => {
      const overlayers = group.get('overlayers');
      const had = prev.includes(Array.isArray(overlayers) ? overlayers[0] : overlayers);
      const has = group.get('display');

      if (!had && has) map.add(overlayers);
      if (had && !has) map.remove(overlayers);
    });
    map.setFitView();
  }, [groups]);

  // 鼠标进入控件时显示对应的图形
  function display(index) {
    if (typeof index === 'number') {
      setGroups(groups.map(group => group.set('display', false)).setIn([index, 'display'], true));
    } else {
      setGroups(groups.map(group => group.set('display', true)));
    }
  }

  return (
    <div className={styles.container} style={{ width, height }}>
      <div className={classnames(styles.map, className)} ref={ref} />
      <Icon type="warning" theme="filled" />
      <ul
        className={styles.panel}
        onMouseLeave={() => display()}
      >
        {groups.valueSeq().map((group, index) => {
          const control = group.get('control');
          const style = styles[control.type];
          const Control = controls[control.type];
          return (
            <li
              key={index}
              className={classnames(styles.control, style)}
              onMouseEnter={() => display(index)}
            >
              <div>{control.label}</div>
              <Control {...control.meta} />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

IllegalMap.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  alarmId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
IllegalMap.defaultProps = {
  alarmId: '',
  className: '',
  width: 1200,
  height: 800,
};
