import React, {useState, useEffect} from 'react';
import {Modal, Table} from 'antd';
import request from "utils/request";
import { ImagePreviewer } from 'components/Previewer';

const STATUS = ['', '通过', '不通过', '未审核'];
const CHECKCOLOR = ['', '#FD322C', '#1bc85e', '#b8becc'];
function getFiles(id, type){
  const [data, setData] = useState([]);
  useEffect(() => {
    request('/web/report/pShowReportFile', {relation_report_id: id, relation_report_type: type})
      .then(body => body.data.data)
      .then(setData)
  }, [id])
  return data
}

export default function ShowFiles({history, match}) {
  const {params: {id, type}} = match;
  const fileLists = getFiles(id, type);
  const [img_flag, setImgFlag] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const columns = [
    // { title: '序号', dataIndex: 'serial', align: 'center' },
    { title: '名称', dataIndex: 'file_name', key: 'file_name', render: (v, {file_url}) => (<span style={{color: '#1491fb', cursor: 'pointer'}} onClick={() => ShowMoreFiles(file_url)}>{v}</span>)},
    { title: '审核结果', dataIndex: 'status', key: 'status', render: (v) =>{ return <span style={{color: CHECKCOLOR[v]}}>{STATUS[v]}</span>} }
  ];
  function ShowMoreFiles(src){
    if(src.indexOf('.pdf') > -1 || src.indexOf('.doc') > -1 || src.indexOf('.xls') > -1 || src.indexOf('.xlsx') > -1 || src.indexOf('.docx') > -1 || src.indexOf('.txt') > -1){
      window.open(src)
    }
    else{
      setImgSrc(src);
      setImgFlag(true);
    }
  }
  function hideImage({visible}){
    setImgFlag(visible);
  }
  return (
    <>
      <Modal
        title={'报备要件'}
        visible={true}
        centered={true}
        bodyStyle={{maxHeight: '80vh', overflow: 'auto', padding: '20px 10px'}}
        footer={null}
        destroyOnClose={true}
        onCancel={() => history.goBack()}>
        <Table
          style={{padding: '0 10px'}}
          bordered
          size="middle"
          columns={columns}
          dataSource={fileLists}
          pagination={false}
        />
      </Modal>
      {
        img_flag ? <ImagePreviewer images={ imgSrc } visiable={img_flag} onChange={visiable => hideImage({ visiable })} />
          : null
      }
    </>
  );
}
