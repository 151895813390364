/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { Modal, Form, message, Input, Checkbox } from 'antd';
import immutable from 'immutable';
import request from 'utils/request/index';

import useImmutable from './useImmutable';

import Text from './FormItem/Text';
import Number from './FormItem/Number';
import TextArea from './FormItem/TextArea';
import Selector from './FormItem/Selector';
import Uploader from './FormItem/Uploader';
import UploaderDragger from './FormItem/UploaderDragger';
import DatePicker from './FormItem/DatePicker';
import RadioKey from './FormItem/Radio';
import RadioGroup from './FormItem/RadioGroups';
import RadioVolumn from './FormItem/RadioVolumn';
import RangePicker from "./FormItem/RangePicker";
import NumberByOptions from "./FormItem/NumberByOptions";
import InputPointer from './FormItem/InputPointer';
import MediasList from './FormItem/MediasList';
const getModalProps = (props = {}) => ({
  visible: true,
  centered: true,
  bodyStyle: {
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  ...props,
});

const getFormProps = (props = {}) => ({
  ...props,
});

let gpsOfflineHide = true; // 不隐藏车辆离线地址和坐标
export default function ModalFormPage({ init, initTo, localModel, judgeDataTable, submit, title, onCancel, onFinish, modalProps, formProps, inputs: originalInputs, defaultValue }) {
  const { get, set, sets, setter, toJS } = useImmutable(immutable.Map(defaultValue));
  const [initialed, setInitialed] = useState(false);
  const [reasonVolumnKey, setReasonVolumnKey] = useState(false);
  const inputs = originalInputs.reduce((flatten, input) => {
    if (Array.isArray(input.children)) return [...flatten, ...input.children.map(child => ({ required: input.required, ...child }))];
    return [...flatten, input];
  }, []);

  // 是否需要初始化数据
  const initialing = init && !initialed;
  const localing = localModel && !initialed;
  const initToing = initTo && !initialed;
  if (initialing) {
    const [api, params = {}, transformer] = init;
    request(api, params)
      .then(body => body.data.data)
      .then(data => (transformer ? transformer(data) : data))
      .then((data) => {
        sets(...inputs.map(({ key }) => [key, data[key]]));
        setInitialed(true);
      });
  }
  if(localing){
    const localData = JSON.parse(localStorage.getItem('SandStone'));
    sets(...inputs.map(({ key }) => [key, localData[key]]));
    setInitialed(true);
  }
  if(initToing){
    sets(...inputs.map(({ key }) => [key, initTo[key]]));
    setInitialed(true);
  }
  function renderInput(key, { type, relies, reliesAs, rely, relyAs, extraData = {}, afterChange = (v, sets) => sets([key, v]), ...rest }) {
    const inputProps = { key, allowClear: true };
    switch (type) {
      case 'date':
      case 'date-range': {
        let disabled = false;
        if (rely) {
          disabled = !!get(rely);
        }
        const { format = 'YYYY-MM-DD HH:mm:ss', ...props } = rest;
        return <DatePicker {...inputProps} {...props} disabled={disabled} format={format} value={get(key)} onChange={setter(key)} />;
      }
      case 'new-date-range': {
        let disabled = false;
        if (rely) {
          disabled = !!get(rely);
        }
        const { format = 'YYYY-MM-DD', ...props } = rest;
        return <DatePicker {...inputProps} {...props} disabled={disabled} format={format} value={get(key)} onChange={setter(key)} />;
      }
      case 'dates-range': {
        let disabled = false;
        const { splitFlag } = rest;
        if (rely) {
          disabled = !!get(rely);
        }
        const { format = 'YYYY-MM-DD HH:mm:ss', ...props } = rest;
        return <RangePicker {...inputProps} {...props} format={format} value={ splitFlag && get(key) && !Array.isArray(get(key)) ? get(key).split(splitFlag) : get(key)} onChange={setter(key)} />;
      }
      case 'checkbox': return <Checkbox checked={get(key)} onChange={e => set(key, e.target.checked ? 1 : 0)} />;
      case 'select': {
        const onChange = value => afterChange(value, sets);
        let disabled = false;
        let { options } = rest;
        const relyKeys = relies || (rely && [rely]);
        const dataKeys = reliesAs || relies || (relyAs && [relyAs]) || (rely && [rely]);
        const {option_type} = rest;
        let localFlag = false;
        let showMsg = '';
        // if (rely) {
        //   const relyValue = get(rely);
        //   // 如果依赖重置，自己也重置
        //   if (relyValue === undefined && get(key) !== undefined) {
        //     set(key, undefined);
        //   }
        //   disabled = relyValue === undefined;
        //   if (typeof options === 'string') {
        //     const dataKey = relyAs || rely;
        //     const data = { [dataKey]: relyValue };
        //     options = { url: options, data };
        //   }
        // }
        if (relyKeys) {
          const relyValues = relyKeys.map(key => get(key));
          disabled = relyValues.filter(value => value === undefined).length > 0;
          // 如果依赖重置，自己也重置
          if (disabled && get(key) !== undefined) {
            set(key, undefined);
          }
          if (typeof options === 'string') {
            const data = dataKeys.reduce((final, dataKey, index) => {
              return { ...final, [dataKey]: relyValues[index] };
            }, {});
            options = { url: options, data: { ...extraData, ...data } };
            localFlag = false;
            if(localing && options.url.indexOf('/web/instance/getStartOrEndSupplement') > -1){
              localFlag = true
            }
          }
        }
        if(option_type){
          const relyValues = get(option_type);
          showMsg = relyValues === 1 ? '《待报备车辆》列表中车辆信息填写完整并且《预报备》已审核通过，此处才会显示报备车辆' : '';
          options = relyValues === 2 ? '/web/keep_on_record/carList?' : '/web/report/getTempReportCars';
        }
        if(localFlag){
          const localData = JSON.parse(localStorage.getItem('SandStone'));
          options.optionValue = localData.end_id;
          options.originalValue = localData.end_id;
          options = {...options, optionValue: localData.end_id, originalValue: localData.end_id};
          rest = {...rest, optionValue: localData.end_id, originalValue: localData.end_id};
          return <Selector {...inputProps} showMsg={showMsg} originalValue={localData.end_id} disabled={disabled} {...rest} options={options} value={get(key)} onChange={onChange} />;
        }
        else if(rest.show_type){
          let newValue = [];
          if(get(key) && get(key) !== '' && !Array.isArray(get(key))){
            get(key).split(",").map((v) => {
              newValue.push(v)
            })
          }else{
            newValue = get(key)
          }
          return <Selector {...inputProps} judgeDataTable={judgeDataTable} showMsg={showMsg} disabled={disabled} mode={"multiple"} {...rest} options={options} value={newValue} onChange={onChange} />;
        }
        else{
          return <Selector {...inputProps} judgeDataTable={judgeDataTable} showMsg={showMsg} disabled={disabled} {...rest} options={options} value={get(key)} onChange={onChange} />;
        }
      }
      // case 'upload': return <Uploader {...inputProps} {...rest} value={get(key)} onChange={setter(key)} />;
      case 'upload': {
        const disabled = rely && !get(rely);
        return <Uploader {...inputProps} {...rest} value={get(key)} onChange={setter(key)} disabled={disabled} />;
      }
      case 'upload-dragger': {
        const msg = (rest.showMsgKey && get(rest.showMsgKey) === 3) ? '请上传运企公司盖章的证明和车辆修理厂维修照片' : '请上传运企公司盖章的证明文件';
        const extraMsg = (rest.officialMsg && get(rest.officialMsg) === 1) ? <div style={{color: '#ff0000', lineHeight: '24px', textAlign: 'left', fontSize: 12, position: ''}}>请上传以下文件：1、运企报备申请（公司签章）；2、技改合格证照片；3、GPS入网登记表；4、道路运输证照片<p>（以上文件未全部上传，审核将不会通过）</p></div> : '';
        return <UploaderDragger {...inputProps} showMsg={msg} extraMsg={extraMsg} {...rest} value={get(key)} onChange={setter(key)} />
      }
      case 'label': return <div {...rest} dangerouslySetInnerHTML={{ __html: rest.value }} />;
      case 'number-options': {
        let numberOption = '', relyValues = [];
        let { reliesCount, options, extraKey, relyMoreKey, maxValueRely } = rest;
        if(rely){
          const relyValues = get(rely);
          numberOption = { [rely]: relyValues };
          options = {url: options, data: numberOption}
        }
        if(reliesCount){
          relyValues = reliesCount.map(key => get(key));
        }
        const numberValue = !reliesCount ? get(key) : (extraKey && (!get([relyMoreKey]) || get([relyMoreKey]) === '' || get([relyMoreKey]) === 0)) ?  get([extraKey]) : ( relyValues[0] && relyValues[1] ? relyValues[0] - relyValues[1] : '');
        return <NumberByOptions {...inputProps} {...rest} max={maxValueRely ? get(maxValueRely) : undefined} value={numberValue} options={options} onChange={setter(key)} />
      }
      case 'number': return <Number {...inputProps} {...rest} value={get(key)}  onChange={setter(key)} />;
      case 'textarea': {
        const { extraRemark, extraTip } = rest;
        return (
          <div>
            {extraTip && <span style={{ fontSize: 14, color: 'red' }}>{extraTip}</span>}
            <TextArea key={key} {...rest} value={get(key)} onChange={setter(key)} />
            {extraRemark && <RadioGroup options={extraRemark} {...rest} labelKey="value" valueKey="value" value={get(key)} onChange={setter(key)} />}
          </div>
          
        )
      }
      case 'radio': {
        let { options } = rest;
        if (typeof options === 'string') {         
          options = { url: options, data: { ...extraData } };
        }
        return <RadioKey options={options} {...inputProps} value={get(key)} onChange={setter(key)} />;
      }
      case 'radioGroup': {
        let { options } = rest;
        return <RadioGroup options={options} {...rest} value={get(key)} onChange={setter(key)} />;
      }
      case 'radioVolume': {
        let { options } = rest;
        return <RadioVolumn options={options} {...inputProps} value={get(key)} radioKey={reasonVolumnKey ? reasonVolumnKey : ''} onChange={setter(key)} radioOnchange={ (e) => setReasonVolumnKey(e) } />;
      }
      case 'pointer': {
        return <InputPointer key={key} {...rest} value={get(key)} onChange={setter(key)} />
      }
      case 'car_match': {
        const value = get(key);
        const msg = !value ? '' : (value.length === 7) ? '' : '请填写正确的车辆号牌'
        return <Text {...inputProps} {...rest} extraText={msg} value={value} onChange={setter(key)} />;
      }
      case 'phone_match': {
        const re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
        const value = get(key);
        let msg = '';
        if(!value) {
          msg = '';
        }else if (value.length !== 11 || re.test(value)) {
          msg = '请填写正确的手机号'
        } else {

        }
        return <Text {...inputProps} {...rest} extraText={msg} value={value} onChange={setter(key)} />;
      }
      case 'lists': return <MediasList {...rest} value={get(key)} onChange={setter(key)} />
      case 'password': return <Text {...inputProps} type="password" {...rest} value={get(key)} onChange={setter(key)} />;
      default: return <Text {...inputProps} {...rest} value={get(key)} onChange={setter(key)} />;
    }
  }
  function renderItem({ label, key, required, hide, hideAccount, children, showOrHide, reasonHide, showOrHideReason, ...rest }) {
    if(rest.type === 'radioVolume' && (get(showOrHide) !== 0 &&  get(showOrHide) !== 0.0)){
      hide = false;
    }
    if (rest.type === 'upload-dragger' && showOrHide && get(showOrHide) === 3) {
      hide = false
    }
    if((rest.type === 'pointer' || !rest.type) && showOrHideReason) {
      if(get(showOrHideReason) === 2 || get(showOrHideReason) === 3 || get(showOrHideReason) === 5 || get(showOrHideReason) === 6) {
        reasonHide = false;
        gpsOfflineHide = false; // 不隐藏车辆离线地址和坐标
      }
      else {
        gpsOfflineHide = true;
      }
    }
    if (hideAccount && get(hideAccount) !== 4) return null;
    if (hide) return null;
    if (reasonHide) return null;
    return (
      <Form.Item key={key || label} label={label} required={required} message={'请填写正确的车辆号牌'}>
        {!Array.isArray(children) ? renderInput(key, rest) : (
          <Input.Group compact>
            {children.map(({ key, ...rest }) => renderInput(key, rest))}
          </Input.Group>
        )}
      </Form.Item>
    );
  }

  // 判断是自己提交还是把数据交给父组件
  function onSubmit() {
    const data = toJS();
    if (!submit) {
      if(reasonVolumnKey && reasonVolumnKey !== '其他'){
        onFinish({...data, reasonVolumnKey});
      }else{
        onFinish(data);
      }
    } else {
      const [api, extraParams = {}] = submit;
      request(api, { ...extraParams, ...data })
        .then(body => body.data)
        .then(({ code, msg }) => {
          if (code === 200) {
            onFinish();
          } else {
            const warn = typeof msg === 'string' ? msg : '保存失败!';
            message.warn(warn);
          }
        });
    }
  }

  const finalModalProps = getModalProps(modalProps);
  const finalFormProps = getFormProps(formProps);
  // 多判断了一个数组类型为数组的，如果是数组，则判断是否长度为空
  const disabled = inputs.find(({ key, required, hide, reasonHide, showOrHideReason, showOrHide, relyAddressAndPoint, type }) => {
    if(reasonHide && gpsOfflineHide) return false;
    if(relyAddressAndPoint) {
     if(get(key) === 2 || get(key) === 3 || get(key) === 5 || get(key) === 6) {
        if(get('stop_address') === undefined || get('stop_address') === '' || get('stop_point') === undefined || get('stop_point') === '') return true
      }
    }
    if(type === 'lists') {
      const value = get(key);
      if(value && value.length > 0) {
        return value.find(({ created_time, type, url }) => {
          return created_time === '' || type === '' || url === ''
        })
      }
      return false;
    }
    if (required && type === 'upload-dragger') {
      if (showOrHide && get(showOrHide) === 3) {
        if (!get(key) || get(key)?.length === 0) return true;
      }
    }
    else return !hide && required && (get(key) === undefined || get(key) === '' || (get(key) instanceof Array && get(key).length === 0))
  });
  return (
    <Modal
      {...finalModalProps}
      title={title}
      onCancel={onCancel}
      onOk={onSubmit}
      okButtonProps={{ disabled }}
    >
      <Form {...finalFormProps}>
        {!initialing && originalInputs.map(renderItem)}
      </Form>
    </Modal>
  );
}
