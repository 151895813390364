// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2O-GPdqabl1s_cDt-WotxY {\n  position: absolute;\n  top: 60px;\n  left: 20px;\n}\n\n._1OhSlLfddaZM3_2_UkGF2c {\n  width: 200px;\n  position: absolute;\n  top: 32px;\n  left: 0;\n  background: rgba(255, 255, 255, 0.9);\n  border-radius: 4px;\n  font-size: 14px;\n}\n\n._1OhSlLfddaZM3_2_UkGF2c > div {\n  -ms-text-overflow: ellipsis;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n._1OhSlLfddaZM3_2_UkGF2c > div:hover {\n  color: #1491fb;\n  background: #eef7ff;\n}\n\n.ant-input-group {\n  display: grid;\n  grid-template-columns: auto 63px;\n}\n.ant-input-group > input {\n  border-radius: 4px 0 0 4px;\n}\n.ant-input-group > button {\n  border-radius: 0 4px 4px 0;\n}", ""]);
// Exports
exports.locals = {
	"addressSearch": "_2O-GPdqabl1s_cDt-WotxY",
	"searchAddress": "_1OhSlLfddaZM3_2_UkGF2c"
};
module.exports = exports;
