import React from 'react';
import { Route } from 'react-router-dom';
import List from './List';
import RouteDetail from  '../../NewSandstoneTakeoverRoutes/DetailComponent/CommonDetails';
import Check from './Check';

export default ({ path }) => (
  <>
    <Route exact path={path} component={List} />
    <Route path={path + ':types/:id/detail'} component={RouteDetail} />
    <Route path={path + ':types/:id/check'} component={Check} />
  </>
);
