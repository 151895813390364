import React, { useState, useEffect } from 'react'
import { Modal, Steps, DatePicker, message } from 'antd'
import Uploader from '../../Uploader'
import moment from 'moment'
import request from 'utils/request';
import { dataSource } from '../List'
import styles from './index.scss'
const { confirm } = Modal;
const { RangePicker } = DatePicker
/* 灰名单延期 */
export default function Details({ props }) {
  let { greyListInfo, GreyListInfo, selectedRows } = props
  const [visible, setVisible] = useState(false)
  const [imgValue, setImgValue] = useState([]);
  const [dates, setDates] = useState([]);
  const [datess, setDatess] = useState();
  const [car_num, setCarNum] = useState('')

  useEffect(() => { if (!visible) { GreyListInfo({ visible }) } }, [visible])
  useEffect(() => { if (greyListInfo) { setVisible(true) } }, [greyListInfo])
  useEffect(() => {
    if (selectedRows.length > 0) {
      setCarNum(selectedRows[0].car_number)
      setDates([selectedRows[0].in_time ? moment(selectedRows[0].in_time) : null, moment(selectedRows[0].out_time)])
      setDatess(moment(selectedRows[0].out_time).add(1, 'day'))
    }
  }, [selectedRows])

  function handleOk(e) {
    showConfirm()
  };
  function handleCancel(e) {setVisible(false)};
  function showConfirm() {
    if (datess == null) {
      message.warn('请选择时间!')
      return
    }
    if (imgValue.length == 0) {
      message.warn('请上传证据！')
      return
    }
    confirm({
      title: '确定要灰名单延期吗？',
      okText: '确定',
      cancelText: '取消',
      centered: true,
      onOk() {
        request('/web/gb_list/continueGrayList', { list_id: selectedRows[0].id, images: imgValue, delay_time: datess.format('YYYY-MM-DD HH:mm:ss') })
          .then(body => {
            if (body.data.code === 200) {
              message.success('灰名单延期成功！')
              setVisible(false)
              dataSource.refresh()
              setImgValue('')
            } else {
              message.warn(body.data.msg)
            }
          })
      },
    });
  }
  function disabledDate(current) {
    let time = dates[1]
    if (!current) {
      return false
    } else {
      return current < moment(time).add(1, 'day')
    }
  }
  return (
    <Modal
      title='灰名单时间延期'
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      maskClosable={false}
    >
      <p>已选车辆</p>
      <span style={{ background: '#F2F2F2', color: '#1890FF', padding: 4, marginRight: 5, borderRadius: 6 }}>{car_num}</span>
      <br /><br />
      <p>原灰名单时间</p>
      <RangePicker onChange={() => { }} showTime={{ format: 'HH' }} disabled format="YYYY-MM-DD HH:mm:ss" value={dates} />
      <br /><br />
      <p>灰名单延期</p>
      <DatePicker
        onChange={(e) => { setDatess(e) }}
        value={datess}
        showTime={{ format: 'HH' }}
        format='YYYY-MM-DD HH:mm:ss'
        disabledDate={disabledDate}
      />
      <br /><br />
      <p>证据上传<span style={{ color: '#c4c4c4', fontSize: 12 }}>(最多可以上传5张)</span></p>
      <Uploader value={imgValue} onChange={(e)=>{setImgValue(e)}} maxUploadLength={5} />
    </Modal >
  )
}
