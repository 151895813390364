import React from 'react';
import './failureReport.scss'
import {
  Table,
  Button,
} from 'antd';
import request from "../../utils/request";

export default class FinishedReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      allData: [],
      sourceData: [],
      columnsData: [],
      form_id: props.data.form_id,
      note: '',

      editValue: '',
      editIndex: '',
      editIndexJ: '',
      tableWidth: 200,
      src: '',
    }
  };

  openUrl = (val) => {
    open(val);
  };

  /*getInput = (text) => {
    let type = text.types, answers = text.answers,value = text.values, index = text.index, indexJ = text.indexJ, fixIndexJ = text.fixIndexJ;
    let that = this;
    if(!answers){
      answers = '';
    }
    if (type === 1 || type === '1') {
      return <Input defaultValue={answers} data-index={index} data-indexj={indexJ} onBlur={this.onChange}/>;
    }
    else if (type === 2 || type === '2') {
      return <TextArea defaultValue={answers} data-index={index} data-indexj={indexJ} onBlur={this.onChange}/>;
    }
    else if (type === 3 || type === '3') {
      const option = [];
      for (let i = 0; i < value.length; i++) {
        option.push({value: value[i]})
      }
      return <Select style={{width: '180px'}} defaultValue={answers} onChange={this.onSelectChange}>
      {
        option.map((v, i) => {
          return (
            <Option key={i} value={v.value} title={index} className={indexJ}>{v.value}</Option>
        )
        })
      }
    </Select>
    }
    else if (type === 4 || type === '4') {
      const defaultFileList = [{
        uid: '1',
        name: 'xxx.png',
        status: 'done',
        response: 'Server Error 500', // custom error message to show
        url: 'http://www.baidu.com/xxx.png',
      }]
      return <div style={{display: 'flex'}} onClick={() => {
        this.onCheckClick(index, indexJ)
      }}>
    <Upload action={''} defaultFileList={defaultFileList} onChange={this.onUploadChange}>
    <Button>
      <Icon type="upload"/> 上传
        </Button>
        </Upload>
        </div>
    }
    else if (type === 5 || type === '5') {
      return <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" onOpenChange={() => {
        this.dateFocus(index, indexJ)
      }}
      style={{width: '160px'}} defaultValue={answers} onChange={this.onDateChange}/>
    }
    else if (type === 6 || type === '6') {
      const option = [];
      for (let i = 0; i < value.length; i++) {
        option.push({value: value[i]})
      }
      return <RadioGroup defaultValue={answers} name={index + ',' + indexJ} onChange={this.onRadioChange}>
      {
        option.map((v, i) => {
          return (
            <Radio key={i} value={v.value}>{v.value}</Radio>
        )

        })
      }
    </RadioGroup>
    }
    else if (type === 7 || type === '7') {
      const options = [];
      for (let i = 0; i < value.length; i++) {
        options.push({label: value[i], value: value[i]})
      }
      const checkData = answers.split('|');
      return <div onClick={() => {
        this.onCheckClick(index, indexJ)
      }}><CheckboxGroup options={options} defaultValue={checkData} onChange={this.onCheckChange}/></div>

    }
    else if (type === 8 || type === '8') {
      return <div onClick={() => {
        this.onCheckClick(index, indexJ)
      }}><InputNumber defaultValue={answers} onChange={this.onNumberChange}/></div>
    }
    else if (type === 9 || type === '9') {
      const fixData = that.state.data.data.fixedData;
      return <Input defaultValue={fixData[index][fixIndexJ]} disabled/>
    }
    return null;
  };*/
  /*
  dateFocus = (index, indexJ) => {
    this.setState({
      editIndex: index,
      editIndexJ: indexJ,
    })
  };
  onCheckClick = (index, indexJ) => { //点击获取index indexJ
    this.setState({
      editIndex: index,
      editIndexJ: indexJ,
    })
  };
  onChange = (e) => {
    e.persist();
    let target = e.target;
    let index = target.dataset.index;
    let indexJ = target.dataset.indexj;
    changeData[index][indexJ] = target.value;
  };
  onSelectChange = (option, e) => {
    let val = e.props;
    changeData[val.title][val.className] = val.value;
  };
  onDateChange = (e, str) => {
    changeData[this.state.editIndex][this.state.editIndexJ] = str;
  };
  onCheckChange = (e) => {
    let that = this;
    let str = e.join('|');
    setTimeout(function () {
      changeData[that.state.editIndex][that.state.editIndexJ] = str;
    }, 1000)

  };
  onRadioChange = (e) => {
    let val = e.target.name;
    let index = val.split(',')[0];
    let indexJ = val.split(',')[1];
    changeData[index][indexJ] = e.target.value;
  };
  onUploadChange = (e) => {
    let that = this;
    setTimeout(function () {
      changeData[that.state.editIndex][that.state.editIndexJ] = e.file.name;
    }, 1000)
  };
  onNumberChange = (e) => {
    let that = this;
    setTimeout(function () {
      changeData[that.state.editIndex][that.state.editIndexJ] = e;
    }, 1000)
  };
  */

  romanceHeader = (data) => {
    let that = this;
    let index = 0;
    const head = data.head;
    let tableWidth = 0;
    const titleArr = [];
    data.titles.detail_title.map((v, i) => {
      titleArr.push({
        title: v.count_name,
        children: [],
        width: 200 * (v.count_name)
      });
      for (let j = 0; j < v.cross_column; j++) {
        titleArr[i].children.push(
          {
            title: head[j + index],
            dataIndex: 'data' + (j + index),
            key: 'data' + (j + index),
            width: 200,
            render: (text, record) => {
              if (text.indexOf('http') != -1) {
                return <Button type={"primary"} size={"small"} onClick={()=>that.openUrl(text)}>查看</Button>
              } else {
                return text;
              }
            }
          })
      }
      index += v.cross_column;
    });

    that.setState({
      columnsData: titleArr,
    });
  };
  romanceTableData = () => {
    let that = this;
    let bodyData = [];
    request({
      url: '/web/Form/showAnser',
      data: {
        id: that.state.form_id,
      },
    })
      .then(body=>body.data)
      .then(({data})=>{
        that.romanceHeader(data);

        data.body.map((item, i) => {
          bodyData.push({});
          let keyData = '';
          let indexJ = 0;
          for (let j = 0; j < item.length; j++) {
            keyData = 'data' + j;
            indexJ++;
            bodyData[i][keyData] = item[j];
          }
        });

        that.setState({
          allData: data,
          sourceData: bodyData,
          note: data.titles.note,
        });
      });
  };

  componentDidMount() {
    this.romanceTableData();
  }

  render() {
    const state = {...this.state};
    return (
      <div className='failure'>
        <Table
          pagination={false} size="middle"
          bordered scroll={{x: 3600, y: 450}}
          columns={state.columnsData}
          dataSource={state.sourceData}
          rowClassName="editable-row"
        />
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10}}>
          <div>
            <span style={{fontSize: 14, color: '#808080'}}>备注： {state.note}</span>
          </div>
        </div>
      </div>
    )
  }
}

