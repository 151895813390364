import React from 'react';
import { Divider } from 'antd';
import { previewable } from 'components/Previewer';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';

import ImagePreviewButtonRender from 'ui/TableCell/ImagePreviewButtonRender';
import ConfirmButton from 'ui/TableCell/ConfirmButton';
import LinkButton from 'ui/TableCell/LinkButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';

import carTypes from 'variables/carTypes';
import nations from 'variables/nations';

import debug from 'utils/debug';
import request from 'utils/request';
import store from 'stores/user';

export const dataSource = new DataSource('/web/driver/newGetList');

function List({ preview }) {
  debug.render('List');

  const render = ImagePreviewButtonRender(preview);
  const columns = [
    serial(true),
    { title: '姓名', dataIndex: 'name', width: width(4), fixed: 'left' },
    { title: '电话号码', dataIndex: 'phone', width: width(1, 15) },
    { title: '车牌号码', dataIndex: 'car_num', width: width(7) },
    { title: '准驾车型', dataIndex: 'car_type', width: width(4), align: 'center' },
    { title: '民族', dataIndex: 'nation', width: width(5) },
    { title: '籍贯', dataIndex: 'birth_address' },
    { title: '常住地', dataIndex: 'address' },
    {
      title: '身份证',
      children: [
        { title: '身份证号码', dataIndex: 'id_card', width: width(1, 20) },
        { title: '照片', dataIndex: 'id_card_photo', width: width(2), align: 'center', render },
      ],
    },
    { title: '驾驶证照片', dataIndex: 'driver_license_photo', width: width(5), align: 'center', render },
    { title: '从业资格证照片', dataIndex: 'work_license_photo', width: width(5), align: 'center', render },
    { title: '近期体检表照片', dataIndex: 'physical_form', width: width(5), align: 'center', render },
    actions({
      isFixed: true,
      width: store.getState().roles.id === 101 ? width(8) : width(3),
      render: ($, { id }) => (
        <>
          <LinkButton to={`./${id}/update`} />
          {store.getState().roles.id === 101 && (
            <>
              <Divider type="vertical" />
              <ConfirmButton title="确认删除该驾驶员?" type="danger" api="/web/driver/delete" id={id} dataSource={dataSource}>删除</ConfirmButton>
            </>
          )}
        </>
      ),
    }),
  ];

  const querys = [
    { title: '关键字', key: 'keyword' },
  ];

  const buttons = [
    store.getState().roles.id === 101 && { title: '新增', link: './create', icon: 'plus' },
  ].filter(o => o);

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}

const onCarNumberChange = async (car_num, sets) => {
  const set = urls => sets(['car_num', car_num], ['drive_license_photo', urls]);
  if (car_num) {
    await request('/web/driver/carDriveLicensePhoto', { car_num }).then(body => body.data.data).then(urls => set(urls));
  } else {
    set('');
  }
};

export function inputs(isCarBindable) {
  return [
    { label: '电话号码', key: 'phone', disabled: true, required: true },
    { label: '驾驶员姓名', key: 'name', required: true },
    isCarBindable && { label: '车牌号码', key: 'car_num', type: 'select', options: '/web/driver/carList', valueKey: 'car_num', labelKey: 'car_num', showSearch: true, required: true, afterChange: onCarNumberChange },
    { label: '准驾车型', key: 'car_type', type: 'select', options: carTypes, required: true },
    { label: '民族', key: 'nation', type: 'select', options: nations, required: true, showSearch: true },
    { label: '籍贯', key: 'birth_address', required: true },
    { label: '常住地', key: 'address', required: true },
    { label: '身份证号码', key: 'id_card', required: true },
    { label: '身份证照片', key: 'id_card_photo', type: 'upload', required: true },
    { label: '驾驶证照片', key: 'driver_license_photo', type: 'upload', required: true },
    isCarBindable && { label: '车辆行驶证照片', key: 'drive_license_photo', type: 'upload', required: true, rely: 'car_num' },
    { label: '从业资格证照片', key: 'work_license_photo', type: 'upload', required: false },
    { label: '近期体检表照片', key: 'physical_form', type: 'upload', required: false },
  ].filter(o => o);
}

export default previewable(List);
