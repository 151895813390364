import React, { useState, useEffect } from 'react'
import request from 'utils/request';
import { Modal,message } from 'antd'
import { dataSource } from '../List'
const { confirm } = Modal;
/* 删除按钮 */
export default function Detele(props) {
  const Content = function () {
    return (
      <>
        <br />
        <p>车牌：{props.car_number}</p>
        <p>运企：{props.transport_name}</p>
      </>
    )
  }
  confirm({
    title: '你确定要删除这条记录吗？',
    content: <Content />,
    okText: '确定',
    okType: 'danger',
    cancelText: '取消',
    centered: true,
    onOk() {
      let list_id = props.id
      request('/web/gb_list/deleteCarAlarmList', { list_id })
        .then(res => {
          message.success('删除成功！')
          dataSource.refresh()
        })
    },
  });
}