import React, { useState } from 'react';
import {Route} from 'react-router-dom';
import {Divider} from 'antd';
import { previewable } from 'components/Previewer/index';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import ImagePreviewButtonRender from 'ui/TableCell/ImagePreviewButtonRender';
import LinkButton from 'ui/TableCell/LinkButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';
import { reasonArr, punishArr, isDirectory, typeArr } from "../../share";
import debug from 'utils/debug';
import store from 'stores/user';
import Detail from "../Details";
import Delay from "../Delay";
import ChangeToBlack from "../ChangeToBlack";

// export const dataSource = new DataSource('web/blacklist/getCarBlacklist', {list_type: 1});
export const dataSource = new DataSource('/web/gb_list/getGbCarLists', {car_type: 1});

function List({ preview, path }) {
  debug.render('List');
  const roleId = store.getState().roles.id;
  const area_id = store.getState().info.area_id;
  const render = ImagePreviewButtonRender(preview);
  const [selectedRow, setSelectedRow] = useState([]);
  const [multipleData, setMultipleData] = useState([]);
  const columns = [
    serial(true),
    { title: '车牌号', dataIndex: 'car_number', width: width(7)},
    { title: '车辆照片', dataIndex: 'photos', render },
    { title: '是否温江目录', dataIndex: 'is_car_directory', render: (v) => (<span>{ (v === 1 || v === '1') ? '是' : '否'}</span>) },
    { title: '运企名称', dataIndex: 'transport_name' },
    { title: '所属区域', dataIndex: 'area_name' },
    { title: '关联驾驶员', dataIndex: 'driver_name' },
    { title: '事由', dataIndex: 'reason', render: function(v){ return typeArr[v]} },
    { title: '进入时间', dataIndex: 'in_time' },
    { title: '提交人', dataIndex: 'created_name' },
    { title: '退出时间', dataIndex: 'out_time' },
    { title: '处罚', dataIndex: 'deal_result', render: (v) => v === 1 ? <span style={{color: 'red'}}>停运</span> : '其他' },
    actions({
      isFixed: true,
      width: roleId === 111 ? 140 : 70,
      render: ($, { id, ...data }) => (
        <>
          <LinkButton to={`./1/${id}/detail`}>详情</LinkButton>
          {
            roleId === 111 ? (
              <>
                <Divider type="vertical" />
                <LinkButton to={{ pathname: `./delay`, state: { data: [{ id, ...data}] }}} danger>延期</LinkButton>
              </>
            ) : null
          }
        </>
      )
    })
  ];

  const querys = roleId === 111 ? [
    { title: '搜索车牌、驾驶员', key: 'keyword', style: { width: 200 } },
    { title: '运企', key: 'transport_id', type: 'select', options: 'web/blacklist/getTransportLists', extraData: { area_id }, valueKey: 'id', labelKey: 'name', style: { width: 200 } },
    { title: '事由', key: 'reason', type: 'select', options: reasonArr, valueKey: 'value', labelKey: 'label', style: { width: 200 }, },
    { title: '是否温江名录', key: 'is_car_directory', type: 'select', options: isDirectory, valueKey: 'value', labelKey: 'label', style: { width: 150 }, },
    { title: '处罚', key: 'deal_result', type: 'select', options: punishArr, valueKey: 'value', labelKey: 'label', style: { width: 120 }, },
    { title: ['进入', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } }
  ] : [
    { title: '搜索车牌、驾驶员', key: 'keyword', style: { width: 200 } },
    { title: '事由', key: 'reason', type: 'select', options: reasonArr, valueKey: 'value', labelKey: 'label', style: { width: 200 }, },
    { title: '是否温江名录', key: 'is_car_directory', type: 'select', options: isDirectory, valueKey: 'value', labelKey: 'label', style: { width: 150 }, },
    { title: '处罚', key: 'deal_result', type: 'select', options: punishArr, valueKey: 'value', labelKey: 'label', style: { width: 120 }, },
    { title: ['进入', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } }
  ];
  const disabled = multipleData.length === 0;
  const buttons = [
    { title: '导出', download: '/web/gb_list/exportGbCarLists', extraParams: {car_type: 1} },
    roleId === 111 && { title: '延期', link: {pathname: './delay', state: { data: multipleData }}, type: 'danger', disabled: disabled },
    roleId === 111 && { title: '转黑', link: {pathname: './change-to-black', state: { data: multipleData }}, disabled: disabled }
  ];

  const onSelectChange = (rows) => {
    setSelectedRow(rows);
    const tableData = dataSource.dataSource.data;
    let data = [];
    tableData.map(({ id, ...d }) => {
      rows.map((v) => {
        if(id === v) {
          data.push({ id, ...d })
        }
      })
    })
    setMultipleData(data);
  };
  const rowSelection = {
    selectedRow,
    selectedRowKeys: selectedRow,
    onChange: onSelectChange,
    selections: []
  };
  return (
    <>
      {
        roleId === 111 ?
          <TablePage
            dataSource={dataSource}
            buttons={buttons}
            querys={querys}
            columns={columns}
            defaultLimit={10}
            rowSelection={rowSelection}
          /> :
          <TablePage
            dataSource={dataSource}
            buttons={buttons}
            querys={querys}
            columns={columns}
            defaultLimit={10}
          />
      }

      <Route path={path + ':types/:id/detail'} component={Detail} />
      <Route path={path + 'delay'} component={Delay} />
      <Route path={path + 'change-to-black'} component={ChangeToBlack} />
    </>
  );
}

export default previewable(List);
