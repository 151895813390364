import React from 'react';
import { Route } from 'react-router-dom';
import List from './List';
import Volume from './Volume';

export default ({ path }) => (
  <>
    <Route path={path} component={List} />
    <Route path={`${path}:id/volume`} component={Volume} />
  </>
);
