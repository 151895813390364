import { useState, useEffect } from 'react';
import request from "utils/request";

export function formatYM(s) {
  const a = s.split('~');
  const b = a[0].split(':')[0];
  const c = a[1].split(':')[0];
  return b + '点 ~ ' + c + '点';
}

export const reasonArr = ['', '事故','交警扣车', '维修', '正退出名录库', '放假断电', '突发故障'];

export const weekReason = [
  { id: 1, name: '事故' } ,
  { id: 2, name: '交警扣车' },
  { id: 3, name: '维修' },
  { id: 4, name: '正退出名录库' },
  { id: 5, name: '放假断电' }
];

export const notWeekReason = [
  { id: 1, name: '事故' } ,
  { id: 2, name: '交警扣车' },
  { id: 7, name: '突发故障' }
];

export function filterTime(offline_time) {
  const date = moment().get('hour');
  const t1 = offline_time[0].split(' ')[0];
  const t2 = offline_time[1].split(' ')[0];
  const end = t1 === t2 ? moment(t2).subtract(-1, 'day').format('YYYY-MM-DD') : t2;
  const time1 = t1 + ' ' + (date < 10 ? ('0' + date) : date) + ':00:00';
  const time2 = end + ' ' + '00:00:00';
  return time1 + ' ~ ' + time2;
}
/**
 * 判断时间是工作日还是非工作日 true - 工作日 false - 非工作日
 * 返回true是非工作日 返回false是工作日
 * */
export function JudgeWeekDay(date, hour, minute) { //
  let flag = true;
  if(date === 5) {
   if(hour > 14) flag = false;
  }else if(date === 6 || date === 0) flag = false;
  return !flag;
}

export function getDateShow(date, hour, minute, second) {
  let week = '';
  switch (date) {
    case 1: week = '星期一'; break;
    case 5: week = '星期五'; break;
  }
  return week + (hour < 10 ? ('0' + hour) : hour) + ':' + (minute < 10 ? ('0' + minute) : minute) + ':' + (second < 10 ? ('0' + second) : second);
}

export function dateTypeChecked() {
  const [data, setData] = useState({dateType: 2, noticeMsg: null, selectOptions: [], severTime: 0});
  useEffect(() => {
    request('/web/report/dateTypeChecked')
      .then((data) => data.data)
      .then(setData)
  }, []);
 return data;
}
