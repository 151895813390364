/* eslint-disable no-shadow */
import { useState, useEffect } from 'react';

export default function useTable(dataSource, extraParams = {}, defaultLimit = 10, defaultQuery = {}) {
  const [unpaginationable, setUnpaginationable] = useState(false);
  // const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [rest, setRest] = useState({});
  // const [limit, setLimit] = useState(defaultLimit);
  const [query, setQuery] = useState({ ...extraParams, ...defaultQuery, page: 1, limit: defaultLimit });
  const [data, setData] = useState([]);

  // 分页器变化事件
  function onPaginationChange(nextPage, nextLimit) {
    if (unpaginationable) return;
    setUnpaginationable(true);
    // setPage(nextPage);
    // setLimit(nextLimit);
    // setPage(limit !== nextLimit ? 1 : nextPage)
    setQuery({ ...query, page: query.limit !== nextLimit ? 1 : nextPage, limit: nextLimit })
  }

  // 传入新的查询参数
  function assignQuery(updates = {}) {
    setQuery({ ...query, ...updates, page: 1 });
  }

  // 获取查询参数
  function getQuery() {
    // return { ...query, page, limit };
    return { ...query };
  }

  // 监听数据源
  useEffect(() => {
    const id = dataSource.subscribe(({ page, limit, total, data, rest }) => {
      // setQuery({ ...query, page, limit })
      // setPage(page);
      // setLimit(limit);
      setTotal(total);
      setRest(rest);
      setData(data);
      setUnpaginationable(false);
    });
    // 组件卸载时停止监听
    return () => dataSource.unsubscribe(id);
  }, []);

  // 查询
  useEffect(() => {
    // const params = {  page, limit, ...query };
    const params = {  ...query };
    dataSource.query(params);
  }, [query]);
  /*, page, limit*/

  return [data, {
    total,
    current: query.page,
    pageSize: query.limit,
    showSizeChanger: true,
    onChange: onPaginationChange,
    onShowSizeChange: onPaginationChange,
    disabled: unpaginationable,
  }, assignQuery, getQuery, rest];
}
