const renderSpan = (cell, row) => ({
  children: cell,
  props: {
    rowSpan: row.span,
  },
});

const rowSpanRender = (...args) => {
  if (typeof args[0] === 'function') {
    return (cell, row) => ({
      ...renderSpan(cell, row),
      children: args[0](cell, row),
    });
  }
  return renderSpan(...args);
};

export {
  rowSpanRender,
};
