import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import { dataSource, inputs } from './List';
import { message } from 'antd';

export default function Update({ history, match }) {
  const back = () => history.goBack();
  const onFinish = () => {
    message.success('点位信息更新成功');
    dataSource.refresh();
    back();
  };

  const id = match.params.id;

  return (
    <ModalFormPage
      init={['/river/river_project/editRiverSiteInfo', { site_id: id }]}
      submit={['/river/river_project/updateRiverSiteInfo', { site_id: id }]}
      title="修改点位信息"
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
    />
  );
}
