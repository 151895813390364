import React from 'react';
import { Route } from 'react-router-dom';
import List from './List'; // 列表
import ListReporting from './ListReporting'; // 上面的名录报备
import CheckRecord from './CheckRecord'; // 审核记录
import ShowFiles from './ShowFiles'; //  查看报备文件
import ReplayListReporting from './ReplayListReporting'; //重新报备
export default ({ path }) => (
  <>
    <Route path={path} component={List} />
    <Route path={path + 'list-reporting'} component={ListReporting} />
    <Route path={path + ':id/list-reporting'} component={ListReporting} />
    <Route path={path + ':id/show-files'} component={ShowFiles} />
    <Route path={path + ':id/check-record'} component={CheckRecord} />
    <Route path={path + ':id/replay-list-reporting'} component={ReplayListReporting} />
  </>
);
