// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1MI7Io4JxyfqjvIG9zMn8S {\n  position: absolute;\n  top: 30px;\n  background: #fff;\n  border-radius: 3px;\n  padding: 10px 20px 20px 20px;\n  box-shadow: 0 0 8px 2px #d4d4d4;\n  font-size: 14px;\n  z-index: 111;\n  width: 316px;\n}\n._1MI7Io4JxyfqjvIG9zMn8S > div:first-child {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n._1MI7Io4JxyfqjvIG9zMn8S ._1bqK6nEkpiWtEgdS9JP_I9 {\n  padding: 6px 0 0;\n}\n._1MI7Io4JxyfqjvIG9zMn8S ._1bqK6nEkpiWtEgdS9JP_I9 > p {\n  color: #1491FB;\n  margin-bottom: 10px;\n}", ""]);
// Exports
exports.locals = {
	"offline": "_1MI7Io4JxyfqjvIG9zMn8S",
	"content": "_1bqK6nEkpiWtEgdS9JP_I9"
};
module.exports = exports;
