import React from 'react';
import request from 'utils/request';
import {Button, Modal, Row, Col, Icon, Carousel} from "antd";
import styles from './Detail.scss';
import RoutesPreview from 'components/Map/RoutesPreview';
import {previewable} from 'components/Previewer';
class Detail extends React.PureComponent {
  state = {
    isMapLoaded: false,
    timestamp: (new Date()).getTime(),
    detailInfo: {
        alarm_record: []
    },
    linesData: [{
      coords: []
    }],
    planLinesData: [{
      coords: []
    }],
    imgFlag: false,
    previewImage: [],
  };
  back = () => this.props.history.goBack();
  componentDidMount () {
    request('/web/homemap/anotherTicketDetail', {
      id: this.props.match.params.id,
    }).then(body => body.data.data).then((data) => {
      this.setState({
        isMapLoaded: true,
        visible: false,
        detailInfo: data.detailInfo,
        planRoute: data.planRoute,
        realRoute: data.realRoute,
        parks: data.parks,
        timestamp: (new Date()).getTime(),
      });
    });
  }

  render () {
    const {detailInfo, timestamp, imgFlag, previewImage, dotPosition} = this.state;
    return (
      <div>
        <Modal
          visible
          width={1000}
          bodyStyle={{height: 650, overflow: 'auto'}}
          title="详情/路线"
          onCancel={this.back}
          onOk={this.back}
          footer={[<Button key="back" onClick={this.back}>关闭</Button>,]}>
          <div className={styles.first}>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>联单号</Col>
              <Col span={4} className={styles.count}>{detailInfo.number}</Col>
              <Col span={4} className={styles.labels}>运输车</Col>
              <Col span={4} className={styles.count}>{detailInfo.car_num}</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>司机</Col>
              <Col span={4} className={styles.count}>{detailInfo.driver}</Col>
              <Col span={4} className={styles.labels}>收单员</Col>
              <Col span={4} className={styles.count}>{detailInfo.acquirer_name}</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>电话</Col>
              <Col span={4} className={styles.count}>{detailInfo.driver_phone}</Col>
              <Col span={4} className={styles.labels}>电话</Col>
              <Col span={4} className={styles.count}>{detailInfo.acquirer_phone}</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>排放有效期</Col>
              <Col span={4} className={styles.count}>{detailInfo.emission_expiry_date}</Col>
              <Col span={4} className={styles.labels}>运输有效期</Col>
              <Col span={10} className={styles.count}>{detailInfo.transport_license_time}</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>排放单位</Col>
              <Col span={4} className={styles.count}>{detailInfo.start_name}</Col>
              <Col span={4} className={styles.labels}>运输单位</Col>
              <Col span={4} className={styles.count}>{detailInfo.transport}</Col>
              <Col span={4} className={styles.labels}>处置单位</Col>
              <Col span={4} className={styles.count}>{detailInfo.end_company}</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>排放证</Col>
              <Col span={4} className={styles.count}>{detailInfo.emission_num}</Col>
              <Col span={4} className={styles.labels}>运输证</Col>
              <Col span={4} className={styles.count}>{detailInfo.transport_license}</Col>
              <Col span={4} className={styles.labels}>处置地点</Col>
              <Col span={4} className={styles.count}>{detailInfo.end_address}</Col>
            </Row>
          </div>
          <div className={styles.first}>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>排放</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>开单时间</Col>
              <Col span={8} className={styles.count}>{detailInfo.open_time}</Col>
              <Col span={4} className={styles.count}></Col>
              <Col span={4} className={styles.count}></Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>运输物料</Col>
              <Col span={4} className={styles.count}>{detailInfo.materiel_name}</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>装车照片</Col>
              {
                  detailInfo.open_photo == ''?<Col span={4} className={styles.labels}>暂无图片</Col>:
                  <Col style={{color: '#1890ff', cursor: 'pointer'}} span={4} className={styles.labels} onClick={()=>this.props.preview.image(detailInfo.open_photo)}>查看照片</Col>
              }
              <Col span={4} className={styles.labels}>进场照片</Col>
                {
                    detailInfo.start_in_photo == ''?<Col span={4} className={styles.labels}>暂无图片</Col>:
                            <Col style={{color: '#1890ff', cursor: 'pointer'}} span={4} className={styles.labels} onClick={()=>this.props.preview.image(detailInfo.start_in_photo)}>查看照片</Col>
                }
              <Col span={4} className={styles.labels}>离场照片</Col>
                {
                    detailInfo.start_out_photo == ''?<Col span={4} className={styles.labels}>暂无图片</Col>:
                            <Col style={{color: '#1890ff', cursor: 'pointer'}} span={4} className={styles.labels} onClick={()=>this.props.preview.image(detailInfo.start_out_photo)}>查看照片</Col>
                }
            </Row>
          </div>
          <div className={styles.first} style={{display: 'flex flex-column', position: 'relative'}}>
            {this.state.isMapLoaded ? (
              <>
                <div className={styles.labels}>运输 ( * 绿色线路为实际路线, 红色线路为规划路线)</div>
                <div style={{ position: 'absolute', zIndex: 1, top: 30, left: 10 }}>
                  {
                    detailInfo.alarm_record.length === 0 ?
                      <div className={styles.record}>无违规行为</div> :
                      detailInfo.alarm_record.map(item => <div className={styles.record}>{item}</div>)
                  }
                </div>
                <RoutesPreview
                  key={timestamp}
                  height={400}
                  planRoute={this.state.planRoute}
                  realRoute={this.state.realRoute}
                  parks={this.state.parks}
                />
              </>
            ) : (
              <div style={{ width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Icon type="loading" /></div>
            )}
          </div>
          <div className={styles.first}>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>处置</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>收单时间</Col>
              <Col span={8} className={styles.count}>{detailInfo.acquirer_time}</Col>
              <Col span={4} className={styles.labels}>接受物料</Col>
              <Col span={4} className={styles.count}>{detailInfo.acquirer_materiel}</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>实收方量</Col>
              <Col span={4} className={styles.count}>{detailInfo.volumn}m³</Col>
                <Col span={4} className={styles.labels}>进场照片</Col>
                {
                    detailInfo.end_in_photo == ''?<Col span={4} className={styles.labels}>暂无图片</Col>:
                            <Col style={{color: '#1890ff', cursor: 'pointer'}} span={4} className={styles.labels} onClick={()=>this.props.preview.image(detailInfo.end_in_photo)}>查看照片</Col>
                }
              <Col span={4} className={styles.labels}>离场照片</Col>
                {
                    detailInfo.end_out_photo == ''?<Col span={4} className={styles.labels}>暂无图片</Col>:
                            <Col style={{color: '#1890ff', cursor: 'pointer'}} span={4} className={styles.labels} onClick={()=>this.props.preview.image(detailInfo.end_out_photo)}>查看照片</Col>
                }
            </Row>
          </div>
          <div className={styles.first}>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>结算</Col>
            </Row>
            <Row gutter={32} className={styles.rowStyle}>
              <Col span={4} className={styles.labels}>状态</Col>
              <Col span={4} className={styles.count}>{detailInfo.account_status}</Col>
              <Col span={4} className={styles.labels}>总金额</Col>
              <Col span={4} className={styles.count}>{detailInfo.account_price}元</Col>
              <Col span={4} className={styles.labels}>时间</Col>
              <Col span={4} className={styles.count}>{detailInfo.account_time}</Col>
            </Row>
          </div>
        </Modal>
        <Modal
          wrapClassName='modal-img'
          centered={true}
          visible={imgFlag}
          onCancel={() => this.setState({imgFlag: false, previewImage: []})}
          footer={null}
          width={1200}
          bodyStyle={{padding: 0}}>
          <Carousel autoplay={true}>
            {
              previewImage.map( (v,i) => {
                return (
                  <div key={i}><img src={v} style={{width: '100%'}} alt=""/></div>
                )
              })
            }
          </Carousel>
        </Modal>
      </div>
    )
  }
}

export default previewable(Detail);
