import {message} from "antd";

export function GetTicketShowTime(val){
  if(val){
    return val.split(' ')[1] ? val.split(' ')[1] === '00:00:00' ? '未知' : val.split(' ')[1] : '未知'
  }else return '未知'
}

export function CompareTime(start, end){
  var ms = new Date(end).getTime() - new Date(start).getTime();
  return Math.floor(ms/1000/60);
}

export function getLastOrNextTime(ticketTimes, ticketId, type){ // type 1 - 找上一单的起点  2 - 找下一单的终点  3 - 找自己的终点  4 - 找自己的起点
  let time = false;
  ticketTimes.map(({start_time, end_time, id}, i) => {
    if(id === ticketId){
      if(type === 1){
        if(i !== 0){
          time = (ticketTimes[i - 1].end_time || ticketTimes[i - 1].end_time === '') ? ticketTimes[i - 1].end_time.indexOf('00:00:00') > -1 ? false : ticketTimes[i - 1].end_time : false;
        }
      }else if(type === 2){
        if(i !== ticketTimes.length - 1){
          time = (ticketTimes[i + 1].start_time || ticketTimes[i + 1].start_time === '') ? ticketTimes[i + 1].start_time.indexOf('00:00:00') > -1 ? false : ticketTimes[i + 1].start_time : false
        }
      }else if(type === 3){
        time = (end_time || end_time === '') ? end_time.indexOf('00:00:00') > -1 ? false : end_time : false
      }else if(type === 4){
        time = (start_time || start_time === '') ? start_time.indexOf('00:00:00') > -1 ? false : start_time : false
      }
    }
  });
  return time
}

export function JudgeTicketByShow({ overDate, ticket_status, ticketTimes, ticketId, isSelf }){
  // 修改本单起点 与上一单终点做比较， 如果上一单终点没有，就与本单终点做比较
  // 修改本单终点 与下一单起点做比较， 如果下一单起点没有，就与本单起点做比较
  const time30 = isSelf ? 0 : 30;
  const time20 = isSelf ? 0 : 20;
  if(ticket_status === 1){ // 如果修改起点
    // 查找上一单的起点时间
    const lastOverTime = getLastOrNextTime(ticketTimes, ticketId, 1);
    if(lastOverTime){ // 如果有上一单的起点时间
      if(CompareTime(lastOverTime, overDate) >= time30){
        const selfOverTime = getLastOrNextTime(ticketTimes, ticketId, 3);
        if(selfOverTime){
          if(CompareTime(overDate, selfOverTime) >= time20){
            return false
          }
        }
      }
    }else{ // 没有上一单起点时间
      // 本单的终点时间
      const selfOverTime = getLastOrNextTime(ticketTimes, ticketId, 3);
      if(selfOverTime){
        if(CompareTime(selfOverTime, overDate) <= time20){
          return false
        }
      }
    }
  }else{
    const nextStartTime = getLastOrNextTime(ticketTimes, ticketId, 2);
    if(nextStartTime){
      if((CompareTime(overDate, nextStartTime)) >= time30){
        const selfStartTime = getLastOrNextTime(ticketTimes, ticketId, 4);
        if(selfStartTime){
          if(CompareTime(selfStartTime, overDate) >= time20){
            return false
          }
        }
      }
    }else{
      const selfStartTime = getLastOrNextTime(ticketTimes, ticketId, 4);
      if(selfStartTime){
        if(CompareTime(selfStartTime, overDate) >= time20){
          return false
        }
      }
    }
  }
  return true;
}

export function JudgeTicket({ overDate, ticket_status, ticketTimes, ticketId, isSelf }){
  // 修改本单起点 与上一单终点做比较， 如果上一单终点没有，就与本单终点做比较
  // 修改本单终点 与下一单起点做比较， 如果下一单起点没有，就与本单起点做比较
  const time30 = isSelf ? 0 : 30;
  const time20 = isSelf ? 0 : 20;
  if(ticket_status === 1){
    const lastOverTime = getLastOrNextTime(ticketTimes, ticketId, 1);
    if(lastOverTime){
      if(CompareTime(lastOverTime, overDate) < time30){
        message.warn(`选择联单起点时间不能小于上一单联单终点时间${!isSelf ? '(前30分钟)' : ''}，请重新选择`, 8);
        return false
      }else {
        const selfOverTime = getLastOrNextTime(ticketTimes, ticketId, 3);
        if(selfOverTime){
          if( CompareTime(overDate, selfOverTime) < time20){
            message.warn(`选择联单起点时间需要大于本联单终点时间${!isSelf ? '(20分钟)' : '' }，请重新选择`, 8);
            return false
          }
        }
      }
    }else{
      const selfOverTime = getLastOrNextTime(ticketTimes, ticketId, 3);
      if(selfOverTime){
        if( CompareTime(overDate, selfOverTime) < time20){
          message.warn(`选择联单起点时间需要大于本联单终点时间${!isSelf ? '(20分钟)' : '' }，请重新选择`, 8);
          return false
        }
      }
    }
  }else{
    const nextStartTime = getLastOrNextTime(ticketTimes, ticketId, 2);
    if(nextStartTime){
      if((CompareTime(overDate, nextStartTime)) < time30){
        message.warn(`选择结束联单时间不能小于下一单联单起点时间${!isSelf ? '(前30分钟)' : ''}，请重新选择`, 8);
        return false
      }else{
        const selfStartTime = getLastOrNextTime(ticketTimes, ticketId, 4);
        if(selfStartTime){
          if(CompareTime(selfStartTime, overDate) < time20){
            message.warn(`选择结束联单时间需要大于本联单起点时间${!isSelf ? '(20分钟)' : '' }，请重新选择`, 8);
            return false
          }
        }
      }
    }else{
      const selfStartTime = getLastOrNextTime(ticketTimes, ticketId, 4);
      if(selfStartTime){
        if(CompareTime(selfStartTime, overDate) < time20){
          message.warn(`选择结束联单时间需要大于本联单起点时间${!isSelf ? '(20分钟)' : '' }，请重新选择`, 8);
          return false
        }
      }
    }
  }
  return true;
}
