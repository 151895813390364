import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import echarts from 'echarts';
import debug from 'utils/debug';
import styles from './index.scss';
const logChartDispose = () => debug.action('Echarts实例已卸载') || true;
function useEcharts() {
  const [chart, setChart] = useState(null); // 存储echart实例
  const refGetter = useCallback(element => !!element && setChart(echarts.init(element)), []); // 创建echart实例
  const isChartUsable = useCallback(() => (!!chart && !chart.isDisposed()), [chart]); // 判断echart实例是否可用
  useEffect(() => () => (isChartUsable() && logChartDispose() && chart.dispose()), [chart]); // 卸载echart实例
  return [chart, isChartUsable, refGetter];
}
let bound = null; let bmap = null; let drawingManager = null;
function useMap(isChartUsable, chart, series, mapPoints, center, setCenterZoom, play_begin, flag, otherCenterFlag, locations, changeCenter, list, changeCenters, oneArr) {
  useEffect(() => {
    if (!isChartUsable()) return;
    (!play_begin) ? chart.clear() : null;
    let option = {
      tooltip: { trigger: 'item' }, animation: false,
      bmap: { center: center || [103.836472, 30.719426], zoom: 13, roam: true, mapStyle: {}, },
      toolbox: { itemSize: 25, itemGap: 15, top: 15, right: 25, iconStyle: { normal: { borderColor: '#000', borderWidth: 2 }, emphasis: { borderColor: '#FF396E', borderWidth: 2 } } },
      series: [...series]
    };
    if (bmap) {
      option.bmap.zoom = bmap.getZoom();
      let lng = bmap.getCenter().lng;
      let lat = bmap.getCenter().lat;
      bound = bmap.getBounds();
      if (bound.containsPoint(new BMap.Point(center[0], center[1])) !== true) {
        option.bmap.center = center;
      }
      else { option.bmap.center = [lng, lat] }
    }
    if (changeCenter) {
      option.bmap.center = center;
      option.bmap.zoom = 14
    }
    if (changeCenters) {
      option.bmap.center = center;
      option.bmap.zoom = 14
    }
    chart.setOption(option);
    bmap = chart ? chart.getModel().getComponent('bmap').getBMap() : null;
  }, [chart, series, mapPoints, locations]);
}
export default function TransportRecall({ arr }) {
  const [oneArr, setOneArr] = useState(arr)
  const [changeCenter, setChangeCenter] = useState(false)
  const [changeCenters, setChangeCenters] = useState(false)
  const [chart, isChartUsable, chartRefGetter] = useEcharts();
  const [mapPoints, setMapPoints] = useState([]);
  const [series, setSeries] = useState([]);
  const [centerZoom, setCenterZoom] = useState({ center: [103.836472, 30.719426], centerFlag: false });
  const [center, setCenter] = useState([103.836472, 30.719426]);
  const [centerFlag, setCenterFlag] = useState(false);
  const [otherCenterFlag, setOtherCenterFlag] = useState(false);
  const [trailBtn, setTrailBtn] = useState({ play_begin: false, visible: false, play_restart: false, slider: 0, moveSpeed: '400', playIndex: 0, movePos: 0 });
  const [list, setList] = useState([])

  const [locations, setLocations] = useState([])
  useEffect(() => {
    const webRTC1 = document.createElement('script');
    webRTC1.type = 'text/javascript';
    webRTC1.src = `https://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.css`;
    document.head.appendChild(webRTC1);
    const webRTC2 = document.createElement('script');
    webRTC2.type = 'text/javascript';
    webRTC2.src = `https://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js`;
    document.head.appendChild(webRTC2);
  }, []);

  useEffect(() => { //控制显示
    const SeriesLinesEffect = (data, name, symbolType = 'image:///images/center/a4.png', symbolSize, symbol = ['none', 'arrow'], color, silent = false) => {
      return {
        type: 'lines', name: name, polyline: true, silent: true, coordinateSystem: 'bmap', symbol: symbol,
        zlevel: 5,
        effect: { show: true, period: 6, trailLength: 0, symbol: symbolType, symbolSize: symbolSize, },
        tooltip: { show: false, },
        lineStyle: {
          normal: {
            color: color,//'#FF9316',
            opacity: 1, width: 2, curveness: 0.5
          },
        },
        progressiveThreshold: 500, progressive: 200, data: data,
        silent,
      }
    };
    let coords = [];
    if (arr.length > 1) {
      coords.push({ coords: arr.map(({ lng, lat }) => ([lng, lat])) })
      setCenter([arr[0].lng, arr[0].lat])
    }
    if (arr.length > 0) {
      setSeries([
        SeriesLinesEffect(coords, 'trailLines', 'image:///images/center/a4.png', [15, 42.5], ['none', 'arrow'], '#FF5152', true)
      ])
    }
  }, [arr]);
  useMap(isChartUsable, chart, series, mapPoints, center, centerZoom, trailBtn.play_begin, centerFlag, otherCenterFlag, locations, changeCenter, list, changeCenters, oneArr);
  return (
    <div className={styles.chart} ref={chartRefGetter} />
  )
}
