import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import {message} from 'antd';

const options = [
  { value: 1, label: "GPS无轨迹" },
  { value: 2, label: "起终点颠倒" },
  { value: 3, label: "轨迹漂移" },
  { value: 4, label: "其它" },
];
const inputs = [
  { label: '无法核实原因', placeholder: '请选择无法核实原因', type: 'select', options: '/web/ticket/getUnVerifyReason', key: 'reason_id', valueKey: 'id', labelKey: 'name', required: true, style: {width: 350} }
  ];

export default function ListReport({ history, match, ticketId, onClose, onSuccess }) {

  function onFinish(){
    message.success('修改成功');
    onSuccess();
  }
  return (
    <ModalFormPage
      title="无法核实"
      submit={['/web/ticket/recordVerifyReason', {id: ticketId}]}
      inputs={inputs}
      onCancel={onClose}
      onFinish={ onFinish }
      modalProps={{
        width: 600,
      }}
      formProps={{
        labelCol: { xs: { span: 6 } },
        wrapperCol: { xs: { span: 18 } },
      }}
    />
  );
}
