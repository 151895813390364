import React from 'react';
import { Divider } from 'antd';
import { previewable } from 'components/Previewer';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import NewDeleteButton from 'ui/TableCell/NewDeleteButton';
import LinkButton from 'ui/TableCell/LinkButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';
import { Link } from 'react-router-dom';

export const dataSource = new DataSource('/river/river_project/getRiverSiteLists');

export const inputs = [
  { label: '项目名称', placeholder: '请选择项目名称', key: 'project_id', type: 'select', options: '/river/river_project/getRiverProjectOption', labelKey: 'name', valueKey: 'id', required: true },
  { label: '点位名称', placeholder: '请填写点位名称', key: 'name', required: true },
  { label: '点位地址', placeholder: '请填写点位地址', key: 'address', required: true },
  { label: '点位经纬度', placeholder: '请拾取点位经纬度', key: 'lng_lat', required: true, type: 'pointer' },
];

function List({ priview }) {
  const columns = [
    serial(true),
    { title: '项目名称', dataIndex: 'project_name' },
    { title: '点位名称', dataIndex: 'name' },
    { title: '点位地址', dataIndex: 'address', render: (address, { id, lng, lat, name }) => <Link to={{ pathname: `./${id}/map`, state: {name, lng, lat}}}>{address}</Link> },
    { title: '点位经度', dataIndex: 'lng', width: width(6) },
    { title: '点位纬度', dataIndex: 'lat', width: width(6) },
    { title: '更新时间', dataIndex: 'updated_time', width: width(14) },
    { title: '更新人', dataIndex: 'updated_name', width: width(6) },
    actions({
      width: width(8),
      render: ($, {id}) => (
        <>
          <LinkButton to={`./${id}/update`} />
          <Divider type="vertical" />
          <NewDeleteButton title="确认删除该项目点位信息?" type="danger" api="/river/river_project/deleteRiverSiteList" site_id={id} dataSource={dataSource}>删除</NewDeleteButton>
        </>
      )
    })
  ];
  const querys = [
    { title: ['更新', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
    { title: '请选择项目', key: 'project_id', type: 'select', options: '/river/river_project/getRiverProjectOption', valueKey: 'id', labelKey: 'name', style: { width: 240 } },
    // { title: '请选择运企', key: 'transport_id', type: 'select', options: '/river/river_base/getRiverTransportOption', valueKey: 'id', labelKey: 'name', style: { width: 120 } },
    { title: '请输入点位名称、更新人模糊搜索', key: 'keyword', style: { width: 300 } },
  ];

  const buttons = [
    { title: '新增', link: './create', icon: 'plus'},
    { title: '导出', download: '/river/river_project/exportRiverSiteLists' },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}

export default previewable(List)
