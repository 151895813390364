// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2U_GRlxLL9s7qz89vtdmhJ {\n  border-bottom: 2px solid rgba(34, 36, 38, 0.15);\n}\n._2U_GRlxLL9s7qz89vtdmhJ ._1lNU7REKdxhMeldmFA2VG_ {\n  color: #999999;\n  font-weight: bold;\n}\n._2U_GRlxLL9s7qz89vtdmhJ ._10kydDbanIe6K0JslRJeCt {\n  color: #333333;\n}\n._2U_GRlxLL9s7qz89vtdmhJ .VoS9-lGLAE4kEOJMHuVFi {\n  margin: 10px 0;\n}\n\n._1w1AMJdexaaGWGw55QA17N {\n  display: inline-block;\n  padding: 3px;\n  border-radius: 3px;\n  color: #fff;\n  background-color: green;\n  margin-right: 5px;\n}\n\n.Fs_pBMDbu-6jNxDJ9-_Gy {\n  display: grid;\n  row-gap: 10px;\n  font-size: 20px;\n  justify-content: flex-start;\n  justify-items: baseline;\n}\n.Fs_pBMDbu-6jNxDJ9-_Gy ._1Rtu3Fs3cVCrN4NI2g21nb {\n  position: relative;\n}\n.Fs_pBMDbu-6jNxDJ9-_Gy ._1Rtu3Fs3cVCrN4NI2g21nb:after {\n  position: absolute;\n  content: \"*\";\n  top: 0;\n  right: -10px;\n  font-size: 12px;\n  color: red;\n}\n.Fs_pBMDbu-6jNxDJ9-_Gy ._-5JvHonNAUVFVBUb28juo {\n  display: flex;\n  gap: 10px;\n}\n.Fs_pBMDbu-6jNxDJ9-_Gy ._2F6yIYbNeQfkrwo0-tS-kP {\n  color: #1684FC;\n}", ""]);
// Exports
exports.locals = {
	"first": "_2U_GRlxLL9s7qz89vtdmhJ",
	"labels": "_1lNU7REKdxhMeldmFA2VG_",
	"count": "_10kydDbanIe6K0JslRJeCt",
	"rowStyle": "VoS9-lGLAE4kEOJMHuVFi",
	"record": "_1w1AMJdexaaGWGw55QA17N",
	"checks": "Fs_pBMDbu-6jNxDJ9-_Gy",
	"label": "_1Rtu3Fs3cVCrN4NI2g21nb",
	"flexs": "_-5JvHonNAUVFVBUb28juo",
	"color": "_2F6yIYbNeQfkrwo0-tS-kP"
};
module.exports = exports;
