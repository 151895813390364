/* eslint-disable no-shadow */
/* eslint-disable no-sparse-arrays */
import React, { useState, useEffect } from 'react';
import { Redirect, Route, NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon, Dropdown, Menu, Badge } from 'antd';
import { logOut, updateBadge } from 'actions/user';
import connector from 'connectors/user';
import request from 'utils/request';
import debug from 'utils/debug';
import Password from '../Login/Password';
import styles from './index.scss';
import routes from './routes';
import './icons';
import IsModifyPassword from './IsModifyPassword';

// [预处理]相对路径转绝对路径
const root = (function decode([title, id, relative = '', options, children], parent = '') {
  const absolute = parent + relative + '/';
  const component = !Array.isArray(children) && children;
  const nodes = Array.isArray(children) && children.map(child => decode(child, absolute));
  return { title, id, absolute, options, component, nodes };
}([, , , , routes]));

const isActive = path => ($, { pathname }) => pathname.includes(path);

const Header = (nodes, user, badge) => (
  <div className={styles.header}>
    <div className={styles.content}>
      <div className={styles.brand}>
        <img className={styles.logo} src="/logo-white-border.png" alt="" />
        <div className={styles.name}>{SITE_NAME}</div>
      </div>
      <div className={styles.nav}>
        {nodes.map(({ absolute, link, title }) => (
          <NavLink key={absolute} to={link} activeClassName={styles.active} isActive={isActive(absolute)}>{title}</NavLink>
        ))}
      </div>
      <div className={styles.right}>
        <Dropdown
          overlay={(
            <Menu>
              <Menu.Item><Link to="/password">修改密码</Link></Menu.Item>
              <Menu.Item onClick={() => logOut()}>退出登录</Menu.Item>
            </Menu>
          )}
        >
          <div className={styles.user}>
            <Icon className={styles.icon} type="user" />
            <div className={styles.name}>{user.info.name}</div>
          </div>
        </Dropdown>
        <Link className={styles.badge} to="/illegal-list/verification-accounts/"><Badge count={badge} /></Link>
      </div>
    </div>
    {/* <IsModifyPassword /> */}
  </div>
);

const NeedTransPort = () => {
  return (
    <div className={styles.needs}>
      <NavLink to="/transportation/">
        <div>运企信息不完整</div>
        <div>请及时前往修改</div>
      </NavLink>
    </div>
  );
}

function Routes({ user, location }) {
  debug.render('Routes');

  const { id, menu = [], badge } = user;
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    if (id) {
      updateBadge();
    }
  }, []);

  useEffect(() => {
    debug.effect('Routes');

    // 根据ID过滤
    function filter(nodes) {
      return nodes.reduce((final, node) => {
        if (menu.includes(node.id)) {
          if (node.component) return [...final, node];
          if (node.nodes) return [...final, { ...node, nodes: filter(node.nodes) }];
        }
        return final;
      }, []);
    }

    // 获得直达最底层的链接
    function link(nodes) {
      return nodes.reduce((final, node) => {
        if (node.component) return [...final, { ...node, link: node.absolute }];
        if (node.nodes) {
          const ns = link(node.nodes);
          const lk = ns[0].link;
          return [...final, { ...node, nodes: ns, link: lk }];
        }
        return final;
      }, []);
    }

    setNodes(link(filter(root.nodes)));
  }, [user]);

  if (!id) return <Redirect to="/login" />;
  if (location.pathname === '/' && nodes[0]) return <Redirect to={nodes[0].link} />;

  const asides = nodes.map((node) => {
    if (node.nodes) {
      const element = (
        <div className={styles.aside}>
          <div className={styles.content}>
            {node.nodes.map(({ absolute, link, title, options: { icon } }) => (
              <NavLink key={absolute} to={link} activeClassName={styles.active} isActive={isActive(absolute)}>
                {icon && <FontAwesomeIcon icon={icon} />}
                <span className={styles.title}>{title}</span>
                <span className={styles.tail}><svg width="10" height="6"><polyline points="0,6 5,0 10,6" /></svg></span>
              </NavLink>
            ))}
          </div>
        </div>
      );
      const route = <Route key={node.absolute} path={node.absolute} component={() => element} />;
      return route;
    }
    return null;
  }).filter(v => v);

  const tabs = nodes.reduce((final, aside) => {
    if (aside.nodes) {
      return [...final, ...aside.nodes.filter((tabs) => tabs.nodes).map((tabs) => {
        const element = (
          <div className={styles.tabs}>
            <div className={styles.segement}>
              {tabs.nodes.map(({ absolute, link, title }) => (
                <NavLink key={absolute} to={link} activeClassName={styles.active} isActive={isActive(absolute)}>{title}</NavLink>
              ))}
            </div>
          </div>
        );
        const route = <Route key={tabs.absolute} path={tabs.absolute} component={() => element} />;
        return route;
      })];
    }
    return final;
  }, []);

  const mains = ((function loop(nodes) {
    return nodes.reduce((final, node) => {
      if (node.component) return [...final, node];
      return [...final, ...loop(node.nodes)];
    }, []);
  })(nodes)).map((node) => {
    const Component = node.component.default;
    const Page = (props) => <Component {...props} path={node.absolute} />;
    const MemoPage = React.memo(Page, () => true);
    return <Route key={node.absolute} path={node.absolute} component={MemoPage} />;
  });

  mains.push(<Route key="password" path="/password" component={Password} />);

  return (
    <div className={styles.container}>
      <IsModifyPassword />
      {Header(nodes, user, badge)}
      {asides}
      {tabs}
      <div className={styles.main}>{mains}</div>
      {user.roles.id === 101 && user.roles.info_complete === 0 && <NeedTransPort />}
    </div>
  );
}

export default connector(React.memo(Routes, (prev, next) => prev.user === next.user && prev.location.pathname !== '/'));
