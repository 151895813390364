/* eslint-disable */
import React, { useMemo } from 'react';
import './index.global.scss';
import ReactDOM from 'react-dom';
import store from 'stores/user';
import {Form, Input, Table, Select, Button, InputNumber, Radio, message, Modal, Icon, Tooltip, Spin} from 'antd';
import moment from 'moment'
import request, {open} from 'utils/request';
import {Link, useHistory} from "react-router-dom";
import {ImagePreviewer} from 'components/Previewer';
import controller from './controller';
import HighLight from './HighLight';
import ExceptionLogs from './ExceptionLogs';
import PrintTicket from '../PrintTicket';
import classNames from 'classnames';
import styles from './List.scss';
import FormItem from 'antd/lib/form/FormItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const {Option} = Select;
const RadioGroup = Radio.Group;
let received_msg = [];
let start_time = moment().subtract(0, 'day').startOf('day');
let end_time = moment().endOf('day');
let LODOP = null;
let selectPrinter = null;
/** 导出 */
function base64(s) { return window.btoa(unescape(encodeURIComponent(s))) }

const CreateOneFormPage = function () {
  LODOP = getLodop();
  LODOP.PRINT_INIT("打印控件功能演示_Lodop功能_表单一");
  LODOP.SET_PRINT_STYLE("FontSize", 18);
  LODOP.SET_PRINT_STYLE("Bold", 1);
  LODOP.SET_PRINT_PAGESIZE(1, 2160, 930, '');
  LODOP.SET_PRINT_MODE("POS_BASEON_PAPER", true);
  //LODOP.ADD_PRINT_TEXT(50, 231, 260, 39);
  var strStyleCSS = '<link href="/_flex.min.css" type="text/css" rel="stylesheet">';
  var strFormHtml = '<head>' + strStyleCSS + '</head><body><div style="width:750px;">' + document.getElementById("print_t1").innerHTML + '</div></body>';
  LODOP.ADD_PRINT_HTM(25, 25, 150, "BottomMargin:0", strFormHtml);
};
// const oldInstance = [1784, 2999, 3135, 3292, 3323, 2254, 3324, 1770, 3295, 3325, 2218, 3297, 3298, 3326, 3300, 3301, 3302, 3250, 3303, 3142, 3304, 3327, 3308, 3105, 3005, 3088, 3328, 3329, 3309, 3314, 2994, 3210, 3222, 3205, 3221, 3317, 3223, 3321, 3225, 3322, 3056, 2417, 3573, 3214, 7033, 3212];
// const oldInstance = [1198,1506,1549,1565,1576,1585,1726,1730,1770,1784,1988,2218,2220,2254,2346,2357,2358,2417,2433,2463,2464,2624,2659,2755,2811,2880,2994,2996,2999,3002,3005,3065,3095,3102,3105,3106,3107,3108,3109,3120,3121,3135,3142,3203,3210,3212,3213,3222,3250,3266,3273,3282,3295,3296,3298,3301,3302,3308,3309,3314,3323,3324,3325,3326,5588,5675,5703,5884,6294,6359,6920,7032,7033,7637,7638,7639,7644,7656,7657,7665,7819,7820,7821,7823, 5894];

const isInArray = function (arr, value) {
  for (var i = 0; i < arr.length; i++) {
    if (value === arr[i]) {
      return true;
    }
  }
  return false;
};

const compare = (filed, value) => {
  return (obj1, obj2) => {
    if(obj1[filed].indexOf(value) > -1){
      if(obj2[filed].indexOf(value) > -1 ){
        if(obj1['serial'] > obj2['serial']){
          return 1
        }else if(obj1['serial'] < obj2['serial']){
          return -1
        }else{
          return 0
        }
      }else{
        return -1
      }
    }else {
      return 1
    }
  }
}
const compareByOrder = () => {
  return (obj1, obj2) => {
    if(obj1['serial'] < obj2['serial']){
      return -1
    }else{
      return 1
    }
  }
}
export const oldInstance = [3298]
let lockReconnect = false;//避免重复连接
let wsUrl = "ws://221.237.182.170:8335";		// websocket链接
let ws = null;
export const eleInstanceIds = []; // 有电子出门条的砂石场
export const eleBuildsIds = [12700, 15629, 15820]; // [14208,15629,15716]; // 有电子出门条的工地
export const outInstanceIds = [277, 284, 281, 182, 294, 296, 301, 302, 311, 312]; // 出货的砂石场
// export const outInstanceIds = [277, 284, 281, 182, 294, 296, 301, 302, 311, 312, 313]; // 出货的砂石场
export const outBuilds = [
  { acquirer: 1, endTime: "", id: 15600, name: "成都西建盛砂建材有限公司",pinyin: "cdxjssjcyxgs", startTime:"", ticketTime: "2022-09-23 13:05:48"},
  { acquirer: 1, endTime: "", id: 15633, name: "四川友翔再生资源有限责任公司",pinyin: "scyxzszyyxzrgs", startTime:"", ticketTime: "2022-09-23 13:05:48"},
  { acquirer: 1, endTime: "", id: 15638, name: "德阳润德商业运营管理有限公司",pinyin: "dyrdsyyyglyxgs", startTime:"", ticketTime: "2022-09-23 13:05:48"},
  { acquirer: 1, endTime: "", id: 15704, name: "四川向通建设工程有限责任公司",pinyin: "scxtjsgcyxzrgs", startTime:"", ticketTime: "2022-09-23 13:05:48"},
  { acquirer: 1, endTime: "", id: 15956, name: "春垚建材公司",pinyin: "cyjcgs", startTime:"", ticketTime: "2022-09-23 13:05:48"},
  { acquirer: 1, endTime: "", id: 15955, name: "双江中天建材公司",pinyin: "sjztjcgs", startTime:"", ticketTime: "2022-09-23 13:05:48"},
  { acquirer: 1, endTime: "", id: 15983, name: "成都艺鑫行商贸有限公司",pinyin: "cdyxhsmyxgs", startTime:"", ticketTime: "2022-09-23 13:05:48"},
  { acquirer: 1, endTime: "", id: 16281, name: "四川涵锐鑫建材销售有限公司",pinyin: "cdyxhsmyxgs", startTime:"", ticketTime: "2022-09-23 13:05:48"},
  { acquirer: 1, endTime: "", id: 16291, name: "四川恒邦志顺建材有限公司",pinyin: "cdyxhsmyxgs", startTime:"", ticketTime: "2022-09-23 13:05:48"},
  { acquirer: 1, endTime: "", id: 16290, name: "四川蜀交商贸有限公司",pinyin: "cdyxhsmyxgs", startTime:"", ticketTime: "2022-09-23 13:05:48"},
]; // 出货的工地选择

export default Form.create({})(class SandStoneReceiver extends React.PureComponent {
  columns = [
    {title: '序号', dataIndex: 'serial', key: 'serial', fixed: 'left', width: 70},
    {title: '车牌号', dataIndex: 'car_num', key: 'car_num', fixed: 'left', width: 150, render: (v) => <HighLight source={v} search={ this.state.keyword } />},
    {title: '工地项目', dataIndex: 'building_name', key: 'building_name'},
    {title: '识别时间', dataIndex: 'end_time', key: 'end_time'},
    {title: '货箱方量', dataIndex: 'car_volumn', key: 'car_volumn', render: (e) => parseFloat(e).toFixed(1)},
    {title: '电子出门条', dataIndex: 'ht_id', key: 'ht_id', render: (e) => <span style={{ color: !e ? '#FF2525' : '#00C77D'}}>{!e ? '无' : '有'}</span>},
    {title: '上一趟工地项目', dataIndex: 'last_building_name', key: 'last_building_name'},
    {title: '联单来源', dataIndex: 'source', key: 'source', render: function (t, e) {
        return t === '补录' ?
          <Tooltip placement="top" title={e.add_reason}>
            <Button type={'default'} size={'small'}>{t}</Button>
          </Tooltip> : <span>{t}</span>
      }
    },
  ];
  columns2 = [
    {title: '序号', dataIndex: 'serial', key: 'serial', fixed: 'left', width: 70},
    {title: '车牌号', dataIndex: 'car_num', key: 'car_num', fixed: 'left', width: 150, render: (v) => <HighLight source={v} search={ this.state.keyword } />},
    {title: '工地项目', dataIndex: 'building_name', key: 'building_name'},
    {title: '识别时间', dataIndex: 'end_time', key: 'end_time'},
    {title: '货箱方量', dataIndex: 'car_volumn', key: 'car_volumn', render: (e) => parseFloat(e).toFixed(1)},
    {title: '上一趟工地项目', dataIndex: 'last_building_name', key: 'last_building_name'},
    {title: '联单来源', dataIndex: 'source', key: 'source', render: function (t, e) {
        return t === '补录' ?
          <Tooltip placement="top" title={e.add_reason}>
            <Button type={'default'} size={'small'}>{t}</Button>
          </Tooltip> : <span>{t}</span>
      }
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      autoCompleteResult: [],
      confirm: true,
      checkedList: [],
      dataSource: [],
      reason: false,
      keyword: '', // 筛选关键字
      has_human_ticket: 0, // 筛选有无出门条
      cancel: '',
      limit: 10,
      page: 1,
      total: 0,
      time: [start_time.format('YYYY-MM-DD HH:mm:ss'), end_time.format('YYYY-MM-DD HH:mm:ss')],
      returnGoods: [
        {label: '是', value: 1},
        {label: '否', value: 0},
      ],
      history: props.history,
      return_goods: 0,
      userInstance: [],
      instance_id: '选定一个实例',
      images: ['/images/icon.png', '/images/icon.png', '/images/icon.png'],
      showImg: '',
      img_flag: false,
      form_data: {},
      building_idss: 15600,
      materielType: 1, // 收货类型
      department_id: '',
      total_volume: 0,
      base_volume: 0,
      modal_flag: false,
      instance_name: '',
      printYear: new Date().getFullYear(),
      printMonth: new Date().getMonth() + 1,
      printDay: new Date().getDate(),
      receiverName: store.getState().info.name,
      userAreaId: store.getState().info.area_id,
      car_num: '',
      printCompany: '成都宏图华信投资有限公司',
      printJson: {
        number: '',
        car_num: '',
        volume: 0,
        reason: '',
        driver_name: '',
        build_name: '',
        real_volumn: 24,
        deduction_volume: 0,
        date: '',
        car_number: 0,
        deduction_reason: '',
        car_type: '',
        car_directory: '',
        car_plan: '',
        materielName: '连砂石'
      },
      buildArr: [],
      materialArr: [], // 物料类型数组      
      reasonRadio: '泥巴',
      buildingArr: [],
      building_id: '',
      is_acquirer: {show: 0, car_num: '', end_time: ''},
      rowSelect: {},
      secondReceiver: JSON.parse(sessionStorage.getItem('secondReceiver')) ? JSON.parse(sessionStorage.getItem('secondReceiver')).name : '',
      receiverSpin: false,
      save: false,
      spinLoading: false,
      defaultDeduction: '1.0',
      localname: '', // 存储当日的localStroage数据
      roleId: store.getState().roles.id,
      ht_visible: false, // 是否查看异常处理详情
      carSize: {
        inner_width: 0,
        inner_long: 0,
        inner_height: 0, // 数据库的货箱高度
        height: 0, // 输出的高
      }
    }
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `/LodopFuncs.js`;
    document.head.appendChild(script);
    this.getUserInstance();
    this.getHistoryBuild();
    this.getMaterialArr();
    /** 进入页面首先判断有没有当日的local 如果有则不做操作，没有则默认添加一个 */
    this.getLocal();
    const setSandStone = JSON.parse(sessionStorage.getItem('setSandstone'));
    const setIsOutBuild = JSON.parse(sessionStorage.getItem('setIsOutBuild'));
    if (!setIsOutBuild) {
      
      if (this.state.roleId === 118) {
        sessionStorage.setItem('setIsOutBuild', JSON.stringify({ building_idss: 15600 }))
        // message.info('请选择工地')
      }
    } else {
      this.setState({ building_idss: setIsOutBuild.building_idss })
    }
    if(setSandStone && setSandStone.instance_id){
      this.onInstanceChange({}, {props: {value: setSandStone.instance_id, title: setSandStone.department_id, instance_name: setSandStone.instance_name, materielType: setSandStone.materielType}})
    } else{
      // const storeInfo = store.getState();
      // const roleId = storeInfo.roles.id;
      // if (roleId !== 118) {
        this.setState({
          modal_flag: true
        })
      // }
    }
    controller.onRefresh(() => {
      const that = this;
      const sessionData = JSON.parse(sessionStorage.getItem('ticketAddAndPrint'));
      const printCompany = !isInArray(oldInstance, sessionData.building_id) ? '成都宏信投建材有限公司' : '成都宏图华信投资有限公司'
      const printJson = {
        number: sessionData.number,
        car_num: sessionData.car_num,
        volume: sessionData.volume,
        reason: sessionData.reason,
        driver_name: sessionData.driver_name,
        build_name: sessionData.build_name,
        real_volumn: sessionData.real_volumn,
        deduction_volume: sessionData.deduction_volume,
        date: sessionData.date,
        transport_count: sessionData.transport_count,
        deduction_reason: sessionData.reason,
        car_type: sessionData.car_type,
        car_directory: sessionData.car_directory,
        car_plan: sessionData.car_plan,
        materielName: sessionData.materielName,
        marked_volumn: sessionData.marked_volumn
      }
      this.setState({
        printCompany: printCompany,
        printJson: printJson
      });
      this.getLocalData({ localtext: '补录的联单数据', printCompany, ...printJson })
      if(sessionData.types){
        this.printTicket();
        this.printingCount(sessionData.ticket_id)
      }
      setTimeout(function () {
        that.getRightTableData({current: that.state.page, pageSize: that.state.limit});
      }, 3000)
    });
  }

  componentWillUnmount() {
    this.handleClose();
  }
  /** 设置当日的localStroage  */
  getLocal() {
    const localname = moment().format('YYYY-MM-DD');
    const local = localStorage.getItem(localname);
    if (!local) {
      localStorage.setItem(localname, JSON.stringify([]))
    }
    this.setState({
      localname: localname,
    })
  }
  /** 根据传入的值去对localStroage做存储 */
  getLocalData(rest) {
    const { localname } = this.state;
    const local = localStorage.getItem(localname);
    const data = JSON.parse(local);
    (data ? data : []).push({localtime: moment().format('YYYY-MM-DD HH:mm:ss'), ...rest});
    localStorage.setItem(localname, JSON.stringify(data))
  }
  /** 导出收货单日志 */
  export() {
    const local = localStorage.getItem(this.state.localname);
    const data = JSON.parse(local);
      
    //Worksheet名
    let worksheet = `${this.state.localname}收货日志`;
    let uri = 'data:application/vnd.ms-excel;base64,';
    //下载的表格模板数据
    let template = data.map((item) => JSON.stringify(item) + '\n');
    //下载模板
    var link = uri + base64(template);
    var a = document.createElement("a");
    a.download = `${this.state.localname}收货日志.txt`;
    a.href = link;
    a.click();
    a.remove();
  }
  /** 清除日志 */
  clearDate = () => {
    const len = localStorage.length; // 获取长度
    for(let i = 0; i < len; i++) {
      const getKey = localStorage.key(i);

      if (getKey.includes('2021') || getKey.includes('2022') || getKey.includes('2023')) {
        localStorage.removeItem(getKey);
      }
    }
  }
  /** 获取物料类型 */
  getMaterialArr = () => {
    request('/web/route/initMateriel', {})
      .then((body) => body.data.data)
      .then((data) => this.setState({ materialArr: data ? data : []}))
  }
  /** 获取工地 */
  getHistoryBuild = () => {
    request('/web/building/history', {})
      .then(body => body.data.data)
      .then(data => this.setState({buildArr: data}));
  };
  /** 打印 */
  printTicket = () => {
    CreateOneFormPage();
    if (selectPrinter) {
      LODOP.PRINTA();
    }
    else {
      LODOP.PRINT();
    }
    selectPrinter = false;
  };
  /** 提交数据 */
  handleSubmit = (e) => {
    let that = this;
    e.preventDefault();
    if (that.state.form_data.building_id === 0) {
      message.warn('请选择工地')
      return false;
    }
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (that.state.reason) {
        if (err) {
          return false
        }
      }
      this.setState({
        confirm: true,
        receiverSpin: true
      });
      that.state.form_data.instance_id = that.state.instance_id;
      that.state.form_data.department_id = that.state.department_id;
      const deduction_volume = that.state.form_data.deduction_volume
      that.getLocalData({ localtext: '点击确认收货的传到后台的联单数据', reasonRadio: that.state.reasonRadio, ...that.state.form_data });
      const isKf = (!deduction_volume || deduction_volume === 0 || deduction_volume === '0.0' || deduction_volume === '0.00');
      request({
        url: '/web/ticket/enterTicket',
        data: {
          ...that.state.form_data,
          volume: that.state.form_data.cancel === 1 || that.state.form_data.cancel === '1' ? 0 : that.state.form_data.volume,
          reason: !isKf ? that.state.reasonRadio === '其他' ? that.state.form_data.reason : that.state.reasonRadio : '',
          second_receiver: that.state.secondReceiver,
          human_ticket_id: that.state.form_data.ht_id,
        }
      }).then(res => {
        if (res.data.code !== 200) {
          this.setState({
            receiverSpin: false,
          });
          that.getLocalData({ localtext: '点击确认收货收货未成功的数据', msg: res.data.msg });
          return message.error(res.data.msg);
        }
        const response = res.data.data;
        const printBuildingId = that.state.form_data.building_id;
        const printCompany = !isInArray(oldInstance, printBuildingId) ? '成都宏信投建材有限公司' : '成都宏图华信投资有限公司';
        that.getLocalData({localtext: '确认收货后的打印数据', printCompany, ...response });
        this.setState({
          receiverSpin: false,
          reason: false,
          printCompany: printCompany,
          printJson: {
            number: response.number,
            car_num: response.car_num,
            volume: response.volume,
            reason: response.reason,
            driver_name: response.driver_name,
            build_name: response.building_name,
            real_volumn: response.real_volumn,
            deduction_volume: response.deduction_volume,
            date: response.date,
            transport_count: response.transport_count,
            deduction_reason: (response.deduction_volume === 0 || response.deduction_volume === '0.0' || response.deduction_volume === '0.00') ? '' : response.deduction_reason,
            car_type: response.car_type,
            materielName: response.materiel_name,
            marked_volumn: response.marked_volumn
          }
        });

        this.getHistoryBuild();
        if (that.state.form_data.cancel === 1 || that.state.form_data.cancel === '1') {
          message.success('退货成功');
        }
        else {
          if(!that.state.save){
            if(response.building_name === '' || response.building_name === null) {
              message.warn('数据异常，请在已收货界面补打该联单！');
            }else {
              that.printTicket();
              that.printingCount(that.state.form_data.id);
            }
          }
          message.success('收货成功，请在“已入库”页面查看详情');
        }
        this.getRightTableData();
        that.setState({
          form_data: {},
        });
      })
    });
  };
  /** 记录打印次数 */
  printingCount = (ticket_id) => {
    this.getLocalData({ localtext: '记录打印次数的请求', ticket_id })
    request('web/ticket/PrintingCount', {ticket_id})
      .then(body => {})
  }
  /** 获取砂石场数据 */
  getUserInstance = () => {
    let that = this;  
    request({
      url: '/web/instance/getUserInstance',
      data: {area_id: store.getState().info.area_id}
    }).then(res => {
      const data = res.data.data;
      const signal = data.filter(({ instance_id }) => outInstanceIds.includes(instance_id));
      const arr = that.state.roleId === 118 ? (signal ? signal : []) : data.filter(({ instance_id, ...rest }) => !outInstanceIds.includes(instance_id));
      that.setState({
        userInstance: arr,        
      });
    })
  }
  /** 获取联单列表数据 */
  getRightTableData = () => {
    let that = this;
    request({
      url: '/web/ticket/getNoAcquirer',
      data: {
        keyword: that.state.keyword,
        instance_id: that.state.instance_id,
        department_id: that.state.department_id,
        has_human_ticket: that.state.has_human_ticket,
        building_id: outInstanceIds.includes(that.state.instance_id) ? this.state.building_idss : undefined,
      }
    }).then(res => {
      const data = res.data.data;
      const source = data.data.map((v, i) => ({
        serial: (i + 1),
        ...v,
      }));
      that.setState({
        dataSource: source,
        total: source.length,
      });
    })
  }
  onGlobalChange = (option) => {
    this.setState({ building_idss: option });
    this.getRightTableData()
  }
  /** 切换砂石场 */
  onInstanceChange = (option, e) => {
    let that = this;
    let instance_name = '';
    if(that.state.userInstance.length > 0){
      that.state.userInstance.map((v) => {
        if (v.instance_id === e.props.value) {
          instance_name = v.instance_name;
        }
      });
    }
    else{
      instance_name = e.props.instance_name
    }
    this.setState({
      modal_flag: false,
      materielType: e.props.materielType || 1,
      instance_id: e.props.value,
      department_id: e.props.title,
      instance_name: instance_name,
      printCompany: !isInArray(oldInstance, e.props.value) ? '成都宏信投建材有限公司' : '成都宏图华信投资有限公司'
    });
    this.getLocalData({ localtext: '切换砂石场', instance_id: e.props.value, department_id: e.props.title, instance_name: instance_name, materielType: e.props.materielType });
    sessionStorage.setItem('setSandstone', JSON.stringify({instance_id: e.props.value, department_id: e.props.title, instance_name: instance_name, materielType: e.props.materielType}))
    setTimeout(() => {
      this.getRightTableData();
    }, 1000);

    ws && this.handleClose();
    this.createWebSocket();
  }

  handleClose() {
    const that = this;
    if(ws){
      ws.onclose = () => {
        that.getLocalData({ localtext: 'ws主动断开' });
      }
      ws && ws.close();
      clearTimeout(this.resetTimer);
      clearTimeout(this.reconnectTimer);
    }

  }

  createWebSocket = () => {
    const that = this;
    try {
      ws = new WebSocket(wsUrl);
      that.reload();
    } catch (e) {
      that.websocketReconnect();
    }
  }

  websocketReconnect = () => {
    const that = this;
    if (lockReconnect) {       // 是否已经执行重连
      return;
    };
    lockReconnect = true;
    //没连接上会一直重连，设置延迟避免请求过多
    tt && clearTimeout(tt);
    var tt = setTimeout(function () {
      if(ws){ws.close()}
      that.createWebSocket()
      lockReconnect = false;
    }, 5000);
  }

  //心跳检测重置
  resetHeartBeat() {
    clearTimeout(this.reconnectTimer);
    this.resetTimer = setTimeout(() => {
      ws.send(JSON.stringify({ type: 'pong', route: "/ticket/acquirer-login" }));
      this.reconnectTimer = setTimeout(() => {
        ws.close();
        this.websocketReconnect(wsUrl);
      }, 30000);
    }, 30000);
  }

  reload() {
    const that = this;
    ws.onclose = (event) => {
      console.log('%c退出', 'color: red')
      that.getLocalData({ localtext: '退出ws'})
      // this.websocketReconnect(wsUrl);
    };
    ws.onopen = () => {
      that.getLocalData({ localtext: '连接ws', department_id: that.state.department_id, instance_id: that.state.instance_id, building_id: that.state.building_idss })
      ws.send(JSON.stringify({
        type: 'login',
        route: "/ticket/acquirer-login",        
        data: {
          department_id: that.state.department_id,
          instance_id: that.state.instance_id,
          building_id: that.state.building_idss
        },
      }));
      this.resetHeartBeat();
    };
    // 通信发生错误时触发
    ws.onerror = function (evt) {
      that.websocketReconnect(wsUrl);
    };

    const onMessage = (evt) => {
      received_msg = JSON.parse(evt.data);
      if (received_msg.type === 'ping') {
        this.resetHeartBeat();
        // ws.send(JSON.stringify({"type": "pong"}));
      }
      else if (received_msg.type === 'enter') {
        ws.send(JSON.stringify({"type": "ok", route: "/ticket/acquirer-login"}));
        let data = received_msg.data;
        if(data.is_acquirer){
          that.setState({
            is_acquirer: {
              show: 1,
              car_num: data.car_num,
              end_time: data.end_time
            },
            form_data: {},
            confirm: data.is_acquirer ? true : false,
          });
          that.getRightTableData();
        } else {
          let flag = false;
          data.cancel = that.state.return_goods;
          data.instance_id = that.state.instance_id;//e.props.value;
          data.department_id = that.state.department_id;//e.props.title;
          let names = '连砂石';
          that.state.materialArr.map(({ id, name }) => {
            if (id === that.state.materielType) names = name;
          })
          data.materiel_id = that.state.materielType;
          data.materiel_name = names;
          if (!data.deduction_volume) {
            flag = false;
          } else if (data.deduction_volume !== '') {
            flag = true
          }

          !data.deduction_volume ? data.volume = parseFloat(data.car_volumn) : (parseFloat(data.car_volumn) - parseFloat(data.deduction_volume)).toFixed(1)

          if(data.door_type === 2 && JSON.stringify(that.state.form_data) === '{}'){
            that.getLocalData({localtext: 'ws获取到的数据联单数据', reasonRadio: '', ...data});
            that.getCarSize(data.car_num); // 传过来数据后去获取车辆的货箱尺寸
            that.setState({
              base_volume: data.volume,
              confirm: data.is_acquirer ? true : false,
              form_data: data,
              reason: flag,
              is_acquirer: {show: 0, car_num: '', end_time: ''}
            });
          }
          that.getRightTableData();
        }
      }
      else if (received_msg.type === 'added') {
        message.warn('无联单，请补录！！');
        let data = received_msg.data;
        data.cancel = that.state.return_goods;
        data.instance_id = that.state.instance_id;//e.props.value;
        data.department_id = that.state.department_id;//e.props.title;
        data.deduction_volume = 0;
        that.getLocalData({ localtext: 'ws推过来后无联单让补录的默认联单数据', reasonRadio: '', ...data })
        that.setState({
          base_volume: data.volume,
          confirm: false,
          form_data: data
        });
      }
    };
    ws.onmessage = onMessage;
  }
  /** 列表选择判断 */
  TicketClick = (e) => {
    let flag = false;
    let names = '连砂石';
    this.state.materialArr.map(({ id, name }) => {
      if (id === this.state.materielType) names = name;
    });        
    const json = {
      ...e,
      building_name: (e.building_name === "无" || e.building_name === "无GPS坐标") && e.has_last_building_name === 1 ? e.last_building_name : e.building_name,
      volume: e.volumn,
      cancel: e.cancel === '否' ? 0 : 1,
      materiel_id: this.state.materielType,
      materiel_name: names
    };
    if (!e.deduction_volume|| e.deduction_volume === 0 || e.deduction_volume === '0.0' || e.deduction_volume === '0.00') {
      flag = false;
    } else if (e.deduction_volume !== '') {
      flag = true
    }
    this.getCarSize(json.car_num)
    this.getLocalData({localtext: '从左侧列表选择的联单数据', ...json, reasonRadio: '' })
    this.setState({
      form_data: json,
      confirm: false,
      is_acquirer: {show: 0, car_num: '', end_time: ''},
      rowSelect: e,
      reason: flag,
    });
  }

  //根据是否被选中返回头表行的className
  setSelectHrpFactRowClassName = (record) => {
    return record.id === this.state.rowSelect.id ? "clickRowStyl" : '';
  }

  ShowImage = (src) => {
    const that = this;
    let arr = [];
    if (src && src.length > 0 && src.indexOf(',') > 0) {
      arr = src.split(",")
    } else {
      arr = [src]
    }
    const arrs = arr.map(( src ) => that.getImgSrc(src));
    this.setState({
      showImg: arrs,
      img_flag: true,
    })
  }

  getImages = (str) => {
    return <img src={this.getImgSrc(str)} style={{width: 96, marginRight: 8, cursor: 'pointer'}} onClick={() => {
      this.ShowImage(str)
    }}/>
  }

  onChangeValue = (type, e) => {
    this.setState(({form_data}) => ({
      form_data: {
        ...form_data,
        [type]: e
      }
    }));
  }

  closeModal = () => {
    this.setState({
      modal_flag: false
    })
  }

  getCreateDefaultValue = () => {
    const materielType = this.state.materielType === 1 ? 1 : 6;
    return {
      deduction_volume: 0,
      reason: '泥巴',
      car_num: '',
      end_department_id: this.state.department_id,
      end_id: this.state.instance_id,
      start_time: moment().subtract((1), 'hours').format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment().format('YYYY-MM-DD HH:mm:ss'),
      acquirer_id: store.getState().info.id,
      start_department_id: 126,
      car_volumn: 24,
      volumn: 0,
      materiel_id: materielType
    }
  }

  getSearchKey = (e) => {
    const key = e.target.value;
    let data = [...this.state.dataSource];
    if(key.length > 2){
      data.sort(compare('car_num', key.toUpperCase()));
    }else{
      data.sort(compareByOrder());
    }
    this.setState({ keyword: key, dataSource: data });
  }
 
  getImgSrc = (src) => {
    if(!src) return src;
    return src.indexOf('http') > -1 ? src : (URLS.FILES + src);
  }
  /** 判断选中的砂石场与选中的工地项目是否有电子出门条  false - 无电子出门条   true - 有电子出门条 */
  judgeIsEle = () => {
    const { form_data, instance_id } = this.state;
    if (!form_data || !instance_id) return false;
    const { building_id } = form_data;
    return eleInstanceIds.includes(instance_id) && eleBuildsIds.includes(building_id);
  }
  /** 获取车辆尺寸 */
  getCarSize = (plateNum) => {
    request('web/car/getCarSize', { plateNum })
      .then((body) => {
        const d = body.data.data.data;
        const w = parseFloat(d.inner_width), l = parseFloat(d.inner_long), h = parseFloat(d.inner_height);
        const liangfang = parseFloat((w * l * 0).toFixed(2));
       
        this.setState({
          form_data: {
            ...this.state.form_data,
            volume: parseFloat((liangfang - parseFloat(this.state.form_data.deduction_volume)).toFixed(2))
          },
          carSize: {
            inner_width: w,
            inner_long: l,
            inner_height: h,
            height: 0,
          },
        })
      })
  }
  getCurrentVolume = (e) => {
    const carSize = this.state.carSize;
    // if (carSize.height) {
      return Number((carSize.inner_width * carSize.inner_long * (e !== undefined ? e : carSize.height)).toFixed(2))
    // }
    // else return 0
  }
  getVolume = () => {
    if (!this.state.form_data) return;
    const { car_volumn, deduction_volume } = this.state.form_data;
    const liangfang = this.getCurrentVolume(); // 量方方量
    const minVloumn = !car_volumn ? liangfang : Math.min(car_volumn, liangfang);

    const volume = Number((minVloumn - Number(deduction_volume)).toFixed(2))
    this.setState({
      form_data: {
        ...this.state.form_data,
        volume
      }
    })
  }
  render() {
    const {getFieldDecorator} = this.props.form;
    const state = {...this.state};
    const write = false;// state.instance_id === 17;
    const isKf = state.userAreaId !== 11; // 判断是否是青白江账号，青白江的没有扣方选项，那么打票也不应该有
    const borderLeft = !write && {borderLeft: '1px solid black'};
    const borderRight = !write && {borderRight: '1px solid black'};
    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 6},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 14},
      },
    };
    const isOut = outInstanceIds.includes(state.instance_id) || state.roleId === 118; // 是否是销售砂石场；
    const singalbuild = outBuilds.find(({ id }) => id === (this.state.building_idss ?? 15600));
    const isEle = !isOut && this.judgeIsEle(); // 是否有电子出门条  false - 无电子出门条   true - 有电子出门条
    const materialArr = state.materialArr.map(({ name, ...rest }) => isEle ? (name.includes('连砂石') || name.includes('泥夹石') ? ({ name, ...rest }) : false) : ({ name, ...rest })).filter((item) => item);
    return (
      <div className="SandStoneReceiver flex flex-column">
        <div className="topSelect" onClick={() => this.setState({modal_flag: true})}>
          <span>当前开票地点是：{state.instance_name}</span>
          <span style={{fontSize: 12}}>点击这里可重新选择</span>
        </div>
        <div className="flex flex-grow">
          <div className="RightSide">
            <div className="RightSearch flex flex-main-between">
              <div className="SearchCar flex flex-cross-end">
                <Button style={{marginLeft: 0}} type="primary"><Link to={{pathname: './create', state: {params: this.getCreateDefaultValue()}}}>{<Icon type={'plus'}/>}补录联单</Link></Button>
                <Button type="primary" style={{marginLeft: 5}} onClick={() => this.export()}>日志导出</Button>
                <Button type="danger" style={{marginLeft: 5}} onClick={() => this.clearDate()}>清除日志</Button>
                <span style={{marginLeft: 5}}>(若搜索不到联单，请点击“补录联单”进行补录)</span>
              </div>
              <div className="flex flex-cross-center">
                <div>第二收货人：
                  <Input placeholder="请输入第二收货人" value={state.secondReceiver} style={{width: 120}} onChange={(e) =>
                    {
                      sessionStorage.setItem('secondReceiver', JSON.stringify({name: e.target.value}))
                      this.setState({secondReceiver: e.target.value})
                    }
                  }
                  />
                </div>
                {isOut && <Select value={state.building_idss} style={{ width: 100, marginLeft: 5 }} onChange={(e) => {
                  this.setState({ building_idss: e });
                  setTimeout(() => {
                    // ws.close();
                    // this.reload();
                    this.getRightTableData();
                    const build = outBuilds.find(({ id }) => id === (this.state.building_idss ?? 15600))
                    this.setState({
                      form_data: {
                        ...this.state.form_data,
                        building_id: this.state.building_idss,
                        build_name: build.name,
                      }
                    })
                  }, 100);
                  sessionStorage.setItem('setIsOutBuild', JSON.stringify({ building_idss: e }));
                }}>
                  {outBuilds.map(({id, name, acquirer, pinyin}, i) => (
                      <Option key={i} value={id} filterProp={name + pinyin}>{name}</Option>
                    ))}
                </Select>}
                {isEle ? <Select style={{ width: 100, marginLeft: 5 }} placeholder="是否有出门条" value={state.has_human_ticket} onChange={(e) => this.setState({ has_human_ticket: e })}>
                  <Option value={0}>全部</Option>
                  <Option value={1}>有出门条</Option>
                </Select> : null}
                <Input placeholder="请输入车牌/工地搜索" style={{ width: 120 }} onChange={(e) => this.getSearchKey(e)} onPressEnter={() => this.getRightTableData()} />
                <Button type="primary" onClick={() => this.getRightTableData()}>搜索</Button>
              </div>
            </div>
            <div style={{marginBottom: 10}}>
              <span style={{color: '#F95E5A'}}>待确认{state.total}单</span>
            </div>
            <Table className="table" rowKey={"id"} onRow={ record => {return { onClick: () => this.TicketClick(record) }} } dataSource={state.dataSource} bordered={true} columns={!isOut ? this.columns : this.columns2} scroll={{x: 1400}} pagination={false} rowClassName={this.setSelectHrpFactRowClassName} />
          </div>
          <div className="LeftSide flex flex-column" id={'LeftSide'}>
            <Spin size="small" spinning={state.receiverSpin}>
              {
                state.is_acquirer.show ?
                  <div style={{textAlign: 'center', color: '#7FC272', marginBottom: 5, fontSize: 18}}>
                    <span style={{fontSize: 22, marginRight: 5 }}>{state.is_acquirer.car_num}</span>
                    <span>{'在' + state.is_acquirer.end_time + '已入库开票'}</span>
                  </div> : null
              }
              <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item label="票号">
                  <Input disabled={true} value={state.form_data.number} />
                </Form.Item>
                <Form.Item label="车牌">
                  <Input disabled={true} style={{width: 170}} value={ state.form_data.car_num } />
                  <span style={{color: !state.form_data.car_type ? '' : (state.form_data.car_type.includes('非名录') || state.form_data.car_type.includes('非合同')) ? '#FE9400' : state.form_data.car_type === '非砂石名录' ? '#FE9400' : state.form_data.car_type === '合同' ? '#4BD863' : '#0079FE'}}>{state.form_data.car_type ? state.form_data.car_type : ''}</span>
                </Form.Item>
                <Form.Item label="车牌照片">
                  {state.form_data.pic && state.form_data.pic.length > 0 && state.form_data.pic.indexOf(',') > 0 ?
                    state.form_data.pic.split(',').map((v, i) =>(
                      <img
                        key={i}
                        src={this.getImgSrc(v)} style={{width: 96, marginRight: 8, cursor: 'pointer'}}
                        onClick={() => this.ShowImage(state.form_data.pic)}
                      />)) : 
                      (state.form_data.pic && state.form_data.pic.length > 0) ? this.getImages(state.form_data.pic)
                  : null}
                </Form.Item>
                <Form.Item label="司机">
                  <Input disabled={true} value={state.form_data.driver_name} />
                </Form.Item>
                <Form.Item label="工地">
                  <Select
                    showSearch
                    placeholder="选择工地"
                    value={state.form_data.building_id}
                    optionFilterProp={"filterProp"}
                    onChange={(e, option) => {
                      this.setState({
                        form_data: {
                          ...state.form_data,
                          building_name: option.props.children,
                          building_id: e,
                        }
                      })
                    }}
                  >
                    {!isOut ? state.buildArr.map(({id, name, acquirer, pinyin}, i) => (
                      <Option key={i} value={id} filterProp={name + pinyin} className={acquirer ? 'optionsClass' : ''}>{name}</Option>
                    )) : [singalbuild].map(({id, name, acquirer, pinyin}, i) => (
                      <Option key={i} value={id} filterProp={name + pinyin} className={acquirer ? 'optionsClass' : ''}>{name}</Option>
                    ))}
                  </Select>
                  {state.form_data.has_last_building_name === 1 ?
                    <span style={{fontSize: 12, color: '#ff0000', marginLeft: 10}}>默认显示该运输车上一趟工地项目</span> :
                    ((state.form_data.last_building_name === '无' || state.form_data.last_building_name === '无GPS坐标') && state.form_data.building_id === 0) ?
                      <span style={{fontSize: 12, color: '#ff0000', marginLeft: 10}}>请选择工地项目</span> : ''
                  }
                </Form.Item>
                <Form.Item label="物料">
                  <Radio.Group
                    onChange={(e) => {
                      this.setState({
                        form_data: {
                          ...state.form_data,
                          materiel_name: e.target.labelName,
                          materiel_id: e.target.value
                        }
                      })
                    }}
                    value={state.form_data.materiel_id}>
                    {materialArr.map(({id, name, }, i) => (
                      <Radio key={id} value={id} labelName={name}>{name}</Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>                
                {isEle ? (<>
                  <div className={styles.flexs}>
                    <Form.Item label="货箱方量" disabled={true}>
                      <Input value={!state.form_data.car_directory_detail ? '-' : `${state.form_data.car_directory_detail.volumn}方`} disabled={true} style={{width: 100}}/>
                      {state.form_data.has_volumn === 0 ? <span style={{fontSize: 12, color: '#ff0000', marginLeft: 10}}>没有找到宏信投核方的方量</span> : ''}
                    </Form.Item>
                    <Form.Item label="货箱高度差" disabled={true}>
                      <Input disabled value={state.form_data.check_quantities ? `${state.form_data.check_quantities.hight_diff}m` : '-'} />
                    </Form.Item>
                  </div>
                  <div className={styles.flexs}>
                    <Form.Item label="货箱尺寸" disabled={true}>
                    {/* (detailInfo.car_directory_detail.inner_height - detailInfo.check_quantities.hight_diff).toFixed(2) */}
                      <Input disabled value={(!state.form_data.car_directory_detail || !state.form_data.check_quantities) ? '-' : `${state.form_data.car_directory_detail.inner_long} x ${state.form_data.car_directory_detail.inner_width} x ${(state.form_data.car_directory_detail.inner_height - (!state.form_data.check_quantities ? 0 : state.form_data.check_quantities.hight_diff)).toFixed(2)}m`} />
                    </Form.Item>
                    <Form.Item label="量方方量" disabled={true}>
                      <Input disabled style={{ color: !state.form_data.check_quantities ? 'red' : '' }} value={state.form_data.check_quantities ? `${state.form_data.check_quantities.quantities}方` : '未量方'} />
                    </Form.Item>
                  </div>
                  <div className={styles.flexs}>
                    <Form.Item label="扣方方量">
                      <Input disabled value={state.form_data.minus_quantities ? `${state.form_data.minus_quantities.minus_quantities}方` : '-'} />
                    </Form.Item>
                    <Form.Item label="实收方量">
                      <Input disabled value={state.form_data.ht_check_status === 3 ? `${state.form_data.minus_quantities.quantities}方` : 
                      state.form_data.ht_check_status === 2 ? `-` : '-' } />
                    </Form.Item>
                  </div>
                </>) : null}
                {!isEle ? (
                  <Form.Item label="货箱方量" disabled={true}>
                    <InputNumber value={state.form_data.car_volumn} precision={2} disabled={true} style={{width: 100}}/>
                    {state.form_data.has_volumn === 0 ? <span style={{fontSize: 12, color: '#ff0000', marginLeft: 10}}>没有找到宏信投核方的方量</span> : ''}
                  </Form.Item>
                ) : null}
                {!isOut ? (
                  <>
                    <Form.Item label="货箱内径尺寸">
                      <div className={styles.sizes}>
                        <div>
                          <label>长</label>
                          <Input disabled value={state.carSize.inner_long} />
                          <span>米</span>
                        </div>
                        <div>
                          <label>宽</label>
                          <Input disabled value={state.carSize.inner_width} />
                          <span>米</span>
                        </div>
                        <div>
                          <label>高</label>
                          <InputNumber min={0} step={0.01} max={Number(state.carSize.inner_height)}  value={state.carSize.height} precision={2} style={{width: 60}} onChange={(e) => {
                            const liangfang = this.getCurrentVolume(e);
                            const minVloume = Math.min(liangfang, state.form_data.car_volumn)
                            this.setState({
                              carSize: { ...state.carSize, height: e },
                              form_data: {
                                ...state.form_data,
                                volume: Number((minVloume - Number(state.form_data.deduction_volume)).toFixed(2))
                              }
                            })
                          }} />
                          <span>米</span>
                        </div>
                        <div className={styles.images} onClick={() => state.form_data.car_num && this.getCarSize(state.form_data.car_num)}>
                          <img src="/images/md-refresh@1x.png" />
                          <span>刷新</span>
                        </div>
                      </div>
                    </Form.Item>
                    <Form.Item label="量方方量" disabled={true}>
                      <Input disabled style={{ color: !state.carSize.height ? 'red' : '' }} value={state.carSize.height ? `${this.getCurrentVolume()}方` : '未量方'} />
                    </Form.Item>
                  </>
                ) : null}
                {isOut ? <Form.Item label="标记方量" disabled={true}>
                  <InputNumber value={state.form_data.marked_volumn} precision={2} disabled={true} style={{width: 100}}/>
                </Form.Item> : null}
                {!isEle ? (<>
                  {!isOut ? <Form.Item label="扣方">
                    <InputNumber min={0} step={0.1} max={isOut ? state.form_data.car_volumn : this.getCurrentVolume()} precision={2} value={state.form_data.deduction_volume} onChange={(e) => {
                      const liangfang = this.getCurrentVolume();
                      const minVloume = Math.min(liangfang, state.form_data.car_volumn)
                      this.setState({
                        form_data: {
                          ...this.state.form_data,
                          deduction_volume: e,
                          volume: Number((minVloume - e).toFixed(2))
                        }
                      });
                      if (e && e != 0) {
                        this.setState({reason: true})
                      } else {
                        this.setState({reason: false})
                      }
                    }}/>
                    <span className={state.form_data.deduction_volume === '1.0' || state.form_data.deduction_volume === 1 ? 'active select-deduction' : 'select-deduction'} onClick={() => {
                      const liangfang = this.getCurrentVolume();
                      const minVloume = Math.min(liangfang, state.form_data.car_volumn)
                      this.setState({
                        reason: true,
                        form_data: {
                          ...state.form_data,
                          deduction_volume: '1.0',
                          volume: Number((minVloume - 1.0).toFixed(2))
                        }})
                    }}>1.0</span>
                    <span className={state.form_data.deduction_volume === '2.0' || state.form_data.deduction_volume === 2 ? 'active select-deduction' : 'select-deduction'} onClick={() => {
                      const liangfang = this.getCurrentVolume();
                      const minVloume = Math.min(liangfang, state.form_data.car_volumn)
                        this.setState({
                        reason: true,
                        form_data: {
                          ...state.form_data,
                          deduction_volume: '2.0',
                          volume: Number((minVloume - 2.0).toFixed(2))
                      }})}}>2.0</span>
                  </Form.Item> : null}
                  <Form.Item label="实收方量">
                    {isOut ? <InputNumber
                      min={0}
                      max={state.form_data.car_volumn}
                      precision={2}
                      value={state.form_data.volume}
                      style={{width: 100}}
                      disabled={isOut}
                      onChange={(e) => {
                        if (!state.form_data) return;
                        const { car_volumn, volume } = state.form_data;
                        if (volume && car_volumn) {
                          if (car_volumn < volume) {
                            return this.setState({ form_data: {...state.form_data, volume: 0 }})
                          }                       
                        }
                        this.setState({ form_data: {...state.form_data, volume: e }})
                      }}
                      onBlur={() => {
                        if (!state.form_data) return;
                        const { car_volumn, volume } = state.form_data;
                        if (volume && car_volumn) {
                          if (car_volumn < volume) {
                            this.setState({ form_data: {...state.form_data, volume: 0 }})
                          }
                        }
                      }}
                    /> : <InputNumber
                        value={state.form_data.volume}
                        style={{width: 100}}
                        disabled={true}
                      />}
                    {isOut ? <Button style={{ marginLeft: 5 }} onClick={() => this.setState({ form_data: {...state.form_data, volume: state.form_data.car_volumn }})}>货箱方量</Button> : null}
                    {isOut ? <Button style={{ marginLeft: 5 }} onClick={() => this.setState({ form_data: {...state.form_data, volume: state.form_data.marked_volumn }})}>标记方量</Button> : null}
                  </Form.Item>
                </>) : null}                
                {(!isOut && state.reason) ?
                  <Form.Item label="扣方原因">
                    {
                      <Radio.Group onChange={(e) => {
                        this.setState({reasonRadio: e.target.value});
                        this.onChangeValue('reason', e.target.value)
                      }} value={state.reasonRadio}>
                        <Radio value={'泥巴'}>泥巴</Radio>
                        <Radio value={'垃圾'}>垃圾</Radio>
                        <Radio value={'未装满'}>未装满</Radio>
                        <Radio value={'其他'}>其他</Radio>
                      </Radio.Group>
                    }
                    {
                      state.reasonRadio === '其他' ? getFieldDecorator('email', {
                        rules: [{
                          required: true, message: '请填写修改方量原因',
                        }],
                      })(
                        <Input onChange={(e) => this.onChangeValue('reason', e.target.value)}/>
                      ) : null
                    }
                  </Form.Item>
                  : null}
                {!isOut ? <Form.Item label="整车退货">
                  <RadioGroup options={state.returnGoods} onChange={(e) => this.onChangeValue('cancel', e.target.value)} value={state.form_data.cancel}/>
                </Form.Item> : null}
                {isEle ? <div onClick={() => state.form_data.ht_id ? state.history.push(`${state.form_data.ht_id}/ele-detail`) : undefined} className={classNames(styles.eleStrip, {[styles.none]: !state.form_data.ht_id})}>
                  <div className={styles.tips}>电子出门条</div>
                  {state.form_data.ht_id ? (
                    <div className={styles.boxs}>
                      <img src={this.getImgSrc(state.form_data.ht_load_img)} />
                      <div className={styles.desc}>
                        <span>{`物料类型：${state.form_data.ht_material_name}`}</span>
                        <span>{`上传时间：${state.form_data.ht_created_time}`}</span>
                        <span title={`工地名称：${state.form_data.ht_start_name}`}>{`工地名称：${state.form_data.ht_start_name}`}</span>
                      </div>
                  </div>
                  ) : <div>暂无电子出门条</div>}
                </div> : null}
                {isEle ? (state.form_data.ht_id && state.form_data.ht_status === 3) ? (
                  <div className={styles.htStatus} onClick={() => this.setState({ ht_visible: { id: state.form_data.ht_id } })}>
                    {`该车辆于${state.form_data.htel_created_time}因`}<div className={styles.reason}>{state.form_data.htel_reason}</div>{`操作了“异常处理”`}
                  </div>
                ) : null : null}
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                  <Button 
                    style={{width: '50%'}}
                    type="primary"
                    onClick={() => this.setState({save: false})}
                    htmlType="submit"
                    disabled={(state.form_data.has === 2 ? true : state.form_data.building_id === 0 ? true : 
                      isEle ? (!state.form_data.minus_quantities || state.form_data.minus_quantities.quantities <= 0 || !(state.form_data.ht_status === 1 || (state.form_data.ht_status === 3 && state.form_data.ht_end_id === state.instance_id))) : 
                      (isOut && (state.form_data.deduction_volume + state.form_data.volume > state.form_data.car_volumn)) ? true : !state.form_data.volume ? true : ((isOut && state.form_data.volume > state.form_data.car_volumn)) ? true : state.form_data.volume <= 0 ? true : state.confirm)}
                  >{state.form_data.cancel === 1 || state.form_data.cancel === '1' ? '确认退货' : '确认收货并打印'}</Button>
                </div>
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                  <Button 
                    style={{width: '50%'}}
                    type="danger"
                    onClick={() => this.setState({save: true})}
                    htmlType="submit"
                    disabled={(state.form_data.has === 2 ? true : state.form_data.building_id === 0 ? true : 
                      isEle ? (!state.form_data.minus_quantities || state.form_data.minus_quantities.quantities <= 0 || !(state.form_data.ht_status === 1 || (state.form_data.ht_status === 3 && state.form_data.ht_end_id === state.instance_id))) : 
                      (isOut && (state.form_data.deduction_volume + state.form_data.volume > state.form_data.car_volumn)) ? true : !state.form_data.volume ? true : ((isOut && state.form_data.volume > state.form_data.car_volumn)) ? true : state.form_data.volume <= 0 ? true : state.confirm)}
                  >保存并确认收货</Button>
                </div>
              </Form>
            </Spin>
          </div>
        </div>
        {state.img_flag && <ImagePreviewer images={state.showImg} visiable={state.img_flag} onChange={visiable => this.setState({img_flag: visiable})} />}
        {state.ht_visible && <ExceptionLogs id={state.ht_visible} onClose={() => this.setState({ ht_visible: false })} />}
        <Modal
          title={'选择实例'}
          visible={state.modal_flag}
          centered={true}
          onCancel={this.closeModal}
          width={400}
          footer={null}
          maskClosable={false}
          destroyOnClose={true}
          bodyStyle={{height: 100, overflow: 'auto'}}
        >
          <div className='flex flex-main-end'>
            <Select placeholder="选择实例" value={state.instance_id} style={{width: 350}} onChange={this.onInstanceChange}>
              {
                state.userInstance.map((v, i) => {
                  return <Option value={v.instance_id} title={v.department_id} materielType={v.materielType} key={i}>{v.instance_name}</Option>
                })
              }
            </Select>
          </div>
        </Modal>
        <PrintTicket write={write} state={state} isKf={isKf} borderLeft={borderLeft} borderRight={borderRight} />
        { ReactDOM.createPortal(<Spin spinning={state.spinLoading} size={'large'} style={{position: 'absolute', top: 0, margin: 'auto', bottom: 0, left: 0, right: 0, width: 50, height: 50, zIndex: 1111111111}} />, document.getElementById('root'))}
      </div>
    )
  }
});
