import { useState } from 'react';
import immutable from 'immutable';

function transformPath(any) {
  if (any) {
    if (typeof any === 'string' || typeof any === 'number') return [any];
    if (any[Symbol.iterator]) return any;
  }
  throw new Error('immutable key path must be valid string/number/iterable');
}

export default function useImmutable(initiaImmut = immutable.Map()) {
  const [immut, setImmut] = useState(initiaImmut);
  const get = path => immut.getIn(transformPath(path));
  const has = path => immut.hasIn(transformPath(path));
  const set = (path, value) => setImmut(immut.setIn(transformPath(path), value));
  const sets = (...pathValuePairs) => setImmut(pathValuePairs.reduce((next, [path, value]) => next.setIn(transformPath(path), value), immut));
  const setter = (path, defaultValue) => (value = defaultValue) => set(path, value);
  const update = updater => setImmut(updater(immut));

  return [immut, { get, has, set, sets, setter, update }];
}
