import React, {useState, useEffect, useCallback} from 'react';
import { previewable } from 'components/Previewer';
import { statusOption, statusColors } from 'variables/auditStatus';
import user from 'stores/user';
import map from 'utils/map';
import request from 'utils/request';
import classNames from 'classnames';
import styles from './Details.scss';
import {Icon, PageHeader, Table} from "antd";

const Header = ({ children }) => (
  <div className={styles.header}>
    <div className={styles.content}>{children}</div>
  </div>
);

const Section = ({ title, children }) => (
  <div className={styles.section}>
    <div className={styles.title}>{title}</div>
    {children}
  </div>
);

function CommonDetails({ preview, match, history }) {
  const plan_id = match.params.id;
  const types = match.params.types;
  const [baseInfo, setBaseInfo] = useState({});
  const [routeInfo, setRouteInfo] = useState([]);
  const [carInfo, setCarInfo] = useState([]);
  const [transDocs, setTransDocs] = useState([]);
  const [routesDocs, setRouteDocs] = useState([]);

  useEffect(() => {
    request('/web/route/getSsPlanDetails', {plan_id})
      .then((res) => res.data)
      .then(data => {
        setBaseInfo(data.baseInfo);
        setRouteInfo(data.routeInfo);
        setCarInfo(data.carInfo);
        if (types === '2') {
          const docs =  JSON.parse(data.baseInfo.content) || [];
          const trans = docs.slice(0, 8).map(({ n, v }) => (v || '').split(',').map((src) => {
            const title = n.indexOf('（') === -1 ? n : n.slice(0, n.indexOf('（'));
            return { title, src };
          }));
          const routes = docs.slice(-4).map(({ n, v }) => (v || '').split(',').map((src) => {
            const title = n.indexOf('（') === -1 ? n : n.slice(0, n.indexOf('（'));
            return { title, src };
          }));
          setTransDocs(trans);
          setRouteDocs(routes);
        }
      });
  }, []);

  //运输距离
  function getDistance(distance) {
    return (Math.round(distance/100)/10).toFixed(1) + '公里';
  }
  //途经点
  function getRoutes(points){
    if (!points) return null;
    return points.map(point => point.address).filter(v => v).join(' -> ')
  }
  function getPoint(route) {
    const arr = [];
    arr.push({
      startLng: route.startAndEnd.start_lng,
      startLat: route.startAndEnd.start_lat,
      endLng: route.startAndEnd.end_lng,
      endLat: route.startAndEnd.end_lat,
    });
    return arr;
  }
  function MapIndex({route, path}) {
    const paths = path.map(({lng, lat}) => new AMap.LngLat(lng, lat));
    const mapRef = useCallback((current) => {
      if (!current) return;
      const map = new window.AMap.Map(current, { mapStyle: 'amap://styles/macaron' });
      map.add(new window.AMap.Polyline({
        path: paths,
        strokeWeight: 6,
        lineJoin: 'bevel',
        lineCap: 'round',
        showDir: true,
      }));
      map.add([{lng: route[0].startLng, lat: route[0].startLat}, {lng: route[0].endLng, lat: route[0].endLat}].map(location => new window.AMap.Marker({
        position: new window.AMap.LngLat(location.lng, location.lat),
        label: {
          direction: 'center',
          offset: new window.AMap.Pixel(0, -35),
        },
      })));
      map.setFitView();
    }, [route]);
    return <div className={styles.map} ref={mapRef} />
  }
  //本运企
  const selfArr = carInfo.filter(({transport_type}) => transport_type === 1);
  const otherArr = carInfo.filter(({transport_type}) => transport_type === 2);
  function selfTable() {
    const selfColumns = [
      { title: '车牌号', dataIndex: 'car_num' },
      { title: '驾驶员', dataIndex: 'driver_name' },
      { title: '驾驶证号码', dataIndex: 'driver_license_num' },
      { title: '准驾车型', dataIndex: 'type' },
      { title: '运渣证编号', dataIndex: 'garbage_license_num' },
      { title: '货箱内径', dataIndex: 'inner_long', render:(t, row) => `${row.inner_long}x${row.inner_width}x${row.inner_height}` },
      { title: '未装车载', dataIndex: 'is_bind_device', render:(t) => t === 1 ? '未安装' : '已安装' },
      { title: '相关文件', dataIndex: 'content', render: (t, row) => (<Icon className={styles.docsIcon} type="folder-open" onClick={() => preview.image(getConnectDocs(row))} />) },
    ];
    return(
      <Table bordered rowKey="id" columns={selfColumns} dataSource={selfArr} pagination={false}/>
    )
  }
  //外调运企
  const otherTransportId = [];
  otherArr.map(({transport_id}) => {
    otherTransportId.push(transport_id)
  });
  function unique (arr) {
    return Array.from(new Set(arr))
  }
  function otherTable(otherId) {
    const otherColumns = [
      { title: '车牌号', dataIndex: 'car_num' },
      { title: '驾驶员', dataIndex: 'driver_name' },
      { title: '驾驶证号码', dataIndex: 'driver_license_num' },
      { title: '准驾车型', dataIndex: 'type' },
      { title: '运渣证编号', dataIndex: 'garbage_license_num' },
      { title: '货箱内径', dataIndex: 'inner_long', render:(t, row) => `${row.inner_long}x${row.inner_width}x${row.inner_height}` },
      { title: '未装车载', dataIndex: 'is_bind_device', render:(t) => t === 1 ? '未安装' : '已安装' },
      { title: '相关文件', dataIndex: 'content', render: (t, row) => (<Icon className={styles.docsIcon} type="folder-open" onClick={() => preview.image(getConnectDocs(row))} />) },
    ];

    return (
      <Table bordered rowKey="id" columns={otherColumns} dataSource={otherArr.filter(item => item.transport_id === otherId)} pagination={false}/>
    )
  }
  function getName(otherId) {
    let name = '';
    otherArr.map((item) => {
      if (item.transport_id === otherId) {
        name = item.transport_name
      }
    });
    return name;
  }
  //相关文件
  function getConnectDocs(connectDocs) {
    const docs = [
      ['车辆行驶证照片', 'drive_license_photo'],
      ['车辆道路运输证照片', 'ship_license_photo'],
      ['车辆成都市建筑垃圾处置运输证正、反面照片', 'garbage_license_photo'],
      ['车辆前方45°角照片', 'car_photo'],
    ].reduce((final, [title, key]) => {
      return [...final, ...(connectDocs[key] || '').split(',').map(src => ({ title, src }))];
    }, []);
    return docs;
  }

  return (
    <div className={styles.container}>
      <Header>
        <PageHeader onBack={history.goBack} title="备案详情" />
      </Header>
      <Section title="审核信息">
        <div className={styles.info}>
          <div className={styles.inline}>
            <div className={styles.label}>审核状态</div>
            <div className={classNames(styles.status, styles.name)} style={{ color: statusColors[baseInfo.status] }}>{statusOption[baseInfo.status]}</div>
          </div>
          {
            baseInfo.status === 3 || baseInfo.status === 5 ?
            <div className={styles.inline}>
              <div className={styles.label}>备注信息</div>
              <div className={styles.name}>{baseInfo.advice}</div>
            </div> :
            null
          }
        </div>
      </Section>
      <Section title="申请信息">
        <div className={styles.info}>
          <div className={styles.inline}>
            <div className={styles.label}>申请时间</div>
            <div className={styles.name}>{baseInfo.created_time}</div>
          </div>
        </div>
      </Section>
      <Section title="基础信息">
        <div className={styles.info}>
          <div className={styles.inline}>
            <div className={styles.label}>起点名称</div>
            <div className={styles.name}>{baseInfo.start_name}</div>
          </div>
          <div className={styles.inline}>
            <div className={styles.label}>运输时限</div>
            <div className={styles.name}>{baseInfo.start_date}至{baseInfo.end_date}</div>
          </div>
        </div>
      </Section>
      <Section title="线路信息">
        <div className={styles.info}>
          <div className={styles.inline}>
            <div className={styles.label}>运输起点</div>
            <div className={styles.name}>{baseInfo.start_name}</div>
          </div>
          <div className={styles.inline}>
            <div className={styles.label}>运输品类</div>
            <div className={styles.name}>{baseInfo.materiel_name}</div>
          </div>
          <div className={styles.inline}>
            <div className={styles.label}>排放总量(方)</div>
            <div className={styles.name}>{baseInfo.out_volume}</div>
          </div>
          {
            routeInfo.map((route, index) => {
              return (
                <div key={index}>
                  <div className={styles.inline}>
                    <div className={styles.label}>运输终点({index+1})</div>
                    <div className={styles.name}>{route.end_name}</div>
                  </div>
                  <div className={styles.inline}>
                    <div className={styles.label}>接收方量(方)</div>
                    <div className={styles.name}>{route.in_volume}</div>
                  </div>
                  <div className={styles.inline}>
                    <div className={styles.label}>运输距离</div>
                    <div className={styles.name}>{getDistance(route.startAndEnd.distance)}</div>
                  </div>
                  <div className={styles.inline}>
                    <div className={styles.label}>途经点</div>
                    <div className={styles.name}>{getRoutes(route.routeSteps)}</div>
                  </div>
                  <div className={styles.inline}>
                    <div className={styles.label}> </div>
                    <div className={styles.name}>
                      <MapIndex route={getPoint(route)} path={route.routeSteps} />
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </Section>
      <Section title="车辆信息">
        <div className={styles.info}>
          <div className={styles.inline}>
            <div className={styles.label}>本运企</div>
            <div className={styles.name}>
              <div className={styles.transName}>
                <div className={styles.nowrap} title={baseInfo.transport_name}>{baseInfo.transport_name}</div>
                <div>已选车辆数  {selfArr.length}  辆</div>
              </div>
              {selfTable()}
            </div>
          </div>
          {
            unique(otherTransportId).map((otherId, index) => {
              return (
                <div className={styles.inline} key={index}>
                  <div className={styles.label}>外调运企</div>
                  <div className={styles.name}>
                    <div className={styles.transName}>
                      <div className={styles.nowrap} title={getName(otherId)}>{getName(otherId)}</div>
                      <div>已选车辆数  {otherArr.filter(item => item.transport_id === otherId).length}  辆</div>
                    </div>
                    {otherTable(otherId)}
                  </div>
                </div>
              )
            })
          }
        </div>
      </Section>
      {
        types === '2' ?
        <Section title="申报附件">
          <div className={styles.info}>
            <div className={styles.inline}>
              <div className={styles.label}>运输信息</div>
              <div className={styles.files}>
                {
                  transDocs.map((doc, index) => {
                    return (
                      <div className={styles.file} key={index} onClick={() => preview.image(doc)}>
                        <div className={styles.name}>{doc[0].title}</div>
                        <div className={styles.image} style={{ backgroundImage: `url(${doc[0].src})` }} />
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className={styles.inline}>
              <div className={styles.label}>线路信息</div>
              <div className={styles.files}>
                {
                  routesDocs.map((doc, index) => {
                    return (
                      <div className={styles.file} key={index} onClick={() => preview.image(doc)}>
                        <div className={styles.name}>{doc[0].title}</div>
                        <div className={styles.image} style={{ backgroundImage: `url(${doc[0].src})` }} />
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </Section> :
        null
      }
    </div>
  )

}

export default previewable(CommonDetails)
