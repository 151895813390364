import React, {useState, useEffect} from 'react';
import {Button, Divider, Popconfirm, message} from 'antd';
import { previewable } from 'components/Previewer';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import ImagePreviewButtonRender from 'ui/TableCell/ImagePreviewButtonRender';
import LinkButton from 'ui/TableCell/LinkButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';
import debug from 'utils/debug';
import request from "utils/request";

export const dataSource = new DataSource('/web/keep_on_record/getList');
function DeleteMsg(id){
  request('/web/keep_on_record/deleted', {id})
    .then(body => {
      if(body.data.code === 200){
        dataSource.refresh();
        message.success('删除成功！');
      }else{
        message.warn(body.data.msg)
      }
    })
}
function List({ preview }) {
  debug.render('List');
  const render = ImagePreviewButtonRender(preview);
  const columns = [
    serial(),
    { title: '车牌号', dataIndex: 'car_num'},
    { title: '报备时间', dataIndex: 'created_time'},
    // { title: '设备编号', dataIndex: 'device_num', width: 150 },
    // { title: '所属运企', dataIndex: 'company_name', width: 200 },
    // { title: '车主姓名', dataIndex: 'owner_name',width: 100 },
    // { title: '车主电话', dataIndex: 'owner_phone',  width: 160},
    // { title: '车辆型号', dataIndex: 'car_model', width: 120 },
    // { title: '货箱内径-长(m)', dataIndex: 'inner_long', width: 220},
    // { title: '货箱内径-宽(m)', dataIndex: 'inner_width', width: 220 },
    // { title: '货箱内径-高(m)', dataIndex: 'inner_height', width: 220 },
    // { title: '方量', dataIndex: 'volumn', width: 120},
    // { title: '核定载重量(吨)', dataIndex: 'weight',  width: 180,},
    // { title: '车辆图片', dataIndex: 'car_photo', render, align: 'center', width: 120,},
    // { title: '车辆行驶证图片', dataIndex: 'drive_license_photo', render, align: 'center', width: 120 },
    // { title: '道路运输证图片', dataIndex: 'ship_license_photo', render, align: 'center', width: 120 },
    // { title: '建筑垃圾处置运输证号图片', dataIndex: 'garbage_license_photo', render, width: 200, align: 'center' },
    actions({
      width: 240,
      render: ($, { id, car_num }) => (
        <>
          <LinkButton to={`./${id}/${car_num}/edit`} primary>编辑</LinkButton>
          <Divider type="vertical" />
          <LinkButton to={`./${id}/${car_num}/show-detail`}>详情</LinkButton>
          <Divider type="vertical" />
          <Popconfirm placement="top" title={'确认删除该车辆?'} onConfirm={() => { DeleteMsg(id) }} okText="Yes" cancelText="No">
            <Button type={'danger'} size={'small'}>删除</Button>
          </Popconfirm>
        </>
      ),
    }),
  ];

  const querys = [
    { title: '请输入车牌搜索', key: 'keyword' },
  ];

  const buttons = [
    { title: '新增', link: './create', icon: 'plus' },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}

export default previewable(List);
