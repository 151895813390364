function timesSort(a, b) {
  const a0 = !a.start_time ? a.start_time : a.start_time.indexOf('00:00:00') > -1 ? undefined : a.start_time;
  const a1 = !a.end_time ? a.end_time : a.end_time.indexOf('00:00:00') > -1 ? undefined : a.end_time;
  const b0 = !b.start_time ? b.start_time : b.start_time.indexOf('00:00:00') > -1 ? undefined : b.start_time;
  const b1 = !b.end_time ? b.end_time : b.end_time.indexOf('00:00:00') > -1 ? undefined : b.end_time;
  if(a0 && b0) {
    return Sort(a0, b0)
  }
  else if(a0 && !b0 && b1) {
    return Sort(a0, b1)
  }
  else if(!a0 && a1 && b0) {
    return Sort(a1, b0)
  }
  else if(!a[0] && a1 && !b0 && !b1) {
    return Sort(a1, b1)
  }
}

function Sort(a, b) {
  if(new Date(a) > new Date(b)) return 1
  else return -1;
}
export function TicketTimeSort(times) {
  const timeArr = times.sort(timesSort);
  // console.log('timeArr', timeArr)
}
