import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import request from 'utils/request';

import { dataSource } from './List';
import {message} from "antd";

export default function Evidence({ history, match }) {
    const id = match.params.id;
    const back = () => history.goBack();
    const onFinish = (data) => {
        const params = {
            id: id,
            evidence: {
                building_in: {
                    name: data.build_in_name || '',
                    file: data.build_in_file || ''
                },
                building_out: {
                    name: data.build_out_name || '',
                    file: data.build_out_file || ''
                },
                car: {
                    name: data.car_name || '',
                    file: data.car_file || ''
                },
                gps: {
                    name: data.gpa_name || '',
                    file: data.gps_file || '',
                },
                factory_in: {
                    name: data.factory_in_name || '',
                    file: data.factory_in_file || ''
                },
                factory_out: {
                    name: data.factory_out_name || '',
                    file: data.factory_out_file || ''
                },
                others: {
                    name: data.other_name || '',
                    file: data.other_file || ''
                }
            }
        };

        /*request('/web/ticket/accountEvidence', params)
        .then(body => body.data)
        .then(({ code, msg }) => {
            if (code === 200) {
                dataSource.refresh();
                back();
            } else {
                const warn = typeof msg === 'string' ? msg : '保存失败!';
                message.warn(warn);
            }
        });*/

        // dataSource.refresh();
        back();
    };
    const inputs = [
        { label: '工地进场证据',
            children: [
                { key: 'build_in_name', placeholder: '文本材料', style: {margin: '10px 0'}, disabled: true, },
                { key: 'build_in_file', type: 'upload', disabled: true, },
            ]
        },
        { label: '工地出场证据',
            children: [
                { key: 'build_out_name', placeholder: '文本材料', style: {margin: '10px 0'}, disabled: true, },
                { key: 'build_out_file', type: 'upload', disabled: true, },
            ]
        },
        { label: '装车证据',
            children: [
                { key: 'car_name', placeholder: '文本材料', style: {margin: '10px 0'}, disabled: true, },
                { key: 'car_file', type: 'upload', disabled: true, },
            ]
        },
        { label: 'GPS轨迹证据',
            children: [
                { key: 'gps_name', placeholder: '文本材料', style: {margin: '10px 0'}, disabled: true, },
                { key: 'gps_file', type: 'upload', disabled: true, },
            ]
        },
        { label: '砂石场进场证据',
            children: [
                { key: 'factory_in_name', placeholder: '文本材料', style: {margin: '10px 0'}, disabled: true, },
                { key: 'factory_in_file', type: 'upload', disabled: true, },
            ]
        },
        { label: '砂石场出场证据',
            children: [
                { key: 'factory_out_name', placeholder: '文本材料', style: {margin: '10px 0'}, disabled: true, },
                { key: 'factory_out_file', type: 'upload', disabled: true, },
            ]
        },
        { label: '其他证据',
            children: [
                { key: 'other_name', placeholder: '文本材料', style: {margin: '10px 0'}, disabled: true, },
                { key: 'other_file', type: 'upload', disabled: true, },
            ]
        },
    ];

    const transformer = ({ evidence }) => {
        if (evidence.length !== 0 && evidence !== null && evidence !== undefined) {
            return ({
                build_in_name: evidence[0].name,
                build_in_file: evidence[0].file,
                build_out_name: evidence[1].name,
                build_out_file: evidence[1].file,
                car_name: evidence[2].name,
                car_file: evidence[2].file,
                gps_name: evidence[3].name,
                gps_file: evidence[3].file,
                factory_in_name: evidence[4].name,
                factory_in_file: evidence[4].file,
                factory_out_name: evidence[5].name,
                factory_out_file: evidence[5].file,
                other_name: evidence[6].name,
                other_file: evidence[6].file,
            });
        }
        return ({
            build_in_name: '',
            build_in_file: '',
            build_out_name: '',
            build_out_file: '',
            car_name: '',
            car_file: '',
            gps_name: '',
            gps_file: '',
            factory_in_name: '',
            factory_in_file: '',
            factory_out_name: '',
            factory_out_file: '',
            other_name: '',
            other_file: ''
        });
    };

    return (
        <ModalFormPage
            init={['/web/ticket/getAccountEvidence', { id }, transformer]}
            // submit={['/web/ticket/accountEvidence', { id }]}
            title="审核证据"
            inputs={inputs}
            onCancel={back}
            onFinish={onFinish}
            modalProps={{
                width: 800,
            }}
            formProps={{
                labelCol: { xs: { span: 4 } },
                wrapperCol: { xs: { span: 20 } },
            }}
        />
    );
}
