import React from 'react';
import { Route } from 'react-router-dom';
import List from './List';
// import Detail from './Detail';
import Detail from '../Details';
import Create from './Create';
import Update from './Update';
import Volumn from './Volumn';
import Delete from './Delete';
import Status from './Status';
import Evidence from './Evidence';
import OfflineWork from '../OfflineWork';
export default ({ path }) => (
  <>
    <Route path={path} component={List} />
    <Route path={path + 'create'} component={Create} />
    <Route path={path + ':id/detail'} component={Detail} />
    <Route path={path + ':id/update'} component={Update} />
    <Route path={path + ':id/volumn'} component={Volumn} />
    <Route path={path + ':id/delete'} component={Delete} />
    <Route path={path + ':id/status'} component={Status} />
    <Route path={path + ':id/evidence'} component={Evidence} />
    <Route path={path + ':id/offline-work'} component={OfflineWork} />
  </>
);
