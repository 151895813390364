import React from 'react';
import { Modal, message } from "antd";
import request from 'utils/request';

export default function SplitTicket({ticketId, onClose, onSuccessT}) {
  const onSuccess = () => {
    request('web/ticket/splitTicket', { id: ticketId })
      .then(body => body.data)
      .then(data => {
        if(data.code !== 200) {
          message.warning(data.msg, 6);
          // onSuccessT([])
          onClose();
        } else {
          message.success('拆分联单成功！', 6);
          onSuccessT(data.data.numbers);
        }
      })
  };
  return (
    <Modal
      title={'拆分联单'}
      // width={800}
      // bodyStyle={{ maxHeight: '60vh', overflow: 'auto', padding: 0 }}
      destroyOnClose={true}
      visible={true}
      onCancel={() => onClose()}
      onOk={onSuccess}
    >
      确认拆分该联单？
    </Modal>
  );
}
