// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".mPN2PUCHbyRLS5I1PYAnD {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  padding: 0 32px;\n}\n.mPN2PUCHbyRLS5I1PYAnD ._34Hop3NraiBlzcsgDd5Jq1 {\n  flex-grow: 1;\n  overflow-y: auto;\n}\n.mPN2PUCHbyRLS5I1PYAnD ._34Hop3NraiBlzcsgDd5Jq1 .ant-table-body {\n  background: white;\n}\n.mPN2PUCHbyRLS5I1PYAnD ._2298uxrb8rETvREf67Hk0R {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  padding: 16px 0;\n  position: relative;\n}\n.mPN2PUCHbyRLS5I1PYAnD ._2298uxrb8rETvREf67Hk0R .CY937OMj3OjhIv169EXoD {\n  position: absolute;\n  content: \"\";\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0);\n}\n.mPN2PUCHbyRLS5I1PYAnD ._2298uxrb8rETvREf67Hk0R .CY937OMj3OjhIv169EXoD[data-disabled=false] {\n  display: none;\n}", ""]);
// Exports
exports.locals = {
	"container": "mPN2PUCHbyRLS5I1PYAnD",
	"table": "_34Hop3NraiBlzcsgDd5Jq1",
	"pagination": "_2298uxrb8rETvREf67Hk0R",
	"mask": "CY937OMj3OjhIv169EXoD"
};
module.exports = exports;
