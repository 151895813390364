import React from 'react';
import { Modal } from 'antd';
import IllegalMap from 'components/Map/IllegalMap';
import debug from 'utils/debug';
import styles from './IllegalMap.scss';


export default function IllegalMapPreviewer({ id, alarmId, type, visiable = false, onChange }) {
  debug.render('PreviewIllegalMap');

  return (
    <Modal
      centered
      width={1200}
      visible={visiable}
      onCancel={() => onChange(false)}
      wrapClassName={styles.modal}
    >
      {visiable && <IllegalMap className={styles.map} height={800} id={id} alarmId={alarmId} type={type} />}
    </Modal>
  );
}
