import React from 'react';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import serial from 'ui/TableCell/serial';
import moment from "moment";

export const dataSource = new DataSource('/river/dc_ticket/lists');
const defaultDate = [moment().startOf('day').format('YYYY-MM-DD'), moment().endOf('day').format('YYYY-MM-DD')];
export default function List() {
  const columns = [
    serial(true),
    { title: '区域', dataIndex: 'area_name' },
    { title: '点位名称', dataIndex: 'instance_name' },
    { title: '运企名称', dataIndex: 'transport_name' },
    { title: '车牌号', dataIndex: 'car_num' },
    { title: '出场时间', dataIndex: 'create_time' },
    { title: '货箱方量', dataIndex: 'volume' },
  ];

  const querys = [
    { title: ['出场', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
    { title: '请选择运企', key: 'transport_id', type: 'select', options: '/river/dc_ticket/searchTransportLists', valueKey: 'id', labelKey: 'name', showSearch: true, allowClear: true, style: { width: 300 } },
    { title: '请输入车牌号模糊搜索', key: 'keyword' },
  ];

  const buttons = [
    { title: '导出', download: '/river/dc_ticket/exportExcel' },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
      showVolume={true}
      defaultQuery={{ times: defaultDate }}
    />
  );
}
