import React from 'react';
import { Route } from 'react-router-dom';
import List from './List'; // 列表
import ListReporting from './ListReporting'; // 上面的名录报备
import CheckRecord from '../Component/CheckRecord'; // 审核记录
import ShowCars from '../Component/ShowCars'; //  查看车辆数量
import ShowFiles from '../Component/ShowFiles'; //  查看报备文件
import ShowCarDetail from './ShowCarDetail'; //  查看车辆详情
import ReplayListReporting from './ReplayListReporting'; //重新报备
export default ({ path }) => (
  <>
    <Route path={path} component={List} />
    <Route path={path + 'list-reporting'} component={ListReporting} />
    <Route path={path + ':id/list-reporting'} component={ListReporting} />
    <Route path={path + ':id/:type/show-cars/'} component={ShowCars} />
    <Route path={path + ':id/:type/show-files'} component={ShowFiles} />
    <Route path={path + ':id/:type/check-record'} component={CheckRecord} />
    <Route path={path + ':id/replay-list-reporting'} component={ReplayListReporting} />
    <Route path={path + ':id/2/show-cars/:id/show-car-detail'} component={ShowCarDetail} />

  </>
);
