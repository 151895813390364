import React from 'react';
import request from "utils/request";
import { dataSource } from "./List";
import { Modal, Form, Select, Input, message } from 'antd';

const statusOptions = [
  { value: 3, label: '已通过' },
  { value: 4, label: '已驳回' },
];

function Check(props) {
  const plan_id = props.match.params.id;
  const back = () => props.history.goBack();
  const onFinish = () => {
    validateFields((err, values) => {
      if(err){
        return;
      }
      const params = {
        ...values,
        plan_id: plan_id
      };
      request('/web/route/AuditSsPlanInfo', params)
        .then(body => {
          if (body) {
            message.success('审核成功');
            back();
            dataSource.refresh();
          } else {
            const warn = typeof msg === 'string' ? msg : '保存失败!';
            message.warn(warn);
          }
        })
    });
  };
  const { getFieldDecorator, getFieldValue, validateFields } = props.form;

  return (
    <Modal
      title="备案审核"
      visible
      centered
      onCancel={back}
      onOk={onFinish}
    >
      <Form>
        <Form.Item label="状态">
          {
            getFieldDecorator('status', {rules: [{ required: true, message: '请选择状态' }], initialValue: status}
            )(
              <Select>
                {statusOptions.map(({value, label}) => (<Select.Option key={value} value={value}>{label}</Select.Option>))}
              </Select>
            )
          }
        </Form.Item>
        <Form.Item label="备注信息">
          {
            getFieldDecorator('advice', {rules: [{ required: true, message: '请输入备注信息' }]})(
              <Input.TextArea />
            )
          }
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Form.create()(Check);
