import React from 'react';
import { Route } from 'react-router-dom';
import List from './List';
import Update from './Update';
import Create from './Create';
import Change from './Change';

export default function ({ path }) {
  return (
    <>
      <Route path={path} component={List} />
      <Route path={path + ':id/:car_num/update'} component={Update} />
      <Route path={path + 'change'} component={Change} />
      <Route path={path + 'create'} component={Create} />
    </>
  )
}
