import React, { useState, useEffect, useCallback, useMemo, useRef  } from 'react';
import styles from "../TransportRadar/index.scss";
import { message, Radio, Modal, Button, Drawer, Switch, Slider } from 'antd';
import echarts from 'echarts';
// import echarts from 'echarts/lib/echarts';
// import 'echarts/extension/bmap/bmap';
// import 'echarts/lib/component/tooltip';

import request from 'utils/request';
import debug from 'utils/debug';
import h_common from 'utils/h_common';
import { dataSource } from './List';
const { confirm } = Modal;
const logChartDispose = () => debug.action('Echarts实例已卸载') || true;
const isArrayAllValid = array => array.findIndex(item => item === undefined) === -1;
const coordObjectsToArrays = objects => objects.map(({ lng, lat }) => [lng, lat]);
const coordObjectsToArraysByTrans = objects => objects.map(({ lng, lat }) => h_common.GPS_transformation(lat, lng));
const coordObjectsToArraysByTicket = objects => objects.map(({ lng, lat, stay_time, ticket_id, time, }) =>({value: h_common.GPS_transformation(lat, lng), stay_time, ticket_id, time}));
const coordObjectsToArraysByPoints = objects => objects.map(({ lng, lat, speed, time, }) =>({value: h_common.GPS_transformation(lat, lng), speed, time}));


function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function useEcharts() {
  const [chart, setChart] = useState(null); // 存储echart实例
  const refGetter = useCallback(element => !!element && setChart(echarts.init(element)), []); // 创建echart实例
  const isChartUsable = useCallback(() => (!!chart && !chart.isDisposed()), [chart]); // 判断echart实例是否可用
  useEffect(() => () => (isChartUsable() && logChartDispose() && chart.dispose()), [chart]); // 卸载echart实例
  return [chart, isChartUsable, refGetter];
}

const startMap = new Map();
let bound = null;
let zoom = 15;
let bmap = null;
function useMap(isChartUsable, chart, series, center, flag) {
  useEffect(() => {
    if (!isChartUsable()) return;
    // chart.clear();
    let option = {
      tooltip : {
        trigger: 'item'
      },
      bmap: {
        center: center,
        zoom: zoom,
        roam: true,
        mapStyle: {
          // styleJson: mapStyleJson,
        },
      },
      series,
    };
    if(bmap){
      option.bmap.zoom = bmap.getZoom();
     let lng = bmap.getCenter().lng;
     let lat = bmap.getCenter().lat;
      bound = bmap.getBounds();
      option.bmap.center = [lng, lat];
    }
    if(flag){
      option.bmap.center = center
    }
    chart.setOption(option);
    bmap = chart ? chart.getModel().getComponent('bmap').getBMap() : null;
    if(bmap){
      bmap.addControl(new BMap.MapTypeControl());
    }
  }, [chart, series]);
}
export default function Trail({ history, match }){
  const {params: {id}} = match;
  const [chart, isChartUsable, chartRefGetter, bmap] = useEcharts();
  const [hour, setHour] = useState("1");

  const [ticketSeries, setTicketSeries] = useState();
  const [ticket_lines, setTicketLines] = useState();
  const [visible, setVisible] = useState(false);
  const [rectify, setRectify] = useState(true); //是否轨迹纠偏
  const [show_points, setShowPoints] =useState(false); // 是否显示原始轨迹点
  const [show_stops, setShowStops] =useState(false); //是否显示停靠点

  const [slider, setSlider] = useState([]);// 控制slider的节点个数便于进度条的控制

  const [moveSpeed, setMoveSpeed] = useState("400"); // 0.5 - 800; 1 - 400; 2 - 200; 4 - 100; 8 - 50;
  const [playIndex, setPlayIndex] = useState(0); // 控制slider 在那一节点
  const [movePos, setMovePos] = useState(0); //控制轨迹在哪一点

  //控制播放、暂停、重置按钮
  const [play_begin, setPlayBegin] = useState(false);
  const [play_end, setPlayEnd] = useState(false);
  const [play_restart, setPlayRestart] = useState(false);
  const [center, setCenter] = useState([103.836472, 30.719426]);
  const [centerFlag, setCenterFlag] = useState(false);
  function getTicketLine(id, m_hour){
    request('/web/ticket_map/getTicketLine', {ticket_id: id, hour: m_hour }).then(body => {
      const data = body.data.data;
      if(data.ticket_lines.length === 0){
        message.warn('暂无联单路线', 6);
      }else{
        let ticket_dates = [];
        setVisible(true);
        setTicketLines(data);
        ticket_dates = data.ticket_lines.map(({time}) => time);
        setSlider(ticket_dates);
      }
    })
  }
  useEffect(() => {
    getTicketLine(id, hour)
  }, [hour])
  useEffect(() => {
    if(ticket_lines){
      let series_data = [];
      // 显示纠偏轨迹 ？ 原始轨迹
      if(ticket_lines.ticket_lines.length > 0){
        rectify ? series_data.push({
            type: 'lines',
            coordinateSystem: 'bmap',
            /*effect: {
              show: true,
              period: 12,
              trailLength: 0,
              symbol: 'image:///images/center/a2.png',
              symbolSize: [15, 42.5],
            },*/
            polyline: true,
            silent: true,
            lineStyle: {
              normal: {
                color: '#EB3873',
                opacity: 1,
                width: 4,
              },
            },
            progressiveThreshold: 500,
            progressive: 200,
            data: [{
              coords: coordObjectsToArrays(ticket_lines.bind_lines || []),
            }]
          }) : series_data.push({
            type: 'lines',
            coordinateSystem: 'bmap',
            /*effect: {
              show: true,
              period: 12,
              trailLength: 0,
              symbol: 'image:///images/center/a2.png',
              symbolSize: [15, 42.5],
            },*/
            polyline: true,
            silent: true,
            lineStyle: {
              normal: {
                color: '#03d5a0',
                opacity: 1,
                width: 3,
              },
            },
            progressiveThreshold: 500,
            progressive: 200,
            data: [{
              coords: coordObjectsToArraysByTrans(ticket_lines.ticket_lines || []),
            }]
          });
      }
      //是否显示原始轨迹点
      show_points ? series_data.push({
        name: 'carPoints',
        type: 'scatter',
        symbol: 'circle',
        coordinateSystem: 'bmap',
        zlevel: 5,
        symbolSize: [10, 10],
        tooltip:{
          show: true,
          formatter:function(a,b,c){
            return a.data.time + '<br />' + a.data.speed + 'km/h';
          }
        },
        data: coordObjectsToArraysByPoints(ticket_lines.ticket_lines || []),
        itemStyle: {
          normal: {
            color:"#5D59E8",
            opacity: 0.8
          }
        },
        hoverAnimation: true,
      }) : series_data.push({
        name: 'carPoints',
        type: 'scatter',
        symbol: 'circle',
        coordinateSystem: 'bmap',
        zlevel: 5,
        data : coordObjectsToArraysByPoints(ticket_lines.ticket_lines || []),
        symbolSize: [10, 10],
        tooltip:{
          formatter:function(a,b,c){
            return a.data.time + '<br />' + a.data.speed + 'km/h';
          }
        },
        itemStyle: {
          normal: {
            color:"#5D59E8",
            opacity: 0
          }
        },
        hoverAnimation: true,
      });
      //是否显示停靠点
      show_stops ? series_data.push({
        type: 'scatter',
        coordinateSystem: 'bmap',
        name: 'overTicket',
        symbol: 'circle',
        symbolSize: 15,
        itemStyle: {
          color: '#1491FB',
          opacity: 1,
        },
        label: {
          show: true,
          position: 'top',
          backgroundColor: 'rgba(255, 255, 255, .85)',
          padding: [2, 5],
          formatter: function(a){return a.data.time + '\n停靠：' + a.data.stay_time + '分钟'}
        },
        data: coordObjectsToArraysByTicket(ticket_lines.stop_points || []),
      }) : series_data.push({
        type: 'scatter',
        coordinateSystem: 'bmap',
        name: 'overTicket',
        symbol: 'circle',
        symbolSize: 15,
        itemStyle: {
          color: '#1491FB',
          opacity: 0,
        },
        label: {
          show: true,
          position: 'top',
          backgroundColor: 'rgba(255, 255, 255, .65)',
          padding: [2, 5],
          formatter: function(a){return a.data.time + '\n停靠：' + a.data.stay_time + '分钟'}
        },
        data: coordObjectsToArraysByTicket(ticket_lines.stop_points || []),
      });
      play_begin ? series_data.push({
        name: 'currscatter',
        type: 'scatter',
        symbol: 'image:///images/center/a2.png',
        symbolRotate: (360 - ticket_lines.ticket_lines[movePos].direction),
        symbolSize: [15, 42.5],
        coordinateSystem: 'bmap',
        zlevel: 4,
        data : ticket_lines.ticket_lines.length > 0 ? [{time: ticket_lines.ticket_lines[movePos].time,direction: ticket_lines.ticket_lines[movePos].direction, speed: ticket_lines.ticket_lines[movePos].speed, value: h_common.GPS_transformation(ticket_lines.ticket_lines[movePos].lat, ticket_lines.ticket_lines[movePos].lng)}] : [{}],
        itemStyle: {
          normal: {
            opacity: 1,
            shadowBlur: 1,
            shadowColor: '#999966',
            shadowOffsetX: 1,
            shadowOffsetY: 1,
          }
        },
        tooltip:{
          formatter:function(a){
            return a.name;
          }
        },
        label:{
          normal:{
            show:true,
            position:'top',
            formatter:function(a){
              return a.data.time + '\n' + a.data.speed + 'km/h';
            },
            backgroundColor:'#fff',
            padding:5

          }
        },
        hoverAnimation: true,
      }) : null;
      series_data.push({
        type: "scatter",
        symbol: 'path://M100 100,A50 50,0 1 1 100 101z M175 100,A25 25,0 1 0 175 101z',
        name: 'start_pointer',
        symbolSize: 15,
        zlevel: 3,
        coordinateSystem: 'bmap',
        data: [{name: '起点', value: h_common.GPS_transformation(ticket_lines.ticket_lines[0].lat, ticket_lines.ticket_lines[0].lng)}],
        tooltip:{
          show: false,
          formatter: function(parmas){
            return '起点:'
          }
        },
        label:{
          normal:{
            show : true,
            position: 'top',
            formatter:function(a,b,c){
              return '{arrow|起点}'
            },
            rich: {
              arrow:{
                color: '#fff',
                fontSize:12,
                width: 40,
                height: 26,
                align:'center',
                padding:[5,0,0,0],
                backgroundColor: {
                  image: '/images/center/diago1.png',
                  repeat:'no-repeat'
                },
                shadowBlur: 1,
                shadowColor: '#d4d4d4',
                shadowOffsetX: 1,
                shadowOffsetY: 1,
              }
            }
          }
        },
        itemStyle: {
          normal: {
            color: '#03d5a0',
            opacity: 1,

          }
        }
      });
      series_data.push({
        type: "scatter",
        symbol: 'path://M100 100,A50 50,0 1 1 100 101z M175 100,A25 25,0 1 0 175 101z',
        name: 'end_pointer',
        coordinateSystem: 'bmap',
        symbolSize: 15,
        zlevel: 3,
        tooltip:{
          show: false,
          formatter: function(parmas){
            return '终点:'
          }
        },
        data: [{name: '终点', value: h_common.GPS_transformation(ticket_lines.ticket_lines[ticket_lines.ticket_lines.length -1].lat, ticket_lines.ticket_lines[ticket_lines.ticket_lines.length -1].lng)}],
        label:{
          normal:{
            show : true,
            position: 'top',
            formatter:function(a,b,c){
              return '{arrow|终点}'
            },
            rich: {
              arrow:{
                color: '#fff',
                fontSize:12,
                width: 40,
                height: 26,
                align:'center',
                padding:[5,0,0,0],
                backgroundColor: {
                  image: '/images/center/diago3.png',
                  repeat:'no-repeat'
                },
                shadowBlur: 1,
                shadowColor: '#d4d4d4',
                shadowOffsetX: 1,
                shadowOffsetY: 1,
              }
            }
          }
        },
        itemStyle: {
          normal: {
            color: '#EB3873',
            opacity: 1,
          }
        }
      });
      if(play_begin){
        setCenterFlag(false);
        let m_c = h_common.GPS_transformation(ticket_lines.ticket_lines[movePos].lat, ticket_lines.ticket_lines[movePos].lng);
        if(bound.containsPoint(new BMap.Point(m_c[0], m_c[1])) !== true) {
          setCenter(m_c);
          setCenterFlag(true);
        }
      }else{
        setCenter([ticket_lines.ticket_lines[0].lng, ticket_lines.ticket_lines[0].lat]);
        setCenterFlag(true);
      }
      setTicketSeries(series_data);
    }
  }, [ticket_lines, rectify, show_points, show_stops, moveSpeed, movePos]);
  useEffect(() => {
  }, [play_begin, movePos]);
  useInterval(() => {
    setMovePos(movePos + 1);
    setPlayIndex(movePos + 1);
  }, (play_begin && (movePos !== slider.length - 1) && !play_end) ? moveSpeed : null);
  function overTicket(ticket_id, lng, lat, time){
    request('/web/ticket_map/overTicket', {ticket_id, lng, lat, time})
      .then(body => {message.success('结束联单'); dataSource.refresh();});
  }
  // charts 的一些操作
  useEffect(() => {
    if (!isChartUsable()) return;
    chart.on('click', (data) => {
     if(data.seriesName === 'overTicket' || data.seriesName === 'carPoints'){
        const point = data.data;
        confirm({
          title: '确认在该点结束联单?',
          content: point.time,
          onOk() {
            overTicket(id, point.value[0], point.value[1], point.time)
          },
          onCancel() {
          },
        });
      }
    });
  }, [isChartUsable, chart]);
  useMap(isChartUsable, chart, ticketSeries ? ticketSeries : [], center, centerFlag);
  return (
    <Modal
      visible
      width={1600}
      bodyStyle={{height: 750, overflow: 'auto', padding: 0}}
      title="轨迹查看"
      onCancel={() => history.goBack()}
      onOk={() => history.goBack()}
      footer={null}
      >
      <div className={styles.container}>
        <div className={styles.chart} ref={chartRefGetter} style={{width: '100%'}} />
        <div style={{position: 'absolute', left: 20, top: 20}}>
          <div >
            <Radio.Group defaultValue={hour} buttonStyle="solid" onChange={(e) => {setHour(e.target.value); trash_show_detail ? getTicketDetail(ticketId, e.target.value) : ''; ticketSeries ? getTicketLine(ticketId, e.target.value) : ''}}>
              <Radio.Button value="1">1小时</Radio.Button>
              <Radio.Button value="2">2小时</Radio.Button>
              <Radio.Button value="3">3小时 </Radio.Button>
            </Radio.Group>
          </div>
        </div>
        <div className={styles.drawerConrainer}>
          <Drawer getContainer={`.${styles.drawerConrainer}`} className={styles.DrawerCon} title={false} placement="bottom" closable={false} mask={false} height={55} maskClosable={false} onClose={() => setVisible(false)} visible={visible}>
            <div className={styles.Drawer}>
              <div style={{display: 'flex'}}>
                <div className={styles.btns}>
                  <Button onClick={() => {setPlayBegin(!play_begin);setPlayEnd(false);}} className={`${styles.play_begin} ${play_begin ? styles.active : ''} `} type="dashed" title={play_begin ? '停止' : '播放'} />
                  {/*<Button onClick={() => {setPlayEnd(true);setPlayBegin(false)}} className={`${styles.play_end} ${play_end ? styles.active : ''}`} type="dashed" />*/}
                  <Button onClick={() => {setPlayRestart(!play_restart);setMovePos(0);setPlayBegin(true)}} className={`${styles.play_restart} ${play_restart ? styles.active : ''}`} type="dashed" />
                </div>
                <div className={styles.control}>
                  <Radio.Group value={moveSpeed} buttonStyle="solid" onChange={(e) => {setMoveSpeed(e.target.value)}}>
                    <Radio.Button value="800">0.5倍</Radio.Button>
                    <Radio.Button value="400">1倍</Radio.Button>
                    <Radio.Button value="200">2倍</Radio.Button>
                    <Radio.Button value="100">4倍</Radio.Button>
                    <Radio.Button value="50">8倍</Radio.Button>
                  </Radio.Group>
                  <Slider min={0} max={slider.length - 1} value={playIndex} tipFormatter={(v) => {return slider[v]} } onChange={e => setPlayIndex(e)} onAfterChange={(e) => setMovePos(e)} />
                </div>
              </div>
              <div style={{marginRight: 40, display: 'flex', alignItems: 'center'}}>
                <Switch defaultChecked={rectify} checkedChildren={'轨迹纠偏'} unCheckedChildren={'未纠偏轨迹'} onChange={e => {setRectify(e)}}> </Switch>
                <Switch defaultChecked={show_points}  checkedChildren={'原始轨迹点'} unCheckedChildren={'原始轨迹点'}  onChange={e => {setShowPoints(e)}}> </Switch>
                <Switch defaultChecked={show_stops}  checkedChildren={'停靠点'} unCheckedChildren={'停靠点'}  onChange={e => {setShowStops(e)}}> </Switch>
                <div style={{display: 'flex'}}>
                  {/*<div style={{display: 'flex', alignItems: 'center', marginLeft: 15}}><span style={{backgroundColor: '#EB3873', display: 'inline-block', width: 20, height: 2, marginRight: 5}}>{}</span><span>规划线路</span></div>*/}
                  <div style={{display: 'flex', alignItems: 'center', marginLeft: 15}}><span style={{backgroundColor: '#EB3873', display: 'inline-block', width: 20, height: 2, marginRight: 5}}>{}</span><span>纠偏轨迹</span></div>
                  <div style={{display: 'flex', alignItems: 'center', marginLeft: 15}}><span style={{backgroundColor: '#03d5a0', display: 'inline-block', width: 20, height: 2, marginRight: 5}}>{}</span><span>未纠偏轨迹</span></div>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </div>
      </Modal>

  )
}
