import React from 'react';
import moment from 'moment';
import { Route } from 'react-router-dom';
import { previewable } from 'components/Previewer';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';

import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import LinkButton from 'ui/TableCell/LinkButton';
import ImagePreviewButtonRender from 'ui/TableCell/ImagePreviewButtonRender';

import debug from 'utils/debug';

import Flow from './Flow';

function createNon(api, download) {
  const dataSource = new DataSource(api);

  // const statuses = ['督办处理', '未处理', '已处理'];
  // const statusOptions = statuses.map((label, value) => ({ value, label }));

  function Non({ preview }) {
    debug.render('Non');

    const render = ImagePreviewButtonRender(preview);

    const columns = [
      serial(true),
      { title: '告警时间', dataIndex: 'alarm_time', width: width(0, 20), fixed: true, sort: true, sortAsKey: 'order', sortDefaultValue: 1 },
      { title: '车牌号', dataIndex: 'car_num', width: width(7) },
      { title: '实例名称', dataIndex: 'instance_name' },
      {
        title: '进场',
        children: [
          { title: '进场时间', dataIndex: 'in_time', width: width(0, 20) },
          { title: '图片', dataIndex: 'in_pic', width: width(2), align: 'center', render },
        ],
      },
      {
        title: '出场',
        children: [
          { title: '出场时间', dataIndex: 'out_time', width: width(0, 20) },
          { title: '图片', dataIndex: 'out_pic', width: width(2), align: 'center', render },
        ],
      },
      { title: '处理进度', dataIndex: 'id', width: width(4), render: (id, { flow_main_name }) => <LinkButton to={`./flows/${id}`}>{flow_main_name || '查看'}</LinkButton> },
    ];

    const querys = [
      { title: '车牌、运企、驾驶员', key: 'keyword' },
      // { title: '处理状态', key: 'flow_status', type: 'select', options: [{ value: 1, label: '未完成' }, { value: 2, label: '已结束' }], allowClear: true, style: { width: 120 } },
      { title: '处理进度', key: 'flow_status', type: 'select', options: '/web/car_alarm/getFlowList', valueKey: 'id', labelKey: 'name', showSearch: true, allowClear: true, style: { width: 160 } },
      { title: '实例', key: 'instance_id', type: 'select', options: '/web/Building/index', valueKey: 'id', labelKey: 'name', showSearch: true, allowClear: true, style: { width: 200 } },
      { title: ['告警', '时间'], key: 'alarm_time', type: 'date-range', showTime: true },
    ];

    const buttons = [
      { title: '导出', download },
    ];

    return (
      <TablePage
        dataSource={dataSource}
        buttons={buttons}
        querys={querys}
        columns={columns}
        defaultLimit={10}
        defaultQuery={{
          flow_status: 30,
          alarm_time: [
            moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          ],
        }}
      />
    );
  }

  return previewable(Non);
}

const NonListed = createNon('/web/car_alarm/Directory', '/web/car_alarm/DirectoryExcel');

export default ({ path }) => (
  <>
    <Route path={path} component={NonListed} />
    <Route path={path + 'flows/:id'} component={Flow} />
  </>
);
