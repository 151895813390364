/* eslint-disable camelcase */
import React from 'react';
import moment from 'moment';
import {
  Modal,
  Form,
  Select,
  DatePicker,
  InputNumber,
  Table,
  message,
} from 'antd';
import request from 'utils/request';
import searchOptions from 'variables/searchOptions';
import { dataSource } from './Plans';

export default class PlanEditor extends React.PureComponent {
  state = {
    startOptions: [],
    endOptions: [],
    start_id: undefined,
    end_id: undefined,
    dates: [],
    materiel: [],
  }

  async componentDidMount() {
    const planId = this.props.match.params.id;
    const [
      startOptions,
      endOptions,
      optionalMateriel,
    ] = await Promise.all([
      // 获取工地列表
      request({ url: '/web/Instance/getBuildingList' }).then(body => body.data.data),
      // 获取砂石场列表
      request({ url: '/web/Instance/getSandFactoryList' }).then(body => body.data.data),
      // 获取可选物品列表
      request({ url: '/web/materiel/getDians' }).then(body => body.data.data),
    ]);

    // 判断是更新还是新增
    // 获取砂石接管编辑信息
    const planDetail = planId ? await request({ url: '/web/plan/getDinasPlanList', data: { dinas_plan_id: planId } }).then(body => body.data.data) : {
      materiel: [],
    };

    const currentMateriel = planDetail.materiel || [];
    const materielMap = new Map();

    // materiel_id: 刘国军接口返回的物品ID
    // id: 郭嘉宇为每一种方案下每一种物品又新生成的ID
    optionalMateriel.forEach(({ id: materiel_id, name }) => materielMap.set(materiel_id, { materiel_name: name }));
    currentMateriel.forEach(({ materiel_id, ...rest }) => materielMap.set(materiel_id, { ...rest, checked: true }));
    const materiel = [...materielMap].map(([materiel_id, rest]) => ({ materiel_id, ...rest }));

    this.setState({
      startOptions,
      endOptions,
      start_id: planDetail.start_id,
      end_id: planDetail.end_id,
      dates: planId ? [moment(planDetail.start_date), moment(planDetail.end_date)] : [],
      materiel,
    });
  }

  onChange = key => value => this.setState({ [key]: value })
  routeBack = () => this.props.history.goBack()

  onSubmit = () => {
    // 物品处理
    // id不存在并且checked=false 则过滤掉
    // id存在并且checked=false 则标记为deleted=true
    const materiels = this.state.materiel
      .filter(({ id, checked }) => id || checked)
      .map(({ checked, ...mater }) => ({ ...mater, deleted: (mater.id && !checked) ? 1 : 0 }))
      .map(({ materiel_id, materiel_name, ...rest }) => ({
        ...rest,
        materiel: {
          id: materiel_id,
          name: materiel_name,
        },
      }));
    // 展开起点工地
    const {
      area_id,
      department_id: start_department_id,
      id: start_id,
      name: start_name,
    } = this.state.startOptions.find(({ id }) => id === this.state.start_id);
    // 展开终点砂石场
    const {
      department_id: end_department_id,
      id: end_id,
      name: end_name,
    } = this.state.endOptions.find(({ id }) => id === this.state.end_id);
    // 起止时间
    const start_date = this.state.dates[0].format('YYYY-MM-DD HH:mm:ss');
    const end_date = this.state.dates[1].format('YYYY-MM-DD HH:mm:ss');
    // 方案ID
    const { id } = this.props.match.params;

    // 提交
    const data = {
      id,
      area_id,
      start_department_id,
      start: {
        id: start_id,
        name: start_name,
      },
      end_department_id,
      end: {
        id: end_id,
        name: end_name,
      },
      start_date,
      end_date,
      materiels,
    };
    // 判断是更新还是新增
    (id ? this.update(data) : this.create(data))
      .then(body => body.data)
      .then(({ code, msg }) => {
        if (code === 200) {
          dataSource.refresh();
          this.routeBack();
        } else {
          message.error(typeof msg === 'string' ? msg : '请求失败');
        }
      });
  }

  update(data) {
    return request('/web/plan/updateDinasPlanInfo', data);
  }

  create(data) {
    return request('/web/plan/createDinasPlanInfo', data);
  }

  materielRender = dataIndex => (value, { materiel_id, checked }) => (
    <InputNumber
      value={value}
      disabled={!checked}
      onChange={next => this.setState(state => ({
        materiel: state.materiel.map(mater => ({
          ...mater,
          [dataIndex]: mater.materiel_id === materiel_id ? next : mater[dataIndex],
        })),
      }))}
    />
  )
  materielColumns = [{
    title: '物品',
    dataIndex: 'materiel_name',
  }, {
    title: '接管方量(方)',
    dataIndex: 'volumn',
    render: this.materielRender('volumn'),
  }, {
    title: '运费单价(元)',
    dataIndex: 'unit_price',
    render: this.materielRender('unit_price'),
  }]

  render() {
    const {
      startOptions,
      endOptions,
      start_id,
      end_id,
      dates,
      materiel,
    } = this.state;
    const unSubmitable = (
      start_id === undefined
      || end_id === undefined
      || dates.length === 0
      || materiel.find(({ checked, volumn, unit_price }) => checked && (!volumn || !unit_price))
      || materiel.filter(({ checked }) => checked).length === 0
    );
    return (
      <Modal
        visible
        title="编辑砂石接管方案"
        onCancel={this.routeBack}
        okButtonProps={{ disabled: unSubmitable }}
        onOk={this.onSubmit}
      >
        <Form>
          <Form.Item label="选择工地">
            <Select {...searchOptions} value={start_id} onChange={this.onChange('start_id')}>
              {startOptions.map(({ id, name }) => <Select.Option key={id} value={id}>{name}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item label="选择砂石场">
            <Select {...searchOptions} value={end_id} onChange={this.onChange('end_id')}>
              {endOptions.map(({ id, name }) => <Select.Option key={id} value={id}>{name}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item label="起止时间">
            <DatePicker.RangePicker value={dates} onChange={this.onChange('dates')} disabledDate={current => current && current <= moment().startOf('day')} />
          </Form.Item>
          <Form.Item label="选择物品">
            <Table
              bordered
              rowKey="materiel_id"
              pagination={false}
              columns={this.materielColumns}
              dataSource={materiel}
              rowSelection={{
                selectedRowKeys: materiel.filter(({ checked }) => checked).map(({ materiel_id }) => materiel_id),
                onChange: materiel_ids => this.setState(state => ({
                  materiel: state.materiel.map(mater => ({ ...mater, checked: materiel_ids.includes(mater.materiel_id) })),
                })),
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
