// https://ant.design/components/input-cn/#Input
import React from 'react';
import { Input } from 'antd';

export default function Text({ onChange, extraText, ...props }) {
  return (
    <>
      <Input {...props} onChange={e => onChange(e.target.value)} />
      {extraText && <span style={{color: '#FD322C'}}>{extraText}</span>}
    </>
  );
}
