import React, {useState, useEffect} from 'react';
import {Button, Divider, Popconfirm, message} from 'antd';
import { previewable } from 'components/Previewer';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';

import LinkButton from 'ui/TableCell/LinkButton';
import LinkText from 'ui/TableCell/LinkText';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';
import debug from 'utils/debug';
import request from "utils/request";

const CheckStatus = [
  { name: '待区（市）县扬尘办审核', id: 1 },
  { name: '待市1级审核', id: 2 },
  { name: '待市2级审核', id: 5 },
  { name: '待市3级审核', id: 6 },
  { name: '通过', id: 3 },
  { name: '不通过', id: 4 }
]; //1.待区（市）县扬尘办审核 2.待市一级审核 3.通过 4.不通过 5.待市二级审核 6.待市三级审核'
const ReportType = [
  { name: '新增', id: 1},
  { name: '退出', id: 2},
];
const STATUS = ['', '待区（市）县扬尘办审核', '待市级扬尘办审核', '通过', '不通过', '待市二级审核', '待市三级审核'];
export const dataSource = new DataSource('/web/report/getOfficialReportLists');
function DeleteMsg(id){
  request('/web/report/deleteOfficialReport', {car_official_report_id: id})
    .then(body => {if(body.data.code === 200){message.success('删除成功！'); dataSource.refresh()}else{message.warn('删除失败！')}})
}
function judgeShow (){
  const [flag, setFlag] = useState(true);
  const [notice, setNotice] = useState('');
  useEffect(() => {
    request('/web/report/isReportChecked', { report_type: 2 })
      .then(body => body.data)
      .then(data => {setFlag(data.status); setNotice(data.notice)})
  }, []);
  return [flag, notice];
}
function List({ preview }) {
  debug.render('List');
  const [showFlag, notice] = judgeShow();
  const columns = [
    serial(true),
    { title: '标题', dataIndex: 'title'},
    { title: '备案类型', dataIndex: 'report_type', render: (type) => (<span>{type === 1 ? '新增' : '退出'}</span>)},
    { title: '报备时间', dataIndex: 'created_time', width: width(14)},
    { title: '车辆数量', dataIndex: 'car_count', width: width(7), render: (car_count, {id}) => (
      <>
        {
          car_count === 0 ? 0 : <LinkText to={`./${id}/${2}/show-cars/`}>{car_count}</LinkText>
        }
      </>
      )
    },
    { title: '报备要件', dataIndex: 'files', width: width(7), render: ($, {id}) => (
        <>
          <LinkText to={`./${id}/${2}/show-files`}>查看</LinkText>
        </>
      ) },
    { title: '备注', dataIndex: 'remark', render: (v) => (<div title={v} style={{width: 200,overflow: 'hidden',
    textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{v}</div>) },
    { title: '审核状态', dataIndex: 'status', render: (t, value) => (<span>{STATUS[t]}</span>) },
    { title: '审核记录', dataIndex: 'start_name', width: width(7) , render: ($, {id}) => (
        <>
          <LinkText to={`./${id}/${2}/check-record`}>查看</LinkText>
        </>
      )},
    actions({
      isFixed: true,
      width: 290,
      render: ($, { id, status }) => (
        <>
          { (status === 1 || status === 4) && showFlag ? (<><LinkButton to={`./${id}/replay-list-reporting`}>重新报备</LinkButton><Divider type="vertical" /></>) : null}
          <Popconfirm placement="top" title={'确认删除?'} onConfirm={() => { DeleteMsg(id) }} okText="Yes" cancelText="No">
            <Button type={'danger'} size={'small'}>删除</Button>
          </Popconfirm>
        </>
      ),
    }),
  ];

  const querys = [
    { title: '请输入标题搜索', key: 'keyword' },
    { title: '请选择备案类型', key: 'report_type', type: 'select', options: ReportType, valueKey: 'id', labelKey: 'name', style: { width: 160 } },
    { title: '请选择审核状态', key: 'status', type: 'select', options: CheckStatus, valueKey: 'id', labelKey: 'name', style: { width: 200 } },
    { title: ['报备', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD HH:mm:ss', style: { width: 360 }, showTime: true },
  ];

  const buttons = [
    { title: '名录报备', link: './list-reporting', icon: 'plus', disabled: !showFlag, extraNotice: notice  },
  ];


  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}

export default previewable(List);
