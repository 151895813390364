import React from 'react';
import { Button, Divider, Icon, Tag } from 'antd';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import LinkButton from 'ui/TableCell/LinkButton';
import ConfirmButton from 'ui/TableCell/ConfirmButton';
import DeleteButton from 'ui/TableCell/DeleteButton';
import width from 'ui/TableCell/width';
import actions from 'ui/TableCell/actions';
import debug from 'utils/debug';
import { rowSpanRender as render } from 'utils/render';
import { labels, colors } from 'variables/auditStatus';

export const dataSource = new DataSource('/web/plan/getDinasPlanLists', {}, (data) => {
  return data.reduce((flatten, { materiel, ...plan }, index) => {
    const serial = index + 1;
    const key = i => `${plan.id}-${i}`;
    const span = i => (i === 0 ? materiel.length : 0);
    return [
      ...flatten,
      ...materiel.map((mater, i) => ({
        ...mater,
        ...plan,
        serial,
        key: key(i),
        span: span(i),
      })),
    ];
  }, []);
});

export default function List() {
  debug.render('List');

  const statusLables = ['', '未开始', '已开始', '已结束'];
  const statusColors = ['', 'rgb(182,182,187)', 'rgb(40,205,65)', 'rgb(182,182,187)'];
  const StatusUpdater = (current, { id }) => {
    const tag = <Tag color={statusColors[current]}>{statusLables[current]}</Tag>;
    if (current >= statusLables.length - 1) return tag;
    const title = `确认修改状态为 '${statusLables[current + 1]}'?`;
    return <ConfirmButton title={title} api="/web/plan/updateDinasPlanStatus" id={id} status={current + 1} dataSource={dataSource}>{statusLables[current]}</ConfirmButton>;
  };

  const DetailLink = (status, { id }) => {
    const tag = <Tag color={colors[status]}>{labels[status]}</Tag>;
    if (status === 0) return tag;
    return <LinkButton to={`../takeover-routes/${id}/detail`}>{labels[status]}</LinkButton>;
  };

  const columns = [
    { title: '序号', dataIndex: 'serial', render, width: width(2), align: 'center' },
    { title: '工地', dataIndex: 'start_name', render: render((name, { start_id }) => <LinkButton to={`./${start_id}/location`}>{name}</LinkButton>) },
    { title: '砂石开挖状态', dataIndex: 'status', render: render(StatusUpdater) },
    { title: '砂石开挖状态记录人', dataIndex: 'last_record_user_name', render },
    { title: '砂石场', dataIndex: 'end_name', render },
    { title: '开始时间', dataIndex: 'start_date', render: render(cell => cell && cell.slice(0, 10)) },
    { title: '结束时间', dataIndex: 'end_date', render: render(cell => cell && cell.slice(0, 10)) },
    { title: '物品', dataIndex: 'materiel_name' },
    { title: '接管方量(方)', dataIndex: 'volumn', render: string => parseFloat(string).toFixed(1) },
    { title: '运费单价(元)', dataIndex: 'unit_price', render: string => parseFloat(string).toFixed(2) },
    // { title: '申报状态', dataIndex: 'audit_status', render: render(status => <span className={styles.status} style={{ color: colors[status], borderColor: colors[status] }}><Icon type={icons[status]} /><span>{labels[status]}</span></span>) },
    { title: '申报状态', dataIndex: 'audit_status', render: render(DetailLink) },
    { title: '关联车辆', key: 'cars-action', render: render((cell, { id }) => <LinkButton to={`./${id}/cars/`}>查看</LinkButton>) },
    actions({
      width: 140,
      render: render(($, { id, audit_status }) => (
        <>
          <DeleteButton api="/web/plan/deleteDinasPlanList" dinas_plan_id={id} dataSource={dataSource} />
          {(audit_status !== 2 && audit_status !== 4) && (
            <>
              <Divider type="vertical" />
              <LinkButton to={`./${id}/update`}>修改</LinkButton>
            </>
          )}
        </>
      )),
    }),
  ];

  const querys = [
    { title: '关键字', key: 'keyword' },
  ];

  const buttons = [
    { title: '新增', link: './create', icon: 'plus' },
  ];

  return (
    <TablePage
      rowKey="key"
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}
