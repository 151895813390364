import React, { useState } from 'react';
import { Button, Drawer, Slider, Switch, Radio } from 'antd';
import styles from './ControlSlider.scss';
import copy from 'copy-to-clipboard';

export default function ControlSlider({ trailBtn, stopPoint, onChange, onClose, showStopPoint }) {
  // const [rectify, setRectify] = useState(true); //是否轨迹纠偏
  // const [show_points, setShowPoints] = useState(false); // 是否显示原始轨迹点
  // const [show_stops, setShowStops] = useState(false); //是否显示停靠点
  // const [trailBtn, setTrailBtn] = useState({ play_begin: false, visible: false, play_restart: false, slider: 0, moveSpeed: '400', playIndex: 0, movePos: 0 });
  const { showCars, rectify, show_points, show_stops, play_begin, play_restart, moveSpeed, playIndex, movePos, slider } = trailBtn;

  return (
    <Drawer
      title={false}
      placement="bottom"
      closable={true}
      mask={false}
      height={55}
      bodyStyle={{padding: 10}}
      maskClosable={false}
      onClose={onClose}
      visible={true}
    >
      <div className={styles.Drawer}>
        <div style={{display: 'flex'}}>
          <div className={styles.btns}>
            <Button
              onClick={() => onChange({ ...trailBtn, play_begin: !trailBtn.play_begin, play_end: false })}
              className={`${styles.play_begin} ${play_begin ? styles.active : ''} `}
              type="dashed" title={play_begin ? '停止' : '播放'}/>
            <Button onClick={() => onChange({ ...trailBtn, movePos: 0, play_restart: !play_restart, play_begin: play_begin })} className={`${styles.play_restart} ${play_restart ? styles.active : ''}`} type="dashed"/>
          </div>
          <div className={styles.control}>
            <Radio.Group value={moveSpeed} buttonStyle="solid" onChange={(e) => onChange({ ...trailBtn, moveSpeed: e.target.value })}>
              <Radio.Button value="800">0.5倍</Radio.Button>
              <Radio.Button value="400">1倍</Radio.Button>
              <Radio.Button value="200">2倍</Radio.Button>
              <Radio.Button value="100">4倍</Radio.Button>
              <Radio.Button value="50">8倍</Radio.Button>
            </Radio.Group>
            <Slider min={0} max={slider.length - 1} value={playIndex} tipFormatter={(v) => {
              return slider[v]
            }} onAfterChange={(e) => onChange({ ...trailBtn, movePos: e })}/>
            {
              stopPoint && <div style={{marginLeft: 5}}><span>坐标：{stopPoint[0] + ', ' + stopPoint[1] }</span> <Button style={{marginLeft: 5}} size="small" onClick={() => copy(stopPoint)}>复制坐标</Button></div>
            }
          </div>
        </div>
        <div style={{marginRight: 40, display: 'flex', alignItems: 'center'}}>
          <Switch defaultChecked={showCars} checkedChildren={'车载轨迹'} unCheckedChildren={'车载轨迹'} onChange={(e) => onChange({ ...trailBtn, showCars: e })}> </Switch>
          <Switch defaultChecked={rectify} checkedChildren={'轨迹纠偏'} unCheckedChildren={'未纠偏轨迹'} onChange={(e) => onChange({ ...trailBtn, rectify: e })}> </Switch>
          <Switch defaultChecked={show_points} checkedChildren={'原始轨迹点'} unCheckedChildren={'原始轨迹点'} onChange={(e) => onChange({ ...trailBtn, show_points: e })}> </Switch>
          <Switch defaultChecked={show_stops} checkedChildren={'停靠点'} unCheckedChildren={'停靠点'} onChange={(e) => {showStopPoint(e); onChange({ ...trailBtn, show_stops: e})}}> </Switch>
          <div style={{display: 'flex'}}>
            <div style={{display: 'flex', alignItems: 'center', marginLeft: 15}}><span style={{
              backgroundColor: '#ff0000',
              display: 'inline-block',
              width: 20,
              height: 2,
              marginRight: 5
            }}>{}</span><span>纠偏轨迹</span></div>
            <div style={{display: 'flex', alignItems: 'center', marginLeft: 15}}><span style={{
              backgroundColor: '#808080',
              display: 'inline-block',
              width: 20,
              height: 2,
              marginRight: 5
            }}>{}</span><span>未纠偏轨迹</span></div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}
