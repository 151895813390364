import React, { useState, useEffect, useCallback, useMemo, useRef  } from 'react';
import { Divider, Icon } from 'antd';
import { previewable } from 'components/Previewer';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';

import LinkButton from 'ui/TableCell/LinkButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';
import user from 'stores/user';

import debug from 'utils/debug';
const ILLEGALS = [
  { value: 1, label: '超速' },
  { value: 2, label: '违规倾倒' },
  { value: 3, label: '路线偏移' },
  { value: 4, label: '违规出料' },
  { value: 5, label: '重车未密闭' },
  { value: 6, label: '商砼车停靠过长' },
];

export const dataSource = new DataSource('/web/ticket/getExceptionTicket', {account_source: 0});
function List({ preview }) {
  debug.render('List');

  const [roleId, setRoleId] = useState('');
  useEffect(()=>{setRoleId(user.getState().roles.id)});

  const renderPreviewIllegalMapIcon = type => (isIllegal, { id }) => {
    if (!isIllegal) return <Icon type="minus" style={{ color: 'rgb(152,152,157)' }} />;
    return <Icon type="warning" theme="filled" style={{ cursor: 'pointer', fontSize: 18, color: 'rgb(255,45,85)' }} onClick={() => preview.illegalMap(id, type)} />;
  };

  const columns = [
    serial(true),
    { title: '单号', dataIndex: 'number', fixed: 'left' },
    { title: '运输车', dataIndex: 'car_num', width: width(7), fixed: 'left' },
    { title: '来源', dataIndex: 'source_text', fixed: 'left' },
    { title: '驾驶员', dataIndex: 'driver_name', width: width(4) },
    { title: '物品', dataIndex: 'materiel_name', width: width(6) },
    { title: '方量', dataIndex: 'volumn', width: width(0, 6) },
    { title: '起点', dataIndex: 'start_name' },
    { title: '起点时间', dataIndex: 'start_time', width: width(0, 19) },
    { title: '终点', dataIndex: 'end_name' },
    { title: '终点时间', dataIndex: 'end_time', width: width(0, 19) },
    { title: '收货人', dataIndex: 'acquirer_name', width: width(4) },
    {
      title: '运输时长',
      dataIndex: 'transport_time',
      render: ($) => {
        const hour = Math.floor($ / 60);
        const minute = $ % 60;
        const show = (number, suffix) => (number > 0 ? `${number}${suffix}` : '');
        return `${show(hour, '小时')}${show(minute, '分钟')}`;
      },
    },
    { title: '运输距离', dataIndex: 'distance', align: 'center', width: width(0, 10), render: $ => ($ && ($ <= 1000 ? `${$}米` : `${($ / 1000).toFixed(2)}公里`)) },
    { title: '超速', dataIndex: 'overspeed', align: 'center', render: renderPreviewIllegalMapIcon('overspeed') },
    { title: '路线偏离', dataIndex: 'route_offset', align: 'center', render: renderPreviewIllegalMapIcon('route_offset') },
    { title: '疑似违规倾倒', dataIndex: 'illegal_dump', align: 'center', render: renderPreviewIllegalMapIcon('illegal_dump') },
    { title: '篷布未密闭', dataIndex: 'uncovered_tarpaulin', align: 'center', render: renderPreviewIllegalMapIcon('uncovered_tarpaulin') },
    { title: '疑似违规出料', dataIndex: 'illegal_discharge', align: 'center', render: renderPreviewIllegalMapIcon('illegal_discharge') },
    { title: '商砼车停靠过长', dataIndex: 'parking_too_long', align: 'center', render: renderPreviewIllegalMapIcon('parking_too_long') },
    { title: '结算状态', dataIndex: 'account_status', width: width(4), align: 'center' },
  ];

  if (roleId !== 101) {
    columns.push(
      actions({
        isFixed: true,
        width: 290,
        render: ($, { id }) => (
          <>
            <LinkButton to={`./${id}/detail`}>详情</LinkButton>
            <Divider type="vertical" />
            <LinkButton to={`./${id}/trail`}>查看轨迹</LinkButton>
          </>
        ),
      }),
    );
  }

  const querys = [
    /*{ title: '关键字', key: 'keyword', style: { width: 100 } },
    { title: '起点部门', key: 'start_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', style: { width: 120 } },
    { title: '起点', key: 'start_id', rely: 'start_department_id', relyAs: 'id', type: 'select', options: '/web/instance/getStartOrEnd', valueKey: 'id', labelKey: 'name', showSearch: true, style: { width: 200 } },
    { title: ['起点', '时间'], key: 'start_time', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
    { title: '终点部门', key: 'end_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', style: { width: 120 } },
    { title: '终点', key: 'end_id', rely: 'end_department_id', relyAs: 'id', type: 'select', options: '/web/instance/getStartOrEnd', valueKey: 'id', labelKey: 'name', showSearch: true, style: { width: 200 } },
    { title: ['终点', '时间'], key: 'end_time', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
    { title: '违规类型', key: 'illegal_id', type: 'select', options: ILLEGALS, mode: 'multiple', style: { width: 200 } },
    { title: '物品', key: 'materiel_id', type: 'select', options: '/web/route/initMateriel', valueKey: 'id', labelKey: 'name', style: { width: 120 } },*/
  ];

  const buttons = [
    // { title: '导出', download: '/web/ticket/exportExcel', extraParams: {account_source: 0} },
  ];

  if (roleId !== 101) {
    // buttons.push({ title: '补录', link: './create', icon: 'plus' })
  }

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      columns={columns}
      defaultLimit={10}
    />
  );
}
export default previewable(List);
