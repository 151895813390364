import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import echarts from 'echarts';
import request from 'utils/request';
import styles from './index.scss';
import { DatePicker, Button, Input, Slider, Switch, Drawer, Radio, message, Spin, Icon, Modal, Cascader } from "antd";
import h_common from 'utils/h_common';
import debug from 'utils/debug';
import LeftList from './LeftList';
import RightList from './RightList';
import polygonCenter from './polygonCenter'
const isArrayAllValid = array => array.findIndex(item => item === undefined) === -1;
const coordObjectsToArrays = objects => objects.map(({ lng, lat }) => [lng, lat]); //单点后轨迹
const coordObjectsToArraysByTrans = objects => objects.map(({ lng, lat }) => h_common.GPS_transformation(lat, lng));
const coordObjectsToArraysByTicket = objects => objects.map(({ lng, lat, stay_time, ticket_id, time, }) => ({ value: h_common.GPS_transformation(lat, lng), stay_time, ticket_id, time }));
const coordObjectsToArraysByPoints = objects => objects.map(({ lng, lat, speed, time, ticket_id }) => ({ value: h_common.GPS_transformation(lat, lng), speed, time, ticket_id }));
const logChartDispose = () => debug.action('Echarts实例已卸载') || true;
function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => { savedCallback.current = callback; });
  useEffect(() => { function tick() { savedCallback.current() }; if (delay !== null) { let id = setInterval(tick, delay); return () => clearInterval(id); } }, [delay]);
}
/** echatrs存储实例操作 */
 function useEcharts() {
  const [chart, setChart] = useState(null); // 存储echart实例
  const refGetter = useCallback(element => !!element && setChart(echarts.init(element)), []); // 创建echart实例
  const isChartUsable = useCallback(() => (!!chart && !chart.isDisposed()), [chart]); // 判断echart实例是否可用
  useEffect(() => () => (isChartUsable() && logChartDispose() && chart.dispose()), [chart]); // 卸载echart实例
  return [chart, isChartUsable, refGetter];
}
let bound = null; let bmap = null; let drawingManager = null;
function useMap(isChartUsable, chart, series, mapPoints, center, play_begin, changeCenter, countryPoint, lookSave) {
  const new_series = { //绿色区域
    name: 'mapPoint', zlevel: 2, type: 'custom', coordinateSystem: 'bmap', tooltip: { show: false },
    renderItem: function (params, api) {
      if (mapPoints === undefined) return
      if (mapPoints.length <= 2) { return false }
      const points = mapPoints.map(({ lng, lat }) => api.coord([lng, lat]))
      return {
        type: 'polygon',
        shape: { points: echarts.graphic.clipPointsByRect(points, { x: params.coordSys.x, y: params.coordSys.y, width: params.coordSys.width, height: params.coordSys.height }) },
        style: api.style({ fill: 'rgba(151, 212, 33, 0.8)', stroke: 'rgb(106, 193, 11)', lineWidth: 3 }),
        styleEmphasis: api.style({ fill: 'rgba(151, 212, 33, 0.8)', stroke: 'rgb(106, 193, 11)', lineWidth: 3 }),
      };
    }, animation: false, data: [{ name: '', value: 0, location: mapPoints, index: lookSave }], z: -10
  }
  const new_series1 = countryPoint.map(v => ({ //橙色区域
    name: v.name, zlevel: 2, type: 'custom', coordinateSystem: 'bmap',
    tooltip: { show: true, formatter: '名称：' + v.name + '<br/>' },
    renderItem: function (params, api) {
      if (v.points.length === 0) { return false }
      const points = v.points.map(({ lng, lat }) => api.coord([lng, lat]))
      return {
        type: 'polygon',
        shape: { points: echarts.graphic.clipPointsByRect(points, { x: params.coordSys.x, y: params.coordSys.y, width: params.coordSys.width, height: params.coordSys.height }) },
        style:
          api.style({
            fill: 'rgb(255, 145, 8, 0.7)',
            stroke: 'rgb(255, 145, 8)', lineWidth: 3,
          }),
        styleEmphasis: api.style({ fill: 'rgb(255, 145, 8, 0.7)', stroke: 'rgb(255, 145, 8)', lineWidth: 3 }),
      };
    },
    animation: false,
    data: [{ name: '', value: '', location: v.points, id: v.area_id }], z: -10
  }))

  useEffect(() => {
    if (!isChartUsable()) return;
    (!play_begin) ? chart.clear() : null;
    let option = {
      tooltip: { trigger: 'item' }, animation: false,
      bmap: { center: center || [103.836472, 30.719426], zoom: 13, roam: true, mapStyle: {}, },
      series: [...series, ...new_series1, new_series]
    };
    if (bmap) {
      option.bmap.zoom = bmap.getZoom();
      let lng = bmap.getCenter().lng;
      let lat = bmap.getCenter().lat;
      bound = bmap.getBounds();
      if (bound.containsPoint(new BMap.Point(center[0], center[1])) !== true) {
        option.bmap.center = center;
      }
      else { option.bmap.center = [lng, lat] }
    }
    if (changeCenter) { option.bmap.zoom = 14 }
    option.bmap.center = center;
    chart.setOption(option);
    bmap = chart ? chart.getModel().getComponent('bmap').getBMap() : null;
    if (bmap) {
      drawingManager = new BMapLib.DrawingManager(bmap, {
        isOpen: false, //是否开启绘制模式
        enableDrawingTool: true, //是否显示工具栏
        drawingToolOptions: {
          anchor: BMAP_ANCHOR_TOP_RIGHT, //位置
          offset: new BMap.Size(5, 5), //偏离值
          drawingModes: [//显示的绘制模式
            BMAP_DRAWING_POLYLINE,//线
            BMAP_DRAWING_POLYGON, //多边形
          ]
        },
        open: open
      });
      bmap.addControl(new BMap.MapTypeControl());
    }
  }, [chart, series, mapPoints,changeCenter]);
}
function useSingle(mapList) {
  const [index, setIndex] = useState();
  const dependencies = [mapList, index];
  const plan = useMemo(() => {
    if (!isArrayAllValid(dependencies)) return {};
    const single = { ...mapList[index], index: (index + 1) }
    return single || {};
  }, [dependencies]);
  return [plan, setIndex];
}
let stopPoints = [];
export default function TransportSkynet(props) {
  const [chart, isChartUsable, chartRefGetter] = useEcharts();
  const [series, setSeries] = useState([]); //轨迹和停靠点
  const [flage, setFlag] = useState(false)//确认获取轨迹（单个区域）
  const [sureGetCar, setSureGetCar] = useState(false)//确认获取轨迹
  const [showSeries, setShowSeries] = useState(false);//显示红色轨迹，flase显示
  const [changeCenter, setChangeCenter] = useState(false);//更改中心点zoom
  const [mapPoints, setMapPoints] = useState([]);//图形区域
  const [countryPoint, setCountryPoint] = useState([])//八个国标
  const [mapList, setMapList] = useState([])
  const [leftLoading, setLeftLoading] = useState(false)
  const [single, setSingle] = useSingle(mapList);
  const [center, setCenter] = useState([104.0723, 30.6632]);
  const [ticket_lines, setTicketLines] = useState();
  const [ticketSeries, setTicketSeries] = useState();
  const [hour, setHour] = useState("1");
  const [rectify, setRectify] = useState(true); //是否轨迹纠偏
  const [show_points, setShowPoints] = useState(false); // 是否显示原始轨迹点
  const [show_stops, setShowStops] = useState(false); //是否显示停靠点
  const [trailBtn, setTrailBtn] = useState({ play_begin: false, visible: false, play_restart: false, slider: 0, moveSpeed: '400', playIndex: 0, movePos: 0 });
  const [singleSure, setSingleSure] = useState(false)
  const [lookSave, setLookSave] = useState(null)
  const [finishe, setFinshe] = useState(false)
  const [singleFlag, setSingleFlag] = useState(false)
  useEffect(() => {
    const webRTC1 = document.createElement('script');
    webRTC1.type = 'text/javascript';
    webRTC1.src = `https://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.css`;
    document.head.appendChild(webRTC1);
    const webRTC2 = document.createElement('script');
    webRTC2.type = 'text/javascript';
    webRTC2.src = `https://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js`;
    document.head.appendChild(webRTC2);
  }, []);
  useEffect(() => {//获取单个轨迹
    if (singleSure) {
      setLeftLoading(true)
      if (single.index <= mapList.length) {
        const e = mapList[single.index - 1];
        request('/web/ticket_map/getTicketLineByCar', { car_num: e.car_number, start_time: e.time, hour: hour })
          .then(body => {
            const data = body.data.data;
            let ticket_dates = [];
            if (data.ticket_lines.length === (0 || 1) || data.stop_points.length === 0 || data.bind_lines.length === (0 || 1)) {
              message.warn('暂无联单路线');
              closebottom()
            } else {
              setTicketLines(data);
              ticket_dates = data.ticket_lines.map(({ time }) => time);
              setTrailBtn({ ...trailBtn, play_begin: false, visible: true, play_restart: false, slider: ticket_dates, playIndex: 0, movePos: 0 });
            }
          })
          .finally(() => {
            setLeftLoading(false)
          })
      }
    }
    setTimeout(() => { setSingleSure(false) }, 500);
  }, [singleSure, hour]);
  useEffect(() => { //控制显示下侧组件
    if (ticket_lines) {
      let series_data = [];
      rectify ? series_data.push({  // 显示纠偏轨迹 ？ 原始轨迹
        type: 'lines', zlevel: 8, name: 'trailLines', coordinateSystem: 'bmap', polyline: true, silent: true,
        lineStyle: { normal: { color: '#46bee9', opacity: 1, width: 4, }, },
        progressiveThreshold: 500,
        progressive: 200,
        data: [{ coords: coordObjectsToArrays(ticket_lines.bind_lines || []), }]
      }) : series_data.push({
        type: 'lines', coordinateSystem: 'bmap', polyline: true, silent: true, zlevel: 8,
        lineStyle: { normal: { color: '#03d5a0', opacity: 1, width: 3 } },
        progressiveThreshold: 500, progressive: 200,
        data: [{ coords: coordObjectsToArraysByTrans(ticket_lines.ticket_lines || []), }]
      });
      show_points ? series_data.push({//是否显示原始轨迹点
        name: 'carPoints', type: 'scatter', symbol: 'circle', coordinateSystem: 'bmap',
        zlevel: 8, data: coordObjectsToArraysByPoints(ticket_lines.ticket_lines || []),
        symbolSize: [10, 10],
        tooltip: { formatter: function (a, b, c) { return a.data.time + '<br />' + a.data.speed + 'km/h' } },
        itemStyle: { normal: { color: "#5D59E8", opacity: 0.8 } },
      }) : series_data.push({
        name: 'carPoints', type: 'scatter', symbol: 'circle',
        coordinateSystem: 'bmap', zlevel: 5,
        data: coordObjectsToArraysByPoints(ticket_lines.ticket_lines || []),
        symbolSize: [10, 10],
        tooltip: { formatter: function (a, b, c) { return a.data.time + '<br />' + a.data.speed + 'km/h' } },
        itemStyle: { normal: { color: "#5D59E8", opacity: 0 } },
      });
      show_stops ? series_data.push({//是否显示停靠点
        type: 'scatter', coordinateSystem: 'bmap', name: 'overTicket', symbol: 'circle', symbolSize: 15, zlevel: 8,
        itemStyle: { color: '#1491FB', opacity: 1, },
        tooltip: { show: true, formatter: function (a) { return a.data.time + '\n停靠：' + a.data.stay_time + '分钟' } },
        label: {
          show: true, position: 'top', backgroundColor: 'rgba(255, 255, 255, .85)', padding: [2, 5],
          formatter: function (a) { return a.data.time + '\n停靠：' + a.data.stay_time + '分钟' }
        },
        data: coordObjectsToArraysByTicket(ticket_lines.stop_points || []),
      }) : series_data.push({
        type: 'scatter', coordinateSystem: 'bmap', name: 'overTicket', symbol: 'circle', symbolSize: 15,
        itemStyle: { color: '#1491FB', opacity: 0, },
        label: {
          show: true, position: 'top', backgroundColor: 'rgba(255, 255, 255, .65)', padding: [2, 5],
          formatter: function (a) { return a.data.time + '\n停靠：' + a.data.stay_time + '分钟' }
        },
        data: coordObjectsToArraysByTicket(ticket_lines.stop_points || []),
      });
      trailBtn.play_begin ? series_data.push({
        name: 'currscatter', type: 'scatter',
        symbol: 'image:///images/center/a2.png',
        symbolRotate: (360 - ticket_lines.ticket_lines[trailBtn.movePos].direction),
        symbolSize: [15, 42.5],
        coordinateSystem: 'bmap',
        zlevel: 8,
        data: [{ time: ticket_lines.ticket_lines[trailBtn.movePos].time, direction: ticket_lines.ticket_lines[trailBtn.movePos].direction, speed: ticket_lines.ticket_lines[trailBtn.movePos].speed, value: h_common.GPS_transformation(ticket_lines.ticket_lines[trailBtn.movePos].lat, ticket_lines.ticket_lines[trailBtn.movePos].lng) }],
        itemStyle: { normal: { opacity: 1, shadowBlur: 1, shadowColor: '#999966', shadowOffsetX: 1, shadowOffsetY: 1 } },
        tooltip: { formatter: function (a) { return a.name } },
        label: { normal: { show: true, position: 'top', formatter: function (a) { return a.data.time + '\n' + a.data.speed + 'km/h' }, backgroundColor: '#fff', padding: 5 } },
      }) : null;
      if (ticket_lines.ticket_lines.length > 0) {
        series_data.push({
          type: "scatter",
          symbol: 'path://M100 100,A50 50,0 1 1 100 101z M175 100,A25 25,0 1 0 175 101z',
          name: 'start_pointer', symbolSize: 15, zlevel: 9, coordinateSystem: 'bmap',
          data: [{ name: '起点', value: h_common.GPS_transformation(ticket_lines.ticket_lines[0].lat, ticket_lines.ticket_lines[0].lng) }],
          tooltip: { show: false, formatter: function (parmas) { return '起点:' } },
          label: {
            normal: {
              show: true, position: 'top', formatter: function (a, b, c) { return '{arrow|起点}' },
              rich: {
                arrow: {
                  color: '#fff', fontSize: 12, width: 40, height: 26, align: 'center',
                  padding: [5, 0, 0, 0], backgroundColor: { image: '/images/center/diago1.png', repeat: 'no-repeat' },
                  shadowBlur: 1, shadowColor: '#d4d4d4', shadowOffsetX: 1, shadowOffsetY: 1,
                }
              }
            }
          },
          itemStyle: { normal: { color: '#03d5a0', opacity: 1, } }
        });
        series_data.push({
          type: "scatter",
          symbol: 'path://M100 100,A50 50,0 1 1 100 101z M175 100,A25 25,0 1 0 175 101z',
          name: 'end_pointer',
          coordinateSystem: 'bmap',
          symbolSize: 15,
          zlevel: 9,
          tooltip: { show: false, formatter: function (parmas) { return '终点:' } },
          data: [{ name: '终点', value: h_common.GPS_transformation(ticket_lines.ticket_lines[ticket_lines.ticket_lines.length - 1].lat, ticket_lines.ticket_lines[ticket_lines.ticket_lines.length - 1].lng) }],
          label: {
            normal: {
              show: true,
              position: 'top',
              formatter: function (a, b, c) { return '{arrow|终点}' },
              rich: { arrow: { color: '#fff', fontSize: 12, width: 40, height: 26, align: 'center', padding: [5, 0, 0, 0], backgroundColor: { image: '/images/center/diago3.png', repeat: 'no-repeat' }, shadowBlur: 1, shadowColor: '#d4d4d4', shadowOffsetX: 1, shadowOffsetY: 1, } }
            }
          },
          itemStyle: { normal: { color: '#EB3873', opacity: 1, } }
        });
      }
      if (trailBtn.play_begin) {
        let m_c = h_common.GPS_transformation(ticket_lines.ticket_lines[trailBtn.movePos].lat, ticket_lines.ticket_lines[trailBtn.movePos].lng);
        if (!bound.containsPoint(new BMap.Point(m_c[0], m_c[1]))) {
          setCenter(m_c)
        }
      } else {
        if (ticket_lines.ticket_lines.length > 0) {
          setCenter(h_common.GPS_transformation(ticket_lines.ticket_lines[0].lat, ticket_lines.ticket_lines[0].lng))
        }
      }
      setTicketSeries([...series_data])
    }
  }, [ticket_lines, rectify, show_points, show_stops, trailBtn]);
  useEffect(() => { }, [trailBtn]);
  useInterval(() => {
    setTrailBtn({ ...trailBtn, movePos: (trailBtn.movePos + 1), playIndex: (trailBtn.movePos + 1) })
  }, (trailBtn.play_begin && (trailBtn.movePos !== trailBtn.slider.length - 1)) ? trailBtn.moveSpeed : null);
  const SeriesLinesEffect = (data, name, symbolType = 'image:///images/center/a4.png', symbolSize, symbol = ['none', 'arrow'], color, silent = false) => {
    return {
      type: 'lines', name: name, polyline: true, silent: true, coordinateSystem: 'bmap', symbol: symbol,
      zlevel: 5,
      effect: { show: true, period: 6, trailLength: 0, symbol: symbolType, symbolSize: symbolSize, },
      tooltip: { show: false, },
      lineStyle: {
        normal: {
          color: color,//'#FF9316',
          opacity: 1, width: 2, curveness: 0.5
        },
      },
      progressiveThreshold: 500, progressive: 200, data: data,
      silent,
    }
  };
  const SeriesPoints = (data, name, label, color, symbol, symbolSize, offsetArr, labelShow, silent = false) => {
    return {
      type: 'scatter',
      coordinateSystem: 'bmap',
      labelName: 'label', symbol: symbol, symbolSize: symbolSize,
      zlevel: 2, rippleEffect: { brushType: 'stroke', }, name: name,
      label: { normal: { show: labelShow, position: 'inside', offset: offsetArr, color: '#ffffff', formatter: function (a) { return label }, }, },
      itemStyle: { normal: { color, opacity: 1, }, },
      tooltip: { formatter: '{b}' }, data: data,
      silent,
    }
  };
  useEffect(() => {
    let coords = [];
    stopPoints = [];
    mapList.map(({ points, stop_points }, index) => {
      if (Array.isArray(points) && points.length > 1) { coords.push({ coords: points.map(({ lng, lat }) => ([lng, lat])) }) }
      if (stop_points.length > 0) {
        stop_points.map(({ lng, lat, ...res }) => {
          stopPoints.push({ ...res, index, value: [lng, lat] })
        })
      }
    })
    if (!showSeries) {
      setSeries([
        SeriesPoints(stopPoints, 'stopPoints', '停靠点', '#fd322c', 'circle', 10, [], false, true),
        SeriesLinesEffect(coords, 'trailLines', 'image:///images/center/a4.png', [15, 42.5], ['none', 'arrow'], '#FF5152', true)
      ])
    } else { //true
      setSeries([])
      setLookSave(null)
    }
    return () => {
      coords = [];
      stopPoints = []
    }
  }, [mapList, showSeries]);
  useMap(isChartUsable, chart, ticketSeries ? ticketSeries : series, mapPoints, center, trailBtn.play_begin, changeCenter, countryPoint, lookSave);
  setTimeout(function () { //地图的操作
    if (drawingManager) {
      drawingManager.addEventListener('overlaycomplete', overlayComplete);
    }
  }, 1000);
  function overlayComplete(e) { //画完之后的操作
    const map_points = e.overlay.getPath();
    setMapPoints(map_points);
    setChangeCenter(true)
    setCountryPoint([])
    setLookSave(null)
    closebottom()
    setSureGetCar(true)
    setFlag(true)
    setFinshe(true)
    setSingleFlag(true)
    setShowSeries(false)
    setCenter(polygonCenter(map_points))
    setTimeout(function () { setChangeCenter(false); setSureGetCar(false); setSingleFlag(false) }, 1100)
  }
  useEffect(() => {//区域图形点击
    if (chart) {
      chart.on('click', function (e) {
        setCountryPoint([])
        let location = e.data.location
        let id = e.data.index
        setMapPoints(location)
        setChangeCenter(true)
        setSureGetCar(true)
        setFlag(true)
        setLookSave(id)
        closebottom()
        if(location===undefined){
          closebottom()
        }else{
          setCenter(polygonCenter(location))
        }
        setTimeout(() => {
          setSureGetCar(false)
          setChangeCenter(false)
        }, 1000);
      })
    }
  }, [mapPoints,chart]);
  /** 关闭轨迹（下侧组件） */
  function closebottom() {
    setTrailBtn({ play_begin: false, visible: false, play_restart: false, slider: 0, moveSpeed: '400', playIndex: 0, movePos: 0 });
    setSingle();
    setSingleFlag(true)
    setTimeout(function () { setTicketSeries(); setSingleFlag(false) }, 100)
  }
  /** 左侧组件信息 */
  function getLeftListInfo({ mapPoints, flag, lookSave, changeTypeFlag }) {
    if (flag) { //点击查看
      setMapPoints(mapPoints)
      setFlag(true)//getCar
      setSureGetCar(true)
      setChangeCenter(true)//大小
      setCountryPoint([])
      setLookSave(lookSave);
      setShowSeries(false);//轨迹显示
      setFlag(flag)
      setCenter(polygonCenter(mapPoints))
    }
    if (changeTypeFlag) {
      setShowSeries(true);
      setCountryPoint([]);
      setMapPoints([])
    }
    setTimeout(() => {
      setSureGetCar(false);
    }, 1000);
  }
  /** 右侧组件信息 */
  function getRightListInfo({ countryPoint, mapList, leftLoading, single, singleSure }) {
    setLeftLoading(leftLoading)
    setCountryPoint(countryPoint)
    setMapList(mapList)
    setSingle(single)
    setSingleSure(singleSure)
  }
  useEffect(() => {//更新中心点
    if (changeCenter) { setCenter(polygonCenter(mapPoints)) }
    setTimeout(() => { setChangeCenter(false)  }, 1000);
  }, [changeCenter]);
  return (
    <div className={styles.container}>
      <Spin size="large" wrapperClassName={styles.mySpin} style={{ width: '100%', height: '100%' }} spinning={leftLoading} > </Spin>
      <div style={{ display: 'flex', flexGrow: 1, width: '100%', position: 'relative' }}  >
        <LeftList getLeftListInfo={getLeftListInfo} closebottom={closebottom} props={{setTrailBtnF:setTrailBtn}}/>
        <div className={styles.chart} ref={chartRefGetter} />
        <RightList getRightListInfo={getRightListInfo} closebottom={closebottom} props={{ showSeries, mapPoints, flage, sureGetCar, finishe, singleFlag,countryPointF:countryPoint,setTrailBtnF:setTrailBtn }} />
      </div>
      {
        trailBtn.visible ?
          <div className={styles.radio}>
            <Radio.Group defaultValue={hour} buttonStyle="solid" onChange={(e) => { setHour(e.target.value); setSingleSure(true) }}>
              <Radio.Button value="1">1小</Radio.Button>
              <Radio.Button value="2">2小</Radio.Button>
              <Radio.Button value="3">3小 </Radio.Button>
              <Radio.Button value="12">12小 </Radio.Button>
              <Radio.Button value="24">24小 </Radio.Button>
            </Radio.Group>
          </div> :
          null
      }
      <Drawer title={false} placement="bottom" closable={true} mask={false} height={55} bodyStyle={{ padding: 10 }} maskClosable={false}
        onClose={closebottom} visible={trailBtn.visible}>
        <div className={styles.Drawer}>
          <div style={{ display: 'flex' }}>
            <div className={styles.btns}>
              <Button onClick={() => { setTrailBtn({ ...trailBtn, play_begin: !trailBtn.play_begin }) }} className={`${styles.play_begin} ${trailBtn.play_begin ? styles.active : ''} `} type="dashed" title={trailBtn.play_begin ? '停止' : '播放'} />
              <Button onClick={() => { setTrailBtn({ ...trailBtn, movePos: 0, play_restart: !trailBtn.play_restart, play_begin: trailBtn.play_begin }) }} className={`${styles.play_restart} ${trailBtn.play_restart ? styles.active : ''}`} type="dashed" />
            </div>
            <div className={styles.control}>
              <Radio.Group value={trailBtn.moveSpeed} buttonStyle="solid" onChange={(e) => { setTrailBtn({ ...trailBtn, moveSpeed: e.target.value }) }}>
                <Radio.Button value="800">0.5倍</Radio.Button>
                <Radio.Button value="400">1倍</Radio.Button>
                <Radio.Button value="200">2倍</Radio.Button>
                <Radio.Button value="100">4倍</Radio.Button>
                <Radio.Button value="50">8倍</Radio.Button>
              </Radio.Group>
              <Slider min={0} max={trailBtn.slider.length - 1} value={trailBtn.playIndex} tipFormatter={(v) => { return trailBtn.slider[v] }} onChange={e => setTrailBtn({ ...trailBtn, playIndex: e })} onAfterChange={(e) => setTrailBtn({ ...trailBtn, movePos: e })} />
            </div>
          </div>
          <div style={{ marginRight: 40, display: 'flex', alignItems: 'center' }}>
            <Switch defaultChecked={rectify} checkedChildren={'轨迹纠偏'} unCheckedChildren={'未纠偏轨迹'} onChange={e => { setRectify(e) }}> </Switch>
            <Switch defaultChecked={show_points} checkedChildren={'原始轨迹点'} unCheckedChildren={'原始轨迹点'} onChange={e => { setShowPoints(e) }}> </Switch>
            <Switch defaultChecked={show_stops} checkedChildren={'停靠点'} unCheckedChildren={'停靠点'} onChange={e => { setShowStops(e) }}> </Switch>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}><span style={{ backgroundColor: '#EB3873', display: 'inline-block', width: 20, height: 2, marginRight: 5 }}>{}</span><span>规划线路</span></div>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}><span style={{ backgroundColor: '#46bee9', display: 'inline-block', width: 20, height: 2, marginRight: 5 }}>{}</span><span>纠偏轨迹</span></div>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}><span style={{ backgroundColor: '#03d5a0', display: 'inline-block', width: 20, height: 2, marginRight: 5 }}>{}</span><span>未纠偏轨迹</span></div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}
