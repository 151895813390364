// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2RPAYIeahwT-gn0DMLE_-B {\n  border: 1px solid #e6e6e6;\n  padding: 5px;\n}\n._2RPAYIeahwT-gn0DMLE_-B > div {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n}\n\n._36E1qo8QnaHG1uyec_Me8E {\n  width: 2px;\n  background-color: #1491FB;\n  height: 72px;\n}\n\n._2Aep0FgXUUN84fg4JUchR3 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-shrink: 0;\n  flex-grow: 0;\n  background-color: #1491FB;\n  color: #fff;\n  width: 20px;\n  height: 20px;\n  border-radius: 20px;\n  font-size: 14px;\n}\n\n._1ENRV8K0J3G5J9I-m0NbMi {\n  opacity: 0;\n}\n\n._2QT38MqOm9hy5s0DMiwkbF {\n  width: 0;\n  height: 0;\n  border-bottom: 15px solid #fff;\n  border-right: 15px solid transparent;\n  z-index: 1;\n  transform: rotate(45deg);\n  box-shadow: -2px 2px 2px 0 rgba(150, 150, 150, 0.1);\n  margin-right: -8px;\n  margin-top: 74px;\n}\n\n._3edyIAohALifGdSS42T4_9 {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  position: relative;\n  background-color: #fff;\n  margin: 20px 0;\n  border-radius: 10px;\n  box-shadow: 0 0 6px 2px rgba(100, 100, 100, 0.1);\n}\n\n._3_rsqneJrzuGY9qGW9rwdb {\n  display: flex;\n  align-items: center;\n  color: #fff;\n  border-radius: 10px 10px 0 0;\n  padding: 10px 20px;\n}\n\n.FMPKRDh3NePjyeKx9Bk9c {\n  display: flex;\n  margin: 0 15px;\n  padding: 10px 0;\n  border-bottom: 1px solid #F4F4F4;\n  box-sizing: border-box;\n  justify-content: space-between;\n}\n.FMPKRDh3NePjyeKx9Bk9c :last-child {\n  border-bottom: none;\n}\n\n._1mMnMlJHBpG6Z49qjxioLr {\n  min-width: 80px;\n  color: #B3B3B3;\n}\n\n._16scfDbD-YF-fzNqyQflM6 {\n  width: 30px;\n  height: 40px;\n  cursor: pointer;\n  margin-left: 8px;\n}\n\n._2c_qcNI_tOELXlDLkJOHZI {\n  cursor: pointer;\n  margin-left: 8px;\n  font-size: 14px !important;\n  padding: 6px 23px;\n  color: #fff !important;\n}", ""]);
// Exports
exports.locals = {
	"base": "_2RPAYIeahwT-gn0DMLE_-B",
	"crossLine": "_36E1qo8QnaHG1uyec_Me8E",
	"number": "_2Aep0FgXUUN84fg4JUchR3",
	"opacity": "_1ENRV8K0J3G5J9I-m0NbMi",
	"triangleLeft": "_2QT38MqOm9hy5s0DMiwkbF",
	"row": "_3edyIAohALifGdSS42T4_9",
	"itemTop": "_3_rsqneJrzuGY9qGW9rwdb",
	"itemBottom": "FMPKRDh3NePjyeKx9Bk9c",
	"itemLeft": "_1mMnMlJHBpG6Z49qjxioLr",
	"imgs": "_16scfDbD-YF-fzNqyQflM6",
	"showFile": "_2c_qcNI_tOELXlDLkJOHZI"
};
module.exports = exports;
