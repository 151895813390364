import React, {useState, useRef, useEffect, useCallback} from 'react';
import {Button, Modal} from 'antd';

export default function SatelliteMap({history, match, location}) {
  const lat = location.state.lat;
  const lng = location.state.lng;

  const back = () => history.goBack();
  const [map, initMap] = useState(null);
  const refGetter = useRef(null);
  useEffect(() => {
    const satellite = new AMap.TileLayer.Satellite();
    setTimeout(function () {
      initMap(new window.AMap.Map(refGetter.current, {layers: [satellite]}), {mapStyle: 'amap://styles/macaron'});
    }, 100)
  }, []);

  const marker = new window.AMap.Marker({
    position: new window.AMap.LngLat(lng, lat),
    animation: 'AMAP_ANIMATION_BOUNCE',
    label: {
      content: name,
      direction: 'center',
      offset: new window.AMap.Pixel(0, -35),
    },
  });

  if (map) {
    map.add(marker);
    map.setFitView([marker], false, [0, 0, 0, 0], 14);
  }

  return (
    <Modal
      visible
      width={1000}
      bodyStyle={{ padding: 0 }}
      closable={false}
      onCancel={back}
      footer={<Button onClick={back}>关闭</Button>}
    >
      <div ref={refGetter} style={{ width: '100%', height: 600 }}> </div>
    </Modal>
  );
}
