import React, {useState, useEffect, useRef} from 'react';
import styles from './Deal.scss';
import { previewable } from 'components/Previewer';
import Uploader from '../Uploader';
import {Modal, Table, Input, message} from 'antd';
import {UseInterval, CountTime} from './Preview';
import request from "utils/request";
import { updateBadge } from 'actions/user';
import { dataSource } from './List';
import {ShowFile, typeArr} from "../share";

const statusArr = ['', '已确认', '申诉中', '申诉未通过', '申诉通过'];
const statusColor = ['', '#AAAAAA', '#F59A23', '#D9001B', '#49D966'];

const columns = [
  { title: '序号', dataIndex: 'serial' },
  { title: '处罚', dataIndex: 'deal_result', render: (v) => (<span style={{ color: '#D9001B' }}>{v === 1 ? '进入灰名单' : '进入黑名单'}</span>) },
  { title: '状态', dataIndex: 'status', render: (v) => (<span style={{ color: statusColor[v] }}>{statusArr[v]}</span>) },
  { title: '车牌', dataIndex: 'car_number' },
  { title: '是否温江目录', dataIndex: 'is_car_directory', render: (v) => (<span>{ (v === 1 || v === '1') ? '是' : '否'}</span>) },
  { title: '关联驾驶员', dataIndex: 'driver_name' },
  { title: '事由', dataIndex: 'reason', width: 300, render: (v) => typeArr[v] },
  { title: '备注', dataIndex: 'remark', width: 300 },
];

function dealVerifyBillZx(id){
  const [data, setData] = useState([]);
  const [info, setInfo] = useState({});
  useEffect(() => {
    request('/web/gb_list/dealVerifyBillZx', { bill_id: id })
      .then(body => body.data.data)
      .then(data => {
        setData(data.cars.map((v, i) => ({ serial: (i + 1), ...v})));
        setInfo(data.info);
      })
  }, [id]);
  return [data, info]
}

function Verify({ path, match, history, preview }) {
  const { params: {id} } = match;
  const [data, info] = dealVerifyBillZx(id);
  const splitTime = 24;
  const startTime = info && info.time;
  const [remainingTime, setRemainingTime] = useState(CountTime(startTime, splitTime));
  const [selectedRow, setSelectedRow] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [reason, setReason] = useState('');

  const back = () => history.goBack();

  UseInterval(() => {
    setRemainingTime(CountTime(startTime, splitTime))
  }, 1000);

  const onSuccess = () => {
    const json = {
      bill_id: id,
      bill_car_ids: selectedRow,
      images: fileList,
      reason: reason
    };

    request('/web/gb_list/commitVerifyBillZx', {...json})
      .then(data => {
        if(data.data.code === 200){
          message.success('审核成功');
          dataSource.refresh();
          updateBadge()
          back();
        }else{
          message.warn(data.data.msg)
        }
      })
  };

  const onSelectChange = (rows) => {
    setSelectedRow(rows)
  };

  const rowSelection = {
    selectedRow,
    onChange: onSelectChange,
    selections: [],
    getCheckboxProps: record => ({disabled: record.status === 1})
  };
  const AppealDeal = () => {
    const flag = selectedRow.length;
    return (
      <div className={styles.check}>
        <span className={flag && styles.active}>{flag ? '驳回已勾选' : '驳回请勾选'}</span>
        <label>{ flag ? '驳回后，将自动进入灰名单' : '对申诉有异议，您可以进行驳回'}</label>
      </div>
    )
  };
  const disabled = selectedRow.length === 0 ? false : (fileList === '' || reason === '');

  return (
    <Modal
      title={'车辆违规事由审核'}
      visible={true}
      destroyOnClose={true}
      maskClosable={false}
      width={1600}
      bodyStyle={{ height: '70vh', overflow: 'auto'}}
      okText={'提交'}
      onCancel={back}
      onOk={onSuccess}
      okButtonProps={{disabled}}
    >
      <div className={styles.tipPart}>
        <p>温馨提醒：据您审核时间还有<span>{remainingTime}</span></p>
        <div>
          <span>申诉时间：{info && info.time}</span>
          <span>申诉人员：{info && info.operator ? info.operator : '系统默认'}</span>
        </div>
      </div>
      <Table rowSelection={rowSelection} columns={columns} dataSource={data} rowKey={'id'} scroll={{ y: 280 }} pagination={false} />
      <AppealDeal />
      {
        selectedRow.length > 0 && (
          <div className={styles.evidenceCheck}>
            <div>
              <span>驳回证据文件<label>(最多上传5条)</label></span>
              <div>
                <span>请上传证据文件：</span>
                <Uploader value={fileList} maxUploadLength={5} onChange={(e) => setFileList(e)} />
              </div>
            </div>
            <div>
              <span>驳回理由</span>
              <div>
                <Input.TextArea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="请输入驳回理由"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </div>
            </div>
          </div>
        )
      }
      {
        info &&
        <div className={styles.evidenceAppeal}>
          <div>
            <span>证据文件</span>
            <div style={{ height: 60}}>
              <span>
                 {
                   info.images && info.images.split(',').map((item, i) => ((item.indexOf('.pdf') > -1 || item.indexOf('.doc') > -1 || item.indexOf('.xlsx') > -1) ? <span className={styles.showFile} onClick={() => ShowFile(item)}>查看文件</span> : <img key={i} className={styles.imgs} src={item}  onClick={() => preview.image(item)} alt=""/>))
                 }
              </span>
            </div>
          </div>
          <div>
            <span>驳回理由</span>
            <div style={{ height: 60}}>{info.reason}</div>
          </div>
        </div>
      }
    </Modal>
  )
}
export default previewable(Verify)
