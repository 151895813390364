
import React, { useState, useEffect } from 'react';
import { Modal, Form, message, Input, Checkbox, Button } from 'antd';
import request from 'utils/request';
import styles from '../Query.scss';
import classNames from 'classnames';
export function EleVolume({ start_id, end_id, plate_num, onChange }) {
  const [form_data, setFormData] = useState({});

  useEffect(() => {
    if (!start_id || !end_id || !plate_num) return;
    request('/web/human_ticket/detailByElectricTicketAdd', { start_id, end_id, plate_num })
      .then((res) => res.data)
      .then((res) => {
        const data = res.data;
        // onChange(data.quantities_check_status)
        setFormData(data ? data : {});
      });
  }, [start_id, end_id, plate_num]);
  
  return (
    <div className={styles.volumeTotal}>
      <div className={styles.volumes}>
        <span>货箱方量</span>
        <Input value={!form_data.car_directory_detail ? '-' : `${form_data.car_directory_detail.volumn}方`} precision={2} disabled={true} style={{width: 150}}/>
      </div>
      <div className={styles.volumes}>
        <span>货箱高度差</span>
        <Input disabled value={form_data.check_quantities ? `${form_data.check_quantities.hight_diff}m` : '-'} style={{width: 150}}/>
      </div>
      <div className={styles.volumes}>
        <span>货箱尺寸</span>
        <Input disabled value={(!form_data.car_directory_detail || !form_data.check_quantities) ? '-' : `${form_data.car_directory_detail.inner_long} x ${form_data.car_directory_detail.inner_width} x ${form_data.car_directory_detail.inner_height - (!form_data.check_quantities ? 0 : form_data.check_quantities.hight_diff)}m`} style={{width: 150}}/>
      </div>
      <div className={styles.volumes}>
        <span>量方方量</span>
        <Input disabled value={form_data.check_quantities ? `${form_data.check_quantities.quantities}方` : '未量方'} style={{width: 150, color: !form_data.check_quantities ? 'red' : ''}}/>
      </div>
      <div className={styles.volumes}>
        <span>扣方方量</span>
        <Input disabled value={form_data.minus_quantities ? `${form_data.minus_quantities.minus_quantities}方` : '-'} style={{width: 150}}/>
      </div>
      <div className={styles.volumes}>
        <span>实收方量</span>
        <Input disabled value={form_data.minus_quantities ? `${form_data.minus_quantities.quantities}方` : '-' } style={{width:150}}/>
      </div>
    </div>
  );
}

export function ElectronStripCom({ start_id, end_id, plate_num, onChange, history, path, extraParams }) {
  const [form_data, setFormData] = useState({});
  useEffect(() => {
    if (!start_id || !end_id || !plate_num) return;
    request('/web/human_ticket/detailByElectricTicketAdd', { start_id, end_id, plate_num })
      .then((res) => res.data)
      .then((res) => {
        const data = res.data;
        onChange(data ? data.id : 0, data.quantities_check_status);
        setFormData(data ? data : {});
      });
  }, [start_id, end_id, plate_num]);

  const getImgSrc = (src) => {
    if (!src) return;
    return src.includes('http') ? src : (URLS.FILES + src);
  }
  const [ht_visible, setHtVisible] = useState(false);

  return (
    <div>
      <div className={classNames(styles.eleStrip, {[styles.none]: !form_data.id})} onClick={() => form_data.id && history.push({ pathname: `create/${form_data.id}/ele-detail`, state: { ...extraParams }})}>
        <div className={styles.tips}>电子出门条</div>
        {form_data.id ? (
          <div className={styles.boxs}>
            <img src={getImgSrc(form_data.load_img)} />
            <div className={styles.desc}>
              <span>{`物料类型：${form_data.material_name}`}</span>
              <span>{`上传时间：${form_data.created_time}`}</span>
              <span title={`工地名称：${form_data.start_name}`}>{`工地名称：${form_data.start_name}`}</span>
            </div>
        </div>
        ) : <div>暂无电子出门条</div>}
      </div>
      {(form_data.status === 3) ? (
        <div className={styles.htStatus} onClick={() => setHtVisible(form_data.exception_logs ? form_data.exception_logs[form_data.exception_logs.length - 1] : {})}>
          {`该车辆于${form_data.exception_logs ? form_data.exception_logs[form_data.exception_logs.length - 1].created_time : ''}因`}<div className={styles.reason}>{form_data.exception_logs ? form_data.exception_logs[form_data.exception_logs.length - 1].reason_text : ''}</div>{`操作了“异常处理”`}
        </div>
      ) : null}
      {ht_visible && <ExceptionLogs1 detailInfo={ht_visible} onClose={() => setHtVisible(false)} />}
    </div>
  );
}