import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';

import {message} from 'antd';
import {dataSource} from './List';
const inputs = [
  { label: '运企名称', placeholder: '请输入运企名称', key: 'shipping_enterprise_name', required: true },
  { label: '法人', placeholder: '请输入法人', key: 'as_a_legal_person', required: true },
  { label: '联系电话', placeholder: '请输入联系电话', key: 'contact_phone_number', required: true },
  { label: '具体地址', placeholder: '请输入具体地址', key: 'the_specific_address', required: true },
  { label: '报备要件', key: 'files', type: 'upload-dragger', required: true, },
  { label: '备注', placeholder: '请输入备注', key: 'note', type: 'textarea', autoSize: { minRows: 4, maxRows: 10 }},
];
export default function ListReport({ history, match }) {
  const back = () => history.goBack();
  function onFinish(){
    dataSource.refresh();
    history.goBack();
    message.success('新增成功');
  }
  return (
    <ModalFormPage
      title="信息变更"
      submit={['/web/keep_on_record/transportAddRecord']}
      init={['/web/keep_on_record/transportInfo']}
      inputs={inputs}
      onCancel={back}
      onFinish={ onFinish }
      modalProps={{
        width: 800,
      }}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}

    />
  );
}
