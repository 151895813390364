/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Button, Input, Icon, PageHeader, message, Checkbox} from 'antd';
import immutable from 'immutable';
import request, { open } from 'utils/request';
import Uploader from 'components/Uploader';

import Text from './FormItem/Text';
import Selector from './FormItem/Selector';
import RangePicker from './FormItem/RangePicker';
import DatePicker from './FormItem/DatePicker';
import RadioSelect from './FormItem/RadioSelect';
import TimeRange from './FormItem/TimeRange';
import useImmutable from './useImmutable';
import store from 'stores/user';
import styles from './Query.scss';
import OnlineRange from "ui/FormItem/OnlineRangePicker";

// 将文件资源地址传给API
async function uploadSrcToApi(api, src) {
  const { code, msg } = await request(api, { file: src }).then(body => body.data);
  const succeed = code === 200;
  if (!succeed) {
    const err = typeof msg === 'string' ? msg : '上传出错';
    message.warning(err);
  }
  return succeed;
}

// 立即上传组件
function StraightUploader({ api, onFinish, ...rest }) {
  const [fileList, setFileList] = useState([]);

  function onFileListChange(fileList) {
    const [src] = fileList.filter(({ status }) => status === 'done').map(({ response }) => response.src);
    if (src) {
      setFileList([]);
      uploadSrcToApi(api, src).then(succeed => succeed && onFinish());
    } else {
      setFileList(fileList);
    }
  }

  return <Uploader fileList={fileList} showUploadList={false} onChange={onFileListChange} {...rest} />;
}

export default function Query({ querys, buttons, radioSelect, setQuery, getQuery, defaultQuery, backTitle, onBack, lineFeed, rate }) {
  const { get, set, setter, toJS } = useImmutable(immutable.Map(defaultQuery));
  const [useRadio, setUseRadio] = useState(false);
  let ratio = 0;
  if (rate !== undefined && rate !== null && rate !== 0) {
    ratio = (rate * 100).toFixed(1)+'%';
  }

  const search = () => {setQuery(toJS()); setUseRadio(!useRadio)};

  const buttonStyle = { type: 'default', size: 'default' };
  function renderButton({ title, icon, back, link, upload, download, extraParams = {}, extraNotice, element, ...rest }, index) {
    if (element) return element;
    if (link) return <><Button key={index} {...buttonStyle} { ...rest }><Link to={link}>{icon && <Icon type={icon} />}{title}</Link></Button><div style={{ fontWeight: 'bold', color: '#ff0000' }}>{extraNotice}</div></>;
    if (download) return <Button key={index} {...buttonStyle} onClick={() => open(download, { ...extraParams, ...getQuery() })}><Icon type="download" />{title}</Button>;
    if (upload) return <StraightUploader key={index} api={upload} onFinish={() => setQuery()} {...rest}><Button {...buttonStyle}><Icon type="upload" />{title}</Button></StraightUploader>;
    return null;
  }
  function renderQuery({ title, key, type, rely, relyAs, extraData, ...rest }) {
    const inputProps = { key, placeholder: title, allowClear: true, size: 'default' };
    switch (type) {
      case 'date-range': {
        const { format = 'YYYY-MM-DD HH:mm:ss', ...props } = rest;
        return <RangePicker {...inputProps} {...props} format={format} value={get(key)} onChange={setter(key)} />;
      }
      case 'date': {
        const { format = 'YYYY-MM-DD HH:mm:ss', ...props } = rest;
        return <DatePicker {...inputProps} {...props} format={format} value={get(key)} onChange={setter(key)} />;
      }
      case 'radioSelect': {
        return <RadioSelect {...inputProps} {...rest} area_id={store.getState().info.area_id} filter={toJS()} useRadio={useRadio} value={get(key)} onChange={setter(key)} />
      }
      case 'select': {
        let disabled = false;
        let { options } = rest;
        if (rely) {
          const relyValue = get(rely);
          // console.log(relyValue)
          // 如果依赖重置，自己也重置
          if (relyValue === undefined && get(key) !== undefined) {
            set(key, undefined);
          }
          disabled = !relyValue;
          if (typeof options === 'string') {
            const dataKey = relyAs || rely;
            const data = { [dataKey]: relyValue };
            options = { url: options, data };
          }
        }
        if(extraData){
          options = { url: options, data: extraData };
        }
        return <Selector {...inputProps} disabled={disabled} {...rest} options={options} value={get(key)} onChange={setter(key)} />;
      }
      case 'time-range': {
        const { format = 'YYYY-MM-DD HH:mm:ss', ...props } = rest;
        return <TimeRange {...inputProps} {...props} format={format} value={get(key)} onChange={setter(key)} />;
      }
      case 'checkbox': return <Checkbox className={styles.check} {...inputProps} checked={get(key)} onChange={e => set(key, e.target.checked ? 1 : 0)}>{title}</Checkbox>;
      case 'online-range': {
        const { format = 'YYYY-MM-DD HH:mm:ss', ...props } = rest;
        return <OnlineRange {...inputProps} {...props} format={format} value={get(key)} onChange={setter(key)} />;
      }
      case 'empty-instance': return null;
      default: return <Text {...inputProps} {...rest} value={get(key)} onChange={setter(key)} onPressEnter={search} />;
    }
  }

  return (
    <div className={styles.container} style={{flexDirection: lineFeed ? 'column' : 'row'}}>
      {onBack && <PageHeader className={styles.back} title={backTitle || '返回'} onBack={onBack} />}
      <div className={styles.buttons}>
        {buttons && buttons.map(renderButton)}
        {rate!==undefined && rate!==null && <div>车辆平均GPS在线率：{ratio}</div>}
      </div>
      {querys && (
        <Input.Group className={styles.querys} style={{ height: 32 }} compact>
          {querys.map(renderQuery)}
          <Button {...buttonStyle} type="primary" onClick={search}><Icon type="search" /></Button>
        </Input.Group>
      )}
    </div>
  );
}
