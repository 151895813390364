// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._14jMDP9gmQPlJPzdaBKUkU {\n  width: 100%;\n}\n._14jMDP9gmQPlJPzdaBKUkU ._9oS1FwElN5a6EE537qaVo {\n  z-index: 9;\n  position: sticky;\n  top: -1px;\n  border-top: 1px solid #d6d6d6;\n  border-bottom: 1px solid #d6d6d6;\n  background: rgba(250, 250, 250, 0.9);\n  background: white;\n}\n._14jMDP9gmQPlJPzdaBKUkU ._9oS1FwElN5a6EE537qaVo ._1E27Xq2BqM2V9i16a7Ua_3 {\n  height: 52px;\n  margin: auto;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n._14jMDP9gmQPlJPzdaBKUkU ._9oS1FwElN5a6EE537qaVo ._1E27Xq2BqM2V9i16a7Ua_3 .nmTBPfhhvyAjmjffFBpWP {\n  font-size: 18px;\n  font-weight: 600;\n  color: #111;\n}\n._14jMDP9gmQPlJPzdaBKUkU ._9oS1FwElN5a6EE537qaVo ._1E27Xq2BqM2V9i16a7Ua_3 ._2aI-HQLLJvQIuIlw0yPPAr {\n  flex: 1;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n._14jMDP9gmQPlJPzdaBKUkU ._3e_fP8Ggpw1kqBYOX20tfJ {\n  margin: auto;\n  color: #111;\n}\n._14jMDP9gmQPlJPzdaBKUkU ._3e_fP8Ggpw1kqBYOX20tfJ ._23O5t-hpYHUpz3WNUtFKaD {\n  padding: 36px 0 22px 0;\n  display: flex;\n  flex-direction: row;\n}\n._14jMDP9gmQPlJPzdaBKUkU ._3e_fP8Ggpw1kqBYOX20tfJ ._23O5t-hpYHUpz3WNUtFKaD:not(:last-child) {\n  border-bottom: 1px solid #d6d6d6;\n}\n._14jMDP9gmQPlJPzdaBKUkU ._3e_fP8Ggpw1kqBYOX20tfJ ._23O5t-hpYHUpz3WNUtFKaD .nmTBPfhhvyAjmjffFBpWP {\n  width: 240px;\n  font-size: 18px;\n  font-weight: 600;\n}\n._14jMDP9gmQPlJPzdaBKUkU ._3e_fP8Ggpw1kqBYOX20tfJ ._23O5t-hpYHUpz3WNUtFKaD ._1E27Xq2BqM2V9i16a7Ua_3 {\n  flex: 1;\n  display: flex;\n  flex-direction: row;\n}\n._14jMDP9gmQPlJPzdaBKUkU ._3e_fP8Ggpw1kqBYOX20tfJ ._23O5t-hpYHUpz3WNUtFKaD ._1E27Xq2BqM2V9i16a7Ua_3 div, ._14jMDP9gmQPlJPzdaBKUkU ._3e_fP8Ggpw1kqBYOX20tfJ ._23O5t-hpYHUpz3WNUtFKaD ._1E27Xq2BqM2V9i16a7Ua_3 p {\n  flex: 1;\n}\n._14jMDP9gmQPlJPzdaBKUkU ._3e_fP8Ggpw1kqBYOX20tfJ ._23O5t-hpYHUpz3WNUtFKaD ._1E27Xq2BqM2V9i16a7Ua_3 div:not(:last-child) {\n  margin-right: 36px;\n}", ""]);
// Exports
exports.locals = {
	"section": "_14jMDP9gmQPlJPzdaBKUkU",
	"header": "_9oS1FwElN5a6EE537qaVo",
	"content": "_1E27Xq2BqM2V9i16a7Ua_3",
	"title": "nmTBPfhhvyAjmjffFBpWP",
	"button": "_2aI-HQLLJvQIuIlw0yPPAr",
	"body": "_3e_fP8Ggpw1kqBYOX20tfJ",
	"item": "_23O5t-hpYHUpz3WNUtFKaD"
};
module.exports = exports;
