import React, {useState, useEffect} from 'react';
import { Select, Button, DatePicker } from 'antd';
import request from 'utils/request';
import moment from 'moment';
import styles from './index.scss';
import Line from './Line';

const emissions = ['纳入监管', '计划监管', '疑似偷排'];
const transport = ['非名录车', '非合同车', '疑似超速', '路线偏离', '疑似违规倾倒', '篷布未密闭', '高峰期上路'];
const consume = ['非法消纳', '指定接管', '砂石场接管'];
const ticket = ['待核实', '待审核', '异常接管', '正常接管', '车载识别', '车牌识别', '已删除(无法核实的电子联单)'];
const abnormal = ['有起点无终点', '有终点无起点', '无GPS轨迹', '车牌识别错误'];

export default function TakeoverCenter() {
  const [query, setQuery] = useState({
    date: [moment().subtract(7, 'days').startOf('day'), moment().subtract(0, 'days').endOf('day')],
    building: undefined,
    sandFactory: undefined,
    consume: undefined
  });
  const [type, setType] = useState(1);
  const [buildArr, setBuildArr] = useState([]);
  const [consumeArr, setConsumeArr] = useState([]);
  const [factoryArr, setFactoryArr] = useState([]);

  const [emissionsData, setEmissions] = useState([]);
  const [transportData, setTransport] = useState([]);
  const [consumeData, setConsume] = useState([]);
  const [ticketData, setTicket] = useState([]);
  const [abnormalData, setAbnormal] = useState([]);

  useEffect(() => {
    request('/web/building/history').then(data => setBuildArr(data.data.data));
    request('/web/big_data/getConsume').then(data => setConsumeArr(data.data.data));
    request('/web/big_data/getSandFactory').then(res => setFactoryArr(res.data.data));
  }, []);
  useEffect(() => {
    const time = [query.date[0].format('YYYY-MM-DD'), query.date[1].format('YYYY-MM-DD')];
    request('/web/big_data/discharge', {time: time})
      .then(response => response.data.data)
      .then(result => setEmissions(result));

    request('/web/big_data/transport', {time: time})
      .then(response => response.data.data)
      .then(result => setTransport(result));

    request('/web/big_data/ticket', {time: time})
      .then(response => response.data.data)
      .then(result => setTicket(result));

    request('/web/big_data/exceptTicket', {time: time})
      .then(response => response.data.data)
      .then(result => setAbnormal(result));
  }, [query.date]);
  useEffect(() => {
    const time = [query.date[0].format('YYYY-MM-DD'), query.date[1].format('YYYY-MM-DD')];
    const params = {
      time: time,
      consume_id: query.consume,
      building_id: query.building,
      sand_id: query.sandFactory
    };
    request('/web/big_data/endPoint', params)
      .then(response => response.data.data)
      .then(result => setConsume(result));
  }, [query]);

  function renderSelect(index) {
    switch (index) {
      case 0:
        return <Select
          allowClear
          value={query.consume}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          style={{width: 150}}
          size="small"
          placeholder="请选择消纳场"
          onChange={value => setQuery({...query, consume: value})}
        >
          {consumeArr.map((v, i) => {
            return (
              <Select.Option key={i} value={v.id} title={v.name}>{v.name}</Select.Option>
            )
          })}
        </Select>;
      case 1:
        return <Select
          allowClear
          value={query.building}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          style={{width: 150}}
          size="small"
          placeholder="请选择工地"
          onChange={value => setQuery({...query, building: value})}
        >
          {buildArr.map((v, i) => {
            return (
              <Select.Option key={i} value={v.id} title={v.name}>{v.name}</Select.Option>
            )
          })}
        </Select>;
      case 2:
        return <Select
          allowClear
          value={query.sandFactory}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          style={{width: 150}}
          size="small"
          placeholder="请选择砂石场"
          onChange={value => setQuery({...query, sandFactory: value})}
        >
          {factoryArr.map((v, i) => {
            return (
              <Select.Option key={i} value={v.id} title={v.name}>{v.name}</Select.Option>
            )
          })}
        </Select>;
      default: break;
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.query}>
        <DatePicker.RangePicker
          allowClear={false}
          value={(query.date || []).map(item => (item ? moment(item, 'YYYY-MM-DD') : null))}
          onChange={(value) => setQuery({...query, date: value})}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.items}>
          <div style={{color: '#FC0064'}}>排放</div>
          <div>
          {
            emissions.map((item, i) => {
              return (
                <div className={styles.charts} key={i}>
                  <div>
                    <span style={{border: '#FC0064 2px solid'}}> </span>
                    <span style={{marginLeft: 10}}>{item}</span>
                  </div>
                  <div className={styles.line}>
                    <Line data={emissionsData[i]} type='emissions'/>
                  </div>
                </div>
              )
            })
          }
          </div>
        </div>
        <div className={styles.items}>
          <div style={{color: '#FE7F02'}}>运输</div>
          <div>
            {
              transport.map((item, i) => {
                return (
                  <div className={styles.charts} key={i}>
                    <div>
                      <span style={{border: '#FE7F02 2px solid'}}> </span>
                      <span style={{marginLeft: 10}}>{item}</span>
                    </div>
                    <div className={styles.line}>
                      <Line data={transportData[i]} type='transport'/>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className={styles.items}>
          <div style={{color: '#31C932'}}>消纳</div>
          <div>
            {
              consume.map((item, i) => {
                return (
                  <div className={styles.charts} key={i}>
                    <div className={styles.consume}>
                      <span style={{border: '#31C932 2px solid'}}> </span>
                      <span style={{marginLeft: 10}}>{item}</span>
                      <div style={{marginLeft: 170}}>
                        {renderSelect(i)}
                      </div>
                    </div>
                    <div className={styles.line}>
                      <Line data={consumeData[i]} type='consume'/>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className={styles.items}>
          <div className={styles.tickets}>
            <span style={{marginRight: 20}} className={type === 1 ? '' : `${styles.active}`} onClick={() => setType(1)}>电子联单</span>
            <span className={type === 2 ? '' : `${styles.active}`} onClick={() => setType(2)}>异常联单分析</span>
          </div>
          {
            type === 1 ?
            <div>
              {
                ticket.map((item, i) => {
                  return (
                    <div className={styles.charts} key={i}>
                      <div>
                        <span style={{border: '#00A4F2 2px solid'}}> </span>
                        <span style={{marginLeft: 10}}>{item}</span>
                      </div>
                      <div className={styles.line}>
                        <Line data={ticketData[i]} type='ticket'/>
                      </div>
                    </div>
                  )
                })
              }
            </div> :
            <div>
              {
                abnormal.map((item, i) => {
                  return (
                    <div className={styles.charts} key={i}>
                      <div>
                        <span style={{border: '#00A4F2 2px solid'}}> </span>
                        <span style={{marginLeft: 10}}>{item}</span>
                      </div>
                      <div className={styles.line}>
                        <Line data={abnormalData[i]} type='abnormal'/>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}
