import React, { useEffect, useState, useCallback } from 'react';
import { previewable } from 'components/Previewer';
import {Form, DatePicker, InputNumber, message, Input, Button, Icon, Modal, Select, Table} from 'antd';

import immutable from 'immutable';
import moment from 'moment';

import user from 'stores/user';

import Selector from 'ui/FormItem/Selector';
import Uploader from 'components/Uploader';

import request from 'utils/request';
import guid from 'utils/guid';
import store from 'stores/user';

import StepsEditor from 'components/Map/StepsEditor';
import Steps from './Steps';
import Actions from './Actions';

import useImmutable from '../../ReportSandstoneTakeoverRoutes/RouteEditor/useImmutable';
import transformers from '../../ReportSandstoneTakeoverRoutes/RouteEditor/transformers';
import utils from '../../ReportSandstoneTakeoverRoutes/RouteEditor/utils';
import styles from './RouteEditor.scss';
import classNames from 'classnames';

const selectorStyle = { style: { width: 330 } };
function useSet(initialSet) {
  const [set, setSet] = useState(initialSet);
  const add = useCallback((...keys) => {
    setSet((previous) => {
      return new Set([...previous, ...keys]);
    });
  });
  const remove = useCallback((...keys) => {
    setSet((previous) => {
      const next = new Set([...previous]);
      keys.forEach((key) => next.delete(key));
      return next;
    });
  });
  const toggle = useCallback((key) => {
    setSet((previous) => {
      const next = new Set([...previous]);
      if (next.has(key)) {
        next.delete(key);
      } else {
        next.add(key);
      }
      return next;
    });
  }, []);
  const clear = useCallback(() => {
    setSet((previous) => {
      const next = new Set([...previous]);
      next.clear();
      return next;
    });
  }, []);
  return [set, { add, remove, toggle, clear }];
}
function useMap(initialMap) {
  const [map, setMap] = useState(initialMap);
  const remove = useCallback((keys) => {
    setMap((previous) => {
      const next = new Map([...previous]);
      if (typeof keys === 'string' || typeof keys === 'number') {
        next.delete(keys);
        // [...keys].map((key) => next.delete(key))
      } else if (Array.isArray(keys)) {
        keys.forEach((key) => next.delete(key));
      }
      return next;
    });
  });
  const toggle = useCallback((key, value) => {
    setMap((previous) => {
      const next = new Map([...previous]);
      if (next.has(key)) {
        next.delete(key);
      } else {
        next.set(key, value);
      }
      return next;
    });
  }, []);
  const set = useCallback((key, value) => {
    setMap((previous) => {
      const next = new Map([...previous]);
      next.set(key, value);
      return next;
    });
  });
  return [map, { set, remove, toggle }];
}

//运输附件
const transformDocArr = [
  { name: '外调车辆服务合同（盖甲、乙双方鲜章）', src: [] },
  { name: '关于载货汽车运输线路备案报告', src: [] },
  { name: '运输线路隐患排查情况说明', src: [] },
  { name: '建筑垃圾处置运输合同或者转运协议扫描件（盖甲、乙双方鲜章）', src: [] },
  { name: '车队长详细资料', src: [] },
  { name: '交通安全承诺书', src: [] },
  { name: '保持车辆技术性能状况良好承诺书', src: [] },
  { name: '关于此次线路申报的承诺', src: [] },
];
//线路附件
const routesDocArr = [
  { name: '运输线路平面图', src: [] },
  { name: '项目起点照片', src: [] },
  { name: '项目终点照片', src: [] },
  { name: 'GPS线路运行轨迹截图', src: [] },
];

function RouteEditor({ history, match, preview }) {
  // 方案ID
  const id = match.params.id;
  const types = match.params.types;
  // 当前运企ID
  const selfTransportID = user.getState().info.user_instance[0].instance_id;
  // 当前进行到第几步
  const [currentStatus, setCurrentStatus] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  // 需要标记增删改的数据
  const [originalRoutes, setOriginals] = useState([]);
  // 公用数据
  const [[transportOptions, sourceOptions, materielOptions, planOptions], setOptions] = useState([[], [], [], [], immutable.OrderedMap()]);
  const [areaOptions, setAreas] = useState([]);
  const [startOptions, setStarts] = useState([]);  //起点
  // 数据结构
  const [steps, { get, set, sets, setter, update }] = useImmutable(() => {
    if (types === '1') {
      return (
        immutable.List([
          immutable.Map({
            title: '基本信息',
            plan: {},
            dates: [],
            types: types, //新增类型
            area: {},
          }),
          immutable.Map({
            title: '车辆信息',
            cars: immutable.Map(),
            partnerCarOptions: [],
          }),
          immutable.Map({
            title: '备案预览',
          }),
        ])
      )
    } else {
      return (
        immutable.List([
          immutable.Map({
            title: '基本信息',
            plan: {},
            dates: [],
            types: types, //新增类型
          }),
          immutable.Map({
            title: '车辆信息',
            cars: immutable.Map(),
            partnerCarOptions: [],
          }),
          immutable.Map({
            title: '申报附件',
          }),
          immutable.Map({
            title: '备案预览',
          }),
        ])
      )
    }
  });
  //外调运企
  const [visible, setVisible] = useState(false);
  const [transports, setTransports] = useState(new Map());
  const [transportIds, updateTransportIds] = useSet(new Set());
  const [activeTransportId, setActiveTransportId] = useState();
  const [cars, updateCars] = useMap(new Map());
  const [search, setSearch] = useState('');
  const [selectedCars, updateSelected] = useMap(new Map());
  //外调运企所属区县
  const [extraCarArea, setExtraCarArea] = useState();
  const [extraTransOptions, setExtraTransOptions] = useState([]);
  //申报附件
  const [transDocs, updateTransDocs] = useMap(new Map());
  const [routesDocs, updateRouteDocs] = useMap(new Map());
  const [bigMap, setBigMap] = useState(false);

  function addRoute() {
    update((steps) => {
      let insertIndex;
      if (types === '1') {
        insertIndex = steps.size - 2;
      }
      if (types === '2'){
        insertIndex = steps.size - 3;
      }
      if (insertIndex === 1) {
        return steps.insert(insertIndex, immutable.Map({
          title: '线路信息',
          key: guid(),
          materiel: {},
          out_volume: 0,
          in_volume: 0,
          end: {},
          path: [],
          distance: 0,
          endArea: {},
          endOptions: [],
        }));
      }
      return steps.insert(insertIndex, immutable.Map({
        title: '线路信息',
        key: guid(),
        materiel: get([1, 'materiel']),
        out_volume: get([1, 'out_volume']),
        in_volume: 0,
        end: {},
        path: [],
        distance: 0,
        endArea: {},
        endOptions: [],
      }));
    });
  }

  // 初始化数据
  useEffect(() => {
    request('/web/route/initPlanInfo')
      .then(res => res.data)
      .then(data => {
        if (data.code !== 200){
          message.warn(data.msg)
        } else {
          const materiels = data.materiel;
          const starts = data.startPoints;
          const transports = data.transports;
          const materielOptions = transformers.materiels(materiels);
          const sourceOptions = transformers.sources(starts);
          const transportOptions = transports;
          setOptions([transportOptions, sourceOptions, materielOptions, [], []]);
          // 车辆信息数据
          // setTransports(new Map(transports.map((item) => [item.id, {
          //   ...item,
          //   cars: new Map(item.cars.map((car) => [car.car_num, {
          //     ...car,
          //     drivers: new Map((car.drivers || []).map((driver) => [driver.id, driver])),
          //   }]))
          // }])));
          updateTransportIds.add(selfTransportID);
          updateSelected.set(selfTransportID, false);
          setActiveTransportId(() => {
            let selfId;
            transports.map((item) => {
              if (item.type === 1) {
                selfId = item.id
              }
            });
            return selfId;
          });
          //运输附件
          transformDocArr.map((item) => updateTransDocs.set(item.name, item.src));
          //线路附件
          routesDocArr.map((item) => updateRouteDocs.set(item.name, item.src));
          setCurrentStatus(1);
        }
      });
    request('/web/car_transport/getArea')
      .then((res) => res.data)
      .then((data) => setAreas(transformers.areas(data.data)));
  }, []);
  useEffect(() => {
    request('/web/route/initPlanInfo', {area_id: 26}).then((res) => res.data).then((data) => {
      const transports = data.transports;
      setExtraTransOptions(transports);
      // 车辆信息数据
      setTransports(new Map(transports.map((item) => [item.id, {
        ...item,
        cars: new Map(item.cars.map((car) => [car.car_num, {
          ...car,
          drivers: new Map((car.drivers || []).map((driver) => [driver.id, driver])),
        }]))
      }])));
    })
  }, []);

  useEffect(() => {
    if (currentStatus !== 1) return;
    if (!id) {
      addRoute();
    } else {
      request('/web/route/getSsPlanDetails', {plan_id: id})
        .then(res => res.data)
        .then(info => {
          const baseInfo = info.baseInfo;
          const routesInfo = info.routeInfo;
          const carInfo = info.carInfo;
          let nextSteps;
          if (types === '1') {
            // 运输路线
            nextSteps = routesInfo.reduce((final, route) => {
              return final.insert(final.size - 2, immutable.Map({
                id: route.id,
                title: '运输线路',
                key: guid(),
                materiel: utils.findInOptions(materielOptions, route.materiel_id),
                end: utils.findInOptions(sourceOptions, route.end_id),
                path: route.routeSteps,
                distance: route.startAndEnd.distance,
                in_volume: route.in_volume,
                out_volume: baseInfo.out_volume,
              }));
            }, steps);
          }
          if (types === '2') {
            nextSteps = info.routeInfo.reduce((final, route) => {
              //申报附件
              const docs = transformers.newDecodeDocs(baseInfo.content);
              docs.map(({name, src}) => {
                if (transDocs.has(name)) {
                  updateTransDocs.set(name, src);
                }
                if (routesDocs.has(name)) {
                  updateRouteDocs.set(name, src);
                }
              });
              return final.insert(final.size - 3, immutable.Map({
                id: route.id,
                title: '运输线路',
                key: guid(),
                materiel: utils.findInOptions(materielOptions, route.materiel_id),
                end: utils.findInOptions(sourceOptions, route.end_id),
                path: route.routeSteps,
                distance: route.startAndEnd.distance,
                in_volume: route.in_volume,
                out_volume: baseInfo.out_volume,
              }));
            }, steps);
          }

          //基本信息
          const dateArr = [moment(baseInfo.start_date), moment(baseInfo.end_date)];
          const source = sourceOptions.find(({ department, value }) => department === baseInfo.start_department_id && value === baseInfo.start_id);
          const plan = { plan: id, ...source };
          if (source) setOptions([transportOptions, sourceOptions, materielOptions, [...planOptions, plan]]);
          nextSteps = nextSteps.setIn([0, 'plan'], plan);
          nextSteps = nextSteps.setIn([0, 'dates'], dateArr);
          update(() => nextSteps);

          //车辆信息
          carInfo.map(({transport_id}) => updateTransportIds.add(transport_id));
          carInfo.map(({car_num, driver_id}) => updateCars.set(car_num, driver_id));
        })
    }
  }, [currentStatus]);

  // 滚动条重新置顶
  const contentBoxRef = useCallback((node) => {
    if (node) node.scrollTo(0, 0);
  }, [currentStep]);

  if (types === '1'){
    if (steps.size < 4) return null;
  }
  if (types === '2'){
    if (steps.size < 5) return null;
  }

  // 判断当前页面是否填完
  function isFinished() {
    if (currentStep === 0) {
      const plan = utils.isValidOption(get([0, 'plan']));
      const dates = utils.isValidDates(get([0, 'dates']));
      return plan && dates;
    }
    if (types === '1') {
      if (currentStep === steps.size - 2) {
        return cars && cars.size > 0;
      }

      const materiel = utils.isValidOption(get([1, 'materiel']));
      const end = utils.isValidOption(get([currentStep, 'end']));
      const distance = get([currentStep, 'distance']);
      const total = utils.isValidNumber(get([1, 'out_volume']));
      const receiveArr = steps.delete(0).delete(-1).delete(-1);
      const volumesin = receiveArr.reduce((sum, route) => (sum + (route.get('in_volume') || 0)), 0);
      const volumesok = get([1, 'out_volume']) >= volumesin;
      if (!volumesok) {
        message.warn('接收方量总和不能大于排放总量')
      }
      return materiel && end && distance && total && volumesok;
    }
    if (types === '2') {
      if (currentStep === steps.size - 3) {
        return cars && cars.size > 0;
      }
      if (currentStep === steps.size - 2) {
        return [...transDocs].every((item) => item[1].length > 0) && [...routesDocs].every((item) => item[1].length > 0);
      }

      const materiel = utils.isValidOption(get([1, 'materiel']));
      const end = utils.isValidOption(get([currentStep, 'end']));
      const distance = get([currentStep, 'distance']);
      const total = utils.isValidNumber(get([1, 'out_volume']));
      const receiveArr = steps.delete(0).delete(-1).delete(-1).delete(-1);
      const volumesin = receiveArr.reduce((sum, route) => (sum + (route.get('in_volume') || 0)), 0);
      const volumesok = get([1, 'out_volume']) >= volumesin;
      if (!volumesok) {
        message.warn('接收方量总和不能大于排放总量')
      }
      return materiel && end && distance && total && volumesok;
    }
  }

  const prevable = currentStep - 1 >= 0;
  const nextable = currentStep + 1 <= steps.size - 1;
  const finished = isFinished();
  // const finished = true;

  const onStepChange = nextStep => setCurrentStep(nextStep);
  const onRouteDelete = () => {
    update(steps => steps.delete(currentStep));
    setCurrentStep(currentStep === 1 ? currentStep : currentStep - 1);
  };
  const onRouteCreate = () => {
    addRoute();
    setCurrentStep(currentStep + 1);
  };
  const onCancel = () => history.goBack();
  const onSubmit = () => {
    if (!id) {
      const data = utils.newCreate(types, steps, transportIds, cars, transports, transDocs, routesDocs, '');
      request('/web/route/createSsPlan', {status: 1, ...data})
        .then(res => res.data)
        .then(data => {
          if (data.code!==200) {
            message.warn(data.msg);
          } else {
            onCancel();
          }
        });
    } else {
      const data = utils.newCreate(types, steps, transportIds, cars, transports, transDocs, routesDocs, id);
      request('/web/route/updateSsPlanInfo', {status: 1, ...data})
        .then(res => res.data)
        .then(data => {
          if (data.code!==200) {
            message.warn(data.msg);
          } else {
            onCancel();
          }
        });
    }
  };
  const onSubmitSure = () => {
    if (!id) {
      let selectStatus;
      switch (types) {
        case '1': selectStatus = 3; break;
        case '2': selectStatus = 2; break;
      }
      const data = utils.newCreate(types, steps, transportIds, cars, transports, transDocs, routesDocs, '');
      request('/web/route/createSsPlan', {status: selectStatus, ...data})
        .then(res => res.data)
        .then(data => {
          if (data.code!==200) {
            message.warn(data.msg);
          } else {
            onCancel();
          }
        });
    } else {
      let selectStatus;
      switch (types) {
        case '1': selectStatus = 3; break;
        case '2': selectStatus = 2; break;
      }
      const data = utils.newCreate(types, steps, transportIds, cars, transports, transDocs, routesDocs, id);
      request('/web/route/updateSsPlanInfo', {status: selectStatus, ...data})
        .then(res => res.data)
        .then(data => {
          if (data.code!==200) {
            message.warn(data.msg);
          } else {
            onCancel();
          }
        });
    }
  };

  function startAreaChange(option) {
    const area_id = option && option.value;
    sets([[0, 'area'], option], [[0, 'plan'], {}]);
    request('/web/route/initPlanInfo', {area_id})
      .then((res) => res.data)
      .then((data) => setStarts(transformers.sources(data.startPoints)));
  }

  // 基本信息
  function renderBase() {
    return (
      <Form>
        {
          types === '1' ?
          (
            <>
              <Form.Item label="区(市)县" required>
                <Selector showSearch allowClear={false} options={areaOptions} {...selectorStyle} optionValue value={get([0, 'area'])} onChange={(value) => startAreaChange(value)} />
              </Form.Item>
              <Form.Item label="运输起点名称" required>
                <Selector showSearch options={startOptions} {...selectorStyle} optionValue value={get([0, 'plan'])} onChange={setter([0, 'plan'], {})} />
              </Form.Item>
            </>
          ) :
          (
            <Form.Item label="运输起点名称" required>
              <Selector showSearch options={sourceOptions} {...selectorStyle} optionValue value={get([0, 'plan'])} onChange={setter([0, 'plan'], {})} />
            </Form.Item>
          )
        }
        <Form.Item label="申报运输时段" required>
          <DatePicker.RangePicker value={get([0, 'dates'])} onChange={setter([0, 'dates'])} format="YYYY-MM-DD" allowClear={false} />
        </Form.Item>
      </Form>
    );
  }

  // 路线信息
  function renderMateriel(i) {
    if (i > 1) {
      return (<Selector options={materielOptions} {...selectorStyle} optionValue value={get([1, 'materiel'])} disabled />);
    } else {
      return (<Selector options={materielOptions} {...selectorStyle} optionValue value={get([1, 'materiel'])} onChange={setter([1, 'materiel'], {})} />);
    }
  }
  function renderTotal(i) {
    if (i > 1) {
      return (<InputNumber min={0} max={999999} precision={1} value={get([1, 'out_volume'])} disabled />);
    } else {
      return (<InputNumber min={0} max={999999} precision={1} value={get([i, 'out_volume'])} onChange={setter([i, 'out_volume'], 0)} />);
    }
  }
  //运输距离
  function getDistance(distance) {
    return (Math.round(distance/100)/10).toFixed(1) + '公里';
  }
  //途经点
  function endAreaChange(option, i) {
    const area_id = option && option.value;
    // sets([[i, 'endArea'], option], [[i, 'endOptions'], []], [[i, 'end'], {}]);
    request('/web/route/initPlanInfo', {area_id})
      .then((res) => res.data)
      .then((data) => {
        sets([[i, 'endOptions'], transformers.sources(data.startPoints)], [[i, 'endArea'], option], [[i, 'end'], {}]);
      });
  }
  function getRoutes(points){
    if (!points) return null;
    return points.map(point => point.address).filter(v => v).join(' -> ')
  }
  function renderRoute(i) {
    const startName = get([0, 'plan']).label;
    const start = get([0, 'plan']);
    const end = get([i, 'end']);

    return (
      <div key={get([i, 'key'])} className={styles.route}>
        <Form>
          <Form.Item label="运输起点">
            <div className={styles.startName}>{startName}</div>
          </Form.Item>
          <Form.Item label="运输品类" required>
            {renderMateriel(i)}
          </Form.Item>
          <Form.Item label="排放总量(方)" required>
            {renderTotal(i)}
          </Form.Item>
          {
            types === '1' ?
            (<>
              <Form.Item label="区(市)县" required>
                <Selector showSearch allowClear={false} options={areaOptions} {...selectorStyle} optionValue value={get([i, 'endArea'])} onChange={(value) => endAreaChange(value, i)} />
              </Form.Item>
              <Form.Item label="运输终点名称" required>
                <Selector options={get([i, 'endOptions'])} {...selectorStyle} optionValue value={get([i, 'end'])} onChange={end => sets([[i, 'end'], end], [[i, 'path'], []], [[i, 'distance'], 0])} showSearch />
              </Form.Item>
            </>) :
            (
              <Form.Item label="运输终点名称" required>
                <Selector options={sourceOptions} {...selectorStyle} optionValue value={get([i, 'end'])} onChange={end => sets([[i, 'end'], end], [[i, 'path'], []], [[i, 'distance'], 0])} showSearch />
              </Form.Item>
            )
          }
          <Form.Item label="接收方量(方)">
            <InputNumber min={0} max={999999} precision={1} value={get([i, 'in_volume'])} onChange={setter([i, 'in_volume'], 0)} />
          </Form.Item>
          <Form.Item label="运输距离">
            <div>{getDistance(get([i, 'distance']))}</div>
          </Form.Item>
          <Form.Item label="途径点">
            <div className={styles.points}>
              {getRoutes(get([i,'path']))}
            </div>
          </Form.Item>
        </Form>
        <Form className={styles.steps}>
          <Form.Item label="编辑路线" required>
            <Button onClick={() => setBigMap(true)}>规划路线</Button>
            {
              bigMap ?
              ReactDOM.createPortal((
                <div className={styles.bigMap}>
                  <div className={styles.map}>
                    <StepsEditor className={styles.BigEditor} start={utils.isValidOption(start) && start} end={utils.isValidOption(end) && end} steps={get([i, 'path'])} onChange={(path, distance) => sets([[i, 'path'], path], [[i, 'distance'], distance])} />
                  </div>
                  <div className={styles.close} onClick={() => setBigMap(false)}> </div>
                </div>
              ), document.getElementById('root')) :
              <StepsEditor className={styles.StepsEditor} start={utils.isValidOption(start) && start} end={utils.isValidOption(end) && end} steps={get([i, 'path'])} onChange={(path, distance) => sets([[i, 'path'], path], [[i, 'distance'], distance])} />
            }

          </Form.Item>
        </Form>
      </div>
    )
  }

  // 车辆信息
  function renderCars() {
    const submit = () => {
      if (transportIds.size > 13) {
        message.warning('外调运企上限为12（家）');
        return false;
      }
      setVisible(false);
    };
    const delTransport = (id) => {
      updateTransportIds.remove(id);
      if (activeTransportId === id) setActiveTransportId();
      const delCars = [...transports.get(id).cars.keys()].filter((carNum) => cars.has(carNum));
      updateCars.remove(delCars);
      updateSelected.remove(id);
    };
    const selectedAll = () => {
      updateSelected.set(activeTransportId, !selectedCars.get(activeTransportId));
      const allCarMap = [...transports.get(activeTransportId).cars];
      const bindCar = [...allCarMap].filter((item) => item[1].is_bind_driver === 1);
      if (selectedCars.get(activeTransportId)) {
        //取消全选
        bindCar.map((car) => {
          if (cars.has(car[0])) {
            updateCars.remove(car[0]);
          }
        });
      } else {
        //全选
        bindCar.map((car, i) => {
          if (!cars.has(car[0])) {
            const driverMap = [...car[1].drivers];
            updateCars.set(car[0], driverMap[0][0]);
          }
        });
      }
    };

    return (
      <div className={styles.carInfo}>
        <div className={styles.transports}>
          <div>外调运企</div>
          <div className={styles.selected}>
            {
              [...transportIds].map((id) => (
                <div key={id} className={styles.trans}>
                  {
                    id === selfTransportID ?
                    <>
                      <span>{transports.get(id).name}(本运企)</span>
                    </> :
                    <>
                      <span>{transports.get(id).name}</span>
                      <Icon type="close" onClick={() => delTransport(id)} />
                    </>
                  }
                </div>
              ))
            }
            <Button size="large" onClick={() => setVisible(true)}><Icon type='plus' />添加外调运企</Button>
          </div>
        </div>
        <div className={styles.cars}>
          <div className={styles.title}>
            <div>选择车辆（已选车辆 {cars.size} 辆）</div>
            <Input placeholder="搜索车辆" onChange={(e) => {setSearch(e.target.value)}} />
          </div>
          <div className={styles.labels}>
            <div className={styles.labelItem}>
              <span>异常车辆标签</span>
              <div className={styles.noBind}>无绑定的司机，请到 “车辆信息” 菜单绑定驾驶员</div>
              <div className={styles.blacklist}>砂石运输名录黑名单车辆</div>
              <div className={styles.noDevice}>未安装车载设备</div>
            </div>
            <div className={classNames(styles.all, {[styles.activeAll] : !selectedCars.get(activeTransportId)})} onClick={() => selectedAll()}>{!selectedCars.get(activeTransportId) ? '全选' : '取消全选'}</div>
          </div>
          <div className={styles.context}>
            <div className={styles.left}>
              {[...transportIds].map((id) => (
                <div key={id} className={classNames(styles.transport, { [styles.active]: activeTransportId === id })}
                  onClick={() => setActiveTransportId(id)}>
                  {
                    id === selfTransportID ?
                    <span>{transports.get(id).name}(本运企)</span> :
                    <span>{transports.get(id).name}</span>
                  }
                </div>
              ))}
            </div>
            <div className={styles.right}>
              {activeTransportId && [...transports.get(activeTransportId).cars.values()].map((car) => {
                return (
                  <div
                    key={car.car_num}
                    // className={classNames(styles.car, { [styles.active]: cars.has(car.car_num), [styles.disabled]: car.is_bind_driver !== 1 || car.is_bind_device !== 1 })}
                    // onClick={() => (car.is_bind_driver === 1 && car.is_bind_device === 1) && updateCars.toggle(car.car_num, [...car.drivers.keys()][0])}
                    className={classNames(styles.car, {[styles.active]: cars.has(car.car_num)})}
                    onClick={() => updateCars.toggle(car.car_num, [...car.drivers.keys()][0])}
                  >
                    <div className={styles.item}>
                      <div className={classNames(styles.carNum, { [styles.searchCar]: search !== "" && car.car_num.indexOf(search.toUpperCase()) !== -1})}>{car.car_num}</div>
                      <div className={styles.label}>
                        {
                          car.is_bind_device !== 1 ? <span className={styles.bindDevice}> </span> : null
                        }
                        {
                          car.is_gb_car === 1 ? <span className={styles.blacklist}> </span> : null
                        }
                        {
                          car.is_bind_driver !== 1 ? <span className={styles.bindDriver}> </span> : null
                        }
                      </div>
                      <div onClick={(e) => e.stopPropagation()}>
                        <Select
                          style={{ width: 100 }}
                          placeholder="请选择司机"
                          disabled={!cars.has(car.car_num)}
                          value={cars.get(car.car_num)}
                          onChange={(driverId) => updateCars.set(car.car_num, driverId)}
                        >
                          {car.is_bind_driver === 1 && [...car.drivers.values()].map((item) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                        </Select>
                      </div>
                    </div>
                  </div>
                  )
              })}
            </div>
          </div>
        </div>
        <Modal
          centered
          bodyStyle={{maxHeight: '50vh',overflowY: 'auto',}}
          visible={visible}
          onOk={() => submit()}
          onCancel={() => setVisible(false)}
          footer={[
            <Button key="submit" type="primary" onClick={() => submit()}>确定</Button>,
          ]}
          destroyOnClose>
          <div>
            <div style={{marginBottom: 20}}>
              <Selector showSearch allowClear={false} options={areaOptions} {...selectorStyle} optionValue value={extraCarArea} onChange={(value) => setExtraCarArea(value)} />
            </div>
            <div className={styles.transList}>
              {
                extraTransOptions.filter(({type, area_id}) => type !== 1 && (extraCarArea ? (extraCarArea.value === area_id) : '')).map(({id, name}) => (
                  <div
                    key={id}
                    className={classNames(styles.transItem, {[styles.active]: transportIds.has(id)})}
                    onClick={() => {
                      updateTransportIds.toggle(id);
                      updateSelected.toggle(id, false);
                    }}>
                    {name}
                  </div>
                ))
              }
            </div>
          </div>
        </Modal>
      </div>
    )
  }

  //预览
  function renderDetails() {
    let materiel;
    let outVolume;
    let routesArr;
    if (types === '1') {
      routesArr = steps.delete(0).delete(-1).delete(-1).toJS();
      materiel = routesArr[0].materiel;
      outVolume = routesArr[0].out_volume;
    }
    if (types === '2'){
      routesArr = steps.delete(0).delete(-1).delete(-1).delete(-1).toJS();
      materiel = routesArr[0].materiel;
      outVolume = routesArr[0].out_volume;
    }
    const base = get([0, 'plan']);
    const dates = get([0, 'dates']);
    const selectCarArr = transformers.newCars(transportIds, cars, transports);

    function MapIndex({route, path}) {
      const paths = path.map(({lng, lat}) => new AMap.LngLat(lng, lat));
      const mapRef = useCallback((current) => {
        if (!current) return;
        const map = new window.AMap.Map(current, { mapStyle: 'amap://styles/macaron' });
        map.add(new window.AMap.Polyline({
          path: paths,
          strokeWeight: 6,
          lineJoin: 'bevel',
          lineCap: 'round',
          showDir: true,
        }));
        map.add([{lng: route[0].startLng, lat: route[0].startLat}, {lng: route[0].endLng, lat: route[0].endLat}].map(location => new window.AMap.Marker({
            position: new window.AMap.LngLat(location.lng, location.lat),
            label: {
              direction: 'center',
              offset: new window.AMap.Pixel(0, -35),
            },
          })));
        map.setFitView();
      }, [route]);
      return <div className={styles.map} ref={mapRef} />
    }
    function getPoint(route) {
      const arr = [];
      arr.push({
        startLng: base.lng,
        startLat: base.lat,
        endLng: route.end.lng,
        endLat: route.end.lat,
      });
      return arr;
    }
    //本运企
    const selfColumns = [
      { title: '车牌号', dataIndex: 'car[0].car_num', width: 120 },
      { title: '驾驶员', dataIndex: 'driver.name', width: 100 },
      { title: '驾驶证号码', dataIndex: 'driver.driver_license_num', width: 130 },
      { title: '准驾车型', dataIndex: 'driver.type', width: 120 },
      { title: '运渣证编号', dataIndex: 'car[0].garbage_license_num', width: 120 },
      { title: '货箱内径', dataIndex: 'car[0].inner_long', render:(t, row) => `${row.car[0].inner_long}x${row.car[0].inner_width}x${row.car[0].inner_height}` },
      { title: '未装车载', dataIndex: 'car[0].is_bind_driver', render:(t) => t === 1 ? '未安装' : '已安装', width: 120 },
      { title: '相关文件', dataIndex: 'content', width: 120, render: (t, row) => (<Icon className={styles.docsIcon} type="folder-open" onClick={() => preview.image(getConnectDocs(row.car[0]))} />) },
    ];
    let selfArr = [];
    selectCarArr.map((item) => {
      if (item[0].type === 1){
        selfArr.push(item[0]);
        return(selfArr);
      }
    });

    //外调运企
    const otherColumns = [
      { title: '车牌号', dataIndex: 'car[0].car_num', width: 120 },
      { title: '驾驶员', dataIndex: 'driver.name', width: 100 },
      { title: '驾驶证号码', dataIndex: 'driver.driver_license_num', width: 130 },
      { title: '准驾车型', dataIndex: 'driver.type', width: 120 },
      { title: '运渣证编号', dataIndex: 'car[0].garbage_license_num', width: 120 },
      { title: '货箱内径', dataIndex: 'car[0].inner_long', render:(t, row) => `${row.car[0].inner_long}x${row.car[0].inner_width}x${row.car[0].inner_height}` },
      { title: '未装车载', dataIndex: 'car[0].is_bind_driver', render:(t) => t === 1 ? '未安装' : '已安装', width: 120 },
      { title: '相关文件', dataIndex: 'content', width: 120, render: (t, row) => (<Icon className={styles.docsIcon} type="folder-open" onClick={() => preview.image(getConnectDocs(row.car[0]))} />) },
    ];
    let otherArr = [];
    selectCarArr.map((item) => {
      if (item[0].type !== 1){
        otherArr.push(item[0]);
        return(otherArr);
      }
    });
    function getName(otherId) {
      let name = '';
      otherArr.map((item) => {
        if (item.id === otherId) {
          name = item.transportName
        }
      });
      return name;
    }
    function getConnectDocs(connectDocs) {
      const docs = [
        ['车辆行驶证照片', 'drive_license_photo'],
        ['车辆道路运输证照片', 'ship_license_photo'],
        ['车辆成都市建筑垃圾处置运输证正、反面照片', 'garbage_license_photo'],
        ['车辆前方45°角照片', 'car_photo'],
      ].reduce((final, [title, key]) => {
        return [...final, ...(connectDocs[key] || '').split(',').map(src => ({ title, src }))];
      }, []);
      return docs;
    }
    //申报附件
    function docsRender(docs) {
      const docArr = JSON.parse(transformers.newEncodeDocs(docs));
      const showDoc =  docArr.map(({ n, v }) => (v || '').split(',').map((src) => {
        const title = n.indexOf('（') === -1 ? n : n.slice(0, n.indexOf('（'));
        return { title, src };
      }));
      return (
        <div className={styles.files}>
          {
            showDoc.map((doc, index) => {
              return (
                <div className={styles.file} key={index} onClick={() => preview.image(doc)}>
                  <div className={styles.docName}>{doc[0].title}</div>
                  <div className={styles.image} style={{ backgroundImage: `url(${doc[0].src})` }} />
                </div>
              )
            })
          }
        </div>
      );
    }

    return(
      <div className={styles.details}>
        <div className={styles.item}>
          <div className={styles.title}>基础信息</div>
          <div className={styles.info}>
            <div className={styles.inline}>
              <div className={styles.label}>运输起点</div>
              <div className={styles.name}>{base.label}</div>
            </div>
            <div className={styles.inline}>
              <div className={styles.label}>运输时限</div>
              <div className={styles.name}>{dates[0].format('YYYY-MM-DD')}至{dates[1].format('YYYY-MM-DD')}</div>
            </div>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>线路信息</div>
          <div className={styles.info}>
            <div className={styles.inline}>
              <div className={styles.label}>运输起点</div>
              <div className={styles.name}>{base.label}</div>
            </div>
            <div className={styles.inline}>
              <div className={styles.label}>运输品类</div>
              <div className={styles.name}>{materiel.label}</div>
            </div>
            <div className={styles.inline}>
              <div className={styles.label}>排放总量(方)</div>
              <div className={styles.name}>{outVolume}</div>
            </div>
            {
              routesArr.map((route, index) => {
                return (
                  <div key={route.key} style={{display: 'flex', flexDirection: 'column'}}>
                    <div className={styles.inline}>
                      <div className={styles.label}>运输终点({index+1})</div>
                      <div className={styles.name}>{route.end.label}</div>
                    </div>
                    <div className={styles.inline}>
                      <div className={styles.label}>接收方量(方)</div>
                      <div className={styles.name}>{route.in_volume}</div>
                    </div>
                    <div className={styles.inline}>
                      <div className={styles.label}>运输距离</div>
                      <div className={styles.name}>{getDistance(route.distance)}</div>
                    </div>
                    <div className={styles.inline}>
                      <div className={styles.label}>途经点</div>
                      <div className={styles.name}>{getRoutes(route.path)}</div>
                    </div>
                    <div className={styles.inline}>
                      <div className={styles.label}> </div>
                      <div className={styles.name}>
                        <MapIndex route={getPoint(route)} path={route.path} />
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>车辆信息</div>
          <div className={styles.info}>
            <div className={styles.inline}>
              <div className={styles.label}>本运企</div>
              <div className={styles.name}>
                <div className={styles.transName}>
                  <div className={styles.nowrap} title={transports.get(selfTransportID).name}>{transports.get(selfTransportID).name}</div>
                  <div>已选车辆数  {selfArr.length}  辆</div>
                </div>
                <Table bordered rowKey="rowKey" columns={selfColumns} dataSource={selfArr} pagination={false}/>
              </div>
            </div>
            {
              Array.from(transportIds).filter(transportId => transportId !== selfTransportID).map((transportId) => (
                <div key={transportId} className={styles.inline}>
                  <div className={styles.label}>外调运企</div>
                  <div className={styles.name}>
                    <div className={styles.transName}>
                      <div className={styles.nowrap} title={getName(transportId)}>{getName(transportId)}</div>
                      <div>已选车辆数  {otherArr.filter(item => item.id === transportId).length}  辆</div>
                    </div>
                    <Table bordered rowKey="rowKey" columns={otherColumns} dataSource={otherArr.filter(item => item.id === transportId)} pagination={false}/>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        {
          types === '2' ?
          <div className={styles.item}>
            <div className={styles.title}>申报附件</div>
            <div className={styles.info}>
              <div className={styles.inline}>
                <div className={styles.label}>运输信息</div>
                <div className={styles.files}>
                  {docsRender([...transDocs])}
                </div>
              </div>
              <div className={styles.inline}>
                <div className={styles.label}>线路信息</div>
                <div className={styles.files}>
                  {docsRender([...routesDocs])}
                </div>
              </div>
            </div>
          </div> :
          null
        }
      </div>
    )
  }

  //申报附件
  function renderDocs() {
    return (
      <div className={styles.docs}>
        <div className={styles.transform}>
          <div>运输信息</div>
          <Form className={styles.form}>
            {
              transformDocArr.map((item) => {
                return (
                  <Form.Item key={item.name} label={item.name} required className={styles.item}>
                    <Uploader className={styles.uploader} accept="image/*" listType="picture-card" fileList={transDocs.get(item.name)}
                      onChange={(fileList) => {
                        if (fileList.length > 4){
                          message.warning('最多上传4张');
                          return false;
                        }
                        updateTransDocs.set(item.name, fileList)}
                      }>
                      <Icon type="plus" />
                    </Uploader>
                  </Form.Item>
                )
              })
            }
          </Form>

        </div>
        <div className={styles.routes}>
          <div>线路信息</div>
          <Form className={styles.form}>
            {
              routesDocArr.map((item) => {
                return (
                  <Form.Item key={item.name} label={item.name} required className={styles.item}>
                    <Uploader className={styles.uploader} accept="image/*" listType="picture-card" fileList={routesDocs.get(item.name)}
                      onChange={(fileList) => {
                        if (fileList.length > 4){
                          message.warning('最多上传4张');
                          return false;
                        }
                        updateRouteDocs.set(item.name, fileList)}
                      }>
                      <Icon type="plus" />
                    </Uploader>
                  </Form.Item>
                )
              })
            }
          </Form>
        </div>
      </div>
    )
  }

  function renderStep() {
    if (currentStep === 0) return renderBase();
    if (types === '1') {
      if (currentStep === steps.size - 2) return renderCars();
    }
    if (types === '2') {
      if (currentStep === steps.size - 3) return renderCars();
      if (currentStep === steps.size - 2) return renderDocs();
    }
    if (currentStep === steps.size - 1) return renderDetails();
    return renderRoute(currentStep);
  }

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <Steps steps={steps} current={currentStep} types={types} />
        <div className={styles.content} ref={contentBoxRef}>{renderStep()}</div>
        <Actions
          steps={steps}
          current={currentStep}
          prevable={prevable}
          nextable={nextable}
          finished={finished}
          onCancel={onCancel}
          onSubmit={onSubmit}
          onStepChange={onStepChange}
          onRouteDelete={onRouteDelete}
          onRouteCreate={onRouteCreate}
          types={types}
          onSubmitSure={onSubmitSure}
        />
      </div>
    </div>
  );
}
export default previewable(RouteEditor);
