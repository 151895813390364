export default {
  action: undefined,
  refresh() {
    console.log(this)
    this.action();
  },
  onRefresh(callback) {
    this.action = () => callback();
  },
};
