import React, { useState, useEffect } from 'react';
import request from 'utils/request';
import { JudgeTicket } from "components/Previewer";
import store from "stores/user";
import {Radio, DatePicker, message, Modal} from "antd";

export default function OverTicketByPoints({ point, ticket_id, ticket_status, ticketTimes, onClose }){
  const roleId = store.getState().roles.id;
  const [overDate, setOverDate] = useState('');
  const [ticketStatus, setTicketStatus] = useState(ticket_status);
  const [isSelf, setIsSelf] = useState(0);
  const overTicket =(json) => {
    request('/web/ticket_map/overTicket', json)
      .then(body => {
        if(body.data.code === 200 ){
          const data = ticketTimes.map(({id, start_time, end_time, ...v}) => ({
            id: id,
            start_time : json.ticket_status === 1 && (id == json.ticket_id) ? json.end_time : start_time,
            end_time : json.ticket_status === 2 && (id == json.ticket_id) ? json.end_time : end_time,
            ...v
          }));
          onClose(data, 1);
          message.success('成功结束联单', 6);
        }else{
          message.warn('结束联单失败');
        }
      });
  }
  function onSuccess(){
    if(roleId !== 111 && roleId !== '111'){
      message.warn('对不起，你没有权限修改');
      onClose(ticketTimes)
    }
    if (!overDate || overDate === '') {
      message.warn(`请选择${ticketStatus === 1 ? '起点' : '结束'}时间！`, 8);
    } else {
      const r = JudgeTicket({overDate: overDate.format('YYYY-MM-DD HH:mm:ss'), ticket_status: ticketStatus, ticketTimes, isSelf, ticketId: ticket_id, show: false});
      if(r){
        const json = {
          end_type: 'source',
          instance_name: point.name,
          ticket_status: ticketStatus,
          ticket_id: point.ticket_id,
          department_id: point.department_id,
          end_time: overDate.format('YYYY-MM-DD HH:mm:ss'),
          instance_id: point.id,
          lat: point.value[1],
          lng: point.value[0]
        };
        overTicket(json);
      }
    }
  }

  return (
    <Modal
      title={'选择修改起点时间或确认在该点结束联单?'}
      width={800}
      bodyStyle={{ maxHeight: '60vh', overflow: 'auto'}}
      destroyOnClose={true}
      visible={true}
      onCancel={() => onClose(ticketTimes)}
      onOk={onSuccess}
    >
      <div>
        <div style={{marginBottom: 10}}>
          <Radio.Group value={ticketStatus} buttonStyle="solid" onChange={(e) => setTicketStatus(e.target.value) }>
            <Radio.Button value={1}>起点</Radio.Button>
            <Radio.Button value={2}>终点</Radio.Button>
          </Radio.Group>
          <Radio.Group value={isSelf} buttonStyle="solid" style={{marginLeft: 30}} onChange={(e) => setIsSelf(e.target.value)}>
            <Radio.Button value={0}>非内转联单</Radio.Button>
            <Radio.Button value={1}>内转联单</Radio.Button>
          </Radio.Group>
        </div>
        <span>请选择结束时间: </span>
        <DatePicker showTime placeholder="选择结束时间" format="YYYY-MM-DD HH:mm:ss" onChange={(next) => setOverDate(next) }/>
      </div>
    </Modal>
  )
}
