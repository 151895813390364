// import debug from 'debug';

// const render = name => debug('lifecycle:render')('<%s />', name);
// const effect = name => debug('lifecycle:effect')('<%s />', name);
// const loging = name => debug('action:loging')('%s', name);
// const http = (api, params) => debug('action:http')('"%s" %o', api, params);
// const httpError = get => debug('action:500')('"%s"', get);
// const action = get => debug('action:action')('"%s"', get);

export default {
  render: () => {},
  effect: () => {},
  loging: () => {},
  http: () => {},
  httpError: () => {},
  action: () => {},
};
