import transformers from './transformers';

export default {
  isValidOption(option) {
    return option !== undefined && option.value !== undefined;
  },
  findInOptions(options, value, by = 'value') {
    return options.find(option => option[by] === value);
  },
  encodePath(array) {
    return JSON.stringify(array.map(({ lng, lat, address }) => [parseFloat(lng).toFixed(6), parseFloat(lat).toFixed(6), address]));
  },
  common(steps) {
    const dinas_plan_id = steps.getIn([0, 'plan']).plan;
    const start_department_id = steps.getIn([0, 'plan']).department;
    const start = {
      id: steps.getIn([0, 'plan']).value,
      name: steps.getIn([0, 'plan']).label,
    };
    const other_transport = {
      id: steps.getIn([0, 'partner']).value || '',
      name: steps.getIn([0, 'partner']).label || '',
    };
    const content = transformers.encodeDocs(steps.getIn([0, 'docs']));
    const content_id = steps.getIn([0, 'content_id']);
    const cars = steps.getIn([steps.size - 1, 'cars']).valueSeq().toArray().map(car => ({
      id: car.id,
      transport_id: car.transport,
      car_num: car.label,
      driver: {
        id: car.driver.value,
        name: car.driver.label,
      },
      inner_long: car.long,
      inner_width: car.width,
      inner_height: car.height,
      volumn: car.volumn,
    }));
    const routes = steps.slice(1, steps.size - 1).toArray().map((route) => {
      return ({
        id: route.get('id'),
        transport_route_id: route.get('transport_route_id'),
        transport_start_date: route.get('dates')[0].format('YYYY-MM-DD'),
        transport_end_date: route.get('dates')[1].format('YYYY-MM-DD'),
        start_lng: steps.getIn([0, 'plan']).lng,
        start_lat: steps.getIn([0, 'plan']).lat,
        start_address: steps.getIn([0, 'plan']).label,
        end_lng: route.get('end').lng,
        end_lat: route.get('end').lat,
        end_address: route.get('end').label,
        distance: route.get('distance'),
        steps: this.encodePath(route.get('path')),
        content: transformers.encodeDocs(route.get('docs')),
        content_id: route.get('content_id'),
        end_department_id: route.get('end').department,
        end: {
          id: route.get('end').value,
          name: route.get('end').label,
        },
        materiel_id: route.get('materiel').value,
        materiel_name: route.get('materiel').label,
      });
    });
    return { dinas_plan_id, start_department_id, start, other_transport, content, content_id, cars, routes };
  },
  create(steps) {
    return this.common(steps);
  },
  update(steps, originalRoutes, originalCars) {
    const data = this.common(steps);
    originalRoutes.forEach((route) => {
      const index = data.routes.findIndex(({ id }) => id === route.id);
      if (index === -1) {
        data.routes.push({
          id: route.id,
          transport_route_id: route.transport_route_id,
          deleted: 1,
        });
      }
    });
    originalCars.forEach((car) => {
      const index = data.cars.findIndex(({ id }) => id === car.id);
      if (index === -1) {
        data.cars.push({
          id: car.id,
          transport_id: car.transport_id,
          deleted: 1,
        });
      }
    });
    return data;
  },
  isValidDates(dates) {
    return dates.length > 0 && dates !== null && dates !== undefined;
  },
  isValidNumber(number) {
    return number > 0;
  },
  routesInfo(routes) {
    let arr = routes;
    arr = arr.map((route) => {
      return ({
        end_id: route.end.value,
        end_name: route.end.label,
        end_department_id: route.end.department,
        end_lat: route.end.lat,
        end_lng: route.end.lng,
        end_address: route.end.address,
        in_volume: route.in_volume,
        distance: route.distance,
        steps: this.encodePath(route.path),
      })
    });
    return arr;
  },
  carInfo(cars) {
    let carArr = cars;
    carArr = carArr.map((car) => {
      return ({
        transport_id: car[0].id,
        car_num: car[0].car[0].car_num,
        driver_id: car[0].driver ? car[0].driver.id : 0,
        driver_name: car[0].driver ? car[0].driver.name : '',
        inner_long: car[0].car[0].inner_long,
        inner_width: car[0].car[0].inner_width,
        inner_height: car[0].car[0].inner_height,
        volumn: car[0].car[0].volumn,
        transport_type: car[0].type,
        driver_license_num: car[0].driver ? car[0].driver.driver_license_num : '',
        type: car[0].driver ? car[0].driver.type : '',
        is_bind_device: car[0].car[0].is_bind_device,
      });
    });
    return carArr;
  },
  newCreate(types, steps, transportIds, cars, transports, transDocs, routesDocs, id) {
    const plan_id = id;
    const area_id = steps.getIn([0, 'plan']).area;
    const start_department_id = steps.getIn([0, 'plan']).department;
    const start_id = steps.getIn([0, 'plan']).value;
    const start_name = steps.getIn([0, 'plan']).label;
    const start_lat = steps.getIn([0, 'plan']).lat;
    const start_lng = steps.getIn([0, 'plan']).lng;
    const start_address = steps.getIn([0, 'plan']).address;
    const start_date = steps.getIn([0, 'dates'])[0].format('YYYY-MM-DD');
    const end_date = steps.getIn([0, 'dates'])[1].format('YYYY-MM-DD');
    const plan_type = parseInt(steps.getIn([0, 'types']));
    let materiel_id;
    let materiel_name;
    let out_volume;
    let routesArr;
    let routes;
    let content;
    if (types === '1') {
      routesArr = steps.delete(0).delete(-1).delete(-1).toJS();
      materiel_id = routesArr[0].materiel.value;
      materiel_name = routesArr[0].materiel.label;
      out_volume = routesArr[0].out_volume;
      content = '';
      routes = this.routesInfo(routesArr);
    }
    if (types === '2') {
      routesArr = steps.delete(0).delete(-1).delete(-1).delete(-1).toJS();
      materiel_id = routesArr[0].materiel.value;
      materiel_name = routesArr[0].materiel.label;
      out_volume = routesArr[0].out_volume;
      routes = this.routesInfo(routesArr);
      const docs = [...transDocs, ...routesDocs];
      content = transformers.newEncodeDocs(docs);
    }
    //车辆
    const selectCars = transformers.newCars(transportIds, cars, transports);
    const finalCars = this.carInfo(selectCars);

    return {
      plan_id,
      area_id,
      start_department_id,
      start_id,
      start_name,
      start_lat,
      start_lng,
      start_address,
      start_date,
      end_date,
      plan_type,
      materiel_id,
      materiel_name,
      out_volume,
      routes,
      cars: finalCars,
      content,
    };
  },
};
