import React, { useState } from 'react';
import { Modal, Input, message } from 'antd';
import { dataSource } from './index';
import request from 'utils/request';

export default function Deal({ location, history }) {
  const back = () => history.goBack();
  const { id, deal_result, plate_num } = location.state;
  const [result, setResult] = useState(deal_result);
  const onFinish = () => {
    request('/river/river_alarm/dealAlarm', {ticket_id: id, deal_result: result} )
      .then(body => body.data)
      .then(data => {
        if (data.code === 200) {
          message.success(data.msg);
          dataSource.refresh();
          back();
        } else {
          message.warn(data.msg);
        }
      });
  };

  return (
    <Modal
      title={`更新${plate_num}超速处理结果`}
      visible={true}
      width={400}
      onCancel={back}
      onOk={onFinish}
    >
      <Input.TextArea plcaeholder="请填写处理结果" defaultValue={result} onChange={e => setResult(e.target.value)} />
    </Modal>
  )
}
