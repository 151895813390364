import React from 'react';
import { Modal, Carousel } from 'antd';
// import '../css/ShowImages.scss';

export default class ShowImages extends React.PureComponent {
  state = {
    visible: true,
    image: [],
    width: 480
  };
  static getDerivedStateFromProps(props, propsstate) {
    return {
      visible: true,
      image: props.data.data,
      width: props.data.width,
    }
  }
  handleCancel = () => {
    this.props.callback();
    this.setState({
      visible: false,
    });
  };
  onChange = (val) => {

  };
  componentDidMount(){
    this.setState({
      visible: true,
    });
  };
  render () {
    const state = {...this.state};
    return (
      <Modal
        closable={false}
        wrapClassName='modal-img'
        centered={true}
        bodyStyle={{padding: 0}}
        visible={state.visible}
        onCancel={this.handleCancel}
        footer={null}
        width={state.width}
        maskClosable={true}
      >
        <Carousel afterChange={this.onChange} autoplay={true}>
          {
            state.image.map( (v,i) => {
              return (
                <div key={i}><img src={v} style={{width: '100%'}} alt=""/></div>
              )
            })
          }
        </Carousel>
      </Modal>
    )
  };
}
