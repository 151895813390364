/* eslint-disable no-shadow */
import React, { useMemo, useState } from 'react';
import { Modal, Form, message, Input, Checkbox, Button } from 'antd';
import immutable from 'immutable';
import request from 'utils/request';
import useImmutable from './useImmutable';
import Text from './FormItem/Text';
import Number from './FormItem/Number';
import TextArea from './FormItem/TextArea';
import Selector from './FormItem/SelectorNew';
import Uploader from './FormItem/Uploader';
import UploaderDragger from './FormItem/UploaderDragger';
import DatePicker from './FormItem/DatePicker';
import RadioKey from './FormItem/Radio';
import RadioVolumn from './FormItem/RadioVolumn';
import RangePicker from "./FormItem/RangePicker";
import NumberByOptions from "./FormItem/NumberByOptions";
import { eleInstanceIds, eleBuildsIds } from '../../paths/SandstoneReceiver/ToBeConfirmed/List';
import { EleVolume, ElectronStripCom } from './FormItem/EleCom';
import { useEffect } from 'react';
import { outInstanceIds } from '../../paths/SandstoneReceiver/ToBeConfirmed/List';
import { CarSize } from './CarSizeCom';

const getModalProps = (props = {}) => ({
  visible: true,
  centered: true,
  bodyStyle: {
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  ...props,
});

const getFormProps = (props = {}) => ({
  ...props,
});

export default function ModalFormPage({ init, initTo, localModel, footer, submit, title, onCancel, onFinish, modalProps, formProps, inputs: originalInputs, defaultValue, ...props }) {
  const { get, set, sets, setter, toJS } = useImmutable(immutable.Map(defaultValue));
  const [initialed, setInitialed] = useState(false);
  const [reasonVolumnKey, setReasonVolumnKey] = useState(false);
  const inputs = originalInputs.reduce((flatten, input) => {
    if (Array.isArray(input.children)) return [...flatten, ...input.children.map(child => ({ required: input.required, ...child }))];
    return [...flatten, input];
  }, []);

  // 是否需要初始化数据
  const initialing = init && !initialed;
  const localing = localModel && !initialed;
  const initToing = initTo && !initialed;
  if (initialing) {
    const [api, params = {}, transformer] = init;
    request(api, params)
      .then(body => body.data.data)
      .then(data => (transformer ? transformer(data) : data))
      .then((data) => {
        sets(...inputs.map(({ key }) => [key, data[key]]));
        setInitialed(true);
      });
  }
  if(initToing){    
    sets(...inputs.map(({ key }) => [key, initTo[key]]));
    setInitialed(true);
  }

  // useEffect(() => {
  //   ...inputs.map(({ key }) => [key, data[key]])
  // }, []);
  function renderInput(key, { type, relies, reliesAs, rely, relyAs, extraData = {}, afterChange = (v, sets) => sets([key, v]), ...rest }) {
    const inputProps = { key, allowClear: true };
    switch (type) {
      case 'date':
      case 'date-range': {
        let disabled = false;
        if (rely) {
          disabled = !!get(rely);
        }
        const { format = 'YYYY-MM-DD HH:mm:ss', ...props } = rest;
        return <DatePicker {...inputProps} {...props} disabled={disabled} format={format} value={get(key)} onChange={setter(key)} />;
      }
      case 'dates-range': {
        let disabled = false;
        const { splitFlag } = rest;
        if (rely) {
          disabled = !!get(rely);
        }
        const { format = 'YYYY-MM-DD HH:mm:ss', ...props } = rest;
        return <RangePicker {...inputProps} {...props} format={format} value={ splitFlag && get(key) && !Array.isArray(get(key)) ? get(key).split(splitFlag) : get(key)} onChange={setter(key)} />;
      }
      case 'checkbox': return <Checkbox checked={get(key)} onChange={e => set(key, e.target.checked ? 1 : 0)} />;
      case 'select': {
        const onChange = value => afterChange(value, sets);
        let disabled = false;
        let { options } = rest;
        const relyKeys = relies || (rely && [rely]);
        const dataKeys = reliesAs || relies || (relyAs && [relyAs]) || (rely && [rely]);
        const {option_type} = rest;
        if (key === 'materiel_id' && isEle && Array.isArray(options)) {
          const materialArr = options.map(({ name, ...rest }) => isEle ? (name.includes('连砂石') || name.includes('泥夹石') ? ({ name, ...rest }) : false) : ({ name, ...rest })).filter((item) => item);
          options = materialArr;
        }
        if (relyKeys) {
          const relyValues = relyKeys.map(key => get(key));
          disabled = relyValues.filter(value => value === undefined).length > 0;
          // 如果依赖重置，自己也重置
          if (disabled && get(key) !== undefined) {
            set(key, undefined);
          }
          if (typeof options === 'string') {
            const data = dataKeys.reduce((final, dataKey, index) => {
              return { ...final, [dataKey]: relyValues[index] };
            }, {});
            options = { url: options, data: { ...extraData, ...data } };
          }
        }
        if(option_type){
          const optionValues = get(option_type);// || [option_type];//relyKeys.map(key => get(key));
          const relyValues = get(option_type);
          options = get(option_type) === 2 ? '/web/keep_on_record/carList' : 'web/keep_on_record/tmpCarList';
          return <Selector {...inputProps} disabled={disabled} {...rest} options={options} value={get(key)} onChange={onChange} />;
        }
        else if(rest.show_type){
          let newValue = [];
          if(get(key) && get(key) !== '' && !Array.isArray(get(key))){
            get(key).split(",").map((v) => {
              newValue.push(v)
            })
          }else{
            newValue = get(key)
          }
          return <Selector {...inputProps} disabled={disabled} mode={"multiple"} {...rest} options={options} value={newValue} onChange={onChange} />;
        }
        else if (Array.isArray(options)) {          
          return <Selector {...inputProps} disabled={disabled} {...rest} options={options} value={get(key)} onChange={onChange} />;
        }
        else if(JSON.stringify(extraData) && !relyKeys){
          return <Selector {...inputProps} {...extraData} disabled={disabled} {...rest} options={{url: options, data: {...extraData}}} value={get(key)} onChange={onChange} />;
        }
        else{
          return <Selector {...inputProps} disabled={disabled} {...rest} options={options} value={get(key)} onChange={onChange} />;
        }
      }
      // case 'upload': return <Uploader {...inputProps} {...rest} value={get(key)} onChange={setter(key)} />;
      case 'upload': {
        const disabled = rely && !get(rely);
        return <Uploader {...inputProps} {...rest} value={get(key)} onChange={setter(key)} disabled={disabled} />;
      }
      case 'upload-dragger': return <UploaderDragger {...inputProps} {...rest} value={get(key)} onChange={setter(key)} />;
      case 'number-options': {
        let numberOption = '', relyValues = [];
        let { reliesCount, options, extraKey, relyMoreKey, maxValueRely } = rest;
        if(rely){
          const relyValues = get(rely);
          numberOption = { [rely]: relyValues };
          options = {url: options, data: { ...numberOption, building_id: get('start_id'), }}
        }
        if(reliesCount){
          relyValues = reliesCount.map(key => get(key));
        }
        const numberValue = !reliesCount ? get(key) : (extraKey && (!get([relyMoreKey]) || get([relyMoreKey]) === '' || get([relyMoreKey]) === 0)) ?  get([extraKey]) : ( relyValues[0] && relyValues[1] ? relyValues[0] - relyValues[1] : '');

        return <NumberByOptions
          valueKey={key}
          {...inputProps}
          {...rest}
          max={maxValueRely ? get(maxValueRely) : undefined}
          value={(numberValue && typeof numberValue === 'number') ? numberValue.toFixed(2) : numberValue}
          options={options}
          onChange={(e, data) => {
            let arr = undefined;
            if (rest.isVolumn && key === 'deduction_volume') {
              const liangfang = get('liangfang');
              if (e > liangfang) {
                arr = undefined;
                return;
              }
              else arr = ['volumn', parseFloat((liangfang - e).toFixed(2))];
            }
            if (data) {
              if (arr) {
                sets([key, e], ['marked_volume', data.marked_volume], arr)
              }
              else sets([key, e], ['marked_volume', data.marked_volume])
            } else {
              if (arr) sets([key, e], arr);
              else sets([key, e])
            }
          }}
        />
      }
      case 'volumn-sel': {
        const car_volumn = get('car_volumn');
        return (car_volumn === 0 || car_volumn === '0.0' || car_volumn === '0.00') ? null : (
          <>
            <Button style={{ marginLeft: 5 }} onClick={() => set('volumn', get('car_volumn'))}>货箱方量</Button>
            <Button style={{ marginLeft: 5 }} onClick={() => set('volumn', get('marked_volume'))}>标记方量</Button> 
          </>
        )
      }
      case 'carSize': {
        const car_num = get('car_num');
        return <CarSize plateNum={car_num} deduction_volume={get('deduction_volume')} onChange={(liangfang, e) => {
          setTimeout(() => {
            sets(['volumn', e], ['liangfang', liangfang]);
          }, 100)
        }} />
      }
      case 'number': return <Number {...inputProps} {...rest} value={get(key)}  onChange={setter(key)} />;
      case 'textarea': return <TextArea key={key} {...rest} value={get(key)} onChange={setter(key)} />;
      case 'radio': {
        let { options } = rest;
        return <RadioKey options={options} {...inputProps} value={get(key)} onChange={setter(key)} />;
      }
      case 'radioVolume': {
        let { options } = rest;
        return <RadioVolumn options={options} {...inputProps} value={get(key)} radioKey={reasonVolumnKey ? reasonVolumnKey : ''} onChange={setter(key)} radioOnchange={ (e) => setReasonVolumnKey(e) } />;
      }
      case 'eleVolume': {
        return !isEle ? null : <EleVolume start_id={get('start_id')} end_id={get('end_id')} plate_num={get('car_num')} />
      }
      case 'electronStrip': {
        // const isEle = judgeIsEle();
        return !isEle ? null : <ElectronStripCom
          path={props.path}
          extraParams={props.extraParams}
          history={props.history}
          onChange={(e, status) => {
            sets(['human_ticket_id', e], ['quantities_check_status', status])            
          }}
          start_id={get('start_id')}
          end_id={get('end_id')}
          plate_num={get('car_num')}
        />
      }
      default:return <Text {...inputProps} {...rest} value={get(key)} onChange={setter(key)} />;
    }
  }
  function renderItem({ label, key, required, hide, children, showOrHide, ...rest }) {
    if(rest.type === 'radioVolume' && (get(showOrHide) !== 0 && get(showOrHide) !== 0.0 && get(showOrHide) !== '0.0')){
      hide = false;
    }
    if (isEle && label.includes('方量')) return;
    if (hide) return null;
    return (     
      <Form.Item key={key || label} label={label} required={required}>
        {!Array.isArray(children) ? renderInput(key, rest) : (
          <Input.Group compact>
            {children.map(({ key, ...rest }) => renderInput(key, rest))}
          </Input.Group>
        )}
      </Form.Item>
    );
  }
  // 判断是自己提交还是把数据交给父组件
  function onSubmit(type) {
    const data = toJS();
    if (!submit) {
      if(reasonVolumnKey && reasonVolumnKey !== '其他'){
        onFinish({...data, reasonVolumnKey, type});
      }else{
        onFinish(data, type);
      }
    } else {
      const [api, extraParams = {}] = submit;
      request(api, { ...extraParams, ...data })
        .then(body => body.data)
        .then(({ code, msg }) => {
          if (code === 200) {
            onFinish();
          } else {
            const warn = typeof msg === 'string' ? msg : '保存失败!';
            message.warn(warn);
          }
        });
    }
  }
  const isEle = eleInstanceIds.includes(toJS().end_id) && eleBuildsIds.includes(toJS().start_id); // 是否有电子出门条
  const finalModalProps = getModalProps(modalProps);
  const finalFormProps = getFormProps(formProps);
  const disabled = inputs.find(({ key, required, hide, type, hasZero }) => {
    return (type === 'electronStrip' && !isEle) ? false : !hide && required && (get(key) === undefined || get(key) === '' || (type === 'number-options' && !hasZero && get(key) === 0))
  }) || (isEle && toJS().quantities_check_status !== 3);
  const [clickSubmit, setClickSubmit] = useState(false);
  const disableds = useMemo(() => {
    const end_id = get('end_id');
    const volumn = get('volumn');
    const car_volumn = ('car_volumn');
    const marked_volume = get('marked_volume');   
    if (!outInstanceIds.includes(end_id)) return false;
    return !volumn || volumn === 0 || volumn === '0.0' || volumn === '0.00' || car_volumn === 0 || car_volumn === '0.0' || car_volumn === '0.00' || marked_volume === 0 || marked_volume === '0.0' || marked_volume === '0.00'
  }, [toJS()]);

  return (
    <Modal
      {...finalModalProps}
      title={title}
      onCancel={onCancel}
      onOk={onSubmit}
      footer={
        <>
          <Button onClick={onCancel}>取消</Button>
          <Button disabled={ disableds || disabled || clickSubmit } onClick={() => {onSubmit(1); setClickSubmit(true)}} type={'primary'} >确认收货并打印</Button>
          <Button disabled={ disableds || disabled || clickSubmit } onClick={() => {onSubmit(0); setClickSubmit(true)}} type='danger'>保存并确认收货</Button>
        </>
          // : <>
          //   <Button onClick={onCancel}>取消</Button>
          //   <Button disabled={ disabled || clickSubmit } onClick={() => {onSubmit(0); setClickSubmit(true)}} type='danger'>确认收货</Button>

          // </>
      }
    >
      <Form {...finalFormProps}>
        {!initialing && originalInputs.map(renderItem)}
      </Form>
    </Modal>
  );
}
