import { connect } from 'react-redux';

function getRoles(roles = {}) {
  switch (roles.id) {
    case 101: return { isTransportEnterpriseManager: true };
    case 102: return { isTransportEnterpriseDriver: true };
    case 103: return { isPolice: true };
    case 104: return { isGTXC: true };
    case 105: return { isHXTManager: true };
    case 106: return { isCTC: true };
    case 107: return { isConcreteStation: true };
    case 108: return { isYCB: true };
    case 109: return { isHXTReceiver: true };
    default: return {};
  }
}

export default connect((user) => ({
  user: {
    ...user,
    ...getRoles(user.roles),
  },
}));
