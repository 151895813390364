import React, { useState, useEffect } from 'react'
import { Modal, Steps, List, Button } from 'antd'
import request from 'utils/request';
import ImagePreviewButtonRender from 'ui/TableCell/ImagePreviewButtonRender';
import { previewable } from 'components/Previewer/index';
import styles from './index.scss'
const { Step } = Steps;


/* 详情按钮 */
function Details({ history }) {
  const back = () => history.goBack();

  // let { detailsInfo, DetailsInfo, selectedRows } = props
  // const render = ImagePreviewButtonRender(preview);
  // const [car_num, setCarNum] = useState('')
  // const [visible, setVisible] = useState(false)
  // const [info, setInfo] = useState([])
  // const nodeName = ['', '告警通知', '上报扬尘办', '转灰名单', '灰名单延期', '转黑名单', '']
  // useEffect(() => { if (detailsInfo) { setVisible(true) } }, [detailsInfo])
  // useEffect(() => { if (!visible) { DetailsInfo({ visible }) } }, [visible])
  // useEffect(() => { if (selectedRows.length > 0) { setCarNum(selectedRows[0].car_number) } }, [selectedRows])
  // function handleCancel(e) { setVisible(false) };
  // function Circle({ props }) { return <div className={styles.Circle}>{props}</div> }
  // useEffect(() => {
  //   if (!visible) return
  //   request('/web/gb_list/getAlarmDetails', { list_id: selectedRows[0].id })
  //     .then(res => {
  //       setInfo(res.data.data.flows)
  //     })
  // }, [visible])
  // function RightContent({ props, item }) {
  //   let { node_time, name, reason, images } = item
  //   return (
  //     <List bordered style={{ borderRadius: 12 }}>
  //       {
  //         props
  //       }
  //       <List.Item style={{ background: '#1890ff', color: '#fff', borderRadius: '12px 12px 0 0' }}> {nodeName[props]} </List.Item>
  //       {props > 2 ? <List.Item>处理结果<span style={{ float: 'right' }}>{nodeName[props]}</span></List.Item> : null}
  //       {online_time ? <List.Item>灰名单延期<span style={{ float: 'right' }}>{online_time}</span></List.Item> : null}
  //       <List.Item>创建时间<span style={{ float: 'right' }}>{node_time}</span></List.Item>
  //       <List.Item>创建人员<span style={{ float: 'right' }}>{name ? name : '系统默认'}</span></List.Item>
  //       {reason ? <List.Item>上报描述<span style={{ float: 'right' }}>{reason}</span></List.Item> : null}
  //       {images ? <List.Item>证据照片<span style={{ float: 'right' }}><Button type='link' onClick={() => preview.image(images)}>查看</Button></span></List.Item> : null}
  //     </List>
  //   )
  // }

  // const [allIndex, setAllIndex] = useState([])
  // useEffect(() => {
  //   let i = []
  //   for (let a = 1; a < info.length + 1; a++) {
  //     i.push(a)
  //   }
  //   i = i.reverse()
  //   setAllIndex(i)
  // }, [info]);
  return (
    <Modal
      title={'详情'}
      centered
      footer={null}
      visible
      maskClosable={false}
      onCancel={back}
    >
{/* 
      {
        info.length > 0 ?
          <Steps direction="vertical" current={3} className={styles.stepsAll}>
            {
              info.map((item, index) => {
                return <Step key={index} icon={<Circle props={allIndex[index]} />} description={<RightContent props={item.node} item={item} />} />
              })
            }
          </Steps>
          : '暂无数据'
      } */}
    </Modal>
  )
}
export default previewable(Details);
