import React from 'react';
import moment from "moment";
import {Route, Link} from 'react-router-dom';
import { previewable } from 'components/Previewer';
import user from 'stores/user';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';

import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import ImagePreview from 'ui/TableCell/ImagePreviewButtonRender';
import LocationPicker from 'components/Map/LocationPicker';

export const dataSource = new DataSource('/web/car/carInOuts');

function List({ preview }) {
  const render = ImagePreview(preview);
  const columns = [
    serial(true),
    { title: '固定源类型', dataIndex: 'department_name', width: width(5) },
    { title: '固定源名称', dataIndex: 'instance_name', render: (t, { department_id, instance_id }) => <Link to={`./${department_id}/${instance_id}/map`}>{t}</Link> },
    { title: '车牌号', dataIndex: 'car_num', width: width(10) },
    { title: '驾驶时间', dataIndex: 'create_time', width: width(10) },
    { title: '进出类型', dataIndex: 'door_type', width: width(6), align: 'center', render: (t) => (t === 1 ? <span>进</span> : <span>出</span>) },
    { title: '车牌照片', dataIndex: 'pic', width: width(5), render, align: 'center' },
    { title: '车身照片', dataIndex: 'pic_path', width: width(5), render, align: 'center' },
    { title: '运企', dataIndex: 'transport_name', },
    { title: '所属地区', dataIndex: 'area_name', width: width(7) },
  ];

  const area = user.getState().info.area_name;
  const querys = [
    { title: '关键字', key: 'keyword', style: { width: 120 } },
    { title: `是否${area}短名录车`, key: 'short', type: 'select', options: [{ value: 1, label: `${area}短名录车` }, { value: 2, label: `非${area}短名录车` }], style: { width: 180 } },
    { title: '固定源类型', key: 'types', type: 'select', options: [{value: 1, label: '工地'}, {value: 2, label: '商砼'}, {value: 3, label: '砂石厂'}], style: { width: 120 } },
    { title: '进出类型', key: 'door_type', type: 'select', options: [{value: 1, label: '进'}, {value: 2, label: '出'}], style: { width: 120 } },
    { title: ['时间', '范围'], key: 'times', type: 'date-range', showTime: true },
  ];
/*  { title: '区(市)县', key: 'area_id', type: 'select', options: '/web/car/areaList', valueKey: 'id', labelKey: 'name', showSearch: true, style: { width: 120 } },*/
  const buttons = [
    { title: '导出', download: '/web/car/carInOutsExcel' },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
      defaultQuery={{
        times: [
          moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        ],
      }}
    />
  );
}

const previewList =  previewable(List);

export default function CarInOut ({path}) {
  return (
    <>
      <Route path={path} component={previewList} />
      <Route path={path + ':department_id/:id/map'} component={LocationPicker('/web/car/instanceAddress','')} />
    </>
  )
}

