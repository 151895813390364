import React, { useContext } from 'react';
import debug from 'utils/debug';
import PreviewerContext from './Context';

export default function previewable(Component) {
  function Previewable(props) {
    debug.render('Previewable');
    const previewFns = useContext(PreviewerContext);
    return <Component {...props} preview={previewFns} />;
  }
  return Previewable
  // return (React.memo(Previewable, () => true));
}
