import React, { useState, useMemo } from 'react';
import ModalFormAddPage from 'ui/ModalFormAddPage';
import debug from 'utils/debug';
import controller from './controller';
import request from "utils/request";
import {InputNumber, message} from "antd";
import store from 'stores/user';
import { outInstanceIds } from './List';

const ReasonOptions = [
  {value: '泥巴', label: '泥巴'},
  {value: '垃圾', label: '垃圾'},
  {value: '未装满', label: '未装满'},
  {value: '其他', label: '其他'},
];

const inputs1 = [
  {
    label: '起点',
    children: [
      // { placeholder: '区域', key: 'start_area_id', hide: true, type: 'select', options: '/web/Gps_Fault/area_list', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
      { placeholder: '起点部门', key: 'start_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
      { placeholder: '起点', key: 'start_id', type: 'select', options: '/web/instance/getBuildingAdd', extraData: { types: 1 }, valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['start_department_id'], reliesAs:['id'], required: true, style: { width: 240 } },
      { placeholder: '运输车', key: 'car_num', type: 'select', options: '/web/Car/getCarList', realTimeSearch: 'keywords', valueKey: 'car_num', labelKey: 'car_num', relies: ['start_department_id', 'start_id'], reliesAs: ['id', 'instance_id'], required: true, style: { width: 130 } },
      { placeholder: '司机', key: 'driver_id', type: 'select', options: '/web/Driver/getDriverByCarNum', valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'car_num', style: { width: 120 } },
    ],
  },
  { label: '开单时间', key: 'start_time', type: 'date', showTime: true, required: true },
  {
    label: '终点',
    children: [
      { placeholder: '终点部门', value: 126, key: 'end_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
      { placeholder: '终点', value: 1, key: 'end_id', type: 'select', options: '/web/instance/getBuilding', extraData: { types: 2 }, valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'end_department_id', relyAs: 'id', required: true, style: { width: 240 } },
    ],
  },
  { label: '入库时间', key: 'end_time', type: 'date', showTime: true, required: true },
  { label: '收货人', key: 'acquirer_id', type: 'select', options: '/web/user/getUsers', valueKey: 'id', labelKey: 'name', showSearch: true, required: true, style: { width: 195 } },
  {
    label: '物料',
    children: [
      { placeholder: '物料', key: 'materiel_id', type: 'select', options: '/web/route/initMateriel', valueKey: 'id', labelKey: 'name', required: true, style: { width: 195 } },
    ],
  },
  { label: '方量',
      children: [
        { placeholder: '货箱方量', key: 'car_volumn', disabled: true, options: '/web/ticket/getCarSaleVolume', precision: 2, rely: 'car_num', type: 'number-options', style: { width: 165 } },
        { placeholder: '标记方量', key: 'marked_volumn', disabled: true, hasZero: true, precision: 2, type: 'number-options', relyKey: 'car_volumn', disabled: false, style: { width: 165 } },
        { placeholder: '实收方量', key: 'volumn', type: 'number-options', disabled: true, precision: 2, style: { width: 165 }, maxValueRely: 'car_volumn', required: false },
        { placeholder: '选择', key: 'volumn_sel', type: 'volumn-sel' }
      ]
    },
  { label: '扣方原因', key: 'reason', type: 'radioVolume', options: ReasonOptions, showOrHide: 'deduction_volume', hide: true, required: true },
  { label: '补录原因', key: 'added_reason', type: 'textarea' },
];

export default function Create({ history, location, path, ...props }) {
  const { params } = location.state;
  const roleId = store.getState().roles.id;
  const [materiels, setmateriels] = useState([
    {id: 1, name: '连砂石'},{id: 2, name: '成品砂石'},{id: 3, name: '混凝土'},{id: 4, name: '弃土'},
    {id: 6, name: '泥夹石'},
    {id: 7, name: '装修垃圾'},
    {id: 8, name: '其它'},
    {id: 9, name: '盾构料'}
  ]);
  // useEffect(() => {
  //   request('/web/route/initMateriel', { })
  //       .then(body => body.data.data)
  //       .then(pairs => setmateriels(pairs))
  // }, []);
  const inputs = useMemo(() => {
    
    return outInstanceIds.includes(params.end_id) ? [
      {
        label: '起点',
        children: [
          { placeholder: '起点部门', key: 'start_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
          { placeholder: '起点', key: 'start_id', type: 'select', options: '/web/instance/getBuildingAdd', extraData: { types: 1 }, valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['start_department_id'], reliesAs:['id'], required: true, style: { width: 240 } },
          { placeholder: '运输车', key: 'car_num', type: 'select', options: '/web/Car/getCarList', realTimeSearch: 'keywords', valueKey: 'car_num', labelKey: 'car_num', relies: ['start_department_id', 'start_id'], reliesAs: ['id', 'instance_id'], required: true, style: { width: 130 } },
          { placeholder: '司机', key: 'driver_id', type: 'select', options: '/web/Driver/getDriverByCarNum', valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'car_num', style: { width: 120 } },
        ],
      },
      { label: '开单时间', key: 'start_time', type: 'date', showTime: true, required: true },
      {
        label: '终点',
        children: [
          { placeholder: '终点部门', value: 126, key: 'end_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
          { placeholder: '终点', value: 1, key: 'end_id', type: 'select', options: '/web/instance/getBuilding', extraData: { types: 2 }, valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'end_department_id', relyAs: 'id', required: true, style: { width: 240 } },
        ],
      },
      { label: '入库时间', key: 'end_time', type: 'date', showTime: true, required: true },
      { label: '收货人', key: 'acquirer_id', type: 'select', options: '/web/user/getUsers', valueKey: 'id', labelKey: 'name', showSearch: true, required: true, style: { width: 195 } },
      {
        label: '物料',
        children: [
          { placeholder: '物料', key: 'materiel_id', type: 'select', options: materiels, valueKey: 'id', labelKey: 'name', required: true, style: { width: 195 } },
        ],
      },
      { label: '方量',
          children: [
            { placeholder: '货箱方量', key: 'car_volumn', disabled: true, options: '/web/ticket/getCarSaleVolume', precision: 2, rely: 'car_num', type: 'number-options', style: { width: 165 } },
            { placeholder: '标记方量', key: 'marked_volume', disabled: true, precision: 2, type: 'number-options', style: { width: 165 } },
            { placeholder: '实收方量', key: 'volumn', type: 'number-options', disabled: true, precision: 2, style: { width: 165 }, maxValueRely: 'car_volumn', required: false },
            { placeholder: '选择', key: 'volumn_sel', type: 'volumn-sel' }
          ]
        },
      // { label: '扣方原因', key: 'reason', type: 'radioVolume', options: ReasonOptions, showOrHide: 'deduction_volume', hide: true, required: true },
      { label: '补录原因', key: 'added_reason', type: 'textarea' },
      // { label: '', key: 'ht_dd', type: 'eleVolume', required: false },
      // { label: '电子出门条', key: 'human_ticket_id', type: 'electronStrip', required: true }
    ] : roleId === 118 ? [
      {
        label: '起点',
        children: [
          // { placeholder: '区域', key: 'start_area_id', hide: true, type: 'select', options: '/web/Gps_Fault/area_list', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
          { placeholder: '起点部门', key: 'start_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
          { placeholder: '起点', key: 'start_id', type: 'select', options: '/web/instance/getBuildingAdd', extraData: { types: 1 }, valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['start_department_id'], reliesAs:['id'], required: true, style: { width: 240 } },
          { placeholder: '运输车', key: 'car_num', type: 'select', options: '/web/Car/getCarList', realTimeSearch: 'keywords', valueKey: 'car_num', labelKey: 'car_num', relies: ['start_department_id', 'start_id'], reliesAs: ['id', 'instance_id'], required: true, style: { width: 130 } },
          { placeholder: '司机', key: 'driver_id', type: 'select', options: '/web/Driver/getDriverByCarNum', valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'car_num', style: { width: 120 } },
        ],
      },
      { label: '开单时间', key: 'start_time', type: 'date', showTime: true, required: true },
      {
        label: '终点',
        children: [
          { placeholder: '终点部门', value: 126, key: 'end_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
          { placeholder: '终点', value: 1, key: 'end_id', type: 'select', options: '/web/instance/getBuilding', extraData: { types: 2 }, valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'end_department_id', relyAs: 'id', required: true, style: { width: 240 } },
        ],
      },
      { label: '入库时间', key: 'end_time', type: 'date', showTime: true, required: true },
      { label: '收货人', key: 'acquirer_id', type: 'select', options: '/web/user/getUsers', valueKey: 'id', labelKey: 'name', showSearch: true, required: true, style: { width: 195 } },
      {
        label: '物料',
        children: [
          { placeholder: '物料', key: 'materiel_id', type: 'select', options: materiels, valueKey: 'id', labelKey: 'name', required: true, style: { width: 195 } },
        ],
      },
      { label: '方量',
          children: [
            { placeholder: '货箱方量', key: 'car_volumn', disabled: true, options: '/web/ticket/getCarVolumn', precision: 2, rely: 'car_num', type: 'number-options', style: { width: 165 } },
            { placeholder: '扣方', min: 0, step: 0.1, hasZero: true, key: 'deduction_volume', precision: 2, type: 'number-options', disabled: false, style: { width: 165 } },
            { placeholder: '实际方量', key: 'volumn', type: 'number-options', precision: 2, style: { width: 165 }, maxValueRely: 'car_volumn', required: true }
          ]
        },
      { label: '扣方原因', key: 'reason', type: 'radioVolume', options: ReasonOptions, showOrHide: 'deduction_volume', hide: true, required: true },
      { label: '补录原因', key: 'added_reason', type: 'textarea' },
      
    ] : [
      {
        label: '起点',
        children: [
          // { placeholder: '区域', key: 'start_area_id', hide: true, type: 'select', options: '/web/Gps_Fault/area_list', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
          { placeholder: '起点部门', key: 'start_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
          { placeholder: '起点', key: 'start_id', type: 'select', options: '/web/instance/getBuildingAdd', extraData: { types: 1 }, valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['start_department_id'], reliesAs:['id'], required: true, style: { width: 240 } },
          { placeholder: '运输车', key: 'car_num', type: 'select', options: '/web/Car/getCarList', realTimeSearch: 'keywords', valueKey: 'car_num', labelKey: 'car_num', relies: ['start_department_id', 'start_id'], reliesAs: ['id', 'instance_id'], required: true, style: { width: 130 } },
          { placeholder: '司机', key: 'driver_id', type: 'select', options: '/web/Driver/getDriverByCarNum', valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'car_num', style: { width: 120 } },
        ],
      },
      { label: '开单时间', key: 'start_time', type: 'date', showTime: true, required: true },
      {
        label: '终点',
        children: [
          { placeholder: '终点部门', value: 126, key: 'end_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
          { placeholder: '终点', value: 1, key: 'end_id', type: 'select', options: '/web/instance/getBuilding', extraData: { types: 2 }, valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'end_department_id', relyAs: 'id', required: true, style: { width: 240 } },
        ],
      },
      { label: '入库时间', key: 'end_time', type: 'date', showTime: true, required: true },
      { label: '收货人', key: 'acquirer_id', type: 'select', options: '/web/user/getUsers', valueKey: 'id', labelKey: 'name', showSearch: true, required: true, style: { width: 195 } },
      {
        label: '物料',
        children: [
          { placeholder: '物料', key: 'materiel_id', type: 'select', options: materiels, valueKey: 'id', labelKey: 'name', required: true, style: { width: 195 } },
        ],
      },
      { label: '方量',
          children: [
            { placeholder: '货箱方量', key: 'car_volumn', disabled: true, options: '/web/ticket/getCarVolumn', precision: 2, rely: 'car_num', type: 'number-options', style: { width: 165 } },
            { placeholder: '扣方', min: 0, step: 0.1, hasZero: true, key: 'deduction_volume', precision: 2, type: 'number-options', disabled: false, style: { width: 165 } },
            { placeholder: '实际方量', key: 'volumn', type: 'number-options', precision: 2, style: { width: 165 }, maxValueRely: 'car_volumn', required: false }
          ]
        },
      { label: '扣方原因', key: 'reason', type: 'radioVolume', options: ReasonOptions, showOrHide: 'deduction_volume', hide: true, required: true },
      { label: '补录原因', key: 'added_reason', type: 'textarea' },
      { label: '', key: 'ht_dd', type: 'eleVolume', required: false },
      { label: '电子出门条', key: 'human_ticket_id', type: 'electronStrip', required: true }
    ]
  }, [materiels]);
  
  debug.render('Create');
  const back = () => history.goBack();
  const onFinish = (data, type) => {
    const second = JSON.parse(sessionStorage.getItem('secondReceiver')) ? JSON.parse(sessionStorage.getItem('secondReceiver')).name : '';
    if(data.reasonVolumnKey){
      data.reason = data.reasonVolumnKey;
    }
    if(!data.volumn){
      data.volumn = parseFloat(parseFloat(data.car_volumn) - (data.deduction_volume ? parseFloat(data.deduction_volume).toFixed(2) : 0)).toFixed(2);
    }
    request('/web/ticket/add', {...data, second_receiver: second, transport_id: '0'})
      .then(body => {
        if(body.data.code !== 200){
          message.warn(body.data.msg)
        }else{
          const d = body.data.data;
          sessionStorage.setItem('ticketAddAndPrint', JSON.stringify({
            types: type,
            ticket_id: d.ticket_id,
            car_type: d.car_type,
            car_directory: d.car_directory,
            car_plan: d.car_plan,
            number: d.number,
            transport_count: d.transport_count,
            building_id: data.start_id,
            car_num: data.car_num,
            volume: d.car_volumn,
            reason: (data.deduction_volume === 0 || data.deduction_volume === '0.0' || data.deduction_volume === '0.00' || data.deduction_volume === 0.0) ? '' : data.reason,
            driver_name: d.driver_name,
            build_name: d.start_name,
            real_volumn: d.volumn,
            deduction_volume: data.deduction_volume,
            materielName: d.materiel_name,
            marked_volumn: data.marked_volume,
            date: d.date
          }))
          controller.refresh();
          back();
        }
      })
  };
 
  return (
    <ModalFormAddPage
      defaultValue={{ ...params, human_ticket_id: 0, quantities_check_status: 1 }}
      title="补录电子联单"
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
      modalProps={{
        width: 900,
      }}
      extraParams={params}
      path={location.pathname}
      history={history}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}
    />
  );
}
