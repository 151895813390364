import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import { dataSource } from './List';

const inputs = [
  { label: '', key: 'volumn', type: 'number', required: true, precision: 1, min: 0, max: 100 },
];

export default function VolumnEditor(props) {
  console.log(props);
  const id = props.match.params.id;
  const car_num = new URLSearchParams(props.location.search).get('car_num');
  const back = () => props.history.goBack();
  const onFinish = () => {
    dataSource.refresh();
    back();
  };

  return (
    <ModalFormPage
      init={['/web/car/sandCarDirectoryEcho', { id }, volumn => ({ volumn })]}
      submit={['/web/car/sandCarDirectoryUpdate', { id }]}
      title={`修改【${car_num}】方量`}
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
    />
  );
}