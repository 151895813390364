import React from 'react';
import { Divider } from 'antd';
import { previewable } from 'components/Previewer';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import NewDeleteButton from 'ui/TableCell/NewDeleteButton';
import LinkButton from 'ui/TableCell/LinkButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';

export const dataSource = new DataSource('/river/river_project/getRiverProjectLists');

export const inputs = [
  { label: '项目名称', placeholder: '请填写项目名称', key: 'name', required: true },
  { label: '项目开始日期', placeholder: '请选择项目开始日期', key: 'begin_date', type: 'new-date-range', required: true },
  { label: '项目截止日期', placeholder: '请选择项目截止日期', key: 'end_date', type: 'new-date-range', required: true },
];

function List({ priview }) {
  const CarEffect = [
    { label: '是', value: 1 },
    { label: '否', value: 2 },
  ];
  const columns = [
    serial(true),
    { title: '项目名称', dataIndex: 'name' },
    { title: '项目开始日期', dataIndex: 'begin_date' },
    { title: '项目截止日期', dataIndex: 'end_date' },
    { title: '更新时间', dataIndex: 'updated_time' },
    { title: '更新人', dataIndex: 'updated_name', width: width(6) },
    actions({
      width: width(22),
      render: ($, {id}) => (
        <>
          <LinkButton to={`./${id}/update`} />
          <Divider type="vertical" />
          <LinkButton to={`/basic-information/points/`} children="配置点位" />
          <Divider type="vertical" />
          <LinkButton to={`/basic-information/project-car/`} children="配置车辆" />
          <Divider type="vertical" />
          <NewDeleteButton title="确认删除该项目信息?" type="danger" api="/river/river_project/deleteRiverProjectList" river_project_id={id} dataSource={dataSource}>删除</NewDeleteButton>
        </>
      )
    })
  ];

  const querys = [
    { title: ['开始', '时间'], key: 'begin_times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
    { title: ['截止', '时间'], key: 'end_times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
    { title: '请输入项目名称、更新人模糊搜索', key: 'keyword', style: { width: 360 } },
  ];

  const buttons = [
    { title: '新增', link: './create', icon: 'plus'},
    { title: '导出', download: '/river/river_project/exportRiverProjectLists' },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}

export default previewable(List)
