/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Section, Item } from 'components/Structure';
import styles from './Section.scss';

export default function BaseSection({ preview, data }) {
  const previewImage = srcs => () => {
    if (!srcs) return;
    preview.image(srcs);
  };

  const Image = ({ srcs }) => {
    const [first] = (srcs || '').split(',');
    return <p onClick={previewImage(srcs)} style={{ backgroundImage: `url(${first})` }} />;
  };

  return (
    <Section title="商砼站信息" buttonName="编辑" buttonLink="./base/update">
      <Item title="基本信息">
        <div>
          <p><strong>{data.name}</strong></p>
          <p>{data.desc}</p>
        </div>
      </Item>
      <Item title="法定代表人">
        <p><strong>{data.corporation}</strong></p>
        <p>{data.corporation_phone}</p>
      </Item>
      <Item title="负责人">
        <p><strong>{data.leader}</strong></p>
        <p>{data.leader_phone}</p>
      </Item>
      <Item title="注册信息">
        <div>
          <p><strong>所属区域：</strong>{data.area_id}</p>
          <p><strong>注册资金：</strong>{data.register_money}</p>
          <p><strong>注册地址：</strong>{data.address}</p>
          <p><strong>注册时间：</strong>{data.register_time}</p>
        </div>
        <div>
          {/* <p><strong>资质编号：</strong>{data.qua_num}</p> */}
          {/* <p><strong>标准资质名称：</strong>{data.stand_qua_name}</p> */}
          <p><strong>统一社会信用代码：</strong>{data.credit_code}</p>
          <p><strong>道路运输经营许可证编号：</strong>{data.road_transport_license}</p>
        </div>
      </Item>
      <Item title="详细信息">
        <div>
          <p><strong>邮政编码：</strong>{data.zip_code}</p>
          <p><strong>办公电话：</strong>{data.office_phone}</p>
          <p><strong>办公面积：</strong>{data.office_acreage}</p>
          <p><strong>办公地址：</strong>{data.office_address}</p>
          <p><strong>停车场地址：</strong>{data.park_address}</p>
        </div>
        <div>
          <p><strong>核准年份：</strong>{data.approved_year}</p>
          {/* <p><strong>专营区域：</strong>{data.franchise_area}</p> */}
          <p><strong>营业期限开始：</strong>{data.business_start_date}</p>
          <p><strong>营业期限截止：</strong>{data.business_forever ? '永久' : data.business_end_date}</p>
        </div>
      </Item>
      <Item title="相关证照">
        <div className={styles.cover}><span><Image srcs={data.business_license_photo} /><strong>工商营业执照</strong></span></div>
        <div className={styles.cover}><span><Image srcs={data.road_trans_op_permit} /><strong>道路运输经营许可证</strong></span></div>
        <div className={styles.cover}><span><Image srcs={data.legal_id_card_photo} /><strong>法定代表人身份证</strong></span></div>
      </Item>
    </Section>
  );
}
