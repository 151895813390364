/* eslint-disable no-shadow */
// 关于 onChange 只出发一次的解决办法
// https://github.com/ant-design/ant-design/issues/2423#issuecomment-233523579
import React from 'react';
import { Upload } from 'antd';
import { upload } from 'utils/request';

const Uploader = ({ onChange, fileList, ...rest }) => (
  <Upload
    {...rest}
    fileList={[...fileList]}
    onChange={({ fileList }) => onChange(fileList)}
    customRequest={({ file, filename, onSuccess, onError }) => {
      const formData = new FormData();
      formData.append(filename, file);

      upload(formData).then((res) => {
        const { data: src, msg } = res.data || {};
        if (res.status === 200 && src) {
          onSuccess({ src });
        } else {
          onError(msg);
        }
      }).catch(console.error);
    }}
  />
);

export default Uploader;
