export function UseMapScatter({ data=[], type='scatter', symbol='circle', symbolSize=10, zlevel=2, name='plansStopPoints', tooltip, label={normal: {show: false}}, color='#1491fb', opacity=1, symbolRotate} ){
  return {
    type,
    coordinateSystem: 'bmap', // 使用的坐标系
    symbol, // 标记的图形
    symbolSize, // 图形的大小
    symbolRotate, // 标记的旋转角度  symbolOffset [0, 0] 标记相对于原本位置的偏移 【左， 上】
    zlevel, // 层级
    // large: true, //是否开启大数据量优化，在数据图形特别多而出现卡顿时候可以开启。
    // largeThreshold: 200  //开启绘制优化的阈值。
    rippleEffect: {
      brushType: 'stroke',
      period: 4,
      scale: 2,
    },
    showEffectOn: 'render', // 何时显示特效 是 effectScatter 属性
    name,
    tooltip, // 鼠标移入时的样式
    label, // 文本标签
    itemStyle: { // 图形样式 emphasis - 高亮的时候的样式
      normal: {
        color,
        opacity,
      },
    },
    data:data,
  }
}

export function UseMapAnchorage({ data=[], symbol, symbolSize, offsetArr, zlevel=2, name, label, color }) {
  return {
    type: 'scatter',
    coordinateSystem: 'bmap',
    zlevel,
    rippleEffect: {
      brushType: 'stroke',
    },
    name,
    label: {
      normal: {
        show: false
      },
    },
    itemStyle: {
      normal: {
        color,
        opacity: 1,
      },
    },
    tooltip: {
      formatter: function(data) {
        const features = ['', '砖厂', '瓦片厂', '玻璃厂', '水泥厂', '其他',]
        const d = data.data, c = d.consum_type, p = d.point_type, t = d.time_status, f = d.features;
        return (
          c === 0 ? `${d.area_name + ' | ' + d.name}<br />点位定性：待排查<br />发现时间：${d.create_time}` :
            p === 1 ? `${d.area_name + ' | ' + d.name}<br />点位定性：疑似点位<br />发现时间：${d.create_time}<br />其他信息：${d.other_info}` :
            p === 2 ? `${d.area_name + ' | ' + d.name}<br />点位定性：${c === 1 ? '非法点位' : '合法点位'}      点位特征： 工地<br />结案时间：${d.create_time}<br />有效时间：${t === 1 ? '无时间期限' : d.begin_time + '至' + d.end_time} ` :
            p === 3 ? `${d.area_name + ' | ' + d.name}<br />点位定性：${c === 1 ? '非法点位' : '合法点位'}      点位特征： 砂石厂<br />结案时间：${d.create_time}<br />有效时间：${t === 1 ? '无时间期限' : d.begin_time + '至' + d.end_time} ` :
            p === 4 ? `${d.area_name + ' | ' + d.name}<br />点位定性：${c === 1 ? '非法点位' : '合法点位'}      点位特征： 消纳厂<br />结案时间：${d.create_time}<br />有效时间：${t === 1 ? '无时间期限' : d.begin_time + '至' + d.end_time} ` :
            p === 5 ? `${d.area_name + ' | ' + d.name}<br />点位定性：${c === 1 ? '非法点位' : '合法点位'}      点位特征： 商砼站<br />结案时间：${d.create_time}<br />有效时间：${t === 1 ? '无时间期限' : d.begin_time + '至' + d.end_time} ` :
            p === 6 ? `${d.area_name + ' | ' + d.name}<br />点位定性：${c === 1 ? '非法点位' : '合法点位'}      点位特征： 其他点位<br />结案时间：${d.create_time}<br />有效时间：${t === 1 ? '无时间期限' : d.begin_time + '至' + d.end_time} ` : 
            p === 7 ? `${d.area_name + ' | ' + d.name}<br />点位定性：${c === 1 ? '非法点位' : '合法点位'}      点位特征： ${features[f] }<br />结案时间：${d.create_time}<br />有效时间：${t === 1 ? '无时间期限' : d.begin_time + '至' + d.end_time} ` : null
        );
      }
    },
    data: data,
  }
}

export function UseMapInstance({ data=[], symbol, symbolSize, offsetArr, zlevel=2, name, label, color, consum_type = 10 }){
  return {
    type: 'scatter',
    coordinateSystem: 'bmap',
    labelName: 'label',
    symbol,
    symbolSize,
    zlevel,
    rippleEffect: {
      brushType: 'stroke',
    },
    name,
    label: {
      normal: {
        show: true,
        position: 'inside',
        offset: offsetArr,
        color: '#ffffff',
        formatter: function (a){return label},
      },
    },
    itemStyle: {
      normal: {
        color,
        opacity: 1,
      },
    },
    tooltip: {
      formatter: function(data){
        return consum_type === 10 ? data.data.name :( consum_type === 1 ?
          (
            data.data.history_count !== 1 ? `非法点位 - ${data.data.name} <br /> 非法点位 <br /> 历史排查：${data.data.history_count}<br /> 结案时间：${data.data.history_create_time}` :
              `非法点位 - ${data.data.name} <br /> 非法点位 <br /> 结案时间：${data.data.history_create_time}` ) :
          `非法点位 - ${data.data.name} <br /> 疑似点位 <br /> 发现时间：${data.data.create_time}<br />`);
      }
    },
    data: data,
  }
}

export function StartOrEndPoint({data=[], opacity=1, type="scatter", width=40, height=26, symbol='circle', zlevel=9, symbolSize=10, name='start_pointer', tooltip= {show: false}, tooltipLabel='起点', format='起点', backImage, itemColor}){
  return {
    type,
    name,
    symbol,
    symbolSize,
    zlevel: 9,
    coordinateSystem: 'bmap',
    data,
    tooltip,
    label:{
      normal:{
        show : true,
        position: 'top',
        formatter:function(a,b,c){
          return `{arrow|${format}}`
        },
        rich: {
          arrow:{
            color: '#fff',
            fontSize:12,
            width,
            height,
            align:'center',
            padding:[5,0,0,0],
            backgroundColor: {
              image: backImage,
              repeat:'no-repeat'
            },
            shadowBlur: 1,
            shadowColor: '#d4d4d4',
            shadowOffsetX: 1,
            shadowOffsetY: 1,
          }
        }
      }
    },
    itemStyle: {
      normal: {
        color: itemColor,
        opacity,
      }
    }
  }
}

export function UseMapLines({polyline=true, silent=true, lineColor='#1491fb', data=[[], []], lineWidth=2, lineType='solid', symbol='none',
                              effect=false, effectSymbol, trailLength, effectSymbolSize, curveness=0, zlevel=5, tooltip={show: false,}, name='label' }){
  return {
    type: 'lines',
    coordinateSystem:'bmap',
    polyline,
    silent,
    name,
    zlevel,
    data: data,
    symbol,
    effect: {
      show: effect,
      period: 30,
      symbol: effectSymbol,
      symbolSize: effectSymbolSize,
      trailLength: 0,
    },
    tooltip,
    lineStyle: {
      normal:{
        width:lineWidth,
        color: lineColor,
        opacity: 1,
        type: lineType,
      }
    },
    itemStyle: {
      normal: {
        opacity: 1,
      }
    },
  }
}

export function UseMapLinesTooltip({name, polyline=true, silent=true, lineColor='#1491fb', data=[[], []], lineWidth=2, lineType='solid', symbol='none',
                              effect=false, effectSymbol='circle', trailLength=0, constantSpeed=0, effectSymbolSize, curveness=0, tooltip, zlevel=5, label={show: false} }){
  return {
    type: 'lines',
    coordinateSystem:'bmap',
    data: data,
    symbol,
    zlevel,
    name,
    polyline: false,
    effect: {
      show: false,
      period: 2,
      constantSpeed: 0,
      trailLength: 0,
      // color: '#ff0000',
      // symbolSize: 6,
      symbol: effectSymbol
    },
    label,
    tooltip,
    lineStyle: {
      normal:{
        width:lineWidth,
        color: lineColor,
        opacity: 1,
        type: lineType,
        curveness
      }
    },
    itemStyle: {
      normal: {
        opacity: 1,
      }
    },
  }
}

export function PointClass({ className, pointType, siteType=0, onFilter }){
  return (
    <div className={className}>
      <span onClick={(e) => {e.stopPropagation(); onFilter(siteType, pointType, 0)}}>全部点位</span>
      <span onClick={(e) => {e.stopPropagation(); onFilter(siteType, pointType, 1)}}>非法点位</span>
      <span onClick={(e) => {e.stopPropagation(); onFilter(siteType, pointType, 2)}}>合法点位</span>
    </div>
  )
}
