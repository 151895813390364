import React from 'react';
import moment from 'moment';
import request from 'utils/request';
import 'components/css/TableClass.global.scss';
import { Table, Button, Input, DatePicker } from 'antd';
const RangePicker = DatePicker.RangePicker;

const ToFormatDate = function (datetime, method) {
  if(!datetime || datetime==null){
    return '';
  }
  var date = new Date(datetime);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  var d = date.getDate();
  var h = date.getHours();
  var mm = date.getMinutes();
  var s = date.getSeconds();
  m = m < 10 ? '0' + m : m;
  d = d < 10 ? '0' + d : d;
  h = h < 10 ? '0' + h : h;
  mm = mm < 10 ? '0' + mm : mm;
  s = s < 10 ? '0' + s : s;
  if(method === 'YY-MM-DD'){
    return y + '-' + m + '-' + d
  }
  else if(method === 'YYYY-MM-DD HH:mm:ss'){
    return y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + s
  }
  return m + '月' + d + '日'
};
const columns = [
  {
    title: '序号',
    type: 'index',
    dataIndex: 'index',
    key: '序号',
    width: 60,
  }, {
    title: '车牌号',
    dataIndex: 'car_number',
    key: 'car_number',
    width: 120
  }, {
    title: '事故时间',
    dataIndex: 'start_time',
    key: 'start_time',
    width: 200
  }, {
    title: '事故地点',
    dataIndex: 'acci_address',
    key: 'acci_address',
    width: 200
  }, {
    title: '驾驶员',
    dataIndex: 'acci_driver',
    key: 'acci_driver',
  }, {
    title: '车型',
    dataIndex: 'car_type',
    key: 'car_type',
  }, {
    title: '死亡人数',
    dataIndex: 'death_num',
    key: 'death_num',
  }, {
    title: '受伤人数',
    dataIndex: 'injury_num',
    key: 'injury_num',
  }, {
    title: '责任认定',
    dataIndex: 'acci_type',
    key: 'acci_type',
  }, {
    title: '确认时间',
    dataIndex: 'end_time',
    key: 'end_time',
    width: 200
  }, {
    title: '车辆所属单位',
    dataIndex: 'transport_name',
    key: 'transport_name',
  }];
const start_time = new Date() - 24 * 60 * 60 * 1000;
const end_time = new Date();
export default class VehicleAccidentInformation extends React.PureComponent{
  constructor(props){
    super(props);
    this.state = {
      dataSource: [],
      keyword: '',
      start_time: [ToFormatDate(start_time , 'YYYY-MM-DD HH:mm:ss'), ToFormatDate(end_time, 'YYYY-MM-DD HH:mm:ss')],
      currentPage: 1,
      limit: 10,
      totalRow: 1,
    }
  };
  getInitData = (e) => {
    console.log(e)
    let that = this;
    let json = {
      keyword: that.state.keyword,
      start_time: that.state.start_time,
      limit: e ? e.pageSize : that.state.limit,
      page: e ? e.current : that.state.currentPage
    };
    let dataSource = [];
    request({
      url: '/web/Car/showAccidentList',
      data: json
    }).then((res) => {
      console.log(res);
      dataSource = res.data.data.data.map((row, index) => ({
        key: row.id,
        index: (index + 1) + (that.state.currentPage - 1) * that.state.limit,
        ...row,
      }));
      that.setState({
        dataSource: dataSource,
        totalRow: res.data.data.total,
        limit: e.pageSize,
        currentPage: e.current
      })
    })
  };
  inputChange = (e) => {
    e.persist();
    console.log(e)
    this.setState({
      keyword: e.target.value
    })
  };
  dateChange = (date, dateString) => {
    console.log(date, dateString)
    this.setState({
      start_time: dateString
    })
  };
  getSearchData = () => {
    this.setState({
      currentPage: 1
    });
    this.getInitData({current: 1, pageSize: this.state.limit})
  };
  componentDidMount () {
    this.getInitData({current: this.state.currentPage, pageSize: this.state.limit});
  }
  render () {
    const state = {...this.state};
    return (
      <div className="car-table-part">
        <div className="table-class">
          {/* <div className="flex flex-main-end down-or-upload">
            <Button type='primary' onClick={() => {this.DownCarIllegal()}}>下载车辆违法</Button>
            <Button style={{marginLeft: 10}} type='primary' onClick={() => {this.UploadCarIllegal()}}>上传违法信息</Button>
          </div>*/}
          <div className="flex flex-main-end search-part">
            <Input onChange={this.inputChange} placeholder='请输入车牌、地点搜索' />
            <RangePicker showTime defaultValue={[moment(new Date(start_time) , 'YYYY-MM-DD HH:mm:ss'), moment(end_time, 'YYYY-MM-DD HH:mm:ss')]} format={'YYYY-MM-DD HH:mm:ss'} onChange={this.dateChange} />
            <Button type="primary" onClick={ () => {this.getSearchData()}}>搜索</Button>
          </div>
        </div>
        <Table style={{height: 'calc(100% - 50px)'}} columns={columns} dataSource={state.dataSource}
               bordered
               pagination={{
                 current: state.currentPage,
                 pageSize: state.limit,
                 total: state.totalRow,
                 showTotal: () => {return `共${state.totalRow}条`}
               }}
               onChange={this.getInitData}
        />
      </div>
    )
  }
}
