// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RKmgGGHdmkDkM8uh5Yg7a {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 14px;\n}\n.RKmgGGHdmkDkM8uh5Yg7a ._3T9A4NI4co1WSJjMt9uYse {\n  margin-left: 14px;\n}\n\n.p8e5VwyfamEdVGZybY75E {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 14px;\n}", ""]);
// Exports
exports.locals = {
	"adder": "RKmgGGHdmkDkM8uh5Yg7a",
	"addBtn": "_3T9A4NI4co1WSJjMt9uYse",
	"info": "p8e5VwyfamEdVGZybY75E"
};
module.exports = exports;
