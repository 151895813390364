// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Ni13cIhPQ7qM7Mpl7erjy {\n  display: none;\n}\n\n._1igWKNoZmw4PlhIYhzU_nT {\n  padding: 10px;\n  box-sizing: border-box;\n}\n\n.UEG2yx0GE8-v3SnbMysaE {\n  text-align: center;\n  border-bottom: 2px solid #000;\n  margin-bottom: 5px;\n  font-weight: bold;\n}\n\n._2v1NLkpknekYhqCQjuTobV {\n  border-top: 2px solid #000;\n  padding-top: 10px;\n  padding-bottom: 10px;\n}", ""]);
// Exports
exports.locals = {
	"content": "_3Ni13cIhPQ7qM7Mpl7erjy",
	"print": "_1igWKNoZmw4PlhIYhzU_nT",
	"header": "UEG2yx0GE8-v3SnbMysaE",
	"summary": "_2v1NLkpknekYhqCQjuTobV"
};
module.exports = exports;
