// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2u5MSRKUdQ9jzK38FJ4d3M {\n  padding: 6px 0;\n}\n._2u5MSRKUdQ9jzK38FJ4d3M > p {\n  color: #1491FB;\n  margin-bottom: 10px;\n}", ""]);
// Exports
exports.locals = {
	"content": "_2u5MSRKUdQ9jzK38FJ4d3M"
};
module.exports = exports;
