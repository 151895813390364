// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".xBEK3rb8clUJ0vmtjm1Z_ {\n  cursor: pointer;\n  position: relative;\n  color: #8e8e93;\n}\n.xBEK3rb8clUJ0vmtjm1Z_:hover {\n  color: #1e88e5;\n}", ""]);
// Exports
exports.locals = {
	"container": "xBEK3rb8clUJ0vmtjm1Z_"
};
module.exports = exports;
