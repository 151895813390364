import React from 'react';
import { Route } from 'react-router-dom';
import List from './List';
import Create from './Create';
import Update from './Update';

export default ({ path }) => (
  <>
    <Route path={path} component={List} />
    <Route path={path + 'create'} component={Create} />
    <Route path={path + ':id/update'} component={Update} />
  </>
);
