import React from 'react';
import ReactDOM from 'react-dom';
import '../ToBeConfirmed/index.global.scss';
import store from 'stores/user';
import request, { open } from 'utils/request';
import {
  Input,
  Table,
  DatePicker,
  Select,
  Button,
  Modal,
  Icon,
  Tooltip,
  Pagination,
  message,
  Spin,
  Divider
} from 'antd';
import moment from 'moment'
import LinkButton from 'ui/TableCell/LinkButton';
import { Route } from 'react-router-dom';
import Update from './Update';
import controller from "./controller";

const { confirm } = Modal;
const {RangePicker} = DatePicker;
const {Option} = Select;
let start_time = moment().subtract(0, 'day').startOf('day');
let end_time = moment().endOf('day');
let LODOP = null;
let selectPrinter = null;

const date = new Date();
const CreateOneFormPage = function (type) {
  LODOP = getLodop();
  LODOP.PRINT_INIT("打印控件功能演示_Lodop功能_表单一");
  LODOP.SET_PRINT_STYLE("FontSize", 18);
  LODOP.SET_PRINT_STYLE("Bold", 1);
  LODOP.SET_PRINT_PAGESIZE(1, 2160, 930, '');
  LODOP.SET_PRINT_MODE("POS_BASEON_PAPER", true);
  //LODOP.ADD_PRINT_TEXT(50, 231, 260, 39);
  var strStyleCSS = '<link href="/_flex.min.css" type="text/css" rel="stylesheet">';
  var strFormHtml = '<head>' + strStyleCSS + '</head><body><div style="width:750px;">' + document.getElementById("print_t1").innerHTML + '</div></body>';
  LODOP.ADD_PRINT_HTM(25, 25, 150, "BottomMargin:0", strFormHtml);
};

// const oldInstance = [1784, 2999, 3135, 3292, 3323, 2254, 3324, 1770, 3295, 3325, 2218, 3297, 3298, 3326, 3300, 3301, 3302, 3250, 3303, 3142, 3304, 3327, 3308, 3105, 3005, 3088, 3328, 3329, 3309, 3314, 2994, 3210, 3222, 3205, 3221, 3317, 3223, 3321, 3225, 3322, 3056, 2417, 3573, 3214, 7033, 3212];
const oldInstance = [1198,1506,1549,1565,1576,1585,1726,1730,1770,1784,1988,2218,2220,2254,2346,2357,2358,2417,2433,2463,2464,2624,2659,2755,2811,2880,2994,2996,2999,3002,3005,3065,3095,3102,3105,3106,3107,3108,3109,3120,3121,3135,3142,3203,3210,3212,3213,3222,3250,3266,3273,3282,3295,3296,3298,3301,3302,3308,3309,3314,3323,3324,3325,3326,5588,5675,5703,5884,6294,6359,6920,7032, 7033, 7637, 7638,7639,7644,7656,7657,7665,7819,7820,7821,7823, 5894];

const isInArray = function (arr, value) {
  for (var i = 0; i < arr.length; i++) {
    if (value === arr[i]) {
      return true;
    }
  }
  return false;
};

const printOption = [
  {id: 0, name: '未打票'},
  {id: 1, name: '已打票'}
];

const carTypeOptions = [
  {id: 1, name: '名录车'},
  {id: 2, name: '非名录车'},
  {id: 3, name: '合同车'},
  {id: 4, name: '非合同车'},
  {id: 5, name: '车载车'},
  {id: 6, name: '非车载车'}
];

export default class SandStoneReceiver extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      autoCompleteResult: [],
      checkedList: [],
      dataSource: [],
      reason: false,
      keyword: '',
      cancel: '',
      limit: 10,
      page: 1,
      total: 0,
      start_volumn: 0,
      deduction_volume: 0,
      total_volume: 0,
      time: [start_time.format('YYYY-MM-DD HH:mm:ss'), end_time.format('YYYY-MM-DD HH:mm:ss')],
      receiverName: store.getState().info.name,
      userAreaId: store.getState().info.area_id,      
      car_num: '',
      printCompany: '成都宏图华信投资有限公司',
      printJson: {
        number: '',
        car_num: '',
        transport_count: 0,
        volume: 0,
        reason: '',
        driver_name: '',
        build_name: '',
        real_volumn: 24,
        deduction_volume: 0,
        date: '',
        car_directory: '',
        car_plan: '',
        deduction_reason: '',
        car_type: '',
        materielName: '连砂石'
      },
      buildingArr: [],
      building_id: '',
      userInstance: [],
      instance_id: '选定一个实例',
      department_id: '',
      instance_name: '',
      materielType: 1,
      modal_flag: false,
      secondReceiver: JSON.parse(sessionStorage.getItem('secondReceiver')) ? JSON.parse(sessionStorage.getItem('secondReceiver')).name : '',
      printing_count: '',
      license: '',
      selectedRowKeys: [],
      rowSelection: {
        onChange: this.onSelectChange,
        selections: [],
      },
      spinLoading: false,
      order: new Map([['end_time', {name: "end_time", value: "asc"}]]), // 排序相关的
    }
  };
  columns = [
    {title: '序号', dataIndex: 'serial', key: 'serial', width: 70},
    {title: '联单号', dataIndex: 'number', key: 'number'},
    {title: '车牌号', dataIndex: 'car_num', key: 'car_num', width: 110},
    {title: '车辆类型', dataIndex: 'car_type', key: 'car_type', width: 100, render: (v, t) => <span style={{color: (v.includes('非名录') || v.includes('非合同')) ? '#FE9400' : v === '非砂石名录' ? '#FE9400' : v === '合同' ? '#4BD863' : '#0079FE'}}>{v}</span>},
    {title: '工地项目', dataIndex: 'building_name', key: 'building_name'},
    {title: '出场审核人', dataIndex: 'startConfirmName', key: 'startConfirmName'},
    {title: '入倒场-加工商收货人', dataIndex: 'endConfirmName', key: 'endConfirmName'},
    {
      title: '识别时间',
      dataIndex: 'endInTime',
      key: 'endInTime',
    },
    {title: '入倒场-经营部收货人', dataIndex: 'acquirer_name'},
    {title: '物料', dataIndex: 'materiel_name'},
    {title: '货箱方量', dataIndex: 'car_volumn', key: 'car_volumn', render: (e) => parseFloat(e).toFixed(2)},    
    {title: '实际方量', dataIndex: 'volumn', key: 'volumn', render: (e) =>parseFloat(e).toFixed(2)},
    {title: '联单来源', dataIndex: 'source', key: 'source', render: (t, e) => t === '补录' ? (
      <Tooltip placement="top" title={e.add_reason}>
        <Button type={'default'} size={'small'}>{t}</Button>
      </Tooltip>) : <span>{t}</span>
    },
    {title: '打印次数', dataIndex: 'printing_count', key: 'printing_count', render: (t) => t === 0 ? <span style={{color: '#ff0000'}}>{t}</span> : t},
    {
      title: '操作', key: 'actions', render: (t, v) => {
        return <><Button size="small" onClick={() => {
          this.getCarTransportNumber(v)
        }}>补打</Button>
          <Divider type="vertical" />
          <LinkButton
            to={{
              pathname: './update',
              state: {params: {id: v.id, start_department_id: v.building_department_id, materielName: v.materiel_name, area_id: v.buidling_area_id, start_id: v.building_id, car_num: v.car_num,
                car_volumn: v.car_volumn, deduction_volume: v.deduction_volume, volumn: v.volumn, reason: v.reason === '' ? '泥巴' : v.reason}
              }
            }
            }>修改并补打</LinkButton>
        </>;
      }
    }
  ];

  componentDidMount() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `/LodopFuncs.js`;
    document.head.appendChild(script);
    request('/web/building/history', {})
      .then(body => body.data.data)
      .then(data => this.setState({buildArr: data}));
    this.getUserInstance();
    const setSandStone = JSON.parse(sessionStorage.getItem('setSandstone'));
    if (setSandStone) {
      this.setState({
        instance_id: setSandStone.instance_id,
        department_id: setSandStone.department_id,
        instance_name: setSandStone.instance_name,
        materielType: setSandStone.materielType,
      })
    }
    setTimeout(() => {
      this.getRightTableData({current: this.state.page, pageSize: this.state.limit});
    }, 1000)
    controller.onRefresh(() => {
      const sessionData = JSON.parse(sessionStorage.getItem('ticketAddAndPrint'));
      this.setState({
        printCompany: !isInArray(oldInstance, sessionData.building_id) ? '成都宏信投建材有限公司' : '成都宏图华信投资有限公司',
        printJson: sessionData
      });      
      this.printTicket();
      this.printingCount(sessionData.ticket_id);
      this.getRightTableData();
    })
  };

  onSelectChange = (selectedRowKeys, v) => {
    this.setState({selectedRowKeys: v})
  };
  printTicket = () => {
    CreateOneFormPage();
    if (selectPrinter) {
      LODOP.PRINTA();
    }
    else {
      LODOP.PRINT();
    }
    selectPrinter = false;
  };
  dateChange = (date, dateString) => {
    this.setState({time: dateString, page: 1});
  };
  getUserInstance = () => {
    let that = this;
    request({
      url: '/web/instance/getUserInstance',
      data: {area_id: 13}
    }).then(res => {
      that.setState({
        userInstance: res.data.data
      });
    })
  };
  onInstanceChange = (option, e) => {
    let that = this;
    let instance_name = '';
    if (that.state.userInstance.length > 0) {
      that.state.userInstance.map((v) => {
        if (v.instance_id === e.props.value) {
          instance_name = v.instance_name;
        }
      });
    }
    else {
      instance_name = e.props.instance_name
    }
    this.setState({
      modal_flag: false,
      instance_id: e.props.value,
      department_id: e.props.title,
      instance_name: instance_name,
      materielType:e.props.materielType
    });
    sessionStorage.setItem('setSandstone', JSON.stringify({
      instance_id: e.props.value,
      department_id: e.props.title,
      instance_name: instance_name,
      materielType: e.props.materielType,
    }))
    setTimeout(() => {
      this.getRightTableData({current: this.state.page, pageSize: this.state.limit});
    }, 1000)
  };
  getCarTransportNumber = (v) => {
    this.printingCount(v.id);
    request('/web/ticket/getCarTransportNumber', {ticket_id: v.id})
      .then(body => body.data.data)
      .then(data => {this.AddPrint(v, data.transport_count)})
  };
  AddPrint = (v, transport_count) => {
    this.setState({
      printCompany: !isInArray(oldInstance, v.building_id) ? '成都宏信投建材有限公司' : '成都宏图华信投资有限公司',
      printJson: {
        ...v,
        transport_count
      }
    });
    this.printTicket();
    setTimeout(() => {
      console.log(this.state.printJson)
      this.getRightTableData({current: this.state.page, pageSize: this.state.limit})
    }, 1000)
  };

  printingCount = (ticket_id) => {
    request('web/ticket/PrintingCount', {ticket_id})
      .then(body => console.log)
  };

  getRightTableData = (e) => {
    let that = this;
    const order = [...that.state.order].map(([_, value]) => value);
    request({
      url: '/web/ticket/getLeftInfo',
      data: {
        keyword: that.state.keyword,
        time: that.state.time,
        cancel: that.state.cancel,
        instance_id: that.state.instance_id,
        department_id: that.state.department_id,
        building_id: that.state.building_id,
        printing_count: that.state.printing_count,
        license: that.state.license,
        limit: e ? e.pageSize : that.state.limit,
        page: e ? e.current : that.state.page,
        order
      }
    }).then(res => {
      const data = res.data.data;
      const source = data.data.map((v, i) => ({
        serial: (((e ? e.current : that.state.page) - 1) * (e ? e.pageSize : that.state.limit)) + (i + 1),
        ...v,
      }));
      that.setState({
        dataSource: source,
        total: data.total,
        buildingArr: data.buildings,
        start_volumn: data.start_volumn,
        deduction_volume: data.deduction_volume,
        total_volume: data.total_volume,
        page: e ? e.current : that.state.page,
      });
    })
  };

  printingMore = () => {
    const that = this;
    confirm({
      title: `确认批量打印${that.state.selectedRowKeys.length}张联单?`,
      width: 300,
      onOk() {
        that.setState({
          spinLoading: true
        });
        that.state.selectedRowKeys.map((v) => {
          that.getCarTransportNumber(v);
        });
        setTimeout(() => {
          that.setState({
            spinLoading: false
          });
        }, 5000)
      },
      onCancel() {
      },
    });

  };
  changeEndTimeSort = (e) => {
    const orders = new Map([...this.state.order]);                    
    orders.set('end_time', { name: 'end_time', value: e });
    this.setState({ order: new Map([...orders]), page: 1 });
  }
  
  render() {
    const state = {...this.state};
    const write = state.instance_id === 17;
    const borderLeft = !write && {borderLeft: '1px solid black'};
    const borderRight = !write && {borderRight: '1px solid black'};
   
    return (
      <>
        <div className="SandStoneReceiver flex flex-column">
          <div className="topSelect" onClick={() => this.setState({modal_flag: true})}>
            <span>当前开票地点是：{state.instance_name}</span>
            <span style={{fontSize: 12}}>点击这里可重新选择</span>
          </div>
          <div className="flex flex-grow">
            <div className="RightSide">
              <div className="RightSearch flex flex-main-between">
                <div className="SearchCar flex flex-cross-end">
                  <Button
                    onClick={() => open('/web/ticket/getLeftInfoExcel', {
                    keyword: state.keyword,
                    time: state.time,
                    cancel: state.cancel,
                    instance_id: state.instance_id,
                    department_id: state.department_id,
                    building_id: state.building_id,
                    printing_count: state.printing_count,
                    license: state.license,
                    account_source: 1
                  })}>
                    <Icon type="download"/>导出
                  </Button>
                  <Button
                    onClick={() => open('/web/ticket/getLeftInfoCountExcel', {
                      keyword: state.keyword,
                      time: state.time,
                      cancel: state.cancel,
                      instance_id: state.instance_id,
                      department_id: state.department_id,
                      building_id: state.building_id,
                      printing_count: state.printing_count,
                      license: state.license,
                      account_source: 1
                    })}>
                      <Icon type="download"/>按项目导出
                  </Button>
                </div>                
                <div className={"flex flex-cross-center"}>
                  <div>第二收货人：{state.secondReceiver === '' ? '暂无' : state.secondReceiver}</div>
                  <Input placeholder="请输入车牌/单号/运企搜索" onChange={(e) => this.setState({keyword: e.target.value, page: 1})}
                         style={{marginRight: 10}}/>
                  <Select placeholder="请选择是否已打票" allowClear style={{width: 150, marginRight: 10}}
                          onChange={(e) => this.setState({printing_count: e, page: 1})}>
                    {printOption.map(({id, name}, i) => <Option value={id} key={i}>{name}</Option>)}
                  </Select>
                  <Select placeholder="请选择工地项目" allowClear style={{width: 200, marginRight: 10}} onChange={(e) => this.setState({building_id: e, page: 1})}>
                    {state.buildingArr.map(({id, name}, i) => <Option value={id} key={i}>{name}</Option>)}
                  </Select>                 
                  <Select placeholder="请选择车辆类型" allowClear style={{width: 150, marginRight: 10}} onChange={(e) => this.setState({license: e, page: 1})}>
                    {carTypeOptions.map(({id, name}, i) => <Option value={id} key={i}>{name}</Option>) }
                  </Select>
                  <Select placeholder="请选择识别时间排序" value={state.order.get('end_time') ? state.order.get('end_time').value : ''} style={{width: 150}} onChange={this.changeEndTimeSort}>
                    <Option value={'asc'}>识别时间正序</Option>
                    <Option value={'desc'}>识别时间倒序</Option>
                  </Select>
                  <RangePicker
                    allowClear={false}
                    style={{width: 350, marginLeft: 10}}
                    defaultValue={[start_time, end_time]}
                    showTime={{format: 'HH:mm:ss'}}
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder={['开始日期', '结束日期']}
                    onChange={this.dateChange}
                  />
                  <Button type="primary" onClick={() => {
                    this.getRightTableData({current: state.page, pageSize: state.limit})
                  }}>搜索</Button>
                </div>
              </div>
              <div style={{marginBottom: 10}}>
                <span style={{color: '#F95E5A'}}>已入库{state.total}单 / 总货箱方量{state.start_volumn ? state.start_volumn.toFixed(2) : '0.00'}方 / 总实际方量{state.total_volume ? state.total_volume.toFixed(2) : '0.00'}方</span>
              </div>
              <Table className="table" rowSelection={this.state.rowSelection} rowKey={"id"}
                     dataSource={state.dataSource} bordered={true} columns={this.columns}
                     pagination={false}
              />
              <div className="flex flex-main-between">
                <Button type="primary" onClick={this.printingMore} disabled={state.selectedRowKeys.length > 0 ? false : true}>批量打印</Button>
                <Pagination
                  current={state.page} pageSize={state.limit} total={state.total} showSizeChanger
                  onChange={(e) => {
                    this.setState({page: e});
                    this.getRightTableData({current: e, pageSize: state.limit})
                  }}
                  onShowSizeChange={(e, limit) => {
                    this.setState({limit: limit});
                    this.getRightTableData({current: 1, pageSize: limit})
                  }} showTotal={() => {
                  return `共${state.total}条`
                }
                }/>
              </div>
            </div>
          </div>
          <Modal
            title={'选择实例'}
            visible={state.modal_flag}
            centered={true}
            onCancel={() => this.setState({modal_flag: false})}
            width={400}
            footer={null}
            maskClosable={false}
            destroyOnClose={true}
            bodyStyle={{height: 100, overflow: 'auto'}}>
            <div className='flex flex-main-end'>
              <Select placeholder="选择实例" value={state.instance_id} style={{width: 350}} onChange={this.onInstanceChange}>
                {
                  state.userInstance.map((v, i) => {
                    return <Option value={v.instance_id} title={v.department_id} materielType={v.materielType} key={i}>{v.instance_name}</Option>
                  })
                }
              </Select>
            </div>
          </Modal>
          {
            <object id="LODOP_OB" classID="clsid:2105C259-1E0C-4534-8141-A753534CB4CA" style={{width: 0, height: 0}}>
              <embed id="LODOP_EM" type="application/x-print-lodop" style={{width: 0, height: 0}}></embed>
            </object>
          }
          <div id="print_t1" style={{ display: 'none', paddingLeft: 0 }}>
            <div style={{textAlign: 'center', marginTop: 4}}>
              <h4>
                <span className="printTitle" style={{ fontSize: 14, letterSpacing: '5px', fontWeight: 'bolder' }}>成都市瀚宇投资有限公司</span>
              </h4>
              {!write && (
                <h4>
                  <span style={{ borderBottom: '4px double black', fontSize: 14, letterSpacing: '5px', fontWeight: 'bolder', marginBottom: 0 }}>{state.instance_name}砂石接管凭证</span>
                </h4>
              )}
            </div>
            <div className="flex" style={{display: 'flex', padding: '0 10px'}}>
              <div className="lj" style={{display: 'inline', width: '69%', fontWeight: 'bold', marginLeft: write ? 40 : 0 }}>{!write && '出料单位：'}{state.printJson.building_name}</div>
              <div className="lj flex-grow" style={{display: 'inline', width: '30%', fontWeight: 'bold'}}>
                <span style={{ paddingLeft: write ? 70 : 10, paddingRight: write ? 15 : 0, display: 'inline-block', boxSizing: 'border-box' }}>{!write && 'NO: '}<span style={{fontSize: !write ? 20 : 16, color: '#D37663'}}>{state.printJson.number}</span></span>
              </div>
            </div>
            <div className="flex" style={{ marginTop: 4, marginLeft: 3, fontSize: 13 }}>
              <table border={ write ? 0 : 1 } cellSpacing="0" cellPadding="5" style={{ borderCollapse: 'collapse', fontSize: 13, tableLayout: 'fixed', textAlign: 'center', width: '99%' }}>
                <tbody>
                <tr>
                  <td style={{width: '12.5%' }}><span>车牌</span></td>
                  <td style={{width: '12.5%' }}>{state.printJson.car_num}</td>
                  <td style={{width: '12.5%' }}><span>车数</span></td>
                  <td style={{width: '12.5%' }}>{state.printJson.transport_count}</td>
                  <td style={{width: '12.5%' }}><span>物料</span></td>
                  <td style={{width: '12.5%' }}>{state.printJson.materiel_name}</td>
                  <td style={{width: '12.5%' }}><span>车辆属性</span></td>
                  <td style={{width: '12.5%', ...borderLeft }}>{state.printJson.car_type}</td>                
                </tr>
                </tbody>
              </table>
            </div>
            <div className="flex" style={{ marginLeft: 3, fontSize: 13 }}>
              <table border={ write ? 0 : 1 } cellSpacing="0" cellPadding="5" style={{ borderCollapse: 'collapse', fontSize: 13, tableLayout: 'fixed', textAlign: 'center', width: '99%' }}>
                <tbody>
                <tr style={{borderTop: 'none'}}>
                  <td style={{width: '12.5%' }}>{!write && `货箱方量(m³)`}</td>
                  <td style={{width: '12.5%' }}>{state.printJson.car_volumn && state.printJson.car_volumn.toFixed(2)}</td>
                  <td style={{width: '12.5%' }}>{!write && `出场时间`}</td>
                  <td style={{width: '12.5%' }}>{state.printJson.startOutTime}</td>
                  <td style={{width: '12.5%' }}>{!write && `出场质量认定`}</td>
                  <td style={{width: '12.5%' }}>合格</td>
                  <td style={{width: '12.5%' }}>{!write && `出场审核人`}</td>
                  <td style={{width: '12.5%', ...borderLeft}}>{state.printJson.startConfirmName}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div className="flex" style={{ marginLeft: 3, fontSize: 13 }}>
              <table border={ write ? 0 : 1 } cellSpacing="0" cellPadding="5" style={{ borderCollapse: 'collapse', fontSize: 13, tableLayout: 'fixed', textAlign: 'center', width: '99%' }}>
                <tbody>
                <tr style={{borderTop: 'none'}}>
                  <td style={{width: '12.5%'}}><span>入倒场时间</span></td>
                  <td style={{width: '12.5%'}}>{state.printJson.endInTime}</td>
                  <td style={{width: '12.5%'}}><span>倒场质量认定</span></td>
                  <td style={{width: '12.5%'}}>合格</td>
                  <td style={{width: '12.5%'}}><span>入倒场-加工商收货人</span></td>
                  <td style={{width: '12.5%'}}>{state.printJson.endConfirmName}</td>
                  <td style={{width: '12.5%'}}><span>入倒场-经营部收货人</span></td>
                  <td style={{width: '12.5%', ...borderLeft}}>{state.printJson.acquirer_name}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div className="flex" style={{ marginLeft: 3, fontSize: 13 }}>
              <table border={ write ? 0 : 1 } cellSpacing="0" cellPadding="5" style={{ borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'center', width: '99%', fontSize: 13 }}>
                <tbody>
                <tr style={{borderTop: 'none'}}>
                  <td style={{width: '12.5%'}}><span>实际方量(m³)</span></td>
                  <td style={{width: '12.5%'}}>{state.printJson.volumn && state.printJson.volumn.toFixed(2)}</td>
                  <td style={{width: '12.5%'}}><span>驾驶员</span></td>
                  <td style={{width: '12.5%'}}></td>
                  <td style={{width: '12.5%'}}></td>
                  <td style={{width: '12.5%'}}></td>
                  <td style={{width: '12.5%'}}></td>
                  <td style={{width: '12.5%', ...borderLeft}}></td>
                </tr>
                </tbody>
              </table>
            </div>
            {!write && (
              <>
                <div style={{fontSize: 12}}>第一联：存根联（白），第二联：公司财务（红），第三联：移交砂石加工单位（蓝）</div>
                <div style={{fontSize: 12}}>第四联：移交砂石加工单位（绿），第五联：移交砂石单位（黄），第六联：移交砂石单位（灰）</div>
              </>
            )}
          </div>
          { ReactDOM.createPortal(<Spin spinning={state.spinLoading} size={'large'} style={{position: 'absolute', top: 0, margin: 'auto', bottom: 0, left: 0, right: 0, width: 50, height: 50, zIndex: 1111111111}} />, document.getElementById('root'))}
        </div>
        <Route path={this.props.path + 'update'} component={Update} />
      </>
    )
  }
};

