import React from 'react';
import { Icon } from 'antd';
import styles from './ImagePreviewButtonRender.scss';

export default function ImagePreviewButtonRender(previewFns) {
  return function ImagePreviewButton(src) {
    if (!src) return null;
    if (src.indexOf('http') === -1) {
      src = URLS.FILES + src;
    }
    const onClick = () => previewFns.image(src);
    return (
      <div className={styles.container} onClick={onClick}>
        <Icon type="picture" theme="filled" />
      </div>
    );
  };
}
