import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {Button, Icon, Input, Pagination, Radio, Table} from "antd";
import styles from "../index.scss";
import controller from "./controller";
import request, { open } from "utils/request";

const Status = [{label: '所有司机', value: 0}, {label: '正常司机', value: 2}, {label: '临近审期', value: 3}, {label: '已到期', value: 4}, {label: '已逾期', value: 5}, {label: '无信息', value: 1}];
const colsColors = ['', '#AAAAAA', '#70B603', '#02A7F0', '#F59A23', '#D9001B'];
const columns = [
  { title: '序号', key: 'serial', dataIndex: 'serial', align: 'center', width: 70},
  { title: '司机名称', key: 'name', dataIndex: 'name', align: 'center'},
  { title: '驾驶证年审期', key: 'expiry_date', dataIndex: 'expiry_date', align: 'center', width: 350, render: function(v, e){return <Link to={`./${e.name}/${e.id}/${1}/edit`}><p style={{ backgroundColor: colsColors[e.expiry_status] }}>{v && v !== '' ? v : '-'}</p></Link>}},
  { title: '从业资格证年审期', key: 'deadline', dataIndex: 'deadline', align: 'center', width: 350, render: function(v, e){return <Link to={`./${e.name}/${e.id}/${2}/edit`}><p style={{ backgroundColor: colsColors[e.deadline_status] }}>{v && v !== '' ? v : '-'}</p></Link>}},
 ];
export default function VehicleAuditPeriod({}){
  const [query, setQuery] = useState({search: '', status: 0, page: 1, limit: 10});
  const [keyword, setKeyword] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [statusRemark, setStatusRemark] = useState([
    { name: '已逾期', value: 12},
    { name: '已到期', value: 12},
    { name: '临近审期', value: 12},
    { name: '正常车辆', value: 12},
    { name: '已取消', value: 12}
  ]);
  controller.onRefresh(() => {
    getDataInit()
  });
  function getDataInit(){
    request('/web/driver/Accounts', {...query})
      .then(body => body.data.data)
      .then(data => {
        let arr = [];
        data.data.map((e, serial) => {
          arr.push({...e, serial: (((query.page - 1) * query.limit) + (serial + 1))})
        });
        setDataSource(arr);
        setStatusRemark(data.items);
        setTotal(data.total);
      })
  }
  useEffect(() => {
    getDataInit();
  }, [JSON.stringify(query)]);
  return (
    <div className={styles.part}>
      <div className={styles.container}>
        <div className={styles.remark}>
          {
            statusRemark.map(({name, count, status}, i) => (
              <div style={{backgroundColor: colsColors[status]}} key={i}>
                <span>{name}</span>
                <span>{count + '人'}</span>
              </div>
            ))
          }
        </div>
        <div className={styles.buttons}>
          <Button onClick={() => open("/web/driver/AccountsExcel", { search: query.search, status: query.status })}><Icon type="download" />导出</Button>
        </div>
        <Input.Group className={styles.querys} compact>
          <Radio.Group value={query.status} buttonStyle="solid" onChange={(e) => setQuery({...query, page: 1, status: e.target.value}) }>
            {
              Status.map(({label, value}) => (
                <Radio.Button value={value} key={value}>{label}</Radio.Button>
              ))
            }
          </Radio.Group>
          <Input placeholder={'请输入司机名称/关键字搜索'} style={{width: 300}} onChange={e => setKeyword(e.target.value)} onPressEnter={(e) => setQuery({...query, page: 1, search: e.target.value})} />
          <Button type="primary" onClick={() => setQuery({...query, page: 1, search: keyword})}><Icon type="search" /></Button>
        </Input.Group>
      </div>
      <div className={styles.newTable}>
        <Table size="middle" rowKey={'id'} className={styles.table} columns={columns} dataSource={dataSource} pagination={false} />
      </div>
      <div className={styles.pagination}>
        <Pagination total={total} current={query.page} pageSize={query.limit} showSizeChanger={true} onChange={(page, limit) => setQuery({...query, page, limit})}
                    onShowSizeChange={(page, limit) => setQuery({...query, page, limit})}
                    showTotal={t => `共 ${t} 条`}
        />
      </div>
    </div>
  )
}
