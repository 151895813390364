import React, { useState } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

export default function OnlineRangePicker({ value, format = 'YYYY-MM-DD', placeholder, onChange, ...props }) {
  const [waitDate, setWaitDate] = useState([]);
  function onCalendarChange(current) {
    let timeRange = 30*24*60*60*1000;
    const minTime = new Date(current).getTime() - timeRange;
    const maxTime = new Date(current).getTime() + timeRange;
    setWaitDate([moment(minTime), moment(maxTime)])
  }
  function disabledDate(current) {
    return current > moment().subtract(1, 'days').endOf('day') || current < waitDate[0] || current > waitDate[1];
  }

  return (
    <DatePicker.RangePicker
      {...props}
      format={format}
      placeholder={placeholder || []}
      value={(value || []).map(item => (item ? moment(item, 'YYYY-MM-DD HH:mm:ss') : null))}
      onChange={(m, dateStrings) => onChange(dateStrings.filter(v => v).length > 0 ? dateStrings : undefined)}
      onCalendarChange={(moment) => onCalendarChange(moment)}
      disabledDate={disabledDate}
    />
  )
}
