import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import debug from 'utils/debug';


import {
  PreviewerContext,
  ImagePreviewer,
  IllegalMapPreviewer,
} from 'components/Previewer';

import Login from './Login';
import Routes from './Routes';
import Unify from '../paths/Unify';
import { ConfigProvider } from 'antd';
import store from 'stores/user';
import { LOG_IN } from '../redux/types/user';

export default function App() {
  debug.render('App');
  const [PreviewerContextValue, setPreviewerContextValue] = useState({});
  const [ImagePreviewerProps, setImagePreviewerProps] = useState({});
  const [IllegalMapPreviewerProps, setIllegalMapPreviewerProps] = useState({});

  useEffect(() => {
    debug.effect('App');
    setPreviewerContextValue({
      image(images, showCar=false, id) {
        setImagePreviewerProps({ images, visiable: true, showCar, id });
      },
      illegalMap(id, type, alarmId) {
        setIllegalMapPreviewerProps({ id, alarmId, type, visiable: true });
      },
    });
  }, []);
  const href = window.location.href
  const unify = href.indexOf('unifyToRadar') > -1;
  if(unify) {
    const a = href.split('=');
    const data = JSON.parse(decodeURIComponent(a[1]));
    // 登陆成功
    const user = {
      id: data.userId,
      key: data.key,
      roles: data.roles,
      info: data.info,
    };
    // 本地缓存用户信息
    window.localStorage.setItem('USER', JSON.stringify(user));
    // 发布事件
    store.dispatch({
      type: LOG_IN,
      payload: user,
    });
  }

  return (
    <PreviewerContext.Provider value={PreviewerContextValue}>
      <ImagePreviewer {...ImagePreviewerProps} onChange={visiable => setImagePreviewerProps({ visiable })} />
      <IllegalMapPreviewer {...IllegalMapPreviewerProps} onChange={visiable => setIllegalMapPreviewerProps({ visiable })} />
      {
        unify ? <Route component={Unify} /> : (
          <Switch>
            <Route path="/login" component={Login} />
            <Route component={Routes} />
          </Switch>
        )
      }
    </PreviewerContext.Provider>
  );
}
