import React from 'react'
import { Route } from 'react-router-dom';
import List from './List'
import Details from './operationJS/Detailscopy'


export default function PenaltyAlarm({ path }) {
  return (
    <>
      <Route path={path} component={List} />
      {/* <Route path={path+'details/:id'} component={Details} /> */}
    </>
  )
}