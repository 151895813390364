import React, { useState } from 'react';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import width from 'ui/TableCell/width';
import DeleteButton from 'ui/TableCell/DeleteButton';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';
import { Route } from 'react-router-dom';
import { Modal, Input } from 'antd';
import debug from 'utils/debug';
import request from 'utils/request';

const dataSource = new DataSource('/web/Car/showBlackList');

function List() {
  debug.render('List');
  const columns = [
    serial(),
    { title: '运输车', dataIndex: 'car_num', width: width(10) },
    { title: '更新时间', dataIndex: 'created_time' },
    actions({
      render: ($, { id }) => (
        <DeleteButton api="/web/Car/deleteBlack" id={id} dataSource={dataSource} />
      ),
    }),
  ];

  const querys = [
    { title: '关键字', key: 'keyword' },
    { title: ['更新', '时间'], key: 'created_time', type: 'date-range' },
  ];

  const buttons = [
    { title: '新增', link: './create', icon: 'plus' },
    { title: '下载模版', download: '/web/Car/downloadBlackExcel' },
    { title: '上传黑名单', upload: '/web/Car/importBlackExcel' },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}

function Create({ history }) {
  debug.render('Create');
  const [car, setCar] = useState();

  const back = () => history.goBack();
  function submit() {
    request('/web/Car/createBlack', { car_num: car }).then(() => {
      dataSource.refresh();
      back();
    });
  }

  return (
    <Modal visible title="新增黑名单记录" onOk={submit} onCancel={back} okButtonProps={{ disabled: !car }}>
      <Input value={car} onChange={e => setCar(e.target.value)} placeholder="请输入车牌号" />
    </Modal>
  );
}

export default function VehicleBlacklist({ path }) {
  debug.render('VehicleBlacklist');
  return (
    <>
      <Route path={path} component={List} />
      <Route path={path + 'create'} component={Create} />
    </>
  );
}
