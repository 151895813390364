import { LOG_IN, LOG_OUT, UPDATE_BADGE } from '../types/user';

// 从本地缓存中拿用户信息
const initialState = (() => {
  try {
    return JSON.parse(window.localStorage.getItem('USER')) || {};
  } catch (e) {
    return {};
  }
})();

function user(state = initialState, { type, payload }) {
  switch (type) {
    case LOG_IN: return payload;
    case LOG_OUT: return {};
    case UPDATE_BADGE: return { ...state, badge: payload };
    default: return state;
  }
}

export default user;
