import React, {useState, useEffect} from 'react';
import request from 'utils/request';
import { Modal } from 'antd';
import classNames from 'classnames';
import styles from './Details.scss';
import { previewable } from 'components/Previewer/index';
import {typeArr, NodeName, NodeColor, NodeTime, ShowFile} from '../share'

function Details({history, match, preview}) {
  const back = () => history.goBack();
  const list_id = match.params.id;
  const list_type = match.params.types;
  console.log(list_id, list_type)
  const [base, setBase] = useState({});
  const [flow, setFlow] = useState([]);
  useEffect(() => {
    request('/web/gb_list/getCarDetails', {car_info_id: list_id, list_type})
      .then(res => {
        const data = res.data.data;
        setBase(data.base_info);
        setFlow(data.flow_info);
      });
  }, []);

  return (
    <Modal
      title="详情"
      visible={true}
      centered={true}
      width={480}
      bodyStyle={{maxHeight: '70vh', overflowY: 'auto',}}
      footer={null}
      onCancel={back}
    >
      <div>
        <div className={styles.base}>
          {
            base.id_card ?
              <div>
                <span>驾驶员：{base.driver_name}</span>
                <span>身份证号：{base.id_card}</span>
              </div> :
              <div>
                <span>已选车辆：{base.car_number}</span>
                <span>关联驾驶员：{base.driver_name}</span>
              </div>
          }

          <div>所属运企：{base.transport_name}</div>
          <div>所属区域：{base.area_name}</div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          {
            flow.map(({node, ...item}, i) => (
              <div key={i} style={{display: 'flex', padding: '0 25px', fontSize: 12}}>
                <div style={{marginRight: 10, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  {i !== 0 ? <div className={styles.crossLine}> </div> : <div className={classNames(styles.crossLine, styles.opacity)}> </div>}
                  <div className={styles.number}>{flow.length - i}</div>
                  {i !== flow.length - 1 ?
                    <div className={styles.crossLine} style={{display: 'flex', flexGrow: 1}}> </div> :
                    <div className={classNames(styles.crossLine, styles.opacity)} style={{display: 'flex', flexGrow: 1}}> </div>
                  }
                </div>
                <div>
                  <div className={styles.triangleLeft}> </div>
                </div>
                <div className={styles.row}>
                  <div  className={styles.itemTop} style={{backgroundColor: NodeColor[node]}}>{NodeName[node]}</div>
                  <div className={styles.itemBottom}>
                    <div className={styles.itemLeft}>{NodeTime[node]}</div>
                    <div style={{color: '#333'}}>{ (node === 2 || node === 5) ? item.delay_time : item.node_time}</div>
                  </div>
                  <div className={styles.itemBottom}>
                    <div className={styles.itemLeft}>事由</div>
                    <div style={{color: '#333'}}>{typeArr[item.reason]}</div>
                  </div>
                  {
                    (item.images !== '' && item.images) && <div className={styles.itemBottom}>
                      <div className={styles.itemLeft}>照片</div>
                      <div>
                        {
                          item.images && item.images.split(',').map((e, i) => ((e.indexOf('.pdf') > -1 || e.indexOf('.doc') > -1 || e.indexOf('.xlsx') > -1) ? <span className={styles.showFile} onClick={() => ShowFile(e)}>查看文件</span> : <img key={i} className={styles.imgs} src={e}  onClick={() => preview.image(e)} alt=""/>))
                        }
                      </div>
                      {/*<div style={{color: '#1941fb', cursor: 'pointer'}} onClick={() => preview.image(item.images)}>查看</div>*/}
                    </div>
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </Modal>
  )
}

export default previewable(Details)
