import width from './width';

export default function serial(isFixed) {
  const fixed = isFixed ? 'left' : undefined;
  return {
    title: '序号',
    dataIndex: 'serial',
    width: width(2),
    align: 'center',
    fixed,
  };
}
