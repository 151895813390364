/*
import qs from 'qs';
import axios from 'axios';

import {
  Page,
  Table,
  Form,
  InputTypes,
  ColumnTypes,
} from 'ui-schema';
import withEmptyOption from 'utils/withEmptyOption';
// import EditRoute from './edit';

export default Page({
  'table': Table({
    api: {
      url: '/web/transport_car/getList',
    },
    query: {
      keyword: {
        type: InputTypes.Text,
        placeholder: '关键字',
      },
      start_time: {
        type: InputTypes.DateRangePicker,
        placeholder: ['开始时间', '结束时间'],
        format: 'YYYY-MM-DD HH:mm:ss',
        showTime: true,
      },
      buttons: {
        type: InputTypes.Button,
        name: '搜索',
      }
    },
    table: {
      index: {
        type: ColumnTypes.Number,
        title: '序号',
        columnOptions: {
          width: 70,
        },
      },
      car_num: {
        type: ColumnTypes.String,
        title: '车牌号码',
      },
      company_name: {
        type: ColumnTypes.String,
        title: '所属运企',
      },
      owner_name: {
        type: ColumnTypes.String,
        title: '车主姓名',
      },
      owner_phone: {
        type: ColumnTypes.String,
        title: '车主电话',
      },
      inner_long: {
        type: ColumnTypes.String,
        title: '货箱内径-长(mm)',
      },
      inner_width: {
        type: ColumnTypes.String,
        title: '货箱内径-宽(mm)',
      },
      inner_height: {
        type: ColumnTypes.String,
        title: '货箱内径-高(mm)',
      },
      volumn: {
        type: ColumnTypes.String,
        title: '方量',
      },
      weight: {
        type: ColumnTypes.String,
        title: '核定载重量(吨)',
      },
      car_photo: {
        type: ColumnTypes.Image,
        title: '车辆图片',
      },
      drive_license_photo: {
        type: ColumnTypes.Image,
        title: '车辆行驶证图片',
      },
      ship_license_photo: {
        type: ColumnTypes.Image,
        title: '道路运输证图片',
      },
      car_model: {
        type: ColumnTypes.String,
        title: '车辆型号',
      },
      actions: {
        type: ColumnTypes.LinkGroup,
        title: '操作',
        buttons: ({ id }) => [
          { name: '编辑', to: `./${id}/edit` },
          { name: '详情', to: `./${id}/details` },
        ],
      },
    },
  }),
  // ':id/edit': EditRoute,
});
*/
import React from 'react';
import request from 'utils/request';
import 'components/css/TableClass.global.scss';
import ShowImages from "components/PopoverPage/ShowImages";
import TransPortCarShowMsg from "components/PopoverPage/TransPortCarShowMsg";
import TransPortCarEditMsg from "../TableComponents/TransPortCarEditMsg";
import { Table, Button, Input, DatePicker, Modal, message } from 'antd';
const RangePicker = DatePicker.RangePicker;
import common from 'utils/common';
import moment from 'moment';

moment.locale('zh-cn');
const ToFormatDate = function (datetime, method) {
  if(!datetime || datetime==null){
    return '';
  }
  var date = new Date(datetime);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  var d = date.getDate();
  var h = date.getHours();
  var mm = date.getMinutes();
  var s = date.getSeconds();
  m = m < 10 ? '0' + m : m;
  d = d < 10 ? '0' + d : d;
  h = h < 10 ? '0' + h : h;
  mm = mm < 10 ? '0' + mm : mm;
  s = s < 10 ? '0' + s : s;
  if(method === 'YY-MM-DD'){
    return y + '-' + m + '-' + d
  }
  else if(method === 'YYYY-MM-DD HH:mm:ss'){
    return y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + s
  }
  return m + '月' + d + '日'
};
const start_time = new Date() - 24 * 60 * 60 * 1000;
const end_time = new Date();
export default class TransportationEnterpriseVehicleInformationBasic extends React.PureComponent{
  constructor(props){
    super(props);
    this.state = {
      dataSource: [],
      keyword: '',
      start_time: [ToFormatDate(start_time , 'YYYY-MM-DD HH:mm:ss'), ToFormatDate(end_time, 'YYYY-MM-DD HH:mm:ss')],
      currentPage: 1,
      limit: 10,
      totalRow: 1,
      dataImages: [],
      img_flag: false,
      detailData: [],
      edit_flag: false,
      show_flag: false,
      columns: [
        {
          title: '序号',
          type: 'index',
          dataIndex: 'index',
          key: '序号',
          width: 70,
        }, {
          title: '车牌号',
          dataIndex: 'car_num',
          key: 'car_num',
          width: 120
        }, {
          title: '设备编号',
          dataIndex: 'device_num',
          key: 'device_num',
          width: 150
        }, {
          title: '所属运企',
          dataIndex: 'company_name',
          key: 'company_name',
          width: 350
        }, {
          title: '车主姓名',
          dataIndex: 'owner_name',
          key: 'owner_name',
          width: 100
        }, {
          title: '车主电话',
          dataIndex: 'owner_phone',
          key: 'owner_phone',
          width: 160
        }, {
          title: '车辆型号',
          dataIndex: 'car_model',
          key: 'car_model',
          width: 120
        }, {
          title: '货箱内径-长(m)',
          dataIndex: 'inner_long',
          key: 'inner_long',
          width: 220
        }, {
          title: '货箱内径-宽(m)',
          dataIndex: 'inner_width',
          key: 'inner_width',
          width: 220
        }, {
          title: '货箱内径-高(m)',
          dataIndex: 'inner_height',
          key: 'inner_height',
          width: 220
        }, {
          title: '方量',
          dataIndex: 'volumn',
          key: 'volumn',
          width: 120
        }, {
          title: '核定载重量(吨)',
          dataIndex: 'weight',
          key: 'weight',
          width: 180,
        }, {
          title: '车辆图片',
          dataIndex: 'car_photo',
          key: 'car_photo',
          width: 120,
          render: (text, record)=>{
            if(!text) {
              return <span>暂无图片</span>
            }else{
              return <span style={{ color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.showImages(text) } }>查看图片</span>
            }
          }
        }, {
          title: '车辆行驶证图片',
          dataIndex: 'drive_license_photo',
          key: 'drive_license_photo',
          width: 180,
          render: (text, record)=>{
            if(!text) {
              return <span>暂无图片</span>
            }else{
              return <span style={{ color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.showImages(text) } }>查看图片</span>
            }
          }
        }, {
          title: '道路运输证图片',
          dataIndex: 'ship_license_photo',
          key: 'ship_license_photo',
          width: 180,
          render: (text, record)=>{
            if(!text) {
              return <span>暂无图片</span>
            }else{
              return <span style={{ color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.showImages(text) } }>查看图片</span>
            }
          }
        }, {
          title: '建筑垃圾处置运输证号图片',
          dataIndex: 'garbage_license_photo',
          key: 'garbage_license_photo',
          width: 280,
          render: (text, record)=>{
            if(!text) {
              return <span>暂无图片</span>
            }else{
              return <span style={{ color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.showImages(text) } }>查看图片</span>
            }
          }
        }, {
          title: '车辆二级维护图片',
          dataIndex: 'complete_maintenance',
          key: 'complete_maintenance',
          width: 280,
          render: (text, record)=>{
            if(!text) {
              return <span>暂无图片</span>
            }else{
              return <span style={{ color: '#1491fb', cursor: 'pointer'}} onClick={ () => { this.showImages(text) } }>查看图片</span>
            }
          }
        }, {
          title: '操作',
          dataIndex: 'actions',
          key: 'actions',
          width: 200,
          fixed: 'right',
          render: (text, record) => {
            return (
              <div className='data-handle'>
                <span onClick={ () => {this.EditMsg(record.id, record.car_num)}}>编辑</span>
                <span onClick={ () => {this.ShowDetailMsg(record.id, record.car_num)}}>详情</span>
              </div>
            )
          }
        }],
      modal_title: ''
    }
  };
  getInitData = (e) => {
    let that = this;
    let json = {
      keyword: that.state.keyword,
      // start_time: that.state.start_time,
      limit: e ? e.pageSize : that.state.limit,
      page: e ? e.current : that.state.currentPage
    };
    let dataSource = [];
    request({
      url: '/web/transport_car/getList',
      data: json
    }).then((res) => {
      dataSource = res.data.data.data.map((row, index) => ({
        key: row.id,
        index: (index + 1) + (e.current - 1) * e.pageSize,
        ...row,
      }));
      that.setState({
        dataSource: dataSource,
        totalRow: res.data.data.total,
        limit: e.pageSize,
        currentPage: e.current
      })
    })
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState({
      limit: pageSize,
      currentPage: current
    })
    this.getInitData({pageSize: pageSize, current: current});
  };
  inputChange = (e) => {
    e.persist();
    this.setState({
      keyword: e.target.value
    })
  };
  dateChange = (date, dateString) => {
    this.setState({
      start_time: dateString
    })
  };
  showImages = (val) => {
    let images = [];
    if(val.indexOf(',') > -1){
      images = val.split(',');
    }else {
      images.push(val)
    }
    this.setState({
      dataImages: images,
      img_flag: true
    });
  };
  EditMsg = (id, name) => {
    let that = this;
    this.getInfoById(id, (data) => {
      that.setState({
        modal_title: '修改' + name + '车辆信息',
        detailData: data,
        edit_flag: true,
      });
    })
  };
  getInfoById = (id, callback) => {
    request({
      url: '/web/transport_car/getInfoById',
      data: {id: id}
    }).then((response) => {
      if(response.data.code !== 200){
        message.error('请求错误');
        return false
      }
      let data = response.data.data;
      let res = data.base;
      const dataArr = {
        id: id,
        base:[
          {
            label: '车牌',
            dataIndex: 'car_num',
            value: res.car_num,
            disabled: true,
            required: true,
          },
          {
            label: '设备编号',
            dataIndex: 'device_num',
            value: res.device_num,
            disabled: true,
            required: false,
          },
          {
            label: '车辆颜色',
            dataIndex: 'color',
            value: res.color,
            disabled: false,
            required: true,
          },
          {
            label: '车辆品牌',
            dataIndex: 'brand',
            value: res.brand,
            disabled: false,
            required: true,
          },
          {
            label: '燃料类型',
            dataIndex: 'fuel_type',
            value: res.fuel_type,
            disabled: false,
            select: common.fuel_type
          },
          {
            label: '排放标准',
            dataIndex: 'emission_standards',
            value: res.emission_standards,
            disabled: false,
            select: common.emission_standards,
            required: true,
          },
          {
            label: '车主姓名',
            dataIndex: 'owner_name',
            value: res.owner_name,
            disabled: false,
            required: true,
          },
          {
            label: '车主手机号',
            dataIndex: 'owner_phone',
            value: res.owner_phone,
            disabled: false,
            phone: true,
            required: true,
          },
          /*{
            label: '车辆行驶证号',
            dataIndex: 'drive_license_num',
            value: res.drive_license_num,
            disabled: false
          },*/
          {
            label: '道路运输证号',
            dataIndex: 'ship_license_num',
            value: res.ship_license_num,
            disabled: false,
            required: true,
          },
          {
            label: '成都市建筑垃圾处置运输证号',
            dataIndex: 'garbage_license_num',
            value: res.garbage_license_num,
            disabled: false,
            required: true,
          },
          {
            label: '车辆型号',
            dataIndex: 'car_model',
            value: res.car_model,
            disabled: false,
            // select: common.car_model,
            required: true,
          },
          {
            label: '发动机号',
            dataIndex: 'motor_num',
            value: res.motor_num,
            disabled: false,
            required: true,
          },
          {
            label: '车架号',
            dataIndex: 'carriage_num',
            value: res.carriage_num,
            disabled: false,
            required: true,
          },
          {
            label: '密闭装置',
            dataIndex: 'obturator',
            value: res.obturator,
            disabled: false,
            select: common.obturator,
            required: true,
          },
          {
            label: '防漏装置',
            dataIndex: 'leakproof',
            value: res.leakproof,
            disabled: false,
            select: common.leakproof,
            required: true,
          },
          {
            label: '货箱内径尺寸-长-米',
            dataIndex: 'inner_long',
            value: res.inner_long,
            disabled: false,
            number: true,
            required: true,
          },
          {
            label: '货箱内径尺寸-宽-米',
            dataIndex: 'inner_width',
            value: res.inner_width,
            disabled: false,
            number: true,
            required: true,
          },
          {
            label: '货箱内径尺寸-高-米',
            dataIndex: 'inner_height',
            value: res.inner_height,
            disabled: false,
            number: true,
            required: true,
          },
          {
            label: ' 核定载重量-吨',
            dataIndex: 'weight',
            value: res.weight,
            disabled: false,
            number: true,
            required: true,
          },
          {
            label: '保险公司',
            dataIndex: 'insure_company',
            value: res.insure_company,
            disabled: false,
            required: true,
          },
          {
            label: '保险金额（元）',
            dataIndex: 'insure_money',
            value: res.insure_money,
            disabled: false,
            number: true,
            required: true,
          },
          {
            label: '保险日期开始',
            dataIndex: 'insure_start_date',
            value: res.insure_start_date,
            disabled: false,
            date: true,
            required: true,
          },
          {
            label: '保险日期截止',
            dataIndex: 'insure_end_date',
            value: res.insure_end_date,
            disabled: false,
            date: true,
            required: true,
          },
          {
            label: '第三者责任险（元）',
            dataIndex: 'third_party_insure',
            value: res.third_party_insure,
            disabled: false,
            number: true,
            required: true,
          },
          {
            label: '建筑垃圾处置运输证号图片',
            dataIndex: 'garbage_license_photo',
            value: res.garbage_license_photo,
            disabled: false,
            extra: true,
            required: true,
          },
          {
            label: '车辆行驶证图片',
            dataIndex: 'drive_license_photo',
            value: res.drive_license_photo,
            disabled: false,
            extra: true,
            required: true,
          },
          {
            label: '道路运输证图片',
            dataIndex: 'ship_license_photo',
            value: res.ship_license_photo,
            disabled: false,
            extra: true,
            required: true,
          },
          {
            label: '车辆照片',
            dataIndex: 'car_photo',
            value: res.car_photo,
            disabled: false,
            extra: true,
            required: true,
          },
          {
            label: '车辆二级维护照片',
            dataIndex: 'complete_maintenance',
            value: res.complete_maintenance,
            disabled: false,
            extra: true,
            required: false,
          },
        ],
        driver: []
      };
      data.driver.map((v, i) => {
        dataArr.driver.push(
          [
            {
              label: 'id',
              dataIndex: 'id',
              value: v.id,
              disabled: true,
              hide: true
            },
            {
              label: '姓名',
              dataIndex: 'name',
              value: v.name,
              disabled: true,
            },
            {
              label: '电话',
              dataIndex: 'phone',
              value: v.phone,
              disabled: true,
            },
            {
              label: '身份证号',
              dataIndex: 'id_card',
              value: v.id_card,
              disabled: true,
            },
            {
            label: '准驾车型',
            dataIndex: 'type',
            value: v.type,
            disabled: true,
            },
            {
              label: '从业资格证号',
              dataIndex: 'work_license_num',
              value: v.work_license_num,
              disabled: true,
            },
            /*{
              label: '驾驶证号',
              dataIndex: 'driver_license_num',
              value: v.driver_license_num,
              disabled: true,
            },*/
            {
              label: '民族',
              dataIndex: 'nation',
              value: v.nation,
              disabled: true,
            },
            {
              label: '籍贯',
              dataIndex: 'birth_address',
              value: v.birth_address,
              disabled: true,
            },
            {
              label: '常住地',
              dataIndex: 'address',
              value: v.address,
              disabled: true,
            },
            {
              label: '备注',
              dataIndex: 'remark',
              value: v.remark,
              disabled: true,
            },
            /*{
              label: '照片',
              dataIndex: 'images',
              value: v.images,
              extra: true,
              disabled: true,
            },*/
            {
              label: '身份证图片',
              dataIndex: 'id_card_photo',
              value: v.id_card_photo,
              extra: true,
              disabled: true,
            },
            {
              label: '驾驶证照片',
              dataIndex: 'driver_license_photo',
              value: v.driver_license_photo,
              extra: true,
              disabled: true,
            },
            {
              label: '从业资格证号图片',
              dataIndex: 'work_license_photo',
              value: v.work_license_photo,
              extra: true,
              disabled: true,
            },
            {
              label: '近期体检表图片',
              dataIndex: 'physical_form',
              value: v.physical_form,
              disabled: true,
              extra: true,
            }
          ]
        )
      });
      callback(dataArr)
    })
  };
  ShowDetailMsg = (id, name) => {
    let that = this;
    that.setState({
      detailData: []
    });
    this.getInfoById(id, (data) => {
      that.setState({
        modal_title: '查看' + name + '车辆详情',
        detailData: data,
        show_flag: true,
      });
    });
  };
  HiddenImages = () => {
    this.setState({
      img_flag: false,
    });
  };
  hideModal = () => {
    this.setState({
      edit_flag: false,
      show_flag: false,
    });
  };
  editTransCarData = (data) => {
    let that = this;
    let postData = {
      id: data.id,
    };
    data.base.map((v) => {
      postData[v.dataIndex] = v.value
    })
    request({
      url: '/web/transport_car/updateInfo',
      data: postData
    }).then((res) => {
      if(res.data.code === 200){
        message.success('修改成功');
        that.setState({
          edit_flag: false
        });
        that.getInitData({current: that.state.currentPage, pageSize: that.state.limit});
      }
      else{
        message.success(res.data.msg);
      }
    })
  };
  getSearchData = () => {
    this.setState({
      currentPage: 1
    })
    this.getInitData({current: 1, pageSize: this.state.limit,})
  };
  BindDriverInfo = (driver_id, transport_car_id, cb) => {
    let that = this;
    request({
      url: '/web/transport_car/bindOneDriver',
      data: {driver_id: driver_id,transport_car_id: transport_car_id}
    }).then( res => {
      message.success('绑定司机成功');
      that.getInfoById(transport_car_id, function (data) {
        that.setState({
          // modal_title: '修改' + name + '车辆信息',
          detailData: data,
          edit_flag: true,
        });
        cb(that.state.detailData)
      })
    })
  };
  // 司机 - 车辆解除绑定
  DeleteCarDriverMsg = (car_num, transport_car_id, driver_id, driver_name, cb) => {
    let that = this;
    request({
      url: '/web/transport_car/unBindOneDriver',
      data: {car_num: car_num, driver_id: driver_id}
    }).then(res => {
      message.success('解除' + driver_name + '司机成功');
      that.getInfoById(transport_car_id, function (data) {
        that.setState({
          // modal_title: '修改' + name + '车辆信息',
          detailData: data,
          edit_flag: true,
        });
        cb(that.state.detailData)
      })
    })
  };
  componentDidMount () {
    this.getInitData({current: this.state.currentPage, pageSize: this.state.limit});
  }
  render () {
    const state = {...this.state};
    return (
      <div className="car-table-part" style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
        <div className="table-class">
          <div className="search-part" style={{textAlign: 'right'}}>
            <Input onChange={this.inputChange} placeholder='请输入车牌搜索' />
            {/*<RangePicker showTime defaultValue={[moment(new Date(start_time) , 'YYYY-MM-DD HH:mm:ss'), moment(new Date(end_time), 'YYYY-MM-DD HH:mm:ss')]} format={'YYYY-MM-DD HH:mm:ss'} onChange={this.dateChange} />*/}
            <Button type="primary" onClick={ () => {this.getSearchData()}}>搜索</Button>
          </div>
        </div>
        <Table style={{height: 'calc(100% - 50px)', overflowY: 'auto', flexGrow: 1}} bordered={true} columns={state.columns} dataSource={state.dataSource}
               pagination={{
                 current: state.currentPage,
                 pageSize: state.limit,
                 total: state.totalRow,
                 showSizeChanger: state.limit,
                 onShowSizeChange: () => this.onShowSizeChange,
                 showTotal: () => {return `共${state.totalRow}条`}
               }}
               scroll={{x: 'max-content'}}
               onChange={this.getInitData}
        />
        {
          state.img_flag ? <ShowImages data={{ data: state.dataImages, width: 480}} callback={this.HiddenImages.bind(this)} /> : null
        }
        <Modal
          title={state.modal_title}
          visible={state.show_flag}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          width={1200}
          height={600}
          footer={null}
          destroyOnClose={true}
          bodyStyle={{height: 600, overflow: 'auto'}}
        >
          <TransPortCarShowMsg data={{data: state.detailData}} />{/*, dataArr: dataArr*/}
        </Modal>
        <Modal
          title={state.modal_title}
          visible={state.edit_flag}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          width={1200}
          height={600}
          footer={null}
          destroyOnClose={true}
          bodyStyle={{height: 600, overflow: 'auto'}}
        >
          <TransPortCarEditMsg data={{data: state.detailData}} editTransCarData={this.editTransCarData.bind(this)} onCancel={this.hideModal.bind(this)} BindDriverInfo={this.BindDriverInfo.bind(this)} DeleteCarDriverMsg={this.DeleteCarDriverMsg.bind(this)} />
        </Modal>
      </div>
    )
  }
}
