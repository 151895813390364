import React from 'react';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';

import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';

import debug from 'utils/debug';

const dataSource = new DataSource('/web/transport_car/showIllegalList');

function Illegals() {
  debug.render('Illegals');

  const columns = [
    serial(true),
    { title: '车牌号', dataIndex: 'car_number', width: width(7), fixed: 'left' },
    { title: '违法时间', dataIndex: 'start_time', width: width(0, 20) },
    { title: '违法地点', dataIndex: 'ille_address' },
    { title: '违法内容', dataIndex: 'content' },
    { title: '违法记分', dataIndex: 'ille_count' },
    { title: '车辆所属单位', dataIndex: 'transport_name' },
  ];

  const querys = [
    { title: '车牌、地点', key: 'keyword' },
    { title: ['违法', '时间'], key: 'start_time', type: 'date-range', showTime: true },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}

export default Illegals;
