import React, {useEffect, useState} from 'react';
import { Route, Link } from 'react-router-dom';
import { Table, Button, Input, Select, Modal, message } from 'antd';
import moment from 'moment';
import user from 'stores/user';
import styles from './GPSOnline.scss';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';

import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';
import LinkButton from 'ui/TableCell/LinkButton';

import debug from 'utils/debug';
import request from "utils/request";
import render from "less/lib/less/render";

// const timeFormat = 'YYYY-MM-DD HH:mm:ss';
// const defaultTime = [moment().subtract(1, 'months'), moment()].map(m => m.format(timeFormat));
const current = moment().format('YYYY-MM-DD');
const first = moment().startOf('month').format('YYYY-MM-DD');
// const defaultTime = current === first ? [current, current] : [moment().startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')];
const defaultTime = [moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'), moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD')];
const transportationsDataSource = new DataSource('/web/cases/onlineAjaxArea');

function Transportations() {
  debug.render('Transportations');

  const columns = [
    serial(true),
    { title: '运企名称', dataIndex: 'name' },
    { title: '在线率', dataIndex: 'value', width: width(10), align: 'center', render: rate => `${(parseFloat(rate) * 100).toFixed(0)}%` },
    actions({
      isFixed: true,
      render: ($, { id, name }) => (
        <LinkButton
          to={{
            pathname: `./${id}/cars`,
            state: {
              name,
              time: transportationsDataSource.params.time,
            },
          }}
        >查看</LinkButton>
      ),
    }),
  ];

  const querys = [
    { title: ['间', '范围'], key: 'time', type: 'time-range', format: 'YYYY-MM-DD' },
  ];

  const buttons = [
    { title: '导出', download: '/web/cases/onlineAjaxAreaExcel' },
  ];

  return (
    <TablePage
      dataSource={transportationsDataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
      // 相当于缓存了间范围参数
      defaultQuery={{
        time: transportationsDataSource.params.time || defaultTime,
      }}
      ratio={true}
    />
  );
}

const carsDataSource = new DataSource('/web/cases/onlineAjaxTransport');

function Cars({ match, location, history }) {
  debug.render('Cars');

  const { name, time } = location.state;
  const transportId = match.params.id;
  const queryParams = { transportId, time };

  const columns = [
    serial(),
    { title: '车牌号', dataIndex: 'plate_num', width: width(10) },
    { title: '在线天数', dataIndex: 'online_num', width: width(10) },
    { title: '离线天数', dataIndex: 'offline_num', width: width(10) },
    { title: '在线率', dataIndex: 'value', render: rate => `${(parseFloat(rate) * 100).toFixed(0)}%` },
  ];

  const buttons = [
    { title: '导出', download: '/web/cases/onlineAjaxTransportExcel' },
  ];

  return (
    <TablePage
      rowKey="serial"
      dataSource={carsDataSource}
      buttons={buttons}
      columns={columns}
      defaultLimit={10}
      extraParams={queryParams}
      backTitle={name}
      onBack={history.goBack}
    />
  );
}

function getByName(data, name) {
  return data.find(item => item.name === name)
}

function Transport() {
  const areaId = user.getState().info.area_id;
  const offlineOptions = [ //1=>生效 2=>审核中 3=>未申报
    { value: 1, label: '生效中' },
    { value: 2, label: '审核中' },
    { value: 3, label: '未申报' },
  ];
  const [selectedRow, setSelectedRow] = useState([]);
  const [rowKeys, setRowKeys] = useState([]);
  const [selectedCars, setCars] = useState([]);
  const [visible, setVisible] = useState(false);

  const [data, setData] = useState(carsDataSource.dataSource);
  useEffect(() => {
    return carsDataSource.subscribe(() => {
      setData(carsDataSource.dataSource);
    });
  }, []);

  const columns = [
    serial(true),
    { title: '当前GPS离线申报', dataIndex: 'report_status', align: 'center', width: width(9), fixed: "left", render: (t) =>( //1=>生效 2=>审核中 3=>未申报
      t === 1 ?
      <span className={styles.efficient}>生效中</span> :
      t === 2 ?
      <span className={styles.checking}>审核中</span> :
      <span className={styles.undeclared}>未申报</span>
    ) },
    { title: '车牌号', dataIndex: 'plate_num', align: 'center', fixed: "left", width: width(7) },
    { title: 'SIM卡号(唯一标识符)', dataIndex: 'device_num', align: 'center', fixed: "left", width: width(10), },
    { title: '最近10分钟是否在线', dataIndex: 'curr_online', align: 'center', fixed: "left", width: width(10), render: (t) => (t === 1 ? <span className={styles.onLine}> </span> : <span className={styles.outLine}> </span>) },
    { title: '在线率',
      children: data.data && data.data.length > 0 && data.data[0].onlineRates.map(item => {
        return {
          title: item.name,
          render: (_, row) => {
            const onlineRate = getByName(row.onlineRates, item.name);
            return !onlineRate ? 0 : onlineRate.type === 2 ? (<span className={styles.on}>已恢复在线</span>) :
              onlineRate.type === 3 ? (<span className={styles.out}>已申报离线</span>) :
              onlineRate.type === 5 ? (<span>-</span>) :
              (<span>{onlineRate.rate !== 0 ? onlineRate.rate + '%' : onlineRate.rate}</span>)
          }
        };
      })
    },
    { title: '平均在线率', dataIndex: 'rate', sort: true, sortAsKey: 'order', sortDefaultValue: 1, render:(t, row) => (<span>{row.avgRate.rate === 0 ? 0 : row.avgRate.rate + '%'}</span>)},
    { title: 'GPS离线段', dataIndex: 'report_offline_time', align: 'center', fixed: "right", width: width(15), },
    { title: '驾驶员', dataIndex: 'driver_name', align: 'center', fixed: "right", width: width(4), },
    { title: '联系电话', dataIndex: 'driver_phone', align: 'center', fixed: "right", width: width(7), },
    actions({
      isFixed: true,
      align: 'center',
      width: width(4),
      render: ($, { report_status, plate_num }) => (
        report_status === 1 ?
        <Button size="small" style={{ backgroundColor: '#FD332D', color: '#FFFFFF' }}><Link to={{pathname: './recover', state: {car_num: plate_num}}}>恢复在线</Link></Button> :
        null
      ),
    }),
  ];

  const querys = [
    { title: '车牌，驾驶员搜索', key: 'keyword' },
    { title: 'GPS离线申报', key: 'report_status', type: 'select', options: offlineOptions, style: {width: 200} },
    { title: '最近10分钟是否在线', key: 'curr_online', type: 'select', options: [{ value: 1, label: '在线' }, { value: 2, label: '离线' }], style: {width: 200} },
    { title: ['时间', '范围'], key: 'time', type: 'online-range', format: 'YYYY-MM-DD' },
  ];
  const buttons = [
    { title: '导出', download: '/web/cases/onlineAjaxTransportExcel' },
    { title: '批量恢复在线', element: isBatch() },
  ];

  function isBatch() {
    if (selectedRow.length === 0 || selectedRow.length === 1) {
      return (
        <Button style={{ backgroundColor: '#FD332D', color: '#FFFFFF', opacity: 0.5 }} disabled>批量恢复在线</Button>
      );
    } else {
      return (
        // <Link to={{pathname: './recover', state: {car_num: selectedCars}}}><Button style={{ backgroundColor: '#FD332D', color: '#FFFFFF' }}>批量恢复在线</Button></Link>
        <Button style={{ backgroundColor: '#FD332D', color: '#FFFFFF' }} onClick={() => {setVisible(true)}}>批量恢复在线</Button>
      );
    }
  }
  const onSelectChange = (rowKeys, rows) => {
    setRowKeys(() => {
      return rows.map(({ plate_num }) => plate_num)
    });
    setSelectedRow(rows);
    const tableData = carsDataSource.dataSource.data;
    let data = [];
    tableData.map(({ plate_num, ...rest }) => {
      rows.map((v) => {
        if(plate_num === v.plate_num) {
          data.push(plate_num)
        }
      })
    });
    setCars(data);
  };
  const rowSelection = {
    selectedRow,
    selectedRowKeys: rowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.report_status !== 1
    }),
    hideDefaultSelections: true,
  };
  const onFinish = () => {
    request('/web/report/recoveryOnline', {cars: selectedCars})
      .then(res => {
        if(res.data.code === 200) {
          setSelectedRow([]);
          setCars([]);
          setRowKeys([]);
          setVisible(false);
          message.success(res.data.msg);
          carsDataSource.refresh();
          // back();
        } else{
          message.warn(res.data.msg);
        }
      });
  };

  return (
    <>
      <TablePage
        dataSource={carsDataSource}
        buttons={buttons}
        querys={querys}
        columns={columns}
        defaultLimit={10}
        // 相当于缓存了间范围参数
        defaultQuery={{
          time: defaultTime,
        }}
        extraParams={{
          id: user.getState().info.user_instance[0].instance_id,
        }}
        ratio={true}
        rowSelection={rowSelection}
        rowKey="plate_num"
        gpsText={areaId === 13 ? true : false}
    />

      <Modal
        title="确认该车辆提前恢复上线"
        visible={visible}
        centered
        bodyStyle={{ maxHeight: '80vh', overflowY: 'auto', }}
        onOk={() => onFinish()}
        onCancel={() => setVisible(false)}
      >
        <div className={styles.cars}>
          {
            selectedCars.map((car_num, i) => (
              <div key={i} className={styles.item}>{car_num}</div>
            ))
          }
        </div>
      </Modal>
    </>
  );
}

function Recover({location, history}) {
  const back = () => history.goBack();
  const cars = location.state.car_num;
  const data = [];
  if(Array.isArray(cars)) {
    cars.map((car_num) => {
      data.push(car_num);
    })
  } else {
    data.push(cars);
  }
  const onFinish = () => {
    request('/web/report/recoveryOnline', {cars: data})
      .then(res => {
        if(res.data.code === 200) {
          message.success(res.data.msg);
          carsDataSource.refresh();
          back();
        } else{
          message.warn(res.data.msg);
        }
      });
  };
  return (
    <Modal
      title="确认该车辆提前恢复上线"
      visible
      centered
      bodyStyle={{ maxHeight: '80vh', overflowY: 'auto', }}
      onOk={onFinish}
      onCancel={back}
    >
      <div className={styles.cars}>
        {
          data.map((car_num, i) => (
            <div key={i} className={styles.item}>{car_num}</div>
          ))
        }
      </div>
    </Modal>
  )
}

export default function GPSOnline({ path }) {
  debug.render('GPSOnline');
  return user.getState().roles.id === 101 ? (
    <>
      <Route path={path} component={Transport}/>
      <Route path={path + 'recover'} component={Recover}/>
    </>
    // <Transport />
  ) : (
    <>
      <Route path={path} exact component={Transportations} />
      <Route path={path + ':id/cars'} component={Cars} />
    </>
  );
}
