import React from 'react';
import { Button, Divider, Icon, Tag } from 'antd';
import user from 'stores/user';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import LinkButton from 'ui/TableCell/LinkButton';
import ConfirmButton from 'ui/TableCell/ConfirmButton';
import DeleteButton from 'ui/TableCell/DeleteButton';
import width from 'ui/TableCell/width';
import actions from 'ui/TableCell/actions';
import debug from 'utils/debug';
import { rowSpanRender as render } from 'utils/render';
import { labels, colors } from 'variables/auditStatus';

export const dataSource = new DataSource('/web/route/getConcretePlanRouteLists', {}, (data) => {
  return data.reduce((flatten, { routes, ...plan }, index) => {
    const serial = index + 1;
    const key = i => `${plan.id}-${i}`;
    const span = i => (i === 0 ? routes.length : 0);
    return [
      ...flatten,
      ...routes.map((route, i) => ({
        ...route,
        ...plan,
        serial,
        key: key(i),
        span: span(i),
      })),
    ];
  }, []);
});

export default function List() {
  debug.render('List');

  const columns = [
    { title: '序号', dataIndex: 'serial', width: 46, align: 'center', render },
    { title: '项目名称', dataIndex: 'start_name', render },
    { title: '终点名称', dataIndex: 'end_name' },
    { title: '物品', dataIndex: 'materiel_name' },
    { title: '开始时间', dataIndex: 'transport_start_date', render: cell => cell && cell.slice(0, 10) },
    { title: '开始时间', dataIndex: 'transport_end_date', render: cell => cell && cell.slice(0, 10) },
    { title: '申报状态', dataIndex: 'status', width: 100, render: render(status => labels[status]) },
    { title: '申报时间', dataIndex: 'created_time', render: render(cell => cell && cell.slice(0, 10)) },
    { title: '审核时间', dataIndex: 'audit_time', render: render(cell => cell && cell.slice(0, 10)) },
    { title: '审核人', dataIndex: 'audit_name', render },
    actions({
      width: 170,
      render: render(($, { id, status }) => (
        <>
          <LinkButton to={`./${id}/detail`}>查看详情</LinkButton>
          {(user.getState().roles.id === 107 && (status === 1 || status === 3)) && (
            <>
              <Divider type="vertical" />
              <LinkButton to={`./${id}/update`}>修改</LinkButton>
            </>
          )}
          {(user.getState().roles.id === 107 && (status === 1 || status === 3)) && (
            <>
              <Divider style={{ margin: '10px 0' }} />
              <ConfirmButton title="确认提交？" api="/web/plan/commitConcretePlanDeclare" concrete_plan_id={id} dataSource={dataSource}>提交申报</ConfirmButton>
            </>
          )}
          {user.getState().roles.id === 107 && status === 1 && (
            <>
              <Divider type="vertical" />
              <DeleteButton api="/web/route/deleteConcretePlanRouteList" concrete_plan_id={id} dataSource={dataSource} />
            </>
          )}
        </>
      )),
    }),
  ];

  const querys = [
    { title: '关键字', key: 'keyword' },
  ];

  const buttons = [];
  if (user.getState().roles.id === 107) buttons.push({ title: '新增', link: './create', icon: 'plus' });

  return (
    <TablePage
      rowKey="key"
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}
