import React, { useState } from 'react';
import {message, Modal, Select} from 'antd';
import Uploader from "../Uploader";
import { reasonArr  } from '../share';
import { dataSource} from "./Car";
import styles from './index.scss';
import request from 'utils/request';

export default function ChangeToBlack({ history, location }){
  const { state : { data } } = location;
  const [fileList, setFileList] = useState([]);
  const [reason, setReason] = useState();
  const back = () => history.goBack();
  const onSubmit = () => {
    const car_ids = data.map(({id}) => id);
    const json = {
      car_ids: car_ids,
      reason: reason,
      images: fileList,
      switch_type: 1
    }
    request('/web/gb_list/carSwitch', {...json})
      .then(body => {
        if(body.data.code === 200){
          message.success(body.data.msg);
          dataSource.refresh();
          back();
        }else{
          message.warn(body.data.msg)
        }
      })
  };

  const disabled = !reason || fileList.length === 0;
  return (
    <Modal
      title={'转黑名单'}
      visible={true}
      onCancel={back}
      onOk={onSubmit}
      width={750}
      bodyStyle={{ height: '50vh', overflow: 'auto'}}
      okButtonProps={{ disabled }}
    >
      <div className={styles.carList}>
        <span>已选车辆</span>
        <div>
          {
            data.map(({ car_number }, i) => <span key={i}>{car_number}</span>)
          }
        </div>
      </div>
      <div className={styles.greyClass}>
        <span>请选择事由</span>
        <Select
          style={{ width: 400 }}
          value={reason}
          allowClear
          placeholder={'请选择事由'}
          onChange={(e) => setReason(e)}
        >
          {
            reasonArr.map(({ value, label }) => (
              <Select.Option value={value} key={value} >{label}</Select.Option>
            ))
          }
        </Select>
      </div>
      <div className={styles.greyClass}>
        <span>证据上传<label>(最多上传5条)</label> </span>
        <Uploader value={fileList} maxUploadLength={5} onChange={(e) => setFileList(e)} />
      </div>
    </Modal>
  )
}
