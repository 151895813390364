// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2KCDcPbXe5QhUeFe5EdbmN {\n  margin: 0;\n  padding: 1em 0 0 0;\n  font-size: 12px;\n  color: red;\n}", ""]);
// Exports
exports.locals = {
	"error": "_2KCDcPbXe5QhUeFe5EdbmN"
};
module.exports = exports;
