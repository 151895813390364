/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { Modal, Table, Pagination } from 'antd';
import { previewable } from 'components/Previewer';
import RoutesPreview from 'components/Map/RoutesPreview';

import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';

import LinkButton from 'ui/TableCell/LinkButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';

import request from 'utils/request';

export default function ModalReports({ materiel_id, transport_id, unit_price, query, onClose }) {
  const columns = [
    serial(true),
    { title: '联单编号', dataIndex: 'number', width: width(7), fixed: 'left' },
    { title: '项目', dataIndex: 'plan_name' },
    { title: '运企', dataIndex: 'transport_name' },
    { title: '车辆', dataIndex: 'car_num', width: width(7) },
    { title: '起点', dataIndex: 'start_name' },
    { title: '开始时间', dataIndex: 'start_time', width: width(10) },
    { title: '终点', dataIndex: 'end_name' },
    { title: '结束时间', dataIndex: 'end_time', width: width(10) },
    { title: '物品', dataIndex: 'materiel_name', width: width(8) },
    { title: '运费单价（元）', dataIndex: 'unit_price', width: width(8) },
    { title: '方量', dataIndex: 'volumn', width: width(4) },
    { title: '得分', dataIndex: 'score', width: width(4) },
    { title: '运费总价（元）', dataIndex: 'total_price', width: width(8) },
    { title: '运营费单价（元）', dataIndex: 'service_price', width: width(8) },
    { title: '运营费总价（元）', dataIndex: 'service_total_price', width: width(8) },
  ];

  const [{ dataSource, total }, setData] = useState({});
  const [page, setPage] = useState(1);
  const limit = 10;

  useEffect(() => {
    if (!materiel_id) {
      setData({});
      setPage(1);
      return;
    }

    request('/web/settlement/settlementIndex', { materiel_id, transport_id, unit_price, ...query, page, limit })
      .then(body => body.data.data)
      .then(({ total, data }) => {
        setData({
          total,
          dataSource: data.map((item, index) => ({ ...item, serial: limit * (page - 1) + index + 1 })),
        });
      });
  }, [materiel_id, transport_id, unit_price, page]);

  function onPaginationChange(nextPage) {
    setPage(nextPage);
  }

  return (
    <Modal
      centered
      title="结算报表"
      visible={!!materiel_id}
      width={1200}
      onCancel={onClose}
      bodyStyle={{
        padding: 0,
        maxHeight: '80vh',
        overflow: 'auto',
      }}
      footer={(
        <Pagination
          pageSize={limit}
          current={page}
          total={total}
          onChange={onPaginationChange}
          onShowSizeChange={onPaginationChange}
        />
      )}
    >
      <Table
        rowKey="id"
        size="small"
        style={{ width: 1200 }}
        scroll={{ x: 'max-content' }}
        bordered={false}
        pagination={false}
        dataSource={dataSource}
        columns={columns}
      />
    </Modal>
  );
}
