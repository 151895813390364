import React from 'react';
import { previewable } from 'components/Previewer';
import user from 'stores/user';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import LinkButton from 'ui/TableCell/LinkButton';
import serial from 'ui/TableCell/serial';
import width from 'ui/TableCell/width';
import actions from 'ui/TableCell/actions';
import {statusColors, statusOption} from "variables/auditStatus";
import {rowSpanRender as render} from "utils/render";

export const dataSource = new DataSource('/web/route/getSsPlanLists', {plan_type: 1});
const Status = [ //1草稿 2待审核 3已通过 4已驳回 5已失效
  // { value: 1, label: '草稿' },
  { value: 2, label: '待审核' },
  { value: 3, label: '已通过' },
  { value: 4, label: '已驳回' },
  { value: 5, label: '已失效' },
];
const Type = [
  { value: 1, label: '临时线路备案' },
  { value: 2, label: '临时线路备案' },
];

function List() {
  const columns = [
    serial(true),
    { title: '申请方', dataIndex: 'transport_name' },
    { title: '运输起点名称', dataIndex: 'start_name' },
    { title: '运输终点名称', dataIndex: 'end_name_string' },
    { title: '运输品类', dataIndex: 'materiel_name' },
    { title: '排放总量(方)', dataIndex: 'out_volume' },
    { title: '车辆调用', dataIndex: 'car_count', render: (t) => (
        <>
          <p>本运企：{t.split(',')[0]}辆</p>
          <p>外调运企：{t.split(',')[1]}辆</p>
        </>
      )
    },
    { title: '申请运输时限', dataIndex: 'times', render: (t, {start_date, end_date}) => (
        <div style={{textAlign: 'center'}}>
          <p>{start_date}</p>
          <p>至</p>
          <p>{end_date}</p>
        </div>
      )
    },
    { title: '发起时间', dataIndex: 'created_time' },
    actions({
      width: 120,
      render: render(($, { id, status, plan_type }) => (
        <LinkButton to={`./${plan_type}/${id}/detail`}>查看详情</LinkButton>
      )),
    }),
  ];
  const querys = [
    { title: '运企', key: 'transport_id', type: 'select', options: '/web/blacklist/getTransportLists', valueKey: 'id', labelKey: 'name', style: { width: 200 } },
    { title: '备案状态', key: 'plan_status', type: 'select', options: Status, style: { width: 150 } },
    { title: ['时间', '范围'], key: 'time', type: 'time-range', format: 'YYYY-MM-DD' },
    { title: '关键字', key: 'keyword' },
  ];
  const buttons = [];

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  )
}

export default previewable(List);
