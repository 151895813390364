import React, { useState, useEffect } from 'react';
import request from 'utils/request';
import styles from './index.scss';
import { Button, Input, Spin, Cascader } from "antd";

/** 源头回溯-Left*/
export default function LeftList({ getLeftListInfo, closebottom }) {
  const [list, setList] = useState([])
  const [newList, setNewList] = useState([])
  const [loading, setLoading] = useState(true)
  const [lookSave, setLookSave] = useState(null)
  const [changeType, setChangeType] = useState(2)
  const [cityList, setCityList] = useState([])
  const [leftSearchCity, setLeftSearchCity] = useState('')
  const [leftSearchValue, setLeftSearchValue] = useState('')
  useEffect(() => { //getLeftList
    request('/web/car_transport/getPolygons', { area_type: changeType })
      .then(body => {
        setList(body.data.data)
        setNewList(body.data.data)
        setLoading(false)
      })
  }, [changeType]);
  /**
   * LeftTitle
   * 1：工地天网
   * 2：城市天网
   */
  function changeTypes(e) {//更改type
    let typeNumber = Number(e.target.id)
    setChangeType(typeNumber)
    setLookSave(null)
    setLoading(true)
    getLeftListInfo({
      changeTypeFlag: true,
      showSeries: true,
    })
    closebottom()
    setTimeout(() => {
    }, 1000);
  }

  /** “查看”Botton */
  function checkTheArea(e) {
    let id = Number(e.target.id)
    setLookSave(id)
    closebottom()
    list.map((item, index) => {
      if (index === id) {
        getLeftListInfo(
          {
            mapPoints: item.points,
            flag: true,
            lookSave: id,
          })
      }
    })
  }
  /** getCity */
  useEffect(() => {
    request('/web/car_transport/getArea')
      .then(body => {
        setCityList(body.data.data)
      })
  }, []);
  /** CityList */
  const options = cityList.map(item =>
    ({
      value: item.name,
      label: item.name
    }))
  /** CityChoice */
  function city(value) {
    if (value.length > 0) {
      setLeftSearchCity(value[0])
    } else {
      setLeftSearchCity('')
    }
  }
  /** CityFilter */
  function leftSearch() {
    let arr = []
    list.forEach(item => {
      if (item.area_name === leftSearchCity && (item.name.indexOf(leftSearchValue) > -1)) {
        arr.push(item)
      } else if (leftSearchCity === '' && leftSearchValue === '') {
        arr.push(item)
      } else if (leftSearchCity === '' && (item.name.indexOf(leftSearchValue) > -1)) {
        arr.push(item)
      }
      if (leftSearchCity === '成都市' && leftSearchValue === '') {
        arr.push(item)
      }
    })
    setNewList(arr)
    setLookSave(null)
    closebottom()
    getLeftListInfo({ changeTypeFlag: true })
  }
  return (
    <div className={styles.save}>
      <div style={{ borderBottom: '1px solid #d4d4d4', marginBottom: 10 }}>
        <p style={{ marginBottom: 18 }}>
          <span id={2} onClick={changeTypes} style={{ color: changeType == 2 ? 'inherit' : '#AAAAAA', cursor: 'pointer' }}>城市天网</span>
          <span id={1} onClick={changeTypes} style={{ marginLeft: 23, color: changeType == 1 ? 'inherit' : '#AAAAAA', cursor: 'pointer' }}>工地天网</span>
        </p>
        <Cascader
          options={options}
          onChange={city}
          className={styles.city}
          placeholder='请选择区（市）县'
        />
        <Input placeholder='请输入名称搜索' onChange={(e) => { setLeftSearchValue(e.target.value) }} style={{ marginTop: 18, marginBottom: 18 }} />
        <Button type='primary' onClick={leftSearch} style={{ width: '100%' }} >确认搜索</Button>
      </div>
      <div style={{ maxHeight: 576, overflowY: 'auto', paddingTop: 10 }} className={styles.saveOver}>
        <Spin spinning={loading}>
          {
            (newList.length > 0) ?
              newList.map((item, index) => {
                return <div className={styles.history} key={index} style={{ background: index === lookSave ? '#EBF9FF' : '#fafafa' }}>
                  <div style={{ height: 126, paddingTop: 10 }}>
                    <div style={{ fontSize: 16, marginBottom: 30, marginBottom: 30, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>名称:{item.name}</div>
                    <div className={styles.smallName}></div>
                    <div className={styles.smallName}>
                      <span>区域:{item.area_name}</span>
                      <div style={{ width: 66, float: 'right' }}>
                        <Button id={index} className={styles.botton} style={{ marginRight: 5, background: '#02A7F0' }} onClick={checkTheArea}>查看</Button></div>
                    </div>
                  </div>
                </div>
              }) : <div style={{ textAlign: 'center' }}>暂无数据 </div>
          }
        </Spin>
      </div>
    </div>
  )
}