import React from 'react';
import { Divider } from 'antd';
import { previewable } from 'components/Previewer';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import NewDeleteButton from 'ui/TableCell/NewDeleteButton';
import LinkButton from 'ui/TableCell/LinkButton';
import width from 'ui/TableCell/width';
import serial from 'ui/TableCell/serial';
import actions from 'ui/TableCell/actions';

export const dataSource = new DataSource('/river/river_project/getRiverProjectCarLists');

function List({ priview }) {
  const CarEffect = [
    { label: '生效', value: 1 },
    { label: '失效', value: 2 },
  ];
  const columns = [
    serial(true),
    { title: '项目名称', dataIndex: 'project_name' },
    { title: '运企名称', dataIndex: 'transport_name' },
    { title: '车辆号牌', dataIndex: 'plate_num', width: width(6) },
    { title: '是否生效', dataIndex: 'is_active', width: width(4), render: (v) => v === 1 ? '是' : '否' },
    { title: '自编号', dataIndex: 'car_id', width: width(4) },
    { title: '核定方量', dataIndex: 'volumn', width: width(4) },
    { title: 'RFID标签', dataIndex: 'rfid_num' },
    { title: '更新时间', dataIndex: 'updated_time', width: width(9) },
    { title: '更新人', dataIndex: 'updated_name', width: width(5) },
    actions({
      width: width(15),
      render: ($, {id, plate_num, transport_id, is_active, transport_name}) => (
        <>
          <LinkButton to={`./${id}/${plate_num}/update`} />
          {
            is_active === 1 && (
              <>
                <Divider type="vertical" />
                <LinkButton to={{ pathname: './change', state: { id, transport_id, plate_num, transport_name }}} children="运企变更" />
              </>
            )
          }
          <Divider type="vertical" />
          <NewDeleteButton title="确认删除该项目车辆信息?" type="danger" api="/river/river_project/deleteRiverProjectCarList" river_project_car_id={id} dataSource={dataSource}>删除</NewDeleteButton>
        </>
      )
    })
  ];
  const querys = [
    { title: ['更新', '时间'], key: 'times', type: 'date-range', format: 'YYYY-MM-DD', style: { width: 220 } },
    { title: '请选择项目', key: 'project_id', type: 'select', options: '/river/river_project/getRiverProjectOption', valueKey: 'id', labelKey: 'name', style: { width: 240 } },
    { title: '请选择运企', key: 'transport_id', type: 'select', options: '/river/river_base/getRiverTransportOption', valueKey: 'id', labelKey: 'name', style: { width: 300 } },
    { title: '请选择车辆是否生效', key: 'is_active', type: 'select', options: CarEffect, style: { width: 180 } },
    { title: '请输入车辆号牌、自编号、RFID标签、更新人模糊搜索', key: 'keyword', style: { width: 420 } },
  ];

  const buttons = [
    { title: '新增', link: './create', icon: 'plus'},
    { title: '导出', download: '/river/river_project/exportRiverProjectCarLists' },
  ];

  return (
    <TablePage
      dataSource={dataSource}
      buttons={buttons}
      querys={querys}
      columns={columns}
      defaultLimit={10}
    />
  );
}

export default previewable(List)
