import React, { useMemo } from 'react';
import { Divider, Icon } from 'antd';
import { Link } from 'react-router-dom';
import DataSource from 'ui/DataSource';
import TablePage from 'ui/TablePage';
import serial from 'ui/TableCell/serial';
import debug from 'utils/debug';
import store from 'stores/user';
import moment from 'moment';
import actions from 'ui/TableCell/actions';
import { previewable } from 'components/Previewer';
import LinkButton from 'ui/TableCell/LinkButton';
import { useState } from 'react';
import { useEffect } from 'react';
import request from 'utils/request';
import { outInstanceIds } from '../ToBeConfirmed/List';
import { Modal, Select } from 'antd';
import styles from './index.scss';
const setSandstone = JSON.parse(sessionStorage.getItem('setSandstone'));
export const dataSource = new DataSource('/web/human_ticket/list', {
  instance_id: setSandstone ? setSandstone.instance_id : undefined,
  sort: 'asc',
  status: 1,
  check_status: 1,
  // instance_id: setSandstone ? setSandstone.instance_id : undefined,
  created_time: [
    moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ],
});
// export const dataSource = new DataSource('/web/ticket/index', { account_source: 0 });
const {Option} = Select;

export default previewable(function ElectronicStrip() {
  debug.render('SandCehicleList');
  const [modal_flag, setModalFlag] = useState(false);
  const [ins, setIns] = useState({});
  const [instanceArr, setInstanceArr] = useState([]);
  const setSandstone = useMemo(() => JSON.parse(sessionStorage.getItem('setSandstone')), []);
  const storeMenu = useMemo(() => store.getState().menu.includes(243), []);

  useEffect(() => {
    if (storeMenu) return;
    if(setSandstone && setSandstone.instance_id) {
      setIns({ ...setSandstone });
      // this.onInstanceChange({}, {props: {value: setSandStone.instance_id, title: setSandStone.department_id, instance_name: setSandStone.instance_name, materielType: setSandStone.materielType}})
    } else{
      setModalFlag(true);
    }
  }, []);
  
  // useEffect(() => {
  //   const json = { ...dataSource.params, instance_id: ins.instance_id };
  //   dataSource.getParams(json);
  //   dataSource.query(json);
  // }, [ins]);

  const directories = [
    // { value: 0, label: '无' },
    { value: 'desc', label: `按上传时间倒序排列` },
    { value: 'asc', label: `按上传时间正序排列` },
  ];
  const statusArr = [
    { value: 0, label: '全部' },
    { value: 1, label: `待签收` },
    { value: 2, label: `终点已签收` },
    { value: 3, label: `异常处理` },
  ];
  const checkStatusArr = [
    { value: 0, label: '全部' },
    { value: 1, label: '未量方' },
    { value: 2, label: '已量方' },
    { value: 3, label: '已扣方' },
  ]
  const columns = storeMenu ? [
    serial(true),
    { title: '项目名称', dataIndex: 'start_name' },
    { title: '目的地', dataIndex: 'end_name' },
    { title: '车牌号', dataIndex: 'plate_num' },
    { title: `车辆类型`, dataIndex: 'car_type_text' },
    { title: '物料类型', dataIndex: 'material_name' },
    { title: '上传时间', dataIndex: 'created_time' },
    { title: '是否超时', dataIndex: 'is_check_overtime', render: (s) => <span style={{ color: s === 2 ? 'red' : 'green'}}>{['', '否', '是'][s]}</span>},

    // { title: '是否超时', dataIndex: 'is_check_overtime', render: (s) => ['', '否', '是'][s] },
    { title: '签收状态', dataIndex: 'status_text' },
    actions({
      isFixed: true,
      width: 84,
      render: ($, { id, status }) => (
        <>
          <LinkButton to={`./${id}/detail`}>详情</LinkButton>
          {status === 1 && <>
            <Divider type="vertical" />
            <LinkButton to={`./${id}/deal`}>异常处理</LinkButton>
          </>}
        </>
      ),
    }),
  ] : [
    serial(true),
    { title: '车牌号', dataIndex: 'plate_num', render: (value) => <span style={{ fontSize: 18, fontWeight: 'bold' }}>{value}</span> },
    { title: '项目名称', dataIndex: 'start_name' },
    { title: `车辆类型`, dataIndex: 'car_type_text' },
    { title: '物料类型', dataIndex: 'material_name' },
    { title: '上传时间', dataIndex: 'created_time' },
    { title: '是否超时', dataIndex: 'is_check_overtime', render: (s) => <span style={{ color: s === 2 ? 'red' : 'green'}}>{['', '否', '是'][s]}</span>},
    { title: '是否量方', dataIndex: 'quantities_check_status_text' },
    { title: '签收状态', dataIndex: 'status_text' },
    actions({
      isFixed: true,
      width: 184,
      render: ($, { id, status, quantities_check_status }) => (
        <>
          <LinkButton to={`./${id}/detail`}>详情</LinkButton>
          {quantities_check_status === 1 && <>
            <Divider type="vertical" />
            <LinkButton to={`./${id}/checks`} type="primary" border="none">量方</LinkButton>
          </>}          
          {status === 1 && <>
            <LinkButton to={`./${id}/deal`}>异常处理</LinkButton>
          </>}
        </>
      ),
    }),
  ];

  const querys = storeMenu ? [
    { title: '搜索项目名称、车牌号', key: 'keyword' },
    { title: `时间排序`, key: 'sort', type: 'select', options: directories, allowClear: true, style: { width: 200 } },
    { title: `签收状态`, key: 'status', type: 'select', options: statusArr, allowClear: true, style: { width: 200 } },
    { title: `是否量方`, key: 'check_status', type: 'select', options: checkStatusArr, allowClear: true, style: { width: 200 } },
    { title: '工地项目', key: 'instance_id', type: 'select', options: instanceArr, valueKey: 'instance_id', labelKey: 'instance_name', style: { width: 200 } },
    { title: '是否超时', key: 'is_check_overtime', type: 'select', options: [{id: 0, name: '全部'}, {id: 1, name: '否'}, {id: 2, name: '是'}], valueKey: 'id', labelKey: 'name', style: { width: 200 } },
    { title: ['上传', '时间'], key: 'created_time', type: 'date-range' },
  ] : [
    { title: '搜索项目名称、车牌号', key: 'keyword' },
    { title: `时间排序`, key: 'sort', type: 'select', options: directories, allowClear: true, style: { width: 200 } },
    { title: `签收状态`, key: 'status', type: 'select', options: statusArr, allowClear: true, style: { width: 200 } },
    { title: `是否量方`, key: 'check_status', type: 'select', options: checkStatusArr, allowClear: true, style: { width: 200 } },
    { title: '是否超时', key: 'is_check_overtime', type: 'select', options: [{id: 0, name: '全部'}, {id: 1, name: '否'}, {id: 2, name: '是'}], valueKey: 'id', labelKey: 'name', style: { width: 200 } },
    { title: ['上传', '时间'], key: 'created_time', type: 'date-range' },
  ];

  useEffect(() => {
    request({
      url: '/web/instance/getUserInstance',
      data: {area_id: store.getState().info.area_id}
    }).then(res => {
      const data = res.data.data;
      const signal = data.filter(({ instance_id }) => outInstanceIds.includes(instance_id));
      const arr = store.getState().roles.id === 118 ? (signal ? signal : []) : data.filter(({ instance_id, ...rest }) => !outInstanceIds.includes(instance_id) || instance_id === 277);
      setInstanceArr(arr);
    })
  }, []);

  return (
    <React.Fragment>
      {!storeMenu ? <div className={styles.topSelect} onClick={() => setModalFlag(true)}>
        <span>当前开票地点是：{ins.instance_name}</span>
        <span style={{fontSize: 12}}>点击这里可重新选择</span>
      </div> : null}
      <TablePage
        dataSource={dataSource}
        querys={querys}
        columns={columns}
        defaultLimit={10}
        extraParams={{
          instance_id: !storeMenu ? (!ins || !ins.instance_id) ? setSandstone ? setSandstone.instance_id : undefined : ins.instance_id : undefined,
        }}
        defaultQuery={{
          sort: 'asc',
          status: 1,
          check_status: 1,
          // instance_id: setSandstone ? setSandstone.instance_id : undefined,
          created_time: [
            moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          ],
        }}
      />
        <Modal
          title={'选择实例'}
          visible={modal_flag}
          centered={true}
          onCancel={() => setModalFlag(false)}
          width={400}
          footer={null}
          maskClosable={false}
          destroyOnClose={true}
          bodyStyle={{height: 100, overflow: 'auto'}}
        >
          <div className='flex flex-main-end'>
            <Select placeholder="选择实例" value={ins.instance_id} style={{width: 350}} onChange={(val, e) => {
              const d = {instance_id: e.props.value, department_id: e.props.title, instance_name: e.props.children, materielType: e.props.materielType};
              setIns(d);
              dataSource.query({ ...dataSource.params, instance_id: e.props.value });           
              sessionStorage.setItem('setSandstone', JSON.stringify(d))
              setModalFlag(false);
            }}>
              {
                instanceArr.map((v, i) => {
                  return <Option value={v.instance_id} title={v.department_id} materielType={v.materielType} key={i}>{v.instance_name}</Option>
                })
              }
            </Select>
          </div>
        </Modal>
    </React.Fragment>    
  );
})
