import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Select, Input, message, Button } from 'antd';
import { dataSource } from './List';
import request from 'utils/request';
import styles from './../Create.scss';

const { Option } = Select;

function getTransport() {
  const [arr, setArr] = useState([]);
  useEffect(() => {
    request('/river/river_base/getRiverTransportOption')
      .then(body => body.data.data)
      .then(setArr)
  }, []);
  return arr;
}

export default function Create({ history, match }) {
  const back = () => history.goBack();
  const transportArr = getTransport();
  const [transportId, setTransportId] = useState();
  const [driverNumbers, setDriverNumbers] = useState(1);
  const [driverMsg, setDriverMsg] = useState([{ name: '', phone: '', cert_code: '' }]);
  const onFinish = () => {
    request('/river/river_base/createRiverTransportDriverInfo', {river_transport_id: transportId, drivers: driverMsg})
      .then(body => body.data)
      .then(data => {
        if(data.code === 200) {
          message.success(data.msg);
          dataSource.refresh();
          back();
        }else {
          message.warn(data.msg)
        }
      })
  };
  const ModifyMsg = (msg, serial, type) => {
    switch (type) {
      case 1: setDriverMsg((s) => s.map((v, i) => (
        {
          ...v,
          name: serial === i ? msg : v.name
        }
      ))); break;
      case 2: setDriverMsg((s) => s.map((v, i) => (
        {
          ...v,
          phone: serial === i ? msg : v.phone
        }
      ))); break;
      case 3: setDriverMsg((s) => s.map((v, i) => (
        {
          ...v,
          cert_code: serial === i ? msg : v.cert_code,
        }
      ))); break
    }
  };

  const deleteDriver = useCallback((e) => {
    const deleteId = Number(e.target.dataset.value);
    setDriverNumbers((v) => (v - 1));
    setDriverMsg((s) => s.map((v, i) => i === deleteId ? false : {...v}).filter((v) => v))
  }, []);

  const [dis, setDis] = useState(false);
  useEffect(() => {
    driverMsg.map(({ name, phone, cert_code }) => {
      if(name !== '' && phone !== '' && cert_code !== '') {setDis(true) }
      else setDis(false)
    })
  }, [JSON.stringify(driverMsg)])
  return (
    <Modal
      title={"新增驾驶员"}
      visible={true}
      width={600}
      onCancel={back}
      onOk={onFinish}
      okButtonProps={{ disabled: !transportId || !dis }}
    >
      <div className={styles.title}>
        <Select placeholder={'请选择运企'} value={transportId} style={{ width: 250 }} onChange={(e) => setTransportId(e)} >
          {
            transportArr.map(({ id, name }, i) => (
              <Option key={i} value={id}>{name}</Option>
            ))
          }
        </Select>
        <div className={styles.changeNumber}>
          <span>驾驶员数量：{driverNumbers}</span><span onClick={() => {setDriverNumbers((v) => (v + 1)); setDriverMsg((s) => { s.push({ name: '', phone: '', cert_code: ''}); return s } )}}>+</span>
        </div>
      </div>
      <div className={styles.content}>
        <div>
          <span>序号</span>
          <span>驾驶员</span>
          <span>驾驶员电话</span>
          <span>驾驶证号</span>
          <span />
        </div>
        {
          driverMsg.map(({ name, phone, cert_code }, i) => (
            <div key={i}>
              <span><Input value={i + 1} readOnly /></span>
              <span><Input value={name} onChange={(e) => ModifyMsg(e.target.value, i, 1)} /></span>
              <span><Input value={phone} onChange={(e) => ModifyMsg(e.target.value, i, 2)} /></span>
              <span><Input value={cert_code} onChange={(e) => ModifyMsg(e.target.value, i, 3)} /></span>
              <Button data-value={i} onClick={deleteDriver} type="danger" disabled={driverNumbers === 1}>删除</Button>
              {/*<span data-value={i} onClick={deleteDriver}>删除</span>*/}
            </div>
          ))
        }
      </div>
    </Modal>
  );
}
