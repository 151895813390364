/* eslint-disable camelcase */
import React from 'react';
import {
  Modal,
  InputNumber,
  Input,
  Table,
  Tabs,
  Button,
  Divider,
} from 'antd';
import request from 'utils/request';

const calcVolumn = car => (car.inner_width || 0) * (car.inner_height || 0) * (car.inner_long || 0);

export default class PlanEditor extends React.PureComponent {
  state = {
    cars: [],
    updateRecords: [],
  }

  componentDidMount() {
    this.updateDataSource();
  }

  async updateDataSource() {
    const planId = this.props.match.params.id;
    const [cars, updateRecords] = await Promise.all([
      // 砂石接管方案关联车辆列表
      request({ url: '/web/plan_car/dinasList', data: { id: planId } }).then(body => body.data.data.data),
      // 砂石接管方案关联车辆的修改记录列表
      request({ url: '/web/plan_car/getDinasRecord', data: { id: planId } }).then(body => body.data.data.data),
    ]);
    const carsWidthVolumn = cars.map(car => ({ ...car, volumn: calcVolumn(car) }));
    this.setState({ cars: carsWidthVolumn, updateRecords });
  }

  updateRecordsColumns = [
    { title: '车牌号', dataIndex: 'car_num' },
    { title: '旧方量', dataIndex: 'old_volumn' },
    { title: '新方量', dataIndex: 'volumn' },
    { title: '修改人', dataIndex: 'created_by' },
    { title: '修改间', dataIndex: 'created_time' },
    { title: '修改原因', dataIndex: 'reason' },
  ]

  submitCarById(carId) {
    const {
      car_num,
      inner_long: new_inner_long,
      inner_width: new_inner_width,
      inner_height: new_inner_height,
      reason,
    } = this.state.cars.find(car => car.id === carId);
    const { id } = this.props.match.params;
    const data = { id, car_num, new_inner_long, new_inner_width, new_inner_height, reason };
    request({ url: '/web/plan_car/saveDinasNewInfo', data })
      .then(() => this.updateDataSource());
  }
  updateCarById = (id, key, value) => {
    this.setState(state => ({
      cars: state.cars.map((car) => {
        const next = { ...car, [key]: car.id === id ? value : car[key] };
        return { ...next, volumn: calcVolumn(next) };
      }),
    }));
    if (key === 'editable') {
      this.setState(state => ({
        cars: state.cars.map(car => ({ ...car, editable: car.id === id ? value : false })),
      }));
    }
  }
  inputNumberRender = dataIndex => (value, row) => (
    <InputNumber
      precision={2}
      value={value}
      disabled={!row.editable}
      onChange={next => this.updateCarById(row.id, dataIndex, next)}
    />
  )
  inputTextAreaRender = dataIndex => (value, row) => (
    <Input.TextArea
      autosize
      value={value}
      disabled={!row.editable}
      onChange={e => this.updateCarById(row.id, dataIndex, e.target.value)}
    />
  )
  carsColumns = [
    { title: '车牌号', dataIndex: 'car_num' },
    { title: '长(米)', dataIndex: 'inner_long', render: this.inputNumberRender('inner_long') },
    { title: '宽(米)', dataIndex: 'inner_width', render: this.inputNumberRender('inner_width') },
    { title: '高(米)', dataIndex: 'inner_height', render: this.inputNumberRender('inner_height') },
    { title: '方量(立方米)', dataIndex: 'volumn', render: volumn => volumn.toFixed(1) },
    { title: '修改原因', dataIndex: 'reason', render: this.inputTextAreaRender('reason') },
    {
      title: '操作',
      key: 'actions',
      render: ($, { id, editable, inner_long, inner_width, inner_height, reason }) => (!editable ? (
        <Button onClick={() => this.updateCarById(id, 'editable', true)} size="small">修改</Button>
      ) : [
        <Button onClick={() => this.updateCarById(id, 'editable', false)} size="small" key="cancel">取消</Button>,
        <Divider key="divider" type="vertical" />,
        <Button onClick={() => this.submitCarById(id)} disabled={!(inner_long && inner_width && inner_height && reason)} size="small" key="submit" type="primary">提交</Button>,
      ]),
    },
  ]

  render() {
    const {
      updateRecords,
      cars,
    } = this.state;

    // console.log(cars);
    return (
      <Modal
        visible
        centered
        width={1000}
        className="Plan-Cars-Modal"
        onCancel={() => this.props.history.goBack()}
        onOk={() => this.props.history.goBack()}
        footer={null}
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane key="1" tab="车辆列表">
            <Table
              bordered
              rowKey="id"
              pagination={false}
              columns={this.carsColumns}
              dataSource={cars}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab="车辆方量修改记录">
            <Table
              bordered
              rowKey="id"
              pagination={false}
              columns={this.updateRecordsColumns}
              dataSource={updateRecords}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    );
  }
}
