// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2P0OOILqZgmnRicm4iitPD {\n  display: flex;\n  align-items: center;\n}\n._2P0OOILqZgmnRicm4iitPD .FnLe6ZkvG8PiKEY3DmzTI {\n  margin-left: 35px;\n  display: flex;\n  justify-items: center;\n}\n._2P0OOILqZgmnRicm4iitPD .FnLe6ZkvG8PiKEY3DmzTI > span:first-child {\n  display: inline-block;\n  font-size: 12px;\n  border: 1px solid #d9d9d9;\n  padding: 7px 10px;\n  color: #4B505D;\n  width: 140px;\n  height: 32px;\n  box-sizing: border-box;\n  -webkit-border-radius: 4px 0 0 4px;\n  -moz-border-radius: 4px 0 0 4px;\n  border-radius: 4px 0 0 4px;\n}\n._2P0OOILqZgmnRicm4iitPD .FnLe6ZkvG8PiKEY3DmzTI > span:last-child {\n  display: inline-block;\n  font-size: 18px;\n  padding: 3px 20px;\n  color: #ffffff;\n  background-color: #1491FB;\n  height: 32px;\n  box-sizing: border-box;\n  -webkit-border-radius: 0 4px 4px 0;\n  -moz-border-radius: 0 4px 4px 0;\n  border-radius: 0 4px 4px 0;\n  cursor: pointer;\n}\n\n._3iJRikyp5TWnMEfIptH54_ {\n  margin-top: 20px;\n}\n._3iJRikyp5TWnMEfIptH54_ > div {\n  display: grid;\n  grid-template-columns: 80px 150px 150px 1fr 70px;\n  border: 1px solid #d9d9d9;\n}\n._3iJRikyp5TWnMEfIptH54_ > div input {\n  border: none;\n}\n._3iJRikyp5TWnMEfIptH54_ > div > span {\n  border-right: 1px solid #d9d9d9;\n  padding: 6px 12px;\n}\n._3iJRikyp5TWnMEfIptH54_ > div > span:last-child {\n  border: none;\n}\n._3iJRikyp5TWnMEfIptH54_ > div > button {\n  margin: 3px 4px;\n}\n._3iJRikyp5TWnMEfIptH54_ > div:first-child {\n  color: #4B505D;\n}\n._3iJRikyp5TWnMEfIptH54_ > div + div {\n  border-top: none;\n}\n._3iJRikyp5TWnMEfIptH54_._3Nk2k45b_Ng7ef_5NySe0N > div {\n  grid-template-columns: 80px 1fr 70px;\n}", ""]);
// Exports
exports.locals = {
	"title": "_2P0OOILqZgmnRicm4iitPD",
	"changeNumber": "FnLe6ZkvG8PiKEY3DmzTI",
	"content": "_3iJRikyp5TWnMEfIptH54_",
	"carContent": "_3Nk2k45b_Ng7ef_5NySe0N"
};
module.exports = exports;
