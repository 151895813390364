import React from 'react';
import { Route } from 'react-router-dom';
import List from '../ReportSandstoneTakeoverRoutes/Routes';
import Detail from '../ReportSandstoneTakeoverRoutes/RouteDetail/RouteDetail';

export default ({ path }) => (
  <>
    <Route exact path={path} component={List} />
    <Route path={path + ':id/detail'} component={Detail} />
  </>
);
