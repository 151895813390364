import React from 'react';
import styles from '../../paths/SandstoneReceiver/ToBeConfirmed/List.scss';
import { useEffect } from 'react';
import request from "utils/request";
import {InputNumber, Input, message} from "antd";
import { useState } from 'react';

export function CarSize({ plateNum, deduction_volume, onChange }) {
  const [carSize, setCarSize] = useState({ inner_width: 0, inner_long: 0, inner_height: 0, height: 0 });
  /** 获取车辆尺寸 */
  const getCarSize = () => {
    request('web/car/getCarSize', { plateNum })
      .then((body) => {
        const d = body.data.data.data;
        const w = Number(d.inner_width), l = Number(d.inner_long), h = Number(d.inner_height);
        const liangfang = Number((w * l * 0).toFixed(2));
        const volume = Number((liangfang - Number(deduction_volume)).toFixed(2));
        onChange(liangfang, volume);
        setCarSize({
          inner_width: w,
          inner_long: l,
          inner_height: h,
          height: 0,
        })
      })
  }

  useEffect(() => {
    if (!plateNum) return;
    getCarSize();
  }, [plateNum]);

  const getCurrentVolume = (e) => {
    return parseFloat((carSize.inner_width * carSize.inner_long * (e !== undefined ? e : carSize.height)).toFixed(2))
    // if (carSize.height) {
    // }
    // else return 0
  }

  return (
    <div className={styles.sizes}>
      <div>
        <label>长</label>
        <Input disabled value={carSize.inner_long} />
        <span>米</span>
      </div>
      <div>
        <label>宽</label>
        <Input disabled value={carSize.inner_width} />
        <span>米</span>
      </div>
      <div>
        <label>高</label>
        <InputNumber step={0.01} min={0} precision={2} max={parseFloat(carSize.inner_height)}  value={carSize.height} style={{width: 60}} onChange={(e) => {
          const liangfang = getCurrentVolume(e);
          const volume = parseFloat((liangfang - parseFloat(deduction_volume)).toFixed(2));
          onChange(liangfang, volume);
          setCarSize({ ...carSize, height: e });
        }} />
        <span>米</span>
      </div>
      <div className={styles.images} onClick={() => plateNum && getCarSize(plateNum)}>
        <img src="/images/md-refresh@1x.png" />
        <span>刷新</span>
      </div>
    </div>
  );
}
