// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1s5NHafDsK-GwDLQ_yhNql {\n  display: grid;\n  row-gap: 10px;\n  padding-top: 40px;\n  position: relative;\n}\n._1s5NHafDsK-GwDLQ_yhNql ._1EN1EJeo-9aPFzpaxcq4W_ {\n  position: absolute;\n  top: 0;\n  right: 20px;\n}\n._1s5NHafDsK-GwDLQ_yhNql ._18CVciu-K3Bv8H_p2UxY-n {\n  position: relative;\n  border: 1px solid #d4d4d4;\n  border-radius: 4px;\n  padding: 10px 20px 0;\n}\n._1s5NHafDsK-GwDLQ_yhNql ._18CVciu-K3Bv8H_p2UxY-n ._5QZnxtGHYbdjL0VhQ3p9a {\n  position: absolute;\n  top: 10px;\n  right: 20px;\n}\n._1s5NHafDsK-GwDLQ_yhNql ._18CVciu-K3Bv8H_p2UxY-n .ant-select {\n  width: 200px;\n  margin-left: 20px;\n}", ""]);
// Exports
exports.locals = {
	"container": "_1s5NHafDsK-GwDLQ_yhNql",
	"btns": "_1EN1EJeo-9aPFzpaxcq4W_",
	"rows": "_18CVciu-K3Bv8H_p2UxY-n",
	"del": "_5QZnxtGHYbdjL0VhQ3p9a"
};
module.exports = exports;
