import React, { useState, useEffect, useCallback, useMemo } from 'react';
import debug from 'utils/debug';
import request from 'utils/request';
import ModalFormPage from 'ui/ModalFormPage';
import { dataSource, inputs } from './List';
import { Modal, Input, Icon } from 'antd';
import styles from './Create.scss';

function Search({ onCancel, onConfirm }) {
  const [phone, setPhone] = useState('');
  const [status, setStatus] = useState(0);
  const [error, setError] = useState('');
  useEffect(() => setError(''), [phone]);

  const disabled = phone.length !== 11 || [...phone].find(char => Number.isNaN(parseInt(char, 10)));

  const text = ['下一步', <Icon type="loading" />][status];

  const test = useCallback(() => {
    setStatus(1);
    request('/web/driver/check', { phone })
      .then(body => body.data.data)
      .then(({ is_register, is_bind, id }) => {
        setStatus(0);
        if (is_register === 2) return setError('系统检测到此号码已注册，但不是驾驶员账号，您不可以继续新增');
        if (is_bind === 1) return setError('系统检测到此驾驶员已与当前运企绑定，您不可以继续新增');

        switch (is_register) {
          case 0: onConfirm({ phone }); break;
          case 1: onConfirm({ id }); break;
          default: setError('服务器错误，无法识别注册类型'); break;
        }
      })
      .catch(console.error);
  }, [phone]);

  return (
    <Modal visible centered title="驾驶员电话号码" onCancel={onCancel} onOk={test} okText={text} okButtonProps={{ disabled }}>
      <Input value={phone} onChange={e => setPhone(e.target.value)} placeholder="请填写驾驶员电话号码" allowClear />
      {error && <p className={styles.error}>{error}</p>}
    </Modal>
  );
}

function Edit({ onCancel, onConfirm, data: { id, phone } }) {
  const init = id ? ['/web/driver/newGetOne', { id }] : undefined;
  const submit = id ? ['/web/driver/newUpdate', { id }] : ['/web/driver/newCreate'];

  return (
    <ModalFormPage
      title="新增驾驶员信息"
      init={init}
      submit={submit}
      inputs={inputs(true)}
      onCancel={onCancel}
      onFinish={onConfirm}
      defaultValue={{ phone }}
      modalProps={{
        closable: false,
        cancelText: '上一步',
      }}
    />
  );
}

export default function Create({ history }) {
  debug.render('Create');

  const [data, setData] = useState(null);

  const exit = useCallback(() => history.goBack(), []);
  const done = useCallback(() => { dataSource.refresh(); exit(); }, []);
  const prev = useCallback(() => setData(null), []);
  const next = useCallback(data => setData(data), []);

  return !data ? <Search onCancel={exit} onConfirm={next}  /> : <Edit onCancel={prev} onConfirm={done} data={data} />;
}
