import width from 'ui/TableCell/width';
import createAlarm from './alarm/createAlarm';

export default createAlarm('overspeed', [
  { title: '告警时间', dataIndex: 'created_time', width: width(0, 20), fixed: true },
  { title: '车牌号', dataIndex: 'car_num', width: width(10) },
  { title: '驾驶员', dataIndex: 'driver_name', width: width(10) },
  { title: '速度', dataIndex: 'speed', width: width(5) },
  { title: '运企名称', dataIndex: 'unit_name' },
  { title: '电子联单号', dataIndex: 'number', width: width(0, 20) },
]);
