import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import request from 'utils/request';
import { update } from '../share';
import { dataSource } from './List';
import {message} from "antd";

// const inputs = [
//   {
//     label: '起点',
//     children: [
//       { placeholder: '区域', key: 'start_area_id', type: 'select', options: '/web/Gps_Fault/area_list', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
//       { placeholder: '起点部门', key: 'start_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', required: true, rely: 'start_area_id', relyAs: 'area_id', style: { width: 120 } },
//       { placeholder: '起点', key: 'start_id', type: 'select', options: '/web/instance/getBuilding', extraData: { types: 1 }, valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['start_area_id', 'start_department_id'], reliesAs:['area_id', 'id'], required: true, style: { width: 240 } },
//       { placeholder: '运输车', key: 'car_num', type: 'select', options: '/web/Car/getCarList', realTimeSearch: 'keywords', valueKey: 'car_num', labelKey: 'car_num', required: true, style: { width: 120 } },
//       { placeholder: '司机', key: 'driver_id', type: 'select', options: '/web/Driver/getDriverByCarNum', valueKey: 'id', labelKey: 'name', showSearch: true, rely: 'car_num', style: { width: 120 } },
//     ],
//   },
//   { label: '开单时间', key: 'start_time', type: 'date', showTime: true, required: true },
//   {
//     label: '终点',
//     children: [
//       { placeholder: '区域', key: 'end_area_id', type: 'select', options: '/web/Gps_Fault/area_list', valueKey: 'id', labelKey: 'name', required: true, style: { width: 120 } },
//       { placeholder: '终点部门', key: 'end_department_id', type: 'select', options: '/web/instance/get', valueKey: 'id', labelKey: 'name', rely: 'end_area_id', relyAs: 'area_id', style: { width: 120 } },
//       { placeholder: '终点', key: 'end_id', type: 'select', options: '/web/instance/getStartOrEndSupplement', extraData: { types: 2 }, valueKey: 'id', labelKey: 'name', showSearch: true, relies: ['end_area_id', 'end_department_id'], reliesAs:['area_id', 'id'], style: { width: 240 } },
//     ],
//   },
//   { label: '入库时间', key: 'end_time', type: 'date', showTime: true, required: true },
//   { label: '收货人', key: 'acquirer_id', type: 'select', options: '/web/user/getUsers', valueKey: 'id', labelKey: 'name', showSearch: true, style: { width: 195 } },
// ];

export default function Update({ history, match }) {
  const id = match.params.id;
  const back = () => history.goBack();
  const onFinish = (data) => {
    const params = {
      id: id,
      start_area_id: data.start_area_id || 0,
      end_area_id: data.end_area_id || 0,
      acquirer_id: data.acquirer_id || 0,
      car_num: data.car_num,
      driver_id: data.driver_id || 0,
      end_department_id: data.end_department_id || 0,
      end_id: data.end_id || 0,
      end_time: data.end_time,
      start_department_id: data.start_department_id || 0,
      start_id: data.start_id || 0,
      start_time: data.start_time,
      account_type: data.account_type,
      materiel_id: data.materiel_id,
      medias: data.medias
    };

    request('/web/ticket/edit', params)
      .then(body => body.data)
      .then(({ code, msg }) => {
        if (code === 200) {
          dataSource.refresh();
          message.success('修改电子联单成功');
          back();
        } else {
          const warn = typeof msg === 'string' ? msg : '保存失败!';
          message.warn(warn);
        }
      });
    // dataSource.refresh();
    // back();
  };

  return (
    <ModalFormPage
      init={['/web/ticket/editShow', { id }]}
      // submit={['/web/ticket/edit', { id }]}
      title="修改电子联单"
      inputs={update.inputs}
      onCancel={back}
      onFinish={onFinish}
      modalProps={{
        width: 1000,
      }}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}
    />
  );
}
