import React, { useEffect } from 'react';
import { Modal, message } from 'antd';
import request from 'utils/request';

const { confirm } = Modal;
// 调换起始点
export function UseChangePoint({ id, onChange, onClose }) {
  
  const onSuccess = () => {
    request('/web/ticket/changePoint', { id })
      .then((body) => body.data)
      .then((data) => {
        if(data.code === 200) {
          const { status } = data.data;
          confirm({
            title: status === 0 ? '提示' : '操作失败',
            content: data.msg, //status === 0 ? '成功调换起终点' : '当前联单有起终点，无法进行“起终点调换”操作'
          });
          onChange();
        } else {
          message.error(data.msg);
          onClose();
        }
      });
  }
  return (
    <Modal
      title={'提示'}
      // width={800}
      // bodyStyle={{ maxHeight: '60vh', overflow: 'auto', padding: 0 }}
      destroyOnClose={true}
      visible={true}
      onCancel={() => onClose()}
      onOk={onSuccess}
    >
      确认进行“起终点调换”？
    </Modal>
  );
}
