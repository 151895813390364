import React, { useState, useEffect } from 'react';
import {Button, Drawer} from 'antd';
import request from 'utils/request/index';
import styles from "./PointPreview.scss";
import { Link } from "react-router-dom";
import { previewable } from "components/Previewer";
import ShowVideo from './ShowVideo';

function getConsumeDetails(id, point_type) {
  const [data, setData] = useState({
    base_info: {},
    car_info: {
      total: 0,
      data: [],
      counts: [
        { number: 0, count_volumn: 0 },
        { number: 0, count_volumn: 0 },
        { number: 0, count_volumn: 0 },
        { number: 0, count_volumn: 0 }
        ]
    },
    result_info: { history_count: [0, 0] },
    materiels: [],
  });
  useEffect(() => {
    request('/web/stationary_source/getConsumeDetails', {id, point_type})
      .then(body => body.data.data)
      .then(setData)
  }, [id, point_type]);
  return data
}

function judgeObj(type){
  return JSON.stringify(type) === '{}' ? false : true;
}

const punishedStatus = ['', '现金处罚', '维护现场', '暂不处罚', '其他'];
const baseLabel = ['', '线下排查', '手动上传'];
const baseConsumType = ['待排查', '非法点位', '合法点位'];

export default previewable(function PointPreview({ params, onClose, preview }){
  const { id, point_type } = params;
  // const id = 297, point_type = 4;
  const consumData = getConsumeDetails(id, point_type);
  const [videoSrc, setVideoSrc] = useState();

  return (
    <>
      <Drawer
        title={`点位预览(${baseLabel[consumData.base_info.label]})`}
        closable={true}
        mask={false}
        destroyOnClose={true}
        className={styles.consum}
        style={{height: 735, top: 139}}
        onClose={onClose}
        visible={true}
        placement={'left'}
        width={350}
        bodyStyle={{padding: '10px 20px', height: 675}}
      >
        <table className={styles.detail_table}>
          <tr>
            <td style={{width: 70}}>点位名称</td>
            <td colSpan={3}>{consumData.base_info.name}</td>
          </tr>
          <tr>
            <td>所属区县</td>
            <td colSpan={3}>{consumData.base_info.area_name}</td>
          </tr>
          <tr>
            <td>卫星地址</td>
            <td>{
              consumData.base_info.lat && consumData.base_info.lng ?
                <Link to={{ pathname: './satellite-map', state: { lat: consumData.base_info.lat, lng: consumData.base_info.lng, types: 'satellite' } }}>查看位置</Link> :
                <span>暂无位置</span>
            }</td>
            <td>点位截图</td>
            <td><span style={{cursor: 'pointer', color: '#1491FB'}} onClick={() => preview.image(consumData.base_info.plan)}>查看点位</span></td>
          </tr>
          <tr>
            <td>详细地址</td>
            <td colSpan={3}>{consumData.base_info.address}</td>
          </tr>

        </table>
        <div className={styles.consumResult}>排查结果</div>
        <div>
          <span className={styles.history}>历史排查</span>
          <span className={styles.line}>{}</span>
          <span>线下排查：</span>
          <span style={{marginRight: 20}}>{judgeObj(consumData.result_info) ? consumData.result_info.history_count[0] : 0}</span>
          <span>执法排查：</span>
          <span style={{marginRight: 20}}>{judgeObj(consumData.result_info) ? consumData.result_info.history_count[1] : 0}</span>
        </div>
        <table className={styles.detail_table}>
          <tr>
            <td style={{width: 70}}>点位定性</td>
            <td>{ baseConsumType[consumData.base_info.consum_type] }</td>
            <td style={{width: 70}}>点位特性</td>
            <td>{ consumData.base_info.point_type_name }</td>
          </tr>
          {
            consumData.base_info.label === 1 ? (
              <>
                <tr>
                  <td>地址信息</td>
                  <td colSpan={3}>{consumData.result_info.address}</td>
                </tr>
                <tr>
                  <td>结案时间</td>
                  <td colSpan={3}>{consumData.result_info.created_time}</td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td>发现时间</td>
                  <td colSpan={3}>{consumData.base_info.create_time}</td>
                </tr>
              </>
            )
          }
          {
            (consumData.result_info.history_count && consumData.result_info.history_count[1] > 0) &&
              <>
                <tr>
                  <td>处罚单位</td>
                  <td colSpan={3}>{ consumData.result_info.punished_department }</td>
                </tr>
                <tr>
                  <td>处罚结果</td>
                  <td colSpan={3}>{ punishedStatus[consumData.result_info.punished_status] }</td>
                </tr>
              </>
          }
          <tr>
            <td>其他信息</td>
            <td colSpan={3}>{consumData.base_info.other_info}</td>
          </tr>
        </table>
        {
          consumData.base_info.point_type_name === '消纳厂' && (
            <>
              <div className={styles.consumResult}>消纳预估</div>
              <table className={styles.detail_table}>
                <tr>
                  <td>物料名称</td>
                  <td>预估方量</td>
                </tr>
                {
                  consumData.materiels.length === 0 ? <tr><td colSpan={2}>暂无数据</td></tr>:
                    consumData.materiels.map(({ material_name, volume }) => (
                      <tr>
                        <td>{material_name}</td>
                        <td>{volume}</td>
                      </tr>
                    ))
                }
              </table>
            </>
          )
        }
        <div className={styles.consumResult}>现场证物</div>
        <table className={styles.detail_table}>
          {
            consumData.result_info.history_count && consumData.result_info.history_count[1] > 0 ?
              <tr>
                <td>票据</td>
                <td>{
                  !consumData.result_info.ticket || consumData.result_info.ticket.length > 0 ?
                    '暂无处罚票据' : <span style={{cursor: 'pointer', color: '#1491FB'}} onClick={() => preview.image(consumData.result_info.ticket)}>查看票据</span>
                }</td>
              </tr>
              : null
          }
          <tr>
            <td>照片</td>
            <td>{
              consumData.base_info.images && consumData.result_info.images !== '' ?
                <span onClick={() => preview.image( consumData.base_info.images)} >{
                  consumData.base_info.images.split(',').map((item, i) => <img key={i} className={styles.imgs} src={item} alt=""/>)
                }</span> :
                <span>暂无照片</span>
            }</td>
          </tr>
          <tr>
            <td>视频</td>
            <td>{
              consumData.base_info.videos ?
                consumData.base_info.videos.split(',').map((item, i) => <img key={i} className={styles.imgs} style={{width: 71}} src="/images/radar/live_video.jpg" onClick={() => setVideoSrc(item)} alt=""/>) :
                <span>暂无视频</span>
            }</td>
          </tr>
        </table>
        <div className={styles.consumDetail}>
          <Link to={`./${id}/${point_type}/suspicious-points/`}>
            <Button type={'primary'}>查看详情</Button>
          </Link>
        </div>
      </Drawer>
      { videoSrc && <ShowVideo videoSrc={videoSrc} onClose={ () => setVideoSrc() } /> }
      </>
  )
})
