import React from 'react';
import { Route } from 'react-router-dom';

import List from './List';
import VolumnEditor from './VolumnEditor';

export default ({ path }) => (
  <>
    <Route path={path} component={List} />
    <Route path={`${path}:id/volumn-editor`} component={VolumnEditor} />
  </>
);