/* eslint-disable no-undef */
import { useMemo } from 'react';
// import { hash } from 'spark-md5';
import md5 from 'blueimp-md5';
import axios from 'axios';
import qs from 'qs';
import { message } from 'antd';
import debug from 'utils/debug';
import request from 'utils/request';
import { LOG_IN, LOG_OUT, UPDATE_BADGE } from '../types/user';
import store from '../stores/user';

export function updateBadge() {
  request('/rest/Bubble/bubble').then((body) => {
    const count = body.data.data.blacklist_car;
    if (count) {
      store.dispatch({
        type: UPDATE_BADGE,
        payload: count,
      });
    }
  }).catch(console.error);
}

function authorize(type, user, key, time) {
  return md5([key, time, type, user].join(''));
}

const getCertificate = ({userId, key, type}) => {
  console.log('signature', signature);
  const time = parseInt(new Date().getTime() / 1000, 10);
  const signature = authorize(type, userId, key, time);
  axios({
    baseURL: URLS.DATA,
    url: '/-/user/certificate',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Shomes-Time': String(time),
      'Shomes-Type': String(type),
      'Shomes-User': String(userId),
      'Shomes-Sign': signature
    },
    data: {
      userId: userId
    },
  }).then((data) => data.data).then(({certificate}) => {
    console.log('certificate', certificate);
    window.location.href = `${URLS.ALPHAURL}?certificate=${certificate}`;
    // window.location.href = `http://localhost:5000?certificate=${certificate}`;
  })

};

export const logIn = (username, password) => {
  debug.loging('log in');
  axios({
    baseURL: URLS.DATA,
    url: '/web/Login/Login',
    method: 'POST',
    data: qs.stringify({
      telephone: username,
      password: md5(password),
      timestamp: Math.floor(Date.now() / 1000)
    }),
  })
    .then((body) => body.data)
    .then(({ code, data, msg }) => {
      if (code !== 200) {
        message.error(msg, 5);
      } else {
        // if(data.roles.id === 101) {
        //   message.success('尊敬的用户，系统已对原平台进行升级，现自动跳转到升级后平台。', 1);
        //   getCertificate({ userId: data.userId, key: data.key, type: 'city_web'});
        //   return;
        // }
        message.success('登录成功', 1);
        // 登陆成功
        const user = {
          id: data.userId,
          key: data.key,
          menu: data.menu,
          roles: data.roles,
          info: data.user_info,
          password
        };
        // 本地缓存用户信息
        window.localStorage.setItem('USER', JSON.stringify(user));
        // 发布事件
        store.dispatch({
          type: LOG_IN,
          payload: user,
        });
      }
    });
};


export const logOut = (msg) => {
  debug.loging('log out');
  // 已经登出后不再继续后面的处理
  if (!store.getState().id) return;
  // 如果是服务端主动登出，显示提示消息
  if (typeof msg === 'string') {
    message.error(msg, 1);
  }
  // 从本地缓存中删除用户信息
  window.localStorage.setItem('USER', JSON.stringify('{}'));
  sessionStorage.removeItem('setSandstone');
  sessionStorage.removeItem('secondReceiver');
  // 发布事件
  store.dispatch({
    type: LOG_OUT,
  });
};
