import React, {useState, useEffect} from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import store from 'stores/user';
import request from 'utils/request';
import {message} from 'antd';
import {dataSource} from './List';
import controller from "./controller";
const EditTypes = ['', '驾驶证年审期', '从业资格证年审期'];
const inputs = [
  [],
  [ { label: '上传照片', key: 'driver_license_photo', type: 'upload', required: true },
    { label: '证件审期', placeholder: '请选择证件审期', key: 'expiry_date', required: true, type: 'date', format: 'YYYY-MM-DD' }
  ],
  [
    { label: '上传照片', key: 'work_license_photo', type: 'upload', required: true },
    { label: '证件审期', placeholder: '请选择证件审期', key: 'deadline', required: true, type: 'date', format: 'YYYY-MM-DD' }
  ],
];
export default function Edit({ history, match }) {
  const back = () => history.goBack();
  const {params: {name, id, types}} = match
  const onFinish = () => {
    message.success(`修改${name} ${EditTypes[types]}成功`);
    controller.refresh();
    back();
  };
  return (
    <ModalFormPage
      title={name + EditTypes[types]}
      init={['/web/identification_and_photo/driver', { id, types }]}
      submit={['/web/identification_and_photo/updateDriver', {id}]}
      inputs={inputs[types]}
      onCancel={back}
      onFinish={onFinish}
      modalProps={{
        width: 800,
      }}
      formProps={{
        labelCol: { xs: { span: 4 } },
        wrapperCol: { xs: { span: 20 } },
      }}

    />
  );
}
