import React, { useState, useEffect, useCallback, useMemo, useRef  } from 'react';
import {DatePicker, Icon, message, Radio, Modal, Button, Drawer, Switch, Slider, Table, Input, Tooltip, Spin} from 'antd';
import moment from 'moment';
import echarts from 'echarts';
const { confirm } = Modal;
import request from 'utils/request';
import debug from 'utils/debug';
import styles from '../TransportRadar/index.scss';
import TrashTicketDetail from '../../CommandCenter/CommandCenter/TrashTicketDetail';
import CallDriver from "../../CommandCenter/CommandCenter/CallDriver";
import store from 'stores/user';
import h_common from 'utils/h_common';
const Search = Input.Search;
const defaultDates = [moment().subtract(0, 'day').startOf('day'), moment().endOf('day')];
const logChartDispose = () => debug.action('Echarts实例已卸载') || true;
const isArrayAllValid = array => array.findIndex(item => item === undefined) === -1;
const coordObjectsToArrays = objects => objects.map(({ lng, lat }) => [lng, lat]);
const coordObjectsToArraysByTrans = objects => objects.map(({ lng, lat }) => h_common.GPS_transformation(lat, lng));
const coordObjectsToArraysByTicket = objects => objects.map(({ lng, lat, stay_time, ticket_id, time, }) =>({value: h_common.GPS_transformation(lat, lng), stay_time, ticket_id, time}));
const coordObjectsToArraysByPoints = objects => objects.map(({ lng, lat, speed, time, ticket_id}) =>({value: h_common.GPS_transformation(lat, lng), speed, time, ticket_id}));

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function useEcharts() {
  const [chart, setChart] = useState(null); // 存储echart实例
  const refGetter = useCallback(element => !!element && setChart(echarts.init(element)), []); // 创建echart实例
  const isChartUsable = useCallback(() => (!!chart && !chart.isDisposed()), [chart]); // 判断echart实例是否可用
  useEffect(() => () => (isChartUsable() && logChartDispose() && chart.dispose()), [chart]); // 卸载echart实例
  return [chart, isChartUsable, refGetter];
}

const startMap = new Map();
function ids2key(departmentId, id) {
  return `${departmentId}/${id}`;
}
function getEnds(startDepartmentId, startId) {
  return startMap.get(ids2key(startDepartmentId, startId));
}
function addEnd(startDepartmentId, startId, endDepartmentId, endId, end) {
  const ends = getEnds(startDepartmentId, startId) || new Map();
  ends.set(ids2key(endDepartmentId, endId), end);
  startMap.set(ids2key(startDepartmentId, startId), ends);
}
let plansData = [], plansListData = [], plansStopData = [], plansElseList = [];
function usePlans(dates, transport_id) {
  const [data, setData] = useState({elseList: [], list: [], lines: [], sourceList: [], stopPoints: [], ticketLines: [], ticketSource: []});
  const begin_date = dates[0].format('YYYY-MM-DD HH:mm:ss');
  const end_date = dates[1].format('YYYY-MM-DD HH:mm:ss');
  useEffect(() => {
    request('web/ticket_map/transportData', {begin_date: begin_date, end_date: end_date, transport_id})
      .then(body => body.data.data)
      .then(setData);
  }, [begin_date, end_date]);
  plansData = data.sourceList;
  plansListData = data.list;
  plansStopData = data.stopPoints;
  plansElseList = data.elseList;
  let sum = 0;
  data.list.map(({num, volumn}) => {
    sum += parseInt(volumn);
  });
  return data;
}
function useAbnormalList(dates, type, page, keywords, source, ticket_ids, stopOrList, transport_id) {
  const [leftLoading, setLeftLoading] = useState(true);
  const [data, setData] = useState({list: [], summary: []});
  const begin_date = dates[0].format('YYYY-MM-DD HH:mm:ss');
  const end_date = dates[1].format('YYYY-MM-DD HH:mm:ss');
  const ticket_type = type;
  useEffect(() => {
    setLeftLoading(true);
    const promise = stopOrList ?
      request('/web/ticket_map/getTicketList', {ticket_ids: ticket_ids}) :
      request('web/ticket_map/transportList', {transport_id, begin_date: begin_date, end_date: end_date,source: source, ticket_type: ticket_type, page: page, limit: 10, keywords: keywords});
    promise
      .then(body => body.data.data)
      .then(setData)
      .finally(() => {
        setLeftLoading(false);
      });
  }, [begin_date, end_date, ticket_type, page, keywords, source, ticket_ids]);
  return [data, leftLoading];
}
let planFlag = false;
function usePlan(plans) {
  const [index, setIndex] = useState();
  const dependencies = [plans, index];
  const plan = useMemo(() => {
    if (!isArrayAllValid(dependencies)) return {};
    return plans[index] || {};
  }, dependencies);
  if(plan.id){
    planFlag = true
  }else{
    planFlag = false
  }
  return [plan, setIndex];
}
let detailData = [];

const SOURCELIST = ['', '车载识别', '人工补录', '车牌识别'];

function useDetails(plan, dates, hour) {
  const [data, setData] = useState();
  // const start_id = plan.start_id;
  const start_id = plan.id;
  // const start_department_id = plan.start_department_id;
  const start_department_id = plan.department_id;
  const begin_date = dates[0].format('YYYY-MM-DD HH:mm:ss');
  const end_date = dates[1].format('YYYY-MM-DD HH:mm:ss');
  const dependencies = [start_id, start_department_id, begin_date, end_date, hour];
  useEffect(() => {
    if (!isArrayAllValid(dependencies)) return;
    setData();
    request('/web/ticket_map/getMapDetail', { start_id, start_department_id, begin_date, end_date, hour: hour }).then(body => setData(body.data.data));
  }, dependencies);
  detailData = data;
  return data;
}
let bound = null;
let zoom = 15;
let bmap = null;
function useMap(isChartUsable, chart, series, center, play_begin, play_end, flag, otherCenterFlag) {
  useEffect(() => {
    if (!isChartUsable()) return;
    (!play_begin || play_end) ? chart.clear() : null;
    let option = {
      tooltip : {
        trigger: 'item'
      },
      animation: false,
      bmap: {
        center: center || [103.836472, 30.719426],
        zoom: 13,
        roam: true,
      },
      series,
    };
    if(bmap){
      option.bmap.zoom = bmap.getZoom();
      let lng = bmap.getCenter().lng;
      let lat = bmap.getCenter().lat;
      bound = bmap.getBounds();
      option.bmap.center = [lng, lat];
    }
    if(flag){
      option.bmap.center = center;
    }
    if(otherCenterFlag){
      option.bmap.center = center;
    }

    chart.setOption(option);
    bmap = chart ? chart.getModel().getComponent('bmap').getBMap() : null;
    if(bmap){
      bmap.addControl(new BMap.MapTypeControl());
    }
  }, [chart, series]);
}

const isInArray = function(arr,value){
  for(var i = 0; i < arr.length; i++){
    if(value === arr[i]){
      return true;
    }
  }
  return false;
};
Array.prototype.remove = function(val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};
const DuplicateRemoval = (data) => {
  let arr = [];
  data.map(v => {
    let f = true;
    arr.map(e => {
      if((v.id === e.id) && (v.department_id === e.department_id)){
        f = false;
      }
    })
    if(f){
      arr.push(v)
    }
  });
  return arr;
};
let newTicketLines = []; // 存储联单轨迹点
let overStopFlag = false; // 是否显示停靠点
let overPointsFlag = false; // 是否显示原始轨迹点
let chartBuild = null;
export default function TransportRadar() {
  const storeInfo = store.getState();
  const {center_one_lng, center_one_lat} = storeInfo.info;
  const { info: {area_id, area_name, user_instance}} = store.getState();
  const [chart, isChartUsable, chartRefGetter] = useEcharts();
  const [dates, setDates] = useState(defaultDates);
  const [series, setSeries] = useState([]);
  const plans = usePlans(dates, user_instance[0].instance_id);
  const [plan, selectPlan] = usePlan(plans.sourceList);
  const [dataSource, setDataSource] = useState([]);//useSource(plans.list);
  const [hour, setHour] = useState("1");
  const details = useDetails(plan, dates, hour);
  const [trashDetailData, setTrashDetailData] = useState({});
  const [trash_show_detail, setTrashShowDetail] = useState(false);
  const [ticketList, setTicketList] = useState([]);
  const [ticketId, setTicketId] = useState();
  const [ticketSeries, setTicketSeries] = useState();
  const [ticketOver, setTicketOver] = useState();
  const [call_driver, setCallDriver] = useState(false);
  const [msg, setMsg] = useState('通话中。。。');
  const [call_flag, setCallFlag] = useState(false);
  const [ticket_lines, setTicketLines] = useState();

  const [visible, setVisible] = useState(false);
  const [rectify, setRectify] = useState(true); //是否轨迹纠偏
  const [show_points, setShowPoints] =useState(false); // 是否显示原始轨迹点
  const [show_stops, setShowStops] =useState(false); //是否显示停靠点
  const [slider, setSlider] = useState([]);// 控制slider的节点个数便于进度条的控制
  const [moveSpeed, setMoveSpeed] = useState("400"); // 0.5 - 800; 1 - 400; 2 - 200; 4 - 100; 8 - 50;
  const [playIndex, setPlayIndex] = useState(0); // 控制slider 在那一节点
  const [movePos, setMovePos] = useState(0); //控制轨迹在哪一点

  //控制播放、暂停、重置按钮
  const [play_begin, setPlayBegin] = useState(false);
  const [play_end, setPlayEnd] = useState(false);
  const [play_restart, setPlayRestart] = useState(false);

  const [center, setCenter] = useState([center_one_lng, center_one_lat]);
  const [centerFlag, setCenterFlag] = useState(false);
  const [otherCenterFlag, setOtherCenterFlag] = useState(true);
  const [visibleTitle, setVisibleTitle] = useState('接管报表');
  const [concertData, setConcertData] = useState({});
  const [visibleTable, setVisibleTable] = useState(false);

  //右侧列表
  // const plans = usePlans(dates);
  // const [leftLoading, setLeftLoading] = useState(true);
  const [navNum, setNavNum] = useState(1); //左侧的伪导航 1 - 异常 2 - 已处理
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [keywords, setKeywords] = useState('');
  const [source, setSource] = useState(0);
  const [stopOrList, setStopOrList] = useState(false);
  const [ticket_ids, setTicketIds] = useState('');
  const [abnormalList, leftLoading] = useAbnormalList(dates, navNum, page, keyword, source, ticket_ids, stopOrList, user_instance[0].instance_id);
  /*uesState({
    navNum: 1,page: 1
  });*/
  const [abnormalData, setAbnormalData] = useState([]);
  const [summary, setSummary] = useState([]);

  const [newConsum, setNewConsum] = useState();

  const [right_flag, setRightFlag] = useState([5, 6]);
  const [linesSource, setLinesSource] = useState();
  const [linesFlag, setLinesFlag] = useState(false);
  const [right_flag1, setRightFlag1] = useState([0]);
  const [top_flag, setTopFlag] = useState([1, 2, 3, 4, 5]);//顶部四条线路的显示与隐藏
  // 黑工地
  const [visibleBuild, setVisibleBuild] = useState(false);
  const [moreVisible, setMoreVisible] = useState(true);

  //接管报表筛选条件
  const [filterFlag, setFilterFlag] = useState('0');
  const [sourceFlag, setSourceFlag] = useState('0');
  useEffect(() => {
    if(stopOrList){
      setAbnormalData(abnormalList)
    }
    else{
      if(abnormalList.list.length === 0 || abnormalList.list.length < 10){
        setMoreVisible(false)
      }else{
        setMoreVisible(true)
      }
      if(page === 1){
        setAbnormalData(abnormalList.list);
        setSummary(abnormalList.summary)
      }
      else{
        setAbnormalData(abnormalData.concat(abnormalList.list))
      }
    }
  }, [abnormalList]);

  let points = [];

  const location = (color, data, label) => ({
    type: 'effectScatter',
    coordinateSystem: 'bmap',
    labelName: label,
    rippleEffect: {
      brushType: 'stroke',
    },
    name: 'ticketPoint',
    label: {
      normal: {
        show: true,
        position: 'right',
        formatter: function (a){return  (a.data.type === 'start' ? '起点：' : '终点：') + a.name},
      },
    },
    symbolSize: () => 10,
    itemStyle: {
      normal: {
        color,
        opacity: 1,
      },
    },
    tooltip: {
      formatter: '{b}'
    },
    data,
  });

  const new_ticketPoint = (data, symbol, symbolSize, offsetArr, label, color) => {
    return {
      type: 'scatter',
      coordinateSystem: 'bmap',
      labelName: 'label',
      symbol: symbol,
      symbolSize: symbolSize,
      zlevel: 2,
      rippleEffect: {
        brushType: 'stroke',
      },
      name: 'new_ticketPoint',
      label: {
        normal: {
          show: true,
          position: 'inside',
          offset: offsetArr,
          color: '#ffffff',
          formatter: function (a){return label},
        },
      },
      itemStyle: {
        normal: {
          color,
          opacity: 1,
        },
      },
      tooltip: {
        formatter: '{b}'
      },
      data: data,
    }
  };
  const forOverTicket = (data, symbol, symbolSize, offsetArr, label, color) => {
    return {
      type: 'scatter',
      coordinateSystem: 'bmap',
      labelName: 'label',
      symbol: symbol,
      symbolSize: symbolSize,
      zlevel: 2,
      rippleEffect: {
        brushType: 'stroke',
      },
      name: 'forOverTicket',
      label: {
        normal: {
          show: true,
          position: 'inside',
          offset: offsetArr,
          color: '#ffffff',
          formatter: function (a){return label},
        },
      },
      itemStyle: {
        normal: {
          color,
          opacity: 1,
        },
      },
      tooltip: {
        formatter: '{b}'
      },
      data: data,
    }
  };
  const sourceList = (color, data, label)  => {
    let sourceListArr = [];
    let data1 = [], data2 = [], data3 = [], data4 = [];
    if(linesFlag){
      const {start_id, start_department_id, end_id, end_department_id} = linesSource;
      data.map((v) => {
        if(v.b_type === 'building' && ((v.id === start_id && v.department_id === start_department_id) || (v.id === end_id && v.department_id === end_department_id))){
          data1.push(v)
        }else if(v.b_type === 'sand_factory' && ((v.id === start_id && v.department_id === start_department_id) || (v.id === end_id && v.department_id === end_department_id))){
          data2.push(v)
        }else if(v.b_type === 'consum' && ((v.id === start_id && v.department_id === start_department_id) || (v.id === end_id && v.department_id === end_department_id))){
          data4.push(v)
        }else if((v.id === start_id && v.department_id === start_department_id) || (v.id === end_id && v.department_id === end_department_id)){
          data3.push(v);
        }
      });
    }else{
      data.map((v) => {
        if(v.b_type === 'building'){
          data1.push(v)
        }else if(v.b_type === 'sand_factory'){
          data2.push(v)
        }else if(v.b_type === 'consum'){
          data4.push(v)
        }else{
          data3.push(v);
        }
      });
    }
    const f1 = isInArray(right_flag, 1);
    const f2 = isInArray(right_flag, 2);
    const f3 = isInArray(right_flag, 3);
    const f4 = isInArray(right_flag, 4);
    if(isInArray(right_flag, 0)){
      sourceListArr.push(new_ticketPoint(DuplicateRemoval(data1), 'image:///images/center/green1.png', [45, 45], [0, -1], '工', color));
      sourceListArr.push(new_ticketPoint(DuplicateRemoval(data2), 'image:///images/center/orange3.png', [32, 44], [-1, -10], '砂', color));
      sourceListArr.push(new_ticketPoint(DuplicateRemoval(data3), 'image:///images/center/radar_c.png', [32, 47], [-1, -5], '商', color));
      sourceListArr.push(new_ticketPoint(DuplicateRemoval(data4), 'image:///images/center/red4.png', [34, 46], [-1, -5], '消', color));
    }
    else{
      sourceListArr.push(new_ticketPoint(f1 ? DuplicateRemoval(data1) : [], 'image:///images/center/green1.png', [45, 45], [0, -1], '工', color));
      sourceListArr.push(new_ticketPoint(f2 ? DuplicateRemoval(data2) : [], 'image:///images/center/orange3.png', [32, 44], [-1, -10], '砂', color));
      sourceListArr.push(new_ticketPoint(f3 ? DuplicateRemoval(data3) : [], 'image:///images/center/radar_c.png', [32, 47], [-1, -5], '商', color));
      sourceListArr.push(new_ticketPoint(f4 ? DuplicateRemoval(data4) : [], 'image:///images/center/red4.png', [34, 46], [-1, -5], '消', color));
    }

    return sourceListArr
  };
  const sourceListForStop = (color, data, label)  => {
    let sourceListArr = [];
    let data1 = [], data2 = [], data3 = [], data4 = [];
    data.map((v) => {
      if(v.b_type === 'building'){
        data1.push(v)
      }else if(v.b_type === 'sand_factory'){
        data2.push(v)
      }else if(v.b_type === 'consum'){
        data4.push(v)
      }else{
        data3.push(v);
      }
    });
    const f1 = isInArray(right_flag1, 1);
    const f2 = isInArray(right_flag1, 2);
    const f3 = isInArray(right_flag1, 3);
    const f4 = isInArray(right_flag1, 4);
    if(isInArray(right_flag1, 0)){
      sourceListArr.push(forOverTicket(DuplicateRemoval(data1), 'image:///images/center/green1.png', [45, 45], [0, -1], '工', color));
      sourceListArr.push(forOverTicket(DuplicateRemoval(data2), 'image:///images/center/orange3.png', [32, 44], [-1, -10], '砂', color));
      sourceListArr.push(forOverTicket(DuplicateRemoval(data3), 'image:///images/center/radar_c.png', [32, 47], [-1, -5], '商', color));
      sourceListArr.push(forOverTicket(DuplicateRemoval(data4), 'image:///images/center/red4.png', [34, 46], [-1, -5], '消', color));
    }
    else if(f1 && f2 && f3 && f4){
      sourceListArr.push(forOverTicket(f1 ? DuplicateRemoval(data1) : [], 'image:///images/center/green1.png', [45, 45], [0, -1], '工', color));
      sourceListArr.push(forOverTicket(f2 ? DuplicateRemoval(data2) : [], 'image:///images/center/orange3.png', [32, 44], [-1, -10], '砂', color));
      sourceListArr.push(forOverTicket(f3 ? DuplicateRemoval(data3) : [], 'image:///images/center/radar_c.png', [32, 47], [-1, -5], '商', color));
      sourceListArr.push(forOverTicket(f4 ? DuplicateRemoval(data4) : [], 'image:///images/center/red4.png', [34, 46], [-1, -5], '消', color));
    }
    return sourceListArr
  };
  const plansStopPoints = (color, data) => {
    return {
      type: 'scatter',
      coordinateSystem: 'bmap',
      labelName: 'label',
      // symbol: 'image:///images/center/green1.png',
      symbolSize: 10,
      zlevel: 2,
      rippleEffect: {
        brushType: 'stroke',
      },
      name: 'plansStopPoints',
      tooltip: {
        formatter: '停靠点'
      },
      label: {
        normal: {
          show: false,
          position: 'inside',
          offset: [0, -1],
          color: '#ffffff',
          formatter: function (a){return '工'},
        },
      },
      itemStyle: {
        normal: {
          color,
          opacity: 1,
        },
      },
      data: data.map(({lng, lat, ticket_id, stay_time, time}) => ({ticket_id, stay_time, time, value: h_common.GPS_transformation(lat, lng)})),
    }
  };
  const [countLines, setCountLines] = useState([{sum: 0, volumn: 0}, {sum: 0, volumn: 0}, {sum: 0, volumn: 0}, {sum: 0, volumn: 0}, {sum: 0, volumn: 0}]);

  function getTicketDetail(id){
    request('web/homemap/ticketDetail', {id: id, area_id: 13, area_name: '温江区'})
      .then(body => { setTrashDetailData(body.data.data); })
      .finally(() => setTrashShowDetail(true))
  }

  function getTicketLine(id, m_hour){
    request('/web/ticket_map/getTicketLine', {ticket_id: id, hour: m_hour ? m_hour :hour }).then(body => {
      const data = body.data.data;
      let ticket_dates = [];
      if(data.ticket_lines.length === 0 && data.stop_points.length === 0){
        message.warn('暂无联单路线');
      }else{
        setVisible(true);
        setTicketLines(data);
        newTicketLines = data;
        ticket_dates = data.ticket_lines.map(({time}) => time);
        setSlider(ticket_dates);
      }
    })
  }
  useEffect(() => {

  }, [ticketList, trash_show_detail, ticketSeries, call_driver, dataSource, abnormalData]);
  useEffect(() => {
    if(ticket_lines){
      let series_data = [];
      if(plans.sourceList.length > 0){
        series_data = sourceListForStop('#22B74F', plans.sourceList.map(({type, name, lng, lat, ...data}, index) => ({
          index,
          type: 'start',
          b_type: type,
          name: name,
          ticket_id: ticketId,
          value:  [lng, lat],
          ...data
        })))
      }
      let coo = [], coo1 = [];
      if(ticket_lines.bind_lines.length === 1){
        ticket_lines.bind_lines.map(({lng, lat}) => {
          coo = [[lng, lat], [lng, lat]]
        });
      }
      if(ticket_lines.ticket_lines.length === 1){
        ticket_lines.ticket_lines.map(({lng, lat}) => {
          coo1 = [h_common.GPS_transformation(lat, lng), h_common.GPS_transformation(lat, lng)]
        });
      }
      // 显示纠偏轨迹 ？ 原始轨迹
      rectify ? series_data.push({
        type: 'lines',
        zlevel: 8,
        name: 'trailLines',
        coordinateSystem: 'bmap',
        polyline: true,
        silent: true,
        lineStyle: {
          normal: {
            color: '#46bee9',
            opacity: 1,
            width: 4,
          },
        },
        progressiveThreshold: 500,
        progressive: 200,
        data: [{
          coords: ticket_lines.bind_lines.length > 1 ? coordObjectsToArrays(ticket_lines.bind_lines || []) : coo,
        }]
      }) : series_data.push({
        type: 'lines',
        coordinateSystem: 'bmap',
        polyline: true,
        silent: true,
        zlevel: 8,
        lineStyle: {
          normal: {
            color: '#03d5a0',
            opacity: 1,
            width: 3,
          },
        },
        progressiveThreshold: 500,
        progressive: 200,
        data: [{
          coords: ticket_lines.ticket_lines.length > 1 ? coordObjectsToArraysByTrans(ticket_lines.ticket_lines || []) : coo1,
        }]
      });
      //是否显示原始轨迹点
      show_points ? series_data.push({
        name: 'carPoints',
        type: 'scatter',
        symbol: 'circle',
        coordinateSystem: 'bmap',
        zlevel: 8,
        data : coordObjectsToArraysByPoints(ticket_lines.ticket_lines || []),
        symbolSize: [10, 10],
        tooltip:{
          formatter:function(a,b,c){
            return a.data.time + '<br />' + a.data.speed + 'km/h';
          }
        },
        itemStyle: {
          normal: {
            color:"#5D59E8",
            opacity: 0.8
          }
        },
      }) : series_data.push({
        name: 'carPoints',
        type: 'scatter',
        symbol: 'circle',
        coordinateSystem: 'bmap',
        zlevel: 5,
        data : coordObjectsToArraysByPoints(ticket_lines.ticket_lines || []),
        symbolSize: [10, 10],
        tooltip:{
          formatter:function(a,b,c){
            return a.data.time + '<br />' + a.data.speed + 'km/h';
          }
        },
        itemStyle: {
          normal: {
            color:"#5D59E8",
            opacity: 0
          }
        },
      });
      //是否显示停靠点
      show_stops ? series_data.push({
        type: 'scatter',
        coordinateSystem: 'bmap',
        name: 'overTicket',
        symbol: 'circle',
        symbolSize: 15,
        zlevel: 8,
        itemStyle: {
          color: '#1491FB',
          opacity: 1,
        },
        tooltip: {
          show: true,
          formatter: function(a){
            return a.data.time + '\n停靠：' + a.data.stay_time + '分钟'
          }
        },
        label: {
          show: true,
          position: 'top',
          backgroundColor: 'rgba(255, 255, 255, .85)',
          padding: [2, 5],
          formatter: function(a){return a.data.time + '\n停靠：' + a.data.stay_time + '分钟'}
        },
        data: coordObjectsToArraysByTicket(ticket_lines.stop_points || []),
      }) : series_data.push({
        type: 'scatter',
        coordinateSystem: 'bmap',
        name: 'overTicket',
        symbol: 'circle',
        symbolSize: 15,
        itemStyle: {
          color: '#1491FB',
          opacity: 0,
        },
        label: {
          show: true,
          position: 'top',
          backgroundColor: 'rgba(255, 255, 255, .65)',
          padding: [2, 5],
          formatter: function(a){return a.data.time + '\n停靠：' + a.data.stay_time + '分钟'}
        },
        data: coordObjectsToArraysByTicket(ticket_lines.stop_points || []),
      });
      play_begin ? series_data.push({
        name: 'currscatter',
        type: 'scatter',
        symbol: 'image:///images/center/a2.png',
        symbolRotate: (360 - ticket_lines.ticket_lines[movePos].direction),
        symbolSize: [15, 42.5],
        coordinateSystem: 'bmap',
        zlevel: 8,
        data : [{time: ticket_lines.ticket_lines[movePos].time,direction: ticket_lines.ticket_lines[movePos].direction, speed: ticket_lines.ticket_lines[movePos].speed, value: h_common.GPS_transformation(ticket_lines.ticket_lines[movePos].lat, ticket_lines.ticket_lines[movePos].lng) }] ,//coordObjectsToArrays([ticket_lines.ticket_lines[movePos]] || []),//[ticket_lines.ticket_lines[movePos]],
        itemStyle: {
          normal: {
            opacity: 1,
            shadowBlur: 1,
            shadowColor: '#999966',
            shadowOffsetX: 1,
            shadowOffsetY: 1,
          }
        },
        tooltip:{
          formatter:function(a){
            return a.name;
          }
        },
        label:{
          normal:{
            show:true,
            position:'top',
            formatter:function(a){
              return a.data.time + '\n' + a.data.speed + 'km/h';
            },
            backgroundColor:'#fff',
            padding:5

          }
        },
      }) : null;
      if(newConsum){
        series_data.push({
          type: 'scatter',
          coordinateSystem: 'bmap',
          labelName: 'label',
          symbol: 'image:///images/center/gray4.png',
          symbolSize: [34, 46],
          zlevel: 7,
          rippleEffect: {
            brushType: 'stroke',
          },
          name: 'newConsum',
          label: {
            normal: {
              show: true,
              position: 'inside',
              offset: [-1, -5],
              color: '#ffffff',
              formatter: function (a){return '疑'},
            },
          },
          itemStyle: {
            normal: {
              color: '#1491fb',
              opacity: 1,
            },
          },
          tooltip: {
            formatter: '{b}'
          },
          data: [{name: newConsum.name, id: newConsum.id, department_id: newConsum.department_id, value: [newConsum.lng, newConsum.lat]}],
        })
      }
      if(ticket_lines.ticket_lines.length > 0){
        const startData = [{name: '起点', value: h_common.GPS_transformation(ticket_lines.ticket_lines[0].lat, ticket_lines.ticket_lines[0].lng)}];
        const endData = [{name: '终点', value: h_common.GPS_transformation(ticket_lines.ticket_lines[ticket_lines.ticket_lines.length -1].lat, ticket_lines.ticket_lines[ticket_lines.ticket_lines.length -1].lng)}];
        series_data.push(startOrEndPoint('start_pointer', 'circle', 10, DuplicateRemoval(startData), '起点', '/images/center/diago1.png', '#03d5a0'));
        series_data.push(startOrEndPoint('end_pointer', 'circle', 10, DuplicateRemoval(endData), '终点', '/images/center/diago3.png', '#EB3873'));
      }
      if(play_begin){
        setCenterFlag(false);
        let m_c = h_common.GPS_transformation(ticket_lines.ticket_lines[movePos].lat, ticket_lines.ticket_lines[movePos].lng);
        if(bound.containsPoint(new BMap.Point(m_c[0], m_c[1])) !== true) {
          setCenter(m_c);
          setCenterFlag(true);
        }
      }else{
        setCenterFlag(false);
        if(ticket_lines.ticket_lines.length > 0){
          setCenter(h_common.GPS_transformation(ticket_lines.ticket_lines[0].lat, ticket_lines.ticket_lines[0].lng));
        }
      }
      setTicketSeries([...series_data])
    }
  }, [ticket_lines, rectify, show_points, show_stops, moveSpeed, movePos, newConsum, right_flag1]);
  useEffect(() => {

  }, [play_begin, movePos]);
  useInterval(() => {
    setMovePos(movePos + 1);
    setPlayIndex(movePos + 1);
  }, (play_begin && (movePos !== slider.length -1 ) && !play_end) ? moveSpeed : null);

  useEffect(() => {
    const webRTC1 = document.createElement('script');
    webRTC1.type = 'text/javascript';
    webRTC1.src = `/webim/webim.config.js`;
    document.head.appendChild(webRTC1);

    const webRTC2 = document.createElement('script');
    webRTC2.type = 'text/javascript';
    webRTC2.src = `/webim/strophe-1.2.8.min.js`;
    document.head.appendChild(webRTC2);

    const webRTC3 = document.createElement('script');
    webRTC3.type = 'text/javascript';
    webRTC3.src = `/webim/websdk-1.4.13.js`;
    document.head.appendChild(webRTC3);

    const webRTC4 = document.createElement('script');
    webRTC4.type = 'text/javascript';
    webRTC4.src = `/webim/webrtc/adapter.js`;
    document.head.appendChild(webRTC4);

    const webRTC5 = document.createElement('script');
    webRTC5.type = 'text/javascript';
    webRTC5.src = `/webim/webrtc/webrtc-1.4.13.js`;
    document.head.appendChild(webRTC5);

    const webRTC6 = document.createElement('script');
    webRTC6.type = 'text/javascript';
    webRTC6.src = `/webim/MediaStreamRecorder.js`;
    document.head.appendChild(webRTC6);
    request({
      url: 'web/web_rtc/register',
      data: {}
    }).then( res => {
      if(res.data.code === 200){

      }
    });
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `/LodopFuncs.js`;
    document.head.appendChild(script);
  }, []);


  function renderBrushed(params){
    if(params.batch[0].selected[0].seriesName === 'forOverTicket'){

    }
    else{
      let dataIndex0 = params.batch[0].selected[0].dataIndex;
      let dataIndex1 = params.batch[0].selected[1].dataIndex;
      let dataIndex2 = params.batch[0].selected[2].dataIndex;
      let dataIndex3 = params.batch[0].selected[3].dataIndex;
      let dataIndex = dataIndex0.concat(dataIndex1).concat(dataIndex2).concat(dataIndex3);
      let arr = [];
      let data0 = [], data1 = [], data2 = [], data3 = [];
      plansData.map((v) => {
        if(v.type === 'building'){
          data0.push(v)
        }else if(v.type === 'sand_factory'){
          data1.push(v)
        }else if(v.type === 'consum'){
          data3.push(v)
        }else{
          data2.push(v);
        }
      });
      if(dataIndex0.length > 0){
        dataIndex0.map( v => {
          const id = data0[v].id;
          const department_id = data0[v].department_id;
          plansListData.map(({start_id, start_department_id, end_id, end_department_id, ...data }) => {
            if((id === start_id && department_id === start_department_id) || (id === end_id && department_id === end_department_id)){
              arr.push({start_id, start_department_id, end_id, end_department_id, ...data})
            }
          });
        });
      }
      if(dataIndex1.length > 0){
        dataIndex1.map( v => {
          const id = data1[v].id;
          const department_id = data1[v].department_id;
          plansListData.map(({start_id, start_department_id, end_id, end_department_id, ...data }) => {
            if((id === start_id && department_id === start_department_id) || (id === end_id && department_id === end_department_id)){
              arr.push({start_id, start_department_id, end_id, end_department_id, ...data})
            }
          });
        });
      }
      if(dataIndex2.length > 0){
        dataIndex2.map( v => {
          const id = data2[v].id;
          const department_id = data2[v].department_id;
          plansListData.map(({start_id, start_department_id, end_id, end_department_id, ...data }) => {
            if((id === start_id && department_id === start_department_id) || (id === end_id && department_id === end_department_id)){
              arr.push({start_id, start_department_id, end_id, end_department_id, ...data})
            }
          });
        });
      }
      if(dataIndex3.length > 0){
        dataIndex3.map( v => {
          const id = data3[v].id;
          const department_id = data3[v].department_id;
          plansListData.map(({start_id, start_department_id, end_id, end_department_id, ...data }) => {
            if((id === start_id && department_id === start_department_id) || (id === end_id && department_id === end_department_id)){
              arr.push({start_id, start_department_id, end_id, end_department_id, ...data})
            }
          });
        });
      }
      if(dataIndex.length > 0){
        setDataSource(arr)
      }
      else{
        setDataSource(plansListData);
      }
      const selected = params.batch[0].selected;
      let stopIndex = [];
      let elseIndex = [];
      selected.map(v => {
        if(v.seriesName === "plansStopPoints"){
          stopIndex = v.dataIndex;
        }
        else if(v.seriesName === 'plans_elseListPoint'){
          elseIndex = v.dataIndex
        }
      });
      if(stopIndex.length > 0) {
        const ticketIds = stopIndex.map(v => (plansStopData[v].ticket_id));
        setStopOrList(true);
        setTicketIds(ticketIds.join(','));
      }
      if(elseIndex.length > 0){
        const plansElsePoint = elseIndex.map(v => (plansElseList[v].id));
      }
    }
  }
  //点击 plans.lines 与 plans.lists 进行匹配筛选
  function dressingListsBylines({l_start_id, l_start_department_id, l_end_id, l_end_department_id, ...data}){
    setLinesSource({start_id: l_start_id, start_department_id: l_start_department_id, end_id: l_end_id, end_department_id: l_end_department_id, ...data});
    setLinesFlag(true);
    let arr = [];
    plansListData.map( ({start_id, start_department_id, end_id, end_department_id, ...data}) => {
      if(l_start_id === start_id && l_start_department_id === start_department_id && l_end_id === end_id && l_end_department_id === l_end_department_id){
        arr.push({start_id, start_department_id, end_id, end_department_id, ...data})
      }
    });
    setDataSource(arr);
    setVisibleTable(true)
  }
  // 点击 plans.sourceList 与 plans.lists 禁行匹配筛选
  function dressingListsBySourceList({id, department_id, b_type, ...data}) {
    let arr = [];
    plansListData.map(({start_id, start_department_id, end_id, end_department_id, ...data }) => {
      if((id === start_id && department_id === start_department_id) || (id === end_id && department_id === end_department_id)){
        arr.push({start_id, start_department_id, end_id, end_department_id, ...data})
      }
    });
    setDataSource(arr);
    setVisibleTable(true);
    if(b_type === 'consum'){
      setVisibleTitle('消纳场报表');
      setConcertData({name: data.name, address: data.address, images: data.images, videos: data.videos, flag: true});
    }else{
      setConcertData({flag: false})
      setVisibleTitle('接管报表');
    }
  }

  function showSuspectConsum(label, data, point){
    setVisibleBuild(true);
    chartBuild = echarts.init(document.getElementById('consumChart'));
    useLine(chartBuild, label, {
      type: "line",
      data: data,
      lineStyle: {
        width: 3,
      }
    } )
  }

  function useLine(chart, label, series) {
    const consumOption = {
      color: ['#1491FB'],
      tooltip: {
        trigger: 'axis',
        splitLine: {
          show: false
        }
      },
      xAxis: {
        type: 'category',
        data: label,
        axisLabel: {
          color: "#191D2D",
        },
        axisLine: {
          lineStyle: {
            color: "#E9E9E9"
          }
        },
      },
      yAxis: {
        type: 'value',
        inside: true,
        name: '车辆数',
        nameTextStyle: {
          color: '#191D2D'
        },
        splitLine: {
          lineStyle: {
            color: "#E9E9E9"
          }
        },
        axisLabel: {
          color: "#191D2D",
        },
        axisLine: {
          show: false,
          lineStyle: {
            width: 0,
            color: "#E9E9E9"
          }
        },
      },
      series: series
    };
    chartBuild.setOption(consumOption);
  }
  // charts 的一些操作
  useEffect(() => {
    if (!isChartUsable()) return;
    chart.on('click', (data) => {
      if(data.seriesName === 'new_ticketPoint'){
        // dressingListsBySourceList(data.data);
        // if (data.data.type === 'start') {selectPlan(data.data.index)}
      }
      else if(data.seriesName === 'ticketLines') { // 点击plans.lines获取相关联的固定源
        // dressingListsBylines(data.data);
      }
      else if(data.seriesName === 'plans_elseList'){ // 点击plans.elseList获取该联单详情
        const id = data.data.id;
        setTicketId(id);getTicketDetail(id);
      }
      else if(data.seriesName === 'forOverTicket'){ // 点击工地、砂石场、商砼、消纳厂 结束联单

      } // 点击固定源结束联单
      else if(data.seriesName === 'overTicket' || data.seriesName === 'carPoints'){

      }//点击停靠点或原始轨迹点结束联单
      else if(data.seriesName === 'suspectConsum'){ //点击黑工地出现折线图
        showSuspectConsum(data.data.label, data.data.car_num, data.data.point)
      }
    });
    chart.on('brushselected', renderBrushed);
  }, [isChartUsable, chart]);
  function getTicketLinesVolumn(data){
    let num = 0;
    data.map((v) => {
      if(v.volumn){
        num += parseFloat(v.volumn)
      }
    });
    return num === 0 ? 0 : num.toFixed(1);
  }

  const startOrEndPoint = (name, symbol='circle', symbolSize, data, format, backImage, itemColor) => {
    return {
      type: "scatter",
      // symbol: 'path://M100 100,A50 50,0 1 1 100 101z M175 100,A25 25,0 1 0 175 101z',
      name: name,
      symbol: symbol,
      symbolSize: symbolSize,
      zlevel: 9,
      coordinateSystem: 'bmap',
      data: data,
      tooltip:{
        show: false,
        formatter: function(parmas){
          return '起点:'
        }
      },
      label:{
        normal:{
          show : true,
          position: 'top',
          formatter:function(a,b,c){
            return `{arrow|${format}}`
          },
          rich: {
            arrow:{
              color: '#fff',
              fontSize:12,
              width: 40,
              height: 26,
              align:'center',
              padding:[5,0,0,0],
              backgroundColor: {
                image: backImage,
                repeat:'no-repeat'
              },
              shadowBlur: 1,
              shadowColor: '#d4d4d4',
              shadowOffsetX: 1,
              shadowOffsetY: 1,
            }
          }
        }
      },
      itemStyle: {
        normal: {
          color: itemColor,
          opacity: 1,

        }
      }
    }
  };

  useEffect(() => {
    let new_series = [];
    let LinesSum = [];
    if (!plan.id) {
      setDataSource(plans.list);
      if(plans.sourceList.length > 0){
        new_series = sourceList('#22B74F', plans.sourceList.map(({type, name, lng, lat, ...data}, index) => ({
          index,
          type: 'start',
          b_type: type,
          name: name,
          value:  [lng, lat],
          ...data
        })))
      }
      if(plans.stopPoints.length > 0 && (isInArray(right_flag, 6) || isInArray(right_flag, 0))){
        if(isInArray(top_flag, 1)){
          new_series.push(plansStopPoints('#fd322c', plans.stopPoints.map(({ ...data}, index) => ({
            index,
            ...data
          }))))
        }
      }
      if(plans.ticketLines.length > 0 && (isInArray(right_flag, 6) || isInArray(right_flag, 0))){
        let m_arr = [], pointer = [];
        let m_index = 0;
        plans.ticketLines.map((v, i) => {
          if(v.points.length > 1){
            m_arr.push({coords: []});
            v.points.map(({lng, lat, ...data}, j) => {
              m_arr[m_index].coords.push(h_common.GPS_transformation(lat, lng));
              if(j === 0){
                pointer.push({value: h_common.GPS_transformation(lat, lng), ...data, name: '起点'})
              }
            })
            m_index ++;
          }
        });
        if(isInArray(top_flag, 1)){
          new_series.push({
            type: 'lines',
            name: 'plansTicketLines',
            polyline: true,
            silent: true,
            coordinateSystem: 'bmap',
            symbol: ['none', 'arrow'],
            zlevel: 5,
            effect: {
              show: true,
              period: 6,
              trailLength: 0,
              symbol: 'image:///images/center/car_pd.png',
              symbolSize: [48, 48],
            },
            tooltip: {
              show: false,
            },
            lineStyle: {
              normal: {
                color: '#FF9316',
                opacity: 1,
                width: 2,
                curveness: 0.5
              },
            },
            progressiveThreshold: 500,
            progressive: 200,
            data: m_arr,
          });
          new_series.push(startOrEndPoint('start_pointer', 'circle', 10, DuplicateRemoval(pointer), '起点', '/images/center/diago1.png', '#03d5a0'));
        }
        if((isInArray(right_flag, 6) || isInArray(right_flag, 0)) && isInArray(top_flag, 1)){
          let data1 = [], data2 = [], data3 = [], data4 =[];
          plans.sourceList.map(({id, department_id, type, lng, lat, ...list}) => {
            plans.ticketSource.map(({instance_id, ...data}) => {
              if(id === instance_id && department_id === data.department_id){
                if(type === 'building'){
                  data1.push({value: [lng, lat], id, department_id, type, ...list})
                }else if(type === 'sand_factory'){
                  data2.push({value: [lng, lat], id, department_id, type, ...list})
                }else if(type === 'consum'){
                  data4.push({value: [lng, lat], id, department_id, type, ...list})
                }else{
                  data3.push({value: [lng, lat], id, department_id, type, ...list})
                }
              }
            })
          });
          new_series.push({
              type: 'scatter',
              coordinateSystem: 'bmap',
              labelName: 'label',
              symbol: 'image:///images/center/green1.png',
              symbolSize: [45, 45],
              zlevel: 2,
              rippleEffect: {
                brushType: 'stroke',
              },
              name: 'new_ticketPoint',
              label: {
                normal: {
                  show: true,
                  position: 'inside',
                  offset: [0, -1],
                  color: '#ffffff',
                  formatter: function (a){return '工'},
                },
              },
              itemStyle: {
                normal: {
                  color: '#22B74F',
                  opacity: 1,
                },
              },
              tooltip: {
                formatter: '{b}'
              },
              data: data1,
            },
            {
              type: 'scatter',
              coordinateSystem: 'bmap',
              labelName: 'label',
              symbol: 'image:///images/center/orange3.png',
              symbolSize: [32, 44],
              zlevel: 2,
              rippleEffect: {
                brushType: 'stroke',
              },
              name: 'ticketPoint',
              label: {
                normal: {
                  show: true,
                  position: 'inside',
                  offset: [-1, -10],
                  color: '#ffffff',
                  formatter: function (a){return '砂'},
                },
              },
              itemStyle: {
                normal: {
                  color: '#22B74F',
                  opacity: 1,
                },
              },
              tooltip: {
                formatter: '{b}'
              },
              data: data2,
            },
            {
              type: 'scatter',
              coordinateSystem: 'bmap',
              labelName: 'label',
              symbol: 'image:///images/center/radar_c.png',
              symbolSize: [32, 47],
              zlevel: 2,
              rippleEffect: {
                brushType: 'stroke',
              },
              name: 'ticketPoint',
              label: {
                normal: {
                  show: true,
                  position: 'inside',
                  offset: [-1, -5],
                  color: '#ffffff',
                  formatter: function (a){return '商'},
                },
              },
              itemStyle: {
                normal: {
                  color: '#22B74F',
                  opacity: 1,
                },
              },
              tooltip: {
                formatter: '{b}'
              },
              data: data3,
            },
            {
              type: 'scatter',
              coordinateSystem: 'bmap',
              labelName: 'label',
              symbol: 'image:///images/center/red4.png',
              symbolSize: [34, 46],
              zlevel: 2,
              rippleEffect: {
                brushType: 'stroke',
              },
              name: 'ticketPoint',
              label: {
                normal: {
                  show: true,
                  position: 'inside',
                  offset: [-1, -5],
                  color: '#ffffff',
                  formatter: function (a){return '消'},
                },
              },
              itemStyle: {
                normal: {
                  color: '#22B74F',
                  opacity: 1,
                },
              },
              tooltip: {
                formatter: '{b}'
              },
              data: data4,
            })
        }
      }
      if(plans.lines.length > 0 && (isInArray(right_flag, 5) || isInArray(right_flag, 0))){
        let coords = [], coords_sand = [], ex_coords = [], startPoints = [], endPoints = [];
        let data1 = [], data2 = [], data3 = [], data4 = [];
        plans.lines.map( ({start_id, start_department_id, end_id, end_department_id, volumn, num, ...data}) => {
          if(data.end_address.lng.length > 3 && data.end_address.type === 'sand_factory'){
            coords_sand.push({volumn: volumn, num: num, l_start_id: start_id, l_start_department_id: start_department_id, l_end_id: end_id, l_end_department_id: end_department_id, start_name: data.start_address.name, end_name: data.end_address.name, coords: [[data.start_address.lng, data.start_address.lat], [data.end_address.lng, data.end_address.lat]]})
          }
          else if(data.end_address.lng.length > 3 && data.end_address.type !== 'consum'){
            coords.push({volumn: volumn, num: num, l_start_id: start_id, l_start_department_id: start_department_id, l_end_id: end_id, l_end_department_id: end_department_id, start_name: data.start_address.name, end_name: data.end_address.name, coords: [[data.start_address.lng, data.start_address.lat], [data.end_address.lng, data.end_address.lat]]})
          }else {
            ex_coords.push({volumn: volumn, num: num, l_start_id: start_id, l_start_department_id: start_department_id, l_end_id: end_id, l_end_department_id: end_department_id, start_name: data.start_address.name, end_name: data.end_address.name, coords: [[data.start_address.lng, data.start_address.lat], [data.end_address.lng, data.end_address.lat]]})
          }
          if((isInArray(right_flag, 5) || isInArray(right_flag, 0)) && isInArray(top_flag, 2) && isInArray(top_flag, 5)){
            plans.sourceList.map(({id, department_id, lng, lat, type, ...list}) => {
              if((start_id === id && start_department_id === department_id) || (end_id === id && end_department_id === department_id)){
                if(type === 'building' ){
                  data1.push({value: [lng, lat], id, department_id, type, ...list})
                }else if(type === 'sand_factory'){
                  data2.push({value: [lng, lat], id, department_id, type, ...list})
                }else if(type === 'consum'){
                  data4.push({value: [lng, lat], id, department_id, type, ...list})
                }else{
                  data3.push({value: [lng, lat], id, department_id, type, ...list})
                }
              }
            })
          }
          else if((isInArray(right_flag, 5) || isInArray(right_flag, 0)) && isInArray(top_flag, 2) && !isInArray(top_flag, 5)){
            if(data.end_address.type !== 'sand_factory' && data.end_address.type !== 'consum'){
              plans.sourceList.map(({id, department_id, lng, lat, type, ...list}) => {
                if((start_id === id && start_department_id === department_id) || (end_id === id && end_department_id === department_id)){
                  if(type === 'building'){
                    data1.push({value: [lng, lat], id, department_id, type, ...list})
                  }else if(type === 'sand_factory'){
                    data2.push({value: [lng, lat], id, department_id, type, ...list})
                  }else if(type === 'consum'){
                    data4.push({value: [lng, lat], id, department_id, type, ...list})
                  }else{
                    data3.push({value: [lng, lat], id, department_id, type, ...list})
                  }
                }
              })
            }
          }
          else if((isInArray(right_flag, 5) || isInArray(right_flag, 0)) && !isInArray(top_flag, 2) && isInArray(top_flag, 5)){
            if(data.end_address.type !== 'building' && data.end_address.type !== 'consum'){
              plans.sourceList.map(({id, department_id, lng, lat, type, ...list}) => {
                if((start_id === id && start_department_id === department_id) || (end_id === id && end_department_id === department_id)){
                  if(type === 'building'){
                    data1.push({value: [lng, lat], id, department_id, type, ...list})
                  }else if(type === 'sand_factory'){
                    data2.push({value: [lng, lat], id, department_id, type, ...list})
                  }else if(type === 'consum'){
                    data4.push({value: [lng, lat], id, department_id, type, ...list})
                  }else{
                    data3.push({value: [lng, lat], id, department_id, type, ...list})
                  }
                }
              })
            }
          }
        });
        if(isInArray(top_flag, 2)){ // 到工地
          new_series.push({
            type: 'lines',
            name: 'ticketLines',
            coordinateSystem: 'bmap',
            symbol: ['none', 'arrow'],
            zlevel: 5,
            effect: {
              show: true,
              period: 6,
              trailLength: 0,
              symbol: 'image:///images/center/a2.png',
              symbolSize: [15, 42.5],
            },
            tooltip: {
              formatter: function(a) {return '起点: ' + a.data.start_name + '</br>终点: ' + a.data.end_name + '</br>趟数: ' + a.data.num + '</br>方量: ' + a.data.volumn}
            },
            lineStyle: {
              normal: {
                color: '#03d5a0',
                opacity: 1,
                width: 2,
                curveness: 0.5
              },
            },
            progressiveThreshold: 500,
            progressive: 200,
            data: coords,
          });
        }
        if(isInArray(top_flag, 5)){ // 到砂石场
          new_series.push({
            type: 'lines',
            name: 'ticketLines',
            coordinateSystem: 'bmap',
            symbol: ['none', 'arrow'],
            zlevel: 5,
            effect: {
              show: true,
              period: 6,
              trailLength: 0,
              symbol: 'image:///images/center/a2.png',
              symbolSize: [15, 42.5],
            },
            tooltip: {
              formatter: function(a) {return '起点: ' + a.data.start_name + '</br>终点: ' + a.data.end_name + '</br>趟数: ' + a.data.num + '</br>方量: ' + a.data.volumn}
            },
            lineStyle: {
              normal: {
                color: '#9A62FE',
                opacity: 1,
                width: 2,
                curveness: 0.5
              },
            },
            progressiveThreshold: 500,
            progressive: 200,
            data: coords_sand,
          });
        }
        // 可能lines的起点为消纳场
        if(isInArray(top_flag, 3)){
          new_series.push({
            type: 'lines',
            coordinateSystem: 'bmap',
            symbol: ['none', 'arrow'],
            name: 'ticketLines',
            zlevel: 4,
            lineStyle: {
              normal: {
                color: '#EB3873',
                opacity: 1,
                width: 2,
                curveness: 0.5
              },
            },
            effect: {
              show: true,
              period: 6,
              trailLength: 0,
              symbol: 'image:///images/center/a1.png',
              symbolSize: [15, 42.5],
            },
            tooltip: {
              formatter: function(a) {return '起点: ' + a.data.start_name + '</br>终点: ' + a.data.end_name + '</br>趟数: ' + a.data.num + '</br>方量: ' + a.data.volumn}
            },
            progressiveThreshold: 500,
            progressive: 200,
            data: ex_coords,
          });
        }
        if(isInArray(right_flag, 5)){
          new_series.push(new_ticketPoint(DuplicateRemoval(data1), 'image:///images/center/green1.png', [45, 45], [0, -1], '工', '#03d5a0'));
          new_series.push(new_ticketPoint(DuplicateRemoval(data2), 'image:///images/center/orange3.png', [32, 44], [-1, -10], '砂', '#03d5a0'));
          new_series.push(new_ticketPoint(DuplicateRemoval(data3), 'image:///images/center/radar_c.png', [32, 47], [-1, -5], '商', '#03d5a0'));
          new_series.push(new_ticketPoint(DuplicateRemoval(data4), 'image:///images/center/red4.png', [34, 46], [-1, -5], '消', '#03d5a0'));
        }
      }
      // plans.elseList 未知终点联单 没有终点 点击查看该联单详情
      if(plans.elseList.length > 0 && (isInArray(right_flag, 5) || isInArray(right_flag, 0))){
        let coords = [], data1 = [], data2 = [], data3 = [], data4 = [], endPointer = [];
        plans.elseList.map( ({start_id, start_department_id, end_id, end_department_id, volumn, num, ...data}) => {
          endPointer.push({value: [data.end_address.lng, data.end_address.lat], start_id, start_department_id, end_id, end_department_id, volumn, num, ...data})
          if(data.start_address.lng > 3 && data.end_address.lng > 3){
            coords.push({name: data.transport_name, start_name: data.start_address.name, end_name: data.end_address.name, volumn: volumn, coords: [[data.start_address.lng, data.start_address.lat], [data.end_address.lng, data.end_address.lat]], ...data});
            if(isInArray(right_flag, 5) && isInArray(top_flag, 4)){
              plans.sourceList.map(({id, department_id, lng, lat, type, ...list}) => {
                if((start_id === id && start_department_id === department_id) || (end_id === id && end_department_id === department_id)){
                  if(type === 'building'){
                    data1.push({value: [lng, lat], id, department_id, type, ...list})
                  }else if(type === 'sand_factory'){
                    data2.push({value: [lng, lat], id, department_id, type, ...list})
                  }else if(type === 'consum'){
                    data4.push({value: [lng, lat], id, department_id, type, ...list})
                  }else{
                    data3.push({value: [lng, lat], id, department_id, type, ...list})
                  }
                }
              })
            }
          }
        });
        if(isInArray(top_flag, 4)){
          new_series.push({
            type: 'lines',
            coordinateSystem: 'bmap',
            name: 'plans_elseList',
            symbol: ['none', 'arrow'],
            zlevel: 4,
            lineStyle: {
              normal: {
                color: '#1491fb',
                opacity: 1,
                width: 2,
                curveness: 0.5
              },
            },
            effect: {
              show: true,
              period: 6,
              trailLength: 0,
              symbol: 'image:///images/center/a1.png',
              symbolSize: [15, 42.5],
            },
            progressiveThreshold: 500,
            progressive: 200,
            tooltip: {
              formatter: function(a) {return '起点: ' + a.data.start_name + '</br>终点: ' + a.data.end_name + '</br>方量: ' + a.data.volumn}},
            data: coords,
          });
        }
        /*   new_series.push({
             type: 'scatter',
             coordinateSystem: 'bmap',
             labelName: 'label',
             // symbol: 'image:///images/center/green1.png',
             symbolSize: 10,
             zlevel: 2,
             rippleEffect: {
               brushType: 'stroke',
             },
             name: 'plans_elseListPoint',
             tooltip: {
               formatter: '停靠点'
             },
             label: {
               normal: {
                 show: false,
                 position: 'inside',
                 offset: [0, -1],
                 color: '#ffffff',
                 formatter: function (a){return '工'},
               },
             },
             itemStyle: {
               normal: {
                 color: '#03d5a0',
                 opacity: 1,
               },
             },
             data: endPointer,
           });*/
        if(isInArray(right_flag, 5)){
          new_series.push(new_ticketPoint(DuplicateRemoval(data1), 'image:///images/center/green1.png', [45, 45], [0, -1], '工', '#03d5a0'));
          new_series.push(new_ticketPoint(DuplicateRemoval(data2), 'image:///images/center/orange3.png', [32, 44], [-1, -10], '砂', '#03d5a0'));
          new_series.push(new_ticketPoint(DuplicateRemoval(data3), 'image:///images/center/radar_c.png', [32, 47], [-1, -5], '商', '#03d5a0'));
          new_series.push(new_ticketPoint(DuplicateRemoval(data4), 'image:///images/center/red4.png', [34, 46], [-1, -5], '消', '#03d5a0'));
        }
      }

      let data_b = [], data_s = [], data_c = [], data_e = [];
      LinesSum.push({sum: plans.ticketLines.length, volumn: getTicketLinesVolumn(plans.ticketLines)});
      // LinesSum.push({sum: plans.ticketLines.length, volumn: plans.ticketLines.length * 24});
      plans.list.map(({end_address: {type}, ...data}) => {
        if(type === 'building' || type === 'concrete'){data_b.push(data)}
        else if(type === 'sand_factory'){data_s.push(data)}
        else if(type === 'consum'){data_c.push(data)}
        else{
          data_e.push(data)
        }
      });
      const a = getDataByParams(1, data_b);
      const b = getDataByParams(1, data_s);
      const c = getDataByParams(1, data_c);
      const d = getDataByParams(1, data_e);
      LinesSum.push({sum: getDataByParams(2, data_b), volumn: a === 0 ? 0 : a.toFixed(1)});
      LinesSum.push({sum: getDataByParams(2, data_s), volumn: b === 0 ? 0 : b.toFixed(1)});
      LinesSum.push({sum: getDataByParams(2, data_c), volumn: c === 0 ? 0 : c.toFixed(1)});
      LinesSum.push({sum: getDataByParams(2, data_e), volumn: d === 0 ? 0 : d.toFixed(1)});

      // LinesSum.push({sum: plans.elseList.length, volumn: getDataByParams(1, plans.elseList) === 0 ? getDataByParams(1, plans.elseList) : (getDataByParams(1, plans.elseList).toFixed(1))});
      setCountLines(LinesSum);
      setSeries(new_series);
    }
    else {
      let arr = [];
      points = [];
      if(details){
        details.ticket_lines.map(line => {
          line.map((v) => {arr.push(h_common.GPS_transformation(v.lat, v.lng))})
        })
      }
      const detailSeries = !details ? [] : [
        // 无线路联单
        {
          type: 'lines',
          coordinateSystem: 'bmap',
          // zlevel: 2,
          symbol: ['none', 'arrow'],
          symbolSize: 10,
          effect: {
            show: true,
            period: 6,
            trailLength: 0,
            symbol: 'image:///images/center/a1.png',
            symbolSize: [15, 42.5],
          },
          lineStyle: {
            normal: {
              color: '#5D59E8',
              width: 3,
              opacity: 0.6,
              curveness: 0.2,
            },
          },
          data: (details.tickets || []).map(({ lng, lat }) => ({
            coords: [[plan.lng, plan.lat], [lng, lat]],
          })),
        },
        // 联单线路
        {
          type: 'lines',
          coordinateSystem: 'bmap',
          effect: {
            show: true,
            period: 12,
            trailLength: 0,
            symbol: 'image:///images/center/a2.png',
            symbolSize: [15, 42.5],
          },
          polyline: true,
          silent: true,
          lineStyle: {
            normal: {
              color: '#5A94DF',
              opacity: 1,
              width: 4,
            },
          },
          progressiveThreshold: 500,
          progressive: 200,
          data: (details.ticket_lines || []).map(line => ({
            coords: coordObjectsToArraysByTrans(line),
          })),
        },
        {
          type: 'scatter',
          coordinateSystem: 'bmap',
          symbol: 'circle',
          symbolSize: 2,
          itemStyle: {
            color: '#FF9316',
            opacity: 1,
          },
          tooltip: {
            formatter: '{b}'
          },
          data: arr,
        },
        // 疑似停靠
        {
          type: 'scatter',
          coordinateSystem: 'bmap',
          symbol: 'circle',
          symbolSize: 8,
          itemStyle: {
            color: '#FA2925',
            opacity: 1,
          },
          tooltip: {
            formatter: '{b}'
          },
          data: coordObjectsToArraysByTrans(details.stop_points || []),
        },
      ];
      setSeries([
        // 起点：工地
        location('#D81159', [{
          type: 'start',
          name: plan.name,
          value: [plan.lng, plan.lat],
        }]),
        ...detailSeries,
      ]);
    }
  }, [chart, plans, plan, details, ticketOver, right_flag, linesSource, top_flag]);

  useMap(isChartUsable, chart, ticketSeries ? ticketSeries : series, center, play_begin, play_end, centerFlag, otherCenterFlag);

  function overTicket(json){
    request('/web/ticket_map/overTicket', json)
      .then(body => {
        if(body.data.code === 200 ){
          message.success('成功结束联单', 6);
          if(json.end_type === 'point'){
            setNewConsum(body.data.data);
          }
        }else{
          message.warn('结束联单失败');
        }} );
  }

  function closeTicketDetail(){
    setTrashShowDetail(false);
  }
  // 呼叫司机
  function startCallDriver (phone, id) {
    setMsg('正在呼叫。。。');
    var options = {
      user: store.getState().info.telephone,
      pwd: 'TFBUserPwd112',
      apiUrl: WebIM.config.apiURL,
      appKey: WebIM.config.appkey
    };
    let conn = new WebIM.connection({
      isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
      https: typeof WebIM.config.https === 'boolean' ? WebIM.config.https : location.protocol === 'https:',
      url: WebIM.config.xmppURL,
      heartBeatWait: WebIM.config.heartBeatWait,
      autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
      autoReconnectInterval: WebIM.config.autoReconnectInterval,
      apiUrl: WebIM.config.apiURL,
      isAutoLogin: true
    });
    conn.open(options);
    let rtcCall = new WebIM.WebRTC.Call({
      connection: conn,
      mediaStreamConstaints: {
        audio: true,
        video: true
      },
      listener: {
        onAcceptCall: function (from, options) {
          console.log('onAcceptCall::', 'from: ', from, 'options: ', options);
        },
        //通过streamType区分视频流和音频流，streamType: 'VOICE'(音频流)，'VIDEO'(视频流)
        onGotRemoteStream: function (stream, streamType) {
          console.log('onGotRemoteStream::', 'stream: ', stream, 'streamType: ', streamType);
          var video = document.getElementById('video');
          video.srcObject = stream;
          setMsg('通话中。。。');
          setCallFlag(true);
        },
        onGotLocalStream: function (stream, streamType) {
          console.log('onGotLocalStream::', 'stream:', stream, 'streamType: ', streamType);
          var video = document.getElementById('localVideo');
          video.srcObject = stream;
        },
        onRinging: function (caller, streamType) {
          console.log("onRinging", caller)
          me.channel.ringing(caller, streamType)
        },
        onTermCall: function (reason) {
          console.log('onTermCall::');
          console.log('reason:', reason);
          setCallDriver(false);
          message.error('用户挂断!');
        },
        onIceConnectionStateChange: function (iceState) {
          console.log('onIceConnectionStateChange::', 'iceState:', iceState, '连接成功');
          setMsg('通话中。。。');
          setCallFlag(true);
        },
        onError: function (e) {
          console.log(e);
          setCallDriver(false);
          if(e.message==='callee is not online!'){
            message.error('呼叫用户不在线!');
          }else{
            message.error('呼叫用户失败!');
          }
        }
      }
    });
    rtcCall.caller = 'mengyuanyuan';
    request({
      url: 'web/web_rtc/addFriend',
      data: {target_userId: id, target_userPhone: phone},
    }).then( res => {
      setTimeout(function(){
        rtcCall.makeVoiceCall(phone);
      },1000);
    });
    setCallDriver(true);
  }

  function rtcCallEnd () {
    rtcCall.endCall();
    setCallDriver(false)
  }
  function selectTicketVolumn(){
    let sum = 0;
    ticketList.map((v) => {
      sum += parseFloat(v.volumn);
    });
    return sum
  }

  const columns = [
    {
      title: '起点',
      dataIndex: 'start_address.name',
      key: 'start_address.name',
      width: 200,
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if(isInArray(arrIndex, index)){
          obj.props.rowSpan = row.start_num;
        }
        else{
          obj.props.rowSpan = 0;
        }
        return obj;
      }
    },
    {
      title: '终点',
      dataIndex: 'end_address.name',
      key: 'end_address.name',
      width: 200,
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if(isInArray(endIndex, index)){
          obj.props.rowSpan = row.end_num;
        }
        else{
          obj.props.rowSpan = 0;
        }
        return obj;
      }
    },
    {
      title: '运企',
      dataIndex: 'transport_name',
      key: 'transport_name',
      width: 150,
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if(isInArray(transportIndex, index)){
          obj.props.rowSpan = row.transport_num;
        }
        else{
          obj.props.rowSpan = 0;
        }
        return obj;
      }
    },
    {
      title: '车牌号',
      dataIndex: 'car_num',
      key: 'car_num',
      width: 100
    },
    {
      title: '趟数',
      dataIndex: 'num',
      key: 'num',
      width: 80
    },
    {
      title: '方量(m³)',
      dataIndex: 'volumn',
      key: 'volumn',
      width: 100
    }
  ];
  // 查看table的合并
  let arrIndex = [];
  let endIndex = [], transportIndex = [];
  function getRowSpan(data) {
    let arr = [];
    let l_start_id = '', l_start_department_id = '', start_num = 0;
    let l_end_id = '', l_end_department_id = '', end_num = 0;
    let l_transport_id = '', transport_num = 0;
    let start_index = 0;
    let end_index = 0;
    let transport_index= 0;
    arrIndex = [];
    endIndex = [];
    transportIndex = [];
    const length = data.length -1
    data.map(({start_id, start_department_id, end_id, end_department_id, transport_id, num,  ...data}, i) => {
      arr.push({start_id, start_department_id, end_id, end_department_id, num,  ...data})
      if(l_start_id === start_id && l_start_department_id === start_department_id){
        start_num ++;
        if(l_end_id === end_id && l_end_department_id === end_department_id){ //终点相同
          end_num ++;
          arr[transport_index].transport_num = transport_num;
          if(l_transport_id === transport_id){
            transport_num ++
          }else{
            // 运企
            transportIndex.push(i);
            transport_num = 0;
            l_transport_id = transport_id;
            transport_index = i;
            transport_num ++;
          }
        }else{ // 起点相同终点不同
          if(i !== 0){
            arr[end_index].end_num = end_num
          }
          endIndex.push(i);
          end_index = i;
          end_num = 0;
          l_end_id = end_id;
          l_end_department_id = end_department_id;
          end_num ++;
          // 运企
          if(i !==0){
            arr[transport_index].transport_num = transport_num;
          }
          transportIndex.push(i);
          // 运企
          transport_num = 0;
          l_transport_id = transport_id;
          transport_index = i;
          transport_num ++;
        }
        if(i === length ){
          arr[start_index].start_num = start_num ;
          arr[end_index].end_num = end_num ;
          arr[transport_index].transport_num = transport_num;
        }
      }else{
        if(i !== 0 || data.length === 1){
          arr[start_index].start_num = start_num;
          arr[end_index].end_num = end_num;
          arr[transport_index].transport_num = transport_num;
        }
        endIndex.push(i);
        arrIndex.push(i);
        transportIndex.push(i);
        // transportIndex.push(i);
        start_index = i;
        start_num = 0;
        l_start_id = start_id;
        l_start_department_id = start_department_id;
        start_num ++;
        //终点
        end_num = 0;
        l_end_id = end_id;
        end_index = i;
        l_end_department_id = end_department_id;
        end_num ++;
        // 运企
        transport_num = 0;
        l_transport_id = transport_id;
        transport_index = i;
        transport_num ++;
      }
    });
    return arr;
  }
  function filterDataBySourceFlag(data, flag){
    const sourceType = ['', 'building', 'sand_factory', 'consum'];
    if(flag === 1){
      return data.map((item) => { return (item.end_address.type === sourceType[flag] || item.end_address.type === 'concrete') ? item : null}).filter(item => item)
    }
    else if(flag){
      return data.map((item) => { return item.end_address.type === sourceType[flag] ? item : null}).filter(item => item)
    }
    else{
      return data.map((item) => { return (!item.end_address.type && item.end_address.type !== 'building' && item.end_address.type !== 'sand_factory' && item.end_address.type !== 'consum' &&  item.end_address.type !== 'concrete') ? item : null}).filter(item => item)
    }
  }
  // 接管报表的一级菜单筛选 --- 区域内外  1 --- 区域内  2 --- 区域外
  function filterAreaData(data, flag){
    if(flag === 1){
      return data.map(({start_area_id, end_area_id, ...item}) => { return start_area_id === area_id && end_area_id === area_id ? item : null}).filter(item => item)
    }
    else if(flag === 2){
      return data.map(({start_area_id, end_area_id, ...item}) => { return start_area_id !== area_id && end_area_id === area_id ? item : null}).filter(item => item)
    }
    else if(flag === 3){
      return data.map(({start_area_id, end_area_id, ...item}) => { return start_area_id === area_id && end_area_id !== area_id ? item : null}).filter(item => item)
    }
  }
  function filterDataSource(data){
    const flag = parseInt(sourceFlag);
    const filter = parseInt(filterFlag);
    let filterData = data;
    let newData = [];
    switch (filter) {
      case 0: filterData = data; break;
      case 1: filterData = filterAreaData(data, 1);break;
      case 2: filterData = filterAreaData(data, 2);break;
      case 3: filterData = filterAreaData(data, 3);break;
    }
    switch (flag) {
      case 0: newData = filterData;break;
      case 1: newData = filterDataBySourceFlag(filterData, 1);break;
      case 2: newData = filterDataBySourceFlag(filterData, 2);break;
      case 3: newData = filterDataBySourceFlag(filterData, 3);break;
      case 4: newData = filterDataBySourceFlag(filterData, false);break;
    }
    return newData;
  }

  function OutToFile(){
    var LODOP = getLodop();
    LODOP.PRINT_INIT("");
    LODOP.ADD_PRINT_TABLE(5,5,"99%","100%",document.getElementById("div1").innerHTML);
    //LODOP.SET_SAVE_MODE("QUICK_SAVE",true);//快速生成（无表格样式,数据量较大或许用到）
    LODOP.SAVE_TO_FILE("报表.xls");
  }

  // 合计方量/趟数 1 - 方量 2 - 趟数
  function getDataByParams(type, dataSource){
    let number = 0;
    switch (type) {
      case 1:
        dataSource.map(({volumn}) => { volumn ? number += parseFloat(volumn) : number});
        break;
      case 2:
        dataSource.map(({num}) => {num ? number += parseFloat(num) : number})
        break;
    }
    return number;
  }
  //右侧控制方法的增加、取消
  function getRightType(type){
    let [...newType] = right_flag;
    setOtherCenterFlag(false);
    if(!isInArray(newType, type)){
      if(type === 0){
        newType = [0]
      }else{
        newType.push(type);
        newType.remove(0);
      }
    }
    else{
      newType.remove(type)
    }
    // if(newType.length === 0){
    //   newType = [0]
    // }

    const r5 = isInArray(newType, 5), r6 = isInArray(newType, 6);
    const t1 = isInArray(top_flag, 1), t2 = isInArray(top_flag, 2), t3 = isInArray(top_flag, 3), t4 = isInArray(top_flag, 4), t5 = isInArray(top_flag, 5);

    if(type === 0 && (!t1 || !t2 || !t3 || !t4 || !t5)){setTopFlag([1, 2, 3, 4, 5])}
    else if((r6 && !t1) && !r5){setTopFlag([1])}
    else if((r6 && !t1) && r5){setTopFlag([...top_flag, 1])}
    else if(!r6 && t1 && r5){setTopFlag([2, 3, 4, 5])}
    else if(!r5 && !r6 && type !== 0){setTopFlag([])}
    else if(!r5 && r6){setTopFlag([1])}
    else if(r5 && !t2 && !t3 && !t4 && !t5 && r6){setTopFlag([1, 2, 3, 4, 5])}
    else if(r5 && !t2 && !t3 && !t4 && !t5 && !r6){setTopFlag([2, 3, 4, 5])}

    setLinesFlag(false);
    setRightFlag(newType);
  }
  function getRightType1(type){
    let [...newType] = right_flag1;
    if(!isInArray(newType, type)){
      if(type === 0){
        newType = [0]
      }else{
        newType.push(type);
        newType.remove(0);
      }
    }
    else{
      newType.remove(type)
    }
    if(newType.length === 0){
      newType = [0]
    }
    setRightFlag1(newType)
  }
  function getTopFlag(type){
    let [...newType] = top_flag;
    if(!isInArray(newType, type)){
      if(type === 0){
        newType = [0]
      }else{
        newType.push(type);
        newType.remove(0);
      }
    }
    else{newType.remove(type)}
    setTopFlag(newType);
    const r5 = isInArray(right_flag, 5), r6 = isInArray(right_flag, 6);
    const t1 = isInArray(newType, 1), t2 = isInArray(newType, 2), t3 = isInArray(newType, 3), t4 = isInArray(newType, 4), t5 = isInArray(newType, 5);
    if(t1 && !r6){
      setRightFlag([...right_flag, 6])
    }
    if(!t1 && r6){
      setRightFlag([...right_flag.remove(6)])
    }
    if(!t2 && !t3 && !t4 && !t5 && r5){
      setRightFlag([...right_flag.remove(5)])
    }
    if((t2 || t3 || t4 || t5) && !r5){
      setRightFlag([...right_flag, 5])
    }
  }
  return (
    <div className={styles.container}>
      <div style={{display: 'flex',flexGrow: 1, width: '100%'}}>
        <div className={styles.overlays}>
          <div>
            <DatePicker.RangePicker className={styles.dates} style={{width: 305}} allowClear={false} size="default" format="YYYY-MM-DD " value={dates} onChange={next => {setDates(next); setVisible(false);planFlag = false;setTrashShowDetail(false);setPlayBegin(false)}} />
          </div>
          {
            !plan.id && (
              <div className={styles.leftTwoAction} >
                <Spin spinning={leftLoading}>
                  {
                    !stopOrList ?
                      <div className={styles.continuousSand}>
                        <div className={styles.leftBoxOne}>
                          <Search defaultValue={keywords} className={styles.antInput}
                                  placeholder="请输入关键字"
                                  onPressEnter={(e) => {setPage(1);setKeyword(keywords)}}
                                  onChange={(e) => {e.persist();setKeywords(e.target.value)}}
                                  enterButton={false}
                          />
                        </div>
                        <div className={styles.summary}>
                          <div>
                            <span>{summary.total ? summary.total : '0'}</span>
                            <span>趟数</span>
                          </div>
                          <div>
                            <span>{summary.volumn ? summary.volumn : '0'}</span>
                            <span>方量</span>
                          </div>
                        </div>
                        {/* <span style={{padding: '5px 5px 0'}}>联单列表 <span>趟数: {summary.total}</span><span style={{marginLeft: 8}}>方量: {summary.volumn}</span></span>*/}
                        <div className={styles.inCirculation}>
                          <div className={styles.sourceClass}><span className={source === 0 ? styles.active : ''} onClick={() => {setPage(1); setSource(0);}}>全部</span><span className={source === 1 ? styles.active : ''} onClick={() => {setPage(1); setSource(1)}}>车载识别</span><span className={source === 2 ? styles.active : ''} onClick={() => {setPage(1); setSource(2)}}>人工补录</span><span className={source === 3 ? styles.active : ''} onClick={() => {setPage(1); setSource(3)}}>车牌识别</span></div>
                          <div className={styles.ticketLists}>
                            {
                              abnormalData.length === 0 ?
                                <div style={{color: '#8A898F', backgroundColor: 'transparent', boxShadow: 'none' }}>暂无该类型联单</div> :
                                abnormalData.map((v, i) => {
                                  return (
                                    <div className={ticketId === v.id ? styles.active : ''} key={i}>
                                      <div className={styles.carTop}>
                                        <div><span>{v.car_num}</span></div>
                                        <div><span>{SOURCELIST[v.source]}</span><span>{v.volumn}方</span></div>
                                      </div>
                                      <div className={styles.companyName}><span className={v.is_device ? styles.device : ''}>车载</span><span className={v.is_constract ? styles.device : ''}>{v.is_constract ? '合同' : '非合同'}</span><span className={v.is_directory ? styles.device : ''}>{v.is_directory ? '名录' : '非名录'}</span></div>
                                      <div className={styles.trashPoint}>
                                        <div className={styles.trashRoute}><p title={v.start_name}>{v.start_name}</p><span>起点</span></div>
                                        <div className={styles.trashRouteLine}>{}</div>
                                        <div className={styles.trashRoute}><p title={v.end_name}>{v.end_name}</p><span>终点</span></div>
                                      </div>
                                      <div className={styles.inTime}><span>{v.start_time}</span>
                                        <div>
                                          <span className={styles.detail} onClick={() =>{setTicketId(v.id); getTicketDetail(v.id)}}>详情</span>
                                          <span className={styles.trail } onClick={() =>{setTicketId(v.id);getTicketLine(v.id)}}>轨迹</span>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                            }
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}><span style={{display: 'inline-block', width: '100%', backgroundColor: 'transparent', padding: '5px 0', cursor: 'pointer', textAlign: 'center'}}  onClick={() => {moreVisible ? setPage(page + 1) : null}}>{moreVisible ? '点击加载更多' : '暂无更多'}</span></div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className={styles.continuousSand}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 0 0'}}>
                          <div>
                            <span style={{padding: '5px 5px 0'}}>汇总: <span>趟数: {abnormalData.length}</span><span style={{marginLeft: 8}}>方量: {getDataByParams(1, abnormalData)}</span></span>
                          </div>
                          <Button size={'small'} onClick={() => setStopOrList(false)}>返回</Button>
                        </div>
                        <div className={styles.inCirculation}>
                          <div className={styles.ticketLists} style={{height: 660}}>
                            {
                              abnormalData.length === 0 ?
                                <div style={{color: '#8A898F', backgroundColor: 'transparent', boxShadow: 'none' }}>暂无联单</div> :
                                abnormalData.map((v, i) => {
                                  return (
                                    <div className={ticketId === v.id ? styles.active : ''} key={i}>
                                      <div className={styles.carTop}>
                                        <div><span>{v.car_num}</span></div>
                                        <div><span>{SOURCELIST[v.source]}</span><span>{v.volumn}方</span></div>
                                      </div>
                                      <div className={styles.companyName}><span className={v.is_device ? styles.device : ''}>车载</span><span className={v.is_constract ? styles.device : ''}>{v.is_constract ? '合同' : '非合同'}</span><span className={v.is_directory ? styles.device : ''}>{v.is_directory ? '名录' : '非名录'}</span></div>
                                      <div className={styles.trashPoint}>
                                        <div className={styles.trashRoute}><p title={v.start_name}>{v.start_name}</p><span>起点</span></div>
                                        <div className={styles.trashRouteLine}>{}</div>
                                        <div className={styles.trashRoute}><p title={v.end_name}>{v.end_name}</p><span>终点</span></div>
                                      </div>
                                      <div className={styles.inTime}><span>{v.start_time}</span>
                                        <div>
                                          <span className={styles.detail} onClick={() =>{setTicketId(v.id); getTicketDetail(v.id)}}>详情</span>
                                          <span className={styles.trail } onClick={() =>{setTicketId(v.id);getTicketLine(v.id)}}>轨迹</span>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                            }
                          </div>
                        </div>
                      </div>
                  }
                </Spin>
              </div>
            )
          }
          {plan.id && (
            <div className={styles.plan}>
              <div className={styles.head}>
                <div className={styles.name}>{plan.start_name}</div>
                <div className={styles.btn} onClick={() => {setTicketSeries(); setTicketList([]); setPlayBegin(false); setMovePos(0); setPlayIndex(0); setVisible(false);planFlag = false; setTrashShowDetail(false); setTimeout(function(){selectPlan()}, 10)}}><Icon type="close" /></div>
              </div>
              <div className={styles.body}>
                {details ? (
                  <div className={ticketList.length > 0 ? `${styles.detail}` : `${styles.detail} ${styles.detail1}`}>
                    <div className={styles.ended}><div><span>已结束</span><span>{details.summary.ended[0]}</span>趟</div> <span>{details.summary.ended[1].toFixed(2)}</span>方</div>
                    <div className={styles.unend}><div><span>未结束</span><span>{details.summary.unend[0]}</span>趟</div> <span>{details.summary.unend[1].toFixed(2)}</span>方</div>
                    {ticketList.length > 0 ? (
                      <>
                        <div className={styles.select}><div><span>已选择</span><span>{ticketList.length}</span>趟</div> <span>{selectTicketVolumn()}</span>方</div>
                      </>
                    ) : null}
                  </div>
                ) : null
                }
                {
                  ticketList.length > 0 ? (
                    <div className={styles.ticketList}>
                      {
                        ticketList.map((v, i) => {
                          return (
                            <div className={styles.ticket} key={i}>
                              <div className={styles.ticket_title}>
                                <div>
                                  <span>{v.car_num}</span>
                                </div>
                                <div>
                                  <span>{v.volumn}方</span>
                                </div>
                              </div>
                              <div className={styles.company_name}>{v.transport_name}</div>
                              <div className={styles.trash_point}>
                                <div className={styles.trash_route}>
                                  <p title={v.start_name && v.start_name !== '' ? v.start_name : '未知'}>{v.start_name && v.start_name !== '' ? v.start_name : '未知'}</p>
                                  <span>起点</span>
                                </div>
                                <div className={styles.trash_route_line}>

                                </div>
                                <div className={styles.trash_route}>
                                  <p title={v.end_name && v.end_name !== '' ? v.end_name : '未知'}>{v.end_name && v.end_name !== '' ? v.end_name : '未知'}</p>
                                  <span>终点</span>
                                </div>
                              </div>
                              <div className={styles.in_time}><span>{v.start_time}</span><div><Button type={'primary'} size={'small'} onClick={() =>{setTicketId(v.id); getTicketDetail(v.id)}}>详情</Button><Button type={'primary'} size={'small'} onClick={() => {setTicketId(v.id);getTicketLine(v.id)}}>轨迹</Button></div></div>
                            </div>
                          )
                        })
                      }
                    </div>
                  ) : <> </>
                }
              </div>
            </div>
          )}
        </div>
        <div className={styles.chart} ref={chartRefGetter} />
      </div>
      {
        visible ?
          <div className={styles.radio}>
            <Radio.Group defaultValue={hour} buttonStyle="solid" onChange={(e) => {setHour(e.target.value); trash_show_detail ? getTicketDetail(ticketId, e.target.value) : ''; ticketSeries ? getTicketLine(ticketId, e.target.value) : ''}}>
              <Radio.Button value="1">1小</Radio.Button>
              <Radio.Button value="2">2小</Radio.Button>
              <Radio.Button value="3">3小 </Radio.Button>
              <Radio.Button value="12">12小 </Radio.Button>
              <Radio.Button value="24">24小 </Radio.Button>
            </Radio.Group>
          </div> : null
      }
      {
        trash_show_detail ? (
          <TrashTicketDetail
            data={trashDetailData}
            ticketType={10}
            carModify={false}
            style={{top: 5, left: 'auto', right: 20}}
            ticketId={ticketId}
            closeTicketDetail={closeTicketDetail}
            startCallDriver={startCallDriver}
          />
        ) : null
      }
      {
        call_driver ? <CallDriver data={{ msg: msg, call_flag: call_flag}} rtcCallEnd={rtcCallEnd} /> : null
      }
      <Drawer title={false} placement="bottom" closable={true} mask={false} height={55} bodyStyle={{padding: 10}} maskClosable={false} onClose={() => {setPlayBegin(false);setMovePos(0);setVisible(false);setPlayIndex(0);setTicketList([]);setTimeout(function(){setTicketSeries();}, 10)}} visible={visible}>
        <div className={styles.Drawer}>
          <div style={{display: 'flex'}}>
            <div className={styles.btns}>
              <Button onClick={() => {setPlayBegin(!play_begin);setPlayEnd(false);}} className={`${styles.play_begin} ${play_begin ? styles.active : ''} `} type="dashed" title={play_begin ? '停止' : '播放'} />
              {/*<Button onClick={() => {setPlayEnd(true);setPlayBegin(false)}} className={`${styles.play_end} ${play_end ? styles.active : ''}`} type="dashed" />*/}
              <Button onClick={() => {setPlayRestart(!play_restart);setMovePos(0);setPlayBegin(true)}} className={`${styles.play_restart} ${play_restart ? styles.active : ''}`} type="dashed" />
            </div>
            <div className={styles.control}>
              <Radio.Group value={moveSpeed} buttonStyle="solid" onChange={(e) => {setMoveSpeed(e.target.value)}}>
                <Radio.Button value="800">0.5倍</Radio.Button>
                <Radio.Button value="400">1倍</Radio.Button>
                <Radio.Button value="200">2倍</Radio.Button>
                <Radio.Button value="100">4倍</Radio.Button>
                <Radio.Button value="50">8倍</Radio.Button>
              </Radio.Group>
              <Slider min={0} max={slider.length - 1} value={playIndex} tipFormatter={(v) => {return slider[v]} } onChange={e => setPlayIndex(e)} onAfterChange={(e) => setMovePos(e)} />
            </div>
          </div>
          <div style={{marginRight: 40, display: 'flex', alignItems: 'center'}}>
            <Switch defaultChecked={rectify} checkedChildren={'轨迹纠偏'} unCheckedChildren={'未纠偏轨迹'} onChange={e => {setRectify(e)}}> </Switch>
            <Switch defaultChecked={show_points}  checkedChildren={'原始轨迹点'} unCheckedChildren={'原始轨迹点'}  onChange={e => {setShowPoints(e); overPointsFlag = e;}}> </Switch>
            <Switch defaultChecked={show_stops}  checkedChildren={'停靠点'} unCheckedChildren={'停靠点'}  onChange={e => {setShowStops(e); overStopFlag = e;}}> </Switch>
            <div style={{display: 'flex'}}>
              {/*<div style={{display: 'flex', alignItems: 'center', marginLeft: 15}}><span style={{backgroundColor: '#EB3873', display: 'inline-block', width: 20, height: 2, marginRight: 5}}>{}</span><span>规划线路</span></div>*/}
              <div style={{display: 'flex', alignItems: 'center', marginLeft: 15}}><span style={{backgroundColor: '#46bee9', display: 'inline-block', width: 20, height: 2, marginRight: 5}}>{}</span><span>纠偏轨迹</span></div>
              <div style={{display: 'flex', alignItems: 'center', marginLeft: 15}}><span style={{backgroundColor: '#03d5a0', display: 'inline-block', width: 20, height: 2, marginRight: 5}}>{}</span><span>未纠偏轨迹</span></div>
            </div>
          </div>
        </div>
      </Drawer>
      <Modal
        title={visibleTitle}
        visible={visibleTable}
        width={1200}
        onCancel={() => setVisibleTable(false)}
        footer={[
          <Button key="back" onClick={() => setVisibleTable(false)}>
            关闭
          </Button>,
        ]}
        bodyStyle = {{
          maxHeight: '70vh',
          overflowY: 'auto', position: 'relative'}
        }
      >
        {
          concertData.flag ?
            <div style={{display: 'flex', marginBottom: 15 }}>
              <div><span>消纳场名称: </span> <span>{concertData.name}</span></div>
              <div style={{marginLeft: 15}}><span>消纳场地址: </span> <span>{concertData.address}</span></div>
              <div style={{marginLeft: 15}}><span>消纳场图片: </span> <span style={{cursor: 'pointer', color: '#1491fb'}} onClick={() => {setImgSrc(concertData.images); setImgFlag(true)}}>查看图片</span></div>
            </div> : null
        }
        <div style={{position: 'absolute',margin: 'auto',left: 0,right: 0,textAlign: 'center', width: 340}}>
          <Radio.Group value={filterFlag} buttonStyle="solid" style={{marginRight: 15}} onChange={(e) => {setFilterFlag(e.target.value)}}>
            <Radio.Button value="0">全部</Radio.Button>
            <Radio.Button value="1">{area_name}内</Radio.Button>
            <Radio.Button value="2">入{area_name}</Radio.Button>
            <Radio.Button value="3">出{area_name}</Radio.Button>
          </Radio.Group>
        </div>
        <div style={{marginBottom: 15, display: 'flex', flexFlow: 'column' }}>
          <Button style={{width: 80, marginBottom: 10}} onClick={() => OutToFile()}><Icon type="download" />{'导出'}</Button>
          <Radio.Group value={sourceFlag} buttonStyle="solid" onChange={(e) => {setSourceFlag(e.target.value)}}>
            <Radio.Button value="0">全部</Radio.Button>
            <Radio.Button value="4">疑似偷排</Radio.Button>
            <Radio.Button value="3">非法消纳</Radio.Button>
            <Radio.Button value="1">指定接管</Radio.Button>
            <Radio.Button value="2">砂石场接管</Radio.Button>
          </Radio.Group>
        </div>
        <div><span>合计: </span><span>方量: {getDataByParams(1, filterDataSource(dataSource)).toFixed(0)}</span><span style={{marginLeft: 10}}>趟数: {getDataByParams(2, filterDataSource(dataSource))}</span></div>
        <div id={'div1'}>
          <Table columns={columns} dataSource={getRowSpan(filterDataSource(dataSource))} bordered pagination={false} />
        </div>
      </Modal>
      {
        !visible ? <div className={styles.right_top_box} style={{top: 710}}>
            <div>
              <div className={styles.all_div} onClick={() => {getRightType(0)}}>
                <Tooltip placement="left" title="全部" overlayClassName="my_tip">
                  <img src={ isInArray(right_flag, 0) ? "/images/center/icon1_h.png" : "/images/center/icon1.png"} alt="" />
                </Tooltip>
              </div>
              <div className={styles.all_div} onClick={() => {getRightType(5)}}>
                <Tooltip placement="left" title="线路" overlayClassName="my_tip">
                  <img src={ isInArray(right_flag, 5) ? "/images/center/xianlu2.png" : "/images/center/xianlu1.png"} style={{ width: '13px', height: '16px' }} alt="" />
                </Tooltip>
              </div>
              <div className={styles.all_div} onClick={() => {getRightType(6)}}>
                <Tooltip placement="left" title="轨迹" overlayClassName="my_tip">
                  <img src={ isInArray(right_flag, 6) ? "/images/center/guiji2.png" : "/images/center/guiji1.png"} style={{ width: '13px', height: '16px' }} alt="" />
                </Tooltip>
              </div>
            </div>
          </div>
          : <div className={styles.right_top_box}>
            <div>
              <div className={styles.all_div} onClick={() => {getRightType1(0)}}>
                <Tooltip placement="left" title="全部" overlayClassName="my_tip">
                  <img src={ isInArray(right_flag1, 0) ? "/images/center/icon1_h.png" : "/images/center/icon1.png"} alt="" />
                </Tooltip>
              </div>
              <div className={styles.all_div} onClick={() => {getRightType1(1)}}>
                <Tooltip placement="left" title="工地" overlayClassName="my_tip">
                  <img src={ isInArray(right_flag1, 1) ? "/images/center/icon2_h.png" : "/images/center/icon2.png"} style={{ width: '14px', height: '13px' }} alt="" />
                </Tooltip>
              </div>
              <div className={styles.all_div} onClick={() => {getRightType1(2)}}>
                <Tooltip placement="left" title="砂石场" overlayClassName="my_tip">
                  <img src={ isInArray(right_flag1, 2) ? "/images/center/icon3_h.png" : "/images/center/icon3.png"} style={{ width: '13px', height: '18px' }} alt="" />
                </Tooltip>
              </div>
              <div className={styles.all_div} onClick={() => {getRightType1(3)}}>
                <Tooltip placement="left" title="商砼" overlayClassName="my_tip">
                  <img src={ isInArray(right_flag1, 3) ? "/images/center/icon4_h.png" : "/images/center/icon4.png"} style={{ width: '13px', height: '19px' }} alt="" />
                </Tooltip>
              </div>
              <div className={styles.all_div} onClick={() => {getRightType1(4)}}>
                <Tooltip placement="left" title="消纳场" overlayClassName="my_tip">
                  <img src={ isInArray(right_flag1, 4) ? "/images/center/icon5_h.png" : "/images/center/icon5.png"} style={{ width: '15px', height: '19px' }} alt="" />
                </Tooltip>
              </div>
            </div>
          </div>
      }
      <Modal
        title="车辆停靠趋势图"
        visible={visibleBuild}
        width={1200}
        onCancel={() => setVisibleBuild(false)}
        footer={[
          <Button key="back" onClick={() => setVisibleBuild(false)}>
            关闭
          </Button>,
        ]}
        bodyStyle = {{
          maxHeight: '70vh',
          padding: 0,}
        }
      >
        <div id={'consumChart'} style={{width: '100%', height: 500}} />
      </Modal>
    </div>
  );
}
