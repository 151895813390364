import React from 'react';
import { Button, Popconfirm, message } from 'antd';
import request from 'utils/request';

export default function NewDeleteButton({ title, type, children, api, dataSource, ...data }) {
  async function confirm() {
    await request(api, data)
      .then(body => body.data)
      .then((data) => {
        if (data.code === 200) {
          message.success(data.msg)
        } else {
          message.warn(data.msg)
        }
      });
    dataSource.refresh();
  }

  return (
    <Popconfirm title={title} onConfirm={confirm}>
      <Button type={type} size="small">{children}</Button>
    </Popconfirm>
  );
}
