import common from 'utils/common';

export const CarInfo = [
  { label: '车牌', key: 'car_num', placeholder: '请填写车牌', type: 'car_match', required: true },
  { label: '设备编号', key: 'device_num', placeholder: '请填写设备编号', required: true },
  { label: '车辆颜色', key: 'color', placeholder: '请填写车辆颜色', required: true },
  { label: '车辆品牌', key: 'brand', placeholder: '请填写车辆品牌', required: true },
  { label: '燃料类型', key: 'fuel_type', placeholder: '请选择燃料类型', type: 'select', options: common.fuel_type, valueKey: 'value', labelKey: 'label', required: true },
  { label: '排放标准', key: 'emission_standards', placeholder: '请选择排放标准', type: 'select', options: common.emission_standards, valueKey: 'value', labelKey: 'label', required: true },
  { label: '车主姓名', key: 'owner_name', placeholder: '请填写车主姓名', required: true },
  { label: '车主手机号', key: 'owner_phone', type: 'phone_match', placeholder: '请填写车主手机号', required: true },
  { label: '道路运输证号', key: 'ship_license_num', placeholder: '请填写道路运输证号' },
  { label: '车辆型号', key: 'car_model', placeholder: '请填写车辆型号', required: true },
  { label: '发动机号', key: 'motor_num', placeholder: '请填写发动机号', required: true },
  { label: '车架号', key: 'carriage_num', placeholder: '请填写车架号', required: true },
  { label: '密闭装置', key: 'obturator', placeholder: '请选择密闭装置', type: 'select', options: common.obturator, required: true },
  { label: '防漏装置', key: 'leakproof', placeholder: '请选择防漏装置', type: 'select', options: common.leakproof, required: true },
  { label: '货箱内径尺寸-长-米', key: 'inner_long', type: 'number', min: 0, style: { width: 200}, placeholder: '请填写货箱内径尺寸-长-米', required: true },
  { label: '货箱内径尺寸-宽-米', key: 'inner_width', type: 'number', min: 0, style: { width: 200}, placeholder: '请填写货箱内径尺寸-宽-米', required: true },
  { label: '货箱内径尺寸-高-米', key: 'inner_height', type: 'number', min: 0, style: { width: 200}, placeholder: '请填写货箱内径尺寸-高-米', required: true },
  { label: '核定载重量-吨', key: 'weight', type: 'number', min: 0, style: { width: 200}, placeholder: '请填写核定载重量-吨', required: true },
  { label: '保险公司', key: 'insure_company', placeholder: '请填写保险公司' },
  { label: '保险金额（元）', key: 'insure_money', type: 'number', min: 0, style: { width: 200}, placeholder: '请填写保险金额（元）' },
  { label: '保险日期开始', key: 'insure_start_date', type: 'date', format: 'YYYY-MM-DD', placeholder: '请选择保险日期开始' },
  { label: '保险日期截止', key: 'insure_end_date', type: 'date', format: 'YYYY-MM-DD', placeholder: '请选择保险日期截止' },
  { label: '第三者责任险（元）', key: 'third_party_insure', min: 0, style: { width: 200}, type: 'number', placeholder: '请填写第三者责任险（元）' },
  { label: '车辆行驶证图片', key: 'drive_license_photo', type: 'upload', required: true },
  { label: '道路运输证图片', key: 'ship_license_photo', type: 'upload' },
  { label: '车辆照片', key: 'car_photo', type: 'upload', required: true },
];

export const CarInfoByEdit = [
  { label: '车牌', key: 'car_num', placeholder: '请填写车牌', type: 'car_match', required: true, disabled: true },
  { label: '设备编号', key: 'device_num', placeholder: '请填写设备编号', required: true, disabled: true },
  { label: '车辆颜色', key: 'color', placeholder: '请填写车辆颜色', required: true },
  { label: '车辆品牌', key: 'brand', placeholder: '请填写车辆品牌', required: true },
  { label: '燃料类型', key: 'fuel_type', placeholder: '请选择燃料类型', type: 'select', options: common.fuel_type, valueKey: 'value', labelKey: 'label', required: true },
  { label: '排放标准', key: 'emission_standards', placeholder: '请选择排放标准', type: 'select', options: common.emission_standards, valueKey: 'value', labelKey: 'label', required: true },
  { label: '车主姓名', key: 'owner_name', placeholder: '请填写车主姓名', required: true },
  { label: '车主手机号', key: 'owner_phone', type: 'phone_match', placeholder: '请填写车主手机号', required: true },
  { label: '道路运输证号', key: 'ship_license_num', placeholder: '请填写道路运输证号' },
  { label: '车辆型号', key: 'car_model', placeholder: '请填写车辆型号', required: true },
  { label: '发动机号', key: 'motor_num', placeholder: '请填写发动机号', required: true },
  { label: '车架号', key: 'carriage_num', placeholder: '请填写车架号', required: true },
  { label: '密闭装置', key: 'obturator', placeholder: '请选择密闭装置', type: 'select', options: common.obturator, required: true },
  { label: '防漏装置', key: 'leakproof', placeholder: '请选择防漏装置', type: 'select', options: common.leakproof, required: true },
  { label: '货箱内径尺寸-长-米', key: 'inner_long', type: 'number', min: 0, style: { width: 200}, placeholder: '请填写货箱内径尺寸-长-米', required: true },
  { label: '货箱内径尺寸-宽-米', key: 'inner_width', type: 'number', min: 0, style: { width: 200}, placeholder: '请填写货箱内径尺寸-宽-米', required: true },
  { label: '货箱内径尺寸-高-米', key: 'inner_height', type: 'number', min: 0, style: { width: 200}, placeholder: '请填写货箱内径尺寸-高-米', required: true },
  { label: '核定载重量-吨', key: 'weight', type: 'number', min: 0, style: { width: 200}, placeholder: '请填写核定载重量-吨', required: true },
  { label: '保险公司', key: 'insure_company', placeholder: '请填写保险公司' },
  { label: '保险金额（元）', key: 'insure_money', type: 'number', min: 0, style: { width: 200}, placeholder: '请填写保险金额（元）' },
  { label: '保险日期开始', key: 'insure_start_date', type: 'date', format: 'YYYY-MM-DD', placeholder: '请选择保险日期开始' },
  { label: '保险日期截止', key: 'insure_end_date', type: 'date', format: 'YYYY-MM-DD', placeholder: '请选择保险日期截止' },
  { label: '第三者责任险（元）', key: 'third_party_insure', min: 0, style: { width: 200}, type: 'number', placeholder: '请填写第三者责任险（元）' },
  { label: '车辆行驶证图片', key: 'drive_license_photo', type: 'upload', required: true },
  { label: '道路运输证图片', key: 'ship_license_photo', type: 'upload' },
  { label: '车辆照片', key: 'car_photo', type: 'upload', required: true },
];
