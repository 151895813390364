import React, {useCallback, useEffect, useState} from 'react';
import echarts from "echarts";
import debug from "utils/debug";
const logChartDispose = () => debug.action('Echarts实例已卸载') || true;

export function UseEcharts() {
  const [chart, setChart] = useState(null); // 存储echart实例
  const refGetter = useCallback(element => !!element && setChart(echarts.init(element)), []); // 创建echart实例
  const isChartUsable = useCallback(() => (!!chart && !chart.isDisposed()), [chart]); // 判断echart实例是否可用
  useEffect(() => () => (isChartUsable() && logChartDispose() && chart.dispose()), [chart]); // 卸载echart实例
  return [chart, isChartUsable, refGetter];
}
