import React, { useMemo, useState, useEffect } from 'react';
import request from 'utils/request';
import { Modal, message, InputNumber, Button } from "antd";
import styles from './Detail.scss';
import { dataSource } from './List';

export default function Checks({ history, match }) {
  const [detailInfo, setDetailInfo] = useState({});
  const [hight_diff, setHightDiff] = useState(0);
  const id = useMemo(() => match.params.id, []);

  useEffect(() => {
    request('/web/human_ticket/detail', { id })
      .then(body => body.data.data).then((data) => {
        setDetailInfo(data);
        setHightDiff(!data.check_quantities ? 0 : data.check_quantities.hight_diff);
      });
  }, []);

  const back = () => history.goBack();
  const onFinish = () => {
    request('/web/check_quantities/add', { human_ticket_id: id, hight_diff })
      .then(body => body.data).then((data) => {
        if (data.code === 150) {
          message.warning(data.msg);
          return;
        }
        message.success('处理成功!');
        dataSource.refresh();
        back();
      });
    
  };

  const getVolume = () => {
    if (!detailInfo.car_directory_detail) return 0;
    const { inner_long, inner_width, inner_height } = detailInfo.car_directory_detail;
    return (inner_long * inner_width * (inner_height - hight_diff)).toFixed(2);
  }
  
  return (
    <Modal
      visible
      width={500}
      centered
      bodyStyle={{ overflow: 'auto'}}
      title="量方"
      onCancel={back}
      // onOk={back}
      footer={[
        <Button key="back" onClick={back}>关闭</Button>,
        <Button key="ok" type="primary" onClick={onFinish} disabled={!detailInfo.car_directory_detail || (detailInfo.car_directory_detail && hight_diff > (detailInfo.car_directory_detail.inner_height))}>确认提交</Button>,
      ]}
    >
      <div className={styles.checks}>
        <div className={styles.label}>装载货物后货箱内径高度差</div>
        <div>
          <div className={styles.flexs}>
            <InputNumber
              placeholder="请输入"
              precision={2}
              min={0}
              autoFocus={true}
              max={detailInfo.car_directory_detail ? detailInfo.car_directory_detail.inner_height : 0}
              step={0.1}
              size="large"
              value={hight_diff}              
              onChange={(e) => {
                setHightDiff(Number(e.toFixed(2)))
              }} style={{ width: 250, color: '#1684FC' }} />
            <span className={styles.color}>m</span>
          </div>
          {(detailInfo.car_directory_detail && hight_diff > (detailInfo.car_directory_detail.inner_height)) && <span style={{ color: 'red', fontSize: 12 }}>你填写的值已超过初始车辆货箱内径-高，请重新填写</span>}
        </div>
        <div className={styles.label}>初始车辆货箱内径尺寸</div>
        <div >{!detailInfo.car_directory_detail ? '-' : `${detailInfo.car_directory_detail.inner_long} x ${detailInfo.car_directory_detail.inner_width} x ${detailInfo.car_directory_detail.inner_height}m`}</div>
        <div className={styles.label}>装载货物后货箱内径尺寸</div>
        <div className={styles.color}>{!detailInfo.car_directory_detail ? '-' : `${detailInfo.car_directory_detail.inner_long} x ${detailInfo.car_directory_detail.inner_width} x ${(detailInfo.car_directory_detail.inner_height - hight_diff).toFixed(2)}m`}</div>
        <div className={styles.label}>量方方量</div>
        <div className={styles.color}>{`${getVolume()} 方`}</div>
      </div>
    </Modal>
  );
}

