// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Mzkz5bJDn-LlT93H9U4tO {\n  margin-top: 16px;\n  color: black;\n}\n._3Mzkz5bJDn-LlT93H9U4tO .ant-timeline-item-content {\n  top: -16px;\n}\n._3Mzkz5bJDn-LlT93H9U4tO .ant-timeline-item-head {\n  background: #e8e8e8;\n}\n._3Mzkz5bJDn-LlT93H9U4tO .ant-timeline-item-tail {\n  top: 0;\n  border-color: #1e88e5;\n}\n\n._21f0t_N4XpYnA4ivOTidRp {\n  display: flex;\n  justify-content: space-between;\n}\n._21f0t_N4XpYnA4ivOTidRp:not(:first-child) {\n  margin-top: 8px;\n}\n._21f0t_N4XpYnA4ivOTidRp span:first-child {\n  color: rgba(0, 0, 0, 0.5);\n}\n\n.sDtwhehP6wveJjUK8ZB0o .qkzTWGjusvRBWJospFIfl {\n  margin-top: 12px;\n  border: 1px solid #e8e8e8;\n}\n.sDtwhehP6wveJjUK8ZB0o .qkzTWGjusvRBWJospFIfl .ant-card-head-title {\n  font-weight: normal;\n}\n\n._17xn7c1PK0tU6a73A54Z1e {\n  border-radius: 4px;\n  padding: 3px 5px;\n  font-size: 12px;\n  color: white;\n  background: #747785;\n}\n._17xn7c1PK0tU6a73A54Z1e[data-status=\"2\"] {\n  background: #1e6ff4;\n}\n._17xn7c1PK0tU6a73A54Z1e[data-status=\"3\"] {\n  background: #ff940e;\n}\n._17xn7c1PK0tU6a73A54Z1e[data-status=\"7\"] {\n  background: #fd332d;\n}\n._17xn7c1PK0tU6a73A54Z1e[data-status=\"8\"] {\n  background: #49d966;\n}\n\n._2VIfcuS5x-I6gg7IFVek8m[data-type=\"101\"], ._2VIfcuS5x-I6gg7IFVek8m[data-type=\"102\"] {\n  max-width: 70%;\n}\n._2VIfcuS5x-I6gg7IFVek8m[data-type=\"103\"] span {\n  cursor: pointer;\n  color: #1e6ff4;\n}", ""]);
// Exports
exports.locals = {
	"content": "_3Mzkz5bJDn-LlT93H9U4tO",
	"cell": "_21f0t_N4XpYnA4ivOTidRp",
	"forms": "sDtwhehP6wveJjUK8ZB0o",
	"form": "qkzTWGjusvRBWJospFIfl",
	"status": "_17xn7c1PK0tU6a73A54Z1e",
	"submit": "_2VIfcuS5x-I6gg7IFVek8m"
};
module.exports = exports;
