import React from 'react';
import ModalFormPage from 'ui/ModalFormPage';
import { dataSource, inputs } from './List';
import { message } from 'antd';

export default function Create({ history, match }) {
  const back = () => history.goBack();
  const onFinish = () => {
    message.success('新增点位成功');
    dataSource.refresh();
    back();
  };

  return (
    <ModalFormPage
      submit={['/river/river_project/createRiverSiteInfo']}
      title="新增点位"
      inputs={inputs}
      onCancel={back}
      onFinish={onFinish}
    />
  );
}
